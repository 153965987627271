
import * as medicines from './actions';
import { ActionType, getType } from 'typesafe-actions';
import {MedicineState, MedicineActionTypes} from './types'
import {KeywordType} from './../../models/Model.Medicine'
import {log, LogLevel} from '../../utils/LogUtil'


const defaultState: MedicineState = {
  medicines: {},
  ingredients: {},
  animalMedicines: {},
};

export default (state = defaultState, action: ActionType<typeof medicines>): MedicineState => {
  // log(LogLevel.REDUX_MEDICINE, "Medicine Reducer. ", action)
  switch (action.type) {
    // case getType(medicines.getMedicine):{
    //   switch(action.payload){
    //     case KeywordType.MEDICINE : {
    //       if(!state.medicines[action.meta]){
    //         let medicines = {...state.medicines};
    //         medicines[action.meta] = {};
    //         return {
    //           ...state,
    //           medicines: medicines,
    //         }
    //       }
    //     }
    //     case KeywordType.INGRADIENT : {
    //       if(!state.ingredients[action.meta]){
    //         let ingredients = {...state.ingredients};
    //         ingredients[action.meta] = {};
    //         return {
    //           ...state,
    //           ingredients: ingredients,
    //         }
    //       }
    //     }
    //   }
    // }
    case getType(medicines.fetchMedicine.success):{
      if(action.payload.type == KeywordType.MEDICINE) {
        let medicines = {...state.medicines};
        medicines[action.payload.id] = action.payload;
        log(LogLevel.REDUX_MEDICINE, "medicines.fetchMedicine.success Medicine", action.payload, state.medicines, medicines)
        return {
          ...state,
          medicines: medicines,
        }
      }
      else if(action.payload.type == KeywordType.INGRADIENT) {
        let ingredients = {...state.ingredients};
        ingredients[action.payload.id] = action.payload;
        log(LogLevel.REDUX_MEDICINE, "medicines.fetchMedicine.success Ingredient", action.payload, state.ingredients, ingredients)
        return {
          ...state,
          ingredients: ingredients,
        }
      }
    }
    case getType(medicines.setMedicine):{
      if(action.payload.type == KeywordType.MEDICINE) {
        let medicines = {...state.medicines};
        medicines[action.payload.id] = action.payload;
        log(LogLevel.REDUX_MEDICINE, "medicines.setMedicine Medicine", action.payload, state.medicines, medicines)
        return {
          ...state,
          medicines: medicines,
        }
      }
      else if(action.payload.type == KeywordType.INGRADIENT) {
        let ingredients = {...state.ingredients};
        ingredients[action.payload.id] = action.payload;
        log(LogLevel.REDUX_MEDICINE, "medicines.setMedicine Ingredient", action.payload, state.ingredients, ingredients)
        return {
          ...state,
          ingredients: ingredients,
        }
      }
      else if(action.payload.type == KeywordType.ANIMAL_MEDICINE) {
        let animalMedicines = {...state.animalMedicines};
        animalMedicines[action.payload.id] = action.payload;
        log(LogLevel.REDUX_MEDICINE, "medicines.setMedicine Ingredient", action.payload, state.animalMedicines, animalMedicines)
        return {
          ...state,
          animalMedicines,
        }
      }
    }
  }
  return state;
}
