import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JobPostPremiumOrderTemplate from "../../components/templates/JobPostPremiumOrder";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import { withRouter } from "react-router-dom";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "../../API.json";
import {
  clearGlobal,
  getGlobal,
  GlobalKey,
  setGlobal,
} from "../../utils/GlobalUtil";
import { UIPopupType } from "../../store/ui/types";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { OrderInfo } from "../../models/Mode.Biz";
import ABTestUtil from "../../utils/ABTestUtil";
import { JobListType } from "../../store/jobpost/types";

const queryString = require("query-string");

const JobPostPremiumOrder = ({ history, location }) => {
  const [id, setId] = useState<number>(0);
  const me = useSelector((state: RootState) => state.user.me);
  const coupon = useSelector((state: RootState) => state.jobpost.coupon);
  const waitingPopup = useSelector(
    (state: RootState) => state.ui.popups[UIPopupType.WAITING_POPUP]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [payDone, setPayDone] = useState<boolean>(false);
  const offer = useSelector((state: RootState) => state.jobpost.offers[id]);
  const dispatch = useDispatch();
  const order = useRef<OrderInfo>(null);
  const returnUrl = useRef<String>(
    location.state && location.state.returnUrl ? location.state.returnUrl : ""
  );
  useEffect(() => {
    let qs = queryString.parse(window.location.search);
    log(
      LogLevel.UI_LIFECYCLE,
      "JobPostPremiumOrder:useEffect []",
      window.location.search,
      qs,
      qs.id
    );
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_ORDER_FAIL",
      "구인구직_프리미엄주문_진입",
      {}
    );
    if (qs.id) {
      try {
        let newId = parseInt(qs.id);
        setId(newId);
        dispatch(actions.jobpost.loadOffer({}, newId));
        dispatch(actions.jobpost.loadCoupon());
      } catch (err) {}
    }

    try {
      const windowAny: any = window;
      windowAny.IMP.init("imp68909306");
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "ProductSaleOrder.init error", e);
    }

    return () => {
      // log(LogLevel.UI_EVENT, "ToggleSwitch:useEffect:checked:return", checked)
    };
  }, []);

  const onBack = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_ORDER_BACK",
      "구인구직_프리미엄주문_종료",
      {}
    );
    if (returnUrl.current) history.replace(returnUrl.current);
    else history.goBack();
  };

  const onFinalize = () => {
    dispatch(
      actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.PREMIUM)
    );
    dispatch(
      actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY)
    );
    onBack();
  };

  const onOrder = (newOrder: OrderInfo) => {
    log(LogLevel.UI_ACTION, "JobPostPremiumOrder:onOrder Start", newOrder);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_ORDER",
      "구인구직_프리미엄_주문",
      newOrder
    );

    newOrder.userId = me.id;
    setGlobal(GlobalKey.SALE_LAST_ORDER_ID + "_JOBPOST_PREMIUM", id, true);

    if (newOrder.payAmount == 0) newOrder.payStatus = "paid";
    else if (ABTestUtil.isTest()) newOrder.payAmount = 100;
    fetchAPI(
      API.JOBPOST_PREMIUM_ORDER,
      "",
      null,
      newOrder,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        newOrder.id = result.data;
        log(
          LogLevel.UI_ACTION,
          "JobPostPremiumOrder:onOrder create success",
          newOrder
        );

        if (newOrder.payAmount == 0 && newOrder.payStatus == "paid") {
          waitingPopup.show();
          setLoading(true);
          order.current = { ...newOrder };
          onOrderSuccess();
          return;
        }

        newOrder.payMerchantId =
          "ymyd_JK_U" +
          newOrder.userId +
          "_PO" +
          newOrder.id +
          "_" +
          new Date().getTime();
        const windowAny: any = window;

        order.current = { ...newOrder };
        log(LogLevel.UI_ACTION, "JobPostPremiumOrder:onOrder ", order.current);

        if (getGlobal(GlobalKey.OS) == "browser") {
          waitingPopup.show();
          setLoading(true);
          windowAny.IMP.request_pay(
            {
              pg: "danal_tpay",
              pay_method: "card",
              merchant_uid: newOrder.payMerchantId,
              name: "약문약답 구인구직 프리미엄",
              amount: newOrder.payAmount,
              // amount : 100,
              buyer_email: me.email,
              buyer_name: me.name,
              buyer_tel: me.phone,
            },
            onOrderDoneWeb
          );
        } else {
          // var titleOptions: any = {
          //   text: "결제진행", // 타이틀
          //   textColor: "#ffffff", // 타이틀 색
          //   textSize: "20", // 타이틀 크기
          //   textAlignment: "left", // 타이틀 정렬 유형
          //   backgroundColor: "#1fbcff", // 타이틀 배경색
          //   show: true, // 타이틀 유무
          //   leftButtonType: "back", // 왼쪽 버튼 유형
          //   leftButtonColor: "#ffffff", // 왼쪽 버튼 색
          //   rightButtonType: "close", // 오른쪽 버튼 유형
          //   rightButtonColor: "#ffffff", // 오른쪽 버튼 색
          // };

          var titleOptions = {
            show: false, // 타이틀 유무
          };
          var userCode = "imp68909306"; // 가맹점 식별코드
          var data = {
            pg: "danal_tpay", // PG사
            pay_method: "card", // 결제수단
            name: "약문약답 구인구직 프리미엄", // 주문명
            merchant_uid: newOrder.payMerchantId, // 주문번호
            amount: newOrder.payAmount, // 결제금액
            // amount: 100,                              // 결제금액
            buyer_name: me.name, // 구매자 이름
            buyer_tel: me.phone, // 구매자 연락처
            buyer_email: me.email, // 구매자 이메일
            app_scheme: "kakao845dd8ac648986a8631ecadc339a7acf", // 앱 URL 스킴
          };

          var params = {
            titleOptions: titleOptions, // 타이틀 옵션
            userCode: userCode, // 가맹점 식별코드
            data: data, // 결제 데이터
            callback: onOrderDoneApp,
          };
          windowAny.cordova.plugins.IamportCordova.payment(params);
        }
      }
    });
  };

  const onOrderDoneWeb = async (rsp) => {
    let orderId = getGlobal(GlobalKey.SALE_LAST_ORDER_ID + "_JOBPOST_PREMIUM");
    let errorMessage = "",
      error;

    if (!orderId) {
      errorMessage = "결제정보 유실: ERRORCODE1 - 관리자에게 문의하세요.";
    }

    if (!errorMessage && !order.current) {
      errorMessage = "결제정보 유실: ERRORCODE2 - 관리자에게 문의하세요.";
    }

    if (rsp.success) {
      clearGlobal(GlobalKey.SALE_LAST_ORDER_ID + "_JOBPOST_PREMIUM", true);
      if (order.current.payMerchantId != rsp.merchant_uid) {
        alert("결제정보 불일치");
        setLoading(false);
        waitingPopup.hide();
        return;
      }
      order.current.paidAmount = rsp.paid_amount;
      order.current.payUid = rsp.imp_uid;
      order.current.payStatus = rsp.status;
      order.current.payApplyNumber = rsp.apply_num;
      order.current.payRecieptUrl = rsp.receipt_url;

      let result = await fetchAPI(
        API.JOBPOST_PREMIUM_ORDER_UPDATE,
        "",
        null,
        order.current,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
      } else {
        alert("주문 실패 : 약문약답 카카오플러스친구로 연락주세요.");
        setLoading(false);
        waitingPopup.hide();
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_ORDER_FAIL",
          "구인구직_프리미엄주문결제실패",
          {}
        );
        return;
      }

      var msg = "결제가 완료되었습니다.";
      msg += "고유ID : " + rsp.imp_uid;
      msg += " 상점 거래ID : " + rsp.merchant_uid;
      msg += " 결제 금액 : " + rsp.paid_amount;
      msg += " 카드 승인번호 : " + rsp.apply_num;
      log(LogLevel.UI_ACTION, "onOrderDoneWeb:success", rsp, msg);
      // this.props.history.replace("/sale/order/done?id=" + orderId);
      onOrderSuccess();
    } else {
      var msg = "결제에 실패하였습니다.";
      msg += "에러내용 : " + rsp.error_msg;
      alert(msg);
      log(LogLevel.UI_ACTION, "onOrder:fail", rsp);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_ORDER_FAIL",
        "구인구직_프리미엄주문결제실패",
        {}
      );
      // this.failed = true;
    }
    setLoading(false);
    waitingPopup.hide();
  };

  const onOrderDoneApp = async (rsp) => {
    // imp_success == "true", imp_uid, imp_merchant_uid
    waitingPopup.show();
    setLoading(true);
    let orderId = getGlobal(GlobalKey.SALE_LAST_ORDER_ID + "_JOBPOST_PREMIUM");
    let errorMessage = "";
    if (!orderId) {
      errorMessage = "결제정보 유실: ERRORCODE1 - 관리자에게 문의하세요.";
    }

    if (!errorMessage && !order.current) {
      errorMessage = "결제정보 유실: ERRORCODE2 - 관리자에게 문의하세요.";
    }

    if (!errorMessage && rsp.imp_success != "true") {
      errorMessage = "결제정보 실패: ERRORCODE1 - 관리자에게 문의하세요.";
    }

    if (rsp.imp_success == "true") {
      clearGlobal(GlobalKey.SALE_LAST_ORDER_ID + "_JOBPOST_PREMIUM", true);
      if (order.current.payMerchantId != rsp.merchant_uid) {
        errorMessage = `결제정보 불일치: ERRORCODE3 ${rsp.merchant_uid}- 관리자에게 문의하세요.`;
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_ORDER_FAIL",
          "구인구직_프리미엄주문결제실패",
          { rsp, errorMessage }
        );
        return;
      }

      order.current.payUid = rsp.imp_uid;
    } else {
      setLoading(false);
      waitingPopup.hide();
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_ORDER_FAIL",
        "구인구직_프리미엄주문결제실패",
        { rsp, errorMessage }
      );
      return;
    }

    let result = await fetchAPI(
      API.SALE_ORDER_PAY_CHECK,
      "",
      {
        id: orderId,
        imp_uid: order.current.payUid,
        merchant_uid: order.current.payMerchantId,
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      order.current.paidAmount = result.data.paidAmount;
      order.current.payStatus = result.data.payStatus;
      order.current.payApplyNumber = result.data.payApplyNumber;
      order.current.payRecieptUrl = result.data.payRecieptUrl;

      let results = await fetchAPI(
        API.JOBPOST_PREMIUM_ORDER_UPDATE,
        "",
        null,
        order.current,
        getGlobal(GlobalKey.TOKEN)
      );
      if (results && !results.error) {
      } else {
        alert("주문 실패 : 약문약답 카카오플러스친구로 연락주세요.");
        setLoading(false);
        waitingPopup.hide();
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_ORDER_FAIL",
          "구인구직_프리미엄주문결제실패",
          {}
        );
        return;
      }

      var msg = "결제가 완료되었습니다.";
      msg += "고유ID : " + rsp.imp_uid;
      msg += " 상점 거래ID : " + rsp.merchant_uid;
      msg += " 결제 금액 : " + rsp.paid_amount;
      msg += " 카드 승인번호 : " + rsp.apply_num;
      log(LogLevel.UI_ACTION, "onOrderDoneWeb:success", rsp, msg);
      onOrderSuccess();
      // this.props.history.replace("/sale/order/done?id=" + orderId);
    } else {
      alert("주문 실패 ERRORCODE 2: 약문약답 카카오플러스친구로 연락주세요.");
      setLoading(false);
      waitingPopup.hide();
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_ORDER_FAIL",
        "구인구직_프리미엄주문결제실패",
        {}
      );
      return;
    }
  };

  const onOrderSuccess = async () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_ORDER_SUCCESS",
      "구인구직_프리미엄주문_결재완료",
      { order }
    );

    waitingPopup.hide();
    setLoading(false);
    setPayDone(true);
  };

  log(LogLevel.UI_LIFECYCLE, "JobPostPremiumOrdern page draw", location);

  if (!offer) return null;
  return (
    <JobPostPremiumOrderTemplate
      offer={offer}
      loading={loading}
      onBack={onBack}
      onOrder={onOrder}
      payDone={payDone}
      onFinalize={onFinalize}
      coupon={coupon}
    />
  );
};

export default withRouter(JobPostPremiumOrder);
