import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "./../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonModal,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonFooter,
  IonSpinner,
} from "@ionic/react";
import "./ProductOrder.css";
import "./../../pages/ProductSaleOrder.css";
import {
  OrderInfo,
  ProductInfo,
  ProductStatus,
} from "./../../models/Model.Sale";
import { log, LogLevel } from "./../../utils/LogUtil";
import { UIPopupType } from "./../../store/ui/types";
import SwipeableViews from "react-swipeable-views";
import ReactHtmlParser from "react-html-parser";
import StringUtil from "./../../utils/StringUtil";
import { KeywordType } from "./../../models/Model.Medicine";
import ComponentGenerator from "./../../ivcomponent/ComponentGenerator";
import LinkMeta from "./../../components/LinkMeta";
import drugIcon from "./../../assets/icon/drug.svg";
import { MY_ADDRESS } from "./../../config.json";
import { Reference } from "./../../models/Model.Board";
import { getGlobal, GlobalKey } from "./../../utils/GlobalUtil";
import NumberSelector from "../NumberSelector";
import { UserInfo, UserWorkType } from "../../models/Model.User";
import Truncate from "react-truncate";
import UserUtil from "../../utils/UserUtil";

type Props = {
  // product: ProductInfo;
  onCancel: () => void;
  me: UserInfo;
  order: OrderInfo;
  paying: boolean;
  onClickChangeAddress: () => void;
  onClickChangePharmacyRegistration: () => void;
  onCountChanged: (count: number) => void;
  onOrder: () => void;
};

type State = {};
const windowAny: any = window;

class ProductOrder extends Component<Props, State> {
  onProgress: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "ProductOrder:goBack");
    this.props.onCancel();
  };

  onClickChangeAddress = () => {
    this.props.onClickChangeAddress();
  };

  onClickChangeRegistration = () => {
    this.props.onClickChangePharmacyRegistration();
  };

  onOrder = async () => {
    log(LogLevel.UI_ACTION, "ProductOrder:onOrder");
    this.props.onOrder();
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductOrder.render");
    if (!this.props.order || !this.props.order.product) return null;

    let button = "주문하기";
    // if(this.state.stock == 0)
    //   button = "품절"
    // else if(this.state.stock < 0)
    //   button = "준비중"

    let saleElement;
    if (
      this.props.order.product.priceOriginal &&
      this.props.order.product.priceOriginal != this.props.order.product.price
    ) {
      let sale = Math.floor(
        ((this.props.order.product.priceOriginal -
          this.props.order.product.price) *
          100) /
          this.props.order.product.priceOriginal
      );
      saleElement = (
        <div className="product-order-information-discount">
          {sale}%{" "}
          <span className="product-order-information-discount-original">
            {this.props.order.product.priceOriginal.toLocaleString()}원
          </span>
        </div>
      );
    }

    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.goBack}>
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="common-header-title" onClick={this.goBack}>
                주문하기
              </IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="common-content-with-header common-background-white common-scroll product-order-container">
          <div className="product-order-title">주문 정보</div>
          <div className="product-order-information-container">
            <div className="product-order-information-title">
              <Truncate lines={1} ellipsis="...">
                <span className="common-bold">
                  {" "}
                  {this.props.order.product.name}
                </span>{" "}
                {this.props.order.product.description
                  ? " - " + this.props.order.product.description
                  : ""}
              </Truncate>
            </div>
            {saleElement}
            <div className="product-order-information-price">
              {this.props.order.product.price.toLocaleString()}원
            </div>
            <div
              className="common-container-row common-flex-align-center"
              style={{ padding: "10px 0px" }}
            >
              <div className="common-flex-grow" />
              <div
                className="product-order-information-count-text"
                style={{ marginRight: "10px" }}
              >
                수량
              </div>
              <NumberSelector
                value={this.props.order.amount}
                onChange={(count) => {
                  this.props.onCountChanged(count);
                }}
              />
              <div
                className="product-order-information-count-text"
                style={{ marginLeft: "10px" }}
              >
                개
              </div>
            </div>
            <div
              className="common-container-row common-flex-align-center"
              style={{ padding: "10px 0px" }}
            >
              <div className="product-order-information-price-total-title">
                총 금액
              </div>
              <div className="common-flex-grow" />
              <div className="product-order-information-price-total-container">
                <div className="product-order-information-price">
                  {(
                    this.props.order.product.price * this.props.order.amount +
                    this.props.order.product.deliveryFee
                  ).toLocaleString()}
                  원
                </div>
                <div className="product-order-information-price-total-information">
                  수량 {this.props.order.amount}개{" "}
                  {this.props.order.product.deliveryFee
                    ? `/ 배송료 ${this.props.order.product.deliveryFee.toLocaleString()}원 포함`
                    : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="product-order-title">배송 정보</div>
          <div className="product-order-information-container">
            <div className="common-container-row common-flex-align-center product-order-information-title">
              <div className="common-bold common-flex-grow">수령인</div>
              <div className="common-flex-grow">
                {this.props.order.recipientName}
              </div>
              <div className="common-bold common-flex-grow">전화번호</div>
              <div className="common-flex-grow">
                {UserUtil.getPhoneNumberString(this.props.order.recipientPhone)}
              </div>
              <IonButton
                color="common-button"
                class="product-order-recipient-change-button"
                onClick={this.onClickChangeAddress}
              >
                변경
              </IonButton>
            </div>
            <div className="common-container-row common-flex-align-center product-order-information-title">
              <div
                className="common-bold common-flex-grow"
                style={{ minWidth: "50px", whiteSpace: "nowrap" }}
              >
                주소
              </div>
              {this.props.order.recipientAddress1 ? (
                <div className="common-flex-grow">
                  {this.props.order.recipientAddress1}{" "}
                  {this.props.order.recipientAddress2}
                </div>
              ) : (
                [
                  <IonButton
                    key="0"
                    color="common-button"
                    class="product-order-delivery-change-button"
                    onClick={this.onClickChangeAddress}
                  >
                    배송지 주소 입력하기
                  </IonButton>,
                  <div key="1" className="common-flex-grow-2" />,
                ]
              )}
            </div>
          </div>
          {this.props.me &&
            this.props.me.workType == UserWorkType.PHARMACY_OWNER &&
            this.props.order.product.pharmacyOnly == true && (
              <div className="product-order-title">약국 인증</div>
            )}
          {this.props.me &&
            this.props.me.workType == UserWorkType.PHARMACY_OWNER &&
            this.props.order.product.pharmacyOnly == true && (
              <div className="product-order-information-container">
                <div className="common-container-row common-flex-align-center product-order-information-title">
                  <div className="common-bold common-flex-grow">약국명</div>
                  <div className="common-flex-grow">
                    {this.props.me.workPlaceName}
                  </div>
                  <div className="common-bold common-flex-grow">대표자</div>
                  <div className="common-flex-grow">{this.props.me.name}</div>
                </div>
                <div className="common-container-row common-flex-align-center product-order-information-title">
                  <div
                    className="common-bold common-flex-grow"
                    style={{ minWidth: "100px" }}
                  >
                    사업자 등록번호
                  </div>
                  {this.props.me.workPlaceRegistration ? (
                    <div className="common-flex-grow">
                      {this.props.me.workPlaceRegistration}
                    </div>
                  ) : (
                    <div className="common-flex-grow-2 product-order-delivery-no-info">
                      미입력
                    </div>
                  )}
                </div>
                <IonButton
                  key="0"
                  color="common-button"
                  class="product-order-registration-change-button"
                  onClick={this.onClickChangeRegistration}
                >
                  사업자 인증정보 입력하기
                </IonButton>
              </div>
            )}
          {this.props.order.product.company && (
            <div className="product-order-guide-container">
              회원 본인은 상기 주문 내역과 반품 및 재판매 주의사항을
              확인하였으며, <br />
              <br />
              다음과 같이 개인정보 위탁 업무에 동의합니다. <br />
              <br />- 위탁받는 자 (수탁자) : {
                this.props.order.product.company
              }{" "}
              <br />- 위탁하는 업무의 내용 : 결제 상품 주문 업무/배송 및 설치
              업무 <br />
            </div>
          )}

          <IonButton
            color="common-button"
            class="product-order-buy-button"
            onClick={this.onOrder}
            disabled={this.props.paying}
          >
            동의 및 결제하기 {this.props.paying && <IonSpinner />}
          </IonButton>
        </div>
      </>
    );
  }
}

export default ProductOrder;
