import styled from "styled-components";

import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";
import {
  ToggleButtonColor,
  ToggleButtonSize,
  ToggleButtonVariant,
} from "./ToggleButton";

interface ToggleButtonWrapperProps {
  color: ToggleButtonColor;
  size: ToggleButtonSize;
  borderRadius: string;
  variant: ToggleButtonVariant;
  disabled?: boolean;
  disabledColor?: boolean;

  left?: boolean;
  right?: boolean;
  justfyContents?: string;
  active?: boolean;
}

const ToggleButtonWrapper = styled.button<ToggleButtonWrapperProps>`
  height: ${({ size }) => {
    switch (size) {
      case "Large":
        return "48px";
      case "Medium":
        return "40px";
      case "Small":
      default:
        return "32px";
    }
  }};
  background-color: ${({ color, active, variant }) => {
    if (active) {
      if (variant === "Tinted" || variant === "OutlinedTinted") {
        switch (color) {
          case "Black":
            return COLOR_SYSTEM.get("Gray")[900];
          case "Red":
          case "Yellow":
          case "Skyblue":
            return COLOR_SYSTEM.get(color)[10];
          case "Gray":
          default:
            return COLOR_SYSTEM.get(color)[20];
        }
      } else if (variant === "Outlined") {
        return COLOR_SYSTEM.get("Gray")[0];
      } else {
        return "transparent";
      }
    } else {
      if (variant === "Tinted" || variant === "OutlinedTinted") {
        switch (color) {
          case "Black":
            return COLOR_SYSTEM.get("Gray")[0];
          default:
            return COLOR_SYSTEM.get("Gray")[10];
        }
      } else if (variant === "Outlined") {
        return COLOR_SYSTEM.get("Gray")[0];
      } else {
        return "transparent";
      }
    }
  }};

  // Font
  ${({ size }) => {
    switch (size) {
      case "Large":
        return typography.get("InputLarge");
      case "Medium":
        return typography.get("InputMedium");
      case "Small":
      default:
        return typography.get("InputSmall");
    }
  }};

  color: ${({ color, active }) => {
    if (active) {
      switch (color) {
        case "Red":
          return COLOR_SYSTEM.get(color)[300];
        case "Yellow":
        case "Skyblue":
        case "Blue":
          return COLOR_SYSTEM.get(color)[400];
        case "Black":
          return COLOR_SYSTEM.get("Gray")[0];
        case "Gray":
        default:
          return COLOR_SYSTEM.get(color)[700];
      }
    } else {
      return COLOR_SYSTEM.get("Gray")[400];
    }
  }};

  // Border
  border: ${({ color, variant, active }) => {
    if (variant === "Outlined" || variant === "OutlinedTinted") {
      if (active) {
        switch (color) {
          case "Red":
            return `1px solid ${COLOR_SYSTEM.get(color)[300]}`;
          case "Yellow":
          case "Skyblue":
            return `1px solid ${COLOR_SYSTEM.get(color)[400]}`;
          case "Black":
            return `1px solid ${COLOR_SYSTEM.get("Gray")[900]}`;
          case "Gray":
          default:
            return `1px solid ${COLOR_SYSTEM.get(color)[700]}`;
        }
      } else {
        return `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`;
      }
    } else {
      return "none";
    }
  }};
  border-radius: ${({ size, borderRadius }) => {
    if (borderRadius) return borderRadius;
    switch (size) {
      case "Large":
      case "Medium":
        return "8px";
      case "Small":
      default:
        return "4px";
    }
  }};
  transform: translateZ(0);

  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  opacity: ${({ disabled, disabledColor }) => {
    if (disabled && disabledColor) return "0.3";
    return "1";
  }};

  .ripple {
    display: block;
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    animation: 1s ripple linear;
    transform: scale(0);
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(8);
    }
  }
`;

export const ToggleIconButtonWrapper = styled(ToggleButtonWrapper)`
  padding: ${({ size }) => {
    switch (size) {
      case "Large":
        return "10px";
      case "Medium":
        return "8px";
      case "Small":
      default:
        return "6px";
    }
  }};

  min-width: ${({ size }) => {
    switch (size) {
      case "Large":
        return "48px";
      case "Medium":
        return "40px";
      case "Small":
      default:
        return "32px";
    }
  }};
  justify-content: center;
`;

export const ToggleTextButtonWrapper = styled(ToggleButtonWrapper)`
  padding: ${({ size }) => {
    switch (size) {
      case "Large":
        return "11.5px 16px";
      case "Medium":
        return "8.5px 12px";
      case "Small":
      default:
        return "3.5px 8px";
    }
  }};
  justify-content: center;
`;

export const ToggleIconWithTextButtonWrapper = styled(ToggleButtonWrapper)`
  padding: ${({ size, left, right }) => {
    if (left) {
      switch (size) {
        case "Large":
          return "11.5px 16px 11.5px 10px";
        case "Medium":
          return "8.5px 12px 8.5px 8px";
        case "Small":
        default:
          return "3.5px 8px 3.5px 6px";
      }
    } else if (right) {
      switch (size) {
        case "Large":
          return "11.5px 10px 11.5px 16px";
        case "Medium":
          return "8.5px 8px 8.5px 12px";
        case "Small":
        default:
          return "3.5px 6px 3.5px 8px";
      }
    }
  }};
  justify-content: ${({ justfyContents }) =>
    justfyContents ? justfyContents : "space-between"};

  .right-button__icon {
    margin-left: ${({ size }) => {
      switch (size) {
        case "Large":
          return "10px";
        case "Medium":
          return "8px";
        case "Small":
        default:
          return "6px";
      }
    }};
  }

  .left-button__icon {
    margin-right: ${({ size }) => {
      switch (size) {
        case "Large":
          return "10px";
        case "Medium":
          return "8px";
        case "Small":
        default:
          return "6px";
      }
    }};
  }
`;
