import React from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { format } from "date-fns";
import {
  IonLabel,
  IonButton,
  IonIcon,
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonAlert,
} from "@ionic/react";
import "./BoardContentListNoticeItem.css";
import qustionIcon from "./../assets/image/question.png";
import { getDateTimeString } from "../utils/TimeUtil";
import commentIcon from "./../assets/icon/comment.svg";
import viewIcon from "./../assets/icon/view.svg";
import scappedIcon from "./../assets/image/scrapped.png";
import unscappedIcon from "./../assets/image/unscrapped.png";
import { BoardContent } from "../models/Model.Board";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "../API.json";
import { BoardType } from "../store/board/types";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import Truncate from "react-truncate";

type Props = RouteComponentProps & {
  key: string;
  content: BoardContent;
  index: number;
  showDate?: boolean;
  onClick?: (id) => void;
};

type States = {};

class BoardContentListNoticeItem extends React.Component<Props, States> {
  state = {};

  constructor(props: Props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.content &&
      (!this.props.content || this.props.content.id != nextProps.content.id)
    )
      return true;
    return false;
  }

  navigateToView = () => {
    if (this.props.onClick) this.props.onClick(this.props.content.id);
    this.props.history.push(`/boards/view?id=${this.props.content.id}`);
  };

  render() {
    let posting: BoardContent = this.props.content;

    if (!posting || !posting.id) return null;
    let title = "제목없음";
    if (posting.subject.length > 0) title = posting.subject;

    let icon = "arrow-down";
    let body;
    let titleStyle = "boardcontentlist-notice-item-title";

    let date;
    if (this.props.showDate) {
      date = (
        <div className="boardcontentlist-notice-item-date">
          {getDateTimeString(posting.createdAt)}
        </div>
      );
    }

    return (
      <div
        className="boardcontentlist-notice-item-container"
        onClick={this.navigateToView}
      >
        <div className="boardcontentlist-notice-item-head-container">
          <div className="boardcontentlist-notice-item-head">
            {date}
            <div className={titleStyle}>{title}</div>
          </div>
        </div>
        {body}
      </div>
    );
  }
}
export default withRouter(BoardContentListNoticeItem);
