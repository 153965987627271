import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Menu from "./Menu";
import { IonApp, IonSplitPane, IonPage } from "@ionic/react";
import { Provider } from "react-redux";
import Dashboard from "./Dashboard";
import UserManage from "./UserManage";
import BoardManage from "./BoardManage";
import { Redirect } from "react-router";
import MedicalSideeffect from "./MedicalSideeffect";
import MedicalATC from "./MedicalATC";
import { LogLevel, log } from "../utils/LogUtil";
import NotificationManage from "./NotificationManage";
import DailyDigest from "./DailyDigest";
import DevRedirect from "./DevRedirect";
import { PrivateRoute } from "../utils/Routing";
import NewComposer from "./NewComposer";
import EventManage from "./EventManage";
import BoardsManage from "./BoardsManage";
import EventInviteRank from "./EventInviteRank";
import MedicalIngredient from "./MedicalIngredient";
import MedicalMedicine from "./MedicalMedicine";
import UserStatistics from "./UserStatistics";
// import ProductManage from './ProductManage';
import OrderManage from "./OrderManage";
// import TempOrderManage from './TempOrderManage';
import BoardsStatistics from "./BoardsStatistics";
import KeyMeasureStatistics from "./KeyMeasureStatistics";
import SeminarManage from "./SeminarManage";
import BannerManage from "./BannerManage";
import BoardHolytreeNew from "./BoardHolytreeNew";
import VideoPlayer from "../poc/VideoPlayer";
import Recommend from "../poc/Recommend";
import UserWorkPlacePositionUpdater from "./UserWorkPlacePositionUpdater";
import PollManage from "./PollManage";
import DailyPharmRecruitMain from "./DailyPharmRecruitMain";
import JobpostManage from "./JobpostManage";
import SaleProductManage from "./SaleProductManage";
import SaleOrderManage from "./SaleOrderManage";
import SearchKeywordManage from "./SearchKeywordManage";
import EventInvite from "./EventInvite";
import BoardContentMoveManage from "./BoardContentMoveManage";
import Boards from "../pages/Boards";
import WeeklyDigest from "./WeeklyDigest";
import AllInOnePartnerManage from "./AllInOnePartnerManage";
import BizCouponManage from "./BizCouponManage";
import DailyPharmRecruitBizMain from "./DailyPharmRecruitBizMain";
import AllInOneOrderManage from "./AllInOneOrderManage";
import PremiumUserManage from "./PremiumUserManage";
import BoardSummaryManage from "./BoardSummaryManage";
import CcUserManage from "./CcUserManage";
import InappManager from "./InappManager";
import BoardQuizManage from "./BoardQuizManage";
import BizJobpostManage from "./BizJobpostManage";

const globalAny: any = global;
const $: any = globalAny.$;

class App extends React.Component {
  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "Admin.App:componentDidMount");
    setTimeout(() => {
      $("ion-app.ion-page").addClass("admin");
    }, 200);
  }

  componentWillUnmount() {
    log(LogLevel.UI_LIFECYCLE, "Admin.App:componentWillUnmount");
    $("ion-app.ion-page").removeClass("admin");
    document.title = "약문약답";
  }

  render() {
    return (
      <IonSplitPane contentId="main">
        <Menu />
        <IonPage id="main">
          <Route
            exact
            path="/admin"
            render={() => <Redirect to="/admin/user" />}
          />
          <Switch>
            <Route path="/admin/dashboard" component={Dashboard} />
            <Route path="/admin/user" component={UserManage} />
            {/* <Route path="/admin/board/holytreenew" component={BoardHolytreeNew} /> */}
            <Route exact path="/admin/boards" component={BoardsManage} />
            <Route
              exact
              path="/admin/board/move"
              component={BoardContentMoveManage}
            />
            <Route exact path="/admin/board" component={BoardManage} />
            <Route
              exact
              path="/admin/notification"
              component={NotificationManage}
            />
            <Route exact path="/admin/digest" component={WeeklyDigest} />
            <Route exact path="/admin/redirect" component={DevRedirect} />
            <Route
              exact
              path="/admin/allinone/purchase"
              component={AllInOneOrderManage}
            />
            <Route
              exact
              path="/admin/allinone"
              component={AllInOnePartnerManage}
            />
            <Route exact path="/admin/cc" component={CcUserManage} />
            <Route exact path="/admin/inapp" component={InappManager} />
            <Route path="/admin/newcomposer" component={NewComposer} />
            <Route path="/admin/invite" component={EventInvite} />
            <Route path="/admin/event/invite" component={EventInviteRank} />
            <Route path="/admin/event" component={EventManage} />
            <Route
              path="/admin/statistics/key"
              component={KeyMeasureStatistics}
            />
            <Route path="/admin/statistics/user" component={UserStatistics} />
            <Route
              path="/admin/statistics/boards"
              component={BoardsStatistics}
            />
            <Route path="/admin/seminar" component={SeminarManage} />
            <Route path="/admin/search" component={SearchKeywordManage} />
            <Route path="/admin/banner" component={BannerManage} />
            <Route path="/admin/lecturedemo" component={VideoPlayer} />
            <Route path="/admin/suggest" component={Recommend} />
            <Route path="/admin/poll" component={PollManage} />
            <Route path="/admin/dailypharm" component={DailyPharmRecruitMain} />
            <Route
              path="/admin/dailypharm-biz"
              component={DailyPharmRecruitBizMain}
            />
            <Route path="/admin/jobpost" component={JobpostManage} />
            <Route path="/admin/bizjobpost" component={BizJobpostManage} />
            <Route path="/admin/sale/product" component={SaleProductManage} />
            <Route path="/admin/sale/order" component={SaleOrderManage} />
            <Route path="/admin/biz/coupon" component={BizCouponManage} />
            <Route path="/admin/premium" component={PremiumUserManage} />
            <Route path="/admin/board/summary" component={BoardSummaryManage} />
            <Route path="/admin/board/quiz" component={BoardQuizManage} />
            {/* <Route exact path="/admin/medicine/atc" component={MedicalATC}/>
            <Route exact path="/admin/medicine/sideeffect" component={MedicalSideeffect}/>
            <Route exact path="/admin/medicine/ingredient" component={MedicalIngredient}/>
            <Route exact path="/admin/medicine/medicine" component={MedicalMedicine}/> */}
            {/* <Route path="/admin/userposition" component={UserWorkPlacePositionUpdater}/> */}
            {/* <Route path="/admin/order" component={OrderManage}/> */}
            {/* <Route path="/admin/temp/order" component={TempOrderManage}/> */}
            {/* <PrivateRoute path='/account' component={Account} />
            <Route path="/tutorial" component={Tutorial} />
            <Route path="/logout" />
            <RequiresTutorialRoute path="/login" component={Login} />
            <RequiresTutorialRoute path="/support" component={Support} />
            <RequiresTutorialRoute path="/signup" component={Signup} />
            <RequiresTutorialRoute path="/" component={AppStack} /> */}
          </Switch>
        </IonPage>
      </IonSplitPane>
    );
  }
}

export default App;
