const Lv1 = `
  box-shadow: 0px 1px 2px rgba(160, 174, 192, 0.12), 0px 1px 6px 1px rgba(160, 174, 192, 0.16), 0px 1px 8px 1px rgba(160, 174, 192, 0.16);
`
const Lv2 = `
  box-shadow: 0px 3px 12px rgba(160, 174, 192, 0.1), 0px 4px 20px 2px rgba(160, 174, 192, 0.08), 0px 2px 28px 3px rgba(160, 174, 192, 0.06);
`
const Lv3 = `
  box-shadow: 0px 5px 8px -4px rgba(160, 174, 192, 0.15), 0px 8px 17px 2px rgba(160, 174, 192, 0.15), 0px 2px 22px 4px rgba(160, 174, 192, 0.06);
`
const Lv4 = `
  box-shadow: 0px 2px 6px rgba(33, 43, 67, 0.12), 0px 6px 12px 1px rgba(33, 43, 67, 0.06), 0px 10px 20px 2px rgba(33, 43, 67, 0.04);
`


const ELEVATION = {
  Lv1,
  Lv2,
  Lv3,
  Lv4
}

export default ELEVATION;