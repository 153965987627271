import { IonButton, IonIcon } from "@ionic/react";
import React, { SyntheticEvent } from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import {
  clearGlobal,
  getGlobal,
  GlobalKey,
  setGlobal,
} from "../../../utils/GlobalUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import StringUtil from "../../../utils/StringUtil";
import Icon from "../Icon";
import "./../../../Common.css";
import "./style.css";

interface VideoHookEvent {
  progress: {};
}

export interface VideoAction {
  setSrc: (src: string) => void;
  getCurrentPlayTime: () => number;
  setSpeed: (number) => void;
  jumpTo: (number) => void;

  play: () => void;
  stop: () => void;
  isPlaying: () => boolean;
  subscribe: <K extends keyof VideoHookEvent>(
    eventName: K,
    listener: (event: VideoHookEvent[K]) => void
  ) => void;
  unsubscribe: <K extends keyof VideoHookEvent>(
    eventName: K,
    listener: (event: VideoHookEvent[K]) => void
  ) => void;
}

type Props = {
  src?: string;
  speed?: number;
  previewUrl?: string;
  startPlayTime?: number;
  className?: string;
  autoStart?: boolean;

  onReady?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onDuration?: (playtime: number) => void;
  onEnd?: () => void;
};

type State = {
  ready: boolean;
  playing: boolean;
  error: boolean;
};

class Video extends React.Component<Props, State> implements VideoAction {
  player: any = null;
  duration: number = 0;
  src: string = "";
  speed: number = 1;
  startPlayTime: number = 0;
  playingTimer = null;

  progressListeners: ((event: VideoHookEvent["progress"]) => void)[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      ready: false,
      playing: false,
      error: false,
    };
    if (this.props.src) this.src = this.props.src;
    if (this.props.speed) this.speed = this.props.speed;
    if (this.props.startPlayTime) this.startPlayTime = this.props.startPlayTime;
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "Video:componentDidMount");
    if (getGlobal(GlobalKey.OS) == "android")
      document
        .getElementById("video-player")
        .addEventListener("webkitfullscreenchange", this.onFullScreen);
    this.player.src = StringUtil.convertFilePath(this.src);
    this.player.currentTime = this.startPlayTime;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // if(prevProps.src != this.props.src)
    log(LogLevel.UI_EVENT, "Video:componentDidUpdate", prevState, this.state);
  }

  componentWillUnmount() {
    if (this.playingTimer) {
      clearInterval(this.playingTimer);
      this.playingTimer = null;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state != nextState) return true;

    if (
      this.props.src == nextProps.src &&
      (this.props.previewUrl == nextProps.previewUrl ||
        (!this.props.previewUrl && !nextProps.previewUrl))
    )
      return false;
    return true;
  }

  subscribe = <K extends keyof VideoHookEvent>(
    eventName: K,
    listener: (event: VideoHookEvent[K]) => void
  ) => {
    switch (eventName) {
      case "progress":
        const onScroll = listener as (
          event: VideoHookEvent["progress"]
        ) => void;
        if (!this.progressListeners.includes(onScroll))
          this.progressListeners.push(onScroll);
        return;
    }
  };

  unsubscribe = <K extends keyof VideoHookEvent>(
    eventName: K,
    listener: (event: VideoHookEvent[K]) => void
  ) => {
    let index = -1;
    switch (eventName) {
      case "progress":
        const onScroll = listener as (
          event: VideoHookEvent["progress"]
        ) => void;
        index = this.progressListeners.indexOf(onScroll);
        if (index >= 0)
          this.progressListeners = this.progressListeners.splice(index, 1);
        return;
    }
  };

  onPlayerReference = (ref) => {
    this.player = ref;
    let source = this.src;
    if (!source) source = this.props.src;
    log(
      LogLevel.UI_LIFECYCLE,
      "Video.onPlayerReference",
      ref,
      source,
      StringUtil.convertFilePath(source)
    );

    if (this.player) this.player.src = StringUtil.convertFilePath(source);
  };

  onFullScreen = (e) => {
    let documentAny: any = document;
    var isFullscreenNow = documentAny.webkitFullscreenElement !== null;
    if (
      getGlobal(GlobalKey.OS) == "android" &&
      screen.orientation &&
      screen.orientation.unlock &&
      screen.orientation.lock
    ) {
      if (isFullscreenNow) {
        screen.orientation.unlock();
      } else {
        screen.orientation.lock("portrait");
      }
    }
    log(
      LogLevel.UI_EVENT,
      "Video:onFullScreen",
      documentAny.webkitFullscreenElement,
      isFullscreenNow
    );
  };

  onDurationReady = () => {
    log(LogLevel.UI_EVENT, "Video:onDurationReady", this.player.duration);
    this.player.currentTime = this.startPlayTime;
    this.duration = this.player.duration;
    if (this.props.onDuration) this.props.onDuration(this.duration);
    this.setState({ ready: true });
  };

  onPlayerReady = () => {
    log(LogLevel.UI_EVENT, "Video:onPlayerReady");
    if (this.props.onReady) this.props.onReady();
  };

  onPlayerPlay = () => {
    log(LogLevel.UI_EVENT, "Video:onPlayerStart");
    this.setState({ playing: true });

    if (!this.playingTimer) {
      let playedBefore = 0;
      let playedCurrent = 0;
      this.playingTimer = setInterval(() => {
        playedCurrent += this.speed;
        if (this.duration) {
          if (playedCurrent - playedBefore > this.duration / 25) {
            log(
              LogLevel.UI_EVENT,
              "Video:onPlayerPlay:intervalTimeout",
              playedBefore,
              playedCurrent
            );

            playedBefore = playedCurrent;
            this.progressListeners.map((onProgress) => {
              onProgress({ progress: 4, time: this.player.currentTime });
            });
          }
        }
      }, 1000);
    }
    if (this.props.onPlay) this.props.onPlay();
  };

  onPlayerPause = () => {
    log(LogLevel.UI_EVENT, "Video:onPlayerPause");
    this.setState({ playing: false });
    if (this.playingTimer) {
      clearInterval(this.playingTimer);
      this.playingTimer = null;
    }
    if (this.props.onPause) this.props.onPause();
  };

  onPlayTimeUpdate = (seconds) => {
    // log(LogLevel.UI_LIFECYCLE, "Video:onPlayTimeUpdate", seconds);
  };

  onPlayerEnd = () => {
    log(LogLevel.UI_EVENT, "Video:onPlayerEnd");
    if (this.props.onEnd) this.props.onEnd();
  };

  onPlayerError = (e) => {
    log(LogLevel.UI_EVENT, "Video:onPlayerError", e.type);
  };

  play = () => {
    log(LogLevel.UI_EVENT, "Video:play");
    if (this.state.playing) {
      return;
    }
    this.player.play();
    this.setState({ playing: true });
  };

  stop = () => {
    log(LogLevel.UI_EVENT, "Video:stop");
    if (!this.state.playing) {
      return;
    }
    this.player.pause();
    this.setState({ playing: false });
  };

  jumpTo = (seconds: number) => {
    this.player.currentTime = seconds;
  };

  getCurrentPlayTime = () => {
    if (!this.player) return 0;
    return this.player.currentTime;
  };

  isPlaying = () => {
    return this.state.playing;
  };

  onEvent = (name, e: SyntheticEvent = null) => {
    log(
      LogLevel.UI_EVENT,
      "Video:onEvent",
      name,
      e.type,
      this.player.readyState,
      this.player.networkState,
      this.player.duration,
      this.player.ended,
      this.player.currentTime,
      this.player.buffered,
      this.player.src
    );
  };

  setSrc = (src) => {
    log(LogLevel.UI_ACTION, "Video:setSrc", src);
    if (this.src != src) {
      this.src = src;
      this.startPlayTime = this.player.currentTime;
      this.player.src = StringUtil.convertFilePath(this.src);
      if (this.state.playing) this.player.play();
    }
  };

  setSpeed = (speed) => {
    log(LogLevel.UI_ACTION, "Video:setSpeed", speed);
    if (this.speed == speed) return;
    this.speed = speed;
    this.player.playbackRate = speed;
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "Video:render",
      this.state,
      this.player,
      this.player && this.player.currentTime,
      this.props.previewUrl &&
        (!this.player || (!this.state.playing && !this.player.currentTime))
    );

    let thumbnail;
    if (this.props.previewUrl && this.state.error) {
      thumbnail = (
        <div
          className="common-content common-position-absolute"
          style={{ top: "0px", left: "0px" }}
        >
          <div className="common-content common-position-relative">
            <IonIcon class="video-play-icon" name="play" />
            <img
              className="common-container"
              src={StringUtil.convertFilePath(this.props.previewUrl)}
            />
          </div>
        </div>
      );
    } else if (
      this.props.previewUrl &&
      (!this.player || (!this.state.playing && !this.player.currentTime))
    ) {
      thumbnail = (
        <div
          className="common-content common-position-absolute"
          style={{ top: "0px", left: "0px" }}
          onClick={() => this.play()}
        >
          <div className="common-content common-position-relative">
            <div className="video-icon-container">
              <Icon className="video-icon" colorName="Primary" name="Play" />
            </div>
            <img
              className="common-container"
              src={StringUtil.convertFilePath(this.props.previewUrl)}
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className={
          "video-play-player-container" +
          (this.props.className ? " " + this.props.className : "")
        }
      >
        <video
          className="video-play-player"
          ref={this.onPlayerReference}
          id="video-player"
          poster={StringUtil.convertFilePath(this.props.previewUrl)}
          playsInline
          controlsList="nodownload"
          preload="auto"
          webkit-playsinline={1}
          controls={true}
          loop={false}
          onCanPlay={this.onPlayerReady}
          autoPlay={this.props.autoStart}
          // onCanPlayThrough={(e) => this.onEvent("onCanPlayThrough", e)}
          onPlay={this.onPlayerPlay}
          onPause={this.onPlayerPause}
          onEnded={this.onPlayerEnd}
          // onProgress={(e) => this.onEvent("onProgress", e)}
          onDurationChange={this.onDurationReady}
          // onSeeking={(e) => this.onEvent("onSeeking", e)}
          // onSeeked={(e) => this.onEvent("onSeeked", e)}
          // onWaiting={(e) => this.onEvent("onWaiting", e)}
          onAbort={(e) => this.onPlayerError}
          onError={(e) => this.onPlayerError}
          // onLoadStart={(e) => this.onEvent("onLoadStart", e)}
          onTimeUpdate={this.onPlayTimeUpdate}
        />
        {thumbnail}
      </div>
    );
  }
}

export default Video;
