import React from "react";
import useBottomSheet from "../hooks/useBottomSheet";
import { BottomSheetState } from "../store/modal/bottomsheet";

export interface WithBottomSheetProps {
  bottomSheet?: {
    show: (options?: Partial<BottomSheetState>) => void;
    close: () => void;
  };
}

export default function withBottomSheet<P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & WithBottomSheetProps> {
  return function ({ ...props }: WithBottomSheetProps) {
    const bottomSheet = useBottomSheet();

    return <Component bottomSheet={bottomSheet} {...(props as P)} />;
  };
}
