import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IonSpinner } from '@ionic/react'
import { SeminarLecture, SeminarLectureComment } from '../../../models/Model.Seminar';
import { Attachment } from '../../../models/Model.Board';
import Header from '../../molecule/Header';
import Body from '../../molecule/Body';
import StringUtil from '../../../utils/StringUtil';
import Scroll from '../../molecule/Scroll';
import { log, LogLevel } from '../../../utils/LogUtil';
import { ScrollAction } from '../../molecule/Scroll';
import Video from '../../atom/Video';
import VideoController from '../../cell/VideoController';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import IconButton from '../../atom/IconButton';
import Footer from '../../molecule/Footer';
import TextButton from '../../atom/TextButton';
import Badge from '../../atom/Badge';
import Text from '../../atom/Text';
import Icon from '../../atom/Icon';
import { extractTimeToJTag, extractTimeValue, getTimerString } from '../../../utils/TimeUtil';
import color from '../../_design/color';
import StarScore from '../../atom/StarScore';
import { TabBar } from '../../cell/TabBar';
import Spinner from '../../atom/Spinner';
import Avatar from '../../atom/Avatar';
import ReactHtmlParser from 'react-html-parser'
import SeminarViewReview from './SeminarViewReview';
import SeminarListItem from './SeminarListItem';
import COLOR from '../../_design/color';
import SeminarViewComment from './SeminarViewComment';


interface Props {
  seminar:SeminarLecture,
  onReply?: (comment: SeminarLectureComment) => void,
  onLike?: (comment: SeminarLectureComment, index) => void,
  onMore?: (comment: SeminarLectureComment, index) => void,
  commentToFocus?: number,
  onFocus?: (el) => void;
}
const SeminarViewComments: FC<Props> = ({
  seminar,
  onReply,
  onLike,
  onMore,
  commentToFocus,
  onFocus,
}) => {
    
  if(!seminar || (!seminar.comments && seminar.loading ))
    return (
      <Spinner/>
    )

  if(!seminar.commentCount){
    return (
      <>
        <div className="common-block-line" style={{marginBottom:"12px"}}>
          <Text type="H3" color="Secondary">
            아직 댓글이 없어요
          </Text>
        </div>
        <div className="common-block-line" style={{marginBottom:"12px"}}>
          <Text type="Body1" color="Gray3">
            이 컨텐츠는 어땠는지 댓글을 남겨주세요
          </Text>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="common-block-line" style={{marginBottom:"24px"}}>
        <Text type="H3" color="Secondary">
          {seminar.commentCount}개의 댓글이 있어요
        </Text>
      </div>
      { (seminar.comments && seminar.comments.length > 0) &&
        seminar.comments.map((comment, index) => {
          return (
            <div key={index.toString()} className="common-container" style={{marginBottom:"20px"}}>
              <SeminarViewComment 
                key={index.toString()} 
                commentToFocus={commentToFocus}
                onFocus={onFocus}
                index={index}
                comment={comment}
                onReply={onReply}
                onLike={onLike}
                onMore={onMore}
              />
            </div>
          )
        })

      }
    </>
  )
}


export default SeminarViewComments
