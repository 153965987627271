import React, { Component } from "react";
import "./ViewerComments.css";
import { BoardComment } from "./../models/Model.Board";
import { log, LogLevel } from "../utils/LogUtil";
import ProfileImage from "./ProfileImage";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import { loadImageBase64 } from "../utils/ImageUtil";
import Textarea from "react-textarea-autosize";
import { RootState } from "../store";
import { connect } from "react-redux";
import { UserLevel } from "../models/Model.User";
import { Flex, Static } from "./atoms/Layout";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import Button from "./atoms/Button";
import CheckBox from "./atoms/CheckBox/CheckBox";
import { IonButton, IonIcon } from "@ionic/react";
// import * as loadImage from 'blueimp-load-image';

const windowAny: any = window;

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    profile?: string;
    id?: number;
    groupId?: number;
    comment?: BoardComment;
    parentId?: number;
    depth?: number;
    onDone?: (BoardComment) => any;
    onCancel?: () => void;
    canAnonymous?: boolean;
    defaultAnonymous?: boolean;
  };

type States = {
  text: string;
  userCustomName: string;
  anonymous: boolean;
  imageBase64: string;
};

class ViewerCommentComposer extends Component<Props, States> {
  nameInput = null;
  idPrefix = "";

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ViewerCommentComposer.constructor", this.props);
    let text = "";
    let anonymous = !!this.props.defaultAnonymous;
    let userCustomName = this.props.me.nickname;
    if (this.props.comment) {
      text = this.props.comment.bodyText;
      anonymous = this.props.comment.isAnonymous;
      userCustomName = this.props.comment.usersCustomName;
    }
    this.state = {
      text,
      anonymous,
      userCustomName,
      imageBase64: "",
    };
    this.idPrefix = `input-${this.props.groupId}-${this.props.parentId}-${this.props.id}-`;
  }

  onTextchange = (e) => {
    log(
      LogLevel.UI_EVENT,
      "ViewerCommentComposer:onTextchange",
      e.target.value
    );
    let text = e.target.value;
    this.setState({ text: text });
  };

  onImageSelected = async (e) => {
    // console.dir(e);
    log(
      LogLevel.UI_ACTION,
      "ViewerCommentComposer:onImageSelected",
      e.target.files[0]
    );
    console.dir(e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0]).then((base64) => {
        this.onImageLoad(base64);
      });
    }
  };

  onImageLoad = async (base64) => {
    this.setState({ imageBase64: base64 });
  };

  onFileSelected = async (e) => {
    // console.dir(e);
    log(
      LogLevel.UI_ACTION,
      "ViewerCommentComposer:onFileSelected",
      e.target.files[0]
    );
    if (e.target.files[0].type.startsWith("image")) {
      this.onImageSelected(e);
      return;
    }
    const data = new FormData();
    data.append("file", e.target.files[0]);
    let name = e.target.files[0].name;
    let size = e.target.files[0].size;
    try {
      let result = await fetchAPI(
        API.UPLOAD_FILE,
        "board",
        null,
        data,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && result.message && result.message === "success") {
        let comment: BoardComment = {
          ...this.props.comment,
          bodyText: "",
          bodyHtml: "",
          isAnonymous: this.state.anonymous, // 익명 게시 여부
          usersCustomName:
            this.state.anonymous &&
            this.state.userCustomName !== this.props.me.nickname
              ? this.state.userCustomName
              : "",
          fileUrl: result.file,
          fileName: name.replace(/\?/g, ""),
          fileSize: size,
        };
        this.props.onDone(comment);
      } else {
        log(
          LogLevel.UI_ACTION,
          "ViewerCommentComposer:onFileSelected failed",
          result
        );
      }
    } catch (error) {
      log(
        LogLevel.UI_ACTION,
        "ViewerCommentComposer:onFileSelected : failed",
        error
      );
      // this.props.navigation.push("AuthRegister");
    }
  };

  onTextDone = async () => {
    if ((!this.state.text && !this.state.imageBase64) || !this.props.onDone)
      return;

    if (this.state.imageBase64) {
      let data: any = { path: "board" };

      data.base64 = this.state.imageBase64;
      try {
        let result = await fetchAPI(
          API.UPLOAD_BASE64,
          "",
          null,
          data,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error) {
          let comment: BoardComment = {
            ...this.props.comment,
            bodyText: "",
            bodyHtml: "",
            isAnonymous: this.state.anonymous, // 익명 게시 여부
            usersCustomName:
              this.state.anonymous &&
              this.state.userCustomName !== this.props.me.nickname
                ? this.state.userCustomName
                : "",
            imageUrl: result.file,
          };
          this.props.onDone(comment);
          this.setState({ imageBase64: "" });
        } else {
          log(
            LogLevel.UI_ACTION,
            "ViewerCommentComposer:onImageSelected failed",
            result
          );
        }
      } catch (error) {
        log(
          LogLevel.UI_ACTION,
          "ViewerCommentComposer:onImageSelected : failed",
          error
        );
        // this.props.navigation.push("AuthRegister");
      }
    } else if (this.state.text.length > 0) {
      let comment: BoardComment = {
        ...this.props.comment,
        isAnonymous: this.state.anonymous, // 익명 게시 여부
        usersCustomName:
          this.state.anonymous &&
          this.state.userCustomName !== this.props.me.nickname
            ? this.state.userCustomName
            : "",
        bodyText: this.state.text, // 본문 텍스트
      };
      this.props.onDone(comment);
      this.setState({ text: "" });
    }
  };

  onKeyPress = (e) => {
    log(
      LogLevel.UI_ACTION,
      "ViewerCommentComposer:onKeyPress",
      e.keyCode,
      e.shiftKey
    );
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.onTextDone();
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ViewerCommentComposer.render", this.props);
    let isAdmin: boolean =
      this.props.me && this.props.me.level >= UserLevel.MANAGER;

    let anonymous;
    if (this.props.canAnonymous) {
      anonymous = (
        // <div className="common-container-row common-flex-align-center viewer-comment-composer-anonymous-container">
        //   <div className="common-flex-grow" />
        //   <IonToggle
        //     class="viewer-comment-composer-anonymous-toggle"
        //     mode="ios"
        //     checked={this.state.anonymous}
        //     onClick={(e) => this.setState({ anonymous: !this.state.anonymous })}
        //   />
        //   <div
        //     className={
        //       this.state.anonymous
        //         ? "viewer-comment-composer-anonymous-button"
        //         : "viewer-comment-composer-anonymous-disabled"
        //     }
        //     onClick={(e) => this.setState({ anonymous: !this.state.anonymous })}
        //   >
        //     {" "}
        //     익명으로 작성{this.state.anonymous ? "중" : "하기"}{" "}
        //   </div>
        // </div>
        <CheckBox
          size="Small"
          checked={this.state.anonymous}
          onClick={() => this.setState({ anonymous: !this.state.anonymous })}
        >
          익명으로 작성{this.state.anonymous ? "중" : "하기"}
        </CheckBox>
      );
    }
    // else if (isAdmin) {
    //   anonymous = (
    //     // <div className="common-container-row common-flex-align-center">
    //     //   <div
    //     //     className={
    //     //       this.state.anonymous ? "viewer-comment-composer-button" : "viewer-comment-composer-button-disabled"
    //     //     }
    //     //   >
    //     //     {" "}
    //     //     익명{" "}
    //     //   </div>
    //     //   <IonCheckbox
    //     //     color="viewer-comment-composer-checkbox"
    //     //     checked={this.state.anonymous}
    //     //     onIonChange={(e) => {
    //     //       log(LogLevel.UI_EVENT, "IonCheckbox changed", e.detail.checked);
    //     //       this.setState({ anonymous: e.detail.checked });
    //     //     }}
    //     //   />
    //     //   <IonInput
    //     //     disabled={!this.state.anonymous}
    //     //     ref={(ref) => {
    //     //       this.nameInput = ref;
    //     //     }}
    //     //     class="viewer-comment-composer-name-input"
    //     //     value={this.state.userCustomName}
    //     //     onIonChange={(e) => this.setState({ userCustomName: e.detail.value })}
    //     //     onClick={(e) => {
    //     //       e.stopPropagation();
    //     //     }}
    //     //   />
    //     // </div>
    //     <CheckBox size="Small" checked={this.state.anonymous} onClick={() => {}}>
    //       익명 댓글
    //     </CheckBox>
    //   );
    // }

    let buttonImage, buttonFile;
    if (!this.state.text) {
      buttonImage = (
        <>
          <Button
            color="Tertiary"
            variant="Ghost"
            size="Large"
            type="Icon"
            icon="Image"
            onClick={() => {
              document.getElementById(this.idPrefix + "button-image").click();
            }}
          />
          <input
            id={this.idPrefix + "button-image"}
            type="file"
            accept="image/*"
            onChange={this.onImageSelected}
            style={{ display: "none" }}
          />
        </>
      );
      buttonFile = (
        <>
          <Button
            color="Tertiary"
            variant="Ghost"
            size="Large"
            type="Icon"
            icon="Clip"
            onClick={() => {
              document.getElementById(this.idPrefix + "button-file").click();
            }}
          />
          <input
            id={this.idPrefix + "button-file"}
            type="file"
            onChange={this.onFileSelected}
            style={{ display: "none" }}
          />
        </>
      );
    }

    let cancelButton;
    // if(this.props.onCancel){
    //   cancelButton = (
    //     <IonButton color="viewer-comment-composer-done" onClick={this.props.onCancel}>
    //       <IonIcon class="viewer-comment-composer-done-icon" name="close"/>
    //     </IonButton>
    //   );
    // }

    return (
      <Flex
        direction="column"
        gap="8px"
        customStyle={{
          paddingTop: "16px",
          paddingLeft: this.props.depth ? 25 * (this.props.depth - 1) : 0,
        }}
      >
        {anonymous}
        <div>
          {this.state.imageBase64 ? (
            <Flex
              alignItems="center"
              customStyle={{
                backgroundColor: COLOR_SYSTEM.get("Gray")[10],
                borderRadius: "12px",
                width: "100%",
                minHeight: "48px",

                "& > textarea": {
                  position: "relative",
                  // background: "#0000",
                  // backgroundColor: COLOR_SYSTEM.get("Gray")[10],
                  zIndex: 2,
                  width: "100%",
                  height: "auto",
                  border: "1px",
                  fontSize: "14px",
                  lineHeight: "22px",
                  padding: "5px 10px",
                  color: "#171717",
                  outlineStyle: "none",
                  resize: "none",
                },
              }}
            >
              <Flex
                alignItems="center"
                gap="8px"
                customStyle={{ flexGrow: 1, padding: "5px 20px" }}
              >
                <img
                  style={{
                    width: "48px",
                    height: "48px",
                    borderRadius: "12px",
                    objectFit: "cover",
                  }}
                  src={this.state.imageBase64}
                />
                <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
                  우측 "전송"버튼을 누르시면 이미지가 업로드됩니다.
                </Text>
              </Flex>

              <Button
                color="Primary"
                variant="Ghost"
                size="Large"
                type="Icon"
                icon="X"
                onClick={() => this.setState({ imageBase64: "" })}
              />
              <Button
                color="Primary"
                variant="Ghost"
                size="Large"
                type="Icon"
                icon="Paperplane Filled"
                onClick={this.onTextDone}
              />
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              customStyle={{
                backgroundColor: COLOR_SYSTEM.get("Gray")[10],
                borderRadius: "12px",
                width: "100%",
                minHeight: "48px",

                "& > textarea": {
                  position: "relative",
                  // background: "#0000",
                  // backgroundColor: COLOR_SYSTEM.get("Gray")[10],
                  zIndex: 2,
                  width: "100%",
                  height: "auto",
                  border: "1px",
                  fontSize: "14px",
                  lineHeight: "22px",
                  padding: "5px 10px",
                  color: "#171717",
                  outlineStyle: "none",
                  resize: "none",
                },
              }}
            >
              <Textarea
                placeholder="댓글을 입력해주세요"
                minRows={1}
                className="viewer-comment-composer-body"
                onChange={this.onTextchange}
                value={this.state.text}
                onKeyDown={this.onKeyPress}
              />
              {buttonImage}
              {buttonFile}
              {/* <IonButton color="viewer-comment-composer-done" onClick={this.onTextDone}>
                <IonIcon class="viewer-comment-composer-done-icon" name="arrow-up" />
              </IonButton> */}
              <Button
                color="Primary"
                variant="Ghost"
                size="Large"
                type="Icon"
                icon="Paperplane Filled"
                onClick={this.onTextDone}
              />
              {cancelButton}
            </Flex>
          )}
        </div>
      </Flex>

      // <>
      //   {anonymous}
      //   <div className="viewer-comment-container" style={{ paddingLeft: this.props.depth ? 25 * this.props.depth : 0 }}>
      //     <ProfileImage
      //       className="viewer-comment-profile"
      //       anonymous={this.state.anonymous}
      //       profileUrl={this.props.profile}
      //     />
      //     <div className="viewer-comment-composer">
      //       <Textarea
      //         placeholder="댓글을 입력해주세요"
      //         minRows={1}
      //         className="viewer-comment-composer-body"
      //         onChange={this.onTextchange}
      //         value={this.state.text}
      //         onKeyDown={this.onKeyPress}
      //       />
      //       {buttonImage}
      //       {buttonFile}
      //       <IonButton color="viewer-comment-composer-done" onClick={this.onTextDone}>
      //         <IonIcon class="viewer-comment-composer-done-icon" name="arrow-up" />
      //       </IonButton>
      //       {cancelButton}
      //     </div>
      //   </div>
      // </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewerCommentComposer);
