import { StateType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchSearchMiddleware } from "./search/middleware";
import { fetchUserMiddleware } from "./user/middleware";
import { fetchMedicineMiddleware } from "./medicine/middleware";
import { fetchBoardMiddleware } from "./board/middleware";
import { fetchNotificationMiddleware } from "./notification/middleware";
import { fetchEventMiddleware } from "./event/middleware";
import { fetchSeminarMiddleware } from "./seminar/middleware";
import { fetchBannerMiddleware } from "./banner/middleware";
import { fetchGamificationMiddleware } from "./gamification/middleware";
import { fetchJobPostMiddleware } from "./jobpost/middleware";
import { fetchAllInOneMiddleware } from "./allinone/middleware";
import { fetchSaleMiddleware } from "./sale/middleware";
import { searchMiddleware } from "./search";

import rootReducer from "./root-reducer";

import * as userActions from "./user/actions";
import * as searchActions from "./search/actions";
import * as medicineActions from "./medicine/actions";
import * as boardActions from "./board/actions";
import * as notificationActions from "./notification/actions";
import * as uiActions from "./ui/actions";
import * as eventActions from "./event/actions";
import * as seminarActions from "./seminar/actions";
import * as bannerActions from "./banner/actions";
import * as gamificationActions from "./gamification/actions";
import * as jobpostActions from "./jobpost/actions";
import * as saleActions from "./sale/actions";
import * as allinoneActions from "./allinone/actions";

export { default } from "./store";
export { default as rootReducer } from "./root-reducer";

export const actions = {
  search: searchActions,
  user: userActions,
  medicine: medicineActions,
  board: boardActions,
  notification: notificationActions,
  ui: uiActions,
  event: eventActions,
  seminar: seminarActions,
  banner: bannerActions,
  gamification: gamificationActions,
  jobpost: jobpostActions,
  sale: saleActions,
  allinone: allinoneActions,
};

export const middlewares: Middleware[] = [
  fetchSearchMiddleware,
  fetchUserMiddleware,
  fetchMedicineMiddleware,
  fetchBoardMiddleware,
  fetchNotificationMiddleware,
  fetchEventMiddleware,
  fetchSeminarMiddleware,
  fetchBannerMiddleware,
  fetchGamificationMiddleware,
  fetchJobPostMiddleware,
  fetchSaleMiddleware,
  fetchAllInOneMiddleware,
  searchMiddleware,
];

export type RootState = StateType<typeof rootReducer>;
