import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import "./../../../Common.css";
import "./style.scss";
import Button from "../../atoms/Button";
import { BoardContent } from "../../../models/Model.Board";
import PostListItem from "../../organisms/AllInOne/PostListItem";
import { Fixed, Header } from "../../atoms/Layout";
import FloatingButton from "../../atoms/FloatingButton";
import Body from "../../cell/Body";

export interface AllInOneIntroductionProps {
  onRecommendPartner: () => void;
  onGoBack: () => void;
}

const windowAny: any = window;

const AllInOneIntroduction: React.FC<
  AllInOneIntroductionProps & RouteComponentProps
> = ({ onRecommendPartner, onGoBack }) => {
  try {
    log(LogLevel.UI_LIFECYCLE, "AllInOneIntroductionTemplate:");

    useEffect(() => {
      log(LogLevel.UI_LIFECYCLE, "AllInOneIntroductionTemplate:useEffect");
      windowAny.AOS.init();

      document.addEventListener("aos:in", (e) => {
        console.log("animated in", e);
      });

      document.addEventListener("aos:out", (e) => {
        console.log("animated out", e);
      });
    }, []);

    return (
      <div className="common-content common-position-relative allinone-introduction">
        <Header>
          <TopAppBar
            title="약사파트너스란?"
            titleType="H4"
            leftButtons={[
              <Button
                color="Tertiary"
                icon="X"
                onClick={onGoBack}
                size="Medium"
                type="Icon"
                variant="Ghost"
              />,
            ]}
          />
        </Header>
        <Body>
          <main>
            <section className="questions">
              <div className="qbox q-01">약국 인테리어, 어디가 좋을까?</div>
              <div className="qbox q-02">자금마련은 어떻게?</div>
              <div className="qbox q-03">세무.. 너무 어려워..</div>
              <div className="qbox q-04">약국 체인으로 시작하면 어떨까?</div>
              <div className="qbox q-05">앗..! 약화사고가 났다!</div>
              <div className="qbox q-06">연말정산은 어떻게?</div>
              <div className="dots">
                <p>.</p>
                <p>.</p>
                <p>.</p>
              </div>
            </section>

            <section className="description">
              <h1 data-aos="fade-in">
                이 모든 고민을 <span className="emphasis">한번에 해결</span>
                해드릴게요!
              </h1>
              <article data-aos="fade-in">
                <div className="img-box">
                  <img src="/images/img_01.png" />
                </div>
                <h2>
                  근약부터 개국까지 -<br />
                  약사 생활에 필요한 모든 것
                </h2>
                <p>
                  여기저기 흩어져있는 업체 정보들을
                  <br />
                  약문약답 약사파트너스 한 곳에서 만나보세요!
                </p>
              </article>
              <h1 data-aos="fade-in">
                약사 생활에 필요한
                <br />
                <span className="emphasis">
                  모든 서비스를 한 곳에서 -<br />
                  약문약답 약사파트너스!
                </span>
              </h1>
            </section>
          </main>

          <section className="recommend" data-aos="fade-in">
            <h1>업체를 추천해주세요!</h1>
            <article>
              <ul>
                <li>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_consultant.png" />
                    <h6>개국컨설팅</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_bank.png" />
                    <h6>대출</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_tax.png" />
                    <h6>세무</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_pharmacy.png" />
                    <h6>프랜차이즈</h6>
                  </div>
                </li>
                <li>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_interior.png" />
                    <h6>인테리어</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_law.png" />
                    <h6>법률</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_labor.png" />
                    <h6>노무</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_computer.png" />
                    <h6>EMR•IT</h6>
                  </div>
                </li>
                <li>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_academic.png" />
                    <h6>약사학회</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_auto.png" />
                    <h6>자동화기기</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_insurance.png" />
                    <h6>보험</h6>
                  </div>
                  <div data-aos="zoom-in">
                    <img src="/images/icn_etc.png" />
                    <h6>기타</h6>
                  </div>
                </li>
              </ul>
            </article>
            <p>
              약사님들의 후기를 통해
              <br />
              믿을 수 있는 업체를 모집 중입니다.
            </p>
            <p>
              실제 이용 후 만족스러웠던
              <br />
              업체를 추천해주세요.
            </p>
            <p>
              추천해주신 모든 분들께
              <br />
              커피쿠폰을 보내드려요!
            </p>
          </section>
        </Body>
      </div>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOneIntroductionTemplate",
      errorMessage: error,
    });
  }
};

export default withRouter(AllInOneIntroduction);
