import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton, IonHeader, IonToolbar, IonButtons, IonIcon, IonLabel, IonContent, IonSegment, IonSegmentButton, IonInput, IonTextarea, IonItem, IonFab, IonFabButton } from '@ionic/react';
import './RegisterWaiting.css';
import './../Common.css'
import {fetchAPI} from "../utils/APIUtil"
import * as API from './../API.json';
import kakaoIcon from './../assets/icon/question_small_yellow_mobile_3X.png'
import profile from './../assets/image/profile_m_1.png'
import BoardList from './../components/BoardList'
import DynamicChecker from '../components/DynamicChecker';
import {log, LogLevel} from '../utils/LogUtil'
import logo from "./../assets/image/logo.png"
import {getGlobal, GlobalKey} from './../utils/GlobalUtil'
import { UserLevel } from '../models/Model.User';
import { UIPopupType, UIServiceType } from '../store/ui/types';
import SwipeableViews from 'react-swipeable-views';
import AnalyticsUtil from '../utils/AnalyticsUtil';
import kakaoOnlycon from './../assets/icon/kakao.svg'
import ABTestUtil from '../utils/ABTestUtil';
import { osName } from 'react-device-detect';
import { getDevice } from '../utils/DeviceUtil';

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
}

const windowAny:any = window;

type State = {
  segmentIndex : number,
}

class RegisterWaiting extends Component<Props, State> {
  waitToQuit = false;
  state = {
    loadingMessage: '',
    segmentIndex : Math.floor(Math.random() * 6),
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    log(LogLevel.UI_EVENT, "RegisterWaiting:componentDidMount backbutton register");
    if(this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);
    // document.addEventListener("backbutton", this.goBack);
    this.check();
  }

  check = () => {
    if(!this.props.me  || this.props.me.level == UserLevel.WAITING_USER){
      setTimeout(()=>{
        if(this.props.me && this.props.me.level == UserLevel.WAITING_USER){
          log(LogLevel.UI_DATA_LOAD, "RegisterWaiting:check")
          this.props.login();
          this.check();
        }
      }, (ABTestUtil.isTest()?10000:60000));
    }
  }

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "RegisterWaiting:goBack");
    let os = getGlobal(GlobalKey.OS);
    if(os && os != "browser" && windowAny.navigator && windowAny.navigator.app){
      if(this.props.confirmPopup && this.props.confirmPopup.isShown()){
        this.props.confirmPopup.hide();
        return;
      }

      if(this.waitToQuit)
        windowAny.navigator.app.exitApp();
      else {
        this.waitToQuit = true;
        setTimeout(() => {this.waitToQuit = false;}, 2000);
        this.props.toastPopup.show("'뒤로'버튼을 한번 더 누르면 종료됩니다.")
      }
    }else{
      this.props.history.goBack()
    }
  }

  componentWillUnmount(){
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    log(LogLevel.UI_EVENT, "RegisterWaiting:componentWillUnmount backbutton unregister");

    // document.removeEventListener("backbutton", this.goBack);
  }

  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    if(!os || os == "browser"){
      try{
        windowAny.Kakao.init('0d4139a6dc131b05b8109f629d7cc3f7');
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
      try{
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: '_Vxjhxgj' // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }else{  
      let plusFriendTemplate = {
        plusFriendId: '_Vxjhxgj',
      };
      try{
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  }

  onCancel = async () => {
    log(LogLevel.UI_ACTION, 'RegisterWaiting.onCancel', this.props.confirmPopup)
    let result = await fetchAPI(API.USER_ME_DELETE, '', null, null, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error){
      
      if(this.props.confirmPopup)
        this.props.confirmPopup.show({
          title:(
            <div className="common-container">
              <span className="common-color-caution">가입신청이 철회</span>되었습니다.
            </div>
          ),
          body: (
            <div className="common-container">
              가입 신청 시 입력하신 내용은 <br/> 복원 불가능한 방법으로 삭제되었습니다. 
            </div>
          ),
          onDone: this.onCancelDone,
          doneText: "네, 알겠습니다"
  
        });

    }
  }

  onCancelDone = (text:string) => {
    this.props.logOutUser();
    this.props.history.replace('/');
  }

  onRetry = () => {
    this.props.history.push('/register/update');
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.me.level == UserLevel.WAITING_USER && this.props.me.level >= UserLevel.NORMAL_USER)
      if(this.props.confirmPopup)
        this.props.confirmPopup.show({
          title:(
            <div className="common-container">
              <span className="common-color-highlight">가입 승인이 완료</span>되었습니다.
            </div>
          ),
          body: (
            <div className="common-container">
              추천 컨텐츠를 다 읽으셨으면,<br/>
              아래 버튼을 눌러주세요.
            </div>
          ),
          onDone: this.onStart,
          doneText: "로그인 하기",
          cancelText: "컨텐츠 계속 보기",
          cancelButtonColorStyle: "confirm-button",
        });
    //   this.props.history.replace('/')
    if(!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
  }

  onStart = () => {
    if(this.props.me.level != UserLevel.WAITING_USER && this.props.me.level != UserLevel.DENIED_USER )
      this.props.history.replace('/')
  }

  
  handleChangeIndex = (index: number) => {
    if(index == this.state.segmentIndex)
      return;
    this.setState({segmentIndex: index});
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SELECT_SAMPLE", '가입대기글보기',{"id":index});
  }

  renderDenied() {
    log(LogLevel.NONE, "RegisterWaiting:renderDenied");

    return (
      <>
        <IonContent class="common-content">
          <div className="common-container-column  common-scroll">
            <img className="register-waiting-logo" src={logo}/>
            <div className='register-waiting-status'>가입 승인이 <span className='register-waiting-status-denied'>반려</span> 되었습니다.</div>
            <div className="register-waiting-message-box">{this.props.me.managerMessage}<br/><span className="common-bold">가입 신청 철회 시, 기존 신청 내역은 바로 폐기 됩니다.</span></div>
            <div className="register-waiting-bottom">
              <IonButton expand="block" color="register-waiting-button-inactive" onClick={this.onCancel}>
                가입신청 철회
              </IonButton>
              <IonButton expand="block" color="register-waiting-button" onClick={this.onRetry}>
                내용 보완 후 재신청
              </IonButton>
              <div className="register-waiting-guide">
                <span className="common-text-align-right">가입 승인에 대한 질문이 있으시면,<br/>카카오톡 플러스 친구"약문약답"으로 문의해주세요.</span>
                <img className="register-waiting-kakao" src={kakaoIcon} onClick={this.onChat}/>
              </div>
            </div>
          </div>
        </IonContent>
      </>

    );
  }

  renderView() {
    if(!this.props.me)
      return (
        <Redirect to={{
          pathname: '/',
          state: { from: this.props.location.pathname, search: this.props.location.search }
        }}/>
      );

    if(this.props.me.level == UserLevel.DENIED_USER){
      return this.renderDenied();
    }

    log(LogLevel.NONE, "RegisterWaiting:render");

    let fab = (
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton color="register-waiting-fab-ask" onClick={this.onChat}>
          <div className="common-container-column common-flex-align-center">
            <IonIcon class="register-waiting-fab-text-ask" src={kakaoOnlycon}/>
            <IonLabel class="register-waiting-fab-text-ask" >카톡<br/>문의</IonLabel>
          </div>
        </IonFabButton>
      </IonFab>
    );
    if(this.props.me.level == UserLevel.NORMAL_USER){
      fab = (
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton color="register-waiting-fab-start" onClick={this.onStart}>
            <div className="common-container-column common-flex-align-center">
              <IonLabel class="register-waiting-fab-text" >시작<br/>하기</IonLabel>
            </div>
          </IonFabButton>
        </IonFab>      
      );
    }

    let message = (
      <div className="common-container">
        <div className='register-waiting-status-text'>
          가입 승인 <span className='register-waiting-status-waiting'>대기 중</span>입니다. <br/>
          가입 승인은 1시간 내에 완료됩니다.
        </div>
        <div className='register-waiting-status-text-etc'>
          ※ 주말에는 승인에 1시간이상 소요될 수 있습니다. 
        </div>
      </div>
    );
    if(this.props.me.level == UserLevel.NORMAL_USER){
      message = (
        <div className="common-container">
          <div className='register-waiting-status-text'>
            <span className='register-waiting-status-waiting'>가입 승인이 완료</span>되었습니다. <br/>
            추천 컨텐츠를 다 읽으셨으면,<br/>
            아래 버튼을 눌러주세요.
          </div>
        </div>  
      );
    }

    let button = (
      <div className="common-container-column common-flex-align-center register-waiting-read-content-button-container">
        <div className="register-waiting-read-content-button">
          약문약답 추천 컨텐츠를 읽어보세요
        </div>
      </div>
    );
    if(this.props.me.level == UserLevel.NORMAL_USER){
      button = (
        <div className="common-container-column common-flex-align-center register-waiting-read-content-button-container">
          <div className="register-waiting-start-content-button" onClick={this.onStart}>
            약문약답 시작하기
          </div>
        </div>
      );
    }

    return (
      <div className="common-content common-background-white">
        <div className="common-container-row common-flex-align-center register-waiting-message-container">
          <img className="register-waiting-logo-small" src={logo}/>
          {message}
        </div>
        {button}
        <div className="common-container-column common-flex-align-center register-waiting-page-container">
          <div className="common-flex-row common-flex-align-center">
            <div className={(this.state.segmentIndex==0?"register-waiting-page-selected":"register-waiting-page")}/>
            <div className={(this.state.segmentIndex==1?"register-waiting-page-selected":"register-waiting-page")}/>
            <div className={(this.state.segmentIndex==2?"register-waiting-page-selected":"register-waiting-page")}/>
            <div className={(this.state.segmentIndex==3?"register-waiting-page-selected":"register-waiting-page")}/>
            <div className={(this.state.segmentIndex==4?"register-waiting-page-selected":"register-waiting-page")}/>
            <div className={(this.state.segmentIndex==5?"register-waiting-page-selected":"register-waiting-page")}/>
          </div>
        </div>
        <SwipeableViews className="register-waiting-swipe" enableMouseEvents index={this.state.segmentIndex} onChangeIndex={this.handleChangeIndex}>
          <div className="common-content common-scroll"><img className="register-waiting-content-sample" src="/samples/sample0.jpg"/></div>
          <div className="common-content common-scroll"><img className="register-waiting-content-sample" src="/samples/sample1.jpg"/></div>
          <div className="common-content common-scroll"><img className="register-waiting-content-sample" src="/samples/sample2.jpg"/></div>
          <div className="common-content common-scroll"><img className="register-waiting-content-sample" src="/samples/sample3.jpg"/></div>
          <div className="common-content common-scroll"><img className="register-waiting-content-sample" src="/samples/sample4.jpg"/></div>
          <div className="common-content common-scroll"><img className="register-waiting-content-sample" src="/samples/sample5.jpg"/></div>
        </SwipeableViews>
        {fab}
      </div>
    );
  }

  render() {
    if(getGlobal(GlobalKey.OS, true) == "browser" || getDevice()=="iPad")
      return (
          <div className='common-content common-flex-row common-flex-align-center'>
            <div className='common-content common-container-column common-flex-align-center'>
              <div className="common-flex-grow"/>
              <div className="common-small-outer-container">
                  {this.renderView()}
              </div>
              <div className="common-flex-grow"/>
            </div>
          </div>
      );
    else{
      return this.renderView();
    }
  }
}



const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  auth: state.user.authStatus,
  confirmPopup : state.ui.popups[UIPopupType.CONFIRM_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  logOutUser: () => actions.user.logout(),
  login: () => actions.user.login(),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterWaiting));