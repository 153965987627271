

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonLabel, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import * as API from './../../../API.json';
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../../models/Model.Board';
import Truncate from 'react-truncate';
import { BannerInfo, EMPTY_BANNER, BannerType } from '../../../models/Model.Banner';
import { PollInfo, EMPTY_POLL, PollItemInfo, PollResponseInfo, EMPTY_POLL_ITEM, comparePollItems } from '../../../models/Model.Poll';
import ReactQuill from 'react-quill';
import { RootState } from '../../../store';
import { UIPopupType } from '../../../store/ui/types';
import { connect } from 'react-redux';
import { getDateStringFromToday } from '../../../utils/TimeUtil';
import PollItemEdit from './PollItemEdit';
import PollSatistics from './PollStatistics';
import PollResponses from './PollResponses';

const AdminAPI = {        
  "POLL_POST" : {"method":"POST", "path":"/admin/poll/poll", "contentType":"application/json"},
  "POLL_PUT" : {"method":"PUT", "path":"/admin/poll/poll", "contentType":"application/json"},
  "POLL_GET_RESULTS" : {"method":"GET", "path":"/admin/poll/", "contentType":"application/json"}

};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onBack: () => void,
  onPop: (depth:number) => void,
  onNext: (component:any, depth:number, data:any) => void, 
  selectedPoll: PollInfo;
  selectedPollItem: PollItemInfo;
  depth: number;
};

type State = {
  poll: PollInfo,
  responses: PollResponseInfo[],
  responseEnded: boolean,
  edited: boolean,
  startAt:string;
  startAtWarn : boolean,
  endAt:string;
  endAtWarn : boolean,
}

class PollEdit extends Component<Props, State> {
  onProcessing: boolean = false;
  static modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'color': []}, {'background': []}],
      ['link'],
      ['clean']
    ]
  }

  static formats = [
    'header', 'font', 'size',  'color', 'background',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'link', 'image', 'video'
  ]

  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_EVENT, "PollEdit:constructor", props);
    let startAt = "", endAt = "";
    if(props.selectedPoll){
      if(props.selectedPoll.startAt)
        startAt = props.selectedPoll.startAt.substring(0,10);
      if(props.selectedPoll.endAt)
        endAt = props.selectedPoll.endAt.substring(0,10);
    }

    this.state = {
      poll: {...props.selectedPoll},
      edited: false,
      responses: null,
      responseEnded: false,
      startAt,
      startAtWarn: false,
      endAt,
      endAtWarn: false,
    };
  }

  componentDidMount() {
    if(this.props.selectedPoll && this.props.selectedPoll.id)
      this.fetchData(this.props.selectedPoll.id);
  }

  componentDidUpdate(prevProps) {
    if(this.props.selectedPoll && this.props.selectedPoll.id && (!prevProps.selectedPoll || this.props.selectedPoll.id != prevProps.selectedPoll.id)){
      this.fetchData(this.props.selectedPoll.id);
    }
    else if(this.props.selectedPoll && !this.props.selectedPoll.id && (!prevProps.selectedPoll || this.props.selectedPoll.id != prevProps.selectedPoll.id)){
      let startAt = "", endAt = "";
      if(this.props.selectedPoll.startAt)
        startAt = this.props.selectedPoll.startAt.substring(0,10);
      if(this.props.selectedPoll.endAt)
        endAt = this.props.selectedPoll.endAt.substring(0,10);
      this.setState({poll: {...this.state.poll, ...this.props.selectedPoll}, edited:false, responses:[], startAt, endAt, startAtWarn:false, endAtWarn:false});
    }
  }

    
  onDateChange = (e, field) => {
    let value = e.target.value;
    let warn = false;
    if(value){
      try{
        let date = Date.parse(value);
        if(!date){
          log(LogLevel.UI_EXCEPTION, "Admin:PollEdit:onConsultsDateEndChange", value);
          warn = true;
        }else{
          log(LogLevel.UI_DATA_LOAD, "Admin:PollEdit:onConsultsDateEndChange", value, date);
        }
      }catch(err) {
        log(LogLevel.UI_EXCEPTION, "Admin:PollEdit:onConsultsDateEndChange", value, err);
        warn = true;
      }
    }

    let newState:any = {};
    newState[field] = value;
    newState[field+"Warn"] = warn;

    if(!warn){
      newState.poll = {...this.state.poll};
      newState.poll[field] = value
    }

    this.setState(newState);
  }

  onCancel = () => {
    
  }


  onDone = async () => {
    if(this.onProcessing)
      return;
    this.onProcessing = true;
    this.props.waitingPopup.show();
    let result;
    let passed = false;
    let poll = {...this.state.poll}
    if(this.state.poll.id){
      result = await fetchAPI(AdminAPI.POLL_PUT, "", null, poll, getGlobal(GlobalKey.TOKEN));
      if(!result || result.error){
        this.props.toastPopup.show("저장실패")
      }else{
        passed = true;
      }
    }else{
      result = await fetchAPI(AdminAPI.POLL_POST, "", null, poll, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error && result.data){
        poll.id = result.data
        this.setState({poll})
        passed = true;
      }
      else  
        this.props.toastPopup.show("저장실패")
    }

    this.props.waitingPopup.hide();

    if(passed){
      this.fetchData(poll.id);
    }
    this.onProcessing = false;
  }

  onDelete = () => {
    this.props.confirmPopup.show({
      title:(
        <div className="common-container">
          <span className="common-bold"> 설문을 삭제</span> 시키시겠습니까?
        </div>
      ),
      onDone: () => {
      },
      doneText: "네",
    });

  }

  onItemChange = (index: number, pollItem: PollItemInfo) => {
    log(LogLevel.UI_LIFECYCLE, "Admin:PollEdit:onItemChange", index, pollItem);
    let items
    if(this.state.poll && this.state.poll.items)
      items = [...this.state.poll.items];
    else 
      items = [];
    items[index] = pollItem;
    this.setState({poll:{...this.state.poll, items}})
  }

  onItemDelete = (index: number) => {
    let items
    if(this.state.poll && this.state.poll.items)
      items = [...this.state.poll.items];
    else 
      items = [];
    items.splice(index, 1);
    this.setState({poll:{...this.state.poll, items}})
  }

  onItemAdd = () => {
    let items
    if(this.state.poll && this.state.poll.items)
      items = [...this.state.poll.items];
    else 
      items = [];
    items.push({...EMPTY_POLL_ITEM, pollId:this.state.poll.id});
    this.setState({poll:{...this.state.poll, items}})
  }

  onItemOrder = () => {
    let items
    if(this.state.poll && this.state.poll.items)
      items = [...this.state.poll.items];
    else 
      items = [];
    items.sort(comparePollItems);
    this.setState({poll:{...this.state.poll, items}})
  }

  fetchData = async (id) => {

    this.props.waitingPopup.show();
    let result = await fetchAPI(API.POLL_GET, ""+ id, {}, null, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error && result.data){
      let startAt = "", endAt = "";
      if(result.data.startAt)
        startAt = result.data.startAt.substring(0,10);
      if(result.data.endAt)
        endAt = result.data.endAt.substring(0,10);

      this.setState({poll: {...result.data}, edited:false, responses:[], startAt, endAt, startAtWarn:false, endAtWarn:false});
    }
    this.props.waitingPopup.hide();
  }


  onLoadResponse = () => {
    
  }

  render() {
    
    if(!this.state.poll)
      return null;
    log(LogLevel.UI_LIFECYCLE, "Admin:PollEdit:render", this.state.poll);

    let deletedStatus;
    if(this.state.poll.id){
      if(this.state.poll.deletedAt){
        deletedStatus = (
          <span className="common-color-caution">(삭제됨)</span>
        );
      }
    }

    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button" onClick={this.props.onBack}>
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="viewer-title" >설문 보기/수정</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="common-container-row common-flex-align-center common-flex-row-wrap">
          <IonButton color="admin-content-manage-cancel-button" onClick={this.onCancel}>
            취소
          </IonButton>     
          <IonButton color="admin-content-manage-button" onClick={this.onDone}>
            저장
          </IonButton>  
          <IonButton color="admin-content-delete-button" onClick={this.onDelete}>
            삭제
          </IonButton>  
          <IonButton color="admin-content-manage-button" onClick={() => {}}>
            미리보기
          </IonButton>  
          <IonButton color="admin-content-manage-button" onClick={() => {this.props.onNext(PollSatistics, this.props.depth, {})}}>
            통계
          </IonButton>  
          <IonButton color="admin-content-manage-button" onClick={() => {this.props.onNext(PollResponses, this.props.depth, {})}}>
            답변
          </IonButton>  
          
        </div>  
        <div className="admin-title">ID : {this.state.poll.id}{deletedStatus}</div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">제목 : </div>
          <IonInput class="admin-input" placeholder="제목" value={this.state.poll.title} onIonChange={(e) => this.setState({poll:{...this.state.poll, title:e.detail.value}, edited:true})}/>
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">별칭 : </div>
          <IonInput class="admin-input" placeholder="Alias" value={this.state.poll.alias} onIonChange={(e) => this.setState({poll:{...this.state.poll, alias:e.detail.value}, edited:true})}/>
        </div>
        <div className="admin-title admin-margin-bottom">설문조사 설명</div>
        <div className="common-container admin-editor common-scroll admin-margin-bottom">
          <ReactQuill
            theme={'snow'}
            onChange={(value, delta, source, editor) => {this.setState({poll:{...this.state.poll, description:value}, edited:true})}}
            value={this.state.poll.description?this.state.poll.description:""}
            modules={PollEdit.modules}
            formats={PollEdit.formats}
            placeholder="설문조사 설명"
          />
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">설문시작 : </div>
          <input className={(this.state.startAtWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.startAt} onChange={(e) => this.onDateChange(e, "startAt")}/>
        </div>
        <div className="common-container-row common-flex-align-center admin-margin-bottom">
          <div className="admin-title">설문종료 : </div>
          <input className={(this.state.endAtWarn?"common-color-caution":"")} placeholder="설문종료" value={this.state.endAt} onChange={(e) => this.onDateChange(e, "endAt")}/>
        </div>
        {this.renderItems()}
      </div>
    );
  }

  renderItems() {
    let items:PollItemInfo[] = this.state.poll.items;
    if(!items)
      items = [];


    return (
      <div className="common-container">
        <div className="admin-title">설문조사 질문</div>
        <div className="admin-content-manage-button-container">
          <IonButton color="admin-button" onClick={this.onItemAdd}>
            추가
          </IonButton>  
          <IonButton color="admin-button" onClick={this.onItemOrder}>
            정렬
          </IonButton>  
        </div>  
        {items.map((item:PollItemInfo, index) => {
          return (
            <PollItemEdit key={index.toString()} pollItem={item} index={index} onChange={this.onItemChange} onDelete={this.onItemDelete}/>
          );
        })}
        
      </div>
    )

  }
}

const mapStateToProps = (state: RootState) => ({
  waitingPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup : state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
}


export default connect(mapStateToProps, mapDispatchToProps)(PollEdit);