import React, { useEffect } from "react";
import { Absolute, Flex, Relative, Static } from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import BoardSummary from "../BoardSummary";
import {
  BoardContentSummary,
  sampleBoardContentSummary,
  sampleBoardContentSummaryStr,
} from "../../../models/Model.Board";
import { PremiumType } from "../../../models/Model.User.Premium";
import Icon from "../../atoms/Icon";
import { on } from "process";

export interface BoardSummaryPopupProps {
  summary: BoardContentSummary;
  onClickReference?: (focus: {
    contentId?: number;
    commentId?: number;
  }) => void;
  onClickUnlock?: () => void;
  onClickAdsReward?: () => void;
  freeSummaryCount: number;
  premium: PremiumType;
  onAdminRefresh?: () => void;
  onReport: (message: string) => void;
  onClickKeyword: (keyword: string) => void;
  summaryGroups: any;
}

const BoardSummaryPopup: React.FC<BoardSummaryPopupProps> = ({
  summary,
  onClickReference,
  onClickUnlock,
  onClickAdsReward,
  freeSummaryCount,
  premium,
  onAdminRefresh,
  onReport,
  onClickKeyword,
  summaryGroups,
}) => {
  return (
    <Flex direction="column" customStyle={{ width: "100%", minHeight: "80vh" }}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        customStyle={{ width: "100%" }}
      >
        <Flex alignItems="center">
          <img src={"/images/robot.png"} style={{ width: "54px" }} />
          <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[900]}>
            AI 요약
          </Text>
        </Flex>
        {!premium &&
          (freeSummaryCount > 0 || (summary && summary.viewed) ? (
            <Flex
              alignItems="center"
              gap="4px"
              customStyle={{
                borderRadius: "1000px",
                background: COLOR_SYSTEM.get("Gray")[20],
                padding: "5px 10px",
              }}
            >
              <Text
                textType="CaptionBold"
                color={COLOR_SYSTEM.get("Gray")[500]}
              >
                무료이용
              </Text>
              <Relative>
                <img src={"/images/ticket.png"} style={{ width: "20px" }} />
                <Text
                  textType="Caption2SemiBold"
                  color={COLOR_SYSTEM.get("Gray")[0]}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    whiteSpace: "nowrap",
                  }}
                >
                  {freeSummaryCount}
                </Text>
              </Relative>
            </Flex>
          ) : (
            <Flex
              alignItems="center"
              gap="4px"
              customStyle={{
                borderRadius: "1000px",
                background: COLOR_SYSTEM.get("Gray")[20],
                padding: "5px 10px",
              }}
            >
              <Text
                textType="CaptionBold"
                color={COLOR_SYSTEM.get("Gray")[500]}
              >
                Supporters Only
              </Text>
              <Icon
                name="Sparkle Filled"
                size={16}
                color={COLOR_SYSTEM.get("Yellow")[400]}
              />
            </Flex>
          ))}
        {onAdminRefresh && (
          <Button
            size={"Medium"}
            type={"Icon"}
            variant={"Contained"}
            color={"Negative"}
            icon="Refresh"
            onClick={onAdminRefresh}
          />
        )}
      </Flex>
      <BoardSummary
        summary={summary}
        onUnlock={onClickUnlock}
        onClickReference={onClickReference}
        onAdsReward={onClickAdsReward}
        locked={!premium && freeSummaryCount <= 0 && summary && !summary.viewed}
        onReport={onReport}
        onClickKeyword={onClickKeyword}
        summaryGroups={summaryGroups}
      />
    </Flex>
  );
};

export default BoardSummaryPopup;
