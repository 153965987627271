

import React, { Component } from 'react';
import './../Admin.scss';
import './../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../models/Model.User';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import ProfileImage from '../../components/ProfileImage';
import { Keyword, KeywordType, Ingredient } from '../../models/Model.Medicine';
import * as API from './../../API.json';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { UIPopupType } from '../../store/ui/types';

type Props =  typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onDone: (ingredient:Ingredient) => void;
};

type State = {
  search: string,
  keywords: Keyword[],
  type: KeywordType;
  id: number;
  textKor: string;
  textEng: string;
  linkKor: string;
  linkEng: string;
  volume: string;
}

const keywordTypeName = ['','성분','의약품','용어','부작용','동물의약품'];


class IngredientSelect extends Component<Props, State> {
  finished = false;
  loading = false;
  userList = null;
  doneProcessing:boolean = false;

  state = {
    search: '',
    keywords: [],
    type: KeywordType.INGRADIENT,
    id: 0,
    textKor: '',
    textEng: '',
    linkKor: '',
    linkEng: '',
    volume: '',
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
  }
  
  onKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "IngredientSelect:onKeyPress", e.keyCode, e.shiftKey)
    if(e.keyCode == 13){
      e.preventDefault();
    }
  }

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({search: keywords});
    this.fetchData(keywords);
  }

  fetchData = async (keywords) => {
    log(LogLevel.UI_DATA_LOAD, "Admin:IngredientSelect:fetchData");
    this.loading = true;

    fetchAPI(API.MEDICINE_DICTIONARY, '', {keyword:keywords, type:0}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      log(LogLevel.UI_DATA_LOAD, "Admin:IngredientSelect.mention.source", keywords);
      
      if(result && !result.error){
        let data = result.data;
        this.setState({keywords: data});
      }
      else{
        this.setState({keywords: []});
      }
    });
  }

  clear = () => {
    this.setState({
      type: KeywordType.INGRADIENT,
      id: 0,
      textKor: '',
      textEng: '',
      linkKor: '',
      linkEng: '',
      volume: '',
    });
  }

  done = () => {
    let ingredient = {
      type: this.state.type,
      id: this.state.id,
      textKor: this.state.textKor,
      textEng: this.state.textEng,
      linkKor: this.state.linkKor,
      linkEng: this.state.linkEng,
      volume: this.state.volume,
    };
    this.props.onDone(ingredient);
    this.clear();
  }

  clearSearch = () => {
    this.setState({search: '', keywords: []});
  }

  onSelect = async (keyword: Keyword) => {
    log(LogLevel.UI_EVENT, "onSelect", keyword);
    if(this.doneProcessing)
    return;
    this.doneProcessing = true;
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("강좌 저장 시작...");
    const result = await fetchAPI(API.MEDICINE_DETAIL, '', {type: keyword.type, id:keyword.id}, null)
    if(result && !result.error){
      log(LogLevel.REDUX_MEDICINE, "fetchSearchMiddleware QnA result",  result.data);
      this.setState({
        type: result.data.type,
        id: result.data.id,
        textKor: result.data.textKor,
        textEng: result.data.textEng,
        linkKor: result.data.linkKor,
        linkEng: result.data.linkEng,
      });
      
    }
    else{
      log(LogLevel.REDUX_MEDICINE, "fetchSearchMiddleware QnA failed", result);
    }
    this.props.progressPopup.hide();
    this.doneProcessing = false;
  }

  render() {
    return (
      <div className="admin-container">
        <div className="common-container-row">
          <div className="common-flex-grow">
            <div className="admin-title">성분입력</div>

            <div className="common-container-row common-flex-align-center">
              <div className="admin-title">한글명 :</div>
              <IonInput class="user-search-input" placeholder="한글명" value={this.state.textKor} onIonChange={(e) => this.setState({textKor:e.detail.value})}/>
            </div>
            <div className="common-container-row common-flex-align-center">
              <div className="admin-title">영문명 :</div>
              <IonInput class="user-search-input" placeholder="영문명" value={this.state.textEng} onIonChange={(e) => this.setState({textEng:e.detail.value})}/>
            </div>
            <div className="common-container-row common-flex-align-center">
              <div className="admin-title">한글링크 :</div>
              <IonInput class="user-search-input" placeholder="한글링크" value={this.state.linkKor} onIonChange={(e) => this.setState({linkKor:e.detail.value})}/>
            </div>
            <div className="common-container-row common-flex-align-center">
              <div className="admin-title">영문링크 :</div>
              <IonInput class="user-search-input" placeholder="영문링크" value={this.state.linkEng} onIonChange={(e) => this.setState({linkEng:e.detail.value})}/>
            </div>
            <div className="common-container-row common-flex-align-center">
              <div className="admin-title">함유량 :</div>
              <IonInput class="user-search-input" placeholder="함유량" value={this.state.volume} onIonChange={(e) => this.setState({volume:e.detail.value})}/>
            </div>
          </div>
          <IonButton class="admin-full-height" color="primary" onClick={this.clear}>
            <IonIcon name="close"/>
          </IonButton>
          <IonButton class="admin-full-height" color="primary" onClick={this.done}>
            <IonIcon name="add"/>
          </IonButton>
        </div>
        <div className="user-search-container">
          <IonInput class="user-search-input" placeholder="검색" value={this.state.search} onIonChange={this.onSearchChange}  onKeyDown={this.onKeyPress}/>
          <IonButton fill="clear" color="primary" onClick={this.clearSearch}>
            <IonIcon name="close"/>
          </IonButton>
        </div>
        <IonList >
          { this.state.keywords.map((item:Keyword, index: number) => (
            <div className="admin-keyword-item-container" onClick={() => {this.onSelect(item)}}>
              <div className="admin-keyword-item-type"> {keywordTypeName[item.type]}</div>
              <div className="admin-keyword-item-info"> 
                <div className="admin-keyword-item-name">{item.text}</div> 
              </div>
            </div>
          ))}              
        </IonList>
      </div>  
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  progressPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  // toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  // filePath : state.board.filePath,
  // medicine: state.medicine,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  // fetchKeyword: (type: KeywordType, id:number) => actions.medicine.getMedicine(type, id),
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientSelect);