import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { SeminarLecture, SeminarLectureComment, SeminarReview, SeminarReviewMent } from '../../../../models/Model.Seminar';
import { Attachment, BoardContent } from '../../../../models/Model.Board';
import Header from '../../../molecule/Header';
import Body from '../../../molecule/Body';
import StringUtil from '../../../../utils/StringUtil';
import Scroll from '../../../molecule/Scroll';
import { log, LogLevel } from '../../../../utils/LogUtil';
import { ScrollAction } from '../../../molecule/Scroll';
import Video from '../../../atom/Video';
import VideoController from '../../../cell/VideoController';
import { getGlobal, GlobalKey } from '../../../../utils/GlobalUtil';
import IconButton from '../../../atom/IconButton';
import Footer from '../../../molecule/Footer';
import TextButton from '../../../atom/TextButton';
import '../../../../Common.css';
import Text from '../../../atom/Text';
import StarScorer from '../../../atom/StarScorer';
import Input from '../../../atom/Input';
import ReactHtmlParser from 'react-html-parser'


interface Props {
  comment?: SeminarLectureComment,
  onDone?: (comment: SeminarLectureComment) => void
  onBack?: () => void
}
const SeminarCommentEdit: FC<Props> = ({
  comment,
  onDone,
  onBack,
}) => {
  const [bodyText, setBodyText] = useState<string>(comment.bodyText);

  const onClickBack = () => {
    if(onBack)
      onBack();
  }

  const onClickDone = () => {
    if(onDone){
      let commentNew:SeminarLectureComment = {
        ...comment,
        bodyText,
      }
      onDone(commentNew);
    }
  }

  return (
    <>
      <Header needBack={false} needClose={true} center={true} name="댓글 수정" goBack={onClickBack}
        buttons={[
          <TextButton size="L" variant="Ghost" color="Primary" onClick={onClickDone}>완료</TextButton>
        ]}
      />
      <Body scrollable={true}>
        <div className="common-block">
          <Input 
            allowMultiline={true} 
            placeholderElement={
              <Text type="Body1" color="Gray4">
                어떤 내용이 유익했는지 <br/>
                혹은 어떤 내용이 미흡했는지 알려주세요
              </Text>
            } 
            minRows={7}
            value={bodyText}
            onChange={(e) => setBodyText(e.target.value)}
          />
        </div>
        <div className="common-bottom-footer-space"/>
      </Body>
    </>
  )
}

const Container: StyledComponent<'div', FC> = styled.div`
  
`

export default SeminarCommentEdit
