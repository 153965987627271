import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from "./../API.json";
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import parse from "html-react-parser";
// import reactElementToJSXString from 'react-element-to-jsx-string';
import { loadImageUrlBase64 } from "./ImageUtil";
import ReactDOMServer from "react-dom/server";
import { MY_ADDRESS } from "./../config.json";

export default class BoardUtil {
  static async fetchAddContent(
    content: BoardContent,
    waitPopup: any,
    generateHtml: boolean = true
  ): Promise<BoardContent> {
    let token = getGlobal(GlobalKey.TOKEN);
    let api = API.BOARD_NEW;
    if (content.groupParent) api = API.REPLY_NEW;

    log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : ", content, api);

    let images: string[] = [];
    if (content.images) images = [...content.images];

    if (content.imagesBase64 && content.imagesBase64.length > 0) {
      if (waitPopup) waitPopup.setLabel("이미지 업로드 중...");
      for (let i = 0; i < content.imagesBase64.length; i++) {
        let data: any = { path: "board" };
        data.base64 = content.imagesBase64[i];
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            images.push(url);
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent upload : ", result.file, images);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }
    content.imagesBase64 = [];

    if (content.imagesLocal && content.imagesLocal.length > 0) {
      if (waitPopup) waitPopup.setLabel("이미지 업로드 중...");
      for (let i = 0; i < content.imagesLocal.length; i++) {
        const data = new FormData();
        log(LogLevel.UI_ACTION, content.imagesLocal[i]);
        data.append("file", content.imagesLocal[i]);
        try {
          let result = await fetchAPI(API.UPLOAD_FILE, "board", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent upload : ", result.file);
            images.push(url);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }

    let files: Attachment[] = [];
    if (content.files) files = [...content.files];
    if (content.filesLocal.length > 0) {
      if (waitPopup) waitPopup.setLabel("파일 업로드 중...");
      for (let i = 0; i < content.filesLocal.length; i++) {
        const data = new FormData();
        log(LogLevel.UI_ACTION, content.filesLocal[i]);
        data.append("file", content.filesLocal[i]);
        try {
          let result = await fetchAPI(API.UPLOAD_FILE, "board", null, data, getGlobal(GlobalKey.TOKEN));
          if (result && result.message && result.message == "success") {
            let url = result.file;
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent upload : ", result.file);
            files.push({
              url: url,
              name: content.filesLocal[i].name.replace(/\?/g, ""),
              size: content.filesLocal[i].size,
            });
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }

    content.filesLocal = [];

    if (waitPopup) waitPopup.setLabel("글 작성 중...");

    if (generateHtml) BoardUtil.generateHtml(content);
    if (!content.isAnonymous) content.isAnonymous = false;
    content.images = images; // 첨부 이미지
    content.files = files; // 첨부 파일

    let result = await fetchAPI(api, "", null, content, getGlobal(GlobalKey.TOKEN));
    if (result && result.message && result.message == "success") {
      log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent success: ", result, api, content, images);
      content.id = result.data;
      if (content.replies) {
        for (let i = 0; i < content.replies.length; i++) {
          content.replies[i].groupParent = content.id;
          await BoardUtil.fetchAddContent(content.replies[i], waitPopup, generateHtml);
        }
      }
      return content;
    } else {
      log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", result, api, content);
    }
  }

  static async fetchAddContentHtml(content: BoardContent, waitPopup: any = null): Promise<BoardContent> {
    let token = getGlobal(GlobalKey.TOKEN);
    let api = API.BOARD_NEW;
    if (content.groupParent) api = API.REPLY_NEW;

    log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : ", content, api);

    if (!content.imagesInner) content.imagesInner = [];

    if (waitPopup) waitPopup.setLabel("데이터 저장 중...");
    let html = content.bodyHtml;
    html = html.replace(/ style="user-select: auto;"/gi, "");
    html = html.replace(/ style="user-select:auto"/gi, "");
    html = html.replace(/<div><br>/gi, "");
    html = html.replace(/<div>/gi, "");
    html = html.replace(/<\/div>/gi, "<br/>");
    html = html.replace(/<br>/gi, "<br/>");
    // console.log(html);
    html = "<div>" + html + "</div>";

    let text = "";
    let innerImages = [];
    let mentionState = 0;
    content.keywords = [];
    content.references = [];
    let a: any = parse(html, {
      replace: (domNode) => {
        // console.log(domNode);
        if (domNode.type == "tag" && domNode.name == "img" && domNode.attribs.src) {
          if (domNode.attribs.src.startsWith("data:image")) {
            let index = innerImages.length;
            innerImages.push(domNode.attribs.src);
            domNode.attribs.src = `-#-INNER_IMAGE-${index}-#-`;
          }
        }
        if (domNode.type == "tag" && domNode.name == "span" && domNode.attribs.class == "mention") {
          mentionState = 1;
          //add keywords
          let containKeyword = false;
          let type = parseInt(domNode.attribs["data-type"]);
          let id = parseInt(domNode.attribs["data-id"]);
          for (let i = 0; i < content.keywords.length; i++) {
            if (content.keywords[i].type == type && content.keywords[i].id == id) {
              containKeyword = true;
              break;
            }
          }

          if (!containKeyword) {
            let text = "";
            if (domNode.children.length > 1 && domNode.children[1].name == "span") {
              if (domNode.children[1].children) {
                for (let i = 0; i < domNode.children[1].children.length; i++) {
                  if (domNode.children[1].children[i].type == "text") {
                    text = domNode.children[1].children[i].data;
                    log(LogLevel.UI_DATA_LOAD, "transform.keyword mention 2", domNode.children[1].children[i], text);
                    break;
                  }
                }
              }
            }
            content.keywords.push({ id, type, text });
          }
          // console.log("1");
        } else if (domNode.type == "tag" && domNode.name == "span" && domNode.attribs.class == "components") {
          text += " ";
          mentionState = 3;
        } else if (domNode.type == "tag" && domNode.name == "br") {
          text += "\n";
          mentionState = 0;
        } else if (domNode.type == "tag" && domNode.name == "a") {
          let url = domNode.attribs.href;
          if (url.startsWith(MY_ADDRESS)) {
            let title = domNode.data;
            let subs = url.replace(MY_ADDRESS, "&&&");
            if (title === url) {
              domNode.data = subs;
            }
            domNode.attribs.href = subs;
            url = subs;
          }
          content.references.push({ url: url, hash: StringUtil.hashcode(url) });
        } else if (domNode.type == "text") {
          let newText = domNode.data;
          if (mentionState == 1 && newText.trim().length) {
            // console.log("2", newText.length);
            mentionState = 2;
          } else if (mentionState == 2 && newText.trim().length) {
            // console.log("0");
            mentionState = 0;
            if (newText.startsWith(" ")) newText = newText.substring(1);
          } else if (mentionState == 3 && newText.trim().length) {
            // console.log("0");
            mentionState = 0;
            newText = "";
          }

          text += newText;
        }
        return domNode;
      },
    });
    html = ReactDOMServer.renderToStaticMarkup(a);
    html = html.substring(5, html.length - 6);

    if (content.cards && content.cards.length > 0) {
      if (waitPopup) waitPopup.setLabel("카드뉴스 업로드 중...");
      let cards = [];
      for (let i = 0; i < content.cards.length; i++) {
        if (!content.cards[i].base64) {
          cards.push(content.cards[i]);
          continue;
        }
        let data: any = { path: "board" };
        data.base64 = content.cards[i].base64;
        let card: Attachment = {
          name: content.cards[i].name,
          description: content.cards[i].description,
          link: content.cards[i].link,
        };
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            card.url = url;
            cards.push(card);
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml upload : ", result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
      content.cards = cards;
      content.image = cards[0].url;
    }

    if (waitPopup) waitPopup.setLabel("이미지 업로드 중...");
    for (let i = 0; i < innerImages.length; i++) {
      let data: any = { path: "board" };
      if (innerImages[i].startsWith("data:image")) {
        data.base64 = innerImages[i];
      } else if (innerImages[i].startsWith("http://")) {
        data.base64 = await loadImageUrlBase64(innerImages[i]);
      }
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
        if (result && !result.error) {
          let url = result.file;
          content.imagesInner.push(url);
          if (!content.image) content.image = url;
          html = html.replace(`-#-INNER_IMAGE-${i}-#-`, url);
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml upload : ", result.file);
          // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        } else {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml failed", result);
        }
      } catch (error) {
        log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : failed", error);
        // this.props.navigation.push("AuthRegister");
      }
    }

    // console.log("html", html);
    // html = html.replace(/[\n]/gi,'');
    content.bodyHtml = html;
    // console.log("text", text.trim())
    content.bodyText = text;

    let images: string[] = [];
    if (content.images) images = [...content.images];

    if (content.imagesBase64 && content.imagesBase64.length > 0) {
      if (waitPopup) waitPopup.setLabel("이미지 업로드 중...");
      for (let i = 0; i < content.imagesBase64.length; i++) {
        let data: any = { path: "board" };
        data.base64 = content.imagesBase64[i];
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            images.push(url);
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml upload : ", result.file, images);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }
    content.imagesBase64 = [];

    let files: Attachment[] = [];
    if (content.files) files = [...content.files];
    if (content.filesLocal && content.filesLocal.length > 0) {
      if (waitPopup) waitPopup.setLabel("파일 업로드 중...");
      for (let i = 0; i < content.filesLocal.length; i++) {
        const data = new FormData();
        log(LogLevel.UI_ACTION, content.filesLocal[i]);
        data.append("file", content.filesLocal[i]);
        try {
          let result = await fetchAPI(API.UPLOAD_FILE, "board", null, data, getGlobal(GlobalKey.TOKEN));
          if (result && result.message && result.message == "success") {
            let url = result.file;
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml upload : ", result.file);
            files.push({
              url: url,
              name: content.filesLocal[i].name.replace(/\?/g, ""),
              size: content.filesLocal[i].size,
            });
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }

    // generate text and check inner images

    content.filesLocal = [];

    if (waitPopup) waitPopup.setLabel("글 작성 중...");

    if (!content.isAnonymous) content.isAnonymous = false;
    content.images = images; // 첨부 이미지
    content.files = files; // 첨부 파일

    let result = await fetchAPI(api, "", null, content, getGlobal(GlobalKey.TOKEN));
    if (result && result.message && result.message == "success") {
      log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml success: ", result, api, content, images);
      content.id = result.data;
      if (content.replies) {
        for (let i = 0; i < content.replies.length; i++) {
          content.replies[i].groupParent = content.id;
          await BoardUtil.fetchAddContentHtml(content.replies[i], waitPopup);
        }
      }
      return content;
    } else {
      log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : failed", result, api, content);
    }
    return null;
  }

  static async fetchUpdateContent(
    content: BoardContent,
    waitPopup: any,
    generateHtml: boolean = true
  ): Promise<BoardContent> {
    let token = getGlobal(GlobalKey.TOKEN);
    let api = API.BOARD_NEW;
    if (content.groupParent) api = API.REPLY_NEW;

    log(LogLevel.UI_ACTION, "BoardUtil.fetchUpdateContent : ", content, api);

    let images: string[] = [];
    if (content.images) images = [...content.images];

    if (content.imagesBase64 && content.imagesBase64.length > 0) {
      if (waitPopup) waitPopup.setLabel("이미지 업로드 중...");
      for (let i = 0; i < content.imagesBase64.length; i++) {
        let data: any = { path: "board" };
        data.base64 = content.imagesBase64[i];
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            images.push(url);
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent upload : ", result.file, images);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }
    content.imagesBase64 = [];

    if (content.imagesLocal && content.imagesLocal.length > 0) {
      if (waitPopup) waitPopup.setLabel("이미지 업로드 중...");
      for (let i = 0; i < content.imagesLocal.length; i++) {
        const data = new FormData();
        log(LogLevel.UI_ACTION, content.imagesLocal[i]);
        data.append("file", content.imagesLocal[i]);
        try {
          let result = await fetchAPI(API.UPLOAD_FILE, "board", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent upload : ", result.file);
            images.push(url);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }

    let files: Attachment[] = [];
    if (content.files) files = [...content.files];
    if (content.filesLocal.length > 0) {
      if (waitPopup) waitPopup.setLabel("파일 업로드 중...");
      for (let i = 0; i < content.filesLocal.length; i++) {
        const data = new FormData();
        log(LogLevel.UI_ACTION, content.filesLocal[i]);
        data.append("file", content.filesLocal[i]);
        try {
          let result = await fetchAPI(API.UPLOAD_FILE, "board", null, data, getGlobal(GlobalKey.TOKEN));
          if (result && result.message && result.message == "success") {
            let url = result.file;
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent upload : ", result.file);
            files.push({
              url: url,
              name: content.filesLocal[i].name.replace(/\?/g, ""),
              size: content.filesLocal[i].size,
            });
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }

    content.filesLocal = [];

    if (waitPopup) waitPopup.setLabel("글 작성 중...");

    if (generateHtml) BoardUtil.generateHtml(content);
    if (!content.isAnonymous) content.isAnonymous = false;
    content.images = images; // 첨부 이미지
    content.files = files; // 첨부 파일

    let result = await fetchAPI(API.BOARD_UPDATE, "", null, content, getGlobal(GlobalKey.TOKEN));
    if (result && result.message && result.message == "success") {
      return content;
    } else {
      log(LogLevel.UI_ACTION, "BoardUtil.fetchUpdateContent : failed", result, api, content);
    }
    return null;
  }

  static async fetchUpdateContentHtml(
    content: BoardContent,
    waitPopup: any,
    generateHtml: boolean = true
  ): Promise<BoardContent> {
    let token = getGlobal(GlobalKey.TOKEN);
    let api = API.BOARD_NEW;
    if (content.groupParent) api = API.REPLY_NEW;

    log(LogLevel.UI_ACTION, "BoardUtil.fetchUpdateContent : ", content, api);

    if (!content.imagesInner) content.imagesInner = [];

    if (waitPopup) waitPopup.setLabel("데이터 저장 중...");
    let html = content.bodyHtml;
    html = html.replace(/ style="user-select: auto;"/gi, "");
    html = html.replace(/ style="user-select:auto"/gi, "");
    html = html.replace(/<div><br>/gi, "");
    html = html.replace(/<div>/gi, "");
    html = html.replace(/<\/div>/gi, "<br/>");
    html = html.replace(/<br>/gi, "<br/>");
    html = html.replace(/<br>/gi, "<br/>");
    html = StringUtil.encodeFilePathFull(html);
    // console.log(html);
    html = "<div>" + html + "</div>";

    content.imagesInner = [];
    content.references = [];
    content.keywords = [];

    let text = "";
    let innerImages = [];
    let mentionState = 0;
    let a: any = parse(html, {
      replace: (domNode) => {
        // console.log(domNode);
        if (domNode.type == "tag" && domNode.name == "img" && domNode.attribs.src) {
          if (domNode.attribs.src.startsWith("data:image")) {
            let index = innerImages.length;
            innerImages.push(domNode.attribs.src);
            domNode.attribs.src = `-#-INNER_IMAGE-${index}-#-`;
          } else {
            if (StringUtil.isEncodeFilePath(domNode.attribs.src)) {
              content.imagesInner.push(domNode.attribs.src);
            }
            if (!content.image) content.image = domNode.attribs.src;
          }
        }

        if (domNode.type == "tag" && domNode.name == "span" && domNode.attribs.class == "mention") {
          mentionState = 1;
          //add keywords
          let containKeyword = false;
          let type = parseInt(domNode.attribs["data-type"]);
          let id = parseInt(domNode.attribs["data-id"]);
          for (let i = 0; i < content.keywords.length; i++) {
            if (content.keywords[i].type == type && content.keywords[i].id == id) {
              containKeyword = true;
              break;
            }
          }

          if (!containKeyword) {
            let text = "";
            if (domNode.children.length > 1 && domNode.children[1].name == "span") {
              if (domNode.children[1].children) {
                for (let i = 0; i < domNode.children[1].children.length; i++) {
                  if (domNode.children[1].children[i].type == "text") {
                    text = domNode.children[1].children[i].data;
                    log(LogLevel.UI_DATA_LOAD, "transform.keyword mention 2", domNode.children[1].children[i], text);
                    break;
                  }
                }
              }
            }
            content.keywords.push({ id, type, text });
          }
          // console.log("1");
        } else if (domNode.type == "tag" && domNode.name == "span" && domNode.attribs.class == "components") {
          text += " ";
          mentionState = 3;
          console.log("+3");
        } else if (domNode.type == "tag" && domNode.name == "br") {
          text += "\n";
          mentionState = 0;
        } else if (domNode.type == "tag" && domNode.name == "a") {
          let url = domNode.attribs.href;
          if (url.startsWith(MY_ADDRESS)) {
            let title = domNode.data;
            let subs = url.replace(MY_ADDRESS, "&&&");
            if (title === url) {
              domNode.data = subs;
            }
            domNode.attribs.href = subs;
            url = subs;
          }
          content.references.push({ url: url, hash: StringUtil.hashcode(url) });
        } else if (domNode.type == "text") {
          let newText = domNode.data;
          console.log("-", newText);
          if (mentionState == 1 && newText.trim().length) {
            // console.log("2", newText.length);
            mentionState = 2;
          } else if (mentionState == 2 && newText.trim().length) {
            // console.log("0");
            mentionState = 0;
            if (newText.startsWith(" ")) newText = newText.substring(1);
          } else if (mentionState == 3 && newText.trim().length) {
            console.log("-3", newText);
            mentionState = 0;
            newText = "";
          }
          text += newText;
        }
        return domNode;
      },
    });
    html = ReactDOMServer.renderToStaticMarkup(a);
    html = html.substring(5, html.length - 6);

    if (content.cards && content.cards.length > 0) {
      if (waitPopup) waitPopup.setLabel("카드뉴스 업로드 중...");
      let cards = [];
      for (let i = 0; i < content.cards.length; i++) {
        if (!content.cards[i].base64) {
          cards.push(content.cards[i]);
          continue;
        }
        let data: any = { path: "board" };
        data.base64 = content.cards[i].base64;
        let card: Attachment = {
          name: content.cards[i].name,
          description: content.cards[i].description,
        };
        try {
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
          if (result && !result.error) {
            let url = result.file;
            card.url = url;
            cards.push(card);
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml upload : ", result.file);
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
      content.cards = cards;
      content.image = cards[0].url;
    }

    if (waitPopup) waitPopup.setLabel("이미지 업로드 중...");
    for (let i = 0; i < innerImages.length; i++) {
      let data: any = { path: "board" };
      if (innerImages[i].startsWith("data:image")) {
        data.base64 = innerImages[i];
      } else if (innerImages[i].startsWith("http://")) {
        data.base64 = await loadImageUrlBase64(innerImages[i]);
      }
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, token);
        if (result && !result.error) {
          let url = result.file;
          content.imagesInner.push(url);
          if (!content.image) content.image = url;
          html = html.replace(`-#-INNER_IMAGE-${i}-#-`, url);
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml upload : ", result.file);
          // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        } else {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml failed", result);
        }
      } catch (error) {
        log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContentHtml : failed", error);
        // this.props.navigation.push("AuthRegister");
      }
    }

    let images: string[] = [];
    if (content.images) images = [...content.images];

    // console.log("html", html);
    // html = html.replace(/[\n]/gi,'');
    content.bodyHtml = html;
    // console.log("text", text.trim())
    content.bodyText = text;

    let files: Attachment[] = [];
    if (content.files) files = [...content.files];
    if (content.filesLocal && content.filesLocal.length > 0) {
      if (waitPopup) waitPopup.setLabel("파일 업로드 중...");
      for (let i = 0; i < content.filesLocal.length; i++) {
        const data = new FormData();
        log(LogLevel.UI_ACTION, content.filesLocal[i]);
        data.append("file", content.filesLocal[i]);
        try {
          let result = await fetchAPI(API.UPLOAD_FILE, "board", null, data, getGlobal(GlobalKey.TOKEN));
          if (result && result.message && result.message == "success") {
            let url = result.file;
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent upload : ", result.file);
            files.push({
              url: url,
              name: content.filesLocal[i].name.replace(/\?/g, ""),
              size: content.filesLocal[i].size,
            });
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
          } else {
            log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent failed", result);
          }
        } catch (error) {
          log(LogLevel.UI_ACTION, "BoardUtil.fetchAddContent : failed", error);
          // this.props.navigation.push("AuthRegister");
        }
      }
    }

    content.filesLocal = [];

    if (waitPopup) waitPopup.setLabel("글 작성 중...");

    if (!content.isAnonymous) content.isAnonymous = false;
    content.images = images; // 첨부 이미지
    content.files = files; // 첨부 파일
    log(LogLevel.UI_ACTION, "fetchUpdateContentHtml:", content);
    let result = await fetchAPI(API.BOARD_UPDATE, "", null, content, getGlobal(GlobalKey.TOKEN));
    if (result && result.message && result.message == "success") {
      return content;
    } else {
      log(LogLevel.UI_ACTION, "BoardUtil.fetchUpdateContent : failed", result, API.BOARD_UPDATE, content);
    }
    return null;
  }

  static generateHtml(content: any): any {
    var entityMap = {
      "<": "&lt;",
      ">": "&gt;",
      // '&': '&amp;',
      // '"': '&quot;',
      // "'": '&#39;',
      // '/': '&#x2F;',
      // '`': '&#x60;',
      // '=': '&#x3D;'
    };
    const linkRegex = /(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-.@:%_+~#=]+)+((\.[0-9:a-zA-Z]{2,5})+)?(\/(.)*)?(\?(.)*)?/gi;
    content.references = [];
    if (content.bodyText) {
      let html = content.bodyText;
      html = html.replace(/[<>]/g, (s) => entityMap[s]); // excape html tag <>
      html = html.replace(linkRegex, (match, key) => {
        log(LogLevel.UI_DATA_LOAD, "generateHtml. link match ", match);
        let url = match;
        if (!url.startsWith("http")) url = "https://" + url;

        if (url.startsWith(MY_ADDRESS)) {
          let subs = url.replace(MY_ADDRESS, "&&&");
          url = subs;
        }
        content.references.push({ url: url, hash: StringUtil.hashcode(url) });
        log(LogLevel.UI_DATA_LOAD, "generateHtml. link match ", match, url);

        return "<a href='" + url + "'>" + url + "</a>";
      }); // link
      html = html.replace(/\n/gi, "<br/>");
      content.bodyHtml = html;
    } else {
      content.bodyHtml = "";
    }
    return content;
  }

  static convertTextToHtml(text: string): string {
    let html = "";

    if (text) {
      var entityMap = {
        "<": "&lt;",
        ">": "&gt;",
        // '&': '&amp;',
        // '"': '&quot;',
        // "'": '&#39;',
        // '/': '&#x2F;',
        // '`': '&#x60;',
        // '=': '&#x3D;'
      };
      const linkRegex = /(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-.@:%_+~#=]+)+((\.[0-9:a-zA-Z]{2,5})+)?(\/(.)*)?(\?(.)*)?/gi;

      html = text;
      html = html.replace(/[<>]/g, (s) => entityMap[s]); // excape html tag <>
      html = html.replace(linkRegex, (match, key) => {
        log(LogLevel.UI_DATA_LOAD, "generateHtml. link match ", match);
        let url = match;
        if (!url.startsWith("http")) url = "https://" + url;

        if (url.startsWith(MY_ADDRESS)) {
          let subs = url.replace(MY_ADDRESS, "&&&");
          url = subs;
        }
        return "<a href='" + url + "'>" + url + "</a>";
      }); // link
      html = html.replace(/\n/gi, "<br/>");
    }
    return html;
  }

  static checksum(content: BoardContent) {
    let checksum =
      content.id * 100 +
      content.likeCnt +
      content.dislikeCnt +
      (content.replyCnt ? content.replyCnt : 0) +
      (content.scrapCnt ? content.scrapCnt : 0) +
      (content.viewCnt ? content.viewCnt : 0) +
      (content.bodyHtml ? 10 : 0);
    if (content.comments) {
      for (let i = 0; i < content.comments.length; i++) checksum += content.comments[i].likeCnt;
    }
    if (content.replies) {
      for (let i = 0; i < content.replies.length; i++) checksum += BoardUtil.checksum(content.replies[i]);
    }
    content.checksum = checksum;

    // console.log("checksum", checksum, content.id, content.scrapCnt, content.viewCnt, content.likeCnt, content.dislikeCnt)
    return checksum;
  }

  static countAllLike(content: BoardContent) {
    let likes = content.likeCnt;
    if (content.replies) {
      for (let i = 0; i < content.replies.length; i++) likes += content.replies[i].likeCnt;
    }
    content.likeCntAll = likes;

    // console.log("checksum", checksum, content.id, content.scrapCnt, content.viewCnt, content.likeCnt, content.dislikeCnt)
    return likes;
  }
}
