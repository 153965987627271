import React, { Component } from "react";
import "./ViewerBodyPopup.css";
import "./../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonModal, IonIcon, IonInput, IonList, IonItem, IonSpinner } from "@ionic/react";
import { fetchAPI } from "./../utils/APIUtil";
import * as API from "./../API.json";
import Skeleton from "react-loading-skeleton";
import smileIcon from "./../assets/icon/smile.svg";
import { KeywordType, Medicine, Ingredient } from "../models/Model.Medicine";
import { compose } from "redux";
import { connect, connectAdvanced } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";
import ViewerBody from "./ViewerBody";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import ReplyList from "./ViewerReplyList";
import { UIServiceType } from "../store/ui/types";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import Popup from "./molecules/Popup";

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    parentId?: number;
    isDiscussion?: boolean;
    full?: boolean;
    closeText?: string;
    noToolbar?: boolean;
    onJumpTo?: (id) => void;
  };

type State = {
  visible: boolean;
  parentId: number;
};

class ViewerBodyPopup extends Component<Props, State> {
  dismissedCallback = null;

  state = {
    visible: false,
    parentId: 0,
  };

  constructor(props: Props) {
    super(props);
    if (this.props.parentId && !this.props.contents[this.props.parentId]) this.props.loadContent(this.props.parentId);
  }

  show = (parentId: number = 0) => {
    log(LogLevel.NONE, "ViewerBodyPopup  :show");
    if (parentId && !this.props.contents[parentId]) this.props.loadContent(parentId);
    this.setState({ visible: true, parentId });
    this.props.backKeyControl.pushListener(() => {
      this.hide();
      return true;
    });
  };

  hide = () => {
    log(LogLevel.NONE, "ViewerBodyPopup  :hide");
    this.setState({ visible: false });
    this.props.backKeyControl.popListener();
  };

  onDone = () => {
    this.hide();
  };

  isShown = () => {
    return this.state.visible;
  };

  onClick = (url: string) => {
    if (url) window.open(url, getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system");
  };

  jump = () => {
    this.hide();

    let parentId = this.props.parentId;
    if (this.state.parentId) parentId = this.state.parentId;

    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "COMPOSER_SELECT_CONTENT", "게시물_질문작성_추천_이동", {
      게시물id: parentId,
    });

    if (this.props.onJumpTo) this.dismissedCallback = this.props.onJumpTo;
  };

  onDidDismiss = () => {
    this.setState(() => ({ visible: false }));
    if (this.dismissedCallback) {
      let parentId = this.props.parentId;
      if (this.state.parentId) parentId = this.state.parentId;
      this.dismissedCallback(parentId);
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ViewerBodyPopup  : render");
    let parentId = this.props.parentId;
    if (this.state.parentId) parentId = this.state.parentId;

    let seeParentTerm = "질문 숨기기";
    if (this.props.isDiscussion) seeParentTerm = "발제 숨기기";
    if (this.props.closeText) seeParentTerm = this.props.closeText;

    let gotoOriginal;
    if (this.props.onJumpTo)
      gotoOriginal = (
        <IonButton color="viewer-body-hide-parent" onClick={this.jump}>
          <div className="viewer-body-hide-parent">보기로 이동 &gt;</div>
        </IonButton>
      );

    let replies, body;
    if (this.props.contents[parentId]) {
      body = (
        <ViewerBody
          postingId={parentId}
          noComment={this.props.noToolbar}
          open={this.props.noToolbar}
          noToolbar={this.props.noToolbar}
          noNavigate={true}
          isOnlyQuestion
        />
      );
      if (this.props.full) {
        replies = (
          <ReplyList
            postingId={parentId}
            noComment={this.props.noToolbar}
            open={this.props.noToolbar}
            noToolbar={this.props.noToolbar}
            noNavigate={true}
          />
        );
      }
    } else {
      body = (
        <div className="common-container-column common-flex-align-center">
          <IonSpinner class="progress-spinner" name="lines-small" />
        </div>
      );
    }

    return (
      // <IonModal cssClass="viewer-body-popup-container"
      //   isOpen={this.state.visible}
      //   backdropDismiss = {true}
      //   onDidDismiss={this.onDidDismiss}
      // >

      //   <div className="viewer-body-popup-inner"  onClick = {(e) => {e.stopPropagation()}}>
      //     <div className="common-container-row viewer-body-header-container" >
      //       <div className="common-flex-grow"/>
      //       <IonButton color="viewer-body-hide-parent" onClick={this.hide}>
      //         <div className="viewer-body-hide-parent">{seeParentTerm}</div>
      //       </IonButton>
      //       {gotoOriginal}
      //     </div>
      //     {body}
      //     {replies}
      //   </div>
      // </IonModal>
      <>
        {this.state.visible && (
          <Popup onClose={this.onDidDismiss} closeOnBackDrop maxHeight="80vh">
            {body}
          </Popup>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  contents: state.board.contents,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadContent: (id: number) => actions.board.getContent(id),
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ViewerBodyPopup);
