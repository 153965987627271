import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IonSpinner } from '@ionic/react'
import { SeminarLecture, SeminarLectureComment } from '../../../models/Model.Seminar';
import { Attachment } from '../../../models/Model.Board';
import Header from '../../molecule/Header';
import Body from '../../molecule/Body';
import StringUtil from '../../../utils/StringUtil';
import Scroll from '../../molecule/Scroll';
import { log, LogLevel } from '../../../utils/LogUtil';
import { ScrollAction } from '../../molecule/Scroll';
import Video from '../../atom/Video';
import VideoController from '../../cell/VideoController';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import IconButton from '../../atom/IconButton';
import Footer from '../../molecule/Footer';
import TextButton from '../../atom/TextButton';
import Badge from '../../atom/Badge';
import Text from '../../atom/Text';
import Icon from '../../atom/Icon';
import { extractTimeToJTag, extractTimeValue, getDateTimeDifferent, getTimerString } from '../../../utils/TimeUtil';
import color from '../../_design/color';
import StarScore from '../../atom/StarScore';
import { TabBar } from '../../cell/TabBar';
import Spinner from '../../atom/Spinner';
import Avatar from '../../atom/Avatar';
import ReactHtmlParser from 'react-html-parser'
import SeminarViewReview from './SeminarViewReview';
import SeminarListItem from './SeminarListItem';
import COLOR from '../../_design/color';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from '../../../store';
import {MY_ADDRESS} from './../../../config.json'
import ViewerLink from '../../molecule/ViewerLink';
import BoardUtil from '../../../utils/BoardUtil';
import typography from '../../_design/typography';
import ViewerAttachment from '../../molecule/ViewerAttachment';
import TextToggleButton from '../../atom/TextToggleButton';
import { UserLevel } from '../../../models/Model.User';


interface ContainerProps {
  depth: number;
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  padding-left: ${(props) => (24 + props.depth * 32)}px;
  padding-right: 24px;

  .seminar-view-comment-body{
    padding-left: 32px;
  }

  .seminar-view-comment-inner{
    border-top: 1px solid ${COLOR.Gray7};
    padding-top: 16px;
  }

  ${typography.Body1}
  color:${COLOR.Gray1};
`

interface Props {
  comment:SeminarLectureComment,
  index: number,
  onReply?: (comment: SeminarLectureComment) => void,
  onLike?: (comment: SeminarLectureComment, index) => void,
  onMore?: (comment: SeminarLectureComment, index) => void,
  commentToFocus?: number,
  onFocus?: (el) => void;
}
const SeminarViewComment: FC<Props> = ({
  comment,
  index,
  onReply,
  onLike,
  onMore,
  commentToFocus,
  onFocus,
}) => {
  const el = useRef(null);
  const level = useSelector((state:RootState) => state.user.me.level);

  const transform = (node, index) => {
    if(node.type =='text'){
      let str = node.data;
      let paragraphs = str.split('\n');
      let rvalue = paragraphs[0];
      for(let i=1; i<paragraphs.length; i++){
        rvalue += <br/>;
        rvalue += paragraphs[i];
      }
      return rvalue;
    }
    if(node.type== 'tag' && node.name == 'br'){
      return <br key={`${index}`} />;
    }

    if(node.type== 'tag' && node.name == 'a'){
      return <ViewerLink url={node.attribs.href}/>
    }
    return undefined;
  }

  const onClickImage = () => {
    const windowAny:any = window;
    let image = StringUtil.convertFilePath(comment.imageUrl);
    log(LogLevel.UI_ACTION, "SeminarViewComment:onClickImage", image)
    let os = getGlobal(GlobalKey.OS);
    if( os && os != "browser" && windowAny.PhotoViewer && !(os=="ios" && image.startsWith("data:image/"))){
      try{
        windowAny.PhotoViewer.show(StringUtil.convertFilePath(image));
      }
      catch(e){
      }
    }
  }

  const onRef = (ref) => {
    el.current = ref;
    if(onFocus && comment.id == commentToFocus){
      onFocus(el.current);
    }
  }

  useEffect(() => {
    log(LogLevel.UI_EVENT, "SeminarViewComment:useEffect[commentToFocus, comment]");
    if(onFocus && el.current && comment.id == commentToFocus){
      onFocus(el.current);
    }
  }, [commentToFocus, comment])
    
  if(!comment)
    return null;

  if(comment.deletedAt){
    return (
      <Container depth={comment.groupDepth}>
        <div className="seminar-view-comment-inner">
          <div className="common-container-row common-flex-align-center" style={{marginBottom:"4px"}}>
            <div style={{marginRight:"12px"}}>
              <Icon width={24} height={24} fill={COLOR.Gray4} name="Exclamation Mark Filled"/>
            </div>
            <Text type="Body1" color="Gray5">삭제된 댓글입니다</Text>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container depth={comment.groupDepth} ref={onRef}>
      <div className="seminar-view-comment-inner">
        <div className="common-container-row common-flex-align-center" style={{marginBottom:"4px"}}>
          <div style={{marginRight:"8px", height: "24px"}}>
            <Avatar size="S" src={comment.userProfileUrl} />
          </div>
          <div className="common-flex-grow">
            <Text type="Body2Bold" color="Gray1">{comment.userNickname}</Text>
          </div>
          {(comment.editable || level >= UserLevel.MANAGER) &&
            <IconButton iconName="More" size="S" variant="Ghost" colorValue={COLOR.Gray4} onClick={() => {if(onMore) onMore(comment, index)}}/>
          }
        </div>
        <div className="seminar-view-comment-body" style={{marginBottom:"12px"}}>
          {comment.bodyText &&
            <Text type="Body1" color="Gray1">{ReactHtmlParser(BoardUtil.convertTextToHtml(comment.bodyText), {transform:transform})}</Text>
          }
          {comment.fileName &&
            <ViewerAttachment attachment={{name:comment.fileName, size:comment.fileSize.toString(), url:comment.fileUrl} }  actionOnClick="download"/>
          }
          {comment.imageUrl &&
            <img src={StringUtil.convertFilePath(comment.imageUrl)} onClick={onClickImage}/>
          }
        </div>
        <div className="seminar-view-comment-body common-container-row common-flex-align-center">
          <div style={{marginRight:"8px"}}>
            <Text type="Label" color="Gray4">{getDateTimeDifferent(comment.createdAt)}</Text>
          </div>
          <TextToggleButton iconLeftName="Heart" iconCheckedColor="Red" variant="Ghost" size="S" checked={comment.liked} onClick={() => onLike(comment, index)}>좋아요 {comment.likeCnt>0?comment.likeCnt:""} </TextToggleButton>
          {(comment.groupDepth < 1) && 
            <TextToggleButton iconLeftName="Comment Filled" variant="Ghost" size="S" checked={false} onClick={() => {if(onReply)onReply(comment)}}>댓글달기</TextToggleButton>
          }
        </div>
      </div>
    </Container>
  )
}



export default React.memo(SeminarViewComment, (props, nextProps) => { 
  if(props.comment != nextProps.comment || ((props.comment.id == props.commentToFocus) != (nextProps.commentToFocus == nextProps.comment.id)))
    return false;
  return true;
})