import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import Icon from '../../atom/Icon'
import { rippleEvent } from '../../_util/RrppleEffect'

type Type = 'List' | 'Inline'
type IconType = "" | 'Radio' | 'Check'
type IconPositionType = "" | 'right' | 'left'

const RADIO_STYLE_TYPE = {
  'List': {
    style:`
      width: 100%;
      height: 50px;
      padding: 0px;
      background-color: ${COLOR.White};
      border:  none;
    `,
    typography:"ButtonL",
    icon:{
      size: "20",
      color: COLOR.Gray6,
      colorChecked: COLOR.Primary,
    },
    text:{
      color: "Gray2",
      colorChecked: "Primary",
    },
  },
  'Inline': {
    style:`

    `,
    typography:"ButtonL",
    icon:{
      size: "20",
      color: COLOR.Primary,
      colorChecked: COLOR.Gray6,
    },
    text:{
      color: "Gray2",
      colorChecked: "Primary",
    },
  },
}
interface WrapperProps {
  type: Type
}

const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  overflow: hidden;
  position: relative;

  .radio-button-icon-left{
    margin-right: 12px;
  }

  .radio-button-icon-right{
    margin-left: 12px;
  }

  ${(props) => RADIO_STYLE_TYPE[props.type].style}

`

interface Props {
  type: Type
  iconType?: IconType
  iconPosition?: IconPositionType
  className?: string
  checked?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  needEffect?: boolean
}
const RadioButton: React.FC<Props> = ({
  type="List",
  iconType = "",
  iconPosition = "",
  checked,
  className,
  children,
  needEffect,
  onClick,
}) => {

  const el = useRef(null);
  const rippleEl = useRef(null);
  return (
    <Wrapper
      ref={el} 
      type={type}
      className={className?className+' common-effect-parent ':'common-effect-parent'}
      onClick={(e) => {
        if(needEffect){
          rippleEvent(e, el.current, rippleEl.current);
        }

        if(onClick) onClick(e);
      }}
    >
      <div className="common-container-row common-flex-align-center">
        {(iconType && iconPosition == 'left') &&
          <div className="radio-button-icon-left">
            <Icon width={RADIO_STYLE_TYPE[type].icon.size} height={RADIO_STYLE_TYPE[type].icon.size} name={(checked?iconType+"Checked":iconType)} fill={RADIO_STYLE_TYPE[type].icon[(checked?"colorChecked":"color")]}/>
          </div>
        }
        <div className="common-flex-grow">
          <Text type={RADIO_STYLE_TYPE[type].typography} color={RADIO_STYLE_TYPE[type].text[(checked?"colorChecked":"color")]}>
            {children}
          </Text>
        </div>
        {(iconType && iconPosition == 'right') &&
          <div className="radio-button-icon-right">
            <Icon width={RADIO_STYLE_TYPE[type].icon.size} height={RADIO_STYLE_TYPE[type].icon.size} name={(checked?iconType+"Checked":iconType)} fill={RADIO_STYLE_TYPE[type].icon[(checked?"colorChecked":"color")]}/>
          </div>
        }
      </div>

      <div  className="common-effect-hover"/>
      <div ref={rippleEl} className="common-effect-ripple"/>

    </Wrapper>
  )
}

export default RadioButton
