import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'

import { UIPopupType } from '../store/ui/types';

import WeeklyDigestNiceList from './component/WeeklyDigestNiceList';
import Viewer from '../pages/Viewer';

const AdminAPI = {        
  "GET_LIST" : {"method":"POST", "path":"/admin/seminar/lectures", "contentType":"application/json"}
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  contentId : any,
}

class WeeklyDigest extends Component<Props, State> {
  popup = null;
  state = {
    contentId: 0,
  };

  constructor(props: Props) {
    super(props);

  }

  componentDidMount() {
  }

  onSelect = (contentId:number) => {
    if(this.state.contentId == contentId)
      this.setState({contentId:0});
    else
      this.setState({contentId});
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "WeeklyDigest.render", this.props, this.state);
    
    let detail;

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                Weekly Digest
            </IonText>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            <div className="admin-grow-container">
              <WeeklyDigestNiceList onSelect={this.onSelect}/>
            </div>
            {
              this.state.contentId > 0 &&
                <div id="admin-sub-conainer" className="admin-container">
                  <Viewer contentId={this.state.contentId} onBack={() => this.setState({contentId:0})}/>
                </div>
            }
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures : state.seminar.lectures,
  progressPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath : state.board.filePath,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId:number) => actions.seminar.getSeminarLecture(lectureId),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WeeklyDigest));