import React from 'react';
import profileImg from '../../../assets/image/profile_m_1.png'
import profileAnonymous from '../../../assets/image/profile_anonymous.png'
import StringUtil from '../../../utils/StringUtil';
import styled from "styled-components";
import { COLOR_SYSTEM } from '../../design/design-system';

type AvatarSize =  "XSmall" | "Small" | "Medium" | "Large" | "XLarge";

const SIZE = {
  "XSmall": 16,
  "Small": 24,
  "Medium": 36,
  "Large": 48,
  "XLarge": 60,
}

export interface AvatarProps {
  className? : string;
  size?: AvatarSize;
  base64? : string;
  url? : string;
  errorUrl?: string;
  anonymous? : boolean;
  border?:boolean;
  onClick? : () => any;
};

const BadgeWrapper = styled.img<AvatarProps>`
  height: ${({ size }) => {return `${SIZE[size]}px`;}};
  width: ${({ size }) => {return `${SIZE[size]}px`;}};
  border-radius: ${({ size }) => {return `${SIZE[size]/2}px`;}};
  ${({ border }) => (border?`border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};`:"")}
`;

const Avatar = ({className, base64, url, anonymous, onClick, errorUrl, size="Medium", border=false}:AvatarProps) => {
  if(anonymous){
    return (
      <BadgeWrapper border={border} size={size} className={className} src={profileAnonymous} onClick={() => {if(onClick)onClick()}}  onError={(e)=>{let targetAny:any = e.target; targetAny.src=(errorUrl)?errorUrl:profileImg}}/>
    );
  }
  else if (base64){
    return (
      <BadgeWrapper border={border} size={size} className={className} src={base64}  onClick={() =>{if(onClick)onClick()}} onError={(e)=>{let targetAny:any = e.target; targetAny.src=(errorUrl)?errorUrl:profileImg}}/>
    );
  }
  else if (url) {
    let profilePath = url;
    profilePath = StringUtil.convertFilePath(profilePath);

    if(profilePath.startsWith("http://k.kakaocdn.net/")){
      profilePath = profilePath.replace("http://k.kakaocdn.net/", "https://k.kakaocdn.net/");
    }
    return (
      <BadgeWrapper border={border} size={size} className={className} src={profilePath}  onClick={() => {if(onClick)onClick()}} onError={(e)=>{let targetAny:any = e.target; targetAny.src=(errorUrl)?errorUrl:profileImg}}/>
    );
  }
  else {
    return (
      <BadgeWrapper border={border} size={size} className={className} src={(errorUrl)?errorUrl:profileImg}  onClick={() => {if(onClick)onClick()}} onError={(e)=>{let targetAny:any = e.target; targetAny.src=(errorUrl)?errorUrl:profileImg}}/>
    );
  }
}

export default Avatar;