import React, { useEffect, useState } from "react";
import Home from "./Home";
import Boards from "./Boards";
import "./Main.css";
import "./../Common.css";
import MyProfile from "./MyProfile";
import MyProfileChange from "./MyProfileChange";
import Setting from "./Setting";
import SettingNotification from "./SettingNotification";
import Search from "./Search";
import Menu from "./Menu";
import * as API from "./../API.json";

import {
  IonTabs,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTab,
  IonPage,
  IonToast,
  IonBadge,
} from "@ionic/react";
import { Route, Redirect, withRouter, RouteComponentProps } from "react-router";
import MyScrap from "./MyScrap";
import Notifications from "./Notifications";
import ScholarSearch from "./ScholarSearch";
import Notice from "./Notice";
import Information from "../page/InformationViewer";
import { LogLevel, log } from "../utils/LogUtil";
import { getGlobal, GlobalKey, setGlobal } from "../utils/GlobalUtil";
import iconBoard from "./../assets/icon/board.svg";
import iconSearch from "./../assets/icon/search.svg";
import iconJobpost from "./../assets/icon/jobpost.svg";
import iconMedicine from "./../assets/icon/medicine.svg";
import bagIcon from "./../assets/icon/shopping_bag.svg";

import { actions, RootState } from "../store";
import { connect } from "react-redux";
import { TARGET } from "./../config.json";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
// import SearchMedicine from "./SearchMedicine";
import SearchBoard from "./Search";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import { STATUS_CODES } from "http";
import { fetchAPI } from "../utils/APIUtil";
import { UserWorkType } from "../models/Model.User";
import { JobListType } from "../store/jobpost/types";
import {
  Sdk,
  SendBirdSelectors,
  sendBirdSelectors,
  SendBirdState,
  useSendbirdStateContext,
  withSendBird,
} from "sendbird-uikit";
import { PrivateRoute } from "../utils/Routing";
import AnalyticsUtil from "../utils/AnalyticsUtil";
import JobPostList from "./JobPostList";
import JobPostDetail from "./JobPostDetail";
import JobPostRegistration from "./JobPostRegistration";
import { displayTabbar } from "../utils/JobPostUtil";
import Text from "../components/atoms/Text";
import { COLOR_SYSTEM } from "../components/design/design-system";
import Icon from "../components/atoms/Icon";
import SeminarListViewer from "../page/Scholar/SeminarListViewer";
import AllInOneHome from "./AllInOneHome";
import PartnersMain from "./PartnersMain";

type Props = RouteComponentProps<{}> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {};

type State = {
  showMenu: boolean;
};

const windowAny: any = window;

class AppStack extends React.Component<Props, State> {
  waitToQuit: boolean = false;
  menu = null;

  sendbirdLoaded: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      showMenu: getGlobal(GlobalKey.SHOW_MENU),
    };
  }

  componentDidMount() {
    log(LogLevel.UI_EVENT, "AppStack:componentDidMount backbutton register");
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
    // document.addEventListener("backbutton", this.goBack);

    // this.intializeSendbird();
  }

  goBack = (e) => {
    let os = getGlobal(GlobalKey.OS);
    log(
      LogLevel.UI_ACTION,
      "AppStack:goBack",
      os,
      this.props.location.pathname,
      windowAny.navigator,
      windowAny.navigator.app,
      this.waitToQuit
    );
    if (this.state.showMenu) {
      this.onCloseMenu();
    } else if (
      os &&
      os != "browser" &&
      this.props.location.pathname === "/main/boards" &&
      windowAny.navigator &&
      windowAny.navigator.app
    ) {
      if (this.props.confirmPopup && this.props.confirmPopup.isShown()) {
        this.props.confirmPopup.hide();
        return;
      }
      if (this.props.keywordPopup && this.props.keywordPopup.isShown()) {
        this.props.keywordPopup.hide();
        return;
      }

      if (this.waitToQuit) {
        log(
          LogLevel.UI_ACTION,
          "AppStack:goBack done",
          os,
          this.props.location.pathname,
          windowAny.navigator,
          windowAny.navigator.app
        );
        windowAny.navigator.app.exitApp();
      } else {
        this.waitToQuit = true;
        setTimeout(() => {
          this.waitToQuit = false;
        }, 2000);
        this.props.toastPopup.show("'뒤로'버튼을 한번 더 누르면 종료됩니다.");
      }
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount() {
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    log(
      LogLevel.UI_EVENT,
      "AppStack:componentWillUnmount backbutton unregister"
    );
    // document.removeEventListener("backbutton", this.goBack);
    // if (
    //   ABTestUtil.isTest(ABTestFeature.CHAT) &&
    //   this.sendbirdLoaded &&
    //   this.props.sdk &&
    //   this.props.sdk.removeChannelHandler
    // ) {
    //   this.props.sdk.removeChannelHandler("ChatUnreadBadge");
    //   log(
    //     LogLevel.UI_EVENT,
    //     "AppStack:componentWillUnmount sendbird finalized"
    //   );
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
    log(
      LogLevel.UI_EVENT,
      "AppStack:componentDidUpdate",
      prevProps.location.pathname,
      this.props.location.pathname
    );
    if (prevProps.location.pathname != this.props.location.pathname) {
      if (this.props.location.pathname == "/main/jobpost") {
        log(
          LogLevel.UI_EVENT,
          "AppStack:componentDidUpdate jobpostVisit",
          this.props.location.pathname
        );
        this.props.jobpostVisit();
        fetchAPI(
          API.JOBPOST_ACCESS,
          "",
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        if (this.props.me.workType == UserWorkType.PHARMACY_OWNER) {
          this.props.reloadList(JobListType.OFFER | JobListType.MY);
        }

        if (
          this.props.me &&
          this.props.me.workType != UserWorkType.PHARMACY_OWNER &&
          !this.props.me.jobpostStatus
        ) {
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_AMPLITUDE,
            "JOBPOST_SETTING",
            "구인구직_약사_설정이동",
            this.state
          );

          setTimeout(() => {
            this.props.history.push("/jobpost/setting");
          }, 100);
        }
        // else{
        //   this.props.reloadList(JobListType.APPLY|JobListType.MY);
        //   this.props.reloadList(JobListType.OFFER|JobListType.RECOMMENDED);
        // }
      } else if (prevProps.location.pathname == "/main/jobpost") {
        log(
          LogLevel.UI_EVENT,
          "AppStack:componentDidUpdate jobpostLeave",
          this.props.location.pathname
        );
        this.props.jobpostLeave();
        displayTabbar();
      }
    }

    // this.intializeSendbird();
  }

  // intializeSendbird = async () => {
  //   if (
  //     ABTestUtil.isTest(ABTestFeature.CHAT) &&
  //     !this.sendbirdLoaded &&
  //     this.props.sdk &&
  //     this.props.sdk.getTotalUnreadMessageCount &&
  //     this.props.sdk.ChannelHandler &&
  //     this.props.sdk.addChannelHandler
  //   ) {
  //     this.props.sdk
  //       .getTotalUnreadMessageCount()
  //       .then((count) => {
  //         this.setState({ chatCount: count });
  //       })
  //       .catch((e) => {
  //         log(
  //           LogLevel.UI_EXCEPTION,
  //           "AppStack:componentDidMount:getTotalUnreadMessageCount",
  //           e
  //         );
  //       });

  //     const channelHandler = new this.props.sdk.ChannelHandler();
  //     channelHandler.onMessageReceived = (channel, message) => {
  //       this.props.sdk
  //         .getTotalUnreadMessageCount()
  //         .then((count) => {
  //           this.setState({ chatCount: count });
  //         })
  //         .catch((e) => {
  //           log(LogLevel.UI_EXCEPTION, "AppStack:componentDidMount:", e);
  //         });
  //     };
  //     channelHandler.onMessageUpdated = (channel, message) => {
  //       this.props.sdk
  //         .getTotalUnreadMessageCount()
  //         .then((count) => {
  //           this.setState({ chatCount: count });
  //         })
  //         .catch((e) => {
  //           log(LogLevel.UI_EXCEPTION, "AppStack:componentDidMount:", e);
  //         });
  //     };
  //     this.props.sdk.addChannelHandler("ChatUnreadBadge", channelHandler);

  //     this.sendbirdLoaded = true;

  //     let user = this.props.sdk.currentUser;

  //     var data = {
  //       id: this.props.me.id.toString(),
  //       name: this.props.me.name,
  //       nickname: this.props.me.nickname,
  //       phone: this.props.me.phone,
  //     };

  //     if (!user.metaData["id"]) {
  //       user.createMetaData(data, (response, error) => {
  //         log(
  //           LogLevel.UI_EVENT,
  //           "AppStack:componentDidUpdate sendbird initializing meta create",
  //           user.metaData,
  //           response,
  //           error
  //         );
  //       });
  //     } else {
  //       user.updateMetaData(data, true, (response, error) => {
  //         log(
  //           LogLevel.UI_EVENT,
  //           "AppStack:componentDidUpdate sendbird initializing meta update",
  //           user.metaData,
  //           response,
  //           error
  //         );
  //       });
  //     }

  //     log(
  //       LogLevel.UI_EVENT,
  //       "AppStack:componentDidUpdate sendbird initialized",
  //       user.metaData
  //     );

  //     // let notiToken = getGlobal(GlobalKey.NOTIFICATION_KEY, true);
  //     // if(notiToken){
  //     //   try {
  //     //     const response = this.props.sdk.registerGCMPushTokenForCurrentUser(notiToken);
  //     //     log(LogLevel.UI_EVENT, "AppStack:componentDidMount:unregisterGCMPushTokenForCurrentUser", response, notiToken);
  //     //   } catch (error) {
  //     //     log(LogLevel.UI_EXCEPTION, "AppStack:componentDidMount:unregisterGCMPushTokenForCurrentUser", error, notiToken);
  //     //   }
  //     // }
  //   }
  // };

  onOpenMenu = () => {
    log(LogLevel.UI_ACTION, "Main:onOpenMenu", this.menu);
    setGlobal(GlobalKey.SHOW_MENU, true);
    this.setState({ showMenu: true });
  };

  onCloseMenu = () => {
    log(LogLevel.UI_ACTION, "Main:onCloseMenu", this.menu);
    setGlobal(GlobalKey.SHOW_MENU, false);
    this.setState({ showMenu: false });
  };

  render() {
    let notiCount;
    if (this.props.notiCount) {
      notiCount = (
        <IonBadge color="appstack-notification-count">
          {this.props.notiCount}
        </IonBadge>
      );
    }

    let tabList = [];
    tabList.push(
      <IonTabButton key="0" tab="home" href="/main/boards">
        <Icon
          name="Home Filled"
          width={24}
          height={24}
          color={
            COLOR_SYSTEM.get("Gray")[
              this.props.location.pathname === "/main/boards" ? 900 : 200
            ]
          }
        />
        <Text
          textType="InputXSmall"
          color={
            COLOR_SYSTEM.get("Gray")[
              this.props.location.pathname === "/main/boards" ? 900 : 200
            ]
          }
        >
          홈
        </Text>
      </IonTabButton>
    );
    tabList.push(
      <IonTabButton key="1" tab="search-medicine" href="/main/search">
        <Icon
          name="MagnifyingGlass Filled"
          width={24}
          height={24}
          color={
            COLOR_SYSTEM.get("Gray")[
              this.props.location.pathname === "/main/search" ? 900 : 200
            ]
          }
        />
        <Text
          textType="InputXSmall"
          color={
            COLOR_SYSTEM.get("Gray")[
              this.props.location.pathname === "/main/search" ? 900 : 200
            ]
          }
        >
          검색
        </Text>
      </IonTabButton>
    );

    if (ABTestUtil.isTest(ABTestFeature.UI_PHARMACY_ALL_IN_ONE)) {
      if (ABTestUtil.isTest(ABTestFeature.UI_YMYD_PARTNERS)) {
        tabList.push(
          <IonTabButton key="4" tab="partners" href="/main/partners">
            <Icon
              name="Vip Filled"
              width={24}
              height={24}
              color={
                COLOR_SYSTEM.get("Gray")[
                  this.props.location.pathname === "/main/partners" ? 900 : 200
                ]
              }
            />
            <Text
              style={{ whiteSpace: "nowrap" }}
              textType="InputXSmall"
              color={
                COLOR_SYSTEM.get("Gray")[
                  this.props.location.pathname === "/main/partners" ? 900 : 200
                ]
              }
            >
              약사파트너스
            </Text>
            <div className="appstack-new-icon-badge">
              <Icon
                name="New Circle Filled"
                width={16}
                height={16}
                color={COLOR_SYSTEM.get("Red")[300]}
              />
            </div>
          </IonTabButton>
        );
      } else {
        tabList.push(
          <IonTabButton key="4" tab="allinone" href="/main/allinone">
            <Icon
              name="Vip Filled"
              width={24}
              height={24}
              color={
                COLOR_SYSTEM.get("Gray")[
                  this.props.location.pathname === "/main/allinone" ? 900 : 200
                ]
              }
            />
            <Text
              style={{ whiteSpace: "nowrap" }}
              textType="InputXSmall"
              color={
                COLOR_SYSTEM.get("Gray")[
                  this.props.location.pathname === "/main/allinone" ? 900 : 200
                ]
              }
            >
              약사파트너스
            </Text>
          </IonTabButton>
        );
      }
    }

    tabList.push(
      <IonTabButton key="2" tab="search" href="/main/lectures">
        <Icon
          name="Content Filled"
          width={24}
          height={24}
          color={
            COLOR_SYSTEM.get("Gray")[
              this.props.location.pathname === "/main/lectures" ? 900 : 200
            ]
          }
        />
        <Text
          textType="InputXSmall"
          color={
            COLOR_SYSTEM.get("Gray")[
              this.props.location.pathname === "/main/lectures" ? 900 : 200
            ]
          }
        >
          컨텐츠
        </Text>
      </IonTabButton>
    );
    if (ABTestUtil.isTest(ABTestFeature.UI_LOCAL_DEAL)) {
      let newCount;
      if (this.props.chatCount) {
        newCount = <div className="appstack-new-count-badge" />;
      }
      tabList.push(
        <IonTabButton key="3" tab="jobpost" href="/main/jobpost">
          <Icon
            name="Job Filled"
            width={24}
            height={24}
            color={
              COLOR_SYSTEM.get("Gray")[
                this.props.location.pathname === "/main/jobpost" ? 900 : 200
              ]
            }
          />
          <Text
            textType="InputXSmall"
            color={
              COLOR_SYSTEM.get("Gray")[
                this.props.location.pathname === "/main/jobpost" ? 900 : 200
              ]
            }
          >
            구인구직
          </Text>
          {newCount}
        </IonTabButton>
      );
    } else if (ABTestUtil.isTest(ABTestFeature.UI_JOB_POST)) {
      let newCount;
      if (this.props.jobpostNewCount) {
        newCount = (
          <div className="appstack-new-count-badge">
            {this.props.jobpostNewCount}
          </div>
        );
      }
      tabList.push(
        <IonTabButton key="3" tab="jobpost" href="/main/jobpost">
          <Icon
            name="Job Filled"
            width={24}
            height={24}
            color={
              COLOR_SYSTEM.get("Gray")[
                this.props.location.pathname === "/main/jobpost" ? 900 : 200
              ]
            }
          />
          <Text
            textType="InputXSmall"
            color={
              COLOR_SYSTEM.get("Gray")[
                this.props.location.pathname === "/main/jobpost" ? 900 : 200
              ]
            }
          >
            구인구직
          </Text>
          {newCount}
        </IonTabButton>
      );
    } else {
      tabList.push(
        <IonTabButton key="3" tab="bookmark" href="/main/bookmark">
          <IonIcon class="appstack-icon" name="bookmark" />
          <IonLabel class="appstack-icon-label">스크랩북</IonLabel>
        </IonTabButton>
      );
    }

    if (ABTestUtil.isTest(ABTestFeature.UI_MENU)) {
      tabList.push(
        <IonTabButton key="5" onClick={this.onOpenMenu}>
          <Icon
            name="User Filled"
            width={24}
            height={24}
            color={COLOR_SYSTEM.get("Gray")[200]}
          />
          <Text
            textType="InputXSmall"
            color={COLOR_SYSTEM.get("Gray")[200]}
            style={{ whiteSpace: "nowrap" }}
          >
            마이페이지
          </Text>
        </IonTabButton>
      );
    } else {
      tabList.push(
        <IonTabButton key="5" tab="user" href="/main/user">
          <IonIcon class="appstack-icon" name="person" />
          <IonLabel class="appstack-icon-label">내페이지</IonLabel>
        </IonTabButton>
      );
    }

    let tab;
    tab = (
      <IonTabBar color="appstack" class="appstack-tabbar" slot="bottom">
        {tabList}
      </IonTabBar>
    );

    return (
      <div className="common-content common-position-relative">
        <IonPage>
          <Route
            exact
            path="/main/home"
            render={() => <Redirect to="/main/boards" exact={true} />}
          />
          <Route
            exact
            path="/main"
            render={() => <Redirect to="/main/boards" />}
          />
          {/**
           * Only render exact matches.  Only destroy on back button click
           * On history.push keep previous route stored for back button
           *
           * TabBar does a push on iontabbutton click.
           * TabBar updates the tab links based on the current route path.
           */}

          <IonTabs>
            <IonRouterOutlet>
              <Route path="/main/:tab(home)" component={Home} exact={true} />

              <Route
                path="/main/:tab(boards)"
                component={Boards}
                exact={true}
              />
              <Route
                path="/main/:tab(search)"
                component={SearchBoard}
                exact={true}
              />
              {/* <Route path="/main/:tab(medicinesearch)" component={SearchMedicine} exact={true} /> */}
              <Route
                path="/main/:tab(bookmark)"
                component={MyScrap}
                exact={true}
              />
              <Route
                path="/main/notification"
                component={Notifications}
                exact={true}
              />
              <Route path="/main/:tab(jobpost)" component={JobPostList} />
              {/* <Route path="/main/:tab(detail)" component={JobPostDetail} /> */}
              {/* <Route path="/main/:tab(register)" component={JobPostRegistration} /> */}
              <Route
                path="/main/:tab(user)"
                component={MyProfile}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/profile"
                component={MyProfileChange}
              />
              <Route path="/main/:tab(partners)" component={PartnersMain} />
              <Route path="/main/:tab(allinone)" component={AllInOneHome} />
              <Route
                path="/main/:tab(user)/setting"
                component={Setting}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/:type(notice)"
                component={Notice}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/notification"
                component={SettingNotification}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/feedback"
                component={Setting}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/:type(faq)"
                component={Notice}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/:type(privacy)"
                component={Information}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/:type(contract)"
                component={Information}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/:type(company)"
                component={Information}
                exact={true}
              />
              <Route
                path="/main/:tab(user)/setting/version"
                component={Setting}
                exact={true}
              />
              <Route
                path="/main/lectures"
                component={SeminarListViewer}
                exact={true}
              />
            </IonRouterOutlet>
            {tab}
          </IonTabs>
        </IonPage>
        <Menu visible={this.state.showMenu} onClose={this.onCloseMenu} />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  notiCount: state.notification.notiCount,
  chatCount: state.notification.chatUnreadCount,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  keywordPopup: state.ui.popups[UIPopupType.KEYWORD_POPUP],
  me: state.user.me,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
  jobpostNewCount: state.jobpost.newCount,
});

// const mapSendbirdStateToProps = (state: SendBirdState) => ({
//   sdk: sendBirdSelectors.getSdk(state),
// });

const mapDispatchToProps = {
  jobpostVisit: () => actions.jobpost.visit(),
  jobpostLeave: () => actions.jobpost.visitLeave(),
  reloadList: (type: number) => actions.jobpost.reloadList(null, type),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppStack));
