import React from "react";
import ComponentInvite from "./ComponentInvite";
import { log, LogLevel } from "../utils/LogUtil";
import ComponentUserInfo from "./ComponentUserInfo";
import ComponentBanner from "./ComponentBanner";
import ComponentLink from "./ComponentLink";

export default class ComponentGenerator {
    static components = {
        invite: ComponentInvite,
        userinfo: ComponentUserInfo,
        banner: ComponentBanner,
        link: ComponentLink
    }

    static generate(data:any): any {
        let props:any = {};
        let advertiserInfo: any;
        for(let val in data){
            if(val.startsWith("data-advertizer-")){
                if(!advertiserInfo)
                    advertiserInfo = {};
                let name = val.substring(16).split("-").map(token => token.replace(/\w\S*/g, (txt) => (txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()))).join("").replace(/\w\S*/g, (txt) => (txt.charAt(0).toLowerCase() + txt.substr(1)));
                advertiserInfo[name] = data[val];
            }
            else if(val.startsWith("data-")){
                let name = val.substring(5);
                props[name] = data[val];
            }
        }
        if(advertiserInfo)
            props["advertiserInfo"] = advertiserInfo;

        log(LogLevel.UI_DATA_LOAD, 'ComponentGenerator:generate: ', data, props)
        if(data && data.class === "components" && props.component && ComponentGenerator.components[props.component]){
            // log(LogLevel.UI_DATA_LOAD, 'ComponentGenerator:generate: props', props)
            return React.createElement(this.components[props.component], {...props});
        }
        return null;
    }

    static validate(data:any) :boolean {
        if(!data.component)
            return false;
        switch(data.component){
            case "invite":
            case "userinfo":
            case "banner":
            case "link":
                return true;
        }
        return false;
    }
}
