import React, { useRef } from 'react';
import { log, LogLevel } from '../../../utils/LogUtil';
import "./../../../Common.css"
import "./style.scss"
import Text from './../../atom/Text'
import { rippleEvent } from '../../_util/RrppleEffect';

type TabBarLevel = '1' | '2' | '3'

const TAB_BAR_LEVEL = {
  "1": {
    typo: "Headline"
  },
  "2": {
    typo: "ButtonM"
  },
  "3": {
    typo: "SubHead"
  },
}
type Props = {
  level:TabBarLevel
  onIndexChanged?: (index:number, key:string) => void;
  index:number
  splitEven?
  children:any
}

export const TabBar: React.FC<Props> = ({
  level,
  onIndexChanged,
  children,
  splitEven,
  index
}) => {

  const childrenWithProps = React.Children.map(children, (child, myIndex) => {
    return <TabBarItem splitEven={splitEven?children.length:0} onClick={(e, key) => onIndexChanged(myIndex, key)} selected={(index == myIndex)} type={TAB_BAR_LEVEL[level].typo} keyChild={child.key}>{child}</TabBarItem>;
  });

  return (
    <div className={"tab-bar-container-"+level}>
      <div className={"tab-bar-items-container" + (splitEven?" common-container":"")}>
        {childrenWithProps}
      </div>
    </div>
  )
}

interface ItemProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, key:string) => void,
  selected?:boolean,
  splitEven: number,
  type?:string,
  keyChild?: string,
}
export const TabBarItem: React.FC<ItemProps> = ({
  children,
  selected,
  type,
  splitEven,
  onClick,
  keyChild,
}) => {
  const el = useRef(null);
  const rippleEl = useRef(null);
  
  return (
    <div className={"tab-bar-item-container" + (splitEven?"":" tab-bar-item-container-fit")} style={{width: splitEven?(100/splitEven)+"%":"unset"}}>
      <div className="tab-bar-item-container-inner">
        <button ref={el}  className={"common-effect-parent tab-bar-item" + (selected?" tab-bar-item-selected":"")}
          onClick={(e) => {
            rippleEvent(e, el.current, rippleEl.current);

            if(onClick) onClick(e, keyChild);
          }}
        >
          <Text type={type}>
            {children}
          </Text>
          <div ref={rippleEl} className="common-effect-ripple"/>
        </button>
      </div>
    </div>
  )
}

  