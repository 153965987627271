import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

const Header = styled.header`
  width: 100%;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Main = styled.main`
  height: 100vh;
  padding: 60px 0px 90px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;

  & > * + * {
    margin-top: 24px;
  }
`;

const Section = styled.section`
  margin-left: 24px;
  margin-right: 24px;

  &.setting__worktype,
  .workplace__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &.setting__workplace {
    & > * + * {
      display: block;
      margin-top: 8px;
    }
  }

  &.setting__manual {
    padding: 20px;
    background-color: ${COLOR_SYSTEM.get("Gray")[10]};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * + * {
      margin-left: 8px;
    }
  }

  .manual__icon {
    width: 18px;
    height: 21px;
  }

  &.setting__alim {
    & > * + * {
      display: block;
      margin-top: 8px;
    }
  }

  .alim__switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
  }

  &.setting__distance {
    & > * {
      display: block;
    }

    & > * + * {
      margin-top: 12px;
    }
  }

  &.setting__address-list {
    & > hr {
      width: 100%;
      margin: 12px 0;
      border-top: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
    }
  }

  &.setting__self-register {
    & > * + * {
      margin-top: 40px;
    }
  }
`;

const AddressItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const PharmacyList = styled.ul`
  max-height: calc(100vh - 280px);
  padding: 0;
  margin: 0;
  overflow-y: auto;

  & > hr {
    width: 100%;
    margin: 12px 0;
    border-top: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  }
`;

const Footer = styled.footer`
  width: 100%;
  height: 75px;
  line-height: 75px;
  padding: 0 24px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 201;

  button {
    width: 100%;
  }
`;

const WorkTypeBottomSheet = styled.div`
  height: 100%;

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    max-height: 100%;
    overflow-y: scroll;

    & > * + * {
      margin-top: 27px;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;
    }
  }
`;

const NaverMap = styled.div`
  margin-top: 20px;

  #map {
    height: 270px;
    border-radius: 12px;
    margin: 12px auto 8px auto;

    @media screen and (min-width: 320px) {
      width: 256px;
    }

    @media screen and (min-width: 375px) {
      width: 340px;
    }

    @media screen and (min-width: 425px) {
      width: 380px;
    }

    @media screen and (min-width: 500px) {
      width: 460px;
    }

    @media screen and (min-width: 600px) {
      width: 525px;
    }
  }

  & img {
    width: 9px;
    height: 12px;
    margin-right: 2px;
  }
`;

export { Header, Main, Section, AddressItem, PharmacyList, Footer, WorkTypeBottomSheet, NaverMap };
