import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from "../API.json";
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import parse from "html-react-parser";
// import reactElementToJSXString from 'react-element-to-jsx-string';
import { loadImageUrlBase64 } from "./ImageUtil";
import ReactDOMServer from "react-dom/server";
import { MY_ADDRESS, TARGET } from "../config.json";
import ABTestUtil, { ABTestFeature } from "./ABTestUtil";
import ChannelTalkService from "./ChannelTalkService";
import { TYPE } from "react-toastify/dist/utils";
import { on } from "process";
import { UserInfo } from "../models/Model.User";
import AnalyticsUtil from "./AnalyticsUtil";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const windowAny: any = window;

export default class AdsUtil {
  static SOLUTION_ADMOB = 0x01;
  static SOLUTION_APPLOVIN = 0x02;
  static SOLUTION_ANY = 0xff;

  static TYPE_BANNER = 0x01;
  static TYPE_INTERSTITIAL = 0x02;
  static TYPE_REWARD = 0x04;

  static TYPE_NAME = {
    [AdsUtil.TYPE_BANNER]: "BANNER",
    [AdsUtil.TYPE_INTERSTITIAL]: "INTERSTITIAL",
    [AdsUtil.TYPE_REWARD]: "REWARD",
  };

  static ADSID = {
    [AdsUtil.SOLUTION_ADMOB]: {
      [AdsUtil.TYPE_BANNER]: {
        ios: "ca-app-pub-1330778848735346/6606934351",
        android: "ca-app-pub-1330778848735346/5876892376",
      },
      [AdsUtil.TYPE_INTERSTITIAL]: {
        ios: "ca-app-pub-1330778848735346/8175698757",
        android: "ca-app-pub-1330778848735346/9927764125",
      },
      [AdsUtil.TYPE_REWARD]: {
        ios: "ca-app-pub-1330778848735346/4268577445",
        android: "ca-app-pub-1330778848735346/6731256154",
      },
    },
    [AdsUtil.SOLUTION_APPLOVIN]: {
      [AdsUtil.TYPE_BANNER]: {
        ios: "93c5f50b71ebf231",
        android: "97e4fb593428ce0c",
      },
      [AdsUtil.TYPE_INTERSTITIAL]: {
        ios: "8cafd7eddbb9427f",
        android: "5748a938c66113ac",
      },
      [AdsUtil.TYPE_REWARD]: {
        ios: "2518990abac32750",
        android: "18bc01c8638a0e51",
      },
    },
  };

  private static _initialized = false;
  private static _enabled = true;
  private static _admob = null;
  private static _admob_banner = null;
  private static _admob_interstitial = null;
  private static _admob_rewarded = null;
  // private static _admob_rewardedInterstitial = null;
  private static _appLovin = null;

  static init = async (me?: UserInfo, force = false) => {
    log(LogLevel.UI_EVENT, "AdsUtil:init start", force);
    if (!AdsUtil._enabled && !force) {
      log(LogLevel.UI_EVENT, "AdsUtil:init - DISABLED");
      return;
    }

    if (me && me.premiumType) {
      log(LogLevel.UI_EVENT, "AdsUtil:init - Premium User");
      AdsUtil._enabled = false;
      return;
    }

    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") {
      log(LogLevel.UI_EVENT, "AdsUtil:init - DISABLED", os);
      AdsUtil._enabled = false;
      return;
    }

    if (AdsUtil._initialized) {
      log(LogLevel.UI_EVENT, "AdsUtil:init - already initialized", os);
      return;
    }

    if (!AdsUtil._admob) {
      if (!windowAny.admob) {
        log(LogLevel.UI_EVENT, "AdsUtil:init - windowAny.admob not found");
      } else {
        log(LogLevel.UI_EVENT, "AdsUtil:init - Admob initialize", os);
        AdsUtil._admob = windowAny.admob;
        await AdsUtil._admob.start();
        log(LogLevel.UI_EVENT, "AdsUtil:init - Admob initialized", os);

        AdsUtil._admob_banner = new AdsUtil._admob.BannerAd({
          adUnitId:
            AdsUtil.ADSID[AdsUtil.SOLUTION_ADMOB][AdsUtil.TYPE_BANNER][os],
        });
        AdsUtil._admob_banner.on("load", AdsUtil._onAdmobBannerLoad);
        AdsUtil._admob_banner.on("loadfail", AdsUtil._onAdmobBannerLoadFail);
        AdsUtil._admob_banner.on("show", AdsUtil._onAdmobBannerShow);
        AdsUtil._admob_banner.on("showfail", AdsUtil._onAdmobBannerShowFail);

        log(LogLevel.UI_EVENT, "AdsUtil:init - Admob Banner initialized", os);

        AdsUtil._admob_interstitial = new AdsUtil._admob.InterstitialAd({
          adUnitId:
            AdsUtil.ADSID[AdsUtil.SOLUTION_ADMOB][AdsUtil.TYPE_INTERSTITIAL][
              os
            ],
        });
        AdsUtil._admob_interstitial.on(
          "load",
          AdsUtil._onAdmobInterstitialLoad
        );
        AdsUtil._admob_interstitial.on(
          "loadfail",
          AdsUtil._onAdmobInterstitialLoadFail
        );
        AdsUtil._admob_interstitial.on(
          "show",
          AdsUtil._onAdmobInterstitialShow
        );
        AdsUtil._admob_interstitial.on(
          "showfail",
          AdsUtil._onAdmobInterstitialShowFail
        );

        log(
          LogLevel.UI_EVENT,
          "AdsUtil:init - Admob InterstitialAd initialized",
          os
        );

        AdsUtil._admob_rewarded = new AdsUtil._admob.RewardedAd({
          adUnitId:
            AdsUtil.ADSID[AdsUtil.SOLUTION_ADMOB][AdsUtil.TYPE_REWARD][os],
        });
        AdsUtil._admob_rewarded.on("load", AdsUtil._onAdmobRewardedLoad);
        AdsUtil._admob_rewarded.on(
          "loadfail",
          AdsUtil._onAdmobRewardedLoadFail
        );
        AdsUtil._admob_rewarded.on("show", AdsUtil._onAdmobRewardedShow);
        AdsUtil._admob_rewarded.on(
          "showfail",
          AdsUtil._onAdmobRewardedShowFail
        );
        AdsUtil._admob_rewarded.on("reward", AdsUtil._onAdmobRewarded);

        log(
          LogLevel.UI_EVENT,
          "AdsUtil:init - Admob RewardedAd initialized",
          os
        );

        // AdsUtil._admob_rewardedInterstitial = new AdsUtil._admob.InterstitialAd({
        //   adUnitId: AdsUtil.ADSID[AdsUtil.SOLUTION_ADMOB][AdsUtil.TYPE_REWARD][os],
        // })
      }
    }

    if (!AdsUtil._appLovin) {
      log(LogLevel.UI_EVENT, "AdsUtil:init - AppLovin initialize", os);
      try {
        AdsUtil._appLovin = windowAny.cordova.require(
          "cordova-plugin-applovin-max.AppLovinMAX"
        );
        if (!AdsUtil._appLovin) {
          log(LogLevel.UI_EVENT, "AdsUtil:init - AppLovin not found");
        } else {
          AdsUtil._appLovin.initialize(
            "ZUT6NoyxDtk1BEAays4NfipbTidBLazTvtw_VkZ2eXGMqCzsvBHnZy_SErxZl_m9RsTTx6aePBmexJroIkOe3I",
            function (configuration) {
              // SDK is initialized, start loading ads
              log(
                LogLevel.UI_EVENT,
                "AdsUtil:init - AppLovin initialized",
                configuration
              );
            }
          );

          AdsUtil._appLovin.createBanner(
            AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_BANNER][
              getGlobal(GlobalKey.OS)
            ],
            AdsUtil._appLovin.AdViewPosition.BOTTOM_CENTER
          );

          window.addEventListener(
            "OnBannerAdLoadedEvent",
            AdsUtil._onAppLovinBannerLoad
          );
          window.addEventListener(
            "OnBannerAdLoadFailedEvent",
            AdsUtil._onAppLovinBannerLoadFail
          );
          window.addEventListener(
            "OnBannerAdClickedEvent",
            AdsUtil._onAppLovinBannerClick
          );
          window.addEventListener(
            "OnBannerAdDisplayedEvent",
            AdsUtil._onAppLovinBannerShow
          );
          window.addEventListener(
            "OnBannerAdFailedToDisplayEvent",
            AdsUtil._onAppLovinBannerShowFail
          );
          window.addEventListener(
            "OnInterstitialLoadedEvent",
            AdsUtil._onAppLovinInterstitialLoad
          );
          window.addEventListener(
            "OnInterstitialLoadFailedEvent",
            AdsUtil._onAppLovinInterstitialLoadFail
          );
          window.addEventListener(
            "OnInterstitialClickedEvent",
            AdsUtil._onAppLovinInterstitialClick
          );
          window.addEventListener(
            "OnInterstitialDisplayedEvent",
            AdsUtil._onAppLovinInterstitialShow
          );
          window.addEventListener(
            "OnInterstitialAdFailedToDisplayEvent",
            AdsUtil._onAppLovinInterstitialShowFail
          );
          window.addEventListener(
            "OnInterstitialHiddenEvent",
            AdsUtil._onAppLovinInterstitialHide
          );

          window.addEventListener(
            "OnRewardedAdLoadedEvent",
            AdsUtil._onAppLovinRewardedLoad
          );
          window.addEventListener(
            "OnRewardedAdLoadFailedEvent",
            AdsUtil._onAppLovinRewardedLoadFail
          );
          window.addEventListener(
            "OnRewardedAdClickedEvent",
            AdsUtil._onAppLovinRewardedClick
          );
          window.addEventListener(
            "OnRewardedAdDisplayedEvent",
            AdsUtil._onAppLovinRewardedShow
          );
          window.addEventListener(
            "OnRewardedAdAdFailedToDisplayEvent",
            AdsUtil._onAppLovinRewardedShowFail
          );
          window.addEventListener(
            "OnRewardedAdReceivedRewardEvent",
            AdsUtil._onAppLovinRewarded
          );
          window.addEventListener(
            "OnRewardedAdHiddenEvent",
            AdsUtil._onAppLovinRewardedHide
          );
        }
      } catch (e) {
        log(LogLevel.UI_EVENT, "AdsUtil:init - AppLovin loading failed", e);
      }
      log(LogLevel.UI_EVENT, "AdsUtil:init - AppLovin initialized", os);
    }

    if (!AdsUtil._admob && !AdsUtil._appLovin) {
      log(
        LogLevel.UI_EVENT,
        "AdsUtil:init - failed all solution, DISABLED",
        os
      );
      AdsUtil._enabled = false;
      AdsUtil._initialized = false;
      return;
    }

    AdsUtil._initialized = true;
  };

  static isAvailable = () => {
    return (
      ABTestUtil.isTest(ABTestFeature.UI_APP_ADS) &&
      AdsUtil._initialized &&
      AdsUtil._enabled
    );
  };

  static async show(
    type: number,
    options: {
      solution?: number;
      onRewarded?: () => void;
      skipRate?: number;
      name?: string;
    } = {}
  ) {
    if (!ABTestUtil.isTest(ABTestFeature.UI_APP_ADS)) {
      log(LogLevel.UI_EVENT, "AdsUtil:show - ABTest - UI_APP_ADS disabled");
      return;
    }

    if (!AdsUtil._initialized) {
      log(LogLevel.UI_EVENT, "AdsUtil:show - not initialized");
      return;
    }

    if (!AdsUtil._enabled) {
      log(LogLevel.UI_EVENT, "AdsUtil:show - DISABLED");
      return;
    }

    if (!AdsUtil._appLovin && !AdsUtil._admob) {
      log(LogLevel.UI_EVENT, "AdsUtil:show - no solution loaded");
      AdsUtil._enabled = false;
      return;
    }

    if (options.skipRate) {
      // skipRate is the percentage of the time the ad will be shown 0 to 100
      let random = Math.floor(Math.random() * 100);
      if (random <= options.skipRate) {
        log(
          LogLevel.UI_EVENT,
          "AdsUtil:show - skipRate",
          random,
          options.skipRate
        );
        return;
      }
    }

    if (!options) options = { solution: AdsUtil.SOLUTION_ADMOB };
    else if (!options.solution) options.solution = AdsUtil.SOLUTION_ANY;
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:show - start",
      AdsUtil.TYPE_NAME[type],
      options
    );
    let solution = "";
    if (options.solution | AdsUtil.SOLUTION_APPLOVIN && AdsUtil._appLovin) {
      if (type == AdsUtil.TYPE_BANNER) {
        // Banner is loaded, show it
        AdsUtil._appLovin.showBanner(
          AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_BANNER][
            getGlobal(GlobalKey.OS)
          ]
        );
        solution = "AppLovin";
      } else if (type == AdsUtil.TYPE_INTERSTITIAL) {
        AdsUtil._appLovin.loadInterstitial(
          AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_INTERSTITIAL][
            getGlobal(GlobalKey.OS)
          ]
        );
        solution = "AppLovin";
      } else if (type == AdsUtil.TYPE_REWARD) {
        AdsUtil._appLovin.loadRewardedAd(
          AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_REWARD][
            getGlobal(GlobalKey.OS)
          ]
        );
        solution = "AppLovin";
      }
    } else if (options.solution | AdsUtil.SOLUTION_ADMOB && AdsUtil._admob) {
      if (type == AdsUtil.TYPE_BANNER) {
        AdsUtil._admob_banner.show();
        solution = "Admob";
      } else if (type == AdsUtil.TYPE_INTERSTITIAL) {
        AdsUtil._admob_interstitial.load();
        solution = "Admob";
      } else if (type == AdsUtil.TYPE_REWARD) {
        AdsUtil._admob_rewarded.load();
        solution = "Admob";
      }
    }

    log(
      LogLevel.UI_EVENT,
      "AdsUtil:show - start done",
      AdsUtil.TYPE_NAME[type],
      solution
    );
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "ADS", "앱광고", {
      action: "노출",
      name: options.name,
      type: AdsUtil.TYPE_NAME[type],
      solution,
    });
  }

  static async hide(type: number) {
    if (!ABTestUtil.isTest(ABTestFeature.UI_APP_ADS)) {
      log(LogLevel.UI_EVENT, "AdsUtil:show - ABTest - UI_APP_ADS disabled");
      return;
    }

    if (!AdsUtil._initialized) {
      log(LogLevel.UI_EVENT, "AdsUtil:hide - not initialized");
      return;
    }

    if (!AdsUtil._enabled) {
      log(LogLevel.UI_EVENT, "AdsUtil:hide - DISABLED");
      return;
    }

    if (type == AdsUtil.TYPE_BANNER) {
      if (AdsUtil._admob_banner) {
        AdsUtil._admob_banner.hide();
      }
      if (AdsUtil._appLovin) {
        AdsUtil._appLovin.hideBanner(
          AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_BANNER][
            getGlobal(GlobalKey.OS)
          ]
        );
      }
      // } else if (type == AdsUtil.TYPE_INTERSTITIAL) {
      //   if (AdsUtil._admob_interstitial) {
      //     AdsUtil._admob_interstitial.hide();
      //   }
      //   if (AdsUtil._appLovin) {
      //     AdsUtil._appLovin.hideInterstitial();
      //   }
      // } else if (type == AdsUtil.TYPE_REWARD) {
      //   if (AdsUtil._admob_rewarded) {
      //     AdsUtil._admob_rewarded.hide();
      //   }
      //   if (AdsUtil._appLovin) {
      //     AdsUtil._appLovin.hideRewardedAd();
      //   }
    }
  }

  /// Admob Banner Events
  static _onAdmobBannerLoad = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobBannerLoad - Admob Banner loaded. ",
      evt
    );
  };
  static _onAdmobBannerLoadFail = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobBannerLoadFailed - Admob Banner load failed",
      evt
    );
  };
  static _onAdmobBannerShow = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobBannerShow - Admob Banner show",
      evt
    );
  };
  static _onAdmobBannerShowFail = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobBannerShowFailed - Admob Banner show failed",
      evt
    );
  };

  /// Admob Interstitial Events
  static _onAdmobInterstitialLoad = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobInterstitialLoad - Admob Interstitial loaded. show!!!",
      evt
    );
    AdsUtil._admob_interstitial.show();
  };
  static _onAdmobInterstitialLoadFail = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobInterstitialLoadFailed - Admob Interstitial load failed",
      evt
    );
  };
  static _onAdmobInterstitialShow = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobInterstitialShow - Admob Interstitial show",
      evt
    );
  };
  static _onAdmobInterstitialShowFail = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobInterstitialShowFailed - Admob Interstitial show failed",
      evt
    );
  };

  /// Admob Rewarded Events
  static _onAdmobRewardedLoad = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobRewardedLoad - Admob Rewarded loaded. show!!!",
      evt
    );
    AdsUtil._admob_rewarded.show();
  };
  static _onAdmobRewardedLoadFail = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobRewardedLoadFailed - Admob Rewarded load failed",
      evt
    );
  };
  static _onAdmobRewardedShow = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobRewardedShow - Admob Rewarded show",
      evt
    );
  };
  static _onAdmobRewardedShowFail = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobRewardedShowFailed - Admob Rewarded show failed",
      evt
    );
  };
  static _onAdmobRewarded = (evt) => {
    // evt.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAdmobRewarded - Admob Rewarded Done",
      evt
    );
  };

  /// AppLovin Banner Events
  static _onAppLovinBannerLoad = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinBannerLoad - AppLovin Banner loaded. show!!!",
      adEventInfo
    );
  };
  static _onAppLovinBannerLoadFail = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinBannerLoadFailed - AppLovin Banner load failed",
      adEventInfo
    );
  };
  static _onAppLovinBannerShow = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinBannerShow - AppLovin Banner show",
      adEventInfo
    );
  };
  static _onAppLovinBannerShowFail = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinBannerShowFailed - AppLovin Banner show failed",
      adEventInfo
    );
  };
  static _onAppLovinBannerClick = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinBannerClick - AppLovin Banner clicked",
      adEventInfo
    );
  };

  /// AppLovin Interstitial Events
  static _onAppLovinInterstitialLoad = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinInterstitialLoad - AppLovin Interstitial loaded. show!!!",
      adEventInfo
    );
    AdsUtil._appLovin.showInterstitial(
      AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_INTERSTITIAL][
        getGlobal(GlobalKey.OS)
      ]
    );
  };
  static _onAppLovinInterstitialLoadFail = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinInterstitialLoadFailed - AppLovin Interstitial load failed",
      adEventInfo
    );
  };
  static _onAppLovinInterstitialShow = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinInterstitialShow - AppLovin Interstitial show",
      adEventInfo
    );
  };
  static _onAppLovinInterstitialShowFail = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinInterstitialShowFailed - AppLovin Interstitial show failed",
      adEventInfo
    );
    AdsUtil._appLovin.hideInterstitial(
      AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_INTERSTITIAL][
        getGlobal(GlobalKey.OS)
      ]
    );
  };
  static _onAppLovinInterstitialClick = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinInterstitialClick - AppLovin Interstitial clicked",
      adEventInfo
    );
  };
  static _onAppLovinInterstitialHide = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinInterstitialShowFailed - AppLovin Interstitial show failed",
      adEventInfo
    );
  };

  /// AppLovin Rewarded Events
  static _onAppLovinRewardedLoad = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinRewardedLoad - AppLovin Rewarded loaded. show!!!",
      adEventInfo
    );
    AdsUtil._appLovin.showRewardedAd(
      AdsUtil.ADSID[AdsUtil.SOLUTION_APPLOVIN][AdsUtil.TYPE_REWARD][
        getGlobal(GlobalKey.OS)
      ]
    );
  };
  static _onAppLovinRewardedLoadFail = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinRewardedLoadFailed - AppLovin Rewarded load failed",
      adEventInfo
    );
  };
  static _onAppLovinRewardedShow = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinRewardedShow - AppLovin Rewarded show",
      adEventInfo
    );
  };
  static _onAppLovinRewardedShowFail = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinRewardedShowFailed - AppLovin Rewarded show failed",
      adEventInfo
    );
  };
  static _onAppLovinRewardedClick = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinRewardedClick - AppLovin Rewarded clicked",
      adEventInfo
    );
  };
  static _onAppLovinRewardedHide = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinRewardedShowFailed - AppLovin Rewarded show failed",
      adEventInfo
    );
  };
  static _onAppLovinRewarded = (adEventInfo) => {
    // adEventInfo.ad
    log(
      LogLevel.UI_EVENT,
      "AdsUtil:onAppLovinRewardedShowFailed - AppLovin Rewarded. Done",
      adEventInfo
    );
  };
}
