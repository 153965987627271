import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./BoardManage.css";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import BoardContentManagePopup from "./component/BoardContentManagePopup";
import BoardContentEdit from "./component/BoardContentEdit";
import { setGlobal, GlobalKey, getGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import { BoardContent, BoardInfo } from "../models/Model.Board";
import { fetchAPI } from "../utils/APIUtil";
import Truncate from "react-truncate";
import BoardSelect from "./component/BoardSelect";
import BoardEdit from "./component/BoardEdit";

const API = {
  GET_LIST: {
    method: "GET",
    path: "/admin/board/boards",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selected: BoardInfo;
};

const emptyBoard: BoardInfo = {
  id: 0,
  ids: "",
  order: 0,
  name: "",
  fullname: "",
  type: 1,
  level: 1000,
  attribute: "",
};

class BoardsManage extends Component<Props, State> {
  boardlist = null;
  state = {
    selected: null,
  };

  constructor(props: Props) {
    super(props);
    document.title = "게시판 관리";
  }

  onSelect = (board: BoardInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", board);
    this.setState({ selected: board });
  };

  onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({ selected: null });
    this.boardlist.fetchData();
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onCancel", this.state.selected);
    this.setState({ selected: null });
  };

  onAdd = () => {
    this.setState({ selected: { ...emptyBoard } });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "Dashboard.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">게시판 관리</IonText>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <IonButton expand="full" onClick={this.onAdd}>
                <IonIcon name="add" />
              </IonButton>
              <BoardSelect
                ref={(ref) => (this.boardlist = ref)}
                onDone={this.onSelect}
              />
            </div>
            <div id="admin-sub-conainer" className="admin-container">
              <BoardEdit board={this.state.selected} onDone={this.onDone} />
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardsManage)
);
