import React, { Component } from "react";
import "./ConfirmPopup.css";
import "./../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonModal, IonIcon, IonInput, IonList, IonItem } from "@ionic/react";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { timeout } from "q";
import smileIcon from "./../../assets/icon/smile.svg";
import { log, LogLevel } from "../../utils/LogUtil";
import DynamicCheckerInput, { CheckStatus } from "./../DynamicCheckerInput";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import Textarea from "react-textarea-autosize";
import { Flex } from "../atoms/Layout";

type Props = {};

type State = {
  visible: boolean;
  text: string;
  emailCheckStatus: CheckStatus;
  emailWarning: string;
};

interface ConfirmPopupOptions {
  title?: any;
  body?: any;
  buttons?: any;
  onDone?: (testInput: string) => void;
  onCancel?: (testInput: string) => void;
  onBackdropDismiss?: () => void;
  backDropDismiss?: boolean;
  needInput?: boolean;
  needTextArea?: boolean;
  needMyEmail?: boolean;
  defaultText?: string;
  inputPlaceHolder?: string;
  isPassword?: boolean;
  iconImage?: any;
  iconName?: string;
  iconStyle?: string;
  image?: any;
  imageStyle?: string;
  doneText?: string;
  cancelText?: string;
  cancelButtonColorStyle?: string;
  confirmButtonColorStyle?: string;
  textAreaStyle?: string;
  needCloseButton?: boolean;
}

class ConfirmPopup extends Component<Props, State> {
  input = null;

  state = {
    visible: false,
    text: "",
    emailCheckStatus: CheckStatus.CHECKED,
    emailWarning: "",
  };

  initialOptions: ConfirmPopupOptions = {
    // title:(
    //   <div className="common-container">
    //     <span className="confirm-title-highlight">진행</span> 확인
    //   </div>
    // ),
    // body: (
    //   <div className="common-container">
    //     이 작업을 <span className="confirm-description-highlight">진행</span>하시겠습니까?
    //   </div>
    // ),
    onDone: null,
    onCancel: null,
    backDropDismiss: true,
    needInput: false,
    needMyEmail: false,
    inputPlaceHolder: "",
    defaultText: "",
    isPassword: false,
    iconImage: smileIcon,
    iconName: null,
    doneText: "예",
    cancelText: null,
    cancelButtonColorStyle: "confirm-cancel-button",
  };

  onEmailChecked = (result) => {
    if (result && !result.error && result.data.value == this.state.text) {
      this.setState({ emailCheckStatus: CheckStatus.CHECKED, emailWarning: "" });
    } else if (result && result.error && result.data.value == this.state.text) {
      this.setState({ emailCheckStatus: CheckStatus.WARNING, emailWarning: "이미 사용중인 이메일주소입니다." });
    } else {
      log(LogLevel.UI_DATA_LOAD, result);
    }
    log(LogLevel.UI_DATA_LOAD, "RegisterStage2:onEmailChecked", result, this.state.text, this.state.emailCheckStatus);
  };

  validateEmail = (email: string) => {
    log(LogLevel.UI_DATA_LOAD, "RegisterStage2:validateEmail", this.state.text, this.state.emailCheckStatus);
    if (!email) {
      this.setState({ emailCheckStatus: CheckStatus.UNCHECKED, emailWarning: "" });
      return;
    } else if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      this.setState({ emailCheckStatus: CheckStatus.CHECKING, emailWarning: "" });
      fetchAPI(API.USER_FIELD_VALIDATE, "email", null, { value: email }, getGlobal(GlobalKey.TOKEN))
        .then((result) => this.onEmailChecked(result))
        .catch((e) => {
          log(LogLevel.UI_EXCEPTION, e);
        });
    } else {
      if (email.length > 10) {
        this.setState({ emailWarning: "이메일주소가 유효하지 않습니다." });
      }
      this.setState({ emailCheckStatus: CheckStatus.WARNING });
    }
  };

  onEmailChange = (e) => {
    let text = e.target.value;
    this.setState({ text });
    log(LogLevel.UI_ACTION, "RegisterStage2:onEmailChange", text);
    this.validateEmail(text);
  };

  options: ConfirmPopupOptions = { ...this.initialOptions };

  show = (options: ConfirmPopupOptions = {}) => {
    this.options = { ...this.initialOptions, ...options };
    let text = "";
    if (this.options.defaultText) text = this.options.defaultText;
    this.setState({ visible: true, text, emailCheckStatus: CheckStatus.CHECKED, emailWarning: "" });
    if (this.options.needInput) {
      this.autoFocusInput();
    }
    log(LogLevel.UI_LIFECYCLE, "ConfirmPopup:show", options, this.options);
  };

  autoFocusTryMax = 10;
  autoFocusTry = 0;
  autoFocusInput = () => {
    setTimeout(() => {
      try {
        if (this.input) {
          this.input.setFocus();
          return;
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "ConfirmPopup:autoFocusInput", e);
      }

      this.autoFocusTry++;
      if (this.autoFocusTry <= this.autoFocusTryMax) {
        log(LogLevel.UI_EXCEPTION, "ConfirmPopup:autoFocusInput try again", this.autoFocusTry);
        this.autoFocusInput();
      } else {
        log(LogLevel.UI_EXCEPTION, "ConfirmPopup:autoFocusInput failed fianally");
      }
    }, 100);
  };

  hide = () => {
    log(LogLevel.UI_LIFECYCLE, "ConfirmPopup:hide", this.state);
    this.setState({ visible: false });
  };

  isShown = () => {
    return this.state.visible;
  };

  onDone = () => {
    this.hide();
    if (this.options.onDone) this.options.onDone(this.state.text);
    this.setState({ text: "" });
  };

  onCancel = () => {
    this.hide();
    if (this.options.onCancel) this.options.onCancel(this.state.text);
  };

  onKeyPress = (e) => {
    if (e.keyCode == 13) this.onDone();
  };

  onClickBackdrop = () => {
    if (this.options.backDropDismiss) {
      this.hide();
      if (this.options.onBackdropDismiss) this.options.onBackdropDismiss();
    }
  };

  render() {
    let icon;
    if (this.options.iconImage) icon = <img className="confirm-icon" src={this.options.iconImage} />;
    else if (this.options.iconName) icon = <IonIcon name={this.options.iconName} />;
    else if (this.options.image)
      icon = (
        <img className={this.options.imageStyle ? this.options.imageStyle : "confirm-image"} src={this.options.image} />
      );
    let cancelButton;

    let buttons;
    if (this.options.buttons) {
      buttons = this.options.buttons;
    } else {
      if (this.options.cancelText) {
        if (typeof this.options.cancelText === "string") {
          cancelButton = (
            <IonButton
              color="common-button"
              class={
                this.options.cancelButtonColorStyle ? this.options.cancelButtonColorStyle : "confirm-cancel-button"
              }
              onClick={this.onCancel}
            >
              {this.options.cancelText}
            </IonButton>
          );
        } else {
          cancelButton = this.options.cancelText;
        }
      }

      buttons = (
        <Flex
          gap="8px"
          customStyle={{
            width: "100%",
            marginTop: "40px",
            "& > button": {
              flex: "1 1 33%",
            },
          }}
        >
          {cancelButton}
          {/* <div className="common-flex-grow" /> */}
          {typeof this.options.doneText === "string" ? (
            <IonButton
              disabled={this.state.emailCheckStatus != CheckStatus.CHECKED}
              color="common-button"
              class={this.options.confirmButtonColorStyle ? this.options.confirmButtonColorStyle : "confirm-button"}
              onClick={this.onDone}
            >
              {this.options.doneText}
            </IonButton>
          ) : (
            this.options.doneText
          )}
        </Flex>
      );
    }

    let input;
    if (this.options.needInput) {
      let placeholder = "";
      if (this.options.inputPlaceHolder) {
        placeholder = this.options.inputPlaceHolder;
      }
      input = (
        <IonInput
          ref={(ref) => {
            this.input = ref;
          }}
          class="confirm-input"
          placeholder={placeholder}
          value={this.state.text}
          type={this.options.isPassword ? "password" : "text"}
          onIonChange={(e) => {
            this.setState({ text: e.detail.value });
          }}
          onKeyDown={this.onKeyPress}
        />
      );
    } else if (this.options.needTextArea) {
      input = (
        <Textarea
          className={"confirm-text-area" + (this.options.textAreaStyle ? " " + this.options.textAreaStyle : "")}
          minRows={4}
          value={this.state.text}
          onChange={(e) => {
            this.setState({ text: e.target.value });
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      );
    } else if (this.options.needMyEmail) {
      input = (
        <DynamicCheckerInput
          containerClassName="confirm-email-container"
          title=""
          optional={true}
          status={this.state.emailCheckStatus}
          warnings={this.state.emailWarning}
          input={this.state.text}
          onChange={this.onEmailChange}
          type="email"
        />
      );
    }

    let closeButton;
    if (this.options.needCloseButton) {
      closeButton = (
        <IonButton color="confirm-close-button" class="confirm-close-button" onClick={this.hide}>
          <IonIcon class="confirm-close-button-icon" name="close" />
        </IonButton>
      );
    }

    let title;
    if (this.options.title) title = this.options.title;

    let body;
    if (this.options.body) body = <div className="confirm-body-container">{this.options.body}</div>;

    return (
      <IonModal
        cssClass="common-modal-popup-container"
        isOpen={this.state.visible}
        backdropDismiss={this.options.backDropDismiss}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="common-modal-popup-inner">
          <div className="common-modal-popup-backdrop" onClick={this.onClickBackdrop} />
          <div className="common-modal-container-outer">
            <div className="common-modal-container common-modal-container-max360">
              {icon}
              {title}
              {body}
              {input}
              {buttons}
              {closeButton}
            </div>
          </div>
          <div className="common-modal-popup-backdrop" onClick={this.onClickBackdrop} />
        </div>
      </IonModal>
    );
  }
}

export default ConfirmPopup;
