import React, { Component } from 'react';
import './style.scss';
import { func } from 'prop-types';
import {log, LogLevel} from '../../../utils/LogUtil'
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import { Reference } from '../../../models/Model.Board';
import {MY_ADDRESS} from './../../../config.json';
import StringUtil from '../../../utils/StringUtil';
import emptyLink from '../../../assets/image/link_empty_image.jpg';
import arrow from '../../../assets/icon/arrow.png';
import ReactHtmlParser from 'react-html-parser'
import Text from '../../atom/Text';
import Thumbnail from '../../atom/Thumbnail';

var parse = require('url-parse');

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  url?: string;
  jumpOnClick?: boolean;
  onClick? : (url:string) => void;
};

function youtubeParser(url){
  var i, r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  r = url.match(rx);
  return (r&&r[1].length==11)? r[1] : false;
}

class Link extends Component<Props> {
  url = "";
  youtubeId = "";

  constructor(props: Props) {
    super(props);
    this.initUrl();
  }

  onClick = (e) => {
    e.stopPropagation();
    if(this.props.onClick)
      this.props.onClick(this.props.url);
  }

  componentDidUpdate(prevProps){
    if(prevProps.url != this.props.url){
      this.initUrl();
    }
  }

  initUrl = () => {
    this.url = StringUtil.convertFilePath(this.props.url);
    this.youtubeId = youtubeParser(this.props.url);
    log(LogLevel.UI_LIFECYCLE, "Link: initUrl ", this.props.url, this.url, this.props.links[this.url])
    
    if(!this.youtubeId && this.url && !this.props.links[this.url])
      this.props.loadLink(this.url);
  }

  render() {
    if(!this.url)
      return null;
    log(LogLevel.UI_LIFECYCLE, "Link: render ", this.props.url, this.url, this.youtubeId)

    //if youtube
    if(this.youtubeId){
      const url = `https://www.youtube.com/embed/${this.youtubeId}`;
      return (
        <div className="link-youtube-container">
          <iframe className="link-youtube" src={url}/>
        </div>
      );
    }

    let reference:Reference = this.props.links[this.url]
    if(reference && !(!reference.title && !reference.description && !reference.image )){
      let imageUrl = reference.image;
      imageUrl = StringUtil.convertFilePath(imageUrl);
      if(imageUrl.startsWith("./")){
        imageUrl = imageUrl.substring(1);
      }
      if(imageUrl.startsWith("//")){
        let parseUrl = parse(this.url);
        imageUrl = parseUrl.protocol + imageUrl;
      }
      else if(imageUrl.startsWith("/")){
        let parseUrl = parse(this.url);

        imageUrl = parseUrl.protocol + (parseUrl.slashes?"//":"") + parseUrl.host + imageUrl;
      }

      const url = new URL(this.url);
      let link = (url.protocol?url.protocol+"//":"") + url.hostname;

      let body = "";
      if(reference.title)
        body = reference.title;
      else if(reference.description)
        body = reference.description;
      
      return (
        <div className="link-container" onClick={this.onClick}>
          <div className="link-info-container">
            <div>
              <Text type="Caption2" color="Blue" numberOfLines={1}> {link}</Text>
            </div>
            {body && 
              <div>
                <Text type="ButtonM" color="Black" numberOfLines={2}> {body}</Text>
              </div>
            }
          </div>
          <div style={{marginLeft:"16px"}}>
            <Thumbnail src={imageUrl} errorImage={emptyLink}/>
          </div>
        </div>
      );
    }else{
      return (
        <Text className="link-plain" type="Body1" color="Blue" numberOfLines={3}> {this.url}</Text>
      );
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  links: state.board.links,
});

const mapDispatchToProps = {
  loadLink : (url:string) => actions.board.loadLink(url, 0),
}
export default connect(mapStateToProps, mapDispatchToProps)(Link);