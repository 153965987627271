import React, { ReactNode } from "react";
import styled from "styled-components";

const KeywordListDiv = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 12px 8px;
  flex-wrap: wrap;
`;
interface KeywordListProps {
  children: ReactNode;
}

const KeywordList: React.FC<KeywordListProps> = ({ children }) => {
  return <KeywordListDiv>{children}</KeywordListDiv>;
};

export default KeywordList;
