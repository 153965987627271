import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, RootState } from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import { IonIcon, IonHeader, IonToolbar, IonButtons, IonButton, IonLabel } from "@ionic/react";
// import './../Common.css';
import { log, LogLevel } from "../../utils/LogUtil";

import { ChannelList, RenderChannelPreviewProps, sendBirdSelectors, SendBirdState, withSendBird } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import ABTestUtil from "../../utils/ABTestUtil";
import { FileMessage, GroupChannelListQuery, Member, UserMessage } from "sendbird";
import { getDateTimeString } from "../../utils/TimeUtil";
import TopAppBar from "../molecules/TopAppBar/TopAppBar";
import { COLOR_SYSTEM } from "../design/design-system";
import styled from "styled-components";
import Button from "../atoms/Button";
import Avatar from "../atoms/Avatar";
import Text from "../atoms/Text";
import Badge from "../atoms/Badge/Badge";
import { UserWorkType } from "../../models/Model.User";
import Spinner from "../../component/atom/Spinner";
const queryString = require("query-string");

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapSendbirdStateToProps>;

type State = {
  hasChat: boolean;
  initialized: boolean;
};

const windowAny: any = window;

const Header = styled.header`
  position: fixed;
  top: 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  width: 100%;
`;

const Main = styled.main`
  padding: 48px 0 24px 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
`;

const Information = styled.div`
  padding: 150px 0 0 0;
  text-align: center;
  .title {
    margin-bottom: 24px;
  }
`;

const List = styled.section`
  padding: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  overflow-y: scroll;
  position: relative;

  & > * + * {
    margin-top: 20px;
  }
`;

const Item = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  .avatar {
    margin-right: 16px;
  }

  .grow {
    flex-grow: 1;
  }

  .item {
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

class ChatList extends Component<Props, State> {
  listRef = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      initialized: false,
      hasChat: false,
    };
    log(LogLevel.UI_LIFECYCLE, "ChatList:constructor", props);

  }

  channelHandler = null;

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "ChatList:componentDidMount");
    this.initSendbird();
  }

  componentDidUpdate(prevProps, prevState) {
    this.initSendbird();
  }

  initSendbird = () => {
    if (!this.channelHandler && this.props.sdk && this.props.sdk.GroupChannel) {
      log(LogLevel.UI_LIFECYCLE, "ChatList:initSendbird", !!this.channelHandler, !!this.props.sdk, !!this.props.sdk.GroupChannel);
      var listQuery: GroupChannelListQuery = this.props.sdk.GroupChannel.createMyGroupChannelListQuery();
      listQuery.includeEmpty = false;
      listQuery.limit = 1; // The value of pagination limit could be set up to 100.

      try {
        if (listQuery.hasNext) {
          listQuery.next().then((groupChannels) => {
            if (groupChannels && groupChannels.length) {
              this.setState({ hasChat: true });
            }
          });
        }
      } catch (e) {
        log(LogLevel.UI_ACTION, "ChatList:intializeSendbird:listQuery.next exception ");
      }
      this.setState({ initialized: true });

      this.channelHandler = new this.props.sdk.ChannelHandler();
      this.channelHandler.onMessageReceived = (channel, message) => {
        log(LogLevel.UI_ACTION, "ChatList:onMessageReceived ", message);
        this.setState({ hasChat: true });
      };
      this.props.sdk.addChannelHandler("ChatList", this.channelHandler);
      log(LogLevel.UI_LIFECYCLE, "ChatList:initSendbird done", this.state);
    }
  };

  componentWillUnmount(): void {
    this.props.sdk.removeChannelHandler("ChatList");
  }

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "ChatList:componentDidMount");

    if (this.props.history.length > 1) this.props.history.goBack();
    else this.props.history.replace("/");
  };

  onSelectChat = (id) => {
    this.props.history.push("/chat?id=" + id);
  };

  handlePostListScroll = (e: any) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;

    console.log(scrollTop);
  };

  renderChannelPreview = (props: RenderChannelPreviewProps) => {
    log(LogLevel.UI_LIFECYCLE, "ChatList.renderChannelPreview", props);
    let user: Member;
    let name = "-";
    let type = "-";
    let url;
    let message;

    let target = "";
    if (ABTestUtil.isDev()) {
      target = "DEV";
    }

    if (props.channel.members && props.channel.members.length) {
      for (let i = 0; i < props.channel.members.length; i++) {
        let member = props.channel.members[i];
        if (!member.userId.startsWith("admin") && member.userId != target + this.props.me.id.toString()) {
          user = member;
          log(LogLevel.UI_LIFECYCLE, "ChatList.renderChannelPreview opponent", user, user.metaData);
          break;
        }
      }
    }

    let meta;
    try {
      if (props.channel.data) meta = JSON.parse(props.channel.data);
    } catch (e) {
      log(LogLevel.UI_LIFECYCLE, "ChatList.renderChannelPreview exception", e);
    }

    if (meta && meta.type == "JOBPOST") {
      if (user) {
        if (this.props.me.id == meta.ouid) name = user.metaData["name"] + " 약사";
        else name = meta.pharmacyName;

        url = user.profileUrl;
      }
    }

    if (!props.channel || !props.channel.lastMessage) {
      message = "";
    } else if (props.channel.lastMessage.messageType == "user") {
      let lastMessage: UserMessage = props.channel.lastMessage;
      message = lastMessage.message;
    } else if (props.channel.lastMessage.messageType == "file") {
      let lastMessage: FileMessage = props.channel.lastMessage;
      message = "[파일]";
    }

    return (
      <Item
        onClick={() => {
          this.onSelectChat(props.channel.url);
        }}
      >
        <Avatar className="avatar" size="Large" url={url} />
        <div className="grow">
          <div className="item">
            <div className="grow">
              <Text textType="Subhead1" color={COLOR_SYSTEM.get("Gray")[900]}>
                {name}
              </Text>{" "}
            </div>
            {props.channel.lastMessage && (
              <div>
                <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[900]}>
                  {getDateTimeString(props.channel.lastMessage.createdAt, { dayOnly: true })}
                </Text>{" "}
              </div>
            )}
          </div>
          <div className="item">
            <div className="grow">
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[500]} numberOfLines={1}>
                {message}
              </Text>{" "}
            </div>
            {props.channel.unreadMessageCount > 0 && (
              <Badge
                color="Red"
                content={props.channel.unreadMessageCount > 99 ? "99+" : props.channel.unreadMessageCount.toString()}
              />
            )}
          </div>
        </div>
      </Item>
    );
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ChatList.render", this.state);
    if (!this.state.initialized){
      return (

        <>
          <Header>
            <TopAppBar
              title="채팅"
              titleType="H4"
              leftButtons={[
                <Button
                  color="Tertiary"
                  icon="CaretLeft"
                  onClick={this.goBack}
                  size="Medium"
                  type="Icon"
                  variant="Ghost"
                />,
              ]}
            />
          </Header>
          <Main>
            <div className="common-container-column common-flex-align-center">
              <Spinner/>
            </div>
          </Main>
        </>
      );
    }
    else if (this.state.initialized && !this.state.hasChat) {
      return (
        <>
          <Header>
            <TopAppBar
              title="채팅"
              titleType="H4"
              leftButtons={[
                <Button
                  color="Tertiary"
                  icon="CaretLeft"
                  onClick={this.goBack}
                  size="Medium"
                  type="Icon"
                  variant="Ghost"
                />,
              ]}
            />
          </Header>
          <Main>
            {this.props.me.workType == UserWorkType.PHARMACY_OWNER ? (
              <Information>
                <div className="title">
                  <Text textType="H2" color={COLOR_SYSTEM.get("Gray")[400]}>
                    진행 중인 채팅이 없어요
                  </Text>
                </div>
                <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[400]}>
                  구직 약사가 채팅을 시작하면 이 곳에 표시됩니다
                </Text>
              </Information>
            ) : (
              <Information>
                <div className="title">
                  <Text textType="H2" color={COLOR_SYSTEM.get("Gray")[400]}>
                    진행 중인 채팅이 없어요
                  </Text>
                </div>
                <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[400]}>
                  채팅을 시작하려면 채용 공고 하단의 <br />
                  지원하기 → 채팅으로 지원하기를 <br />
                  눌러주세요
                </Text>
              </Information>
            )}
          </Main>
        </>
      );
    }

    return (
      <>
        <Header>
          <TopAppBar
            title="채팅"
            titleType="H4"
            leftButtons={[
              <Button
                color="Tertiary"
                icon="CaretLeft"
                onClick={this.goBack}
                size="Medium"
                type="Icon"
                variant="Ghost"
              />,
            ]}
          />
        </Header>
        <Main>
          <List
            ref={(ref) => {
              this.listRef = ref;
            }}
            onScroll={this.handlePostListScroll}
          >
            <ChannelList disableUserProfile={false} renderChannelPreview={this.renderChannelPreview} />
          </List>
        </Main>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  offers: state.jobpost.offers,
});

const mapDispatchToProps = {
  loadJobOffer: (options, id) => actions.jobpost.loadOffer(options, id),
};

const mapSendbirdStateToProps = (state: SendBirdState) => ({
  sdk: sendBirdSelectors.getSdk(state),
});

export default withSendBird(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatList)),
  mapSendbirdStateToProps
);
