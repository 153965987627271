import { UserWorkType } from "../models/Model.User";
import { log, LogLevel } from "./LogUtil";

const globalAny:any = global;


export interface ConditionItem{
    field: string;
    type: string;
    value: any;
}

export interface Condition{
    user: ConditionItem[];  // me
}

export const CONDITION_USER_OWNER : Condition = {
    user: [
        {
            field:"workType",
            type:"eq",
            value: UserWorkType.PHARMACY_OWNER,
        }
    ]  // me
}

export const CONDITION_USER_NOT_OWNER : Condition = {
    user: [
        {
            field:"workType",
            type:"neq",
            value: UserWorkType.PHARMACY_OWNER,
        }
    ]  // me
}

export const CONDITION_USER_EMPLOYEE : Condition = {
    user: [
        {
            field:"workType",
            type:"in",
            value: [UserWorkType.PHARMACY_EMPLOYED, UserWorkType.LEAVE_OF_ABSENCE]
        }
    ]  // me
}


export function conditionCheck(condition:Condition, props: any){
    if(!condition)
        return true;
    
    if(condition.user && condition.user.length && props.me){
        let data = props.me;
        for(let i=0; i<condition.user.length; i++){
            let conditionItem:ConditionItem = condition.user[i];

            if(
                conditionItem.field && (data[conditionItem.field] || data[conditionItem.field]==0 || data[conditionItem.field] == "")
            ){
                if(
                    (conditionItem.type == "eq" && data[conditionItem.field] != conditionItem.value) || 
                    (conditionItem.type == "neq" && data[conditionItem.field] == conditionItem.value) || 
                    (conditionItem.type == "in" && !conditionItem.value.includes(data[conditionItem.field])) || 
                    (conditionItem.type == "nin" && conditionItem.value.includes(data[conditionItem.field])) || 
                    (conditionItem.type == "lt" && data[conditionItem.field] <= conditionItem.value) || 
                    (conditionItem.type == "lte" && data[conditionItem.field] < conditionItem.value) || 
                    (conditionItem.type == "gt" && data[conditionItem.field] >= conditionItem.value) || 
                    (conditionItem.type == "gte" && data[conditionItem.field] > conditionItem.value)
                ){
                    log(LogLevel.UI_DATA_LOAD, "conditionCheck : condition failed", conditionItem, data, data[conditionItem.field], conditionItem.value);
                    return false;
                }
            }
        }
    }
    log(LogLevel.UI_DATA_LOAD, "conditionCheck : condition success", condition.user, props.me);
    return true;
}
