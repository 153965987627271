import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from "react-router";
// import './style.css';
import './../../../Common.css';
import * as API from './../../../API.json';
import { connect } from 'react-redux';
import { RootState, actions } from './../../../store';
import {log, LogLevel} from './../../../utils/LogUtil'

import {getGlobal, GlobalKey, clearGlobal, setGlobal} from './../../../utils/GlobalUtil'
import { UIPopupType, UIServiceType } from '../../../store/ui/types';
import AnalyticsUtil from './../../../utils/AnalyticsUtil';
import {MY_ADDRESS} from './../../../config.json'
import ABTestUtil, { ABTestFeature } from '../../../utils/ABTestUtil';
import VideoPlayer from '../../../components/VideoPlayer';
import SearchAddress from '../../../components/SearchAddress';
import { fetchAPI } from '../../../utils/APIUtil';
import { timerStart } from '../../../utils/TimeUtil';
import SeminarView from '../../../component/template/Seminar/SeminarView';
import { SeminarLecture } from '../../../models/Model.Seminar';
import SeminarList from '../../../component/template/Seminar/SeminarList';
import { BannerInfo } from '../../../models/Model.Banner';
import { action } from 'typesafe-actions';
import SeminarMyList from '../../../component/template/Seminar/SeminarMyList';

const queryString = require('query-string');
const windowAny: any = window;

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
}

type State = {
}

class SeminarMyListViewer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_VIEW", '세미나_리스트_진입');
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "SeminarMyListViewer:componentDidMount backbutton register");
    if(this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);
    this.props.refreshMyList();
  }
          
  componentDidUpdate(prevProps, prevState) {
  }

  goBack = () => {
    log(LogLevel.UI_ACTION, "SeminarMyListViewer:goBack");

    this.props.history.goBack();
    return true;
  }
  
  componentWillUnmount(){
    log(LogLevel.UI_EVENT, "SeminarMyListViewer:componentWillUnmount backbutton unregister");
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_VIEW", '세미나_리스트_이탈');
  }

  onSelectSeminar = (seminar:SeminarLecture) => {
    log(LogLevel.UI_EVENT, "SeminarMyListViewer:onSelectSeminar", seminar);
    this.props.history.push("/lecture?id=" + seminar.id);
  }


  onLoadMore = () => {
    log(LogLevel.UI_EVENT, "SeminarMyListViewer:onLoadMore", );

  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "SeminarMyListViewer:render()", this.props);

    return (
      <>
        <div className={"common-content"}>
          <SeminarMyList
            seminarListState= {this.props.categories["-1"]}
            lectures={this.props.lectures}
            onSelectSeminar= {this.onSelectSeminar}
            onGoBack= {this.goBack}
            onLoadMore= {this.onLoadMore}
          />          
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  categories: state.seminar.lists,
  lectures: state.seminar.lectures,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  refreshMyList: () => actions.seminar.reloadList(null, -1),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeminarMyListViewer));
 