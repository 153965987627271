import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AllInOneHomeTemplate from "../../components/templates/AllInOneHome";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import * as API from "./../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobListType } from "../../store/jobpost/types";
import ABTestUtil from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";
import { MY_ADDRESS } from "./../../config.json";
import Spinner from "../../components/atoms/Spinner";
import { UIServiceType } from "../../store/ui/types";
import { BoardContent } from "../../models/Model.Board";
import { BoardType } from "../../store/board/types";
import { CompanyType } from "../../models/Model.User";
import allinoneDescription from "./../../assets/image/allinone-description.png";
import { shuffle } from "lodash";

const windowAny: any = window;

const categories = [
  { type: CompanyType.ALL, name: "홈" },
  { type: CompanyType.PHARMACEUTICAL_COMPANY, name: "제약사" },
  { type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY, name: "건강기능식품" },
  { type: CompanyType.FRENCHISE, name: "약국체인" },
  { type: CompanyType.DISTRIBUTOR, name: "도매/몰" },
  { type: CompanyType.QUASI_DRUG, name: "의약외품" },
  { type: CompanyType.EMR, name: "IT서비스" },
  { type: CompanyType.FINANCE, name: "대출" },
  { type: CompanyType.CONSULT, name: "컨설팅" },
  { type: CompanyType.TAX, name: "세무" },
  { type: CompanyType.LAW, name: "법률" },
  { type: CompanyType.SCHOLAR, name: "학회/기관" },
  { type: CompanyType.EDUCATION, name: "교육/판매방법" },
  { type: CompanyType.LIFE, name: "라이프" },
];

// const bannersParteners = [
//   {
//     type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
//     imageUrl: "&&&/images/allinone-banner-hecto01.png",
//     url: "/allinone/detail?id=1bfde245-c8de-435f-988f-ac0da81a3f1c",
//     name: "헥토헬스케어",
//     item: "hecto01",
//   },
//   {
//     type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
//     imageUrl: "&&&/images/allinone-banner-mianutra01.png",
//     url: "/allinone/detail?id=039d5457-14e4-4dd2-950d-556749694805",
//     name: "엠아이에이뉴트라",
//     item: "mianutra01",
//   },
//   {
//     type: CompanyType.FRENCHISE,
//     imageUrl: "&&&/images/allinone-banner-hubase02.png",
//     url: "/allinone/detail?id=67db5448-332b-4395-a970-ae97dfb3280b",
//     name: "휴베이스",
//     item: "hubase02",
//   },
//   {
//     type: CompanyType.FRENCHISE,
//     imageUrl: "&&&/images/allinone-banner-onnuri02.png",
//     url: "https://bit.ly/yps_onnuri01",
//     name: "온누리H&C",
//     item: "onnuri02",
//   },
//   {
//     type: CompanyType.EMR,
//     imageUrl: "&&&/images/allinone-banner-teoul01.png",
//     url: "/allinone/detail?id=025e6103-10c5-4c5d-bd04-f0ebbc93c7d7",
//     name: "터울 주식회사",
//     item: "teoul01",
//   },
//   {
//     type: CompanyType.HEALTH_FUNTIONAL_FOOD_COMPANY,
//     imageUrl: "&&&/images/allinone-banner-knj02.jpg",
//     url: "/allinone/detail?id=c8764a41-6fb7-4830-93bf-f86079912121",
//     name: "김남주바이오",
//     item: "knj01",
//   },
//   {
//     type: CompanyType.FINANCE,
//     imageUrl: "&&&/images/allinone-banner-drandpharm01.png",
//     url: "/allinone/detail?id=6697c0c8-a957-4910-b63d-4ee8d30a6b02",
//     name: "닥터앤팜",
//     item: "drandpharm01",
//   },
//   {
//     type: CompanyType.EMR,
//     imageUrl: "&&&/images/allinone-banner-drx01.png",
//     url: "/allinone/detail?id=456038f0-cdeb-4965-ad35-92b50286f18d",
//     name: "내손안의약국",
//     item: "drx01",
//   },
//   {
//     type: CompanyType.EMR,
//     imageUrl: "&&&/images/allinone-banner-mango01.png",
//     url: "/boards/view?id=82229&partnerId=cb9ed578-f10b-423d-a24e-a8e170963431",
//     name: "망고스랩",
//     item: "mango01",
//   },
// ];

// const banners = [
//   {
//     type: CompanyType.ALL,
//     imageSrc: allinoneDescription,
//     url: "/allinone/introduction",
//     name: "소개",
//     item: "소개",
//   },
//   ...shuffle(bannersParteners),
// ];

const AllInOneHome: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();
  const partnerList = useSelector(
    (state: RootState) => state.allinone.partnerList
  );
  const partners = useSelector((state: RootState) => state.allinone.partners);
  const posts = useSelector(
    (state: RootState) => state.board.boards[BoardType.ALLINONE]
  );
  const contents = useSelector((state: RootState) => state.board.contents);
  const banners = useSelector((state: RootState) => state.allinone.banners);

  useEffect(() => {
    log(LogLevel.UI_ACTION, "AllInOneHome:useEffect mount", partnerList, posts);
    if (!banners) dispatch(actions.allinone.getBanners());
    if (!partnerList) dispatch(actions.allinone.getPartnerList());
    dispatch(actions.board.getBoard(BoardType.ALLINONE, 15));
  }, []);

  let queriedIndex = useMemo(() => {
    log(
      LogLevel.UI_ACTION,
      "AllInOneHome:useMemo location queriedIndex",
      history.location
    );
    const qs = queryString.parse(history.location.search);
    if (qs.id && Number(qs.id)) {
      let newIndex = categories.findIndex((category) => {
        return category.type === Number(qs.id);
      });
      return newIndex;
    }

    return -1;
  }, [history.location]);

  let index = getGlobal(GlobalKey.YPARTNERS_CATEGORY_INDEX);
  if (index == -1) {
    if (queriedIndex == -1) index = 0;
    else index = queriedIndex;

    setGlobal(GlobalKey.YPARTNERS_CATEGORY_INDEX, index);
  }
  log(
    LogLevel.UI_ACTION,
    "AllInOneHome:render",
    queriedIndex,
    index,
    location,
    window.location
  );
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(
    index
  );

  useEffect(() => {
    log(
      LogLevel.UI_ACTION,
      "AllInOneHome:useEffect queriedIndex changed",
      queriedIndex
    );
    if (queriedIndex != -1) {
      setGlobal(GlobalKey.YPARTNERS_CATEGORY_INDEX, queriedIndex);
      setSelectedCategoryIndex(queriedIndex);
    }
  }, [queriedIndex]);

  useEffect(() => {
    log(
      LogLevel.UI_ACTION,
      "AllInOneHome:useEffect location, history changed",
      location,
      history
    );
  }, [location, history]);

  const onClickPartner = (id: string) => {
    try {
      log(LogLevel.UI_ACTION, "AllInOneHome:onClickPartner", id, partners[id]);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER",
        "약사파트너스_파트너_선택",
        {
          회사id: id,
          회사: partners && partners[id] ? partners[id].companyName : "",
        }
      );
      history.push("/allinone/detail?id=" + id);
    } catch (err) {
      console.error(err);
    }
  };

  const onClickPost = (post: BoardContent) => {
    try {
      log(
        LogLevel.UI_ACTION,
        "AllInOneHome:onClickPartner",
        post.id,
        post.usersBizId
      );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_CONTENT",
        "약사파트너스_컨텐츠_선택",
        {
          게시물id: post.id,
          회사id: post.usersBizId,
          회사:
            partners && partners[post.usersBizId]
              ? partners[post.usersBizId].companyName
              : "",
          콘텐츠: post.subject,
          from: "메인페이지",
        }
      );
      history.push(
        `/boards/view?id=${post.id}&partnerId=${partners[post.usersBizId].id}`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onClickIntroduction = () => {
    try {
      log(LogLevel.UI_ACTION, "AllInOneHome:onClickPartner");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER",
        "약사파트너스_소개_선택",
        {}
      );
      history.push(`/allinone/introduction`);
    } catch (err) {
      console.error(err);
    }
  };

  const onRecommendPartner = () => {
    try {
      log(LogLevel.UI_ACTION, "AllInOneHome:onRecommendPartner");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_RECOMMEND_PARTNER",
        "약사파트너스_파트너_추천",
        {}
      );
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSfnkh7dx1eNSsCQhEVWXTge1ahXU4LLK9uei-flw__kgG4OtQ/viewform",
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );
    } catch (err) {
      console.error(err);
    }
  };
  log(LogLevel.UI_LIFECYCLE, "AllInOneHome", posts);

  const onLoadMorePosts = useCallback(() => {
    if (posts.end || posts.loading) return;
    dispatch(actions.board.getBoard(BoardType.ALLINONE, 15));
  }, [posts]);

  const onSelectCategoryIndex = (index: number) => {
    setGlobal(GlobalKey.YPARTNERS_CATEGORY_INDEX, index);
    setSelectedCategoryIndex(index);
  };
  const onClickBanner = (item: any, index: number, position: any) => {
    log(
      LogLevel.UI_ACTION,
      "AllInOneHome:onClickPartner",
      item,
      index,
      position
    );
    if (item.targetUrl) {
      try {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PARTNERS_SELECT_PARTNER",
          "약사파트너스_배너_선택",
          {
            index,
            url: item.targetUrl,
            회사: item.name,
            item: item.item,
          }
        );
        const url = item.targetUrl;
        if (url.startsWith("&&&")) {
          if (getGlobal(GlobalKey.OS) !== "browser") {
            history.push(url.substring(3));
          } else {
            window.open(
              url.replace("&&&", MY_ADDRESS),
              getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
            );
          }
        } else if (url.startsWith(MY_ADDRESS)) {
          if (getGlobal(GlobalKey.OS) !== "browser") {
            history.push(url);
          } else {
            window.open(
              url,
              getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
            );
          }
        } else if (url.startsWith("/")) {
          history.push(url);
        } else if (url.startsWith("tel") || url.startsWith("sms")) {
          window.open(url, "_system");
        } else
          window.open(
            url,
            getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
          );
      } catch (err) {
        console.error(err);
      }
    }
  };

  try {
    return (
      <AllInOneHomeTemplate
        partnerList={
          partnerList
            ? partnerList.filter((item, index) => {
                return (
                  categories[selectedCategoryIndex].type == CompanyType.ALL ||
                  partners[item].companyType ==
                    categories[selectedCategoryIndex].type
                );
              })
            : []
        }
        partners={partners}
        posts={posts ? posts.contents : []}
        onLoadMorePosts={onLoadMorePosts}
        contents={contents}
        onClickIntroduction={onClickIntroduction}
        onClickPartner={onClickPartner}
        onClickPost={onClickPost}
        onClickBanner={onClickBanner}
        onRecommendPartner={onRecommendPartner}
        categories={categories}
        banners={
          banners
            ? banners.filter((item, index) => {
                return (
                  categories[selectedCategoryIndex].type == CompanyType.ALL ||
                  item.companyType == categories[selectedCategoryIndex].type
                );
              })
            : []
        }
        selectedCategoryIndex={selectedCategoryIndex}
        onClickCategory={(index) => onSelectCategoryIndex(index)}
      />
    );
  } catch (error) {
    log(LogLevel.UI_EXCEPTION, "AllInOneHome:exception", error.message);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOneHome",
      errorMessage: error,
    });

    return null;
  }
};

export default withRouter(AllInOneHome);
