import React, { useMemo } from "react";
import { ApplyMethod, applyMethodCoupler, JobOfferInfo } from "../../../models/Model.JobPost";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheet, { BottomSheetProps } from "../../molecules/BottomSheet/BottomSheet";
import { ApplyOptionsBottomSheetContainer } from "./style";

interface ApplyOptionsBottomSheetProps extends BottomSheetProps {
  post?: JobOfferInfo;
  onChat?: () => void;
  setPhoneMessagePopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApplyOptionsBottomSheet: React.FC<ApplyOptionsBottomSheetProps> = ({
  post,
  active,
  onClose,
  onChat,
  setPhoneMessagePopup,
}) => {
  const applyMethod = useMemo(() => post.applyMethod || ApplyMethod.ALL, [post]);

  const handleSelect = (applyMethod: ApplyMethod) => () => {
    switch (applyMethod) {
      case ApplyMethod.Chat:
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_APPLY_METHOD", "구인구직_공고_지원방법", {
          jobPostApplyMethod: applyMethodCoupler[post.applyMethod],
          selectedApplyMethod: applyMethodCoupler[ApplyMethod.Chat],
        });
        onChat();
        return;
      case ApplyMethod.Message:
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_APPLY_METHOD", "구인구직_공고_지원방법", {
          jobPostApplyMethod: applyMethodCoupler[post.applyMethod],
          selectedApplyMethod: applyMethodCoupler[ApplyMethod.Message],
        });
        setPhoneMessagePopup(true);
        onClose();
        return;
      default:
        return;
    }
  };

  return (
    <ApplyOptionsBottomSheetContainer>
      <BottomSheet active={active} onClose={onClose}>
        <Text className="bottom-sheet__title" textType="H3" color={COLOR_SYSTEM.get("Gray")[800]}>
          약국에 문의하기
        </Text>
        <ul className="bottom-sheet__select-list">
          {applyMethod.includes(ApplyMethod.Chat) && (
            <li className="chat" onClick={handleSelect(ApplyMethod.Chat)}>
              <Icon width={32} height={32} name={"Chat Filled"} fill={COLOR_SYSTEM.get("Gray")[700]} />
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[700]}>
                약문약답 채팅으로 간편 문의
              </Text>
              <Icon width={24} height={24} name={"CaretRight"} fill={COLOR_SYSTEM.get("Gray")[700]} />
            </li>
          )}
          {applyMethod.includes(ApplyMethod.Message) && (
            <li className="message" onClick={handleSelect(ApplyMethod.Message)}>
              <Icon width={32} height={32} name={"Envelope"} fill={COLOR_SYSTEM.get("Gray")[700]} />
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[700]}>
                휴대폰 기본 문자로 문의
              </Text>
              <Icon width={24} height={24} name={"CaretRight"} fill={COLOR_SYSTEM.get("Gray")[700]} />
            </li>
          )}
        </ul>
      </BottomSheet>
    </ApplyOptionsBottomSheetContainer>
  );
};

export default ApplyOptionsBottomSheet;
