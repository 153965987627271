import React, { Component } from 'react';
import "./PollSimplePage.css"
import "./Common.css"
import { PollItemInfo, PollResponseItemInfo } from '../../models/Model.Poll';
import PollSimplePageItemText from './PollSimplePageItemText';
import PollSimplePageItemSelection from './PollSimplePageItemSelection';
import PollSimplePageItemMultiSelection from './PollSimplePageItemMultiSelection';
import PollSimplePageItemNumberLevelSelection from './PollSimplePageItemNumberLevelSelection';
import PollSimplePageItemNumber from './PollSimplePageItemNumber';

// type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
type Props = {
    key?: string,
    item?: PollItemInfo,
    response?: PollResponseItemInfo,
    index?: number,
    onReponse?: (pollItemindex: number, response: PollResponseItemInfo) => void,
}

type State = {
}

const components = [
    PollSimplePageItemText,
    PollSimplePageItemSelection,
    PollSimplePageItemMultiSelection,
    PollSimplePageItemNumberLevelSelection,
    PollSimplePageItemNumber,
];


class PollSimplePageItem extends Component<Props, State> {

    
    constructor(props: Props) {
        super(props);
    }

    render() {
        if(!this.props.item)
            return null;

        return React.createElement(components[this.props.item.type], {key:this.props.key, index:this.props.index, item:this.props.item, response:this.props.response, onReponse:this.props.onReponse});
    }
}


// const mapStateToProps = (state: RootState) => ({
//   me: state.user.me,
//   informationPopup : state.ui.popups[UIPopupType.INFORMATION_POPUP],
// });

// const mapDispatchToProps = {

// }
export default PollSimplePageItem;
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollSimplePage));