import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import { rippleEvent } from '../../_util/RrppleEffect'


type ButtonSize = 'S' | 'L' | 'F'
type ButtonLevel = '1' | '2' | '3'
const BUTTON_SIZE = {
  S: {
    borderRadius: '4px',
    padding: '5px 14px',
    typography: 'ButtonS',
    block:"inline-block",
    width:"auto"
  },
  L: {
    borderRadius: '4px',
    padding: '7px',
    typography: 'ButtonM',
    block:"block",
    width:"100%"
  },
  F: {
    borderRadius: '8px',
    padding: '11px',
    typography: 'ButtonL',
    block:"block",
    width:"100%"
  },
}
const BUTTON_LEVEL = {
  '1': {
    border: '1px solid '+ COLOR["Gray4"],
    color: "Gray1",
    background: "White",
  },
  '2': {
    border: '1px solid '+ COLOR["Primary"],
    color: "Primary",
    background: "White",
  },
  '3': {
    border: '1px solid '+ COLOR["Primary"],
    color: "White",
    background: "Primary",
  },
}
interface WrapperProps {
  size: ButtonSize
  level: ButtonLevel
  color?: string
  background?: string
  anim?:string
}

const Wrapper: StyledComponent<'button', React.FC, WrapperProps> = styled.button<WrapperProps>`
  padding: ${(props) => BUTTON_SIZE[props.size].padding};
  border-radius: ${(props) => BUTTON_SIZE[props.size].borderRadius};
  background-color: ${(props) => props.background?COLOR[props.background]:COLOR[BUTTON_LEVEL[props.level].background]};
  outline: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: ${(props) => BUTTON_SIZE[props.size].block};
  width: ${(props) => BUTTON_SIZE[props.size].width};
  border: ${(props) => BUTTON_LEVEL[props.level].border};
  &:disabled {
    background-color: ${COLOR.Gray4};
    border:  ${COLOR.Gray4};
    color: ${COLOR["White"]};
    cursor: not-allowed;
  }
  .ripple {
    background-color: #0004;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    transform: scale(0);
  }
  .start {
    transform: scale(0.2);
  }
  .active {
    transform: scale(2);
    transition: transform 700ms, opacity 700ms;
    opacity: 0.2;
  }

`

interface Props {
  size?: ButtonSize
  level?: ButtonLevel
  color?: string
  background?: string
  className?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}
const TextRectangleButton: React.FC<Props> = ({
  size = 'S',
  level = '1',
  color,
  background,
  className,
  children,
  disabled,
  onClick,
}) => {

  const el = useRef(null);
  const rippleEl = useRef(null);
  return (
    <Wrapper
      ref={el} 
      size={size}
      level={level}
      color={color}
      background={background}
      disabled={disabled}
      className={className?className+' common-effect-parent ':'common-effect-parent'}
      onClick={(e) => {
        rippleEvent(e, el.current, rippleEl.current);

        if(onClick) onClick(e);
      }}
    >
      <Text type={BUTTON_SIZE[size].typography} color={color?color:BUTTON_LEVEL[level].color}>
        {children}
      </Text>
      <div  className="common-effect-hover"/>
      <div ref={rippleEl} className="common-effect-ripple"/>

    </Wrapper>
  )
}

export default TextRectangleButton
