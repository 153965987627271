import { action, createAction, createAsyncAction } from 'typesafe-actions';
import {MedicineState, MedicineActionTypes} from './types'
import {KeywordType, Medicine} from './../../models/Model.Medicine'

export const getMedicine = createAction(MedicineActionTypes.MEDICINE_GET, resolve =>
  (type: KeywordType, id: number) => resolve(type, id)
);

export const setMedicine = createAction(MedicineActionTypes.MEDICINE_SET, resolve =>
  (detail: Medicine) => resolve(detail)
);

export const fetchMedicine = createAsyncAction(
  MedicineActionTypes.MEDICINE_REQUEST,
  MedicineActionTypes.MEDICINE_SUCCESS,
  MedicineActionTypes.MEDICINE_ERROR,
)<void, any, Error>();