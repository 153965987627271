

import React, { Component } from 'react';
import '../../Admin.scss';
import '../../SeminarManage.css';
import '../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonToolbar, IonButtons, IonToggle, IonCheckbox } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import { timeout } from 'q';
import smileIcon from '../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey, setGlobal } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import ProfileImage from '../../../components/ProfileImage';
import { SeminarLecture } from '../../../models/Model.Seminar';
import { defaultNewLecture } from '../../SeminarManage';
import { threadId } from 'worker_threads';
import { loadImageBase64 } from '../../../utils/ImageUtil';
import * as API from '../../../API.json';import ReactQuill from 'react-quill';
import Textarea from 'react-textarea-autosize';
import { getDateStringFromToday, getDateTimeString } from '../../../utils/TimeUtil';
import DownloadUtil from '../../../utils/DownloadUtil';
import { ComposedChart, LineChart, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import SeminarStatisticsViewStatistics from './SeminarStatisticsViewStatistics';

type Props = {
  selected?: SeminarLecture,
  waitingPopup?: any,
};

type State = {
}

class SeminarStatisticsView extends Component<Props, State> {

  state = {
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // this.fetchConsults();
  }

  render() {
    if(!this.props.selected)
      return null;

    return (
      <div className="admin-container">
        <SeminarStatisticsViewStatistics selected={this.props.selected} waitingPopup={this.props.waitingPopup}/>
      </div>
    );
  }
}

export default SeminarStatisticsView;