export function getFileSizeString(size){
    if(size < 1024)
        return (size + "B");
    size /= 1024;
    size = size.toFixed(2);
    if(size < 1024)
        return (size + "KB");
    size /= 1024;
    size = size.toFixed(2);
    if(size < 1024)
        return (size + "MB");
    size /= 1024;
    size = size.toFixed(2);
    if(size < 1024)
        return (size + "GB");
    size /= 1024;
    size = size.toFixed(2);
    if(size < 1024)
        return (size + "TB");

}
