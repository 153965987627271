import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

interface JobPostPopupStyledProps {
  // page?: Page;
  selected?: boolean;
}

const ApplyOptionsBottomSheetContainer = styled.div`
  .bottom-sheet__title {
    display: inline-block;
    margin-bottom: 30px;
  }

  .bottom-sheet__select-list {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 16px;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      cursor: pointer;

      & > * + * {
        margin-left: 20px;
      }

      span {
        flex: 1;
      }
    }
  }
`;

const MoreBottomSheetContainer = styled.div`
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 27px;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    width: 100%;
  }
`;

const FinishPostPopupMain = styled.div<JobPostPopupStyledProps>`
  width: 100%;
  height: 100%;
  text-align: ${({ page }) => (page === "Ask" ? "center" : "left")};
  /* ${({ page }) => {
    if (page === "Finish") {
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
      `;
    }
  }} */

  .popup__title {
    padding-left: 24px;
  }
`;

const FinishPostPopupCadidates = styled.ul`
  width: 100%;
  height: calc(100% - 100px);
  margin-top: 15px;
  overflow-y: scroll;
`;

const FinishPostPopupCadidateItem = styled.li<JobPostPopupStyledProps>`
  background-color: ${({ selected }) => (selected ? COLOR_SYSTEM.get("Skyblue")[10] : "transparent")};
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.no-list {
    justify-content: flex-start;
  }

  .profile {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
  }

  .list-item__name {
    flex: 1;
  }
`;

const FinishPostPopupFooter = styled.footer`
  width: 100%;
  padding: 0 24px 8px;
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

const WorkTypeList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  & > * + * {
    margin-top: 27px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .worktype__item__title {
    & > * + * {
      margin-left: 12px;
    }
  }
`;

const SortList = styled.ul`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 8px;
  }

  li {
    padding: 9.5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export {
  ApplyOptionsBottomSheetContainer,
  MoreBottomSheetContainer,
  FinishPostPopupMain,
  FinishPostPopupCadidateItem,
  FinishPostPopupCadidates,
  FinishPostPopupFooter,
  WorkTypeList,
  SortList,
};
