import React from "react";
import { PageMode } from ".";
import { JobListState } from "../../../store/jobpost/types";
import Button from "../../atoms/Button";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";

interface HeaderProps {
  edit?: boolean;
  mode?: PageMode;
  myHistory?: JobListState;
  handleBackClick?: () => void;
  handleLoadMyHistoryClick?: () => void;
}

const Header: React.FC<HeaderProps> = ({ edit, mode, myHistory, handleBackClick, handleLoadMyHistoryClick }) => {
  return (
    <header
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 1,
      }}
    >
      <TopAppBar
        leftButtons={[
          <Button
            color="Secondary"
            icon="CaretLeft"
            onClick={handleBackClick}
            size="Medium"
            type="Icon"
            variant="Ghost"
          />,
        ]}
        rightButtons={
          !edit &&
          mode === "Write" &&
          myHistory &&
          myHistory.items &&
          myHistory.items.length && [
            <Button color="Primary" size="Small" type="Text" variant="Ghost" onClick={handleLoadMyHistoryClick}>
              공고 불러오기
            </Button>,
          ]
        }
        title={!edit && (mode === "Write" ? "공고 작성" : "이전 공고 불러오기")}
        titleType="H4"
      />
    </header>
  );
};

export const MemoizedHeader = React.memo(Header);
