import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  CompanyType,
  CompanyTypeName,
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import "./style.scss";
import "./../../../Common.css";
import Scroll from "../../cell/Scroll";
import Icon from "../../atoms/Icon";
import PartnersCard from "../../organisms/AllInOne/PartnersCard";
import Button from "../../atoms/Button";
import { BoardContent } from "../../../models/Model.Board";
import PostListItem from "../../organisms/AllInOne/PostListItem";
import { Absolute, Fixed, Sticky } from "../../atoms/Layout";
import FloatingButton from "../../atoms/FloatingButton";
import TabBar from "../../molecules/TabBar";
import CardView from "../../../component/molecule/CardView";
import SwipeableViews from "react-swipeable-views";
import PartnersCategoryTemplate from "./PartnersCategory";
import PartnersHomeTemplate from "./PartnersHome";

import Body from "../../../component/molecule/Body";
import { BizUserInfo } from "../../../models/Mode.Biz";
import { getGlobal, setGlobal } from "../../../utils/GlobalUtil";
import { actions } from "../../../store";
import { useDispatch, useSelector } from "react-redux";

export interface AllInOneHomeProps {
  // me: UserInfo;
  partnerList: string[];
  partners: any;
  // posts: BoardContent[];
  categories: any; // [id]: BoardList
  hotList: any;
  newList: any;
  // banners: any[];
  // selectedCategoryIndex: number;
  banners: any[];
  contents: any;
  categoryList: CompanyType[];
  onClickIntroduction: () => void;
  // onClickPartner: (id: string) => void;
  // onClickPost: (post: BoardContent) => void;
  // onClickCategory: (index: number) => void;
  onRecommendPartner: () => void;
  // onClickBanner?: (item: any, index: number, position: any) => void;
  onClickPartner: (patner: BizUserInfo) => void;
  onClickPost: (post: BoardContent) => void;
  onClickCategory: (companyType: CompanyType) => void;
  onClickBanner?: (item: any, index: number, position: any) => void;
  onShowBanner: (item: any, index: number) => void;
}

const PartnersMain: React.FC<AllInOneHomeProps> = (
  {
    // history,
    // me,
    partnerList,
    partners,
    hotList,
    newList,
    categoryList,
    categories,
    banners,
    contents,
    // selectedCategoryIndex,
    onClickIntroduction,
    onClickPartner,
    onClickPost,
    onClickBanner,
    onShowBanner,
    onClickCategory,
    onRecommendPartner,
  },
  ref
) => {
  try {
    log(LogLevel.UI_LIFECYCLE, "PartnersMainTemplate:");
    const headerRef = useRef<HTMLDivElement>();
    const mainRef = useRef<HTMLDivElement>();
    const scrollRef = useRef(null);
    const tabbarRef = useRef();

    const [selectedIndex, setSelectedIndex] = useState(
      getGlobal("intPartnersCategoryIndex")
    );
    const headerHide = useRef(false);
    const dispatch = useDispatch();

    useEffect(() => {
      log(
        LogLevel.ALL,
        "PartnersMainTemplate:useEffect [selectedIndex]",
        categories[categoryList[selectedIndex]].contents,
        categories[categoryList[selectedIndex]].contents == null
      );
      if (categories[categoryList[selectedIndex]].contents == null)
        dispatch(
          actions.board.getBizBoard({
            bizCategoryId: categoryList[selectedIndex],
          })
        );
    }, [selectedIndex]);

    const handleScroll = (isScrollUp: boolean) => {
      if (headerHide.current == isScrollUp) return;
      headerHide.current = isScrollUp;
      if (headerHide.current) {
        // scroll down
        headerRef.current.style.transform = `translateY(-108px)`;
        mainRef.current.style.transform = `translateY(-108px)`;
        mainRef.current.style.height = "calc(100vh - 56px)";
      } else {
        // scroll up
        headerRef.current.style.transform = `translateY(0px)`;
        mainRef.current.style.transform = `translateY(0px)`;
        mainRef.current.style.height = "calc(100vh - 164px)";
      }
    };

    const setIndex = (index: number) => {
      setGlobal("intPartnersCategoryIndex", index);
      setSelectedIndex(index);
    };

    useImperativeHandle(ref, () => ({
      setIndex: setIndex,
    }));

    return (
      <div className="common-content common-position-relative common-background">
        <Sticky
          ref={headerRef}
          as="header"
          top="0"
          left="0"
          customStyle={{
            zIndex: 100,
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
            // paddingTop: "env(safe-area-inset-top)",
            paddingTop: "constant(safe-area-inset-top)",
            transition: "transform linear 0.2s",
          }}
        >
          <TopAppBar title="약사파트너스" titleType="H1" />
          <TabBar
            ref={tabbarRef}
            size="Medium"
            type="Underlined"
            selectedIndex={selectedIndex}
            items={categoryList.map((categoryId, index) => ({
              badge: false,
              onClick: () => setIndex(index),
              text: categories[categoryId].name,
            }))}
            scrollable
            enableAccordion
          />
        </Sticky>
        <Absolute
          as="main"
          top="108px"
          customStyle={{
            width: "100%",
            height: "calc(100vh - 162px)",
            backgroundColor: COLOR_SYSTEM.get("Gray")[10],
            transform: "translateZ(0)",
            transition: "transform linear 0.2s",

            "& > div:last-child": {
              height: "100%",
            },
          }}
          ref={mainRef}
        >
          <Scroll
            handleScroll={handleScroll}
            autoScrollName="partners"
            ref={(ref) => (scrollRef.current = ref)}
          >
            {selectedIndex == 0 ? (
              <PartnersHomeTemplate
                banners={banners}
                onClickPartner={onClickPartner}
                onClickPost={onClickPost}
                onClickCategory={onClickCategory}
                onClickBanner={onClickBanner}
                onShowBanner={onShowBanner}
                category={categories[CompanyType.ALL]}
                categories={categories}
                categoryList={categoryList}
                contents={contents}
                hotList={hotList}
                newList={newList}
                onClickIntroduction={onClickIntroduction}
                onRecommendPartner={onRecommendPartner}
                partners={partners}
                partnerList={partnerList}
              />
            ) : (
              <PartnersCategoryTemplate
                banners={banners}
                onClickPartner={onClickPartner}
                onClickPost={onClickPost}
                onClickBanner={onClickBanner}
                onShowBanner={onShowBanner}
                categoryList={categoryList}
                category={categories[categoryList[selectedIndex]]}
                contents={contents}
                partnerList={partnerList}
                partners={partners}
              />
            )}
          </Scroll>
        </Absolute>
      </div>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PartnersMainTemplate",
      errorMessage: error,
    });
    return null;
  }
};

export default forwardRef(PartnersMain);

interface PartnerCategoryContainer {
  handleScroll: (isScrollUp: boolean) => void;
}
