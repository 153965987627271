import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  CompanyTypeName,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import { BizUserInfo, CouponInfo } from "../../../models/Mode.Biz";

const API = {
  BIZ_USER_LIST: {
    method: "GET",
    path: "/admin/user/bizUserList",
    contentType: "application/json",
  },
};

const ROW_CNT = 30;

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect: (user: BizUserInfo) => void;
    onClose: () => void;
  };

type State = {
  search: string;
  users: BizUserInfo[];
};

class BizUserSelect extends Component<Props, State> {
  finished = false;
  loading = false;
  userList = null;

  state = {
    search: "",
    users: [],
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "BizUserSelect:onKeyPress", e.keyCode, e.shiftKey);
    if (e.keyCode == 13) {
      this.fetchData();
      e.preventDefault();
    }
  };

  onScroll = (event) => {
    let target: any = event.target;
    // log(LogLevel.UI_EVENT, "BoardList:scroll", event.detail.scrollTop, target.clientHeight, this.contentList.clientHeight);
    if (
      !this.loading &&
      target.scrollTop + target.clientHeight > this.userList.clientHeight * 0.9
    ) {
      log(
        LogLevel.UI_EVENT,
        "BizUserSelect:onScroll",
        target.scrollTop,
        target.clientHeight,
        this.userList.clientHeight
      );
      this.fetchDataMore();
    }
  };

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({ search: keywords });
  };

  refresh = () => {
    this.fetchData();
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:BizUserSelect:fetchData");
    this.loading = true;
    fetchAPI(
      API.BIZ_USER_LIST,
      "",
      { start: 0, count: ROW_CNT, query: 0, search: this.state.search },
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:BizUserSelect:fetchData result",
          result
        );
        this.finished = false;
        if (result.data && result.data.length < ROW_CNT) this.finished = true;

        this.setState({ users: result.data });
        this.loading = false;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:BizUserSelect:fetchData result",
          result
        );
        this.finished = true;
        this.setState({ users: [] });
      }
    });
  };

  fetchDataMore = async () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:BizUserSelect:fetchDataMore",
      this.finished
    );
    this.loading = true;
    if (!this.finished) {
      fetchAPI(
        API.BIZ_USER_LIST,
        "",
        {
          start: this.state.users.length,
          count: ROW_CNT,
          query: 0,
          search: this.state.search,
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:BizUserSelect:fetchData result",
            result
          );
          this.finished = false;
          if (result.data && result.data.length < ROW_CNT) this.finished = true;

          this.setState({ users: [...this.state.users, ...result.data] });
          this.loading = false;
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:BizUserSelect:fetchData result",
            result
          );
          this.finished = true;
          this.loading = false;
        }
      });
    }
  };

  clearSearch = () => {
    this.setState({ search: "" });
  };

  onSelect = (user: BizUserInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", user);
    this.props.onSelect(user);
  };

  onChange = () => {
    this.fetchData();
  };

  download = (filename, text) => {
    var pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  };

  onDownload = () => {};

  render() {
    return (
      <div className="admin-container" onScroll={this.onScroll}>
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  법인사용자목록
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="user-search-container">
          <IonInput
            class="user-search-input"
            placeholder="검색"
            value={this.state.search}
            onIonChange={this.onSearchChange}
            onKeyDown={this.onKeyPress}
          />
          <IonButton fill="clear" color="primary" onClick={this.clearSearch}>
            <IonIcon name="close" />
          </IonButton>
          <IonButton color="primary" onClick={this.onDownload}>
            다운로드
          </IonButton>
        </div>
        <div className="user-admin-list-container">
          <IonList
            ref={(ref) => {
              this.userList = ref;
            }}
          >
            {this.state.users.map((item: BizUserInfo, index: number) => {
              return (
                <div
                  key={index.toString()}
                  className="common-container-row common-flex-align-center"
                  onClick={() => this.onSelect(item)}
                >
                  <ProfileImage
                    className="user-admin-list-item-profile"
                    profileUrl={item.logoImageUrl}
                  />
                  <div className="user-admin-list-item-container common-flex-grow">
                    <div>ID: {item.id}</div>
                    <div>
                      {item.companyName}/ {item.companyProfileName}/{" "}
                      {item.userName} / {CompanyTypeName[item.companyType]}
                    </div>
                    <div>{item.phone}</div>
                    <div>{item.email}</div>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    <IconButton
                      size="M"
                      variant="Outlined"
                      color="Primary"
                      iconName="Call"
                      onClick={() => callPhone(item.phone)}
                    />
                  </div>
                </div>
              );
            })}
          </IonList>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BizUserSelect);
