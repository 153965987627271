import React, { Component } from "react";
import "./../../../Common.css";

type Props = {};

const InformationPrivacy: React.SFC<Props> = ({}) => {
  return (
    <div className="common-block">
      개인정보처리방침 <br />
      <br />
      약문약답 운영사인 약문약답 주식회사(이하 '회사')는 회원의 개인정보를
      적극적으로 보호하며 정보통신망이용촉진 및 정보보호 등에 관한 법률 등 모든
      관련 법령을 준수하기 위하여 회원 개인정보처리방침(이하
      '개인정보처리방침')을 제정하고 이를 준수하고 있습니다.
      <br />
      또한 아래에 게시된 개인정보보호 정책을 통하여 회원이 제공하는 개인정보가
      어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
      취해지고 있는지 알려드립니다.
      <br />
      본 개인정보처리방침은 회사가 운영하는 서비스에 공개되며, 회원이 언제나
      쉽게 찾아볼 수 있도록 하고 있습니다.
      <br />
      <br />
      1. 개인정보의 수집 및 이용 목적
      <br />
      '회사'는 아래와 같은 목적으로 개인정보를 수집하여 활용하고 있습니다.
      <br />
      - 회원가입 의사의 확인, 연령 확인, 본인 확인, 이용자 식별, 회원탈퇴 의사의
      확인 등 회원관리
      <br />
      - 서비스의 개발, 제공, 향상 및 원활한 운영 및 이용자가 안심하고 이용 할 수
      있는 서비스 이용환경 구축
      <br />
      - 약문약답 이용약관 및 개인정보처리방침의 개정 등의 고지사항 전달
      <br />
      - 약문약답 신규 서비스/이벤트 안내 및 마케팅 광고
      <br />
      - 법령 및 약문약답 이용약관을 위반하는 회원에 대한 이용 제한 조치,
      서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위에 대한
      방지 및 제재, 계정도용 및 부정거래 방지, 불만처리를 위한 기록 보존
      <br />
      <br />
      2. 개인정보수집/이용 등에 대한 동의방법
      <br />
      회원의 개인정보 수집, 이용 등에 대한 회원동의의 방법은 서비스 회원가입
      시에 게시된 ‘서비스 이용약관’ 및 '개인정보 수집/이용 동의'의 세부내용을
      숙지하고 서비스에서 제공하는 ‘동의’ 표시를 선택함으로써 완료됩니다. 또한
      상담게시판을 통해 회원이 동의 의사를 표시하거나 Fax, 우편 등을 통해 회원이
      동의 의사를 서명, 날인 후 제출하는 경우 개인정보 수집, 이용, 제3자 제공 및
      취급업무의 위탁 등에 동의한 것으로 간주합니다.
      <br />
      <br />
      3. 수집하는 개인정보
      <br />
      1) '회사'는 서비스 제공을 위하여 필요한 최소한의 개인정보를 당사의
      서비스를 통한 회원가입과 고객님이 동의하시는 경우에 한하여 수집,
      이용합니다. 다만, 고객님이 동의하시는 경우에 한하여 '선택정보'를 수집,
      이용하고 있습니다.
      <br />
      <br />
      2) 수집방법은 서비스를 통한 회원가입, 이메일 고객문의를 통해 고객이
      제시하는 정보를 수집합니다. 서비스 이용 과정에서 IP 주소, 쿠키, 서비스
      이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.
      <br />
      <br />
      3)회원인증 시 수집 정보
      <br />
      Ⅰ. 회원인증 시 수집 정보
      <br />
      - 필수정보 : 이름, 연락처, 이메일, 약사면허번호, 약사면허증
      촬영본,근무형태, 근무지
      <br />
      (약사면허증 촬영본은 가인승인 즉시 복구불가한 방법으로 삭제합니다)
      <br />
      Ⅱ. 본인인증 시 수집 정보
      <br />
      - 필수정보 : 이름, 생년월일, 성별, 내/외국인, 휴대폰 번호, 이동통신사 정보
      또는 아이핀 정보, 본인확인값
      <br />
      <br />
      4. 개인정보의 보유, 이용기간 및 파기
      <br />
      1) 회원의 개인정보는 '회사'가 회원에게 서비스를 제공하는 기간(이용기간)
      또는 분쟁처리 기간(보유기간) 동안에 한하여 보유하고 이를 활용합니다.
      <br />
      A. 이용기간: 서비스 가입일 ~ 해지일 까지
      <br />
      B. 보유기간: 불량회원의 부정한 이용의 재발 방지 및 서비스 혼선 방지를 위해
      이용계약 해지일로부터 30일간 보유
      <br />
      <br />
      2) 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
      의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안
      개인정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만
      이용하며 보존기관은 아래와 같습니다.
      <br />
      A. 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의 소비자보호에
      관한 법률)
      <br />
      B. 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의
      소비자보호에 관한 법률)
      <br />
      C. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의
      소비자보호에 관한 법률)
      <br />
      D. 로그인 기록: 3개월(통신비밀보호법)
      <br />
      3) 개인정보의 파기절차 및 방법
      <br />
      <br />
      A. 파기절차 : 회원이 서비스 가입 등을 위해 제공하신 정보는 목적이 달성된
      후 별도의 DB로 옮겨져 본 개인정보처리방침 및 기타 관련 법령에 의한 보유
      사유에 따라 일정기간 저장된 후, 아래 다항에 명시한 방법으로 파기됩니다.
      별도 DB에 옮겨진 개인정보는 법률에 의해 이용되는 경우가 아니고서는 이외의
      다른 목적으로 이용되지 않습니다.
      <br />
      B. 파기대상: 보유기간 및 관련법령에 따른 보존기간이 종료된 회원정보
      <br />
      C. 파기방법 : DB에 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수
      없는 기술적 방법으로 삭제하며 출력물 등은 분쇄하거나 소각하는 방식으로
      파기
      <br />
      <br />
      5. 개인정보 처리 위탁 동의
      <br />
      1) 회사는 서비스 이용계약, A/S 제공 및 그 부대업무의 대행 등 회원 편의
      제공, 관리 등을 위하여 개인정보 취급업무를 위탁하고 있으며, 수탁자에
      대해서는 수탁계약서 등을 통하여 관련 법규 및 지침의 준수, 정보보호 및
      비밀유지, 제3자 제공 금지, 사고 시 책임부담, 위탁기간 종료 즉시 개인정보의
      반납/파기 의무 등을 규정하여 관리하고 있습니다.
      <br />
      <br />
      2) 회사는 회원정보 취급을 위탁 받은 업체가 개인정보 보호와 관련하여 제반의
      조치를 취하도록 하며 만약 회원정보를 위탁 받은 업체가 고의 또는 과실로
      인하여 회원에게 손해를 입힌 경우 그에 대한 모든 책임을 부담합니다.
      <br />
      <br />
      3) 수탁업체 및 수탁 업무내용은 아래와 같습니다.
      <br />
      A. AWS(아마존 웹 서비스) : 클라우드 서버 데이터베이스 저장
      <br />
      B. 젤라블루 : 모바일 쿠폰 발송 대행 서비스
      <br />
      C. 주)케이티엠하우스 : 모바일 쿠폰 발송 대행 서비스 <br />
      D. 판매자 [[](https://www.notion.so/f275b43ec1f84bc8b27c93944e47e7f8) ]
      <br />
      <br />
      6. 개인정보에 대한 회원의 권리와 그 행사 방법
      <br />
      회원은 언제나 회원센터 연락을 통해 등록되어 있는 자신의 개인정보를
      조회하거나 수정할 수 있으며, 개인정보의 수집과 이용에 대한 동의의 철회를
      요청할 수 있습니다.
      <br />
      <br />
      7. 개인정보의 열람, 증명 또는 정정
      <br />
      1) 회원은 우편, FAX 등을 통해 개인정보에 대한 열람, 증명을 요구할 수
      있습니다.
      <br />
      <br />
      2) 회원이 본인의 개인정보에 대한 열람, 증명을 요구하는 경우 회원의 신분을
      증명할 수 있는 주민등록증, 여권, 운전면허증(신형) 등의 신분증명(사본)을
      제시 받아 본인 여부를 확인합니다.
      <br />
      <br />
      3) 회원의 대리인이 열람, 증명을 요구하는 경우에는 대리 관계를 나타내는
      위임장, 명의회원의 인감증명서와 대리인의 신분증명서 등의 증표를 제시 받아
      대리인인지 여부를 확인합니다.
      <br />
      <br />
      4) 회원께서 개인정보의 오류에 대해 정정을 요청하신 경우에는 정정을
      완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
      개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
      없이 통지하여 정정이 이루어지도록 하겠습니다.
      <br />
      <br />
      5) 단, 다음과 같은 경우에는 예외적으로 개인정보의 열람 및 정정을 제한할 수
      있습니다.
      <br />
      A. 본인 또는 제 3 자의 생명, 신체, 재산 또는 권익을 현저하게 해할 우려가
      있는 경우
      <br />
      B. 당해 서비스 제공자의 업무에 현저한 지장을 미칠 우려가 있는 경우
      <br />
      C. 법령에 위반하는 경우
      <br />
      <br />
      8. 개인정보의 수집과 이용 또는 제공에 대한 동의 철회
      <br />
      회원은 개인정보의 수집과 이용 또는 제공에 대한 동의를 철회할 수 있습니다.
      동의 철회를 위해서는 회원센터 연락을 통해 본인 확인 절차를 거친 후, 동의를
      철회할 수 있습니다.
      <br />
      <br />
      9. 개인정보의 자동 수집 장치(쿠키)의 설치, 운영 및 그 거부 방법
      <br />
      1) 회사는 개인화되고 맞춤화된 서비스를 제공하기 위하여 회원의 정보를
      저장하고 수시로 불러오는 쿠키(Cookie)를 사용합니다.
      <br />
      <br />
      2) 쿠키는 웹사이트를 운영하는데 이용되는 서버가 회원이 이용하는
      브라우저에게 보내는 아주 작은 텍스트 파일로 회원이 이용하는
      단말기(PC/스마트폰/태플릿 PC 등)의 하드디스크에 저장됩니다. 이후 회원이
      웹사이트에 방문할 경우 웹사이트 서버는 회원'이 이용하는 단말기의
      하드디스크에 저장되어 있는 쿠키의 내용을 읽어 '회원'의 환경설정을 유지하고
      맞춤화된 서비스를 제공하기 위해 이용됩니다.
      <br />
      <br />
      3) 회사의 쿠키 사용 목적은 회원이 서비스 홈페이지에 접속을 하면 회원이
      방문한 페이지의 접속 빈도, 방문 시간 및 횟수 등을 분석하고 회원의 이용
      행태 등을 파악하여 회원을 위한 마케팅이나 개인화 서비스의 제공 등을 위하여
      사용합니다. 쿠키는 브라우저의 종료 시나 로그아웃 시 만료됩니다.
      <br />
      <br />
      4) 회원은 언제든지 쿠키의 설치를 거부하거나 삭제할 수 있습니다. 이에 따라
      회원은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
      저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
      있습니다. 단, 쿠키의 저장을 거부할 경우 일부 서비스는 이용에 어려움이 있을
      수 있습니다. 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의
      경우)은 다음의 각 호와 같습니다.
      <br />
      A. 웹브라우저 상단의 [도구] 메뉴에서 [인터넷 옵션]을 클릭
      <br />
      B. [개인정보] 탭을 클릭
      <br />
      C. [개인정보 처리 수준]을 설정
      <br />
      <br />
      10. 회원 의견수렴 및 불만처리
      <br />
      '회사'는 회원의 의견을 매우 소중하게 생각합니다. 회원께서 문의사항이 있을
      경우, '회사' 회원센터에 문의하시면 신속하고 정확한 답변을 드리겠습니다.
      <br />
      A. 개인정보보호책임자: 이성연
      <br />
      B. 개인정보보호담당자: 이성연
      <br />
      C. 소속부서: 약문약답 개발팀
      <br />
      D. 이메일: oscar@ymyd.co.kr
      <br />
      <br />
      11. 개인정보의 기술적, 관리적 보호
      <br />
      '회사'는 회원의 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록
      안전성 확보를 위하여 다음과 같은 기술적.관리적 대책을 마련하고 있습니다.
      <br />
      <br />
      [기술적 보호대책]
      <br />
      1) 개인정보는 비밀번호에 의해 보호되며, 중요한 데이터는 파일 및 전송
      데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하는 등의 별도 보안기능을
      통해 보호되고 있습니다.
      <br />
      2) 백신 프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한
      조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런
      바이러스가 출현할 경우 백신이 나오는 즉시 이를 도입, 적용함으로써
      개인정보가 침해되는 것을 방지하고 있습니다.
      <br />
      3) 네트워크 상의 개인정보를 안전하게 전송할 수 있도록 보안장치(SSL)를
      채택하고 있습니다.
      <br />
      4) 해킹 등에 의해 회원의 개인정보가 유출되는 것을 방지하기 위해,
      외부로부터 접근이 통제된 구역에 시스템을 설치하고, 침입을 차단하는 장치를
      이용하고 있습니다.
      <br />
      <br />
      [관리적 보호대책]
      <br />
      1) 회사는 회원의 개인정보에 대한 관리와 접근에 필요한 절차를 마련하여
      임직원이 이를 숙지하고 준수하도록 하고 있으며 준수 여부를 주기적으로
      점검하고 있습니다.
      <br />
      <br />
      2) 회사는 회원의 개인정보를 취급할 수 있는 자를 최소한으로 제한하고 접근
      권한을 관리하며, 교육을 통하여 법규 및 정책을 준수할 수 있도록 하고
      있습니다. 회원의 개인정보를 취급하는 자는 다음과 같습니다.
      <br />
      A. 회원을 직/간접적으로 상대하여 업무를 처리하는 자<br />
      B. 개인정보보호책임자 및 개인정보보호담당자 등 개인정보 관리 및
      개인정보보호 업무를 담당하는 자<br />
      C. 기타 업무상 개인정보의 접근이 불가피한 자<br />
      <br />
      3) 신규직원 채용 시 정보보호서약서에 서명함으로써 직원에 의한
      정보(개인정보 포함)유출을 사전에 방지하고, 수시로 개인정보보호 의무를
      상기시키며 준수 여부를 감사하기 위한 내부 절차를 마련하여 시행하고
      있습니다.
      <br />
      <br />
      4) 개인정보 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게
      이뤄지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확하게
      규정하고 있습니다.
      <br />
      <br />
      5) 서비스이용계약 체결 또는 서비스제공을 위하여 회원의 은행결제계좌,
      신용카드번호 등 대금결제에 관한 정보를 수집하거나 회원에게 제공하는 경우
      당해 회원이 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.
      <br />
      <br />
      12. 개인정보 침해 관련 상담 및 신고
      <br />
      개인정보 침해에 대한 상담 및 신고가 필요하신 경우에는 개인정보 관련
      담당자에게 전화 또는 이메일로 연락하시거나, 미래창조과학부 산하 공공기관인
      한국인터넷진흥원(KISA) 내에 설치된 개인정보 침해 신고센터로 문의하시기
      바랍니다.
      <br />
      A. 개인정보침해사고대응 (https://privacy.kisa.or.kr)
      <br />
      B. 대검찰청 첨단범죄수사과 (http://www.spo.go.kr/ tel.02-3480-2000)
      <br />
      C. 경찰청 사이버 안전국
      ([https://www.ctrc.go.kr/](https://cyberbureau.police.go.kr/index.do)
      tel.182)
      <br />
      <br />
      13. 개인정보 처리방침의 고지 또는 통지방법
      <br />
      개인정보처리방침에 관련 법령 및 정책 또는 보안기술의 변경에 따라 내용의
      추가 삭제 및 수정이 있을 경우 지체없이 서비스 공지사항 또는 별도의 창을
      통해 변경 사유 및 내용 등을 공지합니다.
      <br />
      <br />
      [부칙]
      <br />
      1. (시행일) 본 개인정보 처리방침은 2024년 1월 1일부터 시행합니다.
    </div>
  );
};

export default InformationPrivacy;
