
import * as gamifications from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { GamificationState} from "./types";
import {log, LogLevel} from '../../utils/LogUtil'
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { GamificationPeriodicReport } from '../../models/Model.Gamification';

const defaultState: GamificationState = {
  report : null,
};

export default (state = defaultState, action: ActionType<typeof gamifications>): GamificationState => {
  switch (action.type) {

    case getType(gamifications.fetchReport.success):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchReport.success", action, state);
      if(action.payload){
        let report:GamificationPeriodicReport = action.payload;
        return {...state, report};
      }else{
        return {...state, report:null};
      }
    case getType(gamifications.fetchReport.failure):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchReport.failure", action, state);
      return {...state, report:null};
    case getType(gamifications.clearReport):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchReport.clearReport", action, state);
      return {...state, report:null};
    default:
      return state;
  }

  return state;
}
