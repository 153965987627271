import React from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import styled from "styled-components";

interface ShareBottomSheetStyledProps {
  iconBackgroundColor?: string;
}

const ShareButtonLayout = styled.div<ShareBottomSheetStyledProps>`
  cursor: pointer;
  text-align: center;

  .share-btn__icon {
    width: 60px;
    height: 60px;
    background-color: ${({ iconBackgroundColor }) => iconBackgroundColor};
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }
`;

interface ShareButtonProps {
  backgroundColor: string;
  iconWidth: number;
  iconHeight: number;
  icon: string;
  iconColor: string;
  title?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  backgroundColor,
  iconWidth,
  iconHeight,
  icon,
  iconColor,
  title,
  onClick,
}) => {
  return (
    <ShareButtonLayout iconBackgroundColor={backgroundColor} onClick={onClick}>
      <div className="share-btn__icon">
        <Icon width={iconWidth} height={iconHeight} name={icon} color={iconColor} />
      </div>
      <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[800]}>
        {title}
      </Text>
    </ShareButtonLayout>
  );
};

export default React.memo(ShareButton);
