

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonLabel, IonSpinner, IonToggle } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import * as API from './../../../API.json';
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../../models/Model.Board';
import Truncate from 'react-truncate';
import { BannerInfo, EMPTY_BANNER, BannerType } from '../../../models/Model.Banner';
import { PollInfo, EMPTY_POLL, PollItemInfo, PollResponseInfo, PollStatisticsInfo, PollStatisticsItemInfo } from '../../../models/Model.Poll';
import PollEdit from './PollEdit';
import { loadList } from '../../../store/jobpost/actions';
import { isThisISOWeek } from 'date-fns';
import { BarChart, Bar, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

type Props = {
  onNext: (component:any, depth: number, data:any) => void, 
  selectedPoll: PollInfo;
  statistics: PollStatisticsInfo,
  item: PollStatisticsItemInfo,
  key?: string,
};

type State = {
  showGraph: boolean,
  item: PollStatisticsItemInfo,
}

class PollSatisticsItem extends Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      showGraph: true,
      item: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    if(!this.props.selectedPoll || !this.props.selectedPoll.id)
      return;

    // let result = await fetchAPI(AdminAPI.STATISTICS, this.props.selectedPoll.id + "/statistics", null, null, getGlobal(GlobalKey.TOKEN));
    // if(result && !result.error && result.data){
    //   this.setState({statstics: result.data});
    // }
    // else{
    //   this.setState({statstics: null});
    // }
  }

  render() {
    log(LogLevel.UI_EVENT, "PollSatisticsItem:render", this.state);

    let loadAll;
    if(!this.state.item){
      loadAll = (
        <IonButton color="admin-button" onClick={this.fetchData}>
          전체로드
        </IonButton>   
      );
    }

    let item:PollStatisticsItemInfo = this.props.item;
    if(this.state.item)
      item = this.state.item;

    return (
      <div key={this.props.key} className="admin-card-container">
        <div className="common-container-row common-flex-align-center">
          {loadAll}
          <div className="search-options-container">
            <div className={(this.state.showGraph)?"search-option-text-inactive":"search-option-text-active"} onClick={(e) => {this.setState({showGraph:false})}}>Table</div>
            <IonToggle class="search-options-toggle" mode="ios" checked={this.state.showGraph} onClick={(e) => this.setState({showGraph:(!this.state.showGraph)})}/>
            <div className={(this.state.showGraph)?"search-option-text-active":"search-option-text-inactive"} onClick={(e) => {this.setState({showGraph:true})}}>Graph</div>
          </div>
        </div>  
        <div className="common-container admin-margin-bottom">
          <div className="admin-normal-text">{item.pollItem.order}. {item.pollItem.question}</div>
          <div className="admin-normal-text common-bold common-color-highlight">응답자: {item.responseCount} ({Math.floor((item.responseCount * 100 / this.props.statistics.responseCount))}%)</div>
        </div>
        {this.renderTable()}
        {this.renderGraph()}
      </div>
    );
  }

  
  renderTable = () => {
    if(this.state.showGraph)
      return null;

    let item:PollStatisticsItemInfo = this.props.item;
    if(this.state.item)
      item = this.state.item;

    if(!item.values.length)
      return;

    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <table className="admin-table">
            <tbody>
              <tr>
                <td className="admin-table-label-x">값</td>
                <td className="admin-table-label-x">수</td>
                <td className="admin-table-label-x">비율</td>
              </tr>
              {item.values.map((value, index) => {
                return (
                  <tr key={index.toString()} onClick={() => this.onSelect(value)}>
                    <td className="admin-table-value-text">{value.value}</td>
                    <td className="admin-table-value">{value.count}</td>
                    <td className="admin-table-value">{value.ratio}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  onSelect = (value) => {
  }
    
  
  renderGraph = () => {
    if(!this.state.showGraph)
      return null;

    let item:PollStatisticsItemInfo = this.props.item;
    if(this.state.item)
      item = this.state.item;

    if(!item.values.length)
      return;

    let values = item.values.slice(0,10);

    let width = 100 * values.length;
    if(width > 800)
      width = 800;
    
    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <BarChart width={width} height={250} data={values}>
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
            <XAxis dataKey="value" />
            <YAxis yAxisId="left" scale="auto"/>
            <Tooltip />
            <Legend />
            <Bar yAxisId="left" dataKey="count" fill="#8884d8" barSize={20}/>
          </BarChart>
        </div>

      </div>
    );
  }
}

export default PollSatisticsItem;