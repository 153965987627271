import axios from "axios"
import styled from "styled-components"
import React, { useState, useEffect, useRef } from "react"
import "./../../Admin.scss"
import Textarea from "react-textarea-autosize"
import "./../../../Common.css"
import { withRouter } from "react-router-dom"
import {
  IonButton,
  IonInput,
  IonList,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonIcon,
} from "@ionic/react"
import { fetchAPI } from "../../../utils/APIUtil"
import { connect, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { UIPopupType } from "../../../store/ui/types"
import { log, LogLevel } from "../../../utils/LogUtil"
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil"
import GetUserConditionedDistributionView from "./GetUserConditionedView"
import { NotificationType } from "../../../models/Model.Notification"
import { getDateStringFromToday, getDateTimeDifferent, getDateTimeString } from "../../../utils/TimeUtil"
import Button from "../../../components/atoms/Button"
import ToggleSwitch from "../../../component/atom/ToggleSwitch"
// import { SERVER_ADDRESS, SERVER_API_PORT } from "./../config.json";

const USER_ROW_CNT = 30

const API = {
  MESSAGE_LIST: {
    method: "GET",
    path: "/admin/notification/custom/messages",
    contentType: "application/json",
  },
  INSERT_MESSAGE: {
    method: "POST",
    path: "/admin/notification/custom/message",
    contentType: "application/json",
  },
  UPDATE_MESSAGE: {
    method: "PUT",
    path: "/admin/notification/custom/message",
    contentType: "application/json",
  },
  DELETE_MESSAGE: {
    method: "DELETE",
    path: "/admin/notification/custom/message",
    contentType: "application/json",
  },
  SEND_MESSAGE: {
    method: "POST",
    path: "/admin/notification/custom/send/message",
    contentType: "application/json",
  },
}

const InAppMessageSelect = (props) => {
  const [search, setSearch] = useState("")
  const [messages, setMessages] = useState([])
  const [toastMessage, setToastMessage] = useState<string | null>(null)
  const [hideDeleted, toggleHideDeleted] = useState(true)
  const userListRef = useRef(null)
  let loading = false
  let finished = false

  const toastPopup = useSelector(
    (state: any) => state.ui.popups[UIPopupType.TOAST_POPUP]
  )
  const confirmPopup = useSelector(
    (state: any) => state.ui.popups[UIPopupType.CONFIRM_POPUP]
  )


  const filteredMessages = messages.filter((message) =>
    (!hideDeleted || !message.deletedAt) && 
    (!search || [message.title, message.body, message.scheduledAt].some((value) =>
      value.toLowerCase().includes(search.toLowerCase())
    ))
  );


  useEffect(() => {
    fetchData()
  }, [search, props.selectedService])

  const onSearchChange = (e) => {
    setSearch(e.detail.value)
  }

  const handleSelect = (service) => {
    props.setSelectedService(service)
  }

  const onDelete = (id) => {
    fetchAPI(
      API.DELETE_MESSAGE,
      "/" + id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    )
      .then((response) => {
        // Show a success toast
        setToastMessage(null) // Add this line
        setToastMessage("삭제 완료")

        fetchData()
      })
      .catch((error) => {
        setToastMessage(null) // Add this line
        setToastMessage("삭제 에러")
      })
  }

  const handleSaveChanges = (id, changes) => {
    const index = messages.findIndex((message) => message.id === id)
    if (index !== -1) {
      messages.splice(index, 1, { ...messages[index], ...changes })
    }
    console.log("handleSaveChanges", messages[index])
    onSave(messages[index])
    fetchData()
  }

  const onSend = (e) => {
    fetchAPI(
      API.SEND_MESSAGE,
      "",
      null,
      e,
      getGlobal(GlobalKey.TOKEN)
    ).then((response) => {})
  }

  const onSave = (e) => {
    console.log("onSave ee:", e)
    fetchAPI(
      API.UPDATE_MESSAGE,
      "/" + e.id,
      null,
      e,
      getGlobal(GlobalKey.TOKEN)
    )
      .then((response) => {
        // Show a success toast
        setToastMessage(null) // Add this line
        setToastMessage("Changes saved successfully")

        fetchData()
      })
      .catch((error) => {
        // Show an error toast
        setToastMessage(null) // Add this line
        setToastMessage("Error saving changes")
      })
  }

  const fetchData = async () => {
    if (loading) return
    loading = true

    const result = await fetchAPI(
      API.MESSAGE_LIST,
      "",
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    )

    log(LogLevel.ALL, "fetchData", result)

    if (result) {
      finished = Array.isArray(result.data) && result.data.length < USER_ROW_CNT

      try{
        console.log("result.data", result.data)
        setMessages(Array.isArray(result.data) ? result.data : [])
      }catch(err){
        console.log(err)
      }
    } else {
      finished = true
      setMessages([])
    }

    loading = false
  }
  return (
    <div className="admin-container">
      {toastMessage && toastPopup.show(toastMessage)}
      <IonInput
        class="admin-input"
        placeholder="검색"
        value={search}
        onIonChange={onSearchChange}
      />
      <ToggleSwitch checked={hideDeleted}  onClick={() => toggleHideDeleted(!hideDeleted)} >
            <IonIcon size="large" slot="icon" icon="moon" />
      </ToggleSwitch>
      <IonList ref={userListRef}>
        <div className="partners-list">
          {filteredMessages.map((message) => (
            <CustomMessageList
              key={message.id}
              message={message}
              onSave={handleSaveChanges}
              onDelete={onDelete}
              onSend={onSend}
              toastPopup={toastPopup}
              confirmPopup={confirmPopup}
            />
          ))}
        </div>
      </IonList>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
})

// mapDispatchToProps remains the same

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(InAppMessageSelect)

const CustomMessageListContainer = styled.div<{ deletedAt: string | null }>`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${props => props.deletedAt ? '#FFCCCC' : 'white'};

  h3 {
    cursor: pointer;
    color: blue;
  }
`
const EditableField = styled.div`
  margin: 5px 0;
  &:hover {
    background-color: #f0f0f0;
  }
`

const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 40px;
  display: flex;
  gap: 10px;
`

const InputLarge = styled.textarea`
  border: none;
  background: none;
  padding: 0;
  padding-left: 20px;
  outline: none;
  width: 100%; // This will make the input field take up the full width of its parent
  font-size: 1em;
  resize: vertical; // This allows vertical resizing
  &:focus {
    outline: none;
  }
`

const Input = styled.input`
  border: none;
  background: none;
  padding: 0; // Remove the default padding
  padding-left: 20px;
  outline: none;
  width: 100%;
  &:focus {
    outline: none;
  }
`


const DateInput = styled.input.attrs({ type: "date" })`
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.3s ease-in-out;

  &:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  &.common-color-caution {
    border: 1px solid red;
  }
`

const InformationContainer = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`

const PageButton = styled.button`
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`

function CustomMessageList({
  message,
  onSave,
  onDelete,
  toastPopup,
  onSend,
  confirmPopup,
}) {
  const formatDate = (dateString) => {
    if (null == dateString) return "예약 없음"
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Months are 0-indexed in JavaScript
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const [ids, setIds] = useState([]) // [1, 2, 3, 4, 5
  const [showDetails, setShowDetails] = useState(false)
  const [height, setHeight] = useState("auto")
  const [value, setValue] = useState("")
  const [error, setError] = useState(false)
  const [editFields, setEditFields] = useState({
    id: message.id,
    title: message.title,
    body: message.body,
    url: message.data && message.data.url,
    data: message.data && message.data,
    type: message.data && JSON.parse(message.data).type,
    scheduledAt: getDateStringFromToday({ date: message.scheduledAt }),
    createdAt: message.createdAt,
    startedAt: getDateTimeString(message.startedAt),
    endedAt: getDateTimeString(message.endedAt),
    deletedAt: message.deletedAt,
    sentUserCount: message.sentUserCnt,
    sentDeviceCount: message.sentDeviceCnt,
  })

  const calculateHeight = (value = "") => {
    let numberOfLineBreaks = (value.match(/\n/g) || []).length
    // min-height + lines x line-height + padding + border
    let newHeight = 20 + numberOfLineBreaks * 20 + 12 + 2
    return newHeight
  }

  useEffect(() => {
    setHeight(`${calculateHeight(value)}px`)
  }, [value])

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto"
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
    }
  }, [editFields.data])

  // useEffect(() => {
  //   console.log("editing url", editFields.data.url)
  //   setEditFields((prevFields) => { 
  //   let dataObj: any = {};
  //   try {
  //     dataObj = JSON.parse(prevFields.data);
  //     console.log("hello", dataObj)
  //   } catch (e) {
  //     dataObj = { data: { where: [] } }; // 파싱 실패 시 기본값 설정
  //   }

  //   dataObj = dataObj || {}; 

  //   dataObj = {
  //     ...dataObj,
  //     data: {
  //       ...(dataObj.data || {}),
  //       url: prevFields.data.url, 
  //     }
  //   }

  //   const updatedDataObj = { ...dataObj, data: { ...dataObj.data } };

  //   const rvalue = { ...prevFields, data: JSON.stringify(updatedDataObj, null, 2) };
  //   console.log("rvalue", rvalue)
  //   return rvalue
  // })
  // }, [editFields.data.url])

  const handleFieldChange = (field, value) => {
    if (field === "data") {
      try {
        let jsonData = JSON.parse(value)
      } catch (e) {
        setError(true)
        toastPopup.show("잘못된 JSON 형식입니다.")
      }
    }

    try{
      if(field === "url"){
          setEditFields((prevFields) => { 
          let dataObj: any = {};
          try {
            dataObj = JSON.parse(prevFields.data);
            console.log("hello", dataObj)
          } catch (e) {
            dataObj = { data: { where: [] } }; // 파싱 실패 시 기본값 설정
          }

          dataObj = dataObj || {}; 

          dataObj = {
            ...dataObj,
            data: {
              ...(dataObj.data || {}),
              url: value, 
            }
          }

          const updatedDataObj = { ...dataObj, data: { ...dataObj.data } };

          const rvalue = { ...prevFields, data: JSON.stringify(updatedDataObj, null, 2) };
          return rvalue
        })
      } else {
        setEditFields((prevFields) => ({
          ...prevFields,
          [field]: value,
        }))
      }
    }catch(err){
      console.log(err)
    }
  }

  const handleSaveClick = () => {
    onSave(message.id, editFields)
  }

  const handleDeleteClick = () => {
    onDelete(message.id)
  }

  const handleSendMessages = () => {
    confirmPopup.show({
      iconImage: null,
      title: (
        <div className="common-container">
          <span className="common-bold common-color-caution">
            메세지를 전송하시겠습니까?
          </span>
        </div>
      ),
      body: (
        <div className="common-container">
          <span className="common-bold common-color-caution">
            명에게 보냅니다
          </span>
        </div>
      ),
      onDone: async () => onSend(editFields),
      doneText: "네",
      cancelText: "아니오",
    })
  }

  return (
    <CustomMessageListContainer deletedAt={message.deletedAt}>
      <h3 onClick={() => setShowDetails(!showDetails)}>
        {editFields.title.substring(0, 10)}... <br />{" "}
        {formatDate(editFields.scheduledAt)}
      </h3>

      {showDetails && (
        <>
          <ButtonContainer>
            <Button
              onClick={handleSaveClick}
              size={"Large"}
              type={"Text"}
              variant={"Contained"}
              color={"Primary"}
            >
              예약저장
            </Button>
            <Button
              onClick={handleSendMessages}
              size={"Large"}
              type={"Text"}
              variant={"Contained"}
              color={"Secondary"}
            >
              즉시전송
            </Button>
            <Button
              onClick={handleDeleteClick}
              size={"Large"}
              type={"Text"}
              variant={"Contained"}
              color={"Negative"}
            >
              삭제
            </Button>
          </ButtonContainer>
          <EditableField>
            <strong>ID:</strong> {message.id}
          </EditableField>
          <EditableField>
            <strong>제목:</strong>
            <Input
              value={editFields.title}
              onChange={(e) => handleFieldChange("title", e.target.value)}
            />
          </EditableField>
          <EditableField>
            <strong>내용:</strong>
            <Textarea
              className="admin-text-area"
              minRows={3}
              maxRows={15}
              value={editFields.body}
              onChange={(e) => {
                handleFieldChange("body", e.target.value)
                setValue(e.target.value)
              }}
            />
          </EditableField>
          <EditableField>
            <strong>주소:</strong>
            <Input
              value={editFields.data.url}
              onChange={(e) => handleFieldChange("url", e.target.value)}
            />
          </EditableField>
          <EditableField>
            <strong>예약날짜:</strong>
            <DateInput
              placeholder="예약일시"
              value={editFields.scheduledAt}
              onChange={(e) => {
                handleFieldChange("scheduledAt", e.target.value)
              }}
            />
          </EditableField>
          <EditableField>
            <strong>Data:</strong>
            <Textarea
              className="admin-text-area"
              style={{ borderColor: error ? "red" : "initial" }}
              minRows={3}
              maxRows={15}
              value={editFields.data}
              onChange={(e) => {
                handleFieldChange("data", e.target.value)
                setValue(e.target.value)
              }}
            />
          </EditableField>
          <InformationContainer>
            <EditableField>
              <strong>전송시작시간:</strong> {editFields.startedAt}
            </EditableField>
            <EditableField>
              <strong>전송완료시간:</strong> {editFields.endedAt}
            </EditableField>
            <EditableField>
              <strong>완료된유저:</strong> {editFields.sentUserCount}
            </EditableField>
            <EditableField>
              <strong>완료된디바이스:</strong> {editFields.sentDeviceCount}
            </EditableField>
          </InformationContainer>
          <GetUserConditionedDistributionView
            ids={ids}
            setIds={setIds}
            setEditFields={setEditFields}
            editFields={editFields}
          />
        </>
      )}
    </CustomMessageListContainer>
  )
}
