import React, { Component } from 'react';
import {log, LogLevel} from '../../utils/LogUtil'
import "./PollSimplePage.css"
import "./Common.css"
import ReactHtmlParser from 'react-html-parser'
import { PollResponseItemInfo, PollItemInfo } from '../../models/Model.Poll';
import Slider from '../Slider';


// type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
type Props = {
    key?: string,
    item: PollItemInfo,
    response?: PollResponseItemInfo,
    index: number,
    onReponse?: (pollItemindex: number, response: PollResponseItemInfo) => void,
}

type State = {
    value: number;
}

class PollSimplePageItemNumberLevelSelection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemNumberLevelSelection:constructor",  props);
        let value = 1;
        
        if(this.props.response)
            value = Number(this.props.response.value);
        if(!value)
            value = 1;

        this.state={
            value,
        };
    }

    shouldComponentUpdate(nextProv, nextState){
        if(this.props.item.id != nextProv.item.id || this.state.value != nextState.value)
            return true;
        return false;
    }

    componentDidMount() { 
    }

    onValueChanged = (value) => {
        log(LogLevel.UI_ACTION, "PollSimplePageItemNumberLevelSelection:onValueChanged:1 ", value);
        if(this.props.onReponse)
            this.props.onReponse(this.props.index, {
                pollItemId: this.props.item.id,
                value: value.toString(), // userId
            })
        this.setState({value:value});
    }

    render() {
        log(LogLevel.UI_LIFECYCLE, "PollSimplePageItemNumberLevelSelection render: ", this.props.item, this.props.response);

        let descriptionElement;
        if(this.props.item.description)
            descriptionElement = (
                <div className="poll-simple-page-item-text poll-simple-page-item-margin">{ReactHtmlParser(this.props.item.description,{})}</div>
            );
        return (
            <div className="poll-simple-page-item-container">
                <div className="poll-simple-page-item-question">{this.props.item.question}</div>
                {descriptionElement}
                <div className="poll-simple-page-item-slider-container">
                    <Slider 
                        values={this.props.item.candidates}
                        onSelect={this.onValueChanged}
                        value={this.state.value}
                    />
                </div>
            </div>
        );

        return null;
    }
}


// const mapStateToProps = (state: RootState) => ({
//   me: state.user.me,
//   informationPopup : state.ui.popups[UIPopupType.INFORMATION_POPUP],
// });

// const mapDispatchToProps = {

// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PollSimplePageItemNumberLevelSelection));
export default PollSimplePageItemNumberLevelSelection;