

import React, { Component } from 'react';
import './../Admin.scss';
import './../SeminarManage.css';
import './../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonToolbar, IonButtons, IonToggle, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../models/Model.User';
import { getGlobal, GlobalKey, setGlobal } from '../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import ProfileImage from '../../components/ProfileImage';
import { SeminarLecture } from '../../models/Model.Seminar';
import { defaultNewLecture } from '../SeminarManage';
import { threadId } from 'worker_threads';
import { loadImageBase64 } from '../../utils/ImageUtil';
import * as API from './../../API.json';import ReactQuill from 'react-quill';
import Textarea from 'react-textarea-autosize';
import { getDateStringFromToday, getDateTimeString } from '../../utils/TimeUtil';
import DownloadUtil from '../../utils/DownloadUtil';
import { ComposedChart, LineChart, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';


const AdminAPI = {        
  "BOARDS_STATISTICS" : {"method":"POST", "path":"/admin/board/content/statistics/counts", "contentType":"application/json"},
};

type Props = {
};

type State = {
  statisticsFold: boolean,
  statistics: BoardContentCountStatistics,
  statisticsPeriodOption: number,
  statisticsDateStart:string;
  statisticsDateStartWarn : boolean,
  statisticsDateEnd:string;
  statisticsDateEndWarn : boolean,
  statisticsShowOption: number,
  statisticsShowGraph: boolean;
  statisticsShowGraphSmooth : boolean;
}

interface BoardContentCountStatisticsItem{
  startDate?: string,
  endDate?: string,
  date?: string,
  question?: number,
  answer?: number,
  post?: number,
  comment?: number,
  view?: number,
  questionAccumulated?: number,
  answerAccumulated?: number,
  postAccumulated?: number,
  commentAccumulated?: number,
}

interface BoardContentCountStatistics{
  startDate?:string,
  endDate?:string,
  count?: number,
  unit?: number
  items?: BoardContentCountStatisticsItem[],
}

enum SeminarStatisticsShowOption{
  VIEW = 0x0100,
  QUESTION = 0x01,
  ANSWER = 0x02,
  POST = 0x04,
  COMMENT = 0x08,
  ACMLT_QUESTION = 0x010,
  ACMLT_ANSWER = 0x020,
  ACMLT_POST = 0x040,
  ACMLT_COMMENT = 0x080,
  ETC_QUESTION = 0x0200,
  ETC_ANSWER = 0x0400,
  ACMLT_ETC_QUESTION = 0x0800,
  ACMLT_ETC_ANSWER = 0x1000,
  
}

class BoardContentCountStatisticsView extends Component<Props, State> {
  statisticsLoading = false;
  statisticsLoaded = false;

  state = {
    statisticsFold:false,
    statistics: null,
    statisticsPeriodOption: 1,
    statisticsDateStart: getDateStringFromToday({byWeek:true, weeks:-4}),
    statisticsDateEnd: getDateStringFromToday({days:-4}),
    statisticsDateStartWarn:false,
    statisticsDateEndWarn:false,
    statisticsShowOption: getGlobal(GlobalKey.ADMIN_STATISTICS_BOARD_SHOW_OPTION, true),
    statisticsShowGraph: true,
    statisticsShowGraphSmooth : true,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // this.fetchConsults();
  }
  
  onDateChange = (e, field) => {
    let value = e.target.value;
    let warn = false;
    try{
      let date = Date.parse(value);
      if(!date){
        log(LogLevel.UI_EXCEPTION, "Admin:BoardContentCountStatisticsView:onConsultsDateEndChange", value);
        warn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "Admin:BoardContentCountStatisticsView:onConsultsDateEndChange", value, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "Admin:BoardContentCountStatisticsView:onConsultsDateEndChange", value, err);
      warn = true;
    }

    let newState:any = {};
    newState[field] = value;
    newState[field+"Warn"] = warn;

    this.setState(newState);
  }

  setStatisticsPeriod = (startOption, endOption) => {
    let statisticsDateStart, statisticsDateEnd;
    statisticsDateStart= getDateStringFromToday(startOption);
    statisticsDateEnd= getDateStringFromToday(endOption);
    this.setState({
      statisticsDateStart,
      statisticsDateStartWarn : false,
      statisticsDateEnd,
      statisticsDateEndWarn : false,
    })
  }

  fetchStatistics = () => {
    if(this.state.statisticsDateStartWarn || this.state.statisticsDateEndWarn)
      return;
      
    log(LogLevel.UI_DATA_LOAD, "Admin:BoardContentCountStatisticsView:fetchData");

    if(this.statisticsLoading)
      return;
    this.statisticsLoading = true;
    let request:any = {};
    request.startDate = this.state.statisticsDateStart;
    request.endDate = this.state.statisticsDateEnd;
    request.unit = this.state.statisticsPeriodOption;

    fetchAPI(AdminAPI.BOARDS_STATISTICS, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        log(LogLevel.UI_DATA_LOAD, "Admin:BoardContentCountStatisticsView:fetchData result", result);
        this.setState({statistics: result.data});
      }
      else{
        log(LogLevel.UI_DATA_LOAD, "Admin:BoardContentCountStatisticsView:fetchData result", result);
        this.setState({statistics: null});
      }
      this.statisticsLoaded = true;
      this.statisticsLoading = false;
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "Admin:BoardContentCountStatisticsView:fetchData exception", e);
      this.setState({statistics: null});
      this.statisticsLoading = false;
    });
  }

    
  onDownloadStatistics = () => {
    if(!this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return;
    
    let csv = ",";
    csv += this.state.statistics.items.map((item) => item.date).join(",");
    csv += "\r\n";
    csv += this.statisticsShowfilters.map((item, index) => {
      let results = this.state.statistics;
      if(!(this.state.statisticsShowOption & item.option))
        return "";
      return (
        item.name + "," + this.state.statistics.items.map((item2) => item2[item.field].toString()).join(",") + "\r\n"
      );
    }).join("");

    DownloadUtil.downloadCsv(csv, `Boards-Statics-${this.state.statisticsDateStart.replace(/-/ig, '')}-${this.state.statisticsDateEnd.replace(/-/ig, '')}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`);
  }

  openStatistics = () => {
    if(!this.statisticsLoaded)
      this.fetchStatistics();
    this.setState({statisticsFold:false})
  }
  
  statisticsShowfilters = [
    { option:SeminarStatisticsShowOption.VIEW, name:"조회", field:"view", color:"#4b7610"},
    { option:SeminarStatisticsShowOption.QUESTION, name:"질문", field:"question", color:"#1b7610"},
    { option:SeminarStatisticsShowOption.ANSWER, name:"답변", field:"answer", color:"#e51042"},
    { option:SeminarStatisticsShowOption.ETC_QUESTION, name:"약사라이프질문", field:"etcQuestion", color:"#1b3010"},
    { option:SeminarStatisticsShowOption.ETC_ANSWER, name:"약사라이프답변", field:"etcAnswer", color:"#201042"},
    { option:SeminarStatisticsShowOption.POST, name:"자유게시", field:"post", color:"#fa8610"},
    { option:SeminarStatisticsShowOption.COMMENT, name:"코멘트", field:"comment", color:"#651d00"},
    { option:SeminarStatisticsShowOption.ACMLT_QUESTION, name:"누적질문", field:"questionAccumulated", color:"#1b7610"},
    { option:SeminarStatisticsShowOption.ACMLT_ANSWER, name:"누적답변", field:"answerAccumulated", color:"#e51042"},
    { option:SeminarStatisticsShowOption.ACMLT_ETC_QUESTION, name:"누적약사라이프질문", field:"etcQuestionAccumulated", color:"#1b3010"},
    { option:SeminarStatisticsShowOption.ACMLT_ETC_ANSWER, name:"누적약사라이프답변", field:"etcAnswerAccumulated", color:"#201042"},
    { option:SeminarStatisticsShowOption.ACMLT_POST, name:"누적자유게시", field:"postAccumulated", color:"#fa8610"},
    { option:SeminarStatisticsShowOption.ACMLT_COMMENT, name:"누적코멘트", field:"commentAccumulated", color:"#651d00"},
  ];

  render () {
      if(this.state.statisticsFold){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={this.openStatistics}>
            <div className="common-flex-row">
              <div>게시판 통계</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 
      );
    }

    log(LogLevel.UI_LIFECYCLE, "Admin:BoardContentCountStatisticsView:render", this.state);

    let statisticsPeriodOptions:any[][] = [[],
      [
        {name:"2주간", start:{days: -14}, end:{days:1}}, 
        {name:"4주간", start:{days: -28}, end:{days:1}}, 
        {name:"서비스개시일", start:{date:"2019-07-07"}, end:{days:1}}
      ],
      [
        {name:"4주간", start:{byWeek: true, weeks: -4}, end:{days:1}}, 
        {name:"8주간", start:{byWeek: true, weeks: -8}, end:{days:1}}, 
        {name:"서비스개시일", start:{byWeek: true, date:"2019-07-07"}, end:{days:1}}],
      [
        {name:"4개월간", start:{byMonth: true, months: -4}, end:{days:1}}, 
        {name:"8개월간", start:{byMonth: true, months: -8}, end:{days:1}}, 
        {name:"12개월간", start:{byMonth: true, months: -12}, end:{days:1}}, 
        {name:"서비스개시일", start:{byMonth: true, date:"2019-07-07"}, end:{days:1}}],
    ];

    let smooth;
    if(this.state.statisticsShowGraph)
      smooth = (
        <div className="common-flex-row">
          <IonCheckbox checked={this.state.statisticsShowGraphSmooth} onIonChange={(e) => this.setState({statisticsShowGraphSmooth:!this.state.statisticsShowGraphSmooth})}/>
          <div className="admin-margin-right">Smooth Line</div>
        </div>
      );

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({statisticsFold:true})}>
          <div className="common-flex-row">
            <div>게시판 통계</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Search Option</div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <input className={(this.state.statisticsDateStartWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.statisticsDateStart} onChange={(e) => this.onDateChange(e, "statisticsDateStart")}/>
                <div>~</div>
                <input className={(this.state.statisticsDateEndWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.statisticsDateEnd} onChange={(e) => this.onDateChange(e, "statisticsDateEnd")}/>
              </div>
              <div className="common-container-row-wrap admin-margin-bottom"> 
                {statisticsPeriodOptions[this.state.statisticsPeriodOption].map((item, index) => 
                  <div key={index.toString()} className="admin-text-button" onClick={() => this.setStatisticsPeriod(item.start, item.end)}>{item.name}</div>
                )}               
              </div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <div>단위기간</div>
                <div className={"admin-toggle" + ((this.state.statisticsPeriodOption==1)?" admin-toggle-selected":"")} onClick={() => this.setState({statisticsPeriodOption:1})}>Daily</div>
                <div className={"admin-toggle" + ((this.state.statisticsPeriodOption==2)?" admin-toggle-selected":"")} onClick={() => this.setState({statisticsPeriodOption:2})}>Weekly</div>
                <div className={"admin-toggle" + ((this.state.statisticsPeriodOption==3)?" admin-toggle-selected":"")} onClick={() => this.setState({statisticsPeriodOption:3})}>Monthly</div>
              </div>
            </div>
          </div>
          <div>
            <div className="common-container-column">
              <IonButton onClick={this.fetchStatistics}>Load</IonButton>
              <IonButton color="primary" disabled={!(this.state.statistics)} onClick={this.onDownloadStatistics}>Download</IonButton>
            </div>
          </div>
        </div>
        <div className="common-container-row admin-show-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Display Option</div>
              <div className="common-container-row">
                <div className="common-container-column">
                  <div className="search-options-container">
                    <div className={(this.state.statisticsShowGraph)?"search-option-text-inactive":"search-option-text-active"} onClick={(e) => {this.setState({statisticsShowGraph:false})}}>Table</div>
                    <IonToggle class="search-options-toggle" disabled={!this.state.statistics} mode="ios" checked={this.state.statisticsShowGraph} onClick={(e) => this.setState({statisticsShowGraph:(!this.state.statisticsShowGraph)})}/>
                    <div className={(this.state.statisticsShowGraph)?"search-option-text-active":"search-option-text-inactive"} onClick={(e) => {this.setState({statisticsShowGraph:true})}}>Graph</div>
                  </div>
                  {smooth}
                </div>
                <div className="common-flex-row-wrap common-flex-grow">
                  {this.statisticsShowfilters.map((item, index) => 
                    <div className="common-flex-row" key={index.toString()}>
                      <IonCheckbox disabled={!this.state.statistics} checked={!!(this.state.statisticsShowOption & item.option)} onClick={(e) => {this.setState({statisticsShowOption: (this.state.statisticsShowOption ^ item.option)});setGlobal(GlobalKey.ADMIN_STATISTICS_BOARD_SHOW_OPTION, this.state.statisticsShowOption ^ item.option, true)}}/>
                      <div className="admin-margin-right" style={{color:item.color, fontWeight:"bold"}}>{item.name}</div>
                    </div>
                  )}
                </div>
              </div>              
            </div>
          </div>
        </div>
        {this.renderStatisticsTable()}
        {this.renderStatisticsGraph()}
      </div>
    );
  }
    
  renderStatisticsTable = () => {
    if(this.state.statisticsShowGraph || !this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return null;
    
    let label = (
      <tr>
        <td/>
        {this.state.statistics.items.map((item, index) => 
            <td className="admin-table-label-x" key={index.toString()}>{item.date}</td>
        )}
      </tr>
    );

    let data = this.statisticsShowfilters.map((item, index) => {
      let results = this.state.statistics;
      if(!(this.state.statisticsShowOption & item.option))
        return null;
      return (
        <tr key={index.toString()}>
          <td className="admin-table-label-y">{item.name}</td>
          {this.state.statistics.items.map((item2, index2) => {
            let value = item2[item.field];
            if(!value && value != 0){
              log(LogLevel.UI_EXCEPTION, "renderTable", item, item2)
              value = 0;
            }
            return (
              <td className="admin-table-value" key={index2.toString()}>{value.toString()}</td>
            );

          })}
        </tr>
      );
    });

    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <table className="admin-table">
            <tbody>
              {label}
              {data}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderStatisticsGraph = () => {
    if(!this.state.statisticsShowGraph || !this.state.statistics || !this.state.statistics.items || !this.state.statistics.items.length)
      return null;

    let width = 150 * this.state.statistics.items.length;
    if(width > 800)
      width = 800;
    return(
      <div className="common-container">
        <div className="admin-container-scroll-x">
          <ComposedChart width={width} height={500} data={this.state.statistics.items}>
            <defs>
              {this.statisticsShowfilters.map((item, index) => {
                  let results = this.state.statistics;
                  if((this.state.statisticsShowOption & item.option) && item.field.includes("Accumulated"))
                    return(
                      <linearGradient key={index.toString()} id={item.field} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={item.color} stopOpacity={0.3}/>
                        <stop offset="95%" stopColor={item.color} stopOpacity={0}/>
                      </linearGradient>
                    );
                  return null;
              })}
            </defs>

            {this.statisticsShowfilters.map((item, index) => {
                let results = this.state.statistics;
                if(!(this.state.statisticsShowOption & item.option))
                  return null;
                if(item.field.includes("Accumulated"))
                  return(
                    <Area key={index.toString()} yAxisId="right" type={this.state.statisticsShowGraphSmooth?"monotone":"linear"} dataKey={item.field} fill={`url(#${item.field})`} stroke={item.color} />
                  );
                
                return (
                  <Line key={index.toString()} yAxisId="left" type={this.state.statisticsShowGraphSmooth?"monotone":"linear"} dataKey={item.field} stroke={item.color} />
                );
          
            })}
            <CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" scale="auto"/>
            <YAxis yAxisId="right" orientation="right" scale="auto"/> 
            <Tooltip />
            <Legend />
          </ComposedChart>
        </div>
      </div>
    );
  }

}

export default BoardContentCountStatisticsView;