import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LogLevel, log } from "../../../utils/LogUtil";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { UserInfo } from "../../../models/Model.User";
import { Flex, Header, Static } from "../../atoms/Layout";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Button from "../../atoms/Button";
import Footer from "../../cell/Footer";
import Body from "../../cell/Body";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import CheckBox from "../../atoms/CheckBox/CheckBox";

export interface PremiumUserSubscribeCompleteProps {
  onGoBack: () => void;
  onViewBenefit: () => void;
}

const PremiumUserSubscribeComplete: React.FC<PremiumUserSubscribeCompleteProps> = ({
  onGoBack,
  onViewBenefit,
}) => {
  useEffect(() => {
    log(LogLevel.UI_ACTION, "PremiumUserSubscribeComplete:useEffect mount");
  }, []);

  return (
    <div className="common-content common-position-relative">
      <Header>
        <TopAppBar
          titleType="OFF"
          leftButtons={[
            <Button
              color="Tertiary"
              icon="X"
              onClick={onGoBack}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          rightButtons={[]}
        />
      </Header>
      <Body footerHeight={88}>
        <Flex
          alignItems="center"
          direction="column"
          customStyle={{ padding: "0px 20px" }}
        >
          <img
            src="/images/Clapping hands.png"
            style={{ width: "100px", margin: "60px 0px" }}
          />

          <Static
            customStyle={{
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            <Text textType={"H1"} color={COLOR_SYSTEM.get("Gray")[900]}>
              후원해주셔서 감사합니다.
            </Text>
          </Static>
          <Static
            customStyle={{
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            <Text textType={"Body1"} color={COLOR_SYSTEM.get("Gray")[700]}>
              계속 발전하는 약문약답을 기대해주세요! <br />
              약사님께 도움이 되는 기능으로 보답하겠습니다.
            </Text>
          </Static>
          <Static
            customStyle={{
              textAlign: "center",
              textDecoration: "underline",
            }}
          >
            <Text
              textType={"Body1Medium"}
              color={COLOR_SYSTEM.get("Gray")[900]}
              onClick={onViewBenefit}
            >
              후원 회원 혜택 보기
            </Text>
          </Static>
        </Flex>
      </Body>
      <Footer>
        <Button
          style={{ flexGrow: 1 }}
          size={"Medium"}
          type={"Text"}
          variant={"Contained"}
          color={"Primary"}
          onClick={onGoBack}
        >
          닫기
        </Button>
      </Footer>
    </div>
  );
};

export default PremiumUserSubscribeComplete;
