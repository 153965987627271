
import React, { Component } from 'react';
import './ComponentCompose.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem } from '@ionic/react';
import {fetchAPI} from '../utils/APIUtil'
import * as API from '../API.json';;
import Skeleton from 'react-loading-skeleton';
import smileIcon from './../assets/icon/smile.svg'
import { KeywordType, Medicine, Ingredient } from '../models/Model.Medicine';
import {compose} from 'redux'
import { connect, connectAdvanced } from 'react-redux';
import { RootState, actions} from '../store';
import {log, LogLevel} from '../utils/LogUtil'
import Textarea from 'react-textarea-autosize';
import { UIPopupType } from '../store/ui/types';
import ImageViewNSelect from '../admin/component/ImageViewNSelect';
import { loadImageBase64 } from '../utils/ImageUtil';
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onDone: (data) => void;
};

type State = {
  url: string;
  lecturerImageBase64: any;
  etcStr: string;
}

class ComponentComposeImageBanner extends Component<Props, State> {

  static typeName = "JSON";
  value={component:"banner", src:"", path:"/"};

  constructor(props:Props){
    super(props);
    this.state={
      url:"",
      lecturerImageBase64: "",
      etcStr:"",
    };
  }

  doneProcessing = false;
  onSubmit = async(e) => {
    if(this.doneProcessing)
      return;
    this.doneProcessing = true;
    this.props.progressPopup.show();

    let src="";

    let advertiserInfo: any = {};

    if(this.state.etcStr){

      try{
        advertiserInfo = JSON.parse(this.state.etcStr);
      }catch (e) {
        this.props.progressPopup.hide();
        this.doneProcessing = false;
        this.props.toast.show("광고주 정보가 정확하지 않습니다.");
        return;
      }
    }
    
    this.props.progressPopup.setLabel("이미지 업로드 중...");
    if(this.state.lecturerImageBase64){
      let data:any = {path:"board"}
      data.base64 = this.state.lecturerImageBase64;
      try {
        let result = await fetchAPI(API.UPLOAD_BASE64, '', null, data, getGlobal(GlobalKey.TOKEN));
        if(result && !result.error){
          src = result.file;
          log(LogLevel.UI_ACTION, 'ComponentComposeImageBanner:onSubmit upload : ', result.file);
          let data = {component:"banner", src, path:this.state.url, ...advertiserInfo}
          this.props.onDone(data);
          e.stopPropagation();
          this.props.progressPopup.hide();
          this.doneProcessing = false;
            // log(LogLevel.UI_ACTION, "onShowImagePicker success", url);
        }else{
            log(LogLevel.UI_ACTION, "ComponentComposeImageBanner:onSubmit failed", result);
            this.props.progressPopup.hide();
            this.doneProcessing = false;
            this.props.toast.show("이미지 업로드가 실패했습니다.");
            return;
        }

      } catch (error) {
          log(LogLevel.UI_ACTION, "ComponentComposeImageBanner:onSubmit : failed", error);
          this.props.progressPopup.hide();
          this.doneProcessing = false;
          this.props.toast.show("이미지 업로드가 실패했습니다.");
          return;
      }
    }
  }  

  onClick = (e) => {
    e.stopPropagation();
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ComponentComposeImageBanner  : render");

    return (
          <div className="component-composer-sub-container" onClick={(e) => {e.stopPropagation()}}>
            <div>이미지 :</div>
            <ImageViewNSelect base64={this.state.lecturerImageBase64} onChange={(e) => this.onImageSelected("lecturerImageBase64", e)}/>
            <div className="common-container-row common-flex-align-center" onClick={(e) => {e.stopPropagation()}}>
              <div className="admin-title">url :</div>
              <IonInput class="user-search-input" placeholder="url(ex. /main/jobpost, www.daum.net)" value={this.state.url} onIonChange={(e) => this.setState({url:e.detail.value})}  onClick={(e) => {e.stopPropagation()}}/>
            </div>
            <div className="common-container-row common-flex-align-center">
              <div className="user-manage-title">광고정보 (Oscar 문의)</div>
              <div className="admin-text-button" onClick={() => this.setState({etcStr:JSON.stringify(
                {
                  advertizerType:"", 
                  advertizerCode:"", 
                  advertizerProductCode:"", 
                  advertizerContentType:"", 
                  advertizerContentId:1, 
                  advertizerContentTitle:"", 
                  advertizerGsid: "", 
                  advertizerGstid: "", // google sheet tab name
                  advertizerShid: "", // slack hook url
                  advertizerStid: "", // stibee hook url
                  advertizerStfields: "", // stibee hook data
                }, null, "\t")})}>Default</div>
            </div>
            <Textarea className="admin-text-area" minRows={3} maxRows={15} value={this.state.etcStr} onChange={(e) => {this.setState({etcStr:e.target.value})}} onClick={(e)=>{e.stopPropagation();}}/>
            <IonButton expand="full" onClick={this.onSubmit} >완료하기</IonButton>
          </div>
    );
  }

  onImageSelected = async (name, e, max=1920) => {
    // console.dir(e);
    log(LogLevel.UI_ACTION, "ComponentComposeImageBanner:onImageSelected",e.target.files)
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");

    if (e.target.files && e.target.files.length > 0) {
      let base64 = await loadImageBase64(e.target.files[0], max);
      if(base64){
        this.setState({lecturerImageBase64: base64});
      }
    }
    this.props.progressPopup.hide();
  }

}

const mapStateToProps = (state: RootState) => ({
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentComposeImageBanner);