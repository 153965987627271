import { action, createAction, createAsyncAction } from 'typesafe-actions';
import {SearchState, SearchActionTypes} from './types'
import { BoardContent } from '../../models/Model.Board';
import { SearchKeywordRelatedContentInfo } from '../../models/Model.Search';


export const setSearchText = createAction(SearchActionTypes.SEARCH_SET, resolve =>
  (searchText: string) => resolve(searchText)
);

export const resetSearchText = createAction(SearchActionTypes.SEARCH_RESET, resolve =>
  () => resolve()
);

export const fetchSearch = createAsyncAction(
  SearchActionTypes.SEARCH_REQUEST,
  SearchActionTypes.SEARCH_SUCCESS,
  SearchActionTypes.SEARCH_ERROR,
)<void, any, Error>();



export const loadSearchKeywords = createAction(SearchActionTypes.SEARCH_KEYWORD_LOAD, resolve =>
  () => resolve()
);

export const fetchSearchKeywords = createAsyncAction(
  SearchActionTypes.SEARCH_KEYWORD_REQUEST,
  SearchActionTypes.SEARCH_KEYWORD_SUCCESS,
  SearchActionTypes.SEARCH_KEYWORD_ERROR,
)<void, any, Error>();

export const fetchSearchKeywordRelatives = createAsyncAction(
  SearchActionTypes.SEARCH_KEYWORD_RELATIVES_REQUEST,
  SearchActionTypes.SEARCH_KEYWORD_RELATIVES_SUCCESS,
  SearchActionTypes.SEARCH_KEYWORD_RELATIVES_ERROR,
)<void, any, Error>();

export const sortSearch = createAction(
  SearchActionTypes.SEARCH_SORT,
  resolve => (order : string) => resolve(order))
// import {getGlobal, GlobalKey, setGlobal} from './../utils/GlobalUtil'

// export const fetchSearch = (searchText :string) => (dispatch, getState) => {
//   log(LogLevel.REDUX_SEARCH, "fetchSearch start");
//   dispatch(searchRequest(searchText))
//   fetchAPI(API.BOARD_SEARCH, '', {query: searchText, start:0, count: 100, board: 1}, null, getGlobal(GlobalKey.TOKEN))
//     .then(result => {
//       if(result && result.error){
//         log(LogLevel.REDUX_SEARCH, "fetchSearch QnA result",  result.data);
//         dispatch(searchedQnA(result.data));
//       }
//     });
//   fetchAPI(API.BOARD_SEARCH, '', {query: searchText, start:0, count: 100, board: 2}, null, getGlobal(GlobalKey.TOKEN))
//     .then(result => {
//       if(result && result.error){
//         log(LogLevel.REDUX_SEARCH, "fetchSearch Post result",  result.data);
//         dispatch(searchedPost(result.data));
//       }
//     });
// }

// export const resetSearch = () => (dispatch, getState) => {
//   return dispatch(searchReset());
// }