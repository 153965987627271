import { createAction, createAsyncAction } from "typesafe-actions";
import { NotificationActionTypes } from "./types";
import { Notification } from "../../models/Model.Notification";

export const checkItem = createAction(
  NotificationActionTypes.NOTIFICATION_CHECK_ITEM,
  (resolve) => (notiId: number) => resolve(notiId)
);
export const check = createAction(
  NotificationActionTypes.NOTIFICATION_CHECKED_TIME,
  (resolve) => () => resolve()
);

export const initCheck = createAction(
  NotificationActionTypes.NOTIFICATION_CHECKED_TIME_INIT,
  (resolve) => () => resolve()
);

export const load = createAction(
  NotificationActionTypes.NOTIFICATION_LOAD,
  (resolve) => () => resolve()
);

export const refresh = createAction(
  NotificationActionTypes.NOTIFICATION_REFRESH,
  (resolve) => () => resolve()
);

export const fetchNotificationLoad = createAsyncAction(
  NotificationActionTypes.NOTIFICATION_REQUEST_LOAD,
  NotificationActionTypes.NOTIFICATION_SUCCESS_LOAD,
  NotificationActionTypes.NOTIFICATION_ERROR_LOAD
)<void, Notification[], Error>();

export const fetchNotificationCheckItem = createAsyncAction(
  NotificationActionTypes.NOTIFICATION_REQUEST_CHECK_ITEM,
  NotificationActionTypes.NOTIFICATION_SUCCESS_CHECK_ITEM,
  NotificationActionTypes.NOTIFICATION_ERROR_CHECK_ITEM
)<void, number, Error>();

export const chatUnreadCountLoad = createAction(
  NotificationActionTypes.NOTIFICATION_CHAT_UNREAD_COUNT_LOAD,
  (resolve) => () => resolve()
);

export const chatUnreadCountSet = createAction(
  NotificationActionTypes.NOTIFICATION_CHAT_UNREAD_COUNT_SET,
  (resolve) => (count: number) => resolve(count)
);
