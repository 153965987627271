import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  CompanyType,
  CompanyTypeName,
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import "./../../../Common.css";
import Scroll from "../../cell/Scroll";
import Icon from "../../atoms/Icon";
import PartnersCard from "../../organisms/AllInOne/PartnersCard";

import Button from "../../atoms/Button";
import { BoardContent } from "../../../models/Model.Board";
import PostListItem from "../../organisms/AllInOne/PostListItem";
import { Fixed, Flex, Static } from "../../atoms/Layout";
import FloatingButton from "../../atoms/FloatingButton";
import TabBar from "../../molecules/TabBar";
import CardView from "../../../component/molecule/CardView";
import { BizUserInfo } from "../../../models/Mode.Biz";
import styled from "styled-components";
import Separator from "../../atoms/Separator";
import { BoardListState, BoardSortType } from "../../../store/board/types";
import { getGlobal, setGlobal } from "../../../utils/GlobalUtil";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { useDispatch } from "react-redux";
import useBottomSheet from "../../../hooks/useBottomSheet";
import { getBizBoard, sortBizBoard } from "../../../store/board/actions";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";

const sortName = [
  "최신순",
  "최신순",
  "예전글 순",
  "좋아요 순",
  "스크랩 순",
  "나도궁금해요 순",
  "조회수 순",
  "답변수 순",
  "댓글수 순",
];

export interface PartnersCategoryProps {
  banners: any[];
  partnerList: string[];
  partners: any;
  category: BoardListState;
  categoryList: CompanyType[];
  contents: any;
  onClickPartner: (patner: BizUserInfo) => void;
  onClickPost: (post: BoardContent) => void;
  onClickBanner?: (item: any, index: number, position: any) => void;
  onShowBanner: (item: any, index: number) => void;
}

const PartnerHomeWrapper = styled.div`
  padding: 12px 0px 40px 0px;
  background: #fff;

  .partners-banner-cardview {
    --cardview-card-border-radius: 12px;
    --cardview-card-padding: 0px 0px;
  }

  .partners-card-container {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    padding: 24px 0px 24px 24px;
    height: 300px;
    transform: translateZ(0);

    .partners-card-title {
      margin-bottom: 8px;
    }

    .partners-card-description-img {
      position: absolute;
      right: -15px;
      bottom: 0px;
      width: 60%;
    }

    .partners-card-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      position: absolute;
      bottom: 24px;
      left: 24px;
    }
  }

  .partners-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 40px;

    .partners-card-title {
      margin-bottom: 10px;
    }
  }

  .partners-categories-container {
    margin-top: 80px;

    .partners-categories {
      margin-top: 20px;
      padding: 20px 24px;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px 12px;
      transform: translateZ(0);

      .partners-category-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        white-space: nowrap;
        gap: 4px;

        .partners-card-description-img {
          width: 40px;
        }
      }
    }

    .partners-categories-description {
      margin: 20px 0px;
    }
  }

  .partners-post-container {
    background-color: #fff;
  }

  .partners-suggest-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    gap: 12px;
  }
`;

const PartnersCategory: React.FC<
  PartnersCategoryProps & RouteComponentProps
> = ({
  contents,
  banners,
  partners,
  partnerList,
  category,
  categoryList,
  // contents,
  // selectedCategoryIndex,
  // onClickIntroduction,
  onClickPartner,
  onClickPost,
  onClickBanner,
  onShowBanner,
}) => {
  try {
    const [showContent, setShowContent] = useState<number>(
      getGlobal(`intPartnersCategoryShowContent-${category.bizCategoryId}`)
    ); // 0 : unset, 1: content, 2: partners

    useEffect(() => {
      if (category.contents && category.contents.length && showContent == 0) {
        setShowContent(1);
      }
    }, [category]);

    const dispatch = useDispatch();

    // const boardAttribute: string = useMemo(() => boards[boardList[tab]].attribute, [boards, boardList, tab]);
    const bottomSheet = useBottomSheet();

    const checkSelectedSort = useCallback(
      (sort: BoardSortType) => {
        if (!category.sort && sort === BoardSortType.TIME_DESC) return true;

        return category.sort === sort;
      },
      [category]
    );

    const handleClickSortItem = (sort: BoardSortType) => () => {
      dispatch(sortBizBoard(category, sort));
      dispatch(getBizBoard(category, 15));
      bottomSheet.close();

      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "BOARD_SORT", "게시판정렬", {
        Sort: BoardSortType[sort],
        게시판이름: category.name,
      });
    };

    const handleClickSort = () => {
      bottomSheet.show({
        title: {
          value: "정렬 기준",
          align: "left",
        },
        body: (
          <BottomSheetList>
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      checkSelectedSort(BoardSortType.TIME_DESC) ? 700 : 400
                    ]
                  }
                >
                  최신순
                </Text>
              }
              right={
                checkSelectedSort(BoardSortType.TIME_DESC) ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem(BoardSortType.TIME_DESC)}
            />
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      checkSelectedSort(BoardSortType.LIKE) ? 700 : 400
                    ]
                  }
                >
                  좋아요 순
                </Text>
              }
              right={
                checkSelectedSort(BoardSortType.LIKE) ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem(BoardSortType.LIKE)}
            />
            <BottomSheetListItem
              left={
                <Text
                  textType="InputLarge"
                  color={
                    COLOR_SYSTEM.get("Gray")[
                      checkSelectedSort(BoardSortType.VIEW) ? 700 : 400
                    ]
                  }
                >
                  조회수 순
                </Text>
              }
              right={
                checkSelectedSort(BoardSortType.VIEW) ? (
                  <Icon
                    name="Check"
                    width={24}
                    height={24}
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  />
                ) : null
              }
              onClick={handleClickSortItem(BoardSortType.VIEW)}
            />
          </BottomSheetList>
        ),
      });
    };

    const changeShowContent = (show: number) => {
      setShowContent(show);
      setGlobal(
        `intPartnersCategoryShowContent-${category.bizCategoryId}`,
        show
      );
    };

    let filteredPartnerList = [];
    if (partnerList && category)
      filteredPartnerList = partnerList.filter((partnerId, index) => {
        if (!partners[partnerId]) return false;
        return partners[partnerId].companyType == category.bizCategoryId;
      });

    let filteredBanners = [];
    if (banners && category)
      filteredBanners = banners.filter((item, index) => {
        return (
          category.bizCategoryId == CompanyType.ALL ||
          item.companyType == category.bizCategoryId
        );
      });
    log(
      LogLevel.UI_LIFECYCLE,
      "PartnersCategoryTemplate: ",
      banners,
      partners,
      partnerList,
      category,
      filteredPartnerList,
      filteredBanners
    );

    return (
      <PartnerHomeWrapper>
        <Flex direction="column" customStyle={{ padding: "24px" }}>
          {filteredBanners && filteredBanners.length > 0 && (
            <CardView
              className="partners-banner-cardview"
              randomStart={true}
              style="normal"
              infinite={true}
              items={filteredBanners}
              indexType="dot"
              imageUrlKey="imageUrl"
              imageSrcKey="imageSrc"
              indexPosition="left"
              autoScrollInterval={10000}
              onClick={onClickBanner}
              onShow={onShowBanner}
            />
          )}
          <Flex direction="column">
            <Flex gap="8px" direction="row">
              {category.contents && category.contents.length > 0 && (
                <ToggleButton
                  color={"Black"}
                  size={"Medium"}
                  variant={"OutlinedTinted"}
                  active={showContent == 1}
                  onClick={() => changeShowContent(1)}
                  borderRadius="20px"
                >
                  콘텐츠
                </ToggleButton>
              )}
              <ToggleButton
                color={"Black"}
                size={"Medium"}
                variant={"OutlinedTinted"}
                active={showContent != 1}
                onClick={() => changeShowContent(2)}
                borderRadius="20px"
              >
                파트너
              </ToggleButton>
            </Flex>
            <Flex customStyle={{ justifyContent: "right" }}>
              <Button
                color="Secondary"
                size="Small"
                variant="Ghost"
                type="IconWithText"
                icon="Swap Vertical"
                left
                onClick={handleClickSort}
              >
                {category.sort ? sortName[category["sort"]] : "최신순"}
              </Button>
            </Flex>
          </Flex>

          {showContent != 1 &&
            filteredPartnerList &&
            filteredPartnerList.length > 0 && (
              <Flex gap="40px" customStyle={{ flexWrap: "wrap" }}>
                {filteredPartnerList.map((partnerId: string, index: number) => {
                  if (!partnerId || !partners || !partners[partnerId])
                    return null;
                  return (
                    <PartnersCard
                      key={index.toString()}
                      partner={partners[partnerId]}
                      onClick={() => {
                        if (onClickPartner) onClickPartner(partners[partnerId]);
                      }}
                    />
                  );
                })}
              </Flex>
            )}

          {showContent == 1 &&
            category &&
            category.contents &&
            category.contents.length > 0 && (
              <div className="partners-post-container">
                {category.contents.map((post, index) => (
                  <PostListItem
                    key={index.toString()}
                    showProfile={true}
                    content={contents[post.id]}
                    partner={partners[contents[post.id].usersBizId]}
                    onClick={() => onClickPost(contents[post.id])}
                  />
                ))}
              </div>
            )}
        </Flex>
      </PartnerHomeWrapper>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PartnersCategoryTemplate",
      errorMessage: error,
    });
  }
};

export default withRouter(PartnersCategory);
