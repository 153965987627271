import React from "react";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import styled from "styled-components";
import HotKeywordList from "./HotKeywordList";

const NoSearchedPostContainer = styled.div`
  height: calc(100% - 108px);
  padding: 40px 20px 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  overflow-y: auto;
`;

const ManualUl = styled.ul`
  padding-left: 20px;
  margin: 20px 0 60px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  & > li {
    list-style: disc;
  }
`;

const NoSearchedPost = () => {
  return (
    <NoSearchedPostContainer>
      <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
        검색 결과가 없어요
      </Text>
      <ManualUl>
        <li>오타가 있는지 확인해주세요.</li>
        <li>지금 약문약답의 약사님들이 많이 찾는 검색어를 추천해드립니다</li>
      </ManualUl>
      <HotKeywordList />
    </NoSearchedPostContainer>
  );
};

export default NoSearchedPost;
