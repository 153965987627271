import React from "react";

interface Coords {
  x: number;
  y: number;
}
type UseRippleProps = [Coords, boolean, number, (e: React.MouseEvent<HTMLButtonElement>) => void];

const useRipple = (initialCoords = { x: -1, y: -1 }): UseRippleProps => {
  const [coords, setCoords] = React.useState(initialCoords);
  const [isRippling, setIsRippling] = React.useState(false);
  const [diameter, setDiameter] = React.useState(0);

  React.useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1 && diameter > 0) {
      setIsRippling(true);
      setTimeout(() => {
        setIsRippling(false);
      }, 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  const onChangeCoords = (x: number, y: number, radius: number) => {
    setCoords({ ...coords, x: x - radius, y: y - radius });
  };

  const onSetDiameter = (d: number) => {
    setDiameter(d);
  };

  const onRippleEffect = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { clientX, clientY } = e;
    const { clientWidth, clientHeight } = e.currentTarget;
    const { left, top } = e.currentTarget.getBoundingClientRect();
    const size = Math.max(clientWidth, clientHeight);

    onChangeCoords(clientX - left, clientY - top, size / 2);
    onSetDiameter(size);
  };

  return [coords, isRippling, diameter, onRippleEffect];
};

export default useRipple;
