
import * as uis from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { UIState } from "./types";
import {log, LogLevel} from '../../utils/LogUtil'

const defaultState: UIState = {
  popups: {},
  services: {},
  urlActions: {},
};

export default (state = defaultState, action: ActionType<typeof uis>): UIState => {
  let urlActions;
  switch (action.type) {
    case getType(uis.setPopup):
      log(LogLevel.REDUX_BOARD, "Reducer: uis.setWaitingPopup", action.meta);
      let popups = {...state.popups}
      popups[action.meta] = action.payload
      return ({
        ...state, popups,
      });
    case getType(uis.setService):
      log(LogLevel.REDUX_BOARD, "Reducer: uis.setWaitingPopup", action.meta);
      let services = {...state.services}
      services[action.meta] = action.payload
      return ({
        ...state, services,
      });
    case getType(uis.registerUrlAction):
      log(LogLevel.REDUX_BOARD, "Reducer: uis.registerUrlAction", action);
      urlActions = {...state.urlActions}
      urlActions[action.payload] = action.meta;
      return ({
        ...state, urlActions,
      });
    case getType(uis.unregisterUrlAction):
      log(LogLevel.REDUX_BOARD, "Reducer: uis.registerUrlAction", action);
      urlActions = {...state.urlActions}
      urlActions[action.payload] = null;
      return ({
        ...state, urlActions,
      });
  }
  return state;
}
