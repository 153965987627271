import React from "react";
import useRipple from "../../../hooks/useRipple";
import { COLOR_SYSTEM } from "../../design/design-system";
import Icon from "../Icon";
import {
  IconButtonWrapper,
  IconWithTextButtonWrapper,
  TextButtonWrapper,
} from "./style";

export type ButtonSize = "Large" | "Medium" | "Small" | "XSmall";
export type ButtonType = "Icon" | "Text" | "IconWithText";
export type ButtonVariant = "Contained" | "Outlined" | "Tinted" | "Ghost";
export type ButtonUsage =
  | "Primary"
  | "Secondary"
  | "Tertiary"
  | "Quaternary"
  | "Negative";

export interface ButtonProps {
  /** 버튼 크기 */
  size: ButtonSize;
  /** 버튼 타입 */
  type: ButtonType;
  /** 버튼 종류 */
  variant: ButtonVariant;
  /** 버튼 색상 */
  color: ButtonUsage;
  /** 버튼 안에 들어가는 content */
  children?: React.ReactNode | string;
  /** 버튼 활성 여부 */
  disabled?: boolean;
  /** 버튼 비활성일때 색상 변경 여부 */
  disabledColor?: boolean;
  /** 아이콘 위치 여부(택스트 앞) */
  left?: boolean;
  /** 아이콘 위치 여부(택스트 뒤) */
  right?: boolean;
  /** 아이콘 */
  icon?: string;
  /** 아이콘 색상 */
  iconColor?: string;
  /** 커스텀 class */
  className?: string;
  /** 커스텀 inline CSS */
  style?: React.CSSProperties;
  /** 클릭 이벤트 핸들러 */
  onClick?: (params?: any) => any;
}

const Button = ({
  color,
  size,
  variant,
  children,
  disabled = false,
  disabledColor = false,
  left,
  right,
  icon,
  iconColor,
  type,
  className,
  style,
  onClick,
}: ButtonProps) => {
  const [coords, isRippling, diameter, onRippleEffect] = useRipple({
    x: -1,
    y: -1,
  });

  const setBySize = () => {
    switch (size) {
      case "Large":
        return 24;
      case "Medium":
        return 20;
      case "Small":
        return 16;
      case "XSmall":
        return 8;
      default:
        return 24;
    }
  };

  // const setIconColor = () => (variant === "Contained" ? COLOR_SYSTEM.get("Gray")[0] : YMYD_COLOR.get(color));
  const setIconColor = () => {
    if (variant === "Contained") {
      return COLOR_SYSTEM.get("Gray")[0];
    } else {
      switch (color) {
        case "Primary":
          return COLOR_SYSTEM.get("Skyblue")[400];
        case "Secondary":
          return COLOR_SYSTEM.get("Gray")[900];
        case "Tertiary":
          return COLOR_SYSTEM.get("Gray")[400];
        case "Quaternary":
          return COLOR_SYSTEM.get("Gray")[200];
        case "Negative":
          return COLOR_SYSTEM.get("Red")[300];
        default:
          return;
      }
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onRippleEffect(e);
    if (onClick) onClick(e);
  };

  const renderByType = () => {
    switch (type) {
      case "Icon":
        return (
          <IconButtonWrapper
            type="button"
            color={color}
            size={size}
            variant={variant}
            disabled={disabled}
            disabledColor={disabledColor}
            className={className}
            style={style}
            onClick={handleClick}
          >
            {icon && (
              <Icon
                width={setBySize()}
                height={setBySize()}
                name={icon}
                fill={setIconColor()}
              />
            )}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </IconButtonWrapper>
        );
      case "Text":
        return (
          <TextButtonWrapper
            type="button"
            color={color}
            size={size}
            variant={variant}
            disabled={disabled}
            disabledColor={disabledColor}
            className={className}
            style={style}
            onClick={handleClick}
          >
            {children}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </TextButtonWrapper>
        );
      case "IconWithText":
        return (
          <IconWithTextButtonWrapper
            type="button"
            color={color}
            size={size}
            variant={variant}
            disabled={disabled}
            disabledColor={disabledColor}
            left={left}
            right={right}
            className={className}
            style={style}
            onClick={handleClick}
          >
            {left && icon && (
              <Icon
                width={setBySize()}
                height={setBySize()}
                name={icon}
                fill={iconColor || setIconColor()}
                className="left-button__icon"
              />
            )}
            {children}
            {right && icon && (
              <Icon
                width={setBySize()}
                height={setBySize()}
                name={icon}
                fill={iconColor || setIconColor()}
                className="right-button__icon"
              />
            )}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </IconWithTextButtonWrapper>
        );
      default:
        return null;
    }
  };

  return <>{renderByType()}</>;
};

export default Button;
