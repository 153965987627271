import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
// import './style.css';
import "./../../../Common.css";
import * as API from "./../../../API.json";
import { connect } from "react-redux";
import { RootState, actions } from "./../../../store";
import { log, LogLevel } from "./../../../utils/LogUtil";

import { getGlobal, GlobalKey, clearGlobal, setGlobal } from "./../../../utils/GlobalUtil";
import { UIPopupType, UIServiceType } from "../../../store/ui/types";
import AnalyticsUtil from "./../../../utils/AnalyticsUtil";
import { MY_ADDRESS } from "./../../../config.json";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import VideoPlayer from "../../../components/VideoPlayer";
import SearchAddress from "../../../components/SearchAddress";
import { fetchAPI } from "../../../utils/APIUtil";
import { timerStart } from "../../../utils/TimeUtil";
import SeminarView from "../../../component/template/Seminar/SeminarView";
import {
  SeminarLecture,
  SeminarLectureComment,
  SeminarLecturePartialLoadOption,
  SeminarLectureTypeName,
  SeminarReview,
} from "../../../models/Model.Seminar";
import { Attachment, BoardContent } from "../../../models/Model.Board";
import StringUtil from "../../../utils/StringUtil";
import { BoardType } from "../../../store/board/types";
import DownloadUtil from "../../../utils/DownloadUtil";
import { IonActionSheet } from "@ionic/react";
import { UserLevel } from "../../../models/Model.User";
import SeminarCommentEdit from "../../../component/template/Seminar/SeminarCommentEdit";
import SeminarReviewPopup from "../../../component/template/Seminar/SeminarReviewPopup";
import SeminarReviewDonePopup from "../../../component/template/Seminar/SeminarReviewDonePopup";
import { timingSafeEqual } from "crypto";

const queryString = require("query-string");
const windowAny: any = window;

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {};

type State = {
  overlayView: any;
  showMenuPopover: boolean;
  showDeletePopover: boolean;
};

class SeminarViewer extends Component<Props, State> {
  id: number = 0;
  consultOptions: any = null;
  commentSelected: SeminarLectureComment = null;
  commentSelectedIndex: number = 0;
  reviewPopup = null;
  reviewDonePopup = null;
  afterReview = null;

  menuModify = {
    text: "수정",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onCommentModifyStart();
    },
  };

  menuDelete = {
    text: "삭제",
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Delete clicked");
      this.setState({ showDeletePopover: true });
    },
  };

  menuAdminModify = {
    text: "수정(관리자)",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Modify clicked");
      this.onCommentModifyStart();
    },
  };

  menuAdminDelete = {
    text: "삭제(관리자)",
    cssClass: "common-menu-red",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Delete clicked");
      this.setState({ showDeletePopover: true });
    },
  };

  menuCancel = {
    text: "취소",
    role: "cancel",
    cssClass: "common-menu-normal",
    handler: () => {
      log(LogLevel.UI_ACTION, "Menu Cancel clicked");
    },
  };

  popoverCommentMenu: any[] = [this.menuCancel];

  constructor(props: Props) {
    super(props);

    log(LogLevel.UI_LIFECYCLE, "SeminarViewer.constructor", this.props);
    let qs = queryString.parse(this.props.location.search);
    try {
      this.id = parseInt(qs.id);
    } catch (err) {
      this.id = 0;
    }

    let seminar: SeminarLecture = null;

    if (this.id) {
      if (!this.props.lectures[this.id]) this.props.waitingPopup.show();
      else seminar = this.props.lectures[this.id];
      this.props.loadLecture(this.id);
      this.props.updateSeminarLectureView(this.id, 0);
    }

    this.state = {
      overlayView: null,
      showMenuPopover: false,
      showDeletePopover: false,
    };

    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_VIEW", "세미나_진입", { id: this.id });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);

    if (this.id) {
      if (prevProps.lectures[this.id] && this.props.lectures[this.id]) {
        this.props.waitingPopup.hide();
      }
    }

    if (prevProps.location.search != this.props.location.search) {
      let qs = queryString.parse(this.props.location.search);
      let id = 0;
      try {
        id = parseInt(qs.id);
      } catch (err) {
        id = 0;
      }

      let seminar: SeminarLecture = null;
      if (id && this.id != id) {
        this.id = id;
        if (!this.props.lectures[this.id]) this.props.waitingPopup.show();
        this.props.loadLecture(this.id);
        this.props.updateSeminarLectureView(this.id, 0);
      }
    }
  }

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "SeminarViewer:componentDidMount backbutton register");
    if (this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);
  }

  goBack = () => {
    log(LogLevel.UI_ACTION, "SeminarViewer:goBack");
    if (this.state.overlayView != null) {
      this.onOverlayClosed();
    } else {
      this.onCheckReviewAnd(() => {
        this.props.history.goBack();
      });
    }
    return true;
  };

  componentWillUnmount() {
    log(LogLevel.UI_EVENT, "SeminarViewer:componentWillUnmount backbutton unregister");
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "SEMINAR_VIEW", "세미나_이탈", { id: this.id });
  }

  onOverlayClosed = () => {
    this.setState({
      overlayView: null,
    });
  };

  onCheckReviewAnd = (after: () => void) => {
    this.afterReview = after;
    let lecture: SeminarLecture = this.props.lectures[this.id];
    log(
      LogLevel.UI_ACTION,
      "SeminarViewer: onCheckReviewAnd",
      lecture,
      lecture.myReview,
      lecture.passingMark,
      lecture.viewValue
    );
    if (!lecture.myReview && lecture.viewValue && (!lecture.passingMark || lecture.passingMark <= lecture.viewValue)) {
      this.reviewPopup.show();
    } else if (this.afterReview) {
      this.afterReview();
    }
  };

  onShare = () => {
    log(LogLevel.UI_ACTION, "SeminarViewer: onShare");
    let lecture = this.props.lectures[this.id];
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", "세미나_카카오_공유", { id: this.id });

    let os = getGlobal(GlobalKey.OS);

    if (!os || os == "browser") {
      let feed = {
        objectType: "feed",
        content: {
          title: lecture.title,
          description: lecture.subtitle,
          imageUrl: lecture.shareUrl
            ? StringUtil.convertFilePath(lecture.shareUrl)
            : MY_ADDRESS + "/images/ymydgi_20220719.jpg",
          link: {
            androidExecParams: "type=lecture&id=" + lecture.id,
            iosExecParams: "type=lecture&id=" + lecture.id,
          },
        },
        social: {
          viewCount: lecture.viewCount,
        },
        buttons: [
          {
            title: "App에서 보기",
            link: {
              androidExecParams: "type=lecture&id=" + lecture.id,
              iosExecParams: "type=lecture&id=" + lecture.id,
            },
          },
        ],
      };
      //console.log("SeminarViewer: doKakaoShare", feed)
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
      windowAny.Kakao.Link.sendDefault(feed);
    } else if (windowAny.KakaoCordovaSDK) {
      let feed = {
        objectType: "feed",
        content: {
          title: lecture.title,
          desc: lecture.subtitle,
          imageURL: lecture.shareUrl
            ? StringUtil.convertFilePath(lecture.shareUrl)
            : MY_ADDRESS + "/images/ymydgi_20220719.jpg",
          link: {
            androidExecutionParams: "type=lecture&id=" + lecture.id,
            iosExecutionParams: "type=lecture&id=" + lecture.id,
          },
        },
        social: {
          viewCount: lecture.viewCount,
        },
        buttons: [
          {
            title: "App에서 보기",
            link: {
              androidExecutionParams: "type=lecture&id=" + lecture.id,
              iosExecutionParams: "type=lecture&id=" + lecture.id,
            },
          },
        ],
      };
      windowAny.KakaoCordovaSDK.sendLinkFeed(
        feed,
        (res) => {
          console.log("SeminarViewer: doKakaoShare: Kakao share success", res, feed);
        },
        (res) => {
          console.log("SeminarViewer: doKakaoShare: Kakao share fail", res, feed);
        }
      );
    }
  };

  onSelectAttachment = (attachment: Attachment) => {
    DownloadUtil.downloadDocument(attachment.url, attachment.name, this.props.toastPopup);
  };

  onProgress = ({ progress, time }) => {
    let seminar: SeminarLecture = this.props.lectures[this.id];
    log(LogLevel.UI_LIFECYCLE, "SeminarViewer:onProgress()", seminar.viewValue);
    if (seminar.viewValue == 100) return;
    let newViewValue = (seminar.viewValue ? seminar.viewValue : 0) + progress;
    if (newViewValue > 100) newViewValue = 100;
    AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", "세미나_보기_재생시간_" + newViewValue + "%", {
      id: seminar.id,
    });
    if (
      seminar.passingMark &&
      seminar.viewValue &&
      seminar.viewValue < seminar.passingMark &&
      newViewValue > seminar.passingMark
    )
      AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", "세미나_보기_패스", { id: seminar.id });
    this.props.updateSeminarLectureView(this.id, newViewValue);
    this.props.updateSeminarLectureViewLastPlayTime(this.id, time);
  };

  onVideoEnd = () => {
    // this.onCheckReviewAnd(null);
  };

  onCardProgress = (index) => {
    let seminar: SeminarLecture = this.props.lectures[this.id];
    log(LogLevel.UI_LIFECYCLE, "SeminarViewer:onCardProgress()", seminar.viewValue);
    if (seminar.viewValue == 100) return;

    if (seminar.cards.length > 0) {
      let newViewValue = ((index + 1) * 100) / seminar.cards.length;
      if (!seminar.viewValue || newViewValue > seminar.viewValue) {
        AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", "세미나_보기_카드뉴스_" + newViewValue + "%", {
          id: seminar.id,
        });
        if (
          seminar.passingMark &&
          seminar.viewValue &&
          (!seminar.viewValue || seminar.viewValue < seminar.passingMark) &&
          newViewValue > seminar.passingMark
        )
          AnalyticsUtil.event(AnalyticsUtil.TYPE_AMPLITUDE, "", "세미나_보기_패스", { id: seminar.id });
        this.props.updateSeminarLectureView(this.id, newViewValue);
      }
    }
  };

  onTimestamp = (time) => {
    log(LogLevel.UI_LIFECYCLE, "SeminarViewer:onTimestamp()", this.props, this.state, this.props.lectures[this.id]);
    this.props.updateSeminarLectureViewLastPlayTime(this.id, time);
  };

  onSelectSeminar = (seminar: SeminarLecture) => {
    this.onCheckReviewAnd(() => {
      this.props.history.replace("/lecture?id=" + seminar.id);
    });
  };

  onCommentMore = (comment: SeminarLectureComment, index) => {
    this.commentSelected = comment;
    this.commentSelectedIndex = index;
    if (this.commentSelected.editable) {
      this.popoverCommentMenu = [];
      if (this.commentSelected.bodyText) this.popoverCommentMenu.push(this.menuModify);
      this.popoverCommentMenu.push(this.menuDelete);
      this.popoverCommentMenu.push(this.menuCancel);
    } else if (this.props.me.level >= UserLevel.MANAGER)
      this.popoverCommentMenu = [this.menuAdminModify, this.menuAdminDelete, this.menuCancel];

    this.setState({ showMenuPopover: true });
  };

  onCommentModifyStart = () => {
    this.setState({
      overlayView: (
        <SeminarCommentEdit
          comment={this.commentSelected}
          onDone={this.onCommentModify}
          onBack={() => {
            this.setState({ overlayView: null });
          }}
        />
      ),
    });
  };

  onCommentModify = async (comment: SeminarLectureComment) => {
    this.props.waitingPopup.show();
    let result = await fetchAPI(API.SEMINAR_COMMENT_UPDATE, "", null, comment, getGlobal(GlobalKey.TOKEN));
    if (result && !result.error) {
      if (this.id) {
        let seminar = this.props.lectures[this.id];
        seminar.comments[this.commentSelectedIndex] = { ...comment };
        seminar.comments = [...seminar.comments];
        this.props.updateSeminarLecture(seminar);
      }
    }
    this.setState({ overlayView: null });
    this.props.waitingPopup.hide();
  };

  onCommentDelete = async () => {
    this.props.waitingPopup.show();
    let result = await fetchAPI(
      API.SEMINAR_COMMENT_DELETE,
      "",
      { id: this.commentSelected.id },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      if (this.id) {
        this.props.getSeminarLecture(this.id, SeminarLecturePartialLoadOption.COMMENTS);
      }
    }
    this.props.waitingPopup.hide();
  };

  onCardnewsClick = (item: Attachment, index: number, position: any) => {
    log(LogLevel.UI_LIFECYCLE, "SerminarViewer.onCardnewsClick", item, index, position);
    let url = "";
    if (item.link) {
      url = item.link;
    } else if (item.partialLinks && item.partialLinks.length) {
      for (let i = 0; i < item.partialLinks.length; i++) {
        if (
          position.x >= item.partialLinks[i].left &&
          position.x <= item.partialLinks[i].right &&
          position.y >= item.partialLinks[i].top &&
          position.y <= item.partialLinks[i].bottom
        ) {
          url = item.partialLinks[i].link;
          break;
        }
      }
    }
    if (url) {
      if (url.startsWith("&&&")) {
        if (getGlobal(GlobalKey.OS) != "browser") {
          this.props.history.push(url.substring(3));
        } else {
          window.open(url.replace("&&&", MY_ADDRESS), getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system");
        }
      } else if (url.startsWith(MY_ADDRESS)) {
        if (getGlobal(GlobalKey.OS) != "browser") {
          this.props.history.push(url);
        } else {
          window.open(url, getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system");
        }
      } else if (url.startsWith("/")) {
        this.props.history.push(url);
      } else if (url.startsWith("tel") || url.startsWith("sms")) {
        window.open(url, "_system");
      } else window.open(url, getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system");

      let seminar: SeminarLecture = this.props.lectures[this.id];
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "CONTENT_LIKE", "게시물_카드뉴스_링크", {
        게시물id: seminar.id,
        게시물내용: seminar.title,
        링크: item.link,
      });

      if (seminar.advertiserCode) {
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_REACTION", "광고고객반응", {
          type: "링크클릭",
          code: seminar.advertiserCode,
          productCode: seminar.advertiserProductCode,
          contentType: SeminarLectureTypeName[seminar.type],
          contentId: seminar.id,
          contentTitle: seminar.title,
        });
      }
    }
  };

  onReviewDone = async (score?: number, text?: string) => {
    if (score) {
      let review: SeminarReview = {
        lectureId: this.id,
        score,
      };
      let seminar: SeminarLecture = this.props.lectures[this.id];
      seminar.myReview = review;

      let result = await fetchAPI(API.SEMINAR_REVIEW_POST, "", null, review, getGlobal(GlobalKey.TOKEN));
      if (result && !result.error) {
        this.props.updateSeminarLecture(seminar);

        if (text) {
          try {
            let comment: SeminarLectureComment = {
              lectureId: this.id,
              bodyText: text,
            };
            let result = await fetchAPI(API.SEMINAR_COMMENT_NEW, "", null, comment, getGlobal(GlobalKey.TOKEN));
            if (result && !result.error) {
              AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEMINAR_COMMENT", "세미나_평가", {
                id: seminar.id,
                세미나제목: seminar.title,
              });

              if (seminar.advertiserCode) {
                AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_REACTION", "광고고객반응", {
                  type: "평가",
                  code: seminar.advertiserCode,
                  productCode: seminar.advertiserProductCode,
                  contentType: SeminarLectureTypeName[seminar.type],
                  contentId: seminar.id,
                  contentTitle: seminar.title,
                });
              }
            }
          } catch (e) {
            log(LogLevel.UI_EXCEPTION, "ViewerCommentComposer:Exception failed", e);
          }
        }
        this.reviewDonePopup.show();
      }
    } else {
      if (this.afterReview) this.afterReview();
    }
  };

  onReviewFinish = () => {
    if (this.afterReview) this.afterReview();
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "SeminarViewer:render()", this.props, this.state, this.props.lectures[this.id]);

    return (
      <>
        <div className={"common-content" + (this.state.overlayView ? " common-hidden" : "")}>
          <SeminarView
            seminars={this.props.lectures}
            seminar={this.props.lectures[this.id]}
            onGoBack={this.goBack}
            // onReview={this.onReview}
            onShare={this.onShare}
            onSelectAttachment={this.onSelectAttachment}
            onCardnewsClick={this.onCardnewsClick}
            onVideoProgress={this.onProgress}
            onVideoEnd={this.onVideoEnd}
            onCardProgress={this.onCardProgress}
            onTimestamp={this.onTimestamp}
            onSelectSeminar={this.onSelectSeminar}
            onCommentMore={this.onCommentMore}
          />
        </div>
        {this.state.overlayView}

        <SeminarReviewPopup
          ref={(ref) => (this.reviewPopup = ref)}
          lectureId={this.id}
          onDone={this.onReviewDone}
          onCancel={this.onReviewDone}
        />
        <SeminarReviewDonePopup ref={(ref) => (this.reviewDonePopup = ref)} onDone={this.onReviewFinish} />
        <IonActionSheet
          mode="ios"
          children
          isOpen={this.state.showMenuPopover}
          onDidDismiss={() => {
            this.setState({ showMenuPopover: false });
            this.props.backKeyControl.popListener();
          }}
          buttons={this.popoverCommentMenu}
          backdropDismiss={true}
        />
        <IonActionSheet
          mode="ios"
          children
          header="정말로 삭제하시겠습니까?"
          isOpen={this.state.showDeletePopover}
          onDidDismiss={() => {
            this.setState(() => ({ showDeletePopover: false }));
            this.props.backKeyControl.popListener();
          }}
          buttons={[
            {
              text: "삭제하겠습니다.",
              cssClass: "common-menu-red",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                this.onCommentDelete();
              },
            },
            {
              text: "취소",
              role: "cancel",
              cssClass: "common-menu-normal",
              handler: () => {
                log(LogLevel.UI_ACTION, "Delete Menu Cancel clicked");
              },
            },
          ]}
          backdropDismiss={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  me: state.user.me,
  lectures: state.seminar.lectures,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
  // logOutUser: () => actions.user.logout(),
  // refreshBoards: () => actions.board.refreshBoards(),
  // loadBoard: (board:BoardType) => actions.board.getBoard(board.toString(), 50),
  loadLecture: (id: number) => actions.seminar.getSeminarLecture(id),
  refreshReview: (id) => actions.seminar.getSeminarLecture(id, SeminarLecturePartialLoadOption.REVIEW),
  updateSeminarLectureView: (lectureId, value) => actions.seminar.updateSeminarLectureView(lectureId, value),
  updateSeminarLectureViewLastPlayTime: (lectureId, value) =>
    actions.seminar.updateSeminarLectureViewLastPlayTime(lectureId, value),
  getSeminarLecture: (id, option) => actions.seminar.getSeminarLecture(id, option),
  updateSeminarLecture: (seminar) => actions.seminar.fetchSeminarLecture.success(seminar),
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeminarViewer));
