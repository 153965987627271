export interface EventInfo {
  id?: number;
  name?: string;
  imageUrl?: string;
  imageBase64?: any;
  html?: string;
  isFull?: boolean;
  originUrl?: string;
  link?: string;
  startDate?: string;
  endDate?: string;
  bgColor?: string;
  ignoreDuration?: number;
  ignoredAt?: number;
  ignoreText?: string;
  notToday?: boolean;
  deletedAt?: string;
  condition?: any;
  log?: any;
}

export const EMPTY_EVENT: EventInfo = {
  id: 0,
  name: "",
  imageUrl: "",
  imageBase64: "",
  html: "",
  isFull: false,
  originUrl: "/",
  link: "/boards/view?id=",
  startDate: new Date(Date.now() + 9 * 60 * 60 * 1000)
    .toISOString()
    .substring(0, 10),
  endDate: new Date(Date.now() + (24 * 7 + 9) * 60 * 60 * 1000)
    .toISOString()
    .substring(0, 10),
  bgColor: "#fff",
  ignoreDuration: 1,
};
