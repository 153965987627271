
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonModal, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonFooter } from '@ionic/react';
import './ProductSaleOrder.css';
import { OrderInfo, OrderPayMethod, OrderStatus, ProductInfo, ProductStatus } from '../models/Model.Sale';
import { log, LogLevel } from '../utils/LogUtil';
import { UIPopupType, UIServiceType } from '../store/ui/types';
import SwipeableViews from 'react-swipeable-views';
import ReactHtmlParser from 'react-html-parser'
import StringUtil from '../utils/StringUtil';
import { KeywordType } from '../models/Model.Medicine';
import ComponentGenerator from '../ivcomponent/ComponentGenerator';
import LinkMeta from '../components/LinkMeta';
import drugIcon from './../assets/icon/drug.svg'
import {MY_ADDRESS} from '../config.json'
import { Reference } from '../models/Model.Board';
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';
import ProductOrder from '../components/sale/ProductOrder';
import GeocodeUtil from '../utils/GeocodeUtil';
import ProductOrderDelivery from '../components/sale/ProductOrderDelivery';
import ProductOrderDone from '../components/sale/ProductOrderDone';
import SearchAddress from '../components/SearchAddress';
import ProductOrderRegistration from '../components/sale/ProductOrderRegistration';
import { UserInfo } from '../models/Model.User';
import { fetchAPI } from '../utils/APIUtil';
import * as API from '../API.json';
import AnalyticsUtil from '../utils/AnalyticsUtil';

type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  
}

type State = {
}
const windowAny : any = window;
const queryString = require('query-string');

class ProductSaleOrderDone extends Component<Props, State> {
  orderId:number = 0;

  constructor(props: Props) {
    super(props);
    this.state ={
    }
    try{
      windowAny.IMP.init("imp68909306");
    }catch(e){
      log(LogLevel.UI_EXCEPTION, "ProductSaleOrderDone.init error", e)
    }

    let qs = queryString.parse(this.props.location.search);
    try{
      this.orderId = parseInt(qs.id);
    }catch(err) {
    }

  }

  componentDidMount() {
    this.props.backKeyControl.pushListener(() => {
      this.goBack();
      return false;
    });

    if(this.props.orders && this.props.orders[this.orderId]){
      if(this.props.orders[this.orderId].loading)
        this.props.waitingPopup.show();
      else if(this.props.orders[this.orderId].error){
        this.props.waitingPopup.show();
        this.props.loadOrder(this.orderId);
      }
      else{
        let product : ProductInfo = this.props.products[this.props.orders[this.orderId].productId]
        if(!product)
          product = {};
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SALE_ORDER_DONE", '제품주문결제완료', {"제품id":this.props.orders[this.orderId].productId, "주문id":this.orderId, code:product.advertiserCode, productCode:product.advertiserProductCode});

      }
    }
    else{
      this.props.waitingPopup.show();
      this.props.loadOrder(this.orderId);
    }
  }

  componentWillUnmount() {
    this.props.backKeyControl.popListener();
  }

  componentDidUpdate(prevProps, prevState) {
    if((!prevProps.orders[this.orderId] || prevProps.orders[this.orderId].loading || prevProps.orders[this.orderId].error) && this.props.orders[this.orderId] && !this.props.orders[this.orderId].loading && !this.props.orders[this.orderId].error){
      this.props.waitingPopup.hide();
    }
  }

  goBack = () => {
    log(LogLevel.UI_ACTION, "ProductSaleOrderDone:goBack");
    this.props.history.replace("/");
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductSaleOrderDone.render", this.props.orders[this.orderId], this.props.orders );
    if(!this.props.orders[this.orderId] || this.props.orders[this.orderId].loading || this.props.orders[this.orderId].error){
      return null;
    }

    return <ProductOrderDone order={this.props.orders[this.orderId]} onDone={this.goBack}/>
  }
}

const mapStateToProps = (state: RootState) => ({
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
  orders: state.sale.orders,
  products: state.sale.products,
});

const mapDispatchToProps = {
  loadOrder: (id: number) => actions.sale.getOrder(id),
  updateMe: (user:UserInfo) => actions.user.setMe(user),
  loginUser: () => actions.user.login(),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSaleOrderDone));