import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import ELEVATION from '../../_design/elevation'
import Badge from '../Badge'
import Icon from '../Icon'
import './../../../Common.css'
import { rippleEvent } from '../../_util/RrppleEffect'

export type ButtonPosition = 'BottomRight' | 'BottomCenter' | 'BottomFooterRight' | 'TopCenter'
type ButtonSize = 'M' | 'L' | 'I'  // I for Icon only
type ButtonColor = 'Primary' | 'Secondary' | 'White'
const BUTTON_SIZE = {
  I: {
    height: '60px',
    borderRadius: '30px',
    padding: '20px',
    typography: 'ButtonS',
    iconSize: 20,
    iconMargin: "0px",
    badgeSize:"S",
  },
  M: {
    height: '48px',
    borderRadius: '24px',
    padding: '14px 20px',
    typography: 'ButtonM',
    iconSize: 20,
    iconMargin: "8px",
    badgeSize:"S",
    badgeMargin: "8px",

  },
  L: {
    height: '52px',
    borderRadius: '26px',
    padding: '14px 14px',
    typography: 'ButtonL',
    iconSize: 22,
    iconMarginGap: "4px",
    badgeSize:"M",
  },
}

const BUTTON_COLOR = {
  'Primary':{
    color: COLOR.White,
    background: COLOR.Primary,
    badgeColor: "Primary",
  },
  'Secondary':{
    color: COLOR.White,
    background: COLOR.Secondary,
    badgeColor: "Secondary",
  },
  'White':{
    color: COLOR.Gray1,
    background: COLOR.White,
    badgeColor: "White",
  }
}

const BUTTON_POSITION = {
  'TopCenter':`
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0px);
  `,
  'BottomRight':`
    bottom: 20px;
    right: 24px;
  `,
  'BottomCenter':`
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0px);
  `,
  'BottomFooterRight':`
    bottom: 106px;
    right: 24px;
  `,
}

interface WrapperProps {
  size: ButtonSize
  color: ButtonColor
  position: ButtonPosition
}

const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`

  position: absolute;
  ${(props) => BUTTON_POSITION[props.position]}
  z-index: 100;
  
  .floating-button{
    z-index: 0;
    height: ${(props) => BUTTON_SIZE[props.size].height};
    width: ${(props) => props.block?"100%":"auto"};
    padding: ${(props) => BUTTON_SIZE[props.size].padding};
    border-radius: ${(props) => BUTTON_SIZE[props.size].borderRadius};
    background-color: ${(props) => BUTTON_COLOR[props.color].background};
    color: ${(props) => BUTTON_COLOR[props.color].color};
    outline: none;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${(props) => BUTTON_SIZE[props.size].width};
    &:disabled {
      background-color: ${COLOR.Gray6};
      color: ${COLOR.White};
      cursor: not-allowed;
    }

    ${ELEVATION.Lv4}
  }

`

interface Props {
  size?: ButtonSize
  color?: ButtonColor
  position?: ButtonPosition
  className?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  iconLeftName?: string
  iconRightName?: string
  badge?: string
  block?: boolean
}
const FloatingButton: React.FC<Props> = ({
  size = 'S',
  color = 'Primary',
  position = "BottomRight",
  iconLeftName,
  iconRightName,
  badge,
  className,
  children,
  disabled,
  onClick,
}) => {

  const el = useRef(null);
  const rippleEl = useRef(null);
  return (

    <Wrapper
      ref={el} 
      size={size}
      color={color}
      position={position}
      className={className?className+' common-effect-parent ':'common-effect-parent'}
    >
      <button
        className="floating-button"
        ref={el} 
        disabled={disabled}
        onClick={(e) => {
          rippleEvent(e, el.current, rippleEl.current);

          if(onClick) onClick(e);
        }}
      >
        {(iconLeftName) && 
          <div style={{marginRight:BUTTON_SIZE[size].iconMargin}}>
            <Icon width={BUTTON_SIZE[size].iconSize} height={BUTTON_SIZE[size].iconSize} fill={BUTTON_COLOR[color].color} name={iconLeftName}/>
          </div>
        }
        <Text type={BUTTON_SIZE[size].typography}>
          {children}
        </Text>
        {(badge) && 
          <div style={{marginLeft:BUTTON_SIZE[size].badgeMargin}}>
            <Badge size={BUTTON_SIZE[size].badgeSize} variant="Contained" color={BUTTON_COLOR[color].badgeColor}>{badge}</Badge>
          </div>
        }
        {(iconRightName) && 
          <div style={{marginLeft:BUTTON_SIZE[size].iconMargin}}>
            <Icon width={BUTTON_SIZE[size].iconSize} height={BUTTON_SIZE[size].iconSize} fill={BUTTON_COLOR[color].color} name={iconRightName}/>
          </div>
        }
        <div  className="common-effect-hover"/>
        <div ref={rippleEl} className="common-effect-ripple"/>
      </button>
    </Wrapper>
  )
}

export default FloatingButton
