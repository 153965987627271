import React, { Component } from 'react';
import './SelectPopup.css';
import './../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';;
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import DynamicCheckerInput, { CheckStatus } from './../DynamicCheckerInput';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import Textarea from 'react-textarea-autosize';

type Props = {
};

type State = {
  visible: boolean;
}

interface SelectPopupOptions{
  title?: any,
  candidates?: any[],
  onSelect?: (selectedIndex:number, selectedCandidate:any) => void,
  onCancel?: () => void,
  backDropDismiss?: boolean,
  needCloseButton?: boolean,
  needRadioButton?: boolean,
}

class SelectPopup extends Component<Props, State> {
  input = null;

  state={
    visible: false,
  };

  initialOptions:SelectPopupOptions = {
    title: "",
    candidates: [],
    onSelect: null,
    onCancel: null,
    backDropDismiss: true,
    needCloseButton: true,
    needRadioButton: false,
  }

  options:SelectPopupOptions = {...this.initialOptions};

  show = (options:SelectPopupOptions = {}) => {
    this.options = {...this.initialOptions, ...options,}
    this.setState({visible:true});
    log(LogLevel.UI_LIFECYCLE, "SelectPopup:show", options, this.options)
  }

  hide = () => {
    log(LogLevel.UI_LIFECYCLE, "SelectPopup:hide", this.state)
    this.setState({visible: false});
  }

  isShown = () => {
    return this.state.visible;
  }

  onSelect = (index) => {
    this.hide();
    if(this.options.onSelect)
      this.options.onSelect(index, this.options.candidates[index]);
  }

  onCancel = () => {
    this.hide();
    if(this.options.onCancel)
      this.options.onCancel();
  }

  onClickBackdrop = () => {
    if(this.options.backDropDismiss){
      this.hide();
      if(this.options.onCancel)
        this.options.onCancel();
    }
  }

  render() {

    let closeButton;
    if(this.options.needCloseButton){
      closeButton = (
        <IonButton color="select-popup-close-button" class="select-popup-close-button" onClick={this.hide}>
          <IonIcon class="select-popup-close-button-icon" name="close"/>
        </IonButton>
      );
    }

    let title;
    if(this.options.title)
      title = (
        <div className="select-popup-title-container">
          {this.options.title}
        </div>
      );

    let candidates;
    if(this.options.candidates)
    candidates = (
        <div className="select-popup-body-container">
          {this.options.candidates.map((item, index) => (
            <div  key={index.toString()} className={"select-popup-body-item-out-container" + ((index < this.options.candidates.length-1)?" select-popup-body-item-out-bordered-container":"")} onClick={() => this.onSelect(index)}>
              <div  className="select-popup-body-item-container">
                {item.text?item.text:item}
              </div>
              {this.options.needRadioButton && 
                <IonCheckbox class="select-popup-body-item-checkbox" mode="ios" checked={false}/>
              }
            </div>
          ))}
        </div>
      );

    return (
      <IonModal cssClass="common-modal-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {this.options.backDropDismiss}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="common-modal-popup-inner">
          <div className="common-modal-popup-backdrop"  onClick={this.onClickBackdrop}/>
          <div className="common-modal-container-outer">
            <div className="common-modal-container common-modal-container-max360">
              {title}
              {candidates}
              {closeButton}
            </div>
          </div>
          <div className="common-modal-popup-backdrop"  onClick={this.onClickBackdrop}/>
        </div>
      </IonModal>
    );
  }
}

export default SelectPopup;