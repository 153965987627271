import React, { Component } from 'react';
import './Welcome.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton } from '@ionic/react';
import profileM from './../assets/image/profile_m_4.png'
import profileF from './../assets/image/profile_w_1.png'

import { UserInfo } from '../models/Model.User';
import SliderIndex from './SliderIndex';

type Props = {
  onNext?: () => void;
  onDone?: () => void;
  img?: any;
  description?: any;
};

type State = {
}

class WelcomeStage1Description extends Component<Props, State> {

  render() {
    let next;  
    if(this.props.onNext)
      next = (        
        <div className="welcome-navigate-container-reverse">
          <div className="welcome-navigate-space"/>
          <IonButton color="welcome-navigate-active-reverse" onClick={this.props.onNext}>
            다음 단계로 &gt;
          </IonButton>
        </div>
      );
    
    let done;
    if(this.props.onDone){
      done = (        
        <div className="welcome-next-button-container-reverse">
          <IonButton color="welcome-next-button-reverse" onClick={this.props.onDone}>
            약문약답 시작하기
          </IonButton>
        </div>
      );
    }

    return (
      <div className="welcome-container">
        <div className="welcome-1-description-title">{this.props.description}</div>
        <img className="welcome-1-description-image" src={this.props.img}/>
        {next}
        {done}
      </div>   
    );
  }
}

export default WelcomeStage1Description;