import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonModal, IonIcon, IonInput, IonList, IonItem, IonCheckbox } from "@ionic/react";
import { fetchAPI } from "./../utils/APIUtil";
import * as API from "./../API.json";
import Skeleton from "react-loading-skeleton";
import smileIcon from "./../assets/icon/smile.svg";
import { KeywordType, Medicine, Ingredient } from "../models/Model.Medicine";
import { compose } from "redux";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";
import { getGlobal, GlobalKey, setGlobal } from "../utils/GlobalUtil";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import { readSync } from "fs";


class BackKeyControlService extends Component {
  callbacks: any[] = [];
  names: any[] = [];
  bDeviceReady = false;
  bMounted = false;

  constructor(props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:constructor : ");
    document.addEventListener("deviceready", () => this.ready(false), false);
  }

  ready = (mounted: boolean) => {
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:ready : ", mounted);
    if (mounted) this.bMounted = true;
    else this.bDeviceReady = true;

    if ((this.bDeviceReady || getGlobal(GlobalKey.OS) == "browser") && this.bMounted) {
      log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:ready : done");
      document.addEventListener("backbutton", this.goBack, false);
    }
  };

  componentDidMount() {
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:componentDidMount");
    this.ready(true);
  }

  componentWillUnmount() {
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:componentWillUnmount");
    document.removeEventListener("backbutton", this.goBack);
  }

  goBack = (e) => {
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:goBack");
    if (!this.callbacks.length) return;
    e.preventDefault();
    for (let i = 0; i < this.callbacks.length; i++) {
      if (this.callbacks[i]()) {
        break;
      }
    }
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:goBack", this.callbacks);
  };

  setListener = (callback, name="") => {
    this.callbacks = [callback];
    this.names = [name];
    // log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:setListener", this.callbacks, (new Error()).stack);
  };

  clearListener = (callback) => {
    this.callbacks = [];
  };

  pushListener = (callback, name="") => {
    if(name){
      let index = this.names.indexOf(name);
      if(index >=0){
        this.callbacks[index] = callback;
        return;
      }
    }
    this.callbacks = [callback, ...this.callbacks];
    this.names = [name, ...this.names];
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:pushListener", this.callbacks, this.names);
  };

  popListener = (name="") => {
    if(name){
      let index = this.names.indexOf(name);
      if(index >=0){
        this.callbacks.splice(index, 1);
        this.names.splice(index, 1);
        return;
      }else{
        return;
      }
    }

    this.callbacks.splice(0, 1);
    this.names.splice(0, 1);
    log(LogLevel.UI_LIFECYCLE, "BackKeyControlService:popListener", this.callbacks, this.names);
  };

  render() {
    return <div />;
  }
}

export default BackKeyControlService;
