import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Button from "../../components/atoms/Button";
import { COLOR_SYSTEM } from "../../components/design/design-system";
import Text from "../../components/atoms/Text";
import API from "../../API.json";
import { fetchAPI } from "../../utils/APIUtil";
import queryString from "query-string";
import Spinner from "../../components/atoms/Spinner";
import IngredientList from "../../components/organisms/Medicine/IngredientList";
import styled from "styled-components";
import { openSystemBrowser } from "../../utils/CommonUtil";

const MedicinePageHeader = styled.header`
  height: 48px;
  padding-bottom: 12px;
`;

const MedicinePageMain = styled.main`
  height: calc(100vh - 48px);
  padding: 0 20px 80px;
  overflow-y: auto;

  & > * + * {
    margin-top: 80px;
  }

  .ingredients__horizon-line {
    margin: 16px 0 20px;

    & > hr {
      height: 0;
      border: 0.5px solid ${COLOR_SYSTEM.get("Gray")[50]};
    }
  }

  span {
    user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text;
  }
`;

const MedicineNameDiv = styled.div`
  margin: 8px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MedicineLinkDiv = styled.div`
  display: flex;
  gap: 12px;
  transform: translateZ(0);
`;

const Medicine: React.FC<RouteComponentProps> = ({ location, history }) => {
  const { id: medicineId, type: medicineType } = queryString.parse(location.search);
  const keywordTypeName = ["", "성분", "의약품", "용어", "부작용", "동물의약품"];
  const [medicineDetail, setMedicineDetail] = useState<any>();

  const handleClickBack = () => {
    history.push("/main/search");
  };

  const handleClickLink = (url: string) => () => {
    openSystemBrowser(url);
  };

  useEffect(() => {
    fetchAPI(API.MEDICINE_DETAIL, "", { type: medicineType, id: medicineId }, null) //
      .then((res) => setMedicineDetail(res.data));
  }, []);

  if (!medicineDetail)
    return (
      <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
        <Spinner />
      </div>
    );

  return (
    <>
      <MedicinePageHeader>
        <Button color="Secondary" icon="CaretLeft" onClick={handleClickBack} size="Large" type="Icon" variant="Ghost" />
      </MedicinePageHeader>
      <MedicinePageMain>
        <div>
          <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Skyblue")[400]}>
            {keywordTypeName[medicineDetail.type]}
          </Text>

          <MedicineNameDiv>
            <div>
              <Text textType="H1" color={COLOR_SYSTEM.get("Gray")[900]}>
                {medicineDetail.textKor}
              </Text>
            </div>
            <div>
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
                {medicineDetail.textEng}
              </Text>
            </div>
          </MedicineNameDiv>

          <MedicineLinkDiv>
            {medicineDetail.linkKor && (
              <Button
                color="Secondary"
                size="Small"
                type="Text"
                variant="Tinted"
                onClick={handleClickLink(medicineDetail.linkKor)}
              >
                국내허가사항
              </Button>
            )}
            {medicineDetail.linkEng && (
              <Button
                color="Secondary"
                size="Small"
                type="Text"
                variant="Tinted"
                onClick={handleClickLink(medicineDetail.linkEng)}
              >
                해외자료
              </Button>
            )}
          </MedicineLinkDiv>
        </div>
        {medicineDetail.ingradients && !!medicineDetail.ingradients.length && (
          <div>
            <div>
              <Text textType="H3" color={COLOR_SYSTEM.get("Gray")[900]}>
                원료약품 및 분량
              </Text>
            </div>
            <div className="ingredients__horizon-line">
              <hr />
            </div>

            <IngredientList ingredients={medicineDetail.ingradients} />
          </div>
        )}
      </MedicinePageMain>
    </>
  );
};

export default withRouter(Medicine);
