import React from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";

type BadgeColor = "Primary" | "Red" | "Black";

export interface BadgeProps {
  /** Badge 안에 들어갈 내용 */
  content?: string;
  /** Badge 색상 */
  color: BadgeColor;
}

const BadgeWrapper = styled.span<BadgeProps>`
  display: inline-block;
  padding: ${({ content }) => (content && content.length > 1 ? "0px 6px" : "0px")};
  min-width: ${({ content }) => (content ? "20px" : "8px")};
  height: ${({ content }) => (content ? "20px" : "8px")};
  color: ${COLOR_SYSTEM.get("Gray")[0]};
  background-color: ${({ color }) => {
    switch (color) {
      case "Primary":
        return COLOR_SYSTEM.get("Skyblue")[400];
      case "Red":
        return COLOR_SYSTEM.get("Red")[300];
      case "Black":
        return COLOR_SYSTEM.get("Gray")[900];
      default:
        return;
    }
  }};
  border-radius: 999px;
  text-align: center;
  box-sizing: border-box;

  ${typography.get("CaptionBold")};
`;

const Badge = ({ content = "", color }: BadgeProps) => {
  return (
    <BadgeWrapper color={color} content={content}>
      {content}
    </BadgeWrapper>
  );
};

export default Badge;
