import * as users from "./actions";
import { ActionType, getType } from "typesafe-actions";
import { UserState, AuthStatus } from "./types";
import { log, LogLevel } from "../../utils/LogUtil";
import { UserInfo, UserLevel } from "../../models/Model.User";

export const FREE_SUMMARY_COUNT = 50;
const defaultState: UserState = {
  me: null,
  authStatus: AuthStatus.UNKNOWN,
  token: "",
  users: {},
  isAccomplished: false,
  freeSummaryCount: FREE_SUMMARY_COUNT,
};

export type UserAction = ActionType<typeof users>;

export default (state = defaultState, action: UserAction): UserState => {
  // log(LogLevel.REDUX_USER, "User Reducer. ", action)
  let isAuthenticated: AuthStatus;
  let isAccomplished: boolean = false;
  let newUsers = { ...state.users };
  switch (action.type) {
    case getType(users.logout):
    case getType(users.fetchUserME.failure):
      // newUsers[0] = null;
      return {
        ...state,
        me: null,
        authStatus: AuthStatus.EXPIRED,
        // users: newUsers,
        token: "",
      };

    case getType(users.setMe):
    case getType(users.fetchUserME.success):
      isAuthenticated = AuthStatus.UNKNOWN;
      let me: UserInfo = state.me ? state.me : {};
      if (state.token) {
        me = { ...me, ...action.payload };
        if (me.level >= UserLevel.NORMAL_USER)
          isAuthenticated = AuthStatus.AUTHENTICATED;
        else isAuthenticated = AuthStatus.REGISTERING;

        isAccomplished = me.isAccomplished;
      }
      // newUsers[0] = action.payload;
      let newState = {
        ...state,
        me,
        authStatus: isAuthenticated,
        isAccomplished: isAccomplished,
        // users: newUsers,
      };
      log(
        LogLevel.REDUX_USER,
        "User Reducer. ",
        state,
        newState,
        action,
        isAuthenticated
      );
      return newState;

    case getType(users.fetchUserInfo.success):
      newUsers[action.payload.id] = action.payload;
      return {
        ...state,
        users: newUsers,
      };

    case getType(users.fetchUserAccomplished.success):
      return {
        ...state,
        isAccomplished: action.payload,
      };

    case getType(users.fetchUserBoardStatistics.success):
      return {
        ...state,
        me: { ...state.me, ...action.payload },
      };

    case getType(users.fetchLastRecievedComment.success):
      return {
        ...state,
        me: { ...state.me, recievedComments: action.payload },
      };

    case getType(users.setToken):
      isAuthenticated = AuthStatus.UNKNOWN;
      if (state.me) {
        if (state.me.level >= UserLevel.NORMAL_USER)
          isAuthenticated = AuthStatus.AUTHENTICATED;
        else isAuthenticated = AuthStatus.REGISTERING;
      }
      return {
        ...state,
        token: action.payload,
        authStatus: isAuthenticated,
      };

    case getType(users.fetchFreeSummaryCount.success):
      return {
        ...state,
        freeSummaryCount: action.payload,
      };

    case getType(users.decreaseFreeSummaryCount):
      return {
        ...state,
        freeSummaryCount: state.freeSummaryCount - 1,
      };

    case getType(users.increaseFreeSummaryCount):
      return {
        ...state,
        freeSummaryCount: action.payload,
      };

    default:
      return state;
  }
};
