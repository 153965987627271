import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Icon from '../../atom/Icon'
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from './../../atom/Text'
interface ContainerProps {
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  min-height: 86px;
  padding: 12px 24px 24px 24px;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  backdrop-filter: blur(6px);
`

export interface Props {
}

const Footer: FC<Props> = ({children }) => {
  return (
    <Container>
        {children}
    </Container>
  )
}

export default Footer
