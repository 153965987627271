import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import StringUtil from '../../../utils/StringUtil'
import COLOR from './../../_design/color'


const types =  {
  Thumbnail : `
    width: var(--size, 100px);
    min-width: var(--size, 100px);
    height: var(--size, 100px);
    border-radius: var(--radius, 12px);
  `,

}


type ThumbnailType =
"Thumbnail"


interface ContainerProps {
  type?: ThumbnailType
  className?: string
  src?: string
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.div<ContainerProps>`
  overflow: hidden;
  position: relative;
  
  ${(props) => types[props.type]}
  border: 1px solid ${COLOR.Gray6};

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
`

export interface Props {
  type?: ThumbnailType
  className?: string
  src?: string
  errorImage?: any
}
const Thumbnail: FC<Props> = ({ type="Thumbnail", children, src, className, errorImage }) => {
  let classname = "";
  if(className)
    classname = className;

  src = StringUtil.convertFilePath(src);
  return (
    <Container
      type={type}
      className={classname}
    >
      <img src={src} onError={(e)=>{if(errorImage){let targetAny:any = e.target; targetAny.src=errorImage}}}/>
      {children}
    </Container>
  )
}

export default Thumbnail
