import React, { useCallback, useEffect, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Textarea from 'react-textarea-autosize';
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from '../Text';
import { log, LogLevel } from '../../../utils/LogUtil';

interface WrapperProps {
  checked:boolean
}
const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  .toggle-switch-background {
    width: 36px;
    height: 20px;
    border-radius: 10px;
    padding: 0px;
    outline: none;
    outline-style: none;
    background: ${COLOR.Gray6};
    border: none;
    position: relative;

    .toggle-switch-bean {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      padding: 0px;
      outline: none;
      outline-style: none;
      background: ${COLOR.White};
  
      appearance: none;
      border: none;
      &::placeholder {
        opacity: 0.2;
      }
      &::-webkit-input-placeholder {
        opacity: 0.2;
      }
    }
  }
  
`

export interface Props {
  checked?: boolean;
  onClick?: (e) => void;
}
const ToggleSwitch: React.FC<Props> = ({
  checked = false,
  onClick,
}) => {
  const bgEl = useRef(null);
  const beanEl = useRef(null);
  const initialized = useRef<boolean>(false);

  const requestIds = useRef<number[]>([])
  const lastLeft = useRef<number>()
  const lastBG = useRef<number[]>([0,0,0])
  const frameCount = useRef<number>(0)
  const isAnimating = useRef(false)

  useEffect(() => {
    if(!initialized.current){
      initialized.current = true;
      if(checked){
        lastLeft.current = 20;
        const color = parseInt(COLOR.Primary.replace("#", "0x"));
        const r = color >>> 16;         // 0x12
        const g = color >>> 8 & 0xff;  // 0x34
        const b = color & 0xff;   // 0x56
        lastBG.current = [r, g, b];
        beanEl.current.style.left = lastLeft.current + "px"
        bgEl.current.style.background = "rgb(" + lastBG.current.join(",") + ")"

      }
      else{
        lastLeft.current = 4;
        const color = parseInt(COLOR.Gray6.replace("#", "0x"));
        const r = color >>> 16;         // 0x12
        const g = color >>> 8 & 0xff;  // 0x34
        const b = color & 0xff;   // 0x56
        lastBG.current = [r, g, b];
        beanEl.current.style.left = lastLeft.current + "px"
        bgEl.current.style.background = "rgb(" + lastBG.current.join(",") + ")"
      }
    }
    else{
      if(checked)
        switchTo(20, COLOR.Primary);
      else
        switchTo(4, COLOR.Gray6);
    }
    log(LogLevel.UI_EVENT, "ToggleSwitch:useEffect:checked", checked)




    return () => {
      // log(LogLevel.UI_EVENT, "ToggleSwitch:useEffect:checked:return", checked)
    }
  }, [checked])
  
  const cancelScrolling = useCallback(() => {
    requestIds.current.forEach((requestId) => cancelAnimationFrame(requestId))
    requestIds.current = []
  }, [])

  const switchTo = (left: number, colorS:string) => {
    if (!beanEl || !bgEl) {
      return
    }
    cancelScrolling()
    frameCount.current = 0
    isAnimating.current = true

    const color = parseInt(colorS.replace("#", "0x"));
    const r = color >>> 16;         // 0x12
    const g = color >>> 8 & 0xff;  // 0x34
    const b = color & 0xff;   // 0x56
    const bg = [r, g, b];

    const duration = 150 // 1s == 1000ms
    const startTime = performance.now()
    const fromLeft = lastLeft.current
    const toLeft = left
    const fromBG = lastBG.current
    const toBG = bg
    log(LogLevel.UI_EVENT, "ToggleSwitch:switchTo start:", left, fromLeft, toLeft, beanEl.current, beanEl.current.left, beanEl.current.style)

    lastLeft.current = left;
    lastBG.current = bg;

    const frame = (now: number) => {
      frameCount.current++

      const scrollingTime = now - startTime

      // 순간 이동할 y는 남은 이동Y(toLeft - scrollingLeft)를 남은시간(단위:s) * fps로 나눈다.
      const ratio = (scrollingTime / duration);
      const cleft = fromLeft + (toLeft - fromLeft) * ratio;
      const cbg = [fromBG[0] + (toBG[0] - fromBG[0]) * ratio, fromBG[1] + (toBG[1] - fromBG[1]) * ratio, fromBG[2] + (toBG[2] - fromBG[2]) * ratio]

      // log(LogLevel.UI_EVENT, "ToggleSwitch:switchTo animate:", cbg, fromBG, toBG, ratio)
      //TODO: 마지막 프레임에 최종 목적지보다 1프레임 더 움직인 뒤 뒤로 가는 버그 수정하기
      if (scrollingTime >= duration) {
        beanEl.current.style.left = lastLeft.current + "px"
        bgEl.current.style.background = "rgb(" + lastBG.current.join(",") + ")"
        isAnimating.current = false
      } else {
        beanEl.current.style.left = cleft + "px"
        bgEl.current.style.background = "rgb(" + cbg.join(",") + ")"
        if (lastLeft.current === left) {
          requestIds.current.push(requestAnimationFrame(frame))
        }
      }
    }

    requestIds.current.push(requestAnimationFrame(frame))
  }


  return (
    <Wrapper checked={checked}>
      <div className="toggle-switch-background" ref={bgEl} onClick={onClick}>
        <div className="toggle-switch-bean" ref={beanEl}/>
      </div>
    </Wrapper>

  )
}

export default ToggleSwitch
