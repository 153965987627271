import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

interface JobPostDetailStyledProps {
  last?: boolean;
  rowTitle?: boolean;
  rowDescription?: boolean;
  rowAnnotation?: boolean;
  banner?: boolean;
}

const DetailMain = styled.main<JobPostDetailStyledProps>`
  padding: 20px 24px ${({ banner }) => (banner ? "120px" : "90px")} 24px;
  overflow-y: scroll;
  flex: 1;
  background: #fff;

  .title-container {
    margin-bottom: 48px;

    & > * {
      display: block;
    }

    & > * + * {
      margin-top: 20px;
    }

    .detail-title__top {
      & > * + * {
        margin-left: 8px;
      }
    }

    .detail-title__bottom {
      display: flex;
      justify-content: space-between;

      .count-container {
        & > * + * {
          margin-left: 8px;
        }
      }
    }
  }

  .kakao-share {
    background-color: ${COLOR_SYSTEM.get("Gray")[10]};
    padding: 21px 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    & > * + * {
      margin-top: 16px;
    }
  }
`;

const PharmacyAndMessageContainer = styled.section`
  .detail__pharmacy-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }

  .detail__pharmacy-address,
  .detail__message {
    display: block;
  }

  .detail__pharmacy-address {
    margin: 8px 0 24px;
  }

  .detail__message {
    margin-bottom: 48px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr<JobPostDetailStyledProps>`
  width: 100%;
  display: table;
  border-bottom: ${({ last }) =>
    last ? "none" : `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`};
`;

const TableData = styled.td<JobPostDetailStyledProps>`
  ${({ rowTitle, rowDescription, rowAnnotation }) => {
    if (rowTitle) {
      return `
        width: 90px;
        padding: 20px 0;
        vertical-align: baseline;
      `;
    } else if (rowDescription) {
      return `
        flex-direction: column;
        display: flex;
        padding: 20px 0;
        vertical-align: baseline;
      `;
    } else if (rowAnnotation) {
      return `
        width: 4.5rem;
        vertical-align: baseline;
        text-align: center;
      `;
    }
  }}

  button {
    padding: 3.5px 7px;
  }

  button.after-tax-button {
    width: 10.5em;
    margin-top: 8px;
  }

  .negotiable-tag {
    display: block;
    margin-top: 8px;
    width: fit-content;
  }

  .apply-method__container {
    & > * + * {
      margin-top: 12px;
    }
  }

  .table__apply-method {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 70px;
    }

    @media screen and (max-width: 360px) {
      & {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
      }

      button {
        width: 100px;
      }

      & > * + * {
        margin-top: 8px;
      }
    }
  }
`;

const DetailFooter = styled.footer<{ hasBanner: boolean }>`
  padding: 13px 24px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  width: 100%;
  height: ${({ hasBanner }) => (hasBanner ? "125" : "75")}px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  z-index: 101;

  & > * + * {
    margin-left: 12px;
  }

  .footer__main-button {
    flex: 1;
  }
`;

const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  & > * + * {
    margin-top: 12px;
  }
`;

const PopupFooter = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }

  .alim__button {
    position: relative;

    .hint {
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      height: 30px;
      background-color: ${COLOR_SYSTEM.get("Gray")[700]};
      padding: 2px 6px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        background-color: ${COLOR_SYSTEM.get("Gray")[700]};
      }
    }
  }
`;

const MoreBottomSheet = styled.div`
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 27px;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    width: 100%;
  }
`;

const WelfareTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  margin-left: -4px;

  & > * {
    margin-left: 4px;
    margin-bottom: 4px;
  }
`;

export {
  DetailMain,
  Table,
  TableData,
  TableRow,
  DetailFooter,
  PopupBody,
  PopupFooter,
  MoreBottomSheet,
  PharmacyAndMessageContainer,
  WelfareTagsContainer,
};
