import React from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import { SHADOW } from "../../design/effects";

type SegmentedControlSize = "Medium" | "Small";
type SegmentedControlType = "Text" | "Icon";
type SegmentedControlVariant = "Outlined" | "Tinted";
type SegmentedControlColor = "Gray" | "Skyblue";

export interface Item {
  text?: string;
  icon?: string;
  type: SegmentedControlType;
  active: boolean;
  onClick: (params?: any) => any;
}

export interface SegmentedControlProps {
  /** Segmented Control 크기 */
  size: SegmentedControlSize;
  /** Segmented Control 종류 */
  variant: SegmentedControlVariant;
  /** Segmented Control 색상 */
  color: SegmentedControlColor;
  /** Segmented Control 안에 들어갈 Content */
  items: Item[];
}

interface SegmentedControlWrapperProps {
  size?: SegmentedControlSize;
  color?: SegmentedControlColor;
  variant?: SegmentedControlVariant;
  active?: boolean;
}

const SegmentedControlWrapper = styled.ul<SegmentedControlWrapperProps>`
  height: ${({ size }) => (size === "Medium" ? "40px" : "32px")};
  list-style: none;
  display: inline-flex;
  align-items: center;
  border: ${({ variant }) => {
    if (variant === "Outlined") {
      return `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`;
    }
  }};
  border-radius: ${({ size }) => (size === "Medium" ? "8px" : "4px")};
  margin: 0;
  padding: 3px;
  background-color: ${({ variant }) =>
    variant === "Tinted" ? COLOR_SYSTEM.get("Gray")[20] : COLOR_SYSTEM.get("Gray")[0]};
  box-sizing: border-box;

  & > * + * {
    margin-left: 3px;
  }
`;

const ItemWrapper = styled.li<SegmentedControlWrapperProps>`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ variant, active }) => {
    if (variant === "Tinted" && active) {
      return "white";
    }
  }};
  ${({ variant, active }) => {
    if (variant === "Tinted" && active) {
      return SHADOW.get("1");
    }
  }}
  margin: ${({ variant, active }) => {
    if (variant === "Tinted" && active) {
      return "3px 0";
    }
  }};
  border-radius: 4px;
  box-sizing: border-box;
  flex: 1;

  button {
    width: 100%;
  }
`;

const Line = styled.div`
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: ${COLOR_SYSTEM.get("Gray")[50]};
`;

const SegmentedControl: React.FC<SegmentedControlProps> = ({ size, variant, color, items }) => {
  return (
    <SegmentedControlWrapper size={size} color={color} variant={variant}>
      {items.map((item, index) => (
        <>
          <ItemWrapper key={index} variant={variant} active={item.active}>
            <ToggleButton
              type={item.type}
              color={color}
              variant={"Ghost"}
              size={size}
              active={item.active}
              icon={item.icon || ""}
              onClick={item.onClick}
            >
              {item.text}
            </ToggleButton>
          </ItemWrapper>
          {variant === "Outlined" && index < items.length - 1 && <Line />}
        </>
      ))}
    </SegmentedControlWrapper>
  );
};

export default SegmentedControl;
