import React, { Component } from 'react';
import './../Admin.scss';
import StringUtil from '../../utils/StringUtil';

export enum ImageSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large" 
}

type Props = {
  key?: string;
  base64?: string;
  url?: string;
  filePath?: string;
  onChange: (e) => void;
  imageSize? : ImageSize;
};



const ImageViewNSelect: React.SFC<Props> = ({base64, url, filePath, onChange, imageSize}) => {

const imageSizeStyle = () => {
  if(imageSize == ImageSize.SMALL){
      return {
        width: "50px",
        height: "50px"
      }
  } else if(imageSize == ImageSize.MEDIUM){
    return {
      width: "100px",
      height: "100px"
    }
  } else if(imageSize == ImageSize.LARGE){
    return {
      width: "200px",
      height: "200px"
    }
  }

} 
  if(base64){
    return (
      <div className="admin-image-show-n-change-container">
        <img className="admin-image-show-n-change-image" src={base64} style={imageSizeStyle()}/>
        <input className="admin-image-show-n-change-file" multiple={false} type="file" accept="image/*" onChange={onChange}/>
      </div>
    );
  }
  else if(url){
    return (
      <div className="admin-image-show-n-change-container">
        <img className="admin-image-show-n-change-image" src={StringUtil.convertFilePath(url)} style={imageSizeStyle()}/>
        <input className="admin-image-show-n-change-file" multiple={false} type="file" accept="image/*" onChange={onChange}/>
      </div>
    );
  }
  else{
    return (
      <input multiple={false} type="file" accept="image/*" onChange={onChange}/>
    );
  }
}

export default ImageViewNSelect;