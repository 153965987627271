import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import styled, { StyledComponent } from "styled-components";
import { IonSpinner } from "@ionic/react";
import {
  SeminarLecture,
  SeminarLectureComment,
  SeminarLecturePartialLoadOption,
  SeminarLectureType,
  SeminarLectureTypeName,
} from "../../../../models/Model.Seminar";
import { Attachment, BoardContent } from "../../../../models/Model.Board";
import Header from "../../../molecule/Header";
import Body from "../../../molecule/Body";
import StringUtil from "../../../../utils/StringUtil";
import Scroll from "../../../molecule/Scroll";
import { log, LogLevel } from "../../../../utils/LogUtil";
import { ScrollAction } from "../../../molecule/Scroll";
import Video from "../../../atom/Video";
import VideoController from "../../../cell/VideoController";
import { getGlobal, GlobalKey } from "../../../../utils/GlobalUtil";
import IconButton from "../../../atom/IconButton";
import Footer from "../../../molecule/Footer";
import TextButton from "../../../atom/TextButton";
import "./style.scss";
import "./../../../../Common.css";
import Badge from "../../../atom/Badge";
import Text from "../../../atom/Text";
import Icon from "../../../atom/Icon";
import { getTimerString } from "../../../../utils/TimeUtil";
import color from "../../../_design/color";
import StarScore from "../../../atom/StarScore";
import { TabBar } from "../../../cell/TabBar";
import SeminarViewDetail from "../../../organism/Seminar/SeminarViewDetail";
import Spinner from "../../../atom/Spinner";
import ReactVisibilitySensor from "react-visibility-sensor";
import CardView from "../../../molecule/CardView";
import SeminarViewSeries from "../../../organism/Seminar/SeminarViewSeries";
import FooterEditor, { FooterEditorResult } from "../../../molecule/FooterEditor";
import SeminarViewComments from "../../../organism/Seminar/SeminarViewComments";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../../../store";
import { UIPopupType } from "../../../../store/ui/types";
import { loadImageBase64 } from "../../../../utils/ImageUtil";
import { fetchAPI } from "../../../../utils/APIUtil";
import * as API from "./../../../../API.json";
import AnalyticsUtil from "../../../../utils/AnalyticsUtil";
import { getFileSizeString } from "../../../../utils/FileUtil";
import { MY_ADDRESS } from "./../../../../config.json";

interface Props {
  seminars: any;
  seminar: SeminarLecture;
  onGoBack: () => void;
  // onReview?: () => void;
  // onConsult?: () => void;
  onShare?: () => void;
  onSelectAttachment?: (attachment: Attachment) => void;
  onVideoProgress?: ({ progress, time }) => void;
  onVideoEnd?: () => void;
  onCardProgress?: (index) => void;
  onCardnewsClick?: (card: Attachment, index: number, position: any) => void;
  onTimestamp?: (time) => void;
  onSelectSeminar?: (seminar: SeminarLecture) => void;
  onCommentMore?: (comment: SeminarLectureComment, index) => void;
  history?;
}
const SerminarView: FC<Props> = ({
  seminars,
  seminar,
  onGoBack,
  // onReview,
  onSelectSeminar,
  onShare,
  onSelectAttachment,
  onCardnewsClick,
  onVideoProgress,
  onVideoEnd,
  onCardProgress,
  onTimestamp,
  onCommentMore,
  history,
}) => {
  // const scrollEl = useRef<Scroll>(null);
  // const videoContainerEL = useRef(null);
  const seminarId = useRef<number>(0);
  const videoEl = useRef<Video>(null);
  const detailEl = useRef(null);
  const seriesEl = useRef(null);
  const tabBarEl = useRef(null);
  const commentEl = useRef(null);
  const editorEl = useRef(null);
  const hasSeries = useRef<boolean>(false);
  const hasMultiResolution = useRef<boolean>(false);
  const [videoHighResolution, setVideoHighResolution] = useState<boolean>(getGlobal(GlobalKey.SEMINAR_HIGH_RESOLUTION));
  const [videoSpeed, setvideoSpeed] = useState<number>(1);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [scrollEl, setScrollEl] = useState<Scroll>(null);
  const [videoContainerEL, setVideoContainerEL] = useState(null);
  const [outVideoHeight, setOutVideoHeight] = useState(0);
  const elTabNames = useRef<string[]>([]);
  const [showMoveTop, setShowMoveTop] = useState<boolean>(false);
  const [commentToReply, setCommentToReply] = useState<SeminarLectureComment>(null);
  const [commentToFocus, setCommentToFocus] = useState<number>(0);

  const progressPopup = useSelector((state: RootState) => state.ui.popups[UIPopupType.WAITING_POPUP]);
  const dispatch = useDispatch();

  useEffect(() => {
    log(LogLevel.UI_EVENT, "SerminarView:useEffect[seminar]", seminar, seminarId.current, scrollEl);

    if (videoEl.current) {
      videoEl.current.subscribe("progress", onVideoProgress);
      videoEl.current.setSrc(seminar["videoUrl" + (videoHighResolution ? "HighResolution" : "")]);
      if (
        seminar.lastPlayedTime &&
        videoEl.current.duration &&
        videoEl.current.duration * 0.95 > seminar.lastPlayedTime
      )
        videoEl.current.jumpTo(seminar.lastPlayedTime);
    }

    if (seminar && seminar.id != seminarId.current) {
      if (scrollEl) {
        scrollEl.clearAutoScroll();
        scrollEl.onMoveToTop();
      }
      seminarId.current = seminar.id;
    }
    return () => {};
  }, [seminar]);

  const onReach = ({ targetId }) => {
    log(LogLevel.UI_LIFECYCLE, "SerminarView.onScrollReach", targetId);
    let index = elTabNames.current.indexOf(targetId);
    if (index >= 0) {
      setTabIndex(index);
    }
  };

  const onBack = () => {
    if (scrollEl) scrollEl.clearAutoScroll();
    if (onTimestamp && videoEl.current) onTimestamp(videoEl.current.getCurrentPlayTime());
    if (onGoBack) onGoBack();
  };

  const onContentsVisibilityChange = (visible) => {};

  const onDuration = (playTime) => {
    if (videoEl.current && seminar.lastPlayedTime && playTime * 0.95 > seminar.lastPlayedTime) {
      videoEl.current.jumpTo(seminar.lastPlayedTime);
    }
  };

  const onCommentDone = async (editorResult: FooterEditorResult) => {
    if (!editorResult || (!editorResult.text && !editorResult.file)) return;

    if (progressPopup) progressPopup.show();
    let comment: SeminarLectureComment = {
      lectureId: seminar.id,
    };

    if (commentToReply) {
      comment.groupParent = commentToReply.id;
    }
    try {
      if (editorResult.file) {
        if (editorResult.file.type.startsWith("image")) {
          let base64 = await loadImageBase64(editorResult.file);
          let data: any = { path: "board" };
          data.base64 = base64;
          let result = await fetchAPI(API.UPLOAD_BASE64, "", null, data, getGlobal(GlobalKey.TOKEN));
          if (result && !result.error) {
            comment.imageUrl = result.file;
          } else {
            log(LogLevel.UI_ACTION, "ViewerCommentComposer:onImageSelected failed", result);
          }
        } else {
          let data = new FormData();
          data.append("file", editorResult.file);
          let name = editorResult.file.name;
          let size = editorResult.file.size;
          let result = await fetchAPI(API.UPLOAD_FILE, "lecture", null, data, getGlobal(GlobalKey.TOKEN));
          if (result && result.message && result.message == "success") {
            comment.fileUrl = result.file;
            comment.fileName = name.replace(/\?/g, "");
            comment.fileSize = size;
          } else {
            log(LogLevel.UI_ACTION, "ViewerCommentComposer:onFileSelected failed", result);
          }
        }
      } else if (editorResult.text) {
        comment.bodyText = editorResult.text;
      }

      let result = await fetchAPI(API.SEMINAR_COMMENT_NEW, "", null, comment, getGlobal(GlobalKey.TOKEN));
      if (result && !result.error) {
        comment.id = result.data;

        setCommentToFocus(comment.id);
        // AMPLITUDE
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEMINAR_COMMENT", "세미나_코멘트", {
          id: seminar.id,
          세미나제목: seminar.title,
        });

        if (seminar.advertiserCode) {
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_REACTION", "광고고객반응", {
            type: "댓글",
            code: seminar.advertiserCode,
            productCode: seminar.advertiserProductCode,
            contentType: SeminarLectureTypeName[seminar.type],
            contentId: seminar.id,
            contentTitle: seminar.title,
          });
        }

        if (seminar.id) {
          dispatch(actions.seminar.getSeminarLecture(seminar.id, SeminarLecturePartialLoadOption.COMMENTS));
        }
      }
      log(LogLevel.UI_ACTION, "ViewerBody:onComment", result, comment);
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "ViewerCommentComposer:Exception failed", e);
    }
    setCommentToReply(null);
    if (progressPopup) progressPopup.hide();
  };

  const onCommentLike = (comment: SeminarLectureComment, index) => {
    log(LogLevel.UI_EXCEPTION, "onCommentLike:", comment);
    try {
      if (comment.liked) {
        fetchAPI(API.SEMINAR_COMMENT_ACTION_CANCEL_LIKE, comment.id, null, null, getGlobal(GlobalKey.TOKEN));
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEMINAR_COMMENT_LIKE_CANCEL", "세미나_코멘트_좋아요_취소", {
          id: seminar.id,
          세미나제목: seminar.title,
        });
      } else {
        fetchAPI(API.SEMINAR_COMMENT_ACTION_LIKE, comment.id, null, null, getGlobal(GlobalKey.TOKEN));
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEMINAR_COMMENT_LIKE", "세미나_코멘트_좋아요", {
          id: seminar.id,
          세미나제목: seminar.title,
        });
        if (seminar.advertiserCode) {
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_REACTION", "광고고객반응", {
            type: "좋아요",
            code: seminar.advertiserCode,
            productCode: seminar.advertiserProductCode,
            contentType: SeminarLectureTypeName[seminar.type],
            contentId: seminar.id,
            contentTitle: seminar.title,
          });
        }
      }

      comment.likeCnt = comment.likeCnt + (comment.liked ? -1 : 1);
      comment.liked = !comment.liked;
      seminar.comments[index] = { ...comment };
      seminar.comments = [...seminar.comments];
      dispatch(actions.seminar.fetchSeminarLecture.success(seminar));
      // dispatch(actions.seminar.getSeminarLecture(seminar.id, SeminarLecturePartialLoadOption.COMMENTS));
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "onCommentLike:Exception failed", e);
    }
  };

  const onCommentReply = (comment: SeminarLectureComment) => {
    log(LogLevel.UI_EVENT, "SerminarView:onCommentReply", scrollEl);
    setCommentToReply(comment);
  };

  const onVideoJumpTo = (time) => {
    log(LogLevel.UI_EVENT, "SerminarView:onVideoJumpTo", scrollEl, time);
    if (videoEl.current) {
      videoEl.current.jumpTo(time);
      if (!videoEl.current.isPlaying()) {
        videoEl.current.play();
      }
    }
    if (scrollEl) scrollEl.scrollTo(0);
  };

  const onSeriesRef = (ref) => {
    seriesEl.current = ref;
    log(LogLevel.UI_EVENT, "SerminarView:onSeriesRef", scrollEl, seriesEl.current);
    if (scrollEl) {
      scrollEl.addTarget("series", seriesEl.current);
    }
  };

  const onFocusCommentFound = (el) => {
    if (scrollEl) {
      scrollEl.scrollToElement(el, 300);
      setCommentToFocus(0);
    }
  };

  useEffect(() => {
    log(
      LogLevel.UI_EVENT,
      "SerminarView:useEffect[scrollEl]",
      scrollEl,
      detailEl,
      seriesEl,
      commentEl,
      tabBarEl && tabBarEl.current && tabBarEl.current.clientHeight
    );

    if (scrollEl) {
      elTabNames.current = [];
      scrollEl.subscribe("reach", onReach);
      elTabNames.current.push("detail");
      scrollEl.addTarget("detail", detailEl.current);
      if (seriesEl.current) {
        elTabNames.current.push("series");
        scrollEl.addTarget("series", seriesEl.current);
      }
      elTabNames.current.push("comment");
      scrollEl.addTarget("comment", commentEl.current);

      scrollEl.setMargin(tabBarEl.current.clientHeight);
    }

    if (videoEl.current && onVideoProgress) {
      videoEl.current.subscribe("progress", onVideoProgress);
    }

    return () => {
      if (scrollEl) scrollEl.unsubscribe("reach", onReach);
    };
  }, [scrollEl]);

  useEffect(() => {
    const onResize = () => {
      if (videoContainerEL) {
        log(
          LogLevel.UI_EVENT,
          "SerminarView:useEffect[videoContainerEL].resize",
          videoContainerEL,
          videoContainerEL.clientHeight
        );
        setOutVideoHeight(videoContainerEL.clientHeight - 3);
      }
    };
    if (videoContainerEL) {
      log(
        LogLevel.UI_EVENT,
        "SerminarView:useEffect[videoContainerEL]",
        videoContainerEL,
        videoContainerEL.clientHeight
      );
      setOutVideoHeight(videoContainerEL.clientHeight - 3);
      videoContainerEL.addEventListener("resize", onResize);
    }
    return () => {
      if (videoContainerEL) videoContainerEL.removeEventListener("resize", onResize);
    };
  }, [videoContainerEL]);

  useEffect(() => {
    log(LogLevel.UI_EVENT, "SerminarView:useEffect[]", videoEl.current);

    return () => {
      log(LogLevel.UI_EVENT, "SerminarView:useEffect[] clear");
      if (onTimestamp && videoEl.current) onTimestamp(videoEl.current.getCurrentPlayTime());
    };
  }, []);

  if (!seminar || (!seminar.id && seminar.loading))
    return (
      <>
        <Header needBack={true} />
        <Body scrollable={false}>
          <div className="common-block">
            <Spinner block />
          </div>
        </Body>
      </>
    );

  // const isBrowser = false;

  log(LogLevel.UI_EVENT, "SerminarView", seminar, scrollEl);

  hasSeries.current =
    seminar.seriesId && seminar.series && seminar.series.lectures && seminar.series.lectures.length > 0;
  let titles = [];
  elTabNames.current = [];

  titles.push(<Text key="detail">내용</Text>);
  elTabNames.current.push("detail");
  if (hasSeries.current) {
    titles.push(<Text key="series">시리즈</Text>);
    elTabNames.current.push("series");
  }
  titles.push(<Text key="comment">댓글({seminar.commentCount})</Text>);
  elTabNames.current.push("comment");

  return (
    <>
      <Header
        needBack={true}
        goBack={onBack}
        buttons={[<IconButton key="1" iconName="Share" size="M" variant="Ghost" color="Secondary" onClick={onShare} />]}
      />
      <Body scrollable={false}>
        <div className="common-container" style={{ height: `calc(100% - ${outVideoHeight}px)` }}>
          <Scroll ref={(ref) => setScrollEl(ref)} autoScrollName={"SeminarView"}>
            {seminar.type == SeminarLectureType.SEMINAR && (
              <ReactVisibilitySensor onChange={onContentsVisibilityChange}>
                <Video
                  ref={videoEl}
                  src={seminar["videoUrl" + (videoHighResolution ? "HighResolution" : "")]}
                  previewUrl={seminar.previewUrl}
                  speed={1}
                  // onPlay?:() => void,
                  onPause={() => {
                    if (onTimestamp) onTimestamp(videoEl.current.getCurrentPlayTime());
                  }}
                  onDuration={onDuration}
                  onEnd={() => {
                    if (onTimestamp) onTimestamp(videoEl.current.getCurrentPlayTime());
                    if (onVideoEnd) onVideoEnd();
                  }}
                />
              </ReactVisibilitySensor>
            )}
            {seminar.type == SeminarLectureType.CARDNEWS && (
              <CardView
                items={seminar.cards}
                imageUrlKey="url"
                onClick={onCardnewsClick}
                onProgress={onCardProgress}
                showProgressBar={true}
                showNavigationButton={true}
                indexPosition="right"
                indexType="number"
              />
            )}
            {seminar.type == SeminarLectureType.SEMINAR && (
              <VideoController
                highResolution={videoHighResolution}
                onChangeResolution={
                  hasMultiResolution
                    ? (newHighResolution: boolean) => {
                        setVideoHighResolution(newHighResolution);
                        videoEl.current.setSrc(seminar["videoUrl" + (newHighResolution ? "HighResolution" : "")]);
                      }
                    : null
                }
                speed={videoSpeed}
                onChangeSpeed={(speed: number) => {
                  setvideoSpeed(speed);
                  videoEl.current.setSpeed(speed);
                }}
              />
            )}
            <div className="common-block" style={{ marginBottom: "16px" }}>
              {seminar.categories && seminar.categories.length && (
                <div
                  className="common-container-row common-flex-align-center common-flex-row-wrap"
                  style={{ marginBottom: "16px" }}
                >
                  {seminar.categories.map((category, index) => {
                    return (
                      <div key={index.toString()} style={{ marginRight: "8px" }}>
                        <Badge size="S" variant="Tinted" color="Gray1">
                          {category.title}
                        </Badge>
                      </div>
                    );
                  })}
                </div>
              )}
              <div style={{ marginBottom: "12px" }}>
                <Text type="H2" color="Secondary">
                  {seminar.title}
                </Text>
              </div>
              <div style={{ marginBottom: "16px" }}>
                <Text type="SubHead" color="Gray2">
                  {seminar.subtitle}
                </Text>
              </div>
              <div className="common-container-row common-flex-align-center">
                {seminar.type == SeminarLectureType.SEMINAR && [
                  <Icon key="1" className="seminar-view-information-icon" name="Clock" fill={color.Gray4} />,
                  <Text key="2" className="seminar-view-information-text" type="Body2" color="Gray4">
                    {getTimerString(seminar.playtime)}
                  </Text>,
                ]}
                {seminar.type == SeminarLectureType.CARDNEWS &&
                  seminar.cards &&
                  seminar.cards.length > 0 && [
                    <Icon key="1" className="seminar-view-information-icon" name="Pages Filled" fill={color.Gray4} />,
                    <Text key="2" className="seminar-view-information-text" type="Body2" color="Gray4">
                      {seminar.cards.length}
                    </Text>,
                  ]}
                <Icon className="seminar-view-information-icon" name="Eye Filled" fill={color.Gray4} />
                <Text className="seminar-view-information-text" type="Body2" color="Gray4">
                  {seminar.viewCount}
                </Text>
                <StarScore score={seminar.scoreAverage} size="M" />
                <Text className="seminar-view-information-text" type="Body2" color="Gray4">
                  ({seminar.scoreCount})
                </Text>
              </div>
            </div>
            {seminar.inBanners && seminar.inBanners.length > 0 && (
              <div className="common-block-line" style={{ marginBottom: "40px" }}>
                <img src={StringUtil.convertFilePath(seminar.inBanners[0].url)} className="common-container" />
              </div>
            )}
            <div ref={tabBarEl} className="common-position-sticky" style={{ top: "-1px" }}>
              <TabBar
                splitEven
                level="1"
                index={tabIndex}
                onIndexChanged={(index: number, key: string) => {
                  log(LogLevel.UI_LIFECYCLE, "SerminarView.onIndexChanged", key, index, elTabNames.current);
                  setTabIndex(index);
                  if (scrollEl) {
                    scrollEl.scrollToTarget(key ? key : elTabNames.current[index]);
                  }
                }}
              >
                {titles}
              </TabBar>
            </div>
            <div ref={detailEl} className="common-container">
              <SeminarViewDetail
                seminar={seminar}
                onVideoJumpTo={onVideoJumpTo}
                onSelectAttachment={onSelectAttachment}
              />
            </div>
            {hasSeries.current == true && (
              <div
                ref={(ref) => onSeriesRef(ref)}
                className="common-container"
                style={{ marginTop: "40px", paddingTop: "40px" }}
              >
                <SeminarViewSeries seminars={seminars} seminar={seminar} onClick={onSelectSeminar} />
              </div>
            )}
            {seminar.inBanners && seminar.inBanners.length > 1 && (
              <div className="common-block-line" style={{ marginTop: "40px" }}>
                <img src={StringUtil.convertFilePath(seminar.inBanners[1].url)} className="common-container" />
              </div>
            )}
            <div ref={commentEl} className="common-container" style={{ marginTop: "40px", paddingTop: "40px" }}>
              <SeminarViewComments
                seminar={seminar}
                commentToFocus={commentToFocus}
                onMore={onCommentMore}
                onLike={onCommentLike}
                onReply={onCommentReply}
                onFocus={onFocusCommentFound}
              />
            </div>
            <div className="common-bottom-footer-space" />
          </Scroll>
          <FooterEditor
            ref={editorEl}
            placeholder="댓글을 남겨주세요"
            onDone={onCommentDone}
            replyTitle={commentToReply ? commentToReply.userNickname + "님께 댓글달기" : null}
            replyDescription={commentToReply && commentToReply.bodyText ? commentToReply.bodyText : null}
            onCancelReply={() => setCommentToReply(null)}
          />
        </div>
      </Body>
    </>
  );
};

const Container: StyledComponent<"div", FC> = styled.div``;

export default SerminarView;
