import React, { memo, useMemo } from "react";
import Tag from "../../atoms/Tag";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import styled from "styled-components";
import { SHADOW } from "../../design/effects";
import { Board } from "../../../store/search";
import { getDateTimeStringShort } from "../../../utils/TimeUtil";
import ReactHtmlParser from "react-html-parser";
import NiceCard from "../../../assets/image/nice_card.png";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface SearchedBoardCardProps {
  board: Board;
}

interface IFlexProps {
  gap?: "string";
}

const FlexRowDiv = styled.div<IFlexProps>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
`;

const CardLi = styled.li`
  list-style: none;
  padding: 16px;
  border-radius: 8px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  ${SHADOW.get("1")};
  transform: translateZ(0);
  cursor: pointer;
`;

const TopDiv = styled(FlexRowDiv)`
  justify-content: space-between;
  margin-bottom: 12px;
`;

const PostSubjectDiv = styled.div`
  & em {
    font-style: normal;
    font-weight: 700;
    color: ${COLOR_SYSTEM.get("Skyblue")[400]};
  }
`;

const BodyTextDiv = styled.div`
  & em {
    font-style: normal;
    font-weight: 400;
    color: ${COLOR_SYSTEM.get("Skyblue")[400]};
  }
`;

const BottomDiv = styled(FlexRowDiv)`
  margin-top: 16px;
`;

const SearchedBoardCard: React.FC<SearchedBoardCardProps> = ({ board }) => {
  const currentSearchedKeyword = useSelector(
    (state: RootState) => state.newSearch.currentSearchedKeyword
  );
  const [title, description] = useMemo(() => {
    let title;
    let description;

    if (board.subjectHightlight) {
      title = ReactHtmlParser(board.subjectHightlight);
      description = board.bodyTextHightlight
        ? ReactHtmlParser(board.bodyTextHightlight)
        : board.bodyText;
      return [title, description];
    }

    if (board.subject) {
      title = board.subject;
      description = board.bodyTextHightlight
        ? ReactHtmlParser(board.bodyTextHightlight)
        : board.bodyText;
      return [title, description];
    }

    if (board.bodyText) {
      title = board.bodyText;
      description = board.bodyTextHightlight
        ? ReactHtmlParser(board.bodyTextHightlight)
        : board.bodyText;
      return [title, description];
    }
  }, [board]);

  const handleClickCard = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_SEARCHED_RESULT",
      "검색결과선택",
      {
        검색모드: "BOARD",
        검색어: currentSearchedKeyword,
        게시물내용: (board.subject || board.bodyText).substring(0, 10),
        게시판이름: board.boardsCategoriesName,
      }
    );
  };

  return (
    <CardLi onClick={handleClickCard}>
      <TopDiv>
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          {board.isNice && <img src={NiceCard} alt="" width={17} height={20} />}
          {
            board.boardsCategoriesName === "약사파트너스" ? (
              <Tag variant="Contained" color="Black">
                {board.boardsCategoriesName}
              </Tag>
            ): (
              <Tag variant="Tinted" color="Gray">
                {board.boardsCategoriesName}
              </Tag>
            )
          }
        </div>
        <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
          {getDateTimeStringShort(board.createdAt)}
        </Text>
      </TopDiv>
      <PostSubjectDiv>
        <Text
          textType="Body1Bold"
          color={COLOR_SYSTEM.get("Gray")[900]}
          numberOfLines={1}
        >
          {title}
        </Text>
      </PostSubjectDiv>
      <BodyTextDiv>
        <Text
          textType="Body2"
          color={COLOR_SYSTEM.get("Gray")[600]}
          numberOfLines={2}
        >
          {description}
        </Text>
      </BodyTextDiv>
      <BottomDiv gap="12px">
        <Text textType="Caption" color={COLOR_SYSTEM.get("Skyblue")[400]}>
          의견{" "}
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Skyblue")[400]}>
            {board.replyCnt}
          </Text>
        </Text>
        <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
          좋아요{" "}
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[600]}>
            {board.likeCntAll}
          </Text>
        </Text>
        <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
          읽기{" "}
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[600]}>
            {board.viewCnt}
          </Text>
        </Text>
      </BottomDiv>
    </CardLi>
  );
};

export default memo(SearchedBoardCard);
