import React, { Component } from "react";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
  AllInOnePartnerLevel,
  CompanyType,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import {
  BoardAttribute,
  BoardContent,
  BoardContentQuiz,
} from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import Button from "../../../components/atoms/Button";
import * as API from "../../../API.json";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";
import { loadImageBase64, loadImageUrlBase64 } from "../../../utils/ImageUtil";
import ImageViewNSelect, { ImageSize } from "../ImageViewNSelect";
import HtmlComposer from "../../../components/HtmlComposer";
import Textarea from "react-textarea-autosize";
import BoardSummary from "../../../components/templates/BoardSummary";
import { Flex, Static } from "../../../components/atoms/Layout";
import { MY_ADDRESS } from "../../../config.json";
import Text from "../../../components/atoms/Text";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import Separator from "../../../components/atoms/Separator";
import ToggleButton from "../../../components/atoms/ToggleButton/ToggleButton";
import Viewer from "../../../pages/Viewer";
import BoardQuizView from "../../../components/templates/BoardQuizView";
import { on } from "cluster";
import CheckBox from "../../../components/atoms/CheckBox/CheckBox";
import { get } from "lodash";

const AdminAPI = {
  // PROMPT_GET: {
  //   method: "GET",
  //   path: "/admin/quiz/prompt/",
  //   contentType: "application/json",
  // },
  // SUMMARY_CREATE: {
  //   method: "POST",
  //   path: "/admin/quiz/add",
  //   contentType: "application/json",
  // },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    contentId: number;
    onGenerateQuiz: (contentId: number, prompt: string) => void;
    onCancel: () => void;
  };

type State = {
  savePrompt: boolean;
  prompt: string;
  foldPrompt: boolean;
};

class ContentDetail extends Component<Props, State> {
  state: State = { savePrompt: false, prompt: "", foldPrompt: true };

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps, nextState) {}

  onBack = () => {
    this.props.onCancel;
  };

  render() {
    log(
      LogLevel.UI_ACTION,
      "ContentDetail render",
      this.props.contentId,
      this.state
    );
    if (!this.props.contentId) {
      return null;
    }

    const candidates = getGlobal("jsonQuizPromptCandidates", true, []);

    return (
      <div className="admin-container">
        {this.props.onCancel && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start" class="common-container">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onCancel}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel class="common-header-title common-flex-grow">
                  콘텐츠 상세보기
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}

        <Flex gap="12px" customStyle={{ padding: "0px 20px" }}>
          <Button
            size={"Medium"}
            type={"Text"}
            variant={"Contained"}
            color={"Primary"}
            onClick={() =>
              this.props.onGenerateQuiz(
                this.props.contentId,
                this.state.foldPrompt ? "" : this.state.prompt
              )
            }
          >
            퀴즈 만들기
          </Button>
          <ToggleButton
            size={"Medium"}
            type={"Text"}
            variant={"Tinted"}
            color={"Black"}
            active={!this.state.foldPrompt}
            onClick={() =>
              this.setState({ foldPrompt: !this.state.foldPrompt })
            }
          >
            추가프롬프트 {this.state.foldPrompt ? "⬇️" : "⬆️"}
          </ToggleButton>
        </Flex>
        {!this.state.foldPrompt && (
          <Flex
            gap="8px"
            direction="column"
            customStyle={{ padding: "0px 20px" }}
          >
            <Separator type={"Line"} />
            <Textarea
              style={{ width: "100%" }}
              value={this.state.prompt}
              onChange={(e) => this.setState({ prompt: e.target.value })}
            />
            <Flex gap="4px">
              <Button
                size={"XSmall"}
                type={"Text"}
                variant={"Outlined"}
                color={"Secondary"}
                onClick={() =>
                  this.setState({ prompt: "이번에는 OX퀴즈로 만들어주세요." })
                }
              >
                OX퀴즈
              </Button>
              <Button
                size={"XSmall"}
                type={"Text"}
                variant={"Outlined"}
                color={"Secondary"}
                onClick={() =>
                  this.setState({ prompt: "이번에는 객관식으로 만들어주세요." })
                }
              >
                객관식
              </Button>
              <Button
                size={"XSmall"}
                type={"Text"}
                variant={"Outlined"}
                color={"Secondary"}
                onClick={() =>
                  this.setState({
                    prompt: "이번에는 빈칸채우기로 만들어주세요.",
                  })
                }
              >
                빈칸채우기
              </Button>
              <Button
                size={"XSmall"}
                type={"Text"}
                variant={"Outlined"}
                color={"Secondary"}
                onClick={() =>
                  this.setState({
                    prompt: "이번에는 순서맞추기로 만들어주세요.",
                  })
                }
              >
                순서맞추기
              </Button>
            </Flex>
            <Separator type={"Line"} />
          </Flex>
        )}

        {this.props.contentId > 0 && (
          <Static>
            <Viewer
              contentId={this.props.contentId}
              recommendDisabled={true}
              adminMode={true}
            />
          </Static>
        )}
      </div>
    );

    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.partner.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentDetail);
