export enum KeywordType {
    UNKNOWN = 0,
    MEDICINE = 2,
    INGRADIENT = 1,
    TERM = 3,
    SIDE_EFFECT = 4,
    ANIMAL_MEDICINE = 5,
}

export interface AnimalMedicine{
    type: KeywordType;
    id: number;
    text?: string;
    textKor?: string;
    textEng?: string;
    linkKor?: string;
    linkEng?: string;
    ingradients?: Ingredient[];
    code?: string;
    tagName?: string
}

export interface Medicine{
    type?: KeywordType;
    id: number;
    text?: string;
    textKor?: string;
    textEng?: string;
    linkKor?: string;
    linkEng?: string;
    ingradients?: Ingredient[];
    medicineType?: string;
    company?: string;
    code?: string;
    atcCode?: string;
    insuranceCode?: string;
    tagName?: string;
}

export interface Ingredient{
    type?: KeywordType;
    id: number;
    text?: string;
    textKor?: string;
    textEng?: string;
    linkKor?: string;
    linkEng?: string;
    volume?: string;
    tagName?: string;
}

export interface Keyword {
    type?: KeywordType;
    id: number;
    text?: string;
    textReverse?: string;
    textKor?: string;
    textEng?: string;
    tagName?: string;
    description?: string;
}


export interface ScholarSearchQuery{
    medicines: Keyword[],
    sideEffects: Keyword[],
    userId?: number;
    queriedAt?: string;
}

export enum FrequencyType {
    UNKNOWN = 0,
    POSTMARKETING_REPORTS = 1,
    FREQUENCY_NOT_REPORTED = 2,
    VERY_RARE = 3,
    RARE = 4,
    UNCOMMON= 5,
    COMMON = 6,
    VERY_COMMON= 7,
}

export interface ScholarSearchResult{
    medicines: any, // id : Medicine
    sideEffects: any, // id: Keyword
    relations: ScholarSearchResultItem[],
}

export interface ScholarSearchResultItem{
    sideEffectId: number,
    medicines: {
        medicineId?: number,
        frequency?: FrequencyType,
        frequencyDetail: string,
        reference?: string,
        referenceUrl?: string,
    }[],
}