

import React, { Component } from 'react';
import './../BoardManage.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonActionSheet } from '@ionic/react';
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../models/Model.User';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { BoardContent } from '../../models/Model.Board';
import ReactQuill, { Quill } from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; // ES6
import ViewerBody from '../../components/ViewerBody';
import { Keyword } from '../../models/Model.Medicine';
import BoardListItem from '../../components/BoardListItem';
import Mention from 'quill-mention';
import MagicUrl from 'quill-magic-url';
import { isThisMonth } from 'date-fns';
import parse from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';

const ADMIN_API = {        
  "CANCEL_DELETE" : {"method":"get", "path":"/admin/board/content/revive/", "contentType":"application/json"},
};


var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

var Inline = Quill.import('blots/inline');

class Keywords extends Inline {
  static create(value) {
    let node = super.create(value);
    log(LogLevel.UI_DATA_LOAD, 'Keywords:value: ', value)
    node.setAttribute('keywordtype', value.type);
    node.setAttribute('keywordid', value.id);
    node.textContent = value.text;
    return node;
  }

  static formats(domNode) {
    return {
      type: domNode.getAttribute('keywordtype'),
      id: domNode.getAttribute('keywordid')
    }
  }
}

Keywords.blotName = 'keywords';
Keywords.className = 'keywords';
Keywords.tagName = 'SPAN';

Quill.register(Keywords);
Quill.register(Mention);
Quill.register('modules/magicUrl', MagicUrl);

const keywordTypeName = ['','성분','의약품','용어','부작용'];

type Props = {
  onDone: () => void;
  onCancel: () => void;
  content: BoardContent;
};

type State = {
  content: BoardContent;
  title: string;
  bodyHtml: string;
  bodyText: string;
  selected: string;
  range: any;
  keywords: Keyword[];
  selectedKeywords: Keyword[];
}

class BaordContentEdit extends Component<Props, State> {
  reactQuillRef: any =  null;

  static modules = {
    toolbar: {
      container: "#toolbar",
    },
    magicUrl: true,
    // toolbar: false,
  }

  static formats = [
    'keywords', 'mention',
    'bold', 'underline', 'blockquote',
    'link', 'image', 'color', 'background'
  ]

  state = {
    content: null,
    title : "",
    bodyHtml:'',
    bodyText: '',
    selected:'',
    range:null,
    keywords:[],
    selectedKeywords:[],
  }

  constructor(props:Props){
    super(props);
    if(props.content){
      this.state.content = props.content;
      this.state.bodyHtml = props.content.bodyHtml;
      this.state.title = props.content.subject?props.content.subject:"";
    }
  }

  componentWillUpdate(nextProps, nextState){
    log(LogLevel.UI_LIFECYCLE, "BaordContentEdit:componentWillUpdate", nextProps.content, nextState, this.props, this.state);
    if(nextProps.content && (!this.props.content || this.props.content.id != nextProps.content.id)){
      this.setState({content: nextProps.content, bodyHtml:nextProps.content.bodyHtml, title:nextProps.content.subject?nextProps.content.subject:"", selectedKeywords:[]})
    }
  }

  onDone = async () => {
    let keywords = [];
    if(this.state.content && this.state.content.keywords){
      keywords = this.state.content.keywords;
    }
    let updateContent : BoardContent = {
      id: this.state.content.id,
      bodyHtml: this.state.content.bodyHtml,
      keywords,
      subject : this.state.title,
      managedAt: "1",
    }

    log(LogLevel.UI_ACTION, "BaordContentEdit Update", updateContent)
    let result = await fetchAPI(API.BOARD_UPDATE, "", null, updateContent, getGlobal(GlobalKey.TOKEN))
    if(result && !result.error){
      this.props.onDone();
    }
    else{
      log(LogLevel.UI_EXCEPTION, "BaordContentEdit Update failed", result);
    }
  }
  
  onDelete = async () => {
    if(this.props.content && this.props.content.deletedAt){
      log(LogLevel.UI_ACTION, "BaordContentEdit onDelete cancel", this.props.content);
      let result = await fetchAPI(ADMIN_API.CANCEL_DELETE, this.state.content.id, null, null, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error){
        this.props.onDone();
      }      
    }else {
      log(LogLevel.UI_ACTION, "BaordContentEdit onDelete", this.props.content);
      let result = await fetchAPI(API.BOARD_DELETE, this.state.content.id, null, null, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error){
        this.props.onDone();
      }
      else{
        log(LogLevel.UI_EXCEPTION, "BaordContentEdit Delete failed", result);
      }
    }
  }

  onPreview = () => {
    let content:BoardContent = {...this.state.content};
    let html = this.state.bodyHtml;

    let mentionState = 0;
    content.keywords = [];
    let text = '';

    log(LogLevel.UI_DATA_LOAD, "BoardContentEdit:onPreview: before ", html);


    let a:any = parse(html, {replace: (domNode) => {
      console.log(domNode);
      if(domNode.type == "tag" && domNode.name=="span" && domNode.attribs.class =="mention"){
        mentionState = 1;
        //add keywords
        let containKeyword = false;
        let type = parseInt(domNode.attribs["data-type"]);
        let id = parseInt(domNode.attribs["data-id"]);
        for(let i = 0; i<content.keywords.length; i++){
          if(content.keywords[i].type == type && content.keywords[i].id == id){
            containKeyword = true;
            break;
          }
        }

        if(!containKeyword){
          let text = "";
          if(domNode.children.length > 1 && domNode.children[1].name == 'span'){
            if(domNode.children[1].children){
              for(let i=0; i<domNode.children[1].children.length; i++){
                if(domNode.children[1].children[i].type == "text"){
                  text = domNode.children[1].children[i].data;
                  log(LogLevel.UI_DATA_LOAD, "transform.keyword mention 2", domNode.children[1].children[i], text );
                  break;
                }
              }
            }
          }
          content.keywords.push({id ,type, text})
        }
        // console.log("1");
      }
      else if(domNode.type == "tag" && domNode.name=="br"){
        text += "\n";
        mentionState = 0;
      }
      else if(domNode.type == "text") {
        let newText = domNode.data;
        if(mentionState == 1 && newText.trim().length){
          // console.log("2", newText.length);
          mentionState = 2;
        }
        else if(mentionState == 2 && newText.trim().length) {
          // console.log("0");
          mentionState = 0;
          if(newText.startsWith(" "))
            newText = newText.substring(1);
        }
        
        text += newText;
      }
      return domNode;
    }});
    html = ReactDOMServer.renderToStaticMarkup(a);
    html = html.substring(5, html.length-6);
    html = html.replace(/ style="user-select: auto;"/gi,'');
    html = html.replace(/ style="user-select:auto"/gi,'');
    html = html.replace(/<div><br>/gi,'');
    html = html.replace(/<div>/gi,'');
    html = html.replace(/<\/div>/gi,'<br/>');
    html = html.replace(/<br>/gi,'<br/>');
    
    content.bodyText = text;
    content.bodyHtml = html;
    content.subject = this.state.title;
    log(LogLevel.UI_DATA_LOAD, "BoardContentEdit:onPreview:", content);
    this.setState({content:content});
  }

  handleChange = (value, delta, source, editor) => {
    this.setState({ bodyHtml: value})
    console.log("handleChange", value, delta, source, editor);
  }

  

  handleChangeSelection = (range, source, editor) => {
    let str='';
    if(range){
      if(range.length){
        str = editor.getText(range.index, range.length)
        this.setState({selected:str, range:range});
      }else{
        this.setState({selected:"", range:null});
      }
    }
    // str = Quill.getText();
    console.log("handleChangeSelection", range, source, str);
  } 

  onTitleChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, "BoardContentEdit:onTitleChange", e);
    let title = e.detail.value;
    this.setState({title: title});
  }

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, "BoardContentEdit:onSearchChange", e);
    let keywords = e.detail.value;
    this.setState({selected: keywords});
    this.fetchKeywords();
  }

  fetchKeywords = () => {
    if(this.state.selected){
      log(LogLevel.UI_DATA_LOAD, "BoardContentEdit:fetchKeywords", this.state.selected);
      fetchAPI(API.MEDICINE_DICTIONARY, '', {keyword:this.state.selected, type:0}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          if(this.state.selected === result.query){
            this.setState({keywords:result.data});
          }
        }
      });
    }else {
      this.setState({keywords:[]});
    }
  }

  onKeywordSelect = (item:Keyword) => {
    log(LogLevel.UI_EVENT, "BoardContentEdit:onKeywordSelect", item);
    let range = this.state.range;
    if(range){
      let text = this.reactQuillRef.getEditor().getText(range.index, range.length);
      this.reactQuillRef.getEditor().deleteText(range.index, range.length);
      // this.reactQuillRef.getEditor().insertEmbed(range.index, 'keywords', {text:text, id:item.id, type:item.type});
      let keyword : any = item;
      keyword.denotationChar = ''
      keyword.value = text;
      log(LogLevel.UI_EVENT, "BoardContentEdit:onKeywordSelect", keyword);

      this.reactQuillRef.getEditor().insertEmbed(range.index, 'mention', keyword );
      this.reactQuillRef.getEditor().insertText(range.index + 1, ' ');
      this.reactQuillRef.getEditor().setSelection(range.index + 2);
    }
    this.setState({selected:'', keywords:[], range:null, selectedKeywords:[...this.state.selectedKeywords, item]})
    
    // this.reactQuillRef.getEditor().insertEmbed(range.index, 'variable', "-" + this.state.selectedStr + "-");
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "BaordContentEdit:render", this.state.content);

    if(this.state.content){
      let toolbar = (
        <div id="toolbar">
          <button className="ql-bold"/>
          <button className="ql-underline"/>
          <button className="ql-blockquote"/>
          <button className="ql-color" value= "blue"/>
          <button className="ql-background" value= "yellow"/>                
        </div>
      );

      return (
        <div className="admin-content-manage-container">
          <IonInput placeholder="제목" value={this.state.title} onIonChange={this.onTitleChange}/>
          {toolbar}
          <ReactQuill
            ref={(el) => {this.reactQuillRef = el}}
            theme={'snow'}
            onChange={this.handleChange}
            onChangeSelection={this.handleChangeSelection}
            value={this.state.bodyHtml}
            modules={BaordContentEdit.modules}
            formats={BaordContentEdit.formats}
            // toolbar={false}
            placeholder="test"
          />
          <IonInput placeholder="키워드 검색" value={this.state.selected} onIonChange={this.onSearchChange}/>
          <IonList class="admin-column">
            { this.state.keywords.map((item:Keyword, index: number) => (
              <div key={index.toString()} className="admin-column" onClick={() => this.onKeywordSelect(item)}>
                <div className="admin-row"> 
                  <div className="admin-grow">{item.text}</div>
                  <div> {keywordTypeName[item.type]}</div>
                </div>
              </div>
            ))}              
          </IonList>
          <div className="admin-content-manage-button-container">
            <IonButton color="admin-content-manage-cancel-button" onClick={this.props.onCancel}>
              취소
            </IonButton>     
            <IonButton color="admin-content-manage-button" onClick={this.onPreview}>
              미리보기
            </IonButton>     
            <IonButton color="admin-content-manage-button" onClick={this.onDone}>
              저장
            </IonButton>  
            <IonButton color="admin-content-delete-button" onClick={this.onDelete}>
              삭제 {(this.props.content&&this.props.content.deletedAt)?"취소":""}
            </IonButton>  
          </div>   
          <BoardListItem posting={this.state.content}/>
          <ViewerBody posting={this.state.content}/>   

        </div>
      );

    }
    return null;
  }
}

export default BaordContentEdit;