import { UserInfo } from "./Model.User";

export enum  GamificationPeriodicReportType{
    NONE = 0,
    HELP = 1,
    ANSWER_REPORT = 2,
}

export interface GamificationPeriodicReport{
    type?: GamificationPeriodicReportType,
    startDate?: string;
    endDate?: string;
    intValues?: number[];
    userInfoList?: UserInfo[];
}
