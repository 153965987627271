import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { COLOR_SYSTEM } from '../../design/design-system'
interface ContainerProps {
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.div<ContainerProps>`
  width: 100%;
  min-height: 88px;
  padding: 12px 20px 32px 20px;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  backdrop-filter: blur(6px);
  ${({ customStyle }) => customStyle};
`

export interface Props {
  customStyle?: React.CSSProperties;
}

const Footer: FC<Props> = ({children, customStyle }) => {
  return (
    <Container customStyle={customStyle}>
        {children}
    </Container>
  )
}

export default Footer
