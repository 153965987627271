import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

interface LocationBottomSheetModalWrapperProps {
  active?: boolean;
  address?: string;
}

const LocationBottomSheetModalWrapper = styled.div<LocationBottomSheetModalWrapperProps>``;

const Header = styled.header`
  padding-left: 12px;
`;

const BottomSheetMain = styled.main`
  height: calc(100% - 200px);
`;

const MainNav = styled.section`
  padding: 0 20px;
  margin: 40px 0 0 0;

  ul {
    width: 100%;
  }
`;

const DistrictContainer = styled.section`
  display: flex;
  margin: 24px 24px 0 24px;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  border-radius: 8px;
  height: calc(100% - 80px);
`;

const Left = styled.article`
  flex: 1;
  border-right: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
`;

const Right = styled.article`
  flex: 1;
`;

const ItemList = styled.ul`
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.li<{ selected: boolean }>`
  padding: 8.5px 0 8.5px 20px;
  background-color: ${({ selected }) => {
    if (selected) {
      return COLOR_SYSTEM.get("Skyblue")[10];
    }
  }};
  cursor: pointer;
`;

const DistanceContainer = styled.div<LocationBottomSheetModalWrapperProps>`
  width: 80%;
  height: calc(100% - 40px);
  position: relative;
  overflow-y: ${({ address }) => {
    if (!address) return "scroll";
  }};
  margin: 0 auto;
`;

const NoAddress = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  & > * + * {
    margin-top: 24px;
  }

  h2 {
    margin: 0;
  }

  button {
    width: 100%;
  }

  @media screen and (max-height: 650px) {
    top: 0;
    transform: translate(-50%, 0%);
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const DistanceControlBox = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-height: 600px) {
    height: 100%;
    top: 0;
    transform: translate(-50%, 0%);
    padding-top: 20px;
    padding-bottom: 40px;
    overflow-y: scroll;
  }
`;

const DistanceTooltip = styled.div`
  width: 100%;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  padding: 24px;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 20px;
  overflow-y: scroll;
`;

const WorkingTypeList = styled.ul`
  min-height: 340px;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 40px 20px 0 20px;

  & > * + * {
    margin-top: 20px;
  }
`;

const WorkingType = styled.li<{ selected: boolean }>`
  height: 60px;
  padding: 8px 16px;
  border: ${({ selected }) =>
    `1px solid ${selected ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[50]}`};
  border-radius: 8px;
  background-color: ${({ selected }) => {
    if (selected) return COLOR_SYSTEM.get("Skyblue")[10];
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WorkingTypeTitle = styled.div`
  display: flex;

  & > * + * {
    margin-left: 12px;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding: 20px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 100;

  button:last-child {
    flex: 1;
  }

  & > * + * {
    margin-left: 20px;
  }
`;

export {
  LocationBottomSheetModalWrapper,
  Header,
  BottomSheetMain,
  MainNav,
  DistrictContainer,
  Left,
  Right,
  ItemList,
  Item,
  DistanceContainer,
  NoAddress,
  DistanceControlBox,
  DistanceTooltip,
  WorkingTypeList,
  WorkingType,
  WorkingTypeTitle,
  Footer,
};
