import React, { Component } from "react";
import "./../Admin.scss";
import "./../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
} from "@ionic/react";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
} from "../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { BoardContent } from "../../models/Model.Board";
import Truncate from "react-truncate";
import {
  BannerInfo,
  EMPTY_BANNER,
  BannerType,
} from "../../models/Model.Banner";
import ToggleSwitch from "../../component/atom/ToggleSwitch";

const AdminAPI = {
  BANNER_LIST: {
    method: "GET",
    path: "/admin/banner",
    contentType: "application/json",
  },
};

type Props = {
  onSelect: (banner: BannerInfo) => void;
};

type State = {
  banners: BannerInfo[];
  search: string;
  category: BannerType;
  isActive: boolean;
};

class BannerSelect extends Component<Props, State> {
  state = {
    banners: [],
    search: "",
    category: BannerType.BOARD,
    isActive: getGlobal(GlobalKey.ADMIN_BANNER_OPTION_ALIVE, true),
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({ search: keywords });
    this.fetchData(this.state.category);
  };

  onSelect = (banner: BannerInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", banner);
    this.props.onSelect(banner);
  };

  onChange = () => {
    this.fetchData(this.state.category);
  };

  fetchData = async (category = BannerType.BOARD) => {
    let data: any = { type: category, full: true };
    if (this.state.isActive) data.activeOnly = true;
    let result = await fetchAPI(
      AdminAPI.BANNER_LIST,
      "",
      data,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({ banners: result.data ? result.data : [] });
    } else {
      this.setState({ banners: [] });
    }
  };

  onAdd = () => {
    this.props.onSelect({ ...EMPTY_BANNER, type: this.state.category });
  };

  changeCategory = (category: BannerType) => {
    this.setState({ category });
    this.fetchData(category);
  };

  changeAlive = () => {
    this.setState({ isActive: !this.state.isActive });
    setGlobal(GlobalKey.ADMIN_BANNER_OPTION_ALIVE, !this.state.isActive, true);
    setTimeout(() => {
      this.fetchData(this.state.category);
    }, 100);
  };

  render() {
    return (
      <div className="admin-container">
        <div className="admin-board-list-container">
          <div className="common-container-row-wrap admin-margin-bottom admin-margin-top">
            <div
              className={
                "admin-toggle" +
                (this.state.category == BannerType.BOARD
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => this.changeCategory(BannerType.BOARD)}
            >
              게시판 배너
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.category == BannerType.LECTURE
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => this.changeCategory(BannerType.LECTURE)}
            >
              강좌 Featured
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.category == BannerType.PARTNERS
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => this.changeCategory(BannerType.PARTNERS)}
            >
              파트너스
            </div>
            <div
              className="common-flex-row common-flex-align-center"
              onClick={this.changeAlive}
            >
              <div>활성화only</div>
              <ToggleSwitch checked={this.state.isActive} />
            </div>
          </div>
          <IonList>
            <IonButton expand="full" onClick={this.onAdd}>
              <IonIcon name="add" />
            </IonButton>
            {this.state.banners.map((item: BannerInfo, index: number) => {
              let startDate = new Date(item.startAt);
              startDate.setHours(startDate.getHours() + 9);
              let endDate = new Date(item.endAt);
              endDate.setHours(startDate.getHours() + 9);
              return (
                <div
                  key={index.toString()}
                  className="admin-board-list-item-container"
                  onClick={() => this.onSelect(item)}
                >
                  <div className="admin-row">
                    <div className="admin-grow">
                      {item.id} - {item.name}
                      {item.deletedAt ? "[삭제]" : ""}
                    </div>
                  </div>
                  <div>
                    &nbsp;&nbsp;&nbsp;P{item.priority} -{" "}
                    {startDate.toISOString().substring(0, 10)}~
                    {endDate.toISOString().substring(0, 10)}
                  </div>
                </div>
              );
            })}
          </IonList>
        </div>
      </div>
    );
  }
}

export default BannerSelect;
