import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import JobPostRegistrationTemplate from "../../components/templates/JobPostRegistration";
import { actions, RootState } from "../../store";
import queryString from "query-string";
import { JobListType } from "../../store/jobpost/types";
import {
  ApplyMethod,
  applyMethodCoupler,
  JobOfferInfo,
  JobOfferName,
  JobOfferStatus,
  JobPostRegistrationForm,
  machineNames,
  softwareNames,
  welfareTagsName,
} from "../../models/Model.JobPost";
import { convertMultiSelectToRequestData, convertWorkdayArrToStr } from "../../utils/JobPostUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "../../API.json";
import LoadJobPostHistory from "../../components/organisms/LoadJobPostHistory";
import { log, LogLevel } from "../../utils/LogUtil";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import moment from "moment";

type PageMode = "Write" | "Load" | "Preview";

const JobPostEdit: React.FC<RouteComponentProps> = ({ location, history }) => {
  let loading = false;

  const query = queryString.parse(location.search);
  const jobPostId = Number(query.id);
  const me = useSelector((state: RootState) => state.user.me);
  const jobPost: JobOfferInfo = useSelector((state: RootState) => state.jobpost.offers[jobPostId]);
  const myHistory = useSelector((state: RootState) => state.jobpost.lists[JobListType.OFFER | JobListType.HISTORY]);
  const offers = useSelector((state: RootState) => state.jobpost.offers);
  const dispatch = useDispatch();
  const [mode, setMode] = useState<PageMode>("Write");

  const handleReuse = (offer: JobOfferInfo) => {
    log(LogLevel.UI_EXCEPTION, "JobPostRegistration.handleReuse", offer);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_MODIFY", "구인구직_약국_공고_작성_재활용선택", {});

    const workDayOfWeek = new Array(7).fill(false);
    if (offer.workDayOfWeek) {
      if (offer.workDayOfWeek.includes("월")) workDayOfWeek[0] = true;
      if (offer.workDayOfWeek.includes("화")) workDayOfWeek[1] = true;
      if (offer.workDayOfWeek.includes("수")) workDayOfWeek[2] = true;
      if (offer.workDayOfWeek.includes("목")) workDayOfWeek[3] = true;
      if (offer.workDayOfWeek.includes("금")) workDayOfWeek[4] = true;
      if (offer.workDayOfWeek.includes("토")) workDayOfWeek[5] = true;
      if (offer.workDayOfWeek.includes("일")) workDayOfWeek[6] = true;
    }

    const machine: boolean[] = new Array(5).fill(false);
    machineNames.forEach((machineName, index) => {
      if (offer.pharmacy.machine.split("▒")[0].includes(machineName)) machine[index] = true;
    });
    if (offer.pharmacy.machine.split("▒")[1]) machine[machine.length - 1] = true;

    const welfare: boolean[] = new Array(12).fill(false);
    welfareTagsName.forEach((welfareTag, index) => {
      if (offer.welfare.split("▒")[0].includes(welfareTag)) welfare[index] = true;
    });

    setForm({
      workType: offer.workType,
      title: offer.title,
      workDayOfWeek: workDayOfWeek,
      canNegoWorkDayOfWeek: offer.canNegoWorkDayOfWeek,
      workTime: offer.workTime,
      canNegoWorkTime: offer.canNegoWorkTime,
      salaryType: offer.salaryType,
      taxType: offer.taxType,
      salaryRangeLow: (offer.salaryRangeLow * 10000).toLocaleString(),
      salaryRangeHigh: (offer.salaryRangeHigh * 10000).toLocaleString(),
      canNegoSalary: offer.canNegoSalary,
      needLicense: offer.needLicense,
      applyMethod: offer.applyMethod || ApplyMethod.ALL,
      phonePersonInCharge: offer.phonePersonInCharge || "",
      message: offer.message,
      pharmacistCnt: offer.pharmacy.pharmacistCnt.toString() || "0",
      employeeCnt: offer.pharmacy.employeeCnt.toString() || "0",
      welfare: welfare,
      welfareDetail: offer.welfare.split("▒")[1] || "",
      sw: softwareNames.includes(offer.pharmacy.sw) ? offer.pharmacy.sw : "기타",
      swEtc: softwareNames.includes(offer.pharmacy.sw) ? "" : offer.pharmacy.sw,
      atc: offer.pharmacy.atc,
      machine: machine,
      machineEtc: offer.pharmacy.machine.split("▒")[1] || "",
      name: jobPost.ownerId === me.id ? me.workPlaceName : offer.pharmacy.name,
      phone: me.phone,
      region: jobPost.ownerId === me.id ? me.workPlaceRegion : offer.pharmacy.region,
      address: jobPost.ownerId === me.id ? me.workPlaceAddress : offer.pharmacy.address,
      position: jobPost.ownerId === me.id ? me.workPlacePosition : offer.pharmacy.position,
    });

    setMode("Write");
  };

  const setOriginWorkDayOfWeek = () => {
    const workDayOfWeek = new Array(7).fill(false);
    if (jobPost.workDayOfWeek.includes("월")) workDayOfWeek[0] = true;
    if (jobPost.workDayOfWeek.includes("화")) workDayOfWeek[1] = true;
    if (jobPost.workDayOfWeek.includes("수")) workDayOfWeek[2] = true;
    if (jobPost.workDayOfWeek.includes("목")) workDayOfWeek[3] = true;
    if (jobPost.workDayOfWeek.includes("금")) workDayOfWeek[4] = true;
    if (jobPost.workDayOfWeek.includes("토")) workDayOfWeek[5] = true;
    if (jobPost.workDayOfWeek.includes("일")) workDayOfWeek[6] = true;

    return workDayOfWeek;
  };

  const setMachine = () => {
    const isSelected: boolean[] = new Array(5).fill(false);

    machineNames.forEach((machineName, index) => {
      if (jobPost.pharmacy.machine.split("▒")[0].includes(machineName)) isSelected[index] = true;
    });
    if (jobPost.pharmacy.machine.split("▒")[1]) isSelected[isSelected.length - 1] = true;

    return isSelected;
  };

  const setWelfare = () => {
    const isSelected: boolean[] = new Array(12).fill(false);

    welfareTagsName.forEach((welfareName, index) => {
      if (jobPost.welfare.split("▒")[0].includes(welfareName)) isSelected[index] = true;
    });

    return isSelected;
  };

  const [form, setForm] = useState<JobPostRegistrationForm>({
    workType: jobPost.workType,
    title: jobPost.title,
    workDayOfWeek: setOriginWorkDayOfWeek(),
    canNegoWorkDayOfWeek: jobPost.canNegoWorkDayOfWeek,
    workTime: jobPost.workTime,
    canNegoWorkTime: jobPost.canNegoWorkTime,
    salaryType: jobPost.salaryType,
    taxType: jobPost.taxType,
    salaryRangeLow: (jobPost.salaryRangeLow * 10000).toLocaleString(),
    salaryRangeHigh: (jobPost.salaryRangeHigh * 10000).toLocaleString(),
    canNegoSalary: jobPost.canNegoSalary,
    needLicense: jobPost.needLicense,
    applyMethod: jobPost.applyMethod || ApplyMethod.ALL,
    phonePersonInCharge: jobPost.phonePersonInCharge || "",
    message: jobPost.message,
    pharmacistCnt: jobPost.pharmacy.pharmacistCnt >= 0 ? jobPost.pharmacy.pharmacistCnt.toString() : "",
    employeeCnt: jobPost.pharmacy.employeeCnt >= 0 ? jobPost.pharmacy.employeeCnt.toString() : "",
    welfare: setWelfare(),
    welfareDetail: jobPost.welfare.split("▒")[1] || "",
    sw: softwareNames.includes(jobPost.pharmacy.sw) ? jobPost.pharmacy.sw : "기타",
    swEtc: softwareNames.includes(jobPost.pharmacy.sw) ? "" : jobPost.pharmacy.sw,
    atc: jobPost.pharmacy.atc,
    machine: setMachine(),
    machineEtc: jobPost.pharmacy.machine.split("▒")[1] || "",
    name: jobPost.ownerId === me.id ? me.workPlaceName : jobPost.pharmacy.name,
    phone: me.phone,
    region: jobPost.ownerId === me.id ? me.workPlaceRegion : jobPost.pharmacy.region,
    address: jobPost.ownerId === me.id ? me.workPlaceAddress : jobPost.pharmacy.address,
    position: jobPost.ownerId === me.id ? me.workPlacePosition : jobPost.pharmacy.position,
  });

  // JobPost 수정
  const updateJobPost = (form: JobPostRegistrationForm, postId: number) => {
    const requestBodyData: JobOfferInfo = {
      id: postId,
      title: form.title.trim(),
      status: JobOfferStatus.POSTED,
      pharmacy: {
        name: form.name,
        region: form.region,
        address: form.address,
        phone: form.phone,
        position: form.position,
        pharmacistCnt: Number(form.pharmacistCnt),
        employeeCnt: Number(form.employeeCnt),
        sw: form.sw === "기타" ? form.swEtc.trim() : form.sw,
        atc: form.atc,
        machine: convertMultiSelectToRequestData(form.machine, form.machineEtc, machineNames),
      },
      owner: jobPost.owner,
      workType: form.workType,
      workDayOfWeek: convertWorkdayArrToStr(form.workDayOfWeek),
      canNegoWorkDayOfWeek: form.canNegoWorkDayOfWeek,
      workTime: form.workTime.trim(),
      canNegoWorkTime: form.canNegoWorkTime,
      needLicense: form.needLicense,
      applyMethod: form.applyMethod === ApplyMethod.ALL ? ApplyMethod.None : form.applyMethod,
      phonePersonInCharge: form.phonePersonInCharge,
      salaryType: form.salaryType,
      taxType: form.taxType,
      salaryRangeLow: Number(form.salaryRangeLow.replace(/,/g, "")) / 10000,
      salaryRangeHigh: Number(form.salaryRangeHigh.replace(/,/g, "")) / 10000,
      canNegoSalary: form.canNegoSalary,
      welfare: convertMultiSelectToRequestData(form.welfare, form.welfareDetail, welfareTagsName),
      message: form.message.trim(),
    };

    fetchAPI(API.JOBPOST_OFFER_UPDATE, "", null, requestBodyData, getGlobal(GlobalKey.TOKEN))
      .then((result) => {
        if (result && !result.error && result.data) {
          requestBodyData.id = result.data;
          dispatch(actions.jobpost.updateOffer(requestBodyData));
          dispatch(actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.ALL));
          dispatch(actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.HISTORY));

          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_UPDATE_OFFER", "구인구직_공고_수정페이지_수정하기", {
            ownerId: result.data.ownerId,
            offerId: result.data.id,
            workType: JobOfferName[result.data.workType],
            modifiedAt: moment().format("LLL"),
            applyMethod: applyMethodCoupler[result.data.applyMethod],
          });
        } else {
          dispatch(actions.jobpost.updateOffer(requestBodyData));
        }
      })
      .catch((e) => {
        // log(LogLevel.UI_EXCEPTION, "LocalDealJobPostOfferComposer:exception: ", e);
      });

    history.goBack();
  };

  // 이전 공고 더 불러오기
  const getMorePosts = () => {
    if (loading) return;
    loading = true;
    dispatch(actions.jobpost.loadList(JobListType.OFFER | JobListType.HISTORY));
    loading = false;
  };

  useEffect(() => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_MODIFY", "구인구직_약국_공고_수정_진입", {});

    return () => {};
  }, []);

  useEffect(() => {
    if (mode == "Load") {
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "JOBPOST_MODIFY", "구인구직_약국_공고_작성_재활용", {});
    }

    return () => {};
  }, [mode]);

  if (!jobPost) return null;

  return (
    <>
      {mode === "Load" && (
        <LoadJobPostHistory
          myHistory={myHistory}
          onClose={() => setMode("Write")}
          offers={offers}
          onSelect={handleReuse}
          onGetMorePosts={getMorePosts}
        />
      )}
      {mode === "Write" && (
        <JobPostRegistrationTemplate
          edit
          mode={mode}
          form={form}
          onChangeForm={setForm}
          onChangeMode={setMode}
          myHistory={myHistory}
          onUpdateJobPost={updateJobPost}
          jobPost={jobPost}
        />
      )}
    </>
  );
};

export default withRouter(JobPostEdit);
