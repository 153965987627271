import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AllInOneIntroductionTemplate from "../../components/templates/AllInOneIntroduction";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import * as API from "../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobListType } from "../../store/jobpost/types";
import ABTestUtil from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";
import { MY_ADDRESS } from "../../config.json";
import Spinner from "../../components/atoms/Spinner";
import { UIServiceType } from "../../store/ui/types";
import { BoardContent } from "../../models/Model.Board";
import { callPhone } from "../../utils/UrlSchemeUtil";
import useBottomSheet from "../../hooks/useBottomSheet";
import ShareList from "../../components/organisms/ShareList";
import useShare from "../../hooks/useShare";
import { Flex } from "../../components/atoms/Layout";
import { COLOR_SYSTEM } from "../../components/design/design-system";
import Text from "../../components/atoms/Text";
import Icon from "../../components/atoms/Icon";

const windowAny: any = window;

const AllInOneIntroduction: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  try {
    const backKeyControl = useSelector(
      (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
    );

    useEffect(() => {
      if (backKeyControl)
        backKeyControl.setListener(() => {
          log(LogLevel.UI_LIFECYCLE, "AllInOneIntroduction:back key callback");
          goBack();
        });
    }, []);

    const goBack = () => {
      history.goBack();
    };

    const onRecommendPartner = () => {
      try {
        log(LogLevel.UI_ACTION, "AllInOneHome:onRecommendPartner");
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PARTNERS_RECOMMEND_PARTNER",
          "약사파트너스_설명페이지_파트너_추천",
          {}
        );
        window.open(
          "https://docs.google.com/forms/d/e/1FAIpQLSfnkh7dx1eNSsCQhEVWXTge1ahXU4LLK9uei-flw__kgG4OtQ/viewform",
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
      } catch (err) {
        console.error(err);
      }
    };

    return (
      <AllInOneIntroductionTemplate
        onGoBack={goBack}
        onRecommendPartner={onRecommendPartner}
      />
    );
  } catch (error) {
    console.error(error.message);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOneIntroduction",
      errorMessage: error,
    });
  }
};

export default withRouter(AllInOneIntroduction);
