import React, { useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Textarea from 'react-textarea-autosize';
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from '../Text';

interface WrapperProps {}
const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  background: ${COLOR.Gray8};
  &:focus-within{
    background: ${COLOR.Gray7};
  }
  color: ${COLOR.Secondary};
  position: relative;


  input {
    padding: 0px;

    width: 100%;
    outline: none;
    outline-style: none;
    background: none;
    color: ${COLOR.Secondary};

    appearance: none;
    border: none;

    &::placeholder {
      opacity: 0.2;
    }
    &::-webkit-input-placeholder {
      opacity: 0.2;
    }
  }

  textarea {
    padding: 0px;
    width: 100%;
    outline: none;
    outline-style: none;
    background: none;
    color: #${COLOR.Secondary};

    appearance: none;
    border: none;
    &::placeholder {
      opacity: 0.2;
    }
    &::-webkit-input-placeholder {
      opacity: 0.2;
    }
  }

  .input-prefix {
    margin-right: 12px;
  }

  .input-postfix {
    margin-left: 12px;
  }

  .input-placeholder{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    padding: 12px 16px;
  }

  ${TYPOGRAPY["Body1"]}
  
`

export interface Props {
  className?: string
  type?: 'text' | 'tel' | 'email' | 'password' | 'number'
  placeholder?: string
  maxLength?: number
  minLength?: number
  value?: string
  required?: boolean
  disabled?: boolean
  allowMultiline?: boolean
  postfix?: string
  prefix?: string
  minRows?: number
  maxRows?: number
  id?: string
  placeholderElement?: any
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const Input: React.FC<Props> = ({
  className,
  type = 'text',
  id,
  placeholder,
  placeholderElement,
  maxLength,
  minLength,
  value,
  required,
  disabled,
  onBlur,
  onChange,
  onFocus,
  allowMultiline = false,
  postfix,
  prefix,
  minRows = 3,
  maxRows = 8,
}) => {
  const inputEl = useRef(null);
  return (
    <Wrapper className={className}>
      {allowMultiline?(
        <Textarea 
          inputRef={inputEl} 
          minRows={minRows<maxRows?minRows:maxRows} 
          maxRows={minRows<maxRows?maxRows:minRows} 
          maxLength={maxLength}
          minLength={minLength}
          type={type}
          name={id}
          id={id}
          placeholder={placeholder}
          className="input-textarea" 
          onBlur={onBlur}
          onChange={onChange} 
          onFocus={onFocus}
          value={value} 
          onClick={(e)=>{e.stopPropagation();}}
        />
      ):(
        <div className="common-container-row common-flex-align-center">
          {prefix &&
            <Text className="input-prefix" color="Secondary" type="Body1">{prefix}</Text>
          }
          <input 
            className="common-flex-grow" 
            ref={inputEl} 
            maxLength={maxLength}
            minLength={minLength}
            type={type}
            name={id}
            id={id}
            placeholder={placeholder}
            value={value}
            required={required}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onClick={(e)=>{e.stopPropagation();}}
          />
          {postfix &&
            <Text className="input-postfix" color="Secondary" type="Body1">{postfix}</Text>
          }
        </div>
      )}

      {(placeholderElement && !value) && 
        <div className="input-placeholder"  onClick={() => {if(inputEl.current) inputEl.current.focus()}}>
          {placeholderElement}
        </div>
      }
    </Wrapper>

  )
}

export default Input
