
import * as searches from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { SearchState, SearchActionTypes } from "./types";
import {log, LogLevel} from '../../utils/LogUtil'

const defaultState: SearchState = {
  searchText: '',
  loading: false,
  total : 0,
  searched: [],
  hotKeywords: [],
  suggestedKeywords: [],
  keywordsResults: {},
  order: "score",
};

export default (state = defaultState, action: ActionType<typeof searches>): SearchState => {
  // log(LogLevel.REDUX_SEARCH, "Search Reducer. ", action)
  switch (action.type) {
  case getType(searches.setSearchText):
    log(LogLevel.REDUX_SEARCH, "Reducer. searches.setSearchText", action.payload)
    if(action.payload == state.searchText)
      return state;
    else 
      return {
        ...state,
        searchText: action.payload,
        searched: [],
      };

  case getType(searches.resetSearchText):
    return {
      ...state,
      searchText: '',
      loading: false,
      total:0,
      searched: [],
    };
  case getType(searches.fetchSearch.request):
    return {
      ...state,
      loading: true,
    }
  case getType(searches.fetchSearch.success):
    // if(state.searchText != action.payload.options.search){
    //   log(LogLevel.REDUX_SEARCH, "Reducer. searches.fetchSearch.success no change", action.payload.options.search, state)
    //   return state;
    // }
    console.log("total", action.payload.total)
    console.log("searched", state.searched, action.payload.contents)
    const rvalue = {
      ...state,
      loading: false,
      total: action.payload.total,
      searched: [...state.searched, ...action.payload.contents],
    }

    log(LogLevel.REDUX_SEARCH, "Reducer. searches.fetchSearch.success", action.payload, state, rvalue)
    console.log("rvalue", rvalue)

    return rvalue; 
  case getType(searches.fetchSearch.failure):
    log(LogLevel.REDUX_SEARCH, "Reducer. searches.fetchSearch.failure", action.payload.name, action.payload.message)

    return {
      ...state,
      loading: false,
      searched: [],
    }
  case getType(searches.fetchSearchKeywords.success):
    log(LogLevel.REDUX_SEARCH, "Reducer. searches.fetchSearchKeywords.success", action.payload, state)
    return {
      ...state,
      ...action.payload,
    }
  case getType(searches.fetchSearchKeywordRelatives.success):
    log(LogLevel.REDUX_SEARCH, "Reducer. searches.fetchSearchKeywordRelatives.success", action.payload, state)
    if(action.payload && action.payload.length){
      let keywordsResults = {...state.keywordsResults};
      keywordsResults[action.payload[0].keyword] = action.payload;
  
      return {
        ...state,
        keywordsResults,
      }
    }
  case getType(searches.sortSearch):
    log(LogLevel.REDUX_SEARCH, "Reducer. sortSearch", action.payload, state)
    if(action.payload){
      return {
        ...state,
        loading: false,
        total: 0,
        searched: [],
        order: action.payload,
      }
    }
  default:
    return state;
  }
}
