

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonCheckbox, IonIcon, IonToggle } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import { timeout, async } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey, setGlobal } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import { UIPopupType } from '../../../store/ui/types';

const API = {        
  "USER_CHURN_STATISTICS" : {"method":"POST", "path":"/admin/user/churn/statistics", "contentType":"application/json"},
  "USER_GET" : {"method":"GET", "path":"/admin/user/user", "contentType":"application/json"},
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onSelect?: (user:UserInfo) => void;
};

type State = {
  fold: boolean;
  loading: boolean;
  date: string;
  dateWarn : boolean;
  accumulated: boolean;
  returned: boolean,
  statistics?: UserChurnStatistics,
}

interface UserChurnStatistics{
  date?:string,
  accumulated?:boolean,
  returned?: boolean,
  churn?: UserChurnStatisticsItem[],
}

interface UserChurnStatisticsItem{
  id?: number,
  email?: string,
  name?: string,
  nickname?: string,
  phone?: string,
  createdAt?: string,
  lastUsedAt?: number,    // 0 : never used, 1 month
  willReturn?: boolean,
}


class UserChurnStatisticsView extends Component<Props, State> {

  constructor(props:Props){
    super(props);
    log(LogLevel.UI_DATA_LOAD, "UserChurnStatisticsView constructor");
    let date = new Date(Date.now() + 9*60*60*1000);
    date = new Date(date.getFullYear(), date.getMonth(), 1, 9);

    let showOptions = getGlobal(GlobalKey.ADMIN_STATISTICS_USER_SHOW_OPTION, true);

    this.state = {
      fold: true,
      loading: false,
      date: date.toISOString().substring(0,10),
      dateWarn : false,
      returned : true,
      accumulated: false,
    };
  }

  componentWillUpdate(nextProps, nextState){
  }

  fetchStatistics = async () => {
    if(this.state.loading){
      this.props.toastPopup.show("로딩중입니다.");
      return;
    }
    if(this.state.dateWarn){
      this.props.toastPopup.show("검색옵션을 확인해주세요.");
      return;
    }

    let request:UserChurnStatistics = {};
    request.date = this.state.date;
    request.returned = this.state.returned;
    request.accumulated = this.state.accumulated;

    fetchAPI(API.USER_CHURN_STATISTICS, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error && result.data){
        log(LogLevel.UI_DATA_LOAD, "UserChurnStatisticsView Fetch success", request, result);
        this.setState({statistics:result.data});
      }
      else{
        log(LogLevel.UI_EXCEPTION, "UserChurnStatisticsView Fetch failed", request, result);
      }
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "UserChurnStatisticsView Fetch excepted", request, e);
    })

  }

  onDateChange = (e) => {
    let date = e.target.value;
    let dateWarn = false;
    try{
      let dates = Date.parse(date);
      if(!dates){
        log(LogLevel.UI_EXCEPTION, "UserChurnStatisticsView:onDateStartChange", date);
        dateWarn = true;
      }else{
        log(LogLevel.UI_DATA_LOAD, "UserChurnStatisticsView:onDateStartChange", date, date);
      }
    }catch(err) {
      log(LogLevel.UI_EXCEPTION, "UserChurnStatisticsView:onDateStartChange", date, err);
      console.log(err);
      dateWarn = true;
    }
    this.setState({date, dateWarn});
  }

  setDate = (pid) => {
    let date = this.state.date;
    if(this.state.dateWarn){
      let newDate = new Date(Date.now() + 9*60*60*1000);
      newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1, 9);
      date = newDate.toISOString().substring(0,10)
    }

    if(pid == 0){
      let newDate = new Date(Date.now() + 9*60*60*1000);
      newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1, 9);
      date = newDate.toISOString().substring(0,10)
    }else if(pid>0 && pid <13){
      date = date.substring(0,5) + pid.toString().padStart(2, "0") + date.substring(7);
    }else if(pid > 2000 && pid < 3000){
      date = pid.toString() + date.substring(4);
    }

    this.setState({
      date,
      dateWarn : false,
    })
  }


  onDownload = () => {
    if(!this.state.statistics && !this.state.statistics.churn && !this.state.statistics.churn.length)
      return;

    let csv = "index,id,nikname,name,phone,createdAt,lastAccessed,cameback\r\n";
    
    csv += this.state.statistics.churn.map((item, index) => `${index+1},${item.id},"${item.nickname}","${item.name}","${item.phone}",${item.createdAt},${item.lastUsedAt},${item.willReturn}`).join("\r\n");
    csv += "\r\n";

    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
    pom.setAttribute('download', `User-Rank-Statistics-${this.state.statistics.date.replace(/-/ig, '')}-${this.state.statistics.accumulated?"Accumulated":"LastMonth"}-${(new Date()).toISOString().substring(0, 10).replace(/-/ig, '')}.csv`);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    }
    else {
        pom.click();
    }
  }

  onSelect = (id) => {
    log(LogLevel.UI_ACTION, "UserChurnStatisticsView:onSelect", id);
    fetchAPI(API.USER_GET, "", {id}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error && result.data){
        this.props.onSelect(result.data);
      }
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "UserChurnStatisticsView:onSelect Failed", e);
    });
  }

  renderTable = () => {
    if(!this.state.statistics || !this.state.statistics.churn)
      return;
    let returnLabel;
    if(this.state.statistics.returned)
      returnLabel = (
        <td className="admin-table-label-x">Returned</td>
      );
    let label = (
      <tr>
        <td className="admin-table-label-x">Index</td>
        <td className="admin-table-label-x">Id</td>
        <td className="admin-table-label-x">Nickname</td>
        <td className="admin-table-label-x">Name</td>
        <td className="admin-table-label-x">Phone</td>
        <td className="admin-table-label-x">CreatedAt</td>
        <td className="admin-table-label-x">LastAccessed</td>
        {returnLabel}
      </tr>
    );

    let data = this.state.statistics.churn.map((item, index, list) => {
        let valueLabel;
        if(this.state.statistics.returned)
          valueLabel = (
            <td className="admin-table-value">{item.willReturn?"O":"X"}</td>
          );
        return(
          <tr key={index.toString()} onClick={() => this.onSelect(item.id)}>
            <td className="admin-table-value">{(index+1).toString()}</td>
            <td className="admin-table-value">{item.id}</td>
            <td className="admin-table-value">{item.nickname}</td>
            <td className="admin-table-value">{item.name}</td>
            <td className="admin-table-value">{item.phone}</td>
            <td className="admin-table-value">{item.createdAt.substring(0,10)}</td>
            <td className="admin-table-value">{item.lastUsedAt}</td>
            {valueLabel}
          </tr>
        );
    });

    return(
      <div className="common-container">
        <div className="admin-container-scroll-x common-scroll admin-container-list-max-height">
          <table className="admin-table">
            <tbody>
              {label}
              {data}
            </tbody>
          </table>
        </div>
        <IonButton color="primary" onClick={this.onDownload}>
          다운로드
        </IonButton>
      </div>
    );
    

  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "UserChurnStatisticsView:render", this.props, this.state);

    if(this.state.fold){
      return (
        <div className="common-container">
          <div className="admin-full-button-block" onClick={() => this.setState({fold:false})}>
            <div className="common-flex-row">
              <div>Churn</div>
              <IonIcon name="arrow-down"/>
            </div>
          </div>
        </div> 

      );
    }

    return (
      <div className="common-container">     
        <div className="admin-full-button-block" onClick={() => this.setState({fold:true})}>
          <div className="common-flex-row">
            <div>Churn</div>
            <IonIcon name="arrow-up"/>
          </div>
        </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="admin-margin-bottom">Search Option</div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <input className={(this.state.dateWarn?"common-color-caution":"")} placeholder="시작일시" value={this.state.date} onChange={this.onDateChange}/>
                <div className="admin-text-button" onClick={() => this.setDate(0)}>오늘 </div>
                <div className="admin-text-button" onClick={() => this.setDate(2019)}>2019년 </div>
                <div className="admin-text-button" onClick={() => this.setDate(2020)}>2020년 </div>
                <div className="admin-text-button" onClick={() => this.setDate(2021)}>2021년 </div>
              </div>
              <div className="common-container-row-wrap admin-margin-bottom">
                <div className="admin-text-button" onClick={() => this.setDate(1)}>1월</div>
                <div className="admin-text-button" onClick={() => this.setDate(2)}>2월</div>
                <div className="admin-text-button" onClick={() => this.setDate(3)}>3월</div>
                <div className="admin-text-button" onClick={() => this.setDate(4)}>4월</div>
                <div className="admin-text-button" onClick={() => this.setDate(5)}>5월</div>
                <div className="admin-text-button" onClick={() => this.setDate(6)}>6월</div>
                <div className="admin-text-button" onClick={() => this.setDate(7)}>7월</div>
                <div className="admin-text-button" onClick={() => this.setDate(8)}>8월</div>
                <div className="admin-text-button" onClick={() => this.setDate(9)}>9월</div>
                <div className="admin-text-button" onClick={() => this.setDate(10)}>10월</div>
                <div className="admin-text-button" onClick={() => this.setDate(11)}>11월</div>
                <div className="admin-text-button" onClick={() => this.setDate(12)}>12월</div>
              </div>
              <div className="common-container-row-wrap admin-margin-bottom">
                  <div className="common-flex-row">
                    <IonCheckbox checked={this.state.accumulated} onIonChange={(e) => this.setState({accumulated:!this.state.accumulated})}/>
                    <div className="admin-margin-right">이전누적</div>
                  </div>
                  <div className="common-flex-row">
                    <IonCheckbox checked={this.state.returned} onIonChange={(e) => this.setState({accumulated:!this.state.returned})}/>
                    <div className="admin-margin-right">복귀여부</div>
                  </div>
              </div>
            </div>
          </div>
          <IonButton onClick={this.fetchStatistics} disabled={this.state.loading}>Load</IonButton>
        </div>
        {this.renderTable()}

      </div>
    );


  }
}


const mapStateToProps = (state: RootState) => ({
  toastPopup : state.ui.popups[UIPopupType.TOAST_POPUP]
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
}
export default connect(mapStateToProps, mapDispatchToProps)(UserChurnStatisticsView);