import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "../store";
import { AuthStatus } from "./../store/user/types";
import { log, LogLevel } from "../utils/LogUtil";
import { UserLevel } from "../models/Model.User";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "./ABTestUtil";
import { displayTabbar } from "./JobPostUtil";

const windowAny: any = window;

type Props = ReturnType<typeof mapStateToProps> & {
  component: any; //React.ComponentType<any>;
  path?: string | string[];
  location?: any;
  options?;
  test?: ABTestFeature;
  key?: string;
};

class PRoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    if (this.props.test && !ABTestUtil.isTest(this.props.test)) {
      log(
        LogLevel.UI_LIFECYCLE,
        "Private Routing : render not test",
        this.props.test,
        ABTestUtil.isTest(this.props.test)
      );
      return null;
    }
    const routeRender = (props: any) => {
      log(
        LogLevel.UI_LIFECYCLE,
        "Private Routing : routeRender",
        props,
        this.props.test,
        ABTestUtil.isTest(this.props.test)
      );
      switch (props.location.pathname) {
        case "/main/home":
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "HOME_START", "홈진입");
          break;
        case "/main/boards":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "BOARD_START",
            "게시판진입"
          );
          break;
        case "/main/search":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "SEARCH_START",
            "검색진입"
          );
          break;
        case "/main/user":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "PROFILE_START",
            "프로필진입"
          );
          break;
        case "/main/jobpost":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "JOBPOST_START",
            "구인구직진입"
          );
          break;
        case "/main/allinone":
          AnalyticsUtil.event(
            AnalyticsUtil.TYPE_ALL,
            "ALLINONE_START",
            "약사파트너스진입"
          );
          break;
      }

      if (this.props.user.authStatus == AuthStatus.AUTHENTICATED) {
        log(LogLevel.NONE, "Private Routing : logged in", props);
        return React.createElement(Component, {
          ...props,
          ...this.props.options,
        });
      } else if (this.props.user.authStatus == AuthStatus.REGISTERING) {
        log(LogLevel.NONE, "Private Routing : registering ", props);
        return (
          <Redirect
            to={{
              pathname: "/registering",
            }}
          />
        );
      }
      log(LogLevel.NONE, "Private Routing : not logged in", this.props);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from:
                this.props.location.pathname +
                (this.props.location.search ? this.props.location.search : ""),
            },
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

class RRoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    const routeRender = (props: any) => {
      log(LogLevel.UI_LIFECYCLE, "Registering Routing : routeRender", props);

      if (this.props.user.authStatus == AuthStatus.REGISTERING) {
        log(LogLevel.NONE, "Registering Routing : REGISTERING", props);
        return React.createElement(Component, {
          ...props,
          ...this.props.options,
        });
      }
      log(LogLevel.NONE, "Registering Routing : not logged in", this.props);
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

class MRoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    const routeRender = (props: any) => {
      log(LogLevel.UI_DATA_LOAD, "Manager Routing : routeRender", this.props);

      if (
        this.props.user.authStatus == AuthStatus.AUTHENTICATED &&
        this.props.user.me &&
        this.props.user.me.level >= UserLevel.MANAGER
      ) {
        log(LogLevel.UI_DATA_LOAD, "Manager : logged in", props);
        return React.createElement(Component, props);
      }
      log(
        LogLevel.UI_DATA_LOAD,
        "Manager : not logged in or not manager",
        this.props
      );
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from: this.props.location.pathname,
              search: this.props.location.search,
            },
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

class ARoute extends Component<Props> {
  render() {
    const Component = this.props.component;
    const routeRender = (props: any) => {
      log(LogLevel.NONE, "Admin Routing : routeRender", this.props);

      if (
        this.props.user.authStatus == AuthStatus.AUTHENTICATED &&
        this.props.user.me &&
        this.props.user.me.level >= UserLevel.ADMIN
      ) {
        log(LogLevel.NONE, "Admin  : logged in", props);
        return React.createElement(Component, props);
      }

      log(LogLevel.NONE, "Admin  : not logged in or not admin", this.props);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              from: this.props.location.pathname,
              search: this.props.location.search,
            },
          }}
        />
      );
    };
    return <Route render={routeRender.bind(this)} />;
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export const PrivateRoute = connect(mapStateToProps)(PRoute);
export const ManagerRoute = connect(mapStateToProps)(MRoute);
export const RegisteringRoute = connect(mapStateToProps)(RRoute);
