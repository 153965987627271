import React, { useEffect, useState } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import styled from "styled-components";
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonToolbar, IonButtons, IonToggle, IonCheckbox } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import {log, LogLevel} from '../../../utils/LogUtil'
import { getGlobal, GlobalKey, setGlobal } from '../../../utils/GlobalUtil';
import Button from '../../../components/atoms/Button';
import { AdminCustomBody, Conditions, NotificationType } from "../../../models/Model.Notification";


const AdminAPI = {        
  "USERS_ID" : {"method":"POST", "path":"/admin/notification/custom/user", "contentType":"application/json"},
};

type Props = {
  ids: string[],
  setIds: (ids:string[]) => void;
  editFields: AdminCustomBody,
  setEditFields: (editFields:any) => void;
};


const GetUserConditionedDistributionView = (props: Props) => {
  const fields = ["region1", "region2", "workType", "licensedAt", "birthday","gender", "id"];
  type Conditions = "EQ" | "NEQ" | "IN" | "NIN" | "GT" | "GTE" | "LT" | "LTE" | "LIKE";
  const conditions: Conditions[] = ["EQ", "NEQ", "IN", "NIN", "GT", "GTE", "LT", "LTE","LIKE"];
  let statisticsLoading = false;
  let statisticsLoaded = false;

  const [messageInfo, setMessageInfo] = useState(null);
  const [where, setWhere] = useState([{ field: fields[0], condition: conditions[0], value: undefined }]);
  const [group, setGroup] = useState([]);



    const fetchData = () => {
      
      if (statisticsLoading) {
        return;
      }
      statisticsLoading = true;
      
      let request: any = { where: [...where], groupBy: [...group] };



      request.where.forEach((condition, index) => {
        switch (condition.condition) {
          case "IN":
          case "NIN":
            try {
              request.where[index] = { ...condition, value: condition.value.split(',').map(Number) };
            } catch (e) {
              console.error('Error parsing condition.value:', e);
            }
            break;
          case "EQ":
            request.where[index] = { ...condition, value: condition.value };
            break;
          case "NEQ":
          case "GT":
          case "GTE":
          case "LT":
          case "LTE":
            request.where[index] = { ...condition, value: Number(condition.value) };
            break;
          case "LIKE":
            // No transformation needed for LIKE
            break;
          default:
            console.warn(`Unknown condition: ${condition.condition}`);
        }
      });
  
  
      props.setEditFields((prevFields) => {
        let dataObj: any = {};
        try {
          dataObj = JSON.parse(prevFields.data);
        } catch (e) {
          dataObj = { data: { where: [] } }; // 파싱 실패 시 기본값 설정
        }
  
        dataObj = dataObj || {}; 

        dataObj = { 
          ...dataObj, 
          data: { 
            ...(dataObj.data || {}), // If dataObj.data is null, use an empty object instead
            where: request.where, 
            // group: request.groupBy
          }
        }

  
  
        const updatedDataObj = { ...dataObj, data: { ...dataObj.data } };
  
        const rvalue = { ...prevFields, data: JSON.stringify(updatedDataObj, null, 2) };
        request = rvalue 
        return rvalue
      });





    fetchAPI(AdminAPI.USERS_ID, "", null, request, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        log(LogLevel.UI_DATA_LOAD, "Admin:UserConditionedDistributionStatisticsView:fetchData result", result);
        setMessageInfo(result.data);
      }
      else{
        log(LogLevel.UI_DATA_LOAD, "Admin:UserConditionedDistributionStatisticsView:fetchData result", result);
        setMessageInfo(null);
      }
      statisticsLoaded = true;
      statisticsLoading = false;
    }).catch((e) => {
      log(LogLevel.UI_EXCEPTION, "Admin:UserConditionedDistributionStatisticsView:fetchData exception", e);

      setMessageInfo(null);
      statisticsLoading = false;
    });
  }



  const renderStatistics = () => {
    if(messageInfo){
      return messageInfo.length 
    }
  }



    return (

      <Container>     
        <ButtonBlock>
          <FlexRow>
            <div>인앱 메세지 보낼 유져 선택</div>
          </FlexRow>
        </ButtonBlock>
        <SearchBox>
          <FlexGrow>
            <ContainerColumn>
              <ContainerRow className="common-flex-align-center admin-margin-bottom"> 
                <div>Where Option</div> 
                <Button size={'Small'} type={'Icon'} variant={'Contained'} color={'Primary'} icon="Plus" style={{marginLeft:"10px"}} onClick={() => {
                  let newWhere = [...where];
                  newWhere.push({field:fields[0],condition:conditions[0], value:""})
                  setWhere(newWhere);
                }}/>
              </ContainerRow>
              {where.map((item, index) => {
                return (
                  <div key={index.toString()} className="common-container-row admin-margin-bottom">
                  <select 
                    onChange={(e) => {
                      let newWhere = [...where];
                      newWhere[index] = {...newWhere[index], field:e.target.value};
                      setWhere(newWhere);
                    }} 
                    defaultValue={item.field}
                  >
                    {fields.map((item2, index2) => 
                      <option key={index2.toString()} value={item2} selected={item2 == item.field}>{item2}</option>
                    )}
                  </select>
                  <select 
                    onChange={(e) => {
                      let newWhere = [...where];
                      newWhere[index] = {...newWhere[index], condition:(e.target.value) as Conditions };
                      setWhere(newWhere);
                    }} 
                    defaultValue={conditions[0]}
                  >
                    {conditions.map((item2, index2) => 
                      <option key={index2.toString()} value={item2} selected={item2 == item.condition}>{item2}</option>
                    )}
                  </select>
                  <input value={item.value} onChange={(e) => {
                    let newWhere = [...where];
                    newWhere[index] = {...newWhere[index], value:e.target.value};
                    setWhere(newWhere);
                  }}
                  />
                  <Button size={'Small'} type={'Icon'} variant={'Contained'} color={'Primary'} icon="Minus" style={{marginLeft:"10px"}} onClick={() => {
                    let newWhere = [...where];
                    newWhere.splice(index, 1);
                    setWhere(newWhere);
                  }}/>
                </div>
                )
              })}
              {/* <ContainerRow className="common-flex-align-center admin-margin-bottom"> 
                <div>GroupBy Option</div> 
                {fields.map((item, index) => {
                  if(group.includes(item))
                    return null;
                  return (
                    <Button key={index.toString()} size={'XSmall'} type={'IconWithText'} variant={'Outlined'} color={'Primary'} right icon="Plus" style={{marginLeft:"10px"}} onClick={() => {
                      setGroup([...group, item]);
                    }}>{item}</Button>
                  );
                })}
              </ContainerRow> */}
              {/* <ContainerRow className="common-flex-align-center admin-margin-bottom"> 
                {group.map((item, index) => {
                  return (
                    <Button size={'Small'} type={'IconWithText'} variant={'Contained'} color={'Primary'} right icon="Minus" style={{marginLeft:"10px"}} onClick={() => {
                      let newGroup = [...group];
                      newGroup.splice(index, 1);
                      setGroup(newGroup);
                    }}>{item}</Button>
                  );
                })}
              </ContainerRow> */}
            </ContainerColumn>
          </FlexGrow>
          <div>
            <ContainerColumn>
              <IonButton onClick={fetchData}>Load</IonButton>
            </ContainerColumn>
          </div>
        </SearchBox>
        {renderStatistics()}
      </Container>
    );
  }


const Container = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
`;

const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerRow = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const MarginBottom = styled.div`
  margin-bottom: 20px;
`;

export default GetUserConditionedDistributionView;