import { JobOfferType } from "../models/Model.JobPost";
import { CompanyType } from "../models/Model.User";

export const companyName = {
  [CompanyType.PHARMACY]: "약국",
  [CompanyType.HOSPITAL]: "병·의원",
  [CompanyType.PHARMACEUTICAL_COMPANY]: "제약바이오",
  [CompanyType.DISTRIBUTOR]: "의약품 유통",
  [CompanyType.ETC]: "기타",
  "99": "기타",
};

export const JobBizOfferName = {
  [JobOfferType.EMPLOYED]: "정규직",
  [JobOfferType.TEMPORARY]: "계약직",
};

export enum EmployeeCnt {
  NONE = 0,
  FIFTY = 50,
  HUNDRED = 100,
  FIFTY_HUNDRED = 500,
  GREATER_FIFTY_HUNDRED = 501,
}

export const employeeCntParser = {
  [EmployeeCnt.NONE]: "",
  [EmployeeCnt.FIFTY]: "50명 이하",
  [EmployeeCnt.HUNDRED]: "51~100명",
  [EmployeeCnt.FIFTY_HUNDRED]: "101~500명",
  [EmployeeCnt.GREATER_FIFTY_HUNDRED]: "501명 이상",
};

export type OptionType = "raise" | "alim" | "edit" | "finish" | "URL";
