import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { IonToggle } from "@ionic/react";
import "./ViewerRecommends.css";
import ViewerBody from "./ViewerBody";
import {
  BoardContent,
  BoardComment,
  BoardAttribute,
} from "./../models/Model.Board";
import * as API from "./../API.json";
import { fetchAPI } from "./../utils/APIUtil";
import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "./../store/board/types";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";

import AnalyticsUtil from "../utils/AnalyticsUtil";
import badgeIcon from "./../assets/icon/nice_badge.png";
import Truncate from "react-truncate";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import { isFirstDayOfMonth } from "date-fns";
import { CompanyTypeName, UserLevel } from "../models/Model.User";
import VisibilitySensor from "react-visibility-sensor";
import { SeminarLecture } from "../models/Model.Seminar";
import { Flex, Static } from "./atoms/Layout";
import Tag from "./atoms/Tag";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import Icon from "./atoms/Icon";
import NiceCard from "../assets/image/nice_card.png";
import { render } from "react-dom";
import { Partner } from "../store/search";
import Avatar from "./atoms/Avatar";
import AdsUtil from "../utils/AdsUtil";
import { UIPopupType } from "../store/ui/types";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    posting?: BoardContent;
    disabled?: boolean;
  };

type State = {
  recommends: any[]; // {posting, seminar}
  recommendWithoutReplies: boolean;
  recommendScoreRatio: boolean;
};

class ViewerRecommends extends Component<Props, State> {
  excludes = [];
  recommendsElement = null;
  searching = false;

  state = {
    recommends: null,
    recommendWithoutReplies: ABTestUtil.isTest(
      ABTestFeature.UI_VIEWER_RECOMMEND_OPTIONS
    )
      ? getGlobal(GlobalKey.RECOMMEND_WITHOUT_REPLIES, true)
      : false,
    recommendScoreRatio: true,
  };

  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerRecommends:componentDidMount",
      this.props.posting
    );
    if (!ABTestUtil.isTest(ABTestFeature.UI_VIEWER_RECOMMEND)) return;
    if (this.props.posting) {
      setTimeout(() => {
        this.searchRecommends();
      }, 300);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.disabled ||
      !ABTestUtil.isTest(ABTestFeature.UI_VIEWER_RECOMMEND)
    )
      return;

    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerRecommends:componentDidUpdate",
      prevProps.location.state,
      this.props.location.state
    );
    if (
      this.props.location.state &&
      this.props.location.state.listType &&
      this.props.location.state.index
    ) {
      let lastLocationState;
      if (prevProps.location.state)
        lastLocationState = prevProps.location.state;
      if (
        !lastLocationState ||
        lastLocationState.list != this.props.location.state.listType ||
        lastLocationState.index != this.props.location.state.index
      ) {
        if (
          !this.props.boards[this.props.location.state.listType].end &&
          !this.props.boards[this.props.location.state.listType].loading &&
          this.props.boards[this.props.location.state.listType].contents &&
          this.props.boards[this.props.location.state.listType].contents
            .length -
            1 <=
            this.props.location.state.index
        ) {
          log(
            LogLevel.UI_LIFECYCLE,
            "ViewerRecommends:componentDidUpdate loadBoard"
          );
          this.props.loadBoard(this.props.location.state.listType);
        }
      }
    }
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerRecommends:componentDidUpdate",
      this.props.posting,
      prevProps.posting,
      !prevProps.posting,
      prevProps.posting.id != this.props.posting.id
    );
    if (
      this.props.posting &&
      (!prevProps.posting || prevProps.posting.id != this.props.posting.id)
    ) {
      log(
        LogLevel.UI_LIFECYCLE,
        "ViewerRecommends:componentDidUpdate search recommends",
        this.props.posting,
        prevProps.posting
      );
      this.setState({ recommends: null });
    }

    if (
      (!this.state.recommends ||
        prevState.recommendWithoutReplies !=
          this.state.recommendWithoutReplies ||
        prevState.recommendScoreRatio != this.state.recommendScoreRatio) &&
      !this.searching &&
      this.props.posting &&
      (!this.props.posting.replyCnt || this.props.posting.replies)
    ) {
      this.searchRecommends();
    }
  }

  searchRecommends = () => {
    this.searching = true;
    log(
      LogLevel.UI_ACTION,
      "ViewerRecommends.searchRecommends start",
      this.props.posting,
      this.props.posting.replyCnt,
      this.props.posting.replies
    );
    if (!this.props.posting) return;

    let search = this.props.posting.subject + " " + this.props.posting.bodyText;
    if (
      !this.state.recommendWithoutReplies &&
      this.props.posting.replies &&
      this.props.posting.replies.length > 0
    ) {
      for (let i = 0; i < this.props.posting.replies.length; i++) {
        search += " " + this.props.posting.replies[i].bodyText;
        log(
          LogLevel.UI_ACTION,
          "ViewerRecommends.searchRecommends add replis",
          this.props.posting.replies[i]
        );
      }
    }

    let query: any = {
      search,
      count: 10,
      randomSort: true,
    };

    if (this.state.recommendScoreRatio) query.scoreRatio = true;

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.recommendExcludes &&
      this.props.location.state.recommendExcludes.length
    ) {
      this.excludes = [...this.props.location.state.recommendExcludes];
    }

    if (!this.excludes.includes(this.props.posting.id))
      this.excludes.push(this.props.posting.id);

    query.exclude = this.excludes;

    fetchAPI(API.SEARCH_RECOMMENDS, "", null, query, getGlobal(GlobalKey.TOKEN))
      .then((result) => {
        log(
          LogLevel.UI_ACTION,
          "ViewerRecommends.searchRecommends results",
          query,
          result
        );
        if (
          result &&
          !result.error &&
          result.data &&
          result.data.contents &&
          result.data.contents.length
        ) {
          this.setState({ recommends: result.data.contents });
        } else {
          this.setState({ recommends: [] });
        }
        this.searching = false;
      })
      .catch((e) => {
        log(
          LogLevel.UI_EXCEPTION,
          "ViewerRecommends.searchRecommends exception",
          query,
          e
        );
        this.setState({ recommends: [] });
      });
  };

  navigateToViewPosting = (
    posting: BoardContent,
    listType = null,
    index = null,
    history = null,
    text = "",
    excludes = null,
    adlog = false
  ) => {
    let recommendDepth = 0;
    if (history) recommendDepth = history.length;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_CONTENT",
      "게시물보기_추천선택",
      {
        게시물id: posting.id,
        추천내용: text,
        연속추천선택횟수: recommendDepth,
      }
    );
    this.props.history.replace(`/boards/view?id=${posting.id}`, {
      listType,
      index,
      recommendExcludes: excludes ? excludes : this.excludes,
      history,
    });
    if (adlog && posting.advertiserCode) {
      let advertiserInfo: any = {
        code: posting.advertiserCode,
        productCode: posting.advertiserProductCode,
        contentType: posting.cards ? "CARDNEWS" : "CONTENT",
        contentId: posting.id,
        contentTitle: posting.subject,
      };
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "ADVERTISE_CLICK",
        "광고클릭",
        {
          type: "유사컨텐츠",
          ...advertiserInfo,
        }
      );
    }

    AdsUtil.show(AdsUtil.TYPE_INTERSTITIAL, {
      name: "유사콘텐츠",
      solution: AdsUtil.SOLUTION_ADMOB,
      skipRate: 50,
    });
  };

  navigateToViewPartner = (
    partner: Partner,
    listType = null,
    index = null,
    history = null,
    text = "",
    excludes = null
  ) => {
    let recommendDepth = 0;
    if (history) recommendDepth = history.length;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_CONTENT",
      "게시물보기_추천선택",
      {
        게시물id: partner.id,
        추천내용: text,
        연속추천선택횟수: recommendDepth,
      }
    );
    this.props.history.replace(
      `/partners/detail?id=${partner.id.substring(3, 50)}`,
      {
        listType,
        index,
        recommendExcludes: excludes ? excludes : this.excludes,
        history,
      }
    );
  };

  navigateToViewSeminar = (
    seminar: SeminarLecture,
    listType = null,
    index = null,
    history = null,
    text = "",
    excludes = null
  ) => {
    let recommendDepth = 0;
    if (history) recommendDepth = history.length;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_CONTENT",
      "게시물보기_추천세미나선택",
      {
        세미나id: seminar.id,
        추천내용: text,
        연속추천선택횟수: recommendDepth,
      }
    );
    this.props.history.push(`/lecture?id=${seminar.id}`, {
      listType,
      index,
      recommendExcludes: excludes ? excludes : this.excludes,
      history,
    });

    if (seminar.advertiserCode) {
      let advertiserInfo: any = {
        code: seminar.advertiserCode,
        productCode: seminar.advertiserProductCode,
        contentType: "SEMINAR",
        contentId: seminar.id,
        contentTitle: seminar.title,
      };
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "ADVERTISE_CLICK",
        "광고클릭",
        {
          type: "유사컨텐츠",
          ...advertiserInfo,
        }
      );
    }
  };

  onPostingVisibleChange = (visible: boolean, posting: BoardContent) => {
    log(
      LogLevel.UI_EVENT,
      "BoardListItem: onPostingVisibleChange",
      visible,
      posting
    );
    if (visible && posting.advertiserCode) {
      let advertiserInfo: any = {
        code: posting.advertiserCode,
        productCode: posting.advertiserProductCode,
        contentType: posting.cards ? "CARDNEWS" : "CONTENT",
        contentId: posting.id,
        contentTitle: posting.subject,
      };
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "ADVERTISE_EXPOSURE",
        "광고노출",
        {
          type: "유사컨텐츠",
          ...advertiserInfo,
        }
      );
    }
  };

  onSeminarVisibleChange = (visible: boolean, seminar: SeminarLecture) => {
    log(
      LogLevel.UI_EVENT,
      "BoardListItem: onSeminarVisibleChange",
      visible,
      seminar
    );
    if (visible && seminar.advertiserCode) {
      let advertiserInfo: any = {
        code: seminar.advertiserCode,
        productCode: seminar.advertiserProductCode,
        contentType: "SEMINAR",
        contentId: seminar.id,
        contentTitle: seminar.title,
      };
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "ADVERTISE_EXPOSURE",
        "광고노출",
        {
          type: "유사컨텐츠",
          ...advertiserInfo,
        }
      );
    }
  };

  onChangeRecommendOption(recommendWithoutReplies: boolean) {
    setGlobal(
      GlobalKey.RECOMMEND_WITHOUT_REPLIES,
      recommendWithoutReplies,
      true
    );
    this.setState({ recommendWithoutReplies });
  }

  onChangeRecommendScoreOption(recommendScoreRatio: boolean) {
    this.setState({ recommendScoreRatio });
  }

  render() {
    if (
      this.props.disabled ||
      !ABTestUtil.isTest(ABTestFeature.UI_VIEWER_RECOMMEND)
    )
      return <div className="viewer-recommends-container" />;

    let prevNextElement = this.renderPrevNext();
    let similarElement = this.renderSimilar();

    if (!prevNextElement && !similarElement)
      prevNextElement = <div className="viewer-recommends-empty" />;

    let searchOption;
    if (
      ABTestUtil.isTest(ABTestFeature.UI_VIEWER_RECOMMEND_OPTIONS) &&
      this.props.me.level >= UserLevel.MANAGER
    ) {
      searchOption = (
        <div className="viewer-recommends-option-container">
          <div className="common-flex-grow-2" />
          <div className="common-flex-grow-2" />
          <div
            className={
              this.state.recommendWithoutReplies
                ? "search-option-text-inactive"
                : "search-option-text-active"
            }
            onClick={(e) => {
              this.onChangeRecommendOption(false);
            }}
          >
            답변포함
          </div>
          <IonToggle
            class="search-options-toggle"
            mode="ios"
            checked={this.state.recommendWithoutReplies}
            onClick={(e) =>
              this.onChangeRecommendOption(!this.state.recommendWithoutReplies)
            }
          />
          <div
            className={
              this.state.recommendWithoutReplies
                ? "search-option-text-active"
                : "search-option-text-inactive"
            }
            onClick={(e) => {
              this.onChangeRecommendOption(true);
            }}
          >
            답변제외
          </div>
          <div className="common-flex-grow" />
          <div
            className={
              this.state.recommendScoreRatio
                ? "search-option-text-inactive"
                : "search-option-text-active"
            }
            onClick={(e) => {
              this.onChangeRecommendScoreOption(false);
            }}
          >
            절대점수
          </div>
          <IonToggle
            class="search-options-toggle"
            mode="ios"
            checked={this.state.recommendScoreRatio}
            onClick={(e) =>
              this.onChangeRecommendScoreOption(!this.state.recommendScoreRatio)
            }
          />
          <div
            className={
              this.state.recommendScoreRatio
                ? "search-option-text-active"
                : "search-option-text-inactive"
            }
            onClick={(e) => {
              this.onChangeRecommendScoreOption(true);
            }}
          >
            상대점수
          </div>
        </div>
      );
    }

    return (
      // <Flex
      //   alignItems="center"
      //   gap="12px"
      //   customStyle={{
      //     width: "100%",
      //     padding: "20px",
      //     overflowX: "auto",
      //     border: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
      //   }}
      // >
      // {/* {searchOption} */}
      // {/* {similarElement} */}
      // {/* {prevNextElement} */}
      // </Flex>
      <>
        {similarElement}
        {prevNextElement}
      </>
    );
  }

  renderSimilarPostingItem = (posting: BoardContent, index) => {
    let icon;
    let attribute = posting.boardsCategoriesAttribute
      ? posting.boardsCategoriesAttribute
      : "";
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerRecommends:renderSimilarPostingItem",
      posting,
      attribute
    );

    if (posting.isNice) {
      icon = (
        <div className="viewer-recommends-similar-item-title-icon-container">
          <img
            className="viewer-recommends-similar-item-title-inner-icon"
            src={badgeIcon}
          />
        </div>
      );
    }

    let text = posting.subject;
    if (!text) text = posting.bodyText;

    let replyCnt;
    let likeCnt;
    let viewCnt;
    let type = "유사 게시물";
    if (attribute.includes(BoardAttribute.IS_QNA)) type = "유사 QnA";

    if (attribute.includes(BoardAttribute.UI_LIST_REPLY_CNT)) {
      let replayTerm = "의견";
      if (attribute.includes(BoardAttribute.IS_QNA)) replayTerm = "답변";

      replyCnt = (
        <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
          답변 {posting.replyCnt}
        </Text>
      );
    }
    if (attribute.includes(BoardAttribute.UI_LIST_LIKE_CNT)) {
      let likeTerm = "좋아요";
      if (attribute.includes(BoardAttribute.IS_DISCUSSION)) likeTerm = "찬성";

      likeCnt = (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Icon
            name="Thumbs Up"
            width={16}
            height={16}
            color={COLOR_SYSTEM.get("Gray")[200]}
          />
          <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
            {posting.likeCntAll}
          </Text>
        </div>
      );
    }
    if (attribute.includes(BoardAttribute.UI_LIST_VIEW_CNT)) {
      viewCnt = (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Icon
            name="Eye"
            width={16}
            height={16}
            color={COLOR_SYSTEM.get("Gray")[200]}
          />
          <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
            {posting.viewCnt}
          </Text>
        </div>
      );
    }

    let history = [];
    if (this.props.posting) {
      let current: any = { id: this.props.posting.id };
      if (this.props.location.state) {
        if (this.props.location.state.history)
          history = [...this.props.location.state.history];
        current.listType = this.props.location.state.listType;
        current.index = this.props.location.state.index;
      }
      current.excludes = this.excludes;

      history.push(current);
    }

    let scoreElement;
    if (
      ABTestUtil.isTest(ABTestFeature.UI_VIEWER_RECOMMEND_OPTIONS) &&
      this.props.me.level >= UserLevel.MANAGER
    ) {
      scoreElement = (
        <div className="viewer-recommends-similar-item-header-title common-flex-grow common-color-point common-text-align-right">
          {posting.score.toFixed(2)}
        </div>
      );
    }

    let rvalue = (
      // <div
      //   key={index.toString()}
      //   className="viewer-recommends-similar-item-container"
      //   onClick={() => this.navigateToViewPosting(posting, null, null, history, type, null, true)}
      // >
      //   <div className="viewer-recommends-similar-item-header">
      //     <div className="viewer-recommends-similar-item-header-title">{type}</div>
      //     {icon}
      //     {scoreElement}
      //   </div>
      //   <div className="viewer-recommends-similar-item-text">
      //     <Truncate lines={2} ellipsis="...">
      //       {text}
      //     </Truncate>
      //   </div>
      //   <div className="viewer-recommends-similar-item-info-container">
      //     {likeCnt}
      //     {replyCnt}
      //     {viewCnt}
      //   </div>
      // </div>

      <div key={index.toString()} style={{ flex: "none" }}>
        <Static
          customStyle={{
            padding: "16px 20px 12px",
            transform: "translateZ(0)",
            width: "210px",
            height: "150px",
            borderRadius: "8px",
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
          }}
          onClick={() =>
            this.navigateToViewPosting(
              posting,
              null,
              null,
              history,
              type,
              null,
              true
            )
          }
        >
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {!!posting.isNice && (
              <img src={NiceCard} alt="" width={17} height={20} />
            )}
            <Tag color="Gray" variant="Tinted">
              {posting.boardsCategoriesName}
            </Tag>
          </div>
          <div style={{ margin: "12px 0", height: "44px" }}>
            <Text
              textType="Body2Bold"
              color={COLOR_SYSTEM.get("Gray")[800]}
              numberOfLines={2}
            >
              {text}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {replyCnt}
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              {viewCnt}
              {likeCnt}
            </div>
          </div>
        </Static>
      </div>
    );

    if (posting.advertiserCode) {
      return (
        <VisibilitySensor
          key={index.toString()}
          onChange={(visible) => this.onPostingVisibleChange(visible, posting)}
        >
          {rvalue}
        </VisibilitySensor>
      );
    }
    return rvalue;
  };

  renderSimilarSeminarItem = (seminar: SeminarLecture, index) => {
    let text = seminar.title;
    if (!text) text = seminar.subtitle;

    let averagleScore;
    let viewCnt;
    let type = "관련 세미나";
    if (seminar.scoreCount) {
      averagleScore = (
        <span className="viewer-recommends-item-info">
          평점&nbsp;&nbsp;{seminar.scoreAverage}/5 ({seminar.scoreCount})
        </span>
      );
    }
    viewCnt = (
      <span className="viewer-recommends-item-info">
        시청&nbsp;&nbsp;{seminar.viewCount}
      </span>
    );

    let scoreElement;
    if (
      ABTestUtil.isTest(ABTestFeature.UI_VIEWER_RECOMMEND_OPTIONS) &&
      this.props.me.level >= UserLevel.MANAGER
    ) {
      scoreElement = (
        <div className="viewer-recommends-similar-item-header-title common-flex-grow common-color-point common-text-align-right">
          {seminar.score.toFixed(2)}
        </div>
      );
    }

    let rvalue = (
      // <div
      //   key={index.toString()}
      //   className="viewer-recommends-similar-item-container"
      //   onClick={() => this.navigateToViewSeminar(seminar, null, null, null, type)}
      // >
      //   <div className="viewer-recommends-similar-item-header">
      //     <div className="viewer-recommends-similar-item-header-title">{type}</div>
      //     {scoreElement}
      //   </div>
      //   <div className="viewer-recommends-similar-item-text">
      //     <Truncate lines={2} ellipsis="...">
      //       {text}
      //     </Truncate>
      //   </div>
      //   <div className="viewer-recommends-similar-item-info-container">
      //     {averagleScore}
      //     {viewCnt}
      //   </div>
      // </div>
      <div key={index.toString()} style={{ flex: "none" }}>
        <Static
          customStyle={{
            padding: "16px 20px 12px",
            width: "210px",
            height: "150px",
            borderRadius: "8px",
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
          }}
          onClick={() =>
            this.navigateToViewSeminar(seminar, null, null, null, type)
          }
        >
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <img src={NiceCard} alt="" width={17} height={20} />
            <Tag color="Gray" variant="Tinted">
              {type}
            </Tag>
          </div>
          <div style={{ margin: "12px 0", height: "44px" }}>
            <Text
              textType="Body2Bold"
              color={COLOR_SYSTEM.get("Gray")[800]}
              numberOfLines={2}
            >
              {text}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {averagleScore}
            {viewCnt}
          </div>
        </Static>
      </div>
    );

    if (seminar.advertiserCode) {
      return (
        <VisibilitySensor
          key={index.toString()}
          onChange={(visible) => this.onSeminarVisibleChange(visible, seminar)}
        >
          {rvalue}
        </VisibilitySensor>
      );
    }
    return rvalue;
  };

  renderSimilarPartnerItem = (partner: Partner, index) => {
    let icon;
    // let attribute = partner.boardsCategoriesAttribute
    //   ? partner.boardsCategoriesAttribute
    //   : "";
    // log(
    //   LogLevel.UI_LIFECYCLE,
    //   "ViewerRecommends:renderSimilarPostingItem",
    //   partner,
    //   attribute
    // );

    // if (partner.isNice) {
    //   icon = (
    //     <div className="viewer-recommends-similar-item-title-icon-container">
    //       <img
    //         className="viewer-recommends-similar-item-title-inner-icon"
    //         src={badgeIcon}
    //       />
    //     </div>
    //   );
    // }

    // let text = partner.subject;
    // if (!text) text = partner.bodyText;

    let replyCnt;
    let likeCnt;
    let viewCnt;
    let type = "유사 게시물";

    let history = [];
    if (this.props.posting) {
      let current: any = { id: this.props.posting.id };
      if (this.props.location.state) {
        if (this.props.location.state.history)
          history = [...this.props.location.state.history];
        current.listType = this.props.location.state.listType;
        current.index = this.props.location.state.index;
      }
      current.excludes = this.excludes;

      history.push(current);
    }

    let scoreElement;
    if (
      ABTestUtil.isTest(ABTestFeature.UI_VIEWER_RECOMMEND_OPTIONS) &&
      this.props.me.level >= UserLevel.MANAGER
    ) {
      scoreElement = (
        <div className="viewer-recommends-similar-item-header-title common-flex-grow common-color-point common-text-align-right">
          {/* {partner.score.toFixed(2)} */}
        </div>
      );
    }

    let rvalue = (
      <div key={index.toString()} style={{ flex: "none" }}>
        <Static
          customStyle={{
            padding: "16px 20px 12px",
            transform: "translateZ(0)",
            width: "210px",
            height: "150px",
            borderRadius: "8px",
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
          }}
          onClick={() =>
            this.navigateToViewPartner(partner, null, null, history, type, null)
          }
        >
          <Flex
            direction="column"
            gap="16px"
            alignItems="center"
            justifyContent="center"
          >
            <Flex
              customStyle={{
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Flex customStyle={{ minHeight: "48px", minWidth: "48px" }}>
                <Avatar size="Large" url={partner.imgUrl} border={true} />
              </Flex>
              <Flex direction="column" customStyle={{ alignItems: "left" }}>
                <Flex
                  customStyle={{
                    justifyContent: "left",
                    alignItems: "center",
                    minWidth: "100px",
                    width: "100%",
                  }}
                >
                  <Text
                    numberOfLines={1}
                    textType="Body2Bold"
                    color={COLOR_SYSTEM.get("Gray")[700]}
                  >
                    {partner.title}{" "}
                  </Text>
                </Flex>
                <Flex
                  customStyle={{
                    justifyContent: "left",
                    alignItems: "center",
                    minWidth: "110px",
                  }}
                >
                  <Tag size="Small" variant="Ghost" color="Blue">
                    {" "}
                    {CompanyTypeName[partner.category]}{" "}
                  </Tag>
                </Flex>
              </Flex>
            </Flex>
            <Flex customStyle={{ minHeight: "36px", width: "100%" }}>
              <Text
                numberOfLines={2}
                textType="Caption"
                color={COLOR_SYSTEM.get("Gray")[600]}
              >
                {partner.bodies ? String(partner.bodies).substring(0, 30) : ""}
              </Text>
            </Flex>
          </Flex>
        </Static>
      </div>
    );

    return rvalue;
  };

  renderSimilar() {
    if (!this.state.recommends || this.state.recommends.length === 0)
      return null;

    return (
      <div
        ref={(ref) => {
          this.recommendsElement = ref;
        }}
        style={{
          width: "100%",
          padding: "20px 20px 0",
          display: "flex",
          alignItems: "center",
          gap: "12px",
          overflowX: "scroll",
          transform: "translateZ(0)",
          borderTop: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
        }}
      >
        {this.state.recommends.map((item: any, index) => {
          if (item.posting) {
            let posting: BoardContent = item.posting;
            return this.renderSimilarPostingItem(posting, index);
          } else if (item.seminar) {
            let seminar = item.seminar;
            return this.renderSimilarSeminarItem(seminar, index);
          } else if (item.partner) {
            let partner = item.partner;
            return this.renderSimilarPartnerItem(partner, index);
          } else return null;
        })}
      </div>
    );
  }

  renderPrevNext() {
    let previous, next;
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerRecommends:renderPrevNext",
      this.props.location.state
    );
    if (this.props.location.state) {
      if (
        this.props.location.state &&
        this.props.location.state.listType &&
        this.props.location.state.index >= 0 &&
        this.props.boards[this.props.location.state.listType] &&
        this.props.boards[this.props.location.state.listType].contents
      ) {
        let contents = this.props.boards[this.props.location.state.listType]
          .contents;
        log(
          LogLevel.UI_LIFECYCLE,
          "ViewerRecommends:renderPrevNext",
          this.props.location.state.index,
          contents.length
        );
        if (
          this.props.location.state.index > 0 &&
          this.props.location.state.index <= contents.length
        ) {
          let content = this.props.contents[
            contents[this.props.location.state.index - 1].id
          ];
          previous = this.renderPrevNextContent(
            content,
            true,
            this.props.location.state.listType,
            this.props.location.state.index - 1
          );
        }
        if (this.props.location.state.index < contents.length - 1) {
          let content = this.props.contents[
            contents[this.props.location.state.index + 1].id
          ];
          next = this.renderPrevNextContent(
            content,
            false,
            this.props.location.state.listType,
            this.props.location.state.index + 1
          );
        }
      } else if (
        this.props.location.state &&
        this.props.location.state.history &&
        this.props.location.state.history.length > 0 &&
        this.props.contents[
          this.props.location.state.history[
            this.props.location.state.history.length - 1
          ].id
        ]
      ) {
        let lastViewed = this.props.location.state.history[
          this.props.location.state.history.length - 1
        ];
        let lastHistory = null;
        if (this.props.location.state.history.length > 1) {
          lastHistory = this.props.location.state.history.slice(
            0,
            this.props.location.state.history.length - 1
          );
        }

        let content = this.props.contents[
          this.props.location.state.history[
            this.props.location.state.history.length - 1
          ].id
        ];

        log(
          LogLevel.UI_LIFECYCLE,
          "ViewerRecommends:renderPrevNext recommend history",
          this.props.location.state,
          content
        );
        previous = this.renderPrevNextContent(
          content,
          true,
          lastViewed.listType,
          lastViewed.index,
          lastHistory,
          lastViewed.excludes
        );
      }
    }

    if (!previous && !next) {
      return null;
    }

    return (
      // <div className="viewer-recommends-prev-next-container">
      <Static
        customStyle={{
          marginTop: "16px",
          borderWidth: "1px 0",
          borderStyle: "solid",
          borderColor: COLOR_SYSTEM.get("Gray")[50],
        }}
      >
        {previous}
        {previous && next && (
          <hr
            style={{ margin: 0, backgroundColor: COLOR_SYSTEM.get("Gray")[50] }}
          />
        )}
        {next}
      </Static>
    );
  }

  renderPrevNextContent(
    content: BoardContent,
    isPrevious: boolean,
    listType,
    index,
    stack = null,
    excludes = null
  ) {
    let icon;
    let attribute = this.props.boards[listType]
      ? this.props.boards[listType].attribute
      : "";
    if (!attribute && content.boardsCategoriesAttribute) {
      attribute = content.boardsCategoriesAttribute;
    }
    log(
      LogLevel.UI_LIFECYCLE,
      "ViewerRecommends:renderPreviousNextContent",
      content,
      isPrevious,
      listType,
      index,
      attribute
    );

    if (content.isNice) {
      icon = (
        <img
          className="viewer-recommends-prev-next-item-title-inner-icon"
          src={badgeIcon}
        />
      );
    }

    let text = content.subject;
    if (!text) text = content.bodyText;

    let replyCnt;
    let likeCnt;
    let viewCnt;

    if (attribute.includes(BoardAttribute.UI_LIST_REPLY_CNT)) {
      let replayTerm = "의견";
      if (attribute.includes(BoardAttribute.IS_QNA)) replayTerm = "답변";

      replyCnt = (
        <Text textType="Body2SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
          답변 {content.replyCnt}
        </Text>
      );
    }
    if (attribute.includes(BoardAttribute.UI_LIST_LIKE_CNT)) {
      let likeTerm = "좋아요";
      if (attribute.includes(BoardAttribute.IS_DISCUSSION)) likeTerm = "찬성";

      likeCnt = (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Icon
            name="Eye"
            width={16}
            height={16}
            color={COLOR_SYSTEM.get("Gray")[200]}
          />
          <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
            {content.likeCntAll}
          </Text>
        </div>
      );
    }
    if (attribute.includes(BoardAttribute.UI_LIST_VIEW_CNT)) {
      viewCnt = (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <Icon
            name="Thumbs Up"
            width={16}
            height={16}
            color={COLOR_SYSTEM.get("Gray")[200]}
          />
          <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
            {content.viewCnt}
          </Text>
        </div>
      );
    }

    let type = "다음글";
    if (isPrevious) type = "이전글";

    return (
      // <div
      //   className="viewer-recommends-prev-next-item-container"
      //   onClick={() => this.navigateToViewPosting(content, listType, index, stack, type, excludes)}
      // >
      //   <div className="viewer-recommends-prev-next-item-title-icon-container">{icon}</div>
      //   <div className="viewer-recommends-prev-next-item-detail-container">
      //     <div className="viewer-recommends-prev-next-item-text">
      //       <Truncate lines={1} ellipsis="...">
      //         {text}
      //       </Truncate>
      //     </div>
      //     <div className="viewer-recommends-prev-next-item-info-container">
      //       {likeCnt}
      //       {replyCnt}
      //       {viewCnt}
      //     </div>
      //   </div>
      //   <div className="viewer-recommends-prev-next-item-type">{type}</div>
      // </div>
      <div
        style={{ backgroundColor: COLOR_SYSTEM.get("Gray")[0] }}
        onClick={() =>
          this.navigateToViewPosting(
            content,
            listType,
            index,
            stack,
            type,
            excludes
          )
        }
      >
        <Flex alignItems="center" gap="12px" customStyle={{ padding: "20px" }}>
          <Tag color="Gray" variant="Contained">
            {type}
          </Tag>
          <Text
            textType="Body2Medium"
            color={COLOR_SYSTEM.get("Gray")[800]}
            numberOfLines={1}
            style={{ flex: 1 }}
          >
            {text}
          </Text>
        </Flex>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  contents: state.board.contents,
  boards: state.board.boards,
  me: state.user.me,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
  // logOutUser: () => actions.user.logout(),
  // refreshBoards: () => actions.board.refreshBoards(),
  // loadBoard: (board:BoardType) => actions.board.getBoard(board.toString(), 50),
  loadContent: (id: number) => actions.board.getContent(id),
  loadBoard: (board: BoardType) => actions.board.getBoard(board, 10),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewerRecommends)
);
