import React, { FC } from "react";
import styled, { StyledComponent } from "styled-components";
import Icon from "../../atom/Icon";
import COLOR from "./../../_design/color";
import TYPOGRAPY from "./../../_design/typography";
import Text from "./../../atom/Text";
interface ContainerProps {
  scrollable: boolean;
  hasHeader: boolean;
  hasFooter: boolean;
}

const Container: StyledComponent<
  "div",
  FC,
  ContainerProps
> = styled.div<ContainerProps>`
  width: 100%;
  height: calc(
    100% -
      ${(props) => {
        let minus = 0;
        if (props.hasHeader) minus += 64;
        if (props.hasFooter) minus += 86;
        return minus;
      }}px
  );
  max-height: calc(
    100% -
      ${(props) => {
        let minus = 0;
        if (props.hasHeader) minus += 64;
        if (props.hasFooter) minus += 86;
        return minus;
      }}px
  );
  background: #fff;
  padding: 0px 0px;
  position: relative;

  ${(props) =>
    props.scrollable
      ? `
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
    `
      : `
    overflow-y: hidden;
    `}
`;

export interface Props {
  scrollable?: boolean;
  hasHeader?: boolean;
  hasFooter?: boolean;
}

const Body: FC<Props> = ({
  scrollable = true,
  hasHeader = true,
  hasFooter = false,
  children,
}) => {
  return (
    <Container
      scrollable={scrollable}
      hasHeader={hasHeader}
      hasFooter={hasFooter}
    >
      {children}
    </Container>
  );
};

export default Body;
