import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';;
import Skeleton from 'react-loading-skeleton';
import smileIcon from './../assets/icon/smile.svg'
import { KeywordType, Medicine, Ingredient } from '../models/Model.Medicine';
import {compose} from 'redux'
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import {log, LogLevel} from '../utils/LogUtil'
import { EventInfo } from '../models/Model.Event';
import EventPopup from './EventPopup';
import { UIPopupType } from '../store/ui/types';
import { getGlobal, GlobalKey, setGlobal } from '../utils/GlobalUtil';
import ABTestUtil, { ABTestFeature } from '../utils/ABTestUtil';
import GamificationPeriodicReportPopup from './GamificationPeriodicReportPopup';
import { JobListType } from '../store/jobpost/types';
import { JobApplyInfo, JobApplyStatus } from '../models/Model.JobPost';
import { UserInfo, UserWorkType } from '../models/Model.User';
import AnalyticsUtil from '../utils/AnalyticsUtil';
import { sendKakaoInviteMessage } from '../utils/KakaoUtil';

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
};

type State = {

}

class PopupManager extends Component<Props, State> {
  eventPopup = null;
  reportPopup = null;
  jobpostPopup = null;
  jobpostApplyID = null;
  isRoot:boolean = true;
  

  today = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));

  constructor(props){
    super(props);
  }

  componentDidMount(){
    log(LogLevel.UI_LIFECYCLE, "PopupManager:componentDidMount");
    document.addEventListener("resume", this.onResume, false);


    if(this.props.me){
      this.show(this.props.location);
    }
  }

  componentWillUnmount(){
    log(LogLevel.UI_LIFECYCLE, "PopupManager:componentWillUnmount");
    document.removeEventListener("resume", this.onResume);
  }

  componentDidUpdate(prevProps, prevState){
    log(LogLevel.UI_LIFECYCLE, "PopupManager:componentDidUpdate", prevProps.me, prevProps.location, prevProps.token, prevProps.location.pathname, this.props.me, this.props.location, this.props.location.pathname, this.props.events)
    // log(LogLevel.UI_LIFECYCLE, "PopupManager:componentDidUpdate", prevProps.me, this.props.me)
    
    if(this.props.location.pathname == '/'){
      this.isRoot = true;
      log(LogLevel.UI_LIFECYCLE, "PopupManager:componentDidUpdate isRoot", this.props.location.pathname)
    }
    else if(this.props.me){
      this.show(this.props.location);
    }
  }

  onResume = () => {
    this.show(this.props.location)
  }

  show = (location, lastPath = "", lastSearch = "") => {
    let eventPopupShown:boolean = false;
    log(LogLevel.UI_LIFECYCLE, "PopupManager:show :", location, lastPath, lastSearch, this.isRoot)

    if(location.pathname != lastPath || location.search != lastSearch){
      if(this.isRoot && location.pathname=="/main/boards"){
        eventPopupShown = this.showEventPopup("/");
        if(eventPopupShown)
          this.isRoot = false;
      }else {
        eventPopupShown = this.showEventPopup(location.pathname, location.search)
      }
    }
    
    if(ABTestUtil.isTest(ABTestFeature.GROUD_RULE) && !eventPopupShown && (location.pathname=="/main/home" || location.pathname=="/main/boards") && !this.props.isAccomplished && getGlobal(GlobalKey.NEED_GROUND_RULE_GUIDE, true) && (!getGlobal(GlobalKey.GROUND_RULE_GUIDED_DATE, true) || this.today > getGlobal(GlobalKey.GROUND_RULE_GUIDED_DATE, true)) ){
      setGlobal(GlobalKey.GROUND_RULE_GUIDED_DATE, this.today, true);
      setGlobal(GlobalKey.NEED_GROUND_RULE_GUIDE, false, true);
      log(LogLevel.UI_LIFECYCLE, "PopupManager:show : Show Ground Rule Guide", location)
      this.props.confirmPopup.show({
        title:(
          <div className="common-container">
            <span className="common-color-caution">아시나요?</span>&nbsp;&nbsp;약문약답 활용 규칙
          </div>
        ),
        body: (
          <div className="common-container common-bold">
            모든 회원은<br/>
            매달 게시물 1개 or 댓글 3개를 작성해야합니다. <br/>
            작성하지 않을 경우, 이 팝업을 매일 보게 됩니다. ^^
          </div>
        ),
        iconImage: null,
        doneText: "네, 알겠습니다",
        cancelText: '활동 규칙 보기',
        cancelButtonColorStyle: "confirm-button"
      })
      eventPopupShown = true;
    }

    if(ABTestUtil.isTest(ABTestFeature.UI_GAMIFICATION_WEEKLY_REPORT) && !eventPopupShown && location.pathname=="/main/boards" && this.props.report && this.reportPopup){
      this.reportPopup.show({...this.props.report});
      this.props.clearReport();
      eventPopupShown = true;
    }
    log(LogLevel.UI_LIFECYCLE, "PopupManager:show : Jobpost popup check", ABTestUtil.isTest(ABTestFeature.UI_JOB_POST), eventPopupShown, this.props.passJobs, this.jobpostPopup)

    if(ABTestUtil.isTest(ABTestFeature.UI_JOB_POST) && !eventPopupShown && this.props.passJobs && this.props.passJobs.items && this.props.passJobs.items.length && this.jobpostPopup){
      eventPopupShown = true;
      let apply:JobApplyInfo= this.props.applies[this.props.passJobs.items[0].id];
      log(LogLevel.UI_LIFECYCLE, "PopupManager:show : Jobpost apply done popup", apply, this.jobpostApplyID)
      if(!this.jobpostApplyID || this.jobpostApplyID != apply.id){
        this.jobpostApplyID = apply.id;
        this.jobpostPopup.show(apply);
      }
      // this.reportPopup.show({...this.props.report});
      // this.props.clearReport();
    }

  }

  showEventPopup = (path:string, search:string = "") => {
    if(!this.props.events || this.props.events.length == 0){
      return false;
    }
    
    log(LogLevel.UI_EVENT, "EventPopupManger:showEventPopup", path, this.props.events);
    for(let i=0; i<this.props.events.length; i++){
      if(!this.props.events[i].notToday && (this.props.events[i].originUrl == path || this.props.events[i].originUrl == path+search)){
        this.eventPopup.show(this.props.events[i], () => this.onEventPopupClose(path, search));
        return true;
      }
    }
    return false;
  }

  onEventPopupClose = (path, search) => {
    this.show(this.props.location, path, search);
  }

  onIgnore = (event:EventInfo) => {
    log(LogLevel.UI_ACTION, "EventPopupManger:onIgnore", event);
    this.props.ignoreEvent(event.id);
  }

  onClick = (event:EventInfo) => {
    log(LogLevel.UI_ACTION, "EventPopupManger:onClick", event);

    if(event.log){
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ADVERTISE_CLICK", '광고고객반응', {"type":"이벤트팝업", ...event.log});

      if(event.log.gsid){
        fetchAPI(API.USER_CTA_RECORD, "", null, {
          gsid: event.log.gsid,
          gstid: event.log.gstid, // google sheet tab name
          shid: event.log.shid, // slack hook url
          stid: event.log.stid,
          stfields: event.log.stfields,
          data: event.log.code + "-" + (event.log.productCode?event.log.productCode:"") + "-" + event.log.contentType  + "-" + event.log.contentId // additionalData 
        }, getGlobal(GlobalKey.TOKEN));
      }
    }

    this.props.ignoreEvent(event.id);
    if(event.link){
      if(event.link.startsWith("action://")){
        switch (event.link){
          case "action://invite":
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "INVITE", "초대하기", {"위치":"이벤트팝업"});
          sendKakaoInviteMessage(this.props.me.name, this.props.me);
            break;
        }
      }
      else if(event.link.startsWith("/")){
        this.props.history.push(event.link);
      }else{
        window.open(event.link, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
      }
    }
  }

  render() {
    let reportPopup;
    if(ABTestUtil.isTest(ABTestFeature.UI_GAMIFICATION_WEEKLY_REPORT)){
      reportPopup = <GamificationPeriodicReportPopup ref={(ref) => this.reportPopup = ref} filePath={this.props.filePath}/>
    }

    return (
      <div>
        <EventPopup ref={(ref) => this.eventPopup = ref} onIgnore={this.onIgnore} onClick={this.onClick} filePath={this.props.filePath}/>
        {reportPopup}
      </div>
    );
  }
}


const mapStateToProps = (state: RootState) => ({
  filePath : state.board.filePath,
  events: state.event.events,
  me: state.user.me,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  isAccomplished: state.user.isAccomplished,
  report : state.gamification.report,
  passJobs: state.jobpost.lists[JobListType.APPLY|JobListType.PASSED],
  applies: state.jobpost.applies,
});

const mapDispatchToProps = {
  ignoreEvent : (id: number ) => actions.event.ignoreEvent(id),

  clearReport: () => actions.gamification.clearReport(),

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopupManager));