import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import { rippleEvent } from '../../_util/RrppleEffect'


type ButtonVariant = 'Ghost' | 'Outlined' | 'Contained'
type ButtonSize = 'S' | 'M' | 'L'
type ButtonColor = 'Primary' | 'Secondary' | 'Gray'
const BUTTON_SIZE = {
  S: {
    height: '32px',
    borderRadius: '6px',
    padding: '6px 7px',
    typography: 'ButtonS',
    block:"inline-block",
    iconSize: 18,
    iconMarginGap: "0px",
  },
  M: {
    height: '40px',
    borderRadius: '8px',
    padding: '9px 11px',
    typography: 'ButtonM',
    block:"block",
    iconSize: 20,
    iconMarginGap: "4px",
  },
  L: {
    height: '52px',
    borderRadius: '16px',
    padding: '14px 14px',
    typography: 'ButtonL',
    block:"block",
    iconSize: 22,
    iconMarginGap: "4px",
  },
}

const BUTTON_VARIANT_COLOR = {
  'Ghost': {
    'Primary':{
      borderColor: COLOR.Transparent,
      color: COLOR.Primary,
      background: COLOR.Transparent,
    },
    'Secondary':{
      borderColor: COLOR.Transparent,
      color: COLOR.Gray1,
      background: COLOR.Transparent,
    },
    'Gray':{
      borderColor: COLOR.Transparent,
      color: COLOR.Gray3,
      background: COLOR.Transparent,
    },
    'Disabled':{
      borderColor: COLOR.Transparent,
      color: COLOR.Gray5,
      background: COLOR.Transparent,
    },
  },
  'Outlined': {
    'Primary':{
      borderColor: COLOR.Primary,
      color: COLOR.Primary,
      background: COLOR.Transparent,
    },
    'Secondary':{
      borderColor: COLOR.Gray7,
      color: COLOR.Gray1,
      background: COLOR.Transparent,
    },
    'Gray':{
      borderColor: COLOR.Gray7,
      color: COLOR.Gray3,
      background: COLOR.Transparent,
    },
    'Disabled':{
      borderColor: COLOR.Gray8,
      color: COLOR.Gray5,
      background: COLOR.Transparent,
    },
  },
  'Contained': {
    'Primary':{
      borderColor: COLOR.Primary,
      color: COLOR.White,
      background: COLOR.Primary,
    },
    'Secondary':{
      borderColor: COLOR.Gray7,
      color: COLOR.Gray1,
      background: COLOR.Gray7,
    },
    'Gray':{
      borderColor: COLOR.Gray8,
      color: COLOR.Gray3,
      background: COLOR.Gray8,
    },
    'Disabled':{
      borderColor: COLOR.Gray8,
      color: COLOR.Gray5,
      background: COLOR.Gray8,
    },
  },
}

interface WrapperProps {
  size: ButtonSize
  variant: ButtonVariant
  color: ButtonColor
  block: boolean
}

const Wrapper: StyledComponent<'button', React.FC, WrapperProps> = styled.button<WrapperProps>`
  z-index: 0;
  height: ${(props) => BUTTON_SIZE[props.size].height};
  width: ${(props) => props.block?"100%":"auto"};
  padding: ${(props) => BUTTON_SIZE[props.size].padding};
  border-radius: ${(props) => BUTTON_SIZE[props.size].borderRadius};
  background-color: ${(props) => BUTTON_VARIANT_COLOR[props.variant][props.color].background};
  color: ${(props) => BUTTON_VARIANT_COLOR[props.variant][props.color].color};
  outline: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: ${(props) => BUTTON_SIZE[props.size].block};
  width: ${(props) => BUTTON_SIZE[props.size].width};
  border: 1px solid ${(props) => BUTTON_VARIANT_COLOR[props.variant][props.color].borderColor};
  &:disabled {
    background-color: ${(props) => BUTTON_VARIANT_COLOR[props.variant]['Disabled'].background};
    border: 1px solid ${(props) => BUTTON_VARIANT_COLOR[props.variant]['Disabled'].borderColor};
    color: ${(props) => BUTTON_VARIANT_COLOR[props.variant]['Disabled'].color};
    cursor: not-allowed;
  }

`

interface Props {
  size?: ButtonSize
  variant?: ButtonVariant
  color?: ButtonColor
  className?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  iconLeftName?: string
  iconRightName?: string
  block?: boolean
}
const TextButton: React.FC<Props> = ({
  size = 'S',
  variant = 'Contained',
  color = 'Primary',
  iconLeftName,
  iconRightName,
  className,
  children,
  disabled,
  block,
  onClick,
}) => {

  const el = useRef(null);
  const rippleEl = useRef(null);
  return (
    <Wrapper
      ref={el} 
      size={size}
      variant={variant}
      color={color}
      disabled={disabled}
      block={block}
      className={className?className+' common-effect-parent ':'common-effect-parent'}
      onClick={(e) => {
        rippleEvent(e, el.current, rippleEl.current);

        if(onClick) onClick(e);
      }}
    >
      <Text type={BUTTON_SIZE[size].typography}>
        {children}
      </Text>
      <div  className="common-effect-hover"/>
      <div ref={rippleEl} className="common-effect-ripple"/>

    </Wrapper>
  )
}

export default TextButton
