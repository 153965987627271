import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LogLevel, log } from "../../../utils/LogUtil";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { UserInfo } from "../../../models/Model.User";
import { Fixed, Flex, Header, Static } from "../../atoms/Layout";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Button from "../../atoms/Button";
import Footer from "../../cell/Footer";
import Body from "../../cell/Body";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import Dropdown from "../../molecules/Dropdown/Dropdown";
import useBottomSheet from "../../../hooks/useBottomSheet";
import BottomSheetList from "../../molecules/BottomSheetList";
import BottomSheetListItem from "../../molecules/BottomSheetListItem";
import { set } from "lodash";
import Input from "../../atoms/Input";
import ABTestUtil from "../../../utils/ABTestUtil";
import { on } from "cluster";

export interface PremiumUserSubscribeProps {
  me: UserInfo;
  onGoBack: () => void;
  onSubscribe: (amount: number) => void;
  onViewBenefit: () => void;
  menu: number[];
}

const PremiumUserSubscribe: React.FC<PremiumUserSubscribeProps> = ({
  me,
  onGoBack,
  onSubscribe,
  onViewBenefit,
  menu,
}) => {
  const [amount, setAmount] = useState<number>(10000);
  const [checked, setChecked] = useState<boolean>(false);

  const bottomSheet = useBottomSheet();

  useEffect(() => {
    log(LogLevel.UI_ACTION, "PremiumUserSubscribe:useEffect mount");
  }, []);

  return (
    <div className="common-content common-position-relative">
      <Header>
        <TopAppBar
          titleType="H4"
          leftButtons={[
            <Button
              color="Tertiary"
              icon="X"
              onClick={onGoBack}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          rightButtons={[]}
        />
      </Header>
      <Body footerHeight={88}>
        <Flex
          alignItems="center"
          direction="column"
          customStyle={{ padding: "0px 20px" }}
        >
          <img
            style={{ marginTop: "25px", marginBottom: "20px" }}
            src="/images/candle.png"
            width="156px"
          />

          <Static
            customStyle={{
              textAlign: "center",
              marginBottom: "16px",
            }}
          >
            <Text textType={"H2"} color={COLOR_SYSTEM.get("Gray")[900]}>
              약문약답이 약사님께 도움이 된다면, <br />
              후원으로 약문약답의 발전을 <br />
              응원해주세요.
            </Text>
          </Static>
          <Static
            customStyle={{
              textAlign: "center",
              marginBottom: "8px",
            }}
          >
            <Text
              textType={"Body1Medium"}
              color={COLOR_SYSTEM.get("Gray")[900]}
            >
              약사님께 도움이 되는 기능으로 보답하겠습니다.
            </Text>
          </Static>
          <Static
            customStyle={{
              textAlign: "center",
              marginBottom: "60px",
              textDecoration: "underline",
            }}
          >
            <Text
              textType={"Body1Medium"}
              color={COLOR_SYSTEM.get("Gray")[900]}
              onClick={onViewBenefit}
            >
              후원 회원 혜택 보기
            </Text>
          </Static>
          <Flex
            alignItems="center"
            gap="30px"
            customStyle={{ marginBottom: "80px" }}
          >
            <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
              월
            </Text>
            <Dropdown
              value={amount.toLocaleString() + "원"}
              onClick={(e) => {
                bottomSheet.show({
                  title: {
                    value: "매월 얼마씩 후원할까요?",
                    align: "left",
                  },
                  body: (
                    <PremiumUserSubscribePopup
                      amount={amount}
                      onDone={(amount) => {
                        setAmount(amount);
                        bottomSheet.close();
                      }}
                      menu={menu}
                    />
                  ),
                });
              }}
            />
            <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
              후원하기
            </Text>
          </Flex>
        </Flex>
      </Body>
      <Fixed bottom={0} left={0} customStyle={{ width: "100%" }}>
        <Static
          gap="12px"
          customStyle={{
            width: "calc(100% - 40px)",
            minHeight: "10px",
            margin: "0px 20px",
            padding: "16px 20px",
            borderTop: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
          }}
        >
          <CheckBox
            checked={checked}
            size={"Small"}
            onClick={() => setChecked(!checked)}
          >
            후원금 정기결제에 동의합니다.
          </CheckBox>
        </Static>
        <Flex
          alignItems="center"
          justfyContent="center"
          customStyle={{
            width: "100%",
            height: "52px",
            backgroundColor: COLOR_SYSTEM.get("Skyblue")[400],
            opacity: checked ? "1" : "0.3",
            justifyContent: "center",
          }}
          onClick={() => {
            if (checked) {
              onSubscribe(amount);
            }
          }}
        >
          <Text textType={"InputLarge"} color={COLOR_SYSTEM.get("Gray")[0]}>
            결제하기
          </Text>
        </Flex>
      </Fixed>
    </div>
  );
};

interface PremiumUserSubscribePopupProps {
  amount: number;
  onDone: (amount: number) => void;
  menu: number[];
}

export const PremiumUserSubscribePopup: React.FC<PremiumUserSubscribePopupProps> = ({
  amount,
  onDone,
  menu,
}) => {
  const [selectedDonationIndex, setSelectedDonationIndex] = useState<number>(
    menu.indexOf(amount)
  );
  const [customAmount, setCustomAmount] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <BottomSheetList customStyle={{ height: "390px" }}>
      {menu.map((donation, index) => (
        <BottomSheetListItem
          key={index.toString()}
          left={
            <Text
              textType="Body1Medium"
              color={
                COLOR_SYSTEM.get("Gray")[
                  index == selectedDonationIndex ? 900 : 400
                ]
              }
            >
              {donation.toLocaleString() + "원"}
            </Text>
          }
          right={
            <CheckBox
              checked={index == selectedDonationIndex}
              size={"Small"}
              onClick={() => {}}
            />
          }
          onClick={() => {
            log(
              LogLevel.UI_EXCEPTION,
              "PremiumUserSubscribe:BottomSheetListItem:onClick",
              index,
              selectedDonationIndex
            );
            setSelectedDonationIndex(index);
          }}
        />
      ))}
      <BottomSheetListItem
        left={
          <Text
            textType="Body1Medium"
            color={
              COLOR_SYSTEM.get("Gray")[-1 == selectedDonationIndex ? 900 : 400]
            }
          >
            직접 입력
          </Text>
        }
        right={
          <CheckBox
            checked={-1 == selectedDonationIndex}
            size={"Small"}
            onClick={() => {}}
          />
        }
        onClick={() => {
          log(
            LogLevel.UI_EXCEPTION,
            "PremiumUserSubscribe:BottomSheetListItem:onClick",
            -1,
            selectedDonationIndex
          );
          setSelectedDonationIndex(-1);
          setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
          }, 100);
        }}
      />
      {selectedDonationIndex == -1 && (
        <Input
          inputRef={inputRef}
          value={customAmount}
          error={customAmount && !(Number(customAmount) > 5000)}
          hint={
            !customAmount || Number(customAmount) > 5000
              ? ""
              : "최소 5000원부터 입력가능합니다."
          }
          type="number"
          rightItems={[
            <Text textType="Body1Medium" color={COLOR_SYSTEM.get("Gray")[800]}>
              원
            </Text>,
          ]}
          onChange={(e) => {
            setCustomAmount(e.target.value);
          }}
        />
      )}
      <Button
        size={"Large"}
        type={"Text"}
        variant={"Contained"}
        color={"Primary"}
        style={{
          position: "absolute",
          bottom: "32px",
          width: "calc(100% - 48px)",
        }}
        disabled={selectedDonationIndex == -1 && Number(customAmount) < 5000}
        disabledColor={
          selectedDonationIndex == -1 && Number(customAmount) < 5000
        }
        onClick={() => {
          let amount = 0;
          if (selectedDonationIndex == -1) {
            amount = Number(customAmount);
          } else {
            amount = menu[selectedDonationIndex];
          }
          onDone(amount);
        }}
      >
        선택 완료
      </Button>
    </BottomSheetList>
  );
};

export default PremiumUserSubscribe;
