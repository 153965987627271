import React from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { typography } from "../../design/typography";
export type CheckBoxSize = "Large" | "Medium" | "Small";

export interface CheckBoxProps {
  /** 체크박스 제목 */
  children?: React.ReactNode;
  /** 체크 여부 */
  checked: boolean;
  /** 체크 사이즈 */
  size: CheckBoxSize;
  /** 클릭 이벤트 헨들러 함수 */
  onClick: (params?: any) => any;
}

interface CheckBoxWrapperProps {
  checked: boolean;
  size: CheckBoxSize;
}

const CheckBoxWrapper = styled.label<CheckBoxWrapperProps>`
  display: inline-flex;
  align-items: center;
  color: ${({ checked }) =>
    checked ? COLOR_SYSTEM.get("Gray")[700] : COLOR_SYSTEM.get("Gray")[400]};
  ${typography.get("InputLarge")};

  input {
    display: none;
  }

  div {
    display: inline-block;
    width: ${({ size }) =>
      size === "Small" ? 20 : size === "Medium" ? 24 : 28}px;
    height: ${({ size }) =>
      size === "Small" ? 20 : size === "Medium" ? 24 : 28}px;
    border-radius: ${({ size }) =>
      size === "Small" ? 20 : size === "Medium" ? 24 : 28}px;
    background-color: ${({ checked }) =>
      checked
        ? COLOR_SYSTEM.get("Skyblue")[400]
        : COLOR_SYSTEM.get("Gray")[50]};
    margin-right: 12px;
    position: relative;

    &::before {
      content: "";
      width: 2px;
      height: ${({ size }) =>
        size === "Small" ? 6 : size === "Medium" ? 7 : 8}px;
      position: absolute;
      top: ${({ size }) =>
        size === "Small" ? 7.7 : size === "Medium" ? 10 : 11}px;
      left: ${({ size }) =>
        size === "Small" ? 6.5 : size === "Medium" ? 8.4 : 9.2}px;
      background-color: ${COLOR_SYSTEM.get("Gray")[0]};
      transform: rotate(-45deg);
      border-radius: 10px;
    }

    &::after {
      content: "";
      width: 2px;
      height: ${({ size }) =>
        size === "Small" ? 9 : size === "Medium" ? 10.5 : 12}px;
      position: absolute;
      top: ${({ size }) =>
        size === "Small" ? 5.7 : size === "Medium" ? 6.0 : 7.5}px;
      right: ${({ size }) =>
        size === "Small" ? 7.6 : size === "Medium" ? 10 : 11}px;
      background-color: ${COLOR_SYSTEM.get("Gray")[0]};
      transform: rotate(45deg);
      border-radius: 10px;
    }
  }
`;

const CheckBox = ({
  children,
  checked,
  onClick,
  size = "Large",
}: CheckBoxProps) => {
  const handleClick = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <CheckBoxWrapper checked={checked} onClick={handleClick} size={size}>
      <input type="checkbox" />
      <div></div>
      {children}
    </CheckBoxWrapper>
  );
};

export default CheckBox;
