import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonToggle,
  IonCheckbox,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import { SeminarLecture } from "../../../models/Model.Seminar";
import { defaultNewLecture } from "../../SeminarManage";
import { threadId } from "worker_threads";
import { loadImageBase64 } from "../../../utils/ImageUtil";
import * as API from "../../../API.json";
import ReactQuill from "react-quill";
import Textarea from "react-textarea-autosize";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import DownloadUtil from "../../../utils/DownloadUtil";
import {
  PieChart,
  Pie,
  Cell,
  Line,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import Button from "../../../components/atoms/Button";

const AdminAPI = {
  USERS_STATISTICS: {
    method: "POST",
    path: "/admin/statistic/user",
    contentType: "application/json",
  },
};

type Props = {};

const gender = ["", "남", "여"];

type State = {
  statisticsFold: boolean;
  statistics: any[];
  where: any[];
  group: string[];

  order: number;
  orderAsc: boolean;
};

const fields = [
  "region1",
  "region2",
  "workType",
  "licensedAt",
  "birthyear",
  "gender",
];
const conditions = ["EQ", "NEQ", "IN", "NIN", "GT", "GTE", "LT", "LTE", "LIKE"];

class UserConditionedDistributionStatisticsView extends Component<
  Props,
  State
> {
  statisticsLoading = false;
  statisticsLoaded = false;

  state = {
    statisticsFold: false,
    statistics: null,
    where: [],
    group: [],

    order: 0,
    orderAsc: true,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // this.fetchConsults();
  }

  fetchStatistics = () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:UserConditionedDistributionStatisticsView:fetchData"
    );

    if (this.statisticsLoading) return;
    this.statisticsLoading = true;
    let request: any = {};
    request.where = [...this.state.where];
    for (let i = 0; i < request.where; i++) {
      if (
        request.where[i].condition == "IN" ||
        request.where[i].condition == "NIN"
      ) {
        request.where[i] = {
          ...request.where[i],
          value: JSON.parse(request.where[i].value),
        };
      }
    }
    request.groupBy = [...this.state.group];

    fetchAPI(
      AdminAPI.USERS_STATISTICS,
      "",
      null,
      request,
      getGlobal(GlobalKey.TOKEN)
    )
      .then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:UserConditionedDistributionStatisticsView:fetchData result",
            result
          );
          this.setState({ statistics: result.data });
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:UserConditionedDistributionStatisticsView:fetchData result",
            result
          );
          this.setState({ statistics: null });
        }
        this.statisticsLoaded = true;
        this.statisticsLoading = false;
      })
      .catch((e) => {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:UserConditionedDistributionStatisticsView:fetchData exception",
          e
        );
        this.setState({ statistics: null });
        this.statisticsLoading = false;
      });
  };

  onDownloadStatistics = () => {
    if (!this.state.statistics || !this.state.statistics.length) return null;

    let total = 0;
    for (let i = 0; i < this.state.statistics.length; i++) {
      total += this.state.statistics[i].value;
    }

    let csv = "사용자 분포 통계\r\n";
    if (this.state.where && this.state.where.length > 0) {
      csv += "조건\r\n";
      for (let i = 0; i < this.state.where.length; i++) {
        csv += `${this.state.where[i].field},${this.state.where[i].condition},"${this.state.where[i].value}"\r\n`;
      }
    }

    for (let i = 0; i < this.state.group.length; i++) {
      csv += `${this.state.group[i]},`;
    }
    csv += `수,비율\r\n`;

    for (let i = 0; i < this.state.statistics.length; i++) {
      for (let j = 0; j < this.state.statistics[i].key.length; j++) {
        let result = this.state.statistics[i].key[j];
        if (this.state.group[j] == "workType") {
          result = UserWorkTypeName[result];
        } else if (this.state.group[j] == "gender") {
          result = gender[result];
        }

        csv += `${result},`;
      }
      csv += `${this.state.statistics[i].value},${(
        (this.state.statistics[i].value * 100) /
        total
      ).toFixed(1)}%\r\n`;
    }

    csv += `총합,${this.state.group.map(() => ",").join("")}${total}\r\n`;

    DownloadUtil.downloadCsv(
      csv,
      `User-Statics-${new Date()
        .toISOString()
        .substring(0, 10)
        .replace(/-/gi, "")}.csv`
    );
  };

  openStatistics = () => {
    this.setState({ statisticsFold: false });
  };

  render() {
    if (this.state.statisticsFold) {
      return (
        <div className="common-container">
          <div
            className="admin-full-button-block"
            onClick={this.openStatistics}
          >
            <div className="common-flex-row">
              <div>사용자 분포 통계</div>
              <IonIcon name="arrow-down" />
            </div>
          </div>
        </div>
      );
    }

    log(
      LogLevel.UI_LIFECYCLE,
      "Admin:UserConditionedDistributionStatisticsView:render",
      this.state
    );

    return (
      <div className="common-container">
        <div
          className="admin-full-button-block"
          onClick={() => this.setState({ statisticsFold: true })}
        >
          <div className="common-flex-row">
            <div>사용자 분포 통계</div>
            <IonIcon name="arrow-up" />
          </div>
        </div>
        <div className="common-container-row  admin-search-box">
          <div className="common-flex-grow">
            <div className="common-container-column">
              <div className="common-container-row common-flex-align-center admin-margin-bottom">
                <div>Where Option</div>
                <Button
                  size={"Small"}
                  type={"Icon"}
                  variant={"Contained"}
                  color={"Primary"}
                  icon="Plus"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    let where = [...this.state.where];
                    where.push({
                      field: fields[0],
                      condition: conditions[0],
                      value: "",
                    });
                    this.setState({ where });
                  }}
                />
              </div>
              {this.state.where.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className="common-container-row admin-margin-bottom"
                  >
                    <select
                      onChange={(e) => {
                        let where = this.state.where;
                        where[index] = {
                          ...where[index],
                          field: e.target.value,
                        };
                        this.setState({ where });
                      }}
                      defaultValue={item.field}
                    >
                      {fields.map((item2, index2) => (
                        <option
                          key={index2.toString()}
                          value={item2}
                          selected={item2 == item.field}
                        >
                          {item2}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => {
                        let where = this.state.where;
                        where[index] = {
                          ...where[index],
                          condition: e.target.value,
                        };
                        this.setState({ where });
                      }}
                      defaultValue={item.condition}
                    >
                      {conditions.map((item2, index2) => (
                        <option
                          key={index2.toString()}
                          value={item2}
                          selected={item2 == item.condition}
                        >
                          {item2}
                        </option>
                      ))}
                    </select>
                    <input
                      value={this.state.where[index].value}
                      onChange={(e) => {
                        let where = this.state.where;
                        where[index] = {
                          ...where[index],
                          value: e.target.value,
                        };
                        this.setState({ where });
                      }}
                    />
                    <Button
                      size={"Small"}
                      type={"Icon"}
                      variant={"Contained"}
                      color={"Primary"}
                      icon="Minus"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        let where = [...this.state.where];
                        where.splice(index, 1);
                        this.setState({ where });
                      }}
                    />
                  </div>
                );
              })}
              <div className="common-container-row common-flex-align-center admin-margin-bottom">
                <div>GroupBy Option</div>
                {fields.map((item, index) => {
                  if (this.state.group.includes(item)) return null;
                  return (
                    <Button
                      key={index.toString()}
                      size={"XSmall"}
                      type={"IconWithText"}
                      variant={"Outlined"}
                      color={"Primary"}
                      right
                      icon="Plus"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        this.setState({ group: [...this.state.group, item] });
                      }}
                    >
                      {item}
                    </Button>
                  );
                })}
              </div>
              <div className="common-container-row common-flex-align-center admin-margin-bottom">
                {this.state.group.map((item, index) => {
                  return (
                    <Button
                      size={"Small"}
                      type={"IconWithText"}
                      variant={"Contained"}
                      color={"Primary"}
                      right
                      icon="Minus"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        let group = [...this.state.group];
                        group.splice(index, 1);
                        this.setState({ group });
                      }}
                    >
                      {item}
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <div className="common-container-column">
              <IonButton onClick={this.fetchStatistics}>Load</IonButton>
              <IonButton
                color="primary"
                disabled={!this.state.statistics}
                onClick={this.onDownloadStatistics}
              >
                Download
              </IonButton>
            </div>
          </div>
        </div>
        {this.renderStatistics()}
      </div>
    );
  }

  onOrder = (order: number) => {
    let orderAsc = true;
    if (order == this.state.order) orderAsc = !this.state.orderAsc;

    console.log("onOrder", order, orderAsc);
    let statistics = this.state.statistics.sort((a, b) => {
      if (order < 0) {
        return (
          (a["value"] < b["value"] || a["value"] == null
            ? -1
            : a["value"] > b["value"] || b["value"] == null
            ? 1
            : 0) * (orderAsc ? 1 : -1)
        );
      } else {
        return (
          (a["key"][order] < b["key"][order] || a["key"][order] == null
            ? -1
            : a["key"][order] > b["key"][order] || b["key"][order] == null
            ? 1
            : 0) * (orderAsc ? 1 : -1)
        );
      }
    });
    this.setState({ order, orderAsc, statistics });
  };

  renderStatistics() {
    log(
      LogLevel.UI_LIFECYCLE,
      "DailyPharmRecruitCompanyList.render",
      this.props,
      this.state
    );
    if (!this.state.statistics || !this.state.statistics.length) return null;

    let total = 0;
    for (let i = 0; i < this.state.statistics.length; i++) {
      total += this.state.statistics[i].value;
    }

    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            {this.state.group.map((item, index) => {
              let result = item;
              return (
                <td
                  key={index.toString()}
                  className="admin-table-label-x"
                  onClick={(e) => this.onOrder(index)}
                >
                  {result}{" "}
                  {this.state.order == index
                    ? this.state.orderAsc
                      ? "▲"
                      : "▼"
                    : ""}
                </td>
              );
            })}

            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(-1)}
            >
              수치{" "}
              {this.state.order == -1 ? (this.state.orderAsc ? "▲" : "▼") : ""}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(-1)}
            >
              비율{" "}
              {this.state.order == -1 ? (this.state.orderAsc ? "▲" : "▼") : ""}
            </td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.statistics.map((item: any, index) => {
            log(
              LogLevel.UI_LIFECYCLE,
              "DailyPharmRecruitCompanyList.render item",
              item
            );
            // return null;
            return (
              <tr key={index.toString()}>
                {item.key.map((group, index) => {
                  let result = group;
                  if (this.state.group[index] == "workType") {
                    result = UserWorkTypeName[group];
                  } else if (this.state.group[index] == "gender") {
                    result = gender[group];
                  }
                  return (
                    <td key={index.toString()} className="admin-table-value">
                      {result}
                    </td>
                  );
                })}

                <td className={"admin-table-value"}>{item.value}</td>
                <td className={"admin-table-value"}>
                  {((item.value * 100) / total).toFixed(1)} %
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="admin-table-value" colSpan={this.state.group.length}>
              총합
            </td>

            <td className={"admin-table-value"}>{total}</td>
            <td className={"admin-table-value"} />
          </tr>
        </tbody>
      </table>
    );
  }
}

export default UserConditionedDistributionStatisticsView;
