import React from "react";
import { PopupWrapper, Shadow } from "./style";

export interface PopupProps {
  children?: any;
  onClose?: () => void;
  closeOnBackDrop?: boolean;
  height?: string;
  maxHeight?: string;
}

const Popup: React.FC<PopupProps> = ({
  children,
  onClose,
  closeOnBackDrop,
  height = "auto",
  maxHeight,
}) => {
  return (
    <Shadow
      onClick={(e) => {
        e.stopPropagation();
        if (closeOnBackDrop) onClose();
      }}
    >
      <PopupWrapper
        height={height}
        maxHeight={maxHeight}
        onClick={(e) => {
          if (closeOnBackDrop) e.stopPropagation();
        }}
      >
        {children}
      </PopupWrapper>
    </Shadow>
  );
};

export default Popup;
