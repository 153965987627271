import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { log, LogLevel } from "../../../utils/LogUtil";
import { AllInOnePartnerInfo } from "../../../models/Model.User";
import Text from "../../atoms/Text";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { Flex } from "../../atoms/Layout";
import { BoardContent } from "../../../models/Model.Board";
import "./PostListItem.scss";
import Avatar from "../../atoms/Avatar";
import Tag from "../../atoms/Tag";
import { partnerThumbnail } from "../../../store/board/types";

export interface AllinOneHotContentsListProps {
  content: BoardContent;
  contents: any;
  onClick: () => void;
}
const PartnerHotContentsWrapper = styled.div`
  display: flex;
  width: 80%;
  height: 64px;
  display: flex;
  gap: 20px;

  .partner-content-categories {
    border-radius: 10px;
    max-width: 60px;
    max-height: 60px;
  }

  .partner-new-content {
    /* border-radius: 10px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    border: 1px solid #e0e0e0;
  }
`;

const AllinOneHotContentsList: React.FC<
  AllinOneHotContentsListProps & RouteComponentProps
> = ({ content, contents, onClick }) => {
  if (!content) return null;

  try {
    return (
      <PartnerHotContentsWrapper onClick={onClick}>
        <img
          style={{
            height: "60px",
            width: "60px",
            minWidth: "60px",
          }}
          className="partner-content-categories"
          src={
            contents[content.id].image
              ? contents[content.id].image
              : partnerThumbnail(contents[content.id].subCategories[0].name)
          }
          onError={(e: any) =>
            (e.target.src = partnerThumbnail("default_partners"))
          }
        />
        <Flex
          direction="column"
          customStyle={{
            gap: "4px",
            justifyContent: "center",
          }}
        >
          <Text
            textType="Body2SemiBold"
            color={COLOR_SYSTEM.get("Gray")[800]}
            numberOfLines={2}
          >
            {contents[content.id].subject}
          </Text>
          <Flex customStyle={{ gap: "4px", alignItems: "center" }}>
            <Avatar
              size="XSmall"
              url={contents[content.id].usersProfileUrl}
              border={true}
            />
            <Text
              numberOfLines={1}
              textType="CaptionBold"
              color={COLOR_SYSTEM.get("Gray")[300]}
            >
              {contents[content.id].usersNickname}{" "}
            </Text>
            {contents[content.id].subCategories &&
              contents[content.id].subCategories.length > 0 && (
                <Tag size="Small" variant="Ghost" color="Blue">
                  {contents[content.id].subCategories[0].name}
                </Tag>
              )}
          </Flex>
        </Flex>
      </PartnerHotContentsWrapper>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllinoneHotContentsListItemTemplate",
      errorMessage: error,
    });

    log(
      LogLevel.UI_EXCEPTION,
      "AllinOneHotContentsList.exception",
      error.stack,
      error.message
    );
  }
};

export default withRouter(AllinOneHotContentsList);
