import React, { FC } from "react";
import styled, { StyledComponent } from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

type SeparatorType = "Block" | "Line";

const SeparatorStyle = {
  Block: {
    height: "16px",
    color: COLOR_SYSTEM.get("Gray")[50],
  },
  Line: {
    height: "1px",
    color: COLOR_SYSTEM.get("Gray")[50],
  },
};

interface ContainerProps {
  type: SeparatorType;
}

const Container: StyledComponent<
  "div",
  FC,
  ContainerProps
> = styled.div<ContainerProps>`
  width: 100%;
  height: ${(props) => SeparatorStyle[props.type].height};
  background: ${(props) => SeparatorStyle[props.type].color};
`;

export interface Props {
  type: SeparatorType;
}

const Separator: FC<Props> = ({ type }) => {
  return <Container type={type} />;
};

export default Separator;
