import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import { PullUpModalFooter, PullUpModalMain } from "./style";

interface RaisePopupProps extends PopupProps {
  handleRaiseClick?: () => Promise<void>;
}

const RaisePopup: React.FC<RaisePopupProps> = ({ onClose, handleRaiseClick }) => {
  return (
    <Popup onClose={onClose}>
      <PullUpModalMain>
        <Text element="h4" textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          이 공고를 끝어올릴까요?
        </Text>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
          공고 리스트 최상단으로 이동합니다.
          <br />
          끌어올리기는 하루 한번만 가능하며
          <br />
          내일 00:00에 다시 할 수 있어요
        </Text>
      </PullUpModalMain>
      <PullUpModalFooter>
        <Button color="Secondary" size="Large" type="Text" variant="Tinted" onClick={onClose}>
          취소
        </Button>
        <Button color="Primary" size="Large" type="Text" variant="Contained" onClick={handleRaiseClick}>
          끌어올리기
        </Button>
      </PullUpModalFooter>
    </Popup>
  );
};

export default RaisePopup;
