import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

interface CustomStyle {
  customStyle?: React.CSSProperties;
  selectable?: boolean;
}

interface FlexProps extends CustomStyle {
  direction?: string;
  gap?: string;
  alignItems?: string;
  justifyContent?: string;
  flexWrap?: string;
}

interface StaticProps extends CustomStyle {
}

interface NonStaticProps extends CustomStyle {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  zIndex?: number;
}

export const Header = styled.header<CustomStyle>`
  width: 100%;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  ${({ customStyle }) => customStyle};
`;

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  ${({ customStyle }) => customStyle};
`;

export const Static = styled.div<StaticProps>`
  ${({ customStyle }) => customStyle};
  ${({ selectable }) => selectable?`
    user-select: auto;
    -moz-user-select: auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
    -o-user-select: auto;
    span,a,u,strong,blockquote,ul,li {
      user-select: text;
      -moz-user-select: text;
      -khtml-user-select: text;
      -webkit-user-select: text;
      -o-user-select: text;
    }
    img {
      user-select: auto;
      -moz-user-select: auto;
      -khtml-user-select: auto;
      -webkit-user-select: auto;
      -o-user-select: auto;
    }
  `:""}
`;

export const Absolute = styled.div<NonStaticProps>`
  position: absolute;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
  ${({ customStyle }) => customStyle};
`;

export const Relative = styled.div<NonStaticProps>`
  position: relative;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
  ${({ customStyle }) => customStyle};
`;

export const Sticky = styled.div<NonStaticProps>`
  position: sticky;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
  ${({ customStyle }) => customStyle};
`;

export const Fixed = styled.div<NonStaticProps>`
  position: fixed;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
  ${({ customStyle }) => customStyle};
`;
