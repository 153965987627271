import React, { ComponentType, CSSProperties } from 'react';
import './style.css';
import COLOR from '../../_design/color';


interface Props {
  speed?: number,
  thickness?: number,
  color?:string,
  size?:number,
  block?
}

const Spinner = ({
  speed,
  thickness,
  color,
  size,
  block,
  ...svgProps
}:Props) => {
  const strokeWidth = 4 * (thickness / 100);

  let icon = (
    <svg width={size} height={size} fill="none" {...svgProps} viewBox="0 0 66 66">
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke={COLOR.Gray7}
        strokeWidth={strokeWidth}
      />
      <circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke={color}
        strokeDasharray="1, 174"
        strokeDashoffset="306"
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        style={{animation: `spinners-react-circular ${150 / speed}s linear infinite`}}
      />
    </svg>
  );

  if(block){
    return (
      <div className="common-container-column common-flex-align-center">
        {icon}
      </div>
    );  
  }

  return icon;

};

Spinner.defaultProps = {
  speed: 100,
  thickness: 100,
  color: COLOR.Black,
  size: 30,
};


export default Spinner;
