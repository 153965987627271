import React from "react";
import { PostSort } from "../../../models/Model.JobPost";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheet, { BottomSheetProps } from "../../molecules/BottomSheet/BottomSheet";
import { SortList } from "./style";

interface ListSortFilterBottomSheetProps extends BottomSheetProps {
  sort: PostSort;
  onSortClick: (order: PostSort) => () => void;
}

const ListSortFilterBottomSheet: React.FC<ListSortFilterBottomSheetProps> = ({
  sort,
  active,
  onClose,
  onSortClick,
}) => {
  return (
    <BottomSheet active={active} onClose={onClose} height={"200px"}>
      <SortList>
        <li onClick={onSortClick("raised")}>
          <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
            최신순
          </Text>
          <Icon
            width={24}
            height={24}
            fill={sort === "raised" ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]}
            name={"Check"}
          />
        </li>
        <li onClick={onSortClick("distance")}>
          <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
            거리순
          </Text>
          <Icon
            width={24}
            height={24}
            fill={sort === "distance" ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]}
            name={"Check"}
          />
        </li>
      </SortList>
    </BottomSheet>
  );
};

export default ListSortFilterBottomSheet;
