import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Icon from '../Icon'

import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from './../Text'

type ColorType =
"Blue" | 
"Green" | 
"Red" | 
"Orange" | 
"Brown" | 
"Gray1" |
"Gray2" |
"Shadow" |
"Primary" | 
"Secondary" |
"White"

type SizeType =
"S" | 
"M" |
"L"

type VariantType =
"Ghost" | 
"Tinted" |
"Contained" 
const BADGE_SIZE = {
  "S": {
    padding: "4px 6px",
    borderRadius: "8px",
    typo: "Badge",
    iconSize: "12",
    iconMargin: "2px",
    iconMinusPadding: "-2px"
  },
  "M": {
    padding: "7px 9px",
    borderRadius: "8px",
    typo: "Body2",
    iconSize: "18",
    iconMargin: "4px",
    iconMinusPadding: "-2px"
  },
  "L": {
    padding: "10px 12px",
    borderRadius: "8px",
    typo: "Headline",
    iconSize: "22",
    iconMargin: "6px",
    iconMinusPadding: "-2px"
  },

}
const BADGE_VARIANT_COLOR = {
  'Ghost': {
    "Blue": {
      background: COLOR.Transparent,
      color: "Blue",
    }, 
    "Green": {
      background: COLOR.Transparent,
      color: "Green",
    }, 
    "Red": {
      background: COLOR.Transparent,
      color: "Red",
    }, 
    "Orange": {
      background: COLOR.Transparent,
      color: "Orange",
    }, 
    "Brown": {
      background: COLOR.Transparent,
      color: "Brown",
    }, 
    "Gray1":{
      background: COLOR.Transparent,
      color: "Gray2",
    },
    "Gray2":{
      background: COLOR.Transparent,
      color: "Gray4",
    },
    "Shadow":{
      background: COLOR.Transparent,
      color: "Transparent",
    },
    "Primary":{
      background: COLOR.Transparent,
      color: "Primary",
    },
    "Secondary":{
      background: COLOR.Transparent,
      color: "Secondary",
    },
    "White":{
      background: COLOR.Transparent,
      color: "Secondary",
    },
  },
  'Tinted': {
    "Blue": {
      background: COLOR.LightBlue,
      color: "Blue",
    }, 
    "Green": {
      background: COLOR.LightGreen,
      color: "Green",
    }, 
    "Red": {
      background: COLOR.LightRed,
      color: "Red",
    }, 
    "Orange": {
      background: COLOR.LightOrange,
      color: "Orange",
    }, 
    "Brown": {
      background: COLOR.LightBrown,
      color: "Brown",
    }, 
    "Gray1":{
      background: COLOR.Gray7,
      color: "Gray2",
    },
    "Gray2":{
      background: COLOR.Gray8,
      color: "Gray4",
    },
    "Shadow":{
      background: COLOR.Transparent,
      color: "Transparent",
    },
    "Primary":{
      background: COLOR.Transparent,
      color: "Primary",
    },
    "Secondary":{
      background: COLOR.Transparent,
      color: "Secondary",
    },
    "White":{
      background: COLOR.Transparent,
      color: "Secondary",
    },
  },
  'Contained': {
    "Blue": {
      background: COLOR.Blue,
      color: "White",
    }, 
    "Green": {
      background: COLOR.Green,
      color: "White",
    }, 
    "Red": {
      background: COLOR.Red,
      color: "White",
    }, 
    "Orange": {
      background: COLOR.Orange,
      color: "White",
    }, 
    "Brown": {
      background: COLOR.Brown,
      color: "White",
    }, 
    "Gray1":{
      background: COLOR.Gray2,
      color: "White",
    },
    "Gray2":{
      background: COLOR.Gray4,
      color: "White",
    },
    "Shadow":{
      background: COLOR.Shadow50,
      color: "White",
    },
    "Primary":{
      background: COLOR.White,
      color: "Primary",
    },
    "Secondary":{
      background: COLOR.White,
      color: "Secondary",
    },
    "White":{
      background: COLOR.Gray1,
      color: "White",
    },
  },
}

interface ContainerProps {
  color: ColorType
  variant: VariantType
  size: SizeType
}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.a<ContainerProps>`
  padding:  ${(props) => BADGE_SIZE[props.size].padding};
  background: ${(props) => BADGE_VARIANT_COLOR[props.variant][props.color].background};
  border-radius: ${(props) => BADGE_SIZE[props.size].borderRadius};
  display: inline-block;
`

export interface Props {
  color: ColorType | string
  variant: VariantType
  size: SizeType
  iconName?: string
}
const ColorBadge: FC<Props> = ({ color="Blue", variant="Contained", size="S", children, iconName }) => {
  return (
    <Container
      color={color}
      size={size}
      variant={variant}
    >
      <div className="common-flex-row common-flex-align-center">
        {(iconName) && 
          <div style={{height: BADGE_SIZE[size].iconSize+"px", marginRight:BADGE_SIZE[size].iconMargin, marginLeft:BADGE_SIZE[size].iconMinusPadding}} >
            <Icon width={BADGE_SIZE[size].iconSize} height={BADGE_SIZE[size].iconSize} colorName={BADGE_VARIANT_COLOR[variant][color].color} name={iconName}/>
          </div>
        }
        <Text type={BADGE_SIZE[size].typo} color={BADGE_VARIANT_COLOR[variant][color].color}>
        {children}
        </Text>
      </div>
    </Container>
  )
}

export default ColorBadge
