import React, { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'

import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'

type TextType =
"H1" | 
"H2" | 
"H3" | 
"H4" | 
"Headline" | 
"SubHead" |
"Body1" | 
"Body2" | 
"ButtonL" | 
"ButtonM" | 
"ButtonS" | 
"Caption1" | 
"Caption2"

type TextElement = 'h1' | 'h2' | 'h3' | 'h4' | 'a' | 'span' | 'p' | 'address' | 'div'

interface ContainerProps {
  type?: TextType
  color?: string
  colorHex?: string
  numberOfLines?: number
  className?: string
  align?: 'left' | 'center'
}

const Container: StyledComponent<'a', FC, ContainerProps> = styled.a<ContainerProps>`
  margin: 0px;
  color: ${(props) => props.colorHex?props.colorHex:(props.color?COLOR[props.color]:"inherit")};
  font-family:'Pretendard' ,'sans';
  text-align: ${(props) => props.align};
  &.numberOfLines {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => props.numberOfLines};
  }

  ${(props) => props.type?TYPOGRAPY[props.type]:""}
`

export interface Props {
  type?: string
  color?: string
  colorHex?:string
  style?: any
  element?: TextElement
  numberOfLines?: number
  className?: string
  onClick?: (e) => void
  align?: 'left' | 'center'
}
const Text: FC<Props> = ({ type, style, numberOfLines, color, colorHex, element = 'span', children, className, align="left", onClick }) => {
  let classname = "";
  if(className)
    classname = className;
  if(numberOfLines && numberOfLines > 0){
    if(classname)
      classname += " ";
    classname += "numberOfLines"
  }

  return (
    <Container
      as={element}
      type={type}
      color={color}
      colorHex={colorHex}
      numberOfLines={numberOfLines}
      className={classname}
      align={align}
      onClick={onClick}
      style={style}
    >
      {children}
    </Container>
  )
}

export default Text
