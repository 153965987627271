import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonModal,
  IonLoading,
  IonToast,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonFooter,
  IonInput,
  IonItem,
  IonList,
} from "@ionic/react";
import "./ProductOrder.css";
import "./../../pages/ProductSaleOrder.css";
import * as API from "../../API.json";
import { OrderInfo, ProductInfo, ProductStatus } from "../../models/Model.Sale";
import { log, LogLevel } from "../../utils/LogUtil";
import { UIPopupType } from "../../store/ui/types";
import SwipeableViews from "react-swipeable-views";
import ReactHtmlParser from "react-html-parser";
import StringUtil from "../../utils/StringUtil";
import { KeywordType } from "../../models/Model.Medicine";
import ComponentGenerator from "../../ivcomponent/ComponentGenerator";
import LinkMeta from "../LinkMeta";
import drugIcon from "./../../assets/icon/drug.svg";
import { MY_ADDRESS } from "../../config.json";
import { Reference } from "../../models/Model.Board";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import NumberSelector from "../NumberSelector";
import { UserInfo } from "../../models/Model.User";
import Truncate from "react-truncate";
import { fetchAPI } from "../../utils/APIUtil";
import { loadImageBase64 } from "../../utils/ImageUtil";

type Props = {
  me: UserInfo;
  onCancel: () => void;
  onDone: (newMe: UserInfo) => void;
};

type State = {
  registration: string;
  registrationBase64: any;
};

const windowAny: any = window;

class ProductOrderRegistration extends Component<Props, State> {
  onProgress: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      registration: this.props.me.workPlaceRegistration,
      registrationBase64: null,
    };
  }

  componentDidMount() {}

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "ProductOrderRegistration:goBack");
    this.props.onCancel();
  };

  onDone = () => {
    this.props.onDone({
      id: this.props.me.id,
      workPlaceRegistration: this.state.registration,
      workPlaceRegistrationImageBase64: this.state.registrationBase64,
    });
  };

  onClickCamera = () => {
    log(LogLevel.UI_ACTION, "ProductOrderRegistration:onClickCamera");
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") return;

    if (
      windowAny.navigator &&
      windowAny.navigator.camera &&
      windowAny.navigator.camera.getPicture
    ) {
      windowAny.navigator.camera.getPicture(
        this.onCameraSuccess,
        this.onCameraFail,
        {
          quality: 80,
          destinationType: windowAny.Camera.DestinationType.DATA_URL,
          encodingType: windowAny.Camera.EncodingType.JPEG,
          mediaType: windowAny.Camera.MediaType.PICTURE,
          correctOrientation: true,
          targetWidth: 1920,
          targetHeight: 1920,
        }
      );
    }
  };

  onCameraSuccess = (imageURI) => {
    this.setState({ registrationBase64: "data:image/jpeg;base64," + imageURI });
    // setTimeout(() => {
    //   // this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
    //   this.contentScroll.scrollTo(0, this.contentScroll.clientHeight)
    // }, 300);
  };

  onCameraFail = (message) => {
    log(
      LogLevel.UI_EXCEPTION,
      "ProductOrderRegistration::onCameraFail Failed because: ",
      message
    );
  };

  onImageSelected = (e) => {
    // console.dir(e);
    console.dir(e.target.files[0]);
    console.dir(e.target);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0]).then((base64) => {
        this.onImageLoad(base64);
      });
    }
  };

  onImageLoad = (base64) => {
    this.setState({ registrationBase64: base64 });
    // setTimeout(() => {
    //   // this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
    //   this.contentScroll.scrollTo(0, this.contentScroll.clientHeight)
    // }, 300);
    // log(LogLevel.UI_EVENT, "RegisterStage2a:onImageLoad", this.contentScroll, this.contentScroll.clientHeight)
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductOrderRegistration.render");
    let attachmenet;
    let os = getGlobal(GlobalKey.OS);
    if (os && os != "browser") {
      attachmenet = (
        <div className="product-order-additional-information-upload-button-container">
          <IonButton
            class="product-order-additional-information-upload-button"
            fill="clear"
            onClick={this.onClickCamera}
          >
            <IonIcon
              class="product-order-additional-information-upload-button-icon"
              name="camera"
            />
            <div className="product-order-additional-information-upload-button-text">
              촬영하기
            </div>
          </IonButton>
        </div>
      );
    }

    let attachmentViewer;
    if (this.state.registrationBase64) {
      attachmentViewer = (
        <div className="product-order-additional-information-upload-viewer-container">
          <img
            className="product-order-additional-information-upload-viewer"
            src={this.state.registrationBase64}
          />
        </div>
      );
    } else if (this.props.me.workPlaceRegistrationUrl) {
      attachmentViewer = (
        <div className="product-order-additional-information-upload-viewer-container">
          <img
            className="product-order-additional-information-upload-viewer"
            src={StringUtil.convertFilePath(
              this.props.me.workPlaceRegistrationUrl
            )}
          />
        </div>
      );
    }

    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.goBack}>
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="common-header-title" onClick={this.goBack}>
                사업자 정보 입력
              </IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="common-content-with-header common-background-white common-scroll product-order-additional-information-container">
          <div className="product-order-additional-information-title">
            약국명
          </div>
          <div className="product-order-additional-information-input-container">
            <IonInput
              class="product-order-additional-information-input"
              placeholder="이름"
              value={this.props.me.workPlaceName}
            />
          </div>
          <div className="product-order-additional-information-title">
            사업자등록번호
          </div>
          <div className="product-order-additional-information-input-container">
            <IonInput
              class="product-order-additional-information-input"
              placeholder="사업자 등록번호"
              value={this.state.registration}
              onIonChange={(e) =>
                this.setState({ registration: e.detail.value })
              }
            />
          </div>
          <div className="product-order-additional-information-title">
            사업자등록증
          </div>
          {attachmentViewer}
          <div className="product-order-additional-information-upload-container">
            {attachmenet}
            <div className="product-order-additional-information-upload-button-container">
              <IonButton
                class="product-order-additional-information-upload-button"
                fill="clear"
              >
                <IonIcon
                  class="product-order-additional-information-upload-button-icon"
                  name="image"
                />
                <div className="product-order-additional-information-upload-button-text">
                  첨부하기
                </div>
              </IonButton>
              <input
                className="product-order-additional-information-upload-file"
                type="file"
                accept="image/*"
                onChange={this.onImageSelected}
              />
            </div>
          </div>
        </div>
        <IonFooter
          class="product-order-additional-information-footer"
          mode="ios"
        >
          <IonButton
            color="common-button"
            class="product-order-additional-information-done-button"
            onClick={this.onDone}
          >
            입력 완료
          </IonButton>
        </IonFooter>
      </>
    );
  }
}

type ItemProps = {
  onSelected: (address: string, postalcode: string) => void;
  item: any;
  key: string;
};

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {};

export default ProductOrderRegistration;
