import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

export const Header = styled.header`
  div {
    padding: 20px 8px;
  }
`;

// Main
export const DetailMainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin: 0px auto 0px;
  padding: 12px 24px 120px;
  background: #ffffff;

  h2,
  h4 {
    margin: 0;
  }

  .preview__title {
    display: block;
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 100px;
  }

  & > * + * {
    margin-top: 40px;
  }

  li {
    list-style: disc !important;
  }

  .share-btn {
    padding: 21px 16px;
    background-color: ${COLOR_SYSTEM.get("Gray")[10]};
    text-align: center;
    border-radius: 12px;

    span {
      display: block;
      margin-bottom: 16px;
    }
  }

  &.title-field {
    & > * + * {
      margin-top: 4px;
    }
  }

  .detail-count {
    margin-top: 12px;

    & > * + * {
      margin-left: 8px;
    }
  }

  &.company-info-field {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .company-name {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .company-address-count {
      margin-top: 4px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .company-logo {
      max-width: 200px;
      padding: 20px 10px;
      border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
      border-radius: 8px;
    }
  }

  ul {
    margin: 0;
    padding-inline-start: 20px;
  }

  .email-link {
    color: ${COLOR_SYSTEM.get("Blue")[500]};
    text-decoration: underline;
  }

  .work-place-address {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  .field__title {
    display: block;
    margin-bottom: 12px;
  }

  &.title-field {
    & > * + * {
      margin-top: 4px;
    }
  }

  .detail-count {
    margin-top: 12px;

    & > * + * {
      margin-left: 8px;
    }
  }

  &.company-info-field {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .company-name {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .company-address-count {
      margin-top: 4px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .company-logo {
      max-width: 200px;
      padding: 20px 10px;
      border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
      border-radius: 8px;
    }
  }

  ul {
    margin: 0;
    padding-inline-start: 20px;
  }

  .email-link {
    color: ${COLOR_SYSTEM.get("Blue")[500]};
    text-decoration: underline;
  }

  &.company-work-place {
    .work-place-address {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
`;

export const ButtonBox = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 76px;
  padding: 0 24px;
  line-height: 76px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};

  button {
    width: 100%;
  }
`;

export const UploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ImageView = styled.div`

`;

export const File = styled.div`
  padding: 12px;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .file-icon {
    width: 48px;
    height: 48px;
    background-color: ${COLOR_SYSTEM.get("Skyblue")[50]};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file-info {
    flex: 1;
  }
`;
