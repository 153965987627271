import React, { Component } from "react";
import "./ViewerComments.css";
import { BoardContent, BoardComment, Reference } from "./../models/Model.Board";
import { log, LogLevel } from "../utils/LogUtil";
import ViewerComment from "./ViewerComment";
import ViewerCommentComposer from "./ViewerCommentComposer";
import { UserInfo } from "../models/Model.User";
import ViewerCommentOpenComposer from "./ViewerCommentOpenComposer";
import { RootState } from "../store";
import { connect } from "react-redux";
import { UIPopupType } from "../store/ui/types";
import { Flex, Static } from "./atoms/Layout";
import { COLOR_SYSTEM } from "./design/design-system";
import Avatar from "./atoms/Avatar";
import Text from "./atoms/Text";
import { getDateTimeString } from "../utils/TimeUtil";
import Button from "./atoms/Button";
import CheckBox from "./atoms/CheckBox/CheckBox";
import Textarea from "react-textarea-autosize";

const windowAny: any = window;

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    open?: boolean;
    comments?: BoardComment[];
    postingId?: number;
    postingGroupId?: number;
    noComposer?: boolean;
    key?: string;
    onComment?: (id: number, comment: BoardComment) => any;
    onUserPressed?: (id: number) => any;
    onPartnerPressed?: (id: string) => void;
    onChange?: (noti: BoardComment, index: number) => any;
    onDelete?: (noti: BoardComment, index: number, isAdmin: boolean) => any;
    onReport?: (noti: BoardComment, type: number) => any;
    onReply?: (noti: BoardComment, index: number) => any;
    onLike?: (noti: BoardComment, index: number) => any;
    onDislike?: (noti: BoardComment) => any;
    lastViewedAt?: any;
    findFocus?: any;
    onFindFocusComponent?: (focused: any) => void;
    isDiscussion?: boolean;
    canDislike?: boolean;
    canAnonymous?: boolean;
    emphasisLike?: boolean;
    anonymousNames?: any;
    rootAuthorId?: number;
    rootAuthorName?: string;
  };

class ViewerComments extends Component<Props> {
  constructor(props: Props) {
    super(props);
    log(LogLevel.UI_LIFECYCLE, "ViewerComments.constructor", this.props);
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.findFocus != this.props.findFocus &&
      nextProps.findFocus.commentId != this.props.findFocus.commentId
    ) {
      return true;
    }

    if (nextProps.comments === this.props.comments) {
      log(
        LogLevel.UI_LIFECYCLE,
        "ViewerComments.shouldComponentUpdate same",
        nextProps.comments
      );
      return false;
    }
    return true;
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ViewerComments.render", this.props);
    let comments = this.props.comments;

    // if((!comments || comments.length == 0) && (!this.props.me || !this.props.me.profileUrl))
    //   return null;

    if (!comments) {
      comments = [];
    }

    let composer;
    let defaultAnonymous = false;
    if (
      this.props.anonymousNames &&
      this.props.anonymousNames[this.props.me.id]
    )
      defaultAnonymous = true;

    if (!this.props.noComposer && this.props.onComment) {
      composer = (
        <ViewerCommentComposer
          id={this.props.postingId}
          defaultAnonymous={defaultAnonymous}
          canAnonymous={this.props.canAnonymous}
          groupId={this.props.postingGroupId}
          profile={this.props.me.profileUrl}
          onDone={(comment) =>
            this.props.onComment(this.props.postingId, comment)
          }
        />
      );
    }

    log(LogLevel.UI_LIFECYCLE, "ViewerComments.render", comments);

    return (
      <div>
        {!this.props.noComposer && this.props.onComment && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            customStyle={{
              padding: "18px 0",
              marginBottom: "20px",
              borderBottom: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
            }}
          >
            <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[700]}>
              댓글 {comments.length}개
            </Text>
          </Flex>
        )}
        {comments.map((item, index) => (
          <ViewerComment
            key={index.toString()}
            open={this.props.open}
            comment={item}
            index={index}
            lastViewedAt={this.props.lastViewedAt}
            onChange={this.props.onChange}
            onDelete={this.props.onDelete}
            onReport={this.props.onReport}
            onReply={this.props.onReply}
            onLike={this.props.onLike}
            noComposer={this.props.noComposer}
            onDislike={this.props.onDislike}
            onUserPressed={this.props.onUserPressed}
            onPartnerPressed={this.props.onPartnerPressed}
            findFocus={this.props.findFocus}
            onFindFocusComponent={this.props.onFindFocusComponent}
            isDiscussion={this.props.isDiscussion}
            canDislike={this.props.canDislike}
            canAnonymous={this.props.canAnonymous}
            defaultAnonymous={defaultAnonymous}
            anonymousNames={this.props.anonymousNames}
            emphasisLike={this.props.emphasisLike}
            rootAuthorId={this.props.rootAuthorId}
            rootAuthorName={this.props.rootAuthorName}
          />
        ))}
        {composer}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  filePath: state.board.filePath,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewerComments);
