import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { fetchAPI } from "../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../models/Model.Seminar";
import { UIPopupType } from "../store/ui/types";
import { STATUS_CODES } from "http";
import {
  PollInfo,
  PollItemInfo,
  PollResponseInfo,
  PollResponseItemInfo,
} from "../models/Model.Poll";
import PollSelect from "./component/poll/PollSelect";
import { idText } from "typescript";
import { JobApplyInfo, JobOfferInfo } from "../models/Model.JobPost";
import JobpostList from "./component/bizjobpost/JobpostList";
import JobpostDetail from "./component/bizjobpost/JobpostDetail";

const AdminAPI = {
  GET_LIST: {
    method: "POST",
    path: "/admin/seminar/lectures",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  offer: JobOfferInfo;
};

class BizJobpostManage extends Component<Props, State> {
  lecturesList = null;
  lectureEditor = null;
  popup = null;
  state = {
    offer: null,
    apply: null,
  };

  constructor(props: Props) {
    super(props);
    document.title = "법인 구인구직 현황";
  }

  componentDidMount() {}

  onSelectOffer = (offer: JobOfferInfo) => {
    this.setState({ offer });
  };

  onUnselect = () => {
    this.setState({ offer: null });
  };

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "BizJobpostManage.render",
      this.props,
      this.state
    );

    let detail;
    if (this.state.offer || this.state.apply) {
      detail = (
        <div className="admin-main-container">
          <div className="admin-grow-container">
            <JobpostList onSelectOffer={this.onSelectOffer} />
          </div>
          <div id="admin-sub-conainer" className="admin-container">
            <JobpostDetail offer={this.state.offer} onBack={this.onUnselect} />
          </div>
        </div>
      );
    } else {
      detail = (
        <div className="admin-main-container">
          <div className="admin-grow-container">
            <JobpostList onSelectOffer={this.onSelectOffer} />
          </div>
        </div>
      );
    }
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">법인 구인구직 현황</IonText>
        </IonHeader>

        <IonContent>{detail}</IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures: state.seminar.lectures,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId: number) =>
    actions.seminar.getSeminarLecture(lectureId),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BizJobpostManage)
);
