
import React, { Component } from 'react';
import './ComponentCompose.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem } from '@ionic/react';
import {fetchAPI} from '../utils/APIUtil'
import * as API from '../API.json';;
import Skeleton from 'react-loading-skeleton';
import smileIcon from './../assets/icon/smile.svg'
import { KeywordType, Medicine, Ingredient } from '../models/Model.Medicine';
import {compose} from 'redux'
import { connect, connectAdvanced } from 'react-redux';
import { RootState, actions} from '../store';
import {log, LogLevel} from '../utils/LogUtil'
import Textarea from 'react-textarea-autosize';
import { UIPopupType } from '../store/ui/types';
import ImageViewNSelect from '../admin/component/ImageViewNSelect';
import { loadImageBase64 } from '../utils/ImageUtil';
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onDone: (data) => void;
};

type State = {
  url: string;
  text: string;
  etcStr: string;
}

class ComponentComposeLink extends Component<Props, State> {

  static typeName = "JSON";
  value={component:"link", src:"", path:"/"};

  constructor(props:Props){
    super(props);
    this.state={
      url:"",
      text: "",
      etcStr:"",
    };
  }

  doneProcessing = false;
  onSubmit = async(e) => {
    log(LogLevel.UI_ACTION, 'ComponentComposeLink:onSubmit : ');
    if(this.doneProcessing)
      return;
    this.doneProcessing = true;

    let src="";

    let advertiserInfo: any = {};

    if(this.state.etcStr){

      try{
        advertiserInfo = JSON.parse(this.state.etcStr);
      }catch (e) {
        this.props.progressPopup.hide();
        this.doneProcessing = false;
        this.props.toast.show("광고주 정보가 정확하지 않습니다.");
        return;
      }
    }
    let data = {component:"link", text:this.state.text, path:this.state.url, ...advertiserInfo}
    this.props.onDone(data);
    e.stopPropagation();
    this.doneProcessing = false;
  }  

  onClick = (e) => {
    e.stopPropagation();
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ComponentComposeLink  : render");

    return (
          <div className="component-composer-sub-container" onClick={(e) => {e.stopPropagation()}}>
            <div className="common-container-row common-flex-align-center" onClick={(e) => {e.stopPropagation()}}>
              <div className="admin-title">text :</div>
              <IonInput class="user-search-input" placeholder="url(ex. /main/jobpost, www.daum.net)" value={this.state.text} onIonChange={(e) => this.setState({text:e.detail.value})}  onClick={(e) => {e.stopPropagation()}}/>
            </div>
            <div className="common-container-row common-flex-align-center" onClick={(e) => {e.stopPropagation()}}>
              <div className="admin-title">url :</div>
              <IonInput class="user-search-input" placeholder="url(ex. /main/jobpost, www.daum.net)" value={this.state.url} onIonChange={(e) => this.setState({url:e.detail.value})}  onClick={(e) => {e.stopPropagation()}}/>
            </div>
            <div className="common-container-row common-flex-align-center">
              <div className="user-manage-title">광고정보 (Oscar 문의)</div>
              <div className="admin-text-button" onClick={() => this.setState({etcStr:JSON.stringify(
                {
                  advertizerType:"", 
                  advertizerCode:"", 
                  advertizerProductCode:"", 
                  advertizerContentType:"", 
                  advertizerContentId:1, 
                  advertizerContentTitle:"", 
                  advertizerGsid: "", 
                  advertizerGstid: "", // google sheet tab name
                  advertizerShid: "", // slack hook url
                  advertizerStid: "", // stibee hook url
                  advertizerStfields: "", // stibee hook data
                }, null, "\t")})}>Default</div>
            </div>
            <Textarea className="admin-text-area" minRows={3} maxRows={15} value={this.state.etcStr} onChange={(e) => {this.setState({etcStr:e.target.value})}} onClick={(e)=>{e.stopPropagation();}}/>
            <IonButton expand="full" onClick={this.onSubmit} >완료하기</IonButton>
          </div>
    );
  }

}

const mapStateToProps = (state: RootState) => ({
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentComposeLink);