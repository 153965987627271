import { combineReducers } from "redux";

import user from "./user/reducer";
import search from "./search/reducer";
import medicine from "./medicine/reducer";
import board from "./board/reducer";
import notification from "./notification/reducer";
import ui from "./ui/reducer";
import event from "./event/reducer";
import seminar from "./seminar/reducer";
import banner from "./banner/reducer";
import gamification from "./gamification/reducer";
import jobpost from "./jobpost/reducer";
import sale from "./sale/reducer";
import allinone from "./allinone/reducer";
import popup from "./popup";
import confirm from "./modal/confirm";
import bottomSheet from "./modal/bottomsheet";
import newSearch from "./search";

const rootReducer = combineReducers({
  user,
  search,
  medicine,
  board,
  notification,
  ui,
  event,
  seminar,
  banner,
  gamification,
  jobpost,
  sale,
  allinone,
  popup,
  confirm,
  bottomSheet,
  newSearch,
});

export default rootReducer;
