import { JobOfferSalaryType, JobOfferTaxType, machineNames } from "../models/Model.JobPost";

/** 다중선택 가능 버튼 -> Request Data 형식으로 바꿔주는 함수 */
const convertMultiSelectToRequestData = (buttons: boolean[], etc?: string | null, coupler?: any[]) => {
  let result = "";

  buttons.forEach((item, index) => {
    if (item && coupler[index] !== "기타") result += `#${coupler[index]}`;
  });

  return `${result}▒${etc.trim() || ""}`;
};

/** 근무요일 */
const convertWorkdayArrToStr = (workDayArr: boolean[]) => {
  let workDay = "";
  const dayCoupler = ["월", "화", "수", "목", "금", "토", "일"];

  workDayArr.forEach((day, index) => {
    if (day) workDay += `${dayCoupler[index]} `;
  });

  return workDay.trimRight();
};

/** 다중선택 -> String */
const convertMultSelectToStr = (isSelect: boolean[], etc?: string | null, model?: any[]) => {
  let result = [];

  isSelect.forEach((item, index) => {
    if (item) {
      if (index === isSelect.length - 1) {
        result.push(etc);
      } else {
        result.push(model[index]);
      }
    }
  });

  return result.join(", ");
};

/** 입력값 -> 단위로 환산 (ex. 16454 -> 1만6,454) */
const numberToUnit = (number: number, tenThousand: boolean = false) => {
  if (!number || number < 0) return "0";

  // 돈 단위
  const unitWords = tenThousand ? ["만", "억", "조", "경"] : ["", "만", "억", "조", "경"];
  // 단위 나누는 기준
  const splitUnit = 10000;
  // 돈 단위 배열 length
  const splitCount = unitWords.length;
  const resultArray = [];
  let resultString = "";

  for (let i = 0; i < splitCount; i++) {
    let unitResult = Math.floor((number % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i));
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString = resultArray[i].toLocaleString() + unitWords[i] + resultString;
  }

  return resultString;
};

/** tabbar 숨기기 */
const hideTabbar = () => {
  const tabBar = document.querySelector(".appstack-tabbar") as HTMLElement | null;
  tabBar.setAttribute("style", "display: none");
};

/** tabbar 보이기 */
const displayTabbar = () => {
  const tabBar = document.querySelector(".appstack-tabbar") as HTMLElement | null;
  tabBar.setAttribute("style", "display: flex");
};

const isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    navigator.userAgent.match(/Opera Mini/i) ||
    navigator.userAgent.match(/IEMobile/i)
  ) {
    return true;
  }
};

const setSalaryRange = (salaryRangeLow: number, salaryRangeHigh: number) => {
  if (salaryRangeLow === salaryRangeHigh || salaryRangeLow === 0) return `${salaryRangeHigh}만원`;
  return `${salaryRangeLow.toLocaleString()}만원 ~ ${salaryRangeHigh.toLocaleString()}만원`;
};

/** 급여 변환 함수 */
const renderJobPostPay = (
  salaryType: JobOfferSalaryType,
  taxType: JobOfferTaxType,
  salaryRangeLow: number,
  salaryRangeHigh: number,
  isBiz?: boolean
) => {
  if (!salaryRangeLow && !salaryRangeHigh) return "추후 협의";

  const salaryTypeCoupler = {
    [JobOfferSalaryType.ANUAL]: "연봉",
    [JobOfferSalaryType.MONTHLY]: "월급",
    [JobOfferSalaryType.HOURLY]: "시급",
    [JobOfferSalaryType.WEEKLY]: "주급",
    [JobOfferSalaryType.DAILY]: "일급",
  };
  const taxTypeCoupler = {
    [JobOfferTaxType.PRE_TAX]: "세전",
    [JobOfferTaxType.AFTER_TAX]: "세후",
  };
  const type = salaryTypeCoupler[salaryType];
  const tax = taxTypeCoupler[taxType];
  const range = setSalaryRange(salaryRangeLow, salaryRangeHigh);

  return isBiz ? `${type} ${range}` : `${tax} ${type} ${range}`;
};

/** 휴대폰번호에 하이픈 추가하는 함수 */
const addHyphenToPhoneNumber = (phoneNumber: string = "") =>
  phoneNumber.replace(/^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?([0-9]{3,4})-?([0-9]{4})$/, "$1-$2-$3");

export const getByteSize = (size: number) => {
  const byteUnits = ["KB", "MB", "GB", "TB"];

  for (let i = 0; i < byteUnits.length; i++) {
    size = Math.floor(size / 1024);

    if (size < 1024) return `${size.toFixed(0)} ${byteUnits[i]}`;
  }
};

/** ISO -> String */
export const parseDate = (isoDate: string) => {
  const date = new Date(isoDate);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}.${month < 10 ? 0 : ""}${month}.${day < 10 ? 0 : ""}${day}`;
};

export {
  convertMultiSelectToRequestData,
  convertWorkdayArrToStr,
  convertMultSelectToStr,
  numberToUnit,
  hideTabbar,
  displayTabbar,
  isMobile,
  renderJobPostPay,
  addHyphenToPhoneNumber,
};
