
import React, { Component } from 'react';
import './ComponentCompose.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem } from '@ionic/react';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';;
import Skeleton from 'react-loading-skeleton';
import smileIcon from './../assets/icon/smile.svg'
import { KeywordType, Medicine, Ingredient } from '../models/Model.Medicine';
import {compose} from 'redux'
import { connect, connectAdvanced } from 'react-redux';
import { RootState, actions} from '../store';
import {log, LogLevel} from '../utils/LogUtil'
import Textarea from 'react-textarea-autosize';
import { UIPopupType } from '../store/ui/types';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onDone: (data) => void;
};

type State = {
  texts: string;
}

class ComponentComposeJson extends Component<Props, State> {

  static typeName = "JSON";
  defaults=[
    {component:"invite", userid:0},
    {component:"userinfo", userid:0},
    {component:"banner", src:"", path:"/"},
  ];

  constructor(props:Props){
    super(props);
    let data = this.defaults[0];
    this.state={
      texts: JSON.stringify(data, null, "\t"),
    };
  }

  onSubmit = (e) => {
    try {
      let data = JSON.parse(this.state.texts)
      this.props.onDone(data)
    } catch (error) {
      this.props.toast.show("입력값이 잘못되었습니다.")
    }

    e.stopPropagation();
    
  }

  onSelect = (index:number) =>{
    let data = this.defaults[index];
    this.setState({texts: JSON.stringify(data, null, "\t"),})
  }
  

  onClick = (e) => {
    e.stopPropagation();
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ComponentComposeJson  : render");

    return (
          <div className="component-composer-sub-container">
            <div className="common-container-row">
              <IonButton class="component-composer-preset-button" onClick={(e) => {e.stopPropagation(); this.onSelect(0);}} >Invite</IonButton>
              <IonButton class="component-composer-preset-button" onClick={(e) => {e.stopPropagation(); this.onSelect(1);}} >User</IonButton>
              <IonButton class="component-composer-preset-button" onClick={(e) => {e.stopPropagation(); this.onSelect(2);}} >Banner</IonButton>
            </div>
            <textarea className="component-composer-textarea" onChange={(e) => {this.setState({texts:e.target.value})}} value={this.state.texts} onClick={this.onClick}/>
            <IonButton expand="full" onClick={this.onSubmit} >완료하기</IonButton>
          </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentComposeJson);