import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { Page } from "./FinishJobPostPopup";

interface JobPostPopupStyledProps {
  page?: Page;
  selected?: boolean;
}

const PopupBody = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * + * {
    margin-top: 20px;
  }
`;

const SuccessPopupFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    flex: 1;
  }

  .alim__button {
    position: relative;

    .hint {
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      height: 30px;
      background-color: ${COLOR_SYSTEM.get("Gray")[700]};
      padding: 2px 6px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        background-color: ${COLOR_SYSTEM.get("Gray")[700]};
      }
    }
  }
`;

const PopupFooter = styled.div`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }

  .alim__button {
    position: relative;

    .hint {
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      height: 30px;
      background-color: ${COLOR_SYSTEM.get("Gray")[700]};
      padding: 2px 6px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 10px;
        height: 10px;
        background-color: ${COLOR_SYSTEM.get("Gray")[700]};
      }
    }
  }
`;

const PullUpModalMain = styled.main`
  display: flex;
  flex-direction: column;
  text-align: center;

  & > * + * {
    margin-top: 12px;
  }
`;

const PullUpModalFooter = styled.footer`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

const RaiseErrorPopupMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > * + * {
    margin-top: 12px;
  }
`;

const FinishPostPopupMain = styled.div<JobPostPopupStyledProps>`
  width: 100%;
  height: 100%;
  text-align: ${({ page }) => (page === "Ask" ? "center" : "left")};
  ${({ page }) => {
    if (page === "Finish") {
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
      `;
    }
  }}
`;

const IsSuccessSelectorList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  height: calc(100% - 100px);
  overflow-y: scroll;

  & > * + * {
    margin-top: 24px;
  }
`;

const IsSuccessSelectorItem = styled.li`
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  display: flex;
  align-items: center;
  cursor: pointer;

  & > * + * {
    margin-left: 16px;
  }

  img {
    width: 32px;
  }
`;

const FinishPostPopupCadidates = styled.ul`
  width: 100%;
  margin-top: 15px;
`;

const FinishPostPopupCadidateItem = styled.li<JobPostPopupStyledProps>`
  background-color: ${({ selected }) =>
    selected ? COLOR_SYSTEM.get("Skyblue")[10] : "transparent"};
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .profile {
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 50%;
  }

  .list-item__name {
    flex: 1;
  }
`;

const FinishPostPopupFooter = styled.footer`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

const ColumnDirectionButtonContainer = styled.div`
  width: 100%;

  & > * + * {
    margin-top: 8px;
  }

  button {
    width: 100%;
  }
`;

const RowDirectionButtonContainer = styled.div`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

export {
  PopupBody,
  PopupFooter,
  PullUpModalMain,
  PullUpModalFooter,
  SuccessPopupFooter,
  RaiseErrorPopupMain,
  FinishPostPopupMain,
  FinishPostPopupFooter,
  FinishPostPopupCadidateItem,
  FinishPostPopupCadidates,
  IsSuccessSelectorList,
  IsSuccessSelectorItem,
  ColumnDirectionButtonContainer,
  RowDirectionButtonContainer,
};
