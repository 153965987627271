import React, { Children, Component } from 'react';
import './style.scss';
import { IonModal} from '@ionic/react';
import Text from './../../../atom/Text'
import StarScorer from '../../../atom/StarScorer';
import TextButton from '../../../atom/TextButton';
import COLOR from '../../../_design/color';
import Icon from '../../../atom/Icon';

type Props = {
  onDone: () => void;
};

type State = {
  visible: boolean;
  score: number;
}

class SeminarReviewPopup extends Component<Props, State> {

  state={
    visible: false,
    score: 0,
  };

  show = () => {
    this.setState({visible: true});
  }

  hide = () => {
    this.setState({visible: false});
  }

  isShown = () => {
    return this.state.visible;
  }

  onDone = () => {
    this.hide();
    if(this.props.onDone)
      this.props.onDone();
  }

  render() {
    return (
      <IonModal cssClass="seminar-review-done-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {false}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="seminar-review-done-popup-inner">
          <div className="seminar-review-done-popup-backdrop"  onClick={this.onDone}/>
          <div className="seminar-review-done-container-outer">
            <div className="seminar-review-done-container">
              <div className="seminar-review-done-body-container">
                <div className="seminar-review-done-body-icon">
                  <Icon width={60} height={60} name="Check Circle" fill={COLOR.Primary}/>
                </div>
                <div className="seminar-review-done-body-title">
                  <Text type="H4" color="Secondary">평가가 등록됐어요!</Text>
                </div>
              </div>
              <div className="seminar-review-done-buttons-container">
                  <TextButton size="L" variant="Contained" color="Secondary" block onClick={this.onDone}>닫기</TextButton>
              </div>
            </div>
          </div>
          <div className="seminar-review-done-popup-backdrop"  onClick={this.onDone}/>
        </div>
      </IonModal>
    );
  }
}

export default SeminarReviewPopup;