import React, { Component } from 'react';
import './Slider.css';
import { func } from 'prop-types';
import {log, LogLevel} from '../utils/LogUtil'
import { RootState } from '../store';
import { connect } from 'react-redux';
import { Reference } from '../models/Model.Board';
import {MY_ADDRESS} from './../config.json';
import StringUtil from '../utils/StringUtil';
import Sliders from 'rc-slider';

type Props = {
  values?: string[]; // index -> to Value
  valueMap?: any[]; // {name, value}
  onSelect? : (value) => void;
  value: number;
  isRange?: boolean;
};

class Slider extends Component<Props> {
  valueToInner:any = {};
  innerToValue:any = {};
  marks: any = {};
  values: number[] = [];
  interval: number = 0;

  slidesElement = null;

  constructor(props: Props) {
    super(props);

    if(this.props.values && this.props.values.length>1)
    {
        this.interval = Math.floor(90/(this.props.values.length-1));
        for(let i=0; i<this.props.values.length; i++){
            let inner = i*this.interval + 5;
            this.innerToValue[inner] = i+1;
            this.valueToInner[i+1] = inner;
            this.values.push(inner);
            this.marks[inner]={label:this.props.values[i], style:{color:"black", whiteSpace:"nowrap"}};
        }
    }
    else if(this.props.valueMap && this.props.valueMap.length>1)
    {
        this.interval = Math.floor(90/(this.props.valueMap.length-1));
        for(let i=0; i<this.props.valueMap.length; i++){
            let inner = i*this.interval + 5;
            this.innerToValue[inner] = this.props.valueMap[i].value;
            this.valueToInner[this.props.valueMap[i].value] = inner;
            this.values.push(inner);
            this.marks[inner]={label:this.props.valueMap[i].name, style:{color:"black", whiteSpace:"nowrap"}};
        }
    }
  }

  onValueChanged = (value) => {
    log(LogLevel.UI_ACTION, "Slider:onValueChanged: 1", value);
    for(let i=0; i<this.values.length; i++){
        if(value < this.values[i] + (this.interval/2)){
            value = this.values[i];
            break;
        }
    }
    log(LogLevel.UI_ACTION, "Slider:onValueChanged: 2 ", value);
    if(value != this.props.value){
        this.props.onSelect(this.innerToValue[value]);
    }
  }

  onClick = (e) => {

    if(this.slidesElement){
      this.onValueChanged((e.clientX - this.slidesElement.offsetLeft) * 100 / this.slidesElement.offsetWidth);
    }

    e.stopPropagation();

  }


  render() {
    log(LogLevel.UI_LIFECYCLE, "Slider: render ", this.props, this.marks, this.innerToValue, this.valueToInner);
    return (
      <div ref={ref => this.slidesElement= ref} className="slides-container">
          <div className="common-container" style={{height:"10px"}}  onClick={this.onClick}/>
          <Sliders 
              className="slides-slider" 
              included={true} 
              min={0} 
              max={100} 
              marks={this.marks} 
              value={this.valueToInner[this.props.value]} 
              trackStyle={{ backgroundColor: (this.props.isRange?'#2667EB':'black'), height: 2 }}
              handleStyle={{
                  borderColor: '#2667EB',
                  height: 15,
                  width: 15,
                  backgroundColor: '#2667EB',
              }}
              dotStyle={{ borderColor: 'black', height: 10, width: 10}}
              activeDotStyle={{ borderColor: (this.props.isRange?'#2667EB':'black'), height: 10, width: 10}}
              railStyle={{ backgroundColor: 'black', height: 2 }}
              onChange={this.onValueChanged}

          />
          <div className="common-container" style={{height:"25px"}}  onClick={this.onClick}/>

      </div>
    );
  } 
}

export default Slider;