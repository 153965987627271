
import React, { Component, SyntheticEvent } from 'react';
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonModal, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './VideoPlayer.css';
import {log, LogLevel} from '../utils/LogUtil'
import {setGlobal, GlobalKey, getGlobal} from './../utils/GlobalUtil'
import { UIPopupType } from '../store/ui/types';
import { UserWorkTypeName } from '../models/Model.User';

type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
    playing:boolean,
    resolution: number,
    speed: number,
    playingTime: number,
}

const windowAny:any = window;

class VideoPlayer extends Component<Props, State> {
    // lectureUrl = '###/lectures/01_VDX_0430.mp4';
    // lectureUrl = 'https://www.youtube.com/watch?v=oUFJJNQGwhk';
    // lectureUrl = 'blob:https://www.youtube.com/3c6ba173-a5d8-4d78-bfc5-1cec8a110b66';
    // lectureUrl = 'https://youtu.be/i0USKXvG0lA';
    // lectureUrl = 'https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lectures/01_VDX_0430.mp4';

    // lectureUrl = 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4';
    player = null;
    lecturePlayedTimeRatio: number = 0;
    playingTimer;
    duration: number = 0.0;

    constructor(props: Props) {
        super(props);
 

        this.state = {
            playing: true,
            resolution: 0,
            speed: 1,
            playingTime: 0
        };

        this.loadScript();
    }

    componentDidMount() {
        if(getGlobal(GlobalKey.OS) == "android")
            document.getElementById("video-player").addEventListener('webkitfullscreenchange', this.onFullScreen)
        this.lecturePlayedTimeRatio = getGlobal("lecturePlayedTimeRatio", true);
        if(!this.lecturePlayedTimeRatio || this.lecturePlayedTimeRatio > 0.99)
            this.lecturePlayedTimeRatio = 0;

        this.player.playbackRate = 1.0;

        this.boot({
            "pluginKey": "6fb9029c-e5d0-4aa8-8bed-6dd8a9db70fb", //please fill with your plugin key
            "memberId": this.props.user.me.id, //fill with user name
            "profile": {
                "name": this.props.user.me.name, //fill with user name
                "mobileNumber": this.props.user.me.phone, //fill with user phone number
                "email": this.props.user.me.email,
                "avatarUrl": this.props.user.me.profileUrl,
                "referrer":"강의페이지",
                "type": UserWorkTypeName[this.props.user.me.workType],
                "pharmacy": this.props.user.me.workPlaceName,
                "address": this.props.user.me.workPlaceAddress,
            }   
        });
          
          
    }

    componentWillUnmount () {
        if(getGlobal(GlobalKey.OS) == "android")
            document.getElementById("video-player").removeEventListener('webkitfullscreenchange', this.onFullScreen)
        this.shutdown();
    }

    onFullScreen = (e) => {
        let documentAny:any = document;
        let globalAnt:any = global;
        var isFullscreenNow = (documentAny.webkitFullscreenElement !== null)
        if(getGlobal(GlobalKey.OS) == "android" && screen.orientation && screen.orientation.unlock && screen.orientation.lock){
            if(isFullscreenNow){
                screen.orientation.unlock();
            }else{
                screen.orientation.lock("portrait");
            }
        }
        log(LogLevel.UI_EVENT, "VideoPlayer:onFullScreen", documentAny.webkitFullscreenElement, isFullscreenNow);
    }

    onDurationReady = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerReady");
        this.player.currentTime = this.lecturePlayedTimeRatio * this.player.duration;
        this.duration = this.player.duration;
    }

    onPlayerReady = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerReady");
    }

    onPlayerPlay = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerStart");
        this.setState({playing:true});
        if(!this.playingTimer) {
            this.playingTimer = setInterval(() => {
                log(LogLevel.UI_EVENT, "VideoPlayer:intervalTimeout" );
                this.setState({playingTime: (this.state.playingTime + this.state.speed)});
            }, 1000);
        }
    }

    onPlayerPause = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerPause");
        this.setState({playing:false});
        if(this.playingTimer) {
            clearInterval(this.playingTimer);
            this.playingTimer = 0;
        }

        setGlobal("lecturePlayedTimeRatio", this.lecturePlayedTimeRatio, true);
    }

    onPlayTimeUpdate = () => {
        this.lecturePlayedTimeRatio = this.player.currentTime / this.player.duration;
    }

    
    onPlayerEnd = () => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerEnd");
    }

    onPlayerError = (e) => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onPlayerError", e.type);
    }

    doPlay = () => {
        if(this.state.playing){
            this.player.pause();
        }
        else {
            this.player.play();
        }
        this.setState({playing:!this.state.playing});
        this.lecturePlayedTimeRatio = getGlobal("lecturePlayedTime", true);
    }

    onEvent=(name, e:SyntheticEvent = null) => {
        log(LogLevel.UI_EVENT, "VideoPlayer:onEvent",name, e.type, this.player.readyState, this.player.networkState, this.player.duration, this.player.ended, this.player.currentTime , this.player.buffered, this.player.src);
    }

    changeResolution = (id) => {
        if(this.state.resolution == id)
            return;

        let currentTime = this.player.currentTime;
        switch(id){
            case 0:
                this.player.src = "https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lectures/01_VDX_0430_360.mp4";
                break;
            case 1:
                this.player.src = "https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lectures/01_VDX_0430_720.mp4";
                break;
        }
        this.player.currentTime = currentTime;
        if(this.state.playing)
            this.player.play();

        this.setState({resolution : id});
    }

    changeSpeed = (speed) => {
        if(this.state.speed == speed)
            return;
        this.player.playbackRate = speed;
        this.setState({speed});
    }

    temp = 1;
    onDownload = () => {
        // let windowAny:any = window;
        // windowAny.open("https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/documents/animal_medicine/A00200045.pdf", "_system");
        this.props.toast.show(this.temp.toString());
        this.temp++;
    }

    onBack = () => {
        this.props.history.goBack();
    }

    loadScript() {
        if (windowAny.ChannelIO) {
          return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
        }
        var d = window.document;
        var ch:any = function() {
          ch.c(arguments);
        };
        ch.q = [];
        ch.c = function(args) {
          ch.q.push(args);
        };
        windowAny.ChannelIO = ch;
        function l() {
          if (windowAny.ChannelIOInitialized) {
            return;
          }
          windowAny.ChannelIOInitialized = true;
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
          s.charset = 'UTF-8';
          var x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        }
        if (document.readyState === 'complete') {
          l();
        } else if (windowAny.attachEvent) {
          windowAny.attachEvent('onload', l);
        } else {
          window.addEventListener('DOMContentLoaded', l, false);
          window.addEventListener('load', l, false);
        }
      }
    
      boot(settings) {
        windowAny.ChannelIO('boot', settings);
      }
    
      shutdown() {
        windowAny.ChannelIO('shutdown');
      }

    render() {
        log(LogLevel.UI_LIFECYCLE, "VideoPlayer.render", this.state.playingTime, this.duration, this.lecturePlayedTimeRatio );

        return (
        <>
            <IonHeader class="common-header" no-border>
                <IonToolbar color="common-header-toolbar">
                    <IonButtons slot="start">
                    <IonButton class="common-header-toolbar-button" onClick={this.onBack}>
                        <IonIcon name="arrow-back" mode="ios"/>
                    </IonButton>
                    <IonLabel class="common-header-title" onClick={this.onBack}>Video Player</IonLabel>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <div className="common-container common-scroll">
                <video className="video-play-player" 
                    ref={(ref) => this.player=ref} 
                    id="video-player"
                    playsInline
                    controlsList="nodownload"
                    preload="auto"
                    webkit-playsinline={1}
                    controls={true}
                    loop={false}
                    onCanPlay={this.onPlayerReady}
                    // onCanPlayThrough={(e) => this.onEvent("onCanPlayThrough", e)}
                    onPlay={this.onPlayerPlay}
                    onPause={this.onPlayerPause}
                    onEnded={this.onPlayerEnd}
                    onProgress={(e) => this.onEvent("onProgress", e)}
                    onDurationChange={this.onDurationReady}
                    onSeeking={(e) => this.onEvent("onSeeking", e)}
                    onSeeked={(e) => this.onEvent("onSeeked", e)}
                    onWaiting={(e) => this.onEvent("onWaiting", e)}
                    onAbort={(e) => this.onPlayerError}
                    onError={(e) => this.onPlayerError}
                    onLoadStart={(e) => this.onEvent("onLoadStart", e)}
                    onTimeUpdate={this.onPlayTimeUpdate}
                    src = "https://s3.ap-northeast-2.amazonaws.com/storage.dev.ymyd.onjourney.co.kr/lectures/01_VDX_0430_360.mp4"
                />
                <div className="common-container-row common-flex-align-center">
                    <div>해상도</div>
                    <div className={"admin-toggle" + ((this.state.resolution==0)?" admin-toggle-selected":"")} onClick={() => this.changeResolution(0)}>360p</div>
                    <div className={"admin-toggle" + ((this.state.resolution==1)?" admin-toggle-selected":"")} onClick={() => this.changeResolution(1)}>720p</div>
                    <div className="common-flex-grow"/>
                    <div>속도</div>
                    <div className={"admin-toggle" + ((this.state.speed==1)?" admin-toggle-selected":"")} onClick={() => this.changeSpeed(1)}>X1</div>
                    <div className={"admin-toggle" + ((this.state.speed==1.5)?" admin-toggle-selected":"")} onClick={() => this.changeSpeed(1.5)}>X1.5</div>
                    <div className={"admin-toggle" + ((this.state.speed==2)?" admin-toggle-selected":"")} onClick={() => this.changeSpeed(2)}>X2</div>
                </div>
                PlayingTime : {this.state.playingTime} <br/>
                Done (10%) : {(this.state.playingTime > (this.duration*0.1))?"Done":"Not Yet"}
                <br/>
                <IonButton onClick={this.onDownload}>
                    교재다운로드
                </IonButton>
            </div>

        </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  filePath: state.board.filePath,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideoPlayer));