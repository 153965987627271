import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonSelect, IonText, IonSelectOption, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonCheckbox } from '@ionic/react';
import './Poc.css'
import {log, LogLevel} from '../utils/LogUtil';
import {getGlobal, GlobalKey} from './../utils/GlobalUtil';
import { BoardType, BoardActionTypes } from '../store/board/types';
import { BoardContent, Attachment, BoardAttribute, QNA_PRESET } from '../models/Model.Board';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';
import BoardUtil from '../utils/BoardUtil';
import HtmlComposer from '../components/HtmlComposer';
import ViewerBody from '../components/ViewerBody';
import { UIPopupType } from '../store/ui/types';
import BoardContentSelect from '../admin/component/BoardContentSelect';
import ViewerBodyPopup from '../components/ViewerBodyPopup';
import parse from 'html-react-parser';
import Truncate from 'react-truncate';

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  selected: BoardContent;
  edited: boolean, 
  selecting : boolean,
  recommends: BoardContent[];
  recommendExclude: boolean,
}

var empty: BoardContent = {
  usersId: -1,
  boardsCategoriesId: 1,
  boardsCategoriesAttribute:QNA_PRESET,
  subject: '',
  bodyHtml: '<div></div>',
  filesLocal: [],
  createdAt: "2019-09-25T09:00:00",
  skipNotification:true,
}

class Recommends extends Component<Props, State> {

  changedAfterSearch : boolean = false;
  showParentPopup=null;
  timer = null;

  initialState:State={
    edited: false,
    selected : {...empty},
    selecting: false,
    recommends: null,
    recommendExclude:false,
  }
  state:State = {...this.initialState}
  
  popupProgress=null;

  constructor(props: Props) {
    super(props);
    empty.usersId = this.props.me.id;
  }

  onSelect = (content:BoardContent) => {
    log(LogLevel.UI_ACTION, "Recommends.onSelect", content);

    this.setState({selected:{...content, bodyHtml:content.bodyText.replace(/\n/gi, "<br/>")}});
    this.changedAfterSearch = true;
  };

  componentDidMount() {
    this.timer = setInterval(this.searchRecommends, 2000);
  }

  componentWillUnmount() {
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  searchRecommends = async () => {
    log(LogLevel.UI_ACTION, "Recommends.searchRecommends", this.changedAfterSearch);

    if(this.changedAfterSearch){
      this.changedAfterSearch = false;
      let html = this.state.selected.bodyHtml;
      html = html.replace(/ style="user-select: auto;"/gi,'');
      html = html.replace(/ style="user-select:auto"/gi,'');
      html = html.replace(/<div><br>/gi,'');
      html = html.replace(/<div>/gi,'');
      html = html.replace(/<\/div>/gi,'<br/>');
      html = html.replace(/<br>/gi,'<br/>');
      html = "<div>" + html + "</div>";
  
      let text = '';
      let a:any = parse(html, {replace: (domNode) => {
        // console.log(domNode);
        if(domNode.type == "tag" && domNode.name=="br"){
          text += " ";
        }
        else if(domNode.type == "text") {
          text += domNode.data;
        }
        return domNode;
      }});

      let query : any = {
        search : this.state.selected.subject + " " + text
      }
      if(this.state.selected.id && this.state.recommendExclude)
        query.exclude = [this.state.selected.id];
      let result = await fetchAPI(API.SEARCH_RECOMMENDS, "", null, query, getGlobal(GlobalKey.TOKEN));
      log(LogLevel.UI_ACTION, "Recommends.searchRecommends", query, result);

      if(result && !result.error && result.data && result.data.contents && result.data.contents.length) {
        this.setState({recommends:result.data.contents});
      }
    }
  }

  onChange = (type:string, value:string) => {
    let selected = {...this.state.selected};
    selected[type] = value;
    this.changedAfterSearch = true;
    this.setState({selected, edited:true});
  }

  onView = (id: number) => {
    if(this.showParentPopup)
      this.showParentPopup.show(id);

  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "Recommends.render", this.props, this.state);

    let contentList;
    if(this.state.selecting)
      contentList = (
        <div id="admin-sub-conainer" className="admin-container">
          <BoardContentSelect onDone={this.onSelect}  aliveOnly={true} />
        </div>
      );

    let recommends;
    let showParentPopup;

    if(this.state.recommends && this.state.recommends.length){
      recommends = (
        <div className="test-floating-recommends-container">
          {this.state.recommends.map((item, index) => 
            <div key={index.toString()} className="test-floating-recommend-item-container" onClick={() => this.onView(item.id)}>
              <div className="admin-title">
                <Truncate lines={1} ellipsis="...">
                  {item.subject}
                </Truncate>
              </div>
              <div className="admin-normal-text">
                <Truncate lines={2} ellipsis="...">
                  {item.bodyText}
                </Truncate>
              </div>
            </div>
          )}
        </div>
      );

      
      showParentPopup = (
        <ViewerBodyPopup ref={(ref) => this.showParentPopup = ref} parentId={0} isDiscussion={false} full={true}/>
      );
    }

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                추천 POC
            </IonText>
            <IonButtons slot="end">
                <IonButton onClick={() => this.setState({selecting:!this.state.selecting})}>게시판선택</IonButton>
            </IonButtons>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
            <div className="common-flex-row common-flex-align-center">
              <IonButton class="board-new-button" onClick={() => {this.setState({edited:false, selected:{...empty}})}}>
                <IonLabel>초기화</IonLabel>
              </IonButton>

              <IonCheckbox checked={this.state.recommendExclude} onClick={(e) => {this.setState({recommendExclude:!this.state.recommendExclude}); this.changedAfterSearch = true}}/>
              <div className="admin-margin-right"  onClick={(e) => {this.setState({recommendExclude:!this.state.recommendExclude}); this.changedAfterSearch = true}}>해당 글 제외</div>
            </div>


              <HtmlComposer
                title={this.state.selected.subject}
                onTitleChanged = {(title:string) => this.onChange("subject", title)}
                body = {this.state.selected.bodyHtml}
                onBodyChanged = {(body:string) => this.onChange("bodyHtml", body)}
                filesLocal = {this.state.selected.filesLocal}
                isQnA = {true}
              />
              {recommends}
              {showParentPopup}
            </div>
            {contentList}
            
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Recommends));