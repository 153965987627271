import React, { Component } from "react";
import "./../../UserManage.css";
import "./../../../Common.css";
import "./../../Admin.scss";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonCheckbox,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout, async } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserInfo,
  UserWorkTypeName,
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  UserPermanentType,
  UserWorkType,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import { actions, RootState } from "../../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../../store/ui/types";
import { BoardAttribute } from "../../../models/Model.Board";
import {
  getDateStringFromToday,
  getDateTimeString,
} from "../../../utils/TimeUtil";
import StringUtil from "../../../utils/StringUtil";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import { JobOfferName } from "../../../models/Model.JobPost";
import UserUtil from "../../../utils/UserUtil";
import {
  BizUserInfo,
  CASH_COUPON,
  CONFERENCE_COUPON_3,
  CouponInfo,
  FREE_COUPON,
  MOU_COUPON_30,
  MOU_COUPON_60,
} from "../../../models/Mode.Biz";
import CheckBox from "../../../components/atoms/CheckBox/CheckBox";
import Input from "../../../components/atoms/Input";

const API = {
  COUPON_POST: {
    method: "POST",
    path: "/admin/biz/coupon",
    contentType: "application/json",
  },
  COUPON_PUT: {
    method: "PUT",
    path: "/admin/biz/coupon",
    contentType: "application/json",
  },
  COUPON_POST_BY_PHONE: {
    method: "POST",
    path: "/admin/biz/coupon/user/phone",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: () => void;
    onCancel: () => void;
    coupon: CouponInfo;
  };

type State = {
  coupon: CouponInfo;
  userPhones: string;
  useUserPhone: boolean;
};

class BizCouponEdit extends Component<Props, State> {
  state = {
    coupon: null,
    userPhones: "",
    useUserPhone: false,
  };

  constructor(props: Props) {
    super(props);
    if (props.coupon) {
      log(LogLevel.UI_DATA_LOAD, "BizCouponEdit", this.props.coupon);
      this.state = {
        coupon: { ...this.props.coupon },
        useUserPhone: false,
        userPhones: "",
      };
    }
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.props.coupon &&
      (!prevProps.coupon || this.props.coupon.id != prevProps.coupon.id)
    ) {
      this.setState({
        coupon: {
          ...this.props.coupon,
          usedAt: this.props.coupon.usedAt
            ? getDateStringFromToday({ date: this.props.coupon.usedAt })
            : "",
          expireAt: this.props.coupon.expireAt
            ? getDateStringFromToday({ date: this.props.coupon.expireAt })
            : "",
        },
      });
      log(
        LogLevel.UI_DATA_LOAD,
        "BizCouponEdit:componentDidUpdate",
        prevProps.user,
        this.state
      );
    }
  }

  onDone = async () => {
    let result;
    this.props.progressPopup.show();
    log(
      LogLevel.UI_ACTION,
      "BizCouponEdit onDone",
      this.state.coupon,
      this.state.userPhones
    );
    if (this.state.useUserPhone && this.state.userPhones) {
      let phones = this.state.userPhones
        .split(/[,\n]/)
        .filter((phone) => !!phone);
      log(LogLevel.UI_ACTION, "BizCouponEdit onDone phones", phones);
      let success: string[] = [];
      let failed: string[] = [];
      for (let i = 0; i < phones.length; i++) {
        this.props.progressPopup.setLabel(
          `${i + 1}/${phones.length}번째 전송(${phones[i]}`
        );
        result = await fetchAPI(
          API.COUPON_POST_BY_PHONE,
          "",
          null,
          { phone: phones[i], coupon: this.state.coupon },
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error) {
          success.push(phones[i]);
        } else {
          failed.push(phones[i]);
        }
      }
      log(LogLevel.UI_ACTION, "BizCouponEdit onDone success", success);
      log(LogLevel.UI_ACTION, "BizCouponEdit onDone failed", failed);

      if (success.length) this.props.onDone();
    } else if (this.state.coupon.id) {
      result = await fetchAPI(
        API.COUPON_PUT,
        "",
        null,
        this.state.coupon,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        this.props.onDone();
      } else {
        log(LogLevel.UI_EXCEPTION, "BizCouponEdit onDone failed", result);
      }
    } else {
      result = await fetchAPI(
        API.COUPON_POST,
        "",
        null,
        this.state.coupon,
        getGlobal(GlobalKey.TOKEN)
      );
      if (result && !result.error) {
        this.props.onDone();
      } else {
        log(LogLevel.UI_EXCEPTION, "BizCouponEdit onDone failed", result);
      }
    }
    this.props.progressPopup.hide();
  };

  onClickPreset = (selectedPreset: CouponInfo) => {
    this.setState({ coupon: { ...this.state.coupon, ...selectedPreset } });
  };

  onSelectBizUser = (selectedUser: BizUserInfo) => {
    if (
      selectedUser &&
      selectedUser.id &&
      this.state.coupon &&
      this.state.coupon.bizUserId != selectedUser.id
    ) {
      this.setState({
        coupon: { ...this.state.coupon, bizUserId: selectedUser.id },
      });
    }
  };

  onSelectUser = (selectedUser: UserInfo) => {
    if (
      selectedUser &&
      selectedUser.id &&
      this.state.coupon &&
      this.state.coupon.userId != selectedUser.id
    ) {
      this.setState({
        coupon: { ...this.state.coupon, userId: selectedUser.id },
      });
    }
  };

  render() {
    if (this.state.coupon) {
      return (
        <div className="user-manage-container">
          {this.props.onCancel && (
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start">
                  <IonButton
                    class="common-header-toolbar-button"
                    onClick={this.props.onCancel}
                  >
                    <IonIcon name="arrow-back" mode="ios" />
                  </IonButton>
                  <IonLabel
                    class="common-header-title"
                    onClick={this.props.onCancel}
                  >
                    구인구직 쿠폰 정보
                  </IonLabel>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          )}
          <div className="user-manage-button-container common-flex-align-center">
            <div
              className="common-color-highlight"
              style={{ marginRight: "20px" }}
              onClick={() => this.onClickPreset(CASH_COUPON)}
            >
              CASH_COUPON
            </div>
            <div
              className="common-color-highlight"
              style={{ marginRight: "20px" }}
              onClick={() => this.onClickPreset(FREE_COUPON)}
            >
              FREE_COUPON
            </div>
            <div
              className="common-color-highlight"
              style={{ marginRight: "20px" }}
              onClick={() => this.onClickPreset(MOU_COUPON_30)}
            >
              MOU_BIZ_COUPON_30
            </div>
            <div className="user-manage-button-container" />
            <IonButton
              color="user-manage-cancel-button"
              onClick={this.props.onCancel}
            >
              취소
            </IonButton>
            <IonButton color={"user-manage-button"} onClick={this.onDone}>
              저장
            </IonButton>
          </div>
          {!this.state.coupon.id && (
            <div className="common-container-row common-flex-align-center">
              <CheckBox
                checked={this.state.useUserPhone}
                size={"Large"}
                onClick={() => {
                  this.setState({ useUserPhone: !this.state.useUserPhone });
                }}
              >
                사용자 전화번호 발급
              </CheckBox>
            </div>
          )}
          {!this.state.useUserPhone && (
            <div className="common-container-row common-flex-align-center">
              <div className="user-manage-title">법인사용자 : </div>
              <IonInput
                class="user-search-input"
                placeholder="이름"
                value={this.state.coupon.bizUserId}
                onIonChange={(e) =>
                  this.setState({
                    coupon: {
                      ...this.state.coupon,
                      bizUserId: e.detail.value,
                    },
                  })
                }
              />
            </div>
          )}
          {!this.state.useUserPhone && (
            <div className="common-container-row common-flex-align-center">
              <div className="user-manage-title">사용자 : </div>
              <IonInput
                class="user-search-input"
                placeholder="이름"
                value={this.state.coupon.userId.toString()}
                onIonChange={(e) =>
                  this.setState({
                    coupon: {
                      ...this.state.coupon,
                      userId: Number(e.detail.value),
                    },
                  })
                }
              />
            </div>
          )}
          {this.state.useUserPhone && (
            <div className="common-container">
              <div className="user-manage-title">사용자 전화번호 : </div>
              <Input
                placeholder="사용자 전화번호"
                multiLine={true}
                value={this.state.userPhones}
                onChange={(e) => {
                  this.setState({ userPhones: e.target.value });
                }}
              />
            </div>
          )}
          <div className="user-manage-title">ID : {this.props.coupon.id}</div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">코드 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이름"
              value={this.state.coupon.name}
              onIonChange={(e) =>
                this.setState({
                  coupon: {
                    ...this.state.coupon,
                    name: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">설명 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이름"
              value={this.state.coupon.detail}
              onIonChange={(e) =>
                this.setState({
                  coupon: {
                    ...this.state.coupon,
                    detail: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">할인금액 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이름"
              type="number"
              value={this.state.coupon.priceDiscount.toString()}
              onIonChange={(e) =>
                this.setState({
                  coupon: {
                    ...this.state.coupon,
                    priceDiscount: parseInt(e.detail.value),
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">사용일 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이름"
              value={this.state.coupon.usedAt}
              onIonChange={(e) =>
                this.setState({
                  coupon: {
                    ...this.state.coupon,
                    usedAt: e.detail.value,
                  },
                })
              }
            />
          </div>
          <div className="common-container-row common-flex-align-center">
            <div className="user-manage-title">만료일 : </div>
            <IonInput
              class="user-search-input"
              placeholder="이름"
              value={this.state.coupon.expireAt}
              onIonChange={(e) =>
                this.setState({
                  coupon: {
                    ...this.state.coupon,
                    expireAt: e.detail.value,
                  },
                })
              }
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BizCouponEdit);
