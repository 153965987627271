import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './SeminarManage.css'
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'
import {setGlobal, GlobalKey, getGlobal} from './../utils/GlobalUtil'
import { fetchAPI } from '../utils/APIUtil';
import Truncate from 'react-truncate';
import { SeminarLecture, SeminarLectureAttribute } from '../models/Model.Seminar';
import SeminarSelect from './component/seminar/SeminarSelect';
import SeminarEdit from './component/seminar/SeminarEdit';
import IngredientSelect from './component/IngredientSelect';
import { Ingredient } from '../models/Model.Medicine';
import { UIPopupType } from '../store/ui/types';
import { STATUS_CODES } from 'http';
import SeminarStatistics from './component/seminar/SeminarStatisticsView';
import SeminarCategorySelect from './component/seminar/SeminarCategorySelect';
import SeminarSeriesManage from './component/seminar/SeminarSeriesManage';

const AdminAPI = {        
  "GET_LIST" : {"method":"POST", "path":"/admin/seminar/lectures", "contentType":"application/json"}
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  selected: SeminarLecture,
  preview: SeminarLecture,
  previewId: number,
  viewOption: SeminarManageSecondViewOption,
}

export enum SeminarManageSecondViewOption {
  NONE,
  LIST,
  PREVIEW,
  INGREDIENT,
  STATISTICS,
  CATEGORY,
  SERIES,
}
export const defaultNewLecture:SeminarLecture = {
  id: 0,
  title: '이성연 신상정보',
  subtitle: '누가봐도 완벽한 오스카. 그 완벽함을 살펴본다.',
  description: "<div>누가봐도 완벽한 오스카. 그 완벽함을 살펴본다.</div><div><br></div><div>나도 오스카 잘난줄은 알았는데 이정도 인줄은 몰랐다. </div><div>누구나 알고 있는 오스카의 멋짐을 파해친다. </div><div>1. 오스카의 외모</div><div>2. 오스카의 몸매</div><div>3. 오스카의 마음</div><div>4. 오스카의 재능</div><div>5. 오스카의 취미</div><div><br></div><div>외짱몸더짱마더더짱 오스카의 진실입니다.  </div>",
  effect: "<div>오스카에 대한 충성심이 아주 높아진다. </div>",
  playtime: 100,
  attribute: SeminarLectureAttribute.NONE,
  
  imageUrl: '',
  previewUrl: '',
  bannerUrl: '',
  videoUrl: '###/lectures/01_VDX_0430_360.mp4',
  videoUrlHighResolution: '###/lectures/01_VDX_0430_720.mp4',

  passingMark: 70,
  references:[],
  materials:[],
  ingredients:[],
}
// export const defaultNewLecture:SeminarLecture = {
//   id: 0,
//   title: '',
//   subtitle: '',
//   description: "",
//   effect: "",
//   playtime: 0,
//   attribute: SeminarLectureAttribute.NONE,
  
//   companyLogoUrl: '',
//   imageUrl: '',
//   previewUrl: '',
//   bannerUrl: '',
//   videoUrl: '',
//   videoUrlHighResolution: '',

//   references:[],
//   materials:[],
//   ingredients:[],
//   consultOptions:["U","P"],
//   consultGuide:"제품 도입에 관심 있으신가요?\n제품 샘플을 보내드리고,\n담당자가 연락드리겠습니다.",
// }
class SeminarManage extends Component<Props, State> {
  lecturesList = null;
  lectureEditor = null;
  popup = null;
  state = {
    selected: {...defaultNewLecture},
    preview: null,
    previewId: 0,
    viewOption: SeminarManageSecondViewOption.LIST,
  };

  constructor(props: Props) {
    super(props);

  }

  componentDidMount() {
  }
  
  onSelect = (lecture: SeminarLecture) => {
    log(LogLevel.UI_EVENT, "onSelect", lecture);
    this.setState({selected:lecture});
  }
  
  onChange = (lecture: SeminarLecture) => {
    log(LogLevel.UI_EVENT, "onChange", lecture);
    if(this.lecturesList)
      this.lecturesList.refresh();
  }
  
  onPreview = (lecture: SeminarLecture) => {
    log(LogLevel.UI_EVENT, "onPreview", lecture);
    this.setState({preview:lecture, previewId:lecture.id, viewOption:SeminarManageSecondViewOption.PREVIEW});
  }

  onPreviewById = (lectureId: number) => {
    log(LogLevel.UI_EVENT, "onPreviewId", lectureId);
    this.setState({preview:null, previewId:lectureId, viewOption:SeminarManageSecondViewOption.PREVIEW});
  }

  onSearchIngredient = () => {
    log(LogLevel.UI_EVENT, "onSearchIngredient");
    this.setState({viewOption:SeminarManageSecondViewOption.INGREDIENT});
  }
  
  onSelectIngredient = (ingredient:Ingredient) => {
    log(LogLevel.UI_EVENT, "onSelectIngredient", this.lectureEditor, ingredient);
    if(this.lectureEditor)
      this.lectureEditor.addIngredient(ingredient);
  }

  onSearchCategories = () => {
    log(LogLevel.UI_EVENT, "onSearchCategories");
    this.setState({viewOption:SeminarManageSecondViewOption.CATEGORY});
  }
  
  onSelectCategory = (category:any) => {
    log(LogLevel.UI_EVENT, "onSelectCategory", this.lectureEditor, category);
    if(this.lectureEditor)
      this.lectureEditor.addCategory(category);
  }

  onStatistics = () => {
    this.setState({viewOption:SeminarManageSecondViewOption.STATISTICS});
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "Dashboard.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                세미나 관리
            </IonText>
            <IonButtons slot="end">
                <IonButton onClick={() => {this.setState({viewOption:SeminarManageSecondViewOption.LIST})}}>목록보기</IonButton>
                <IonButton onClick={() => {this.setState({viewOption:SeminarManageSecondViewOption.CATEGORY})}}>카테고리보기</IonButton>
                <IonButton onClick={() => {this.setState({viewOption:SeminarManageSecondViewOption.SERIES})}}>시리즈보기</IonButton>
            </IonButtons>
        </IonHeader>

        <IonContent>

          <div className="admin-main-container">
            <div className="admin-container">
              <SeminarEdit 
                ref={(ref)=>{this.lectureEditor = ref}} 
                lecture={this.state.selected} 
                onDone={this.onChange} 
                onPreview={this.onPreview} 
                onSelectIngredient={this.onSearchIngredient} 
                onSelectCategory={this.onSearchCategories}
                onStatistics={this.onStatistics}
                progressPopup={this.props.progressPopup} 
                toastPopup={this.props.toastPopup} 
                filePath={this.props.filePath}
                confirmPopup={this.props.confirmPopup}
              />
            </div>
            <div id="admin-sub-conainer" className={"admin-container" + (this.state.viewOption==SeminarManageSecondViewOption.LIST?"":" admin-container-hidden")}>
              <SeminarSelect ref={(ref)=>{this.lecturesList = ref}} onDone={this.onSelect} selected={this.state.selected} onPreview={this.onPreviewById}/>
            </div>
            <div id="admin-sub-conainer" className={"admin-container" + (this.state.viewOption==SeminarManageSecondViewOption.INGREDIENT?"":" admin-container-hidden")}>
              <IngredientSelect onDone={this.onSelectIngredient} />
            </div>
            <div id="admin-sub-conainer" className={"admin-container" + (this.state.viewOption==SeminarManageSecondViewOption.STATISTICS?"":" admin-container-hidden")}>
              <SeminarStatistics selected={this.state.selected} waitingPopup={this.props.progressPopup}/>
            </div>
            <div id="admin-sub-conainer" className={"admin-container" + (this.state.viewOption==SeminarManageSecondViewOption.CATEGORY?"":" admin-container-hidden")}>
              <SeminarCategorySelect onSelect={this.onSelectCategory} selected={this.state.selected}/>
            </div>
            <div id="admin-sub-conainer" className={"admin-container" + (this.state.viewOption==SeminarManageSecondViewOption.SERIES?"":" admin-container-hidden")}>
              <SeminarSeriesManage/>
            </div>
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures : state.seminar.lectures,
  progressPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath : state.board.filePath,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId:number) => actions.seminar.getSeminarLecture(lectureId),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeminarManage));