import { GamificationPeriodicReport } from "../../models/Model.Gamification";

export interface GamificationState {
  report: GamificationPeriodicReport;
}

export enum GamificationActionTypes{
  GAMIFICATION_REPORT_FETCH = '@@event/GAMIFICATION_REPORT_FETCH',
  GAMIFICATION_REPORT_REQUEST = '@@event/GAMIFICATION_REPORT_REQUEST',
  GAMIFICATION_REPORT_SUCCESS = '@@event/GAMIFICATION_REPORT_SUCCESS',
  GAMIFICATION_REPORT_ERROR = '@@event/GAMIFICATION_REPORT_ERROR',
  GAMIFICATION_REPORT_CLEAR = '@@event/GAMIFICATION_REPORT_CLEAR',
}
