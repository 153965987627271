import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { LogLevel, log } from "../../../utils/LogUtil";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import { UserInfo } from "../../../models/Model.User";
import { Flex, Header, Static } from "../../atoms/Layout";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Button from "../../atoms/Button";
import Footer from "../../cell/Footer";
import Body from "../../cell/Body";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import CheckBox from "../../atoms/CheckBox/CheckBox";
import {
  PremiumPurchaedStatus,
  UserPremiumInfo,
} from "../../../models/Model.User.Premium";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import Separator from "../../atoms/Separator";
import { on } from "cluster";
import Popup from "../../molecules/Popup";
import useBottomSheet from "../../../hooks/useBottomSheet";
import { PremiumUserSubscribePopup } from "../PremiumUserSubscribe";

export interface PremiumUserDetailProps {
  premium: UserPremiumInfo;
  me: UserInfo;
  onGoBack: () => void;
  onChangeCard: () => void;
  onUnsubscribe: () => void;
  onResubscribe: (amount) => void;
  menu: number[];
}

const PremiumUserDetail: React.FC<PremiumUserDetailProps> = ({
  me,
  premium,
  onGoBack,
  onChangeCard,
  onUnsubscribe,
  onResubscribe,
  menu,
}) => {
  const [showUnsubscritePopup, setShowUnsubscritePopup] = useState(false);
  const bottomSheet = useBottomSheet();

  const status: PremiumPurchaedStatus = useMemo(() => {
    let rvalue = PremiumPurchaedStatus.SCHEDULED;
    if (premium && premium.status == PremiumPurchaedStatus.PROVIDED)
      rvalue = PremiumPurchaedStatus.PROVIDED;
    else if (premium && premium.status == PremiumPurchaedStatus.FAILED)
      rvalue = PremiumPurchaedStatus.FAILED;
    else if (premium && premium.status == PremiumPurchaedStatus.CANCELED)
      rvalue = PremiumPurchaedStatus.CANCELED;
    else if (
      premium.purchases &&
      premium.purchases.length > 0 &&
      premium.purchases[0].status == PremiumPurchaedStatus.FAILED
    ) {
      rvalue = PremiumPurchaedStatus.FAILED;
    } else if (
      premium.purchases &&
      premium.purchases.length > 0 &&
      premium.purchases[0].status == PremiumPurchaedStatus.CANCELED
    ) {
      rvalue = PremiumPurchaedStatus.CANCELED;
    }
    return rvalue;
  }, [premium]);

  useEffect(() => {
    log(LogLevel.UI_ACTION, "PremiumUserDetail:useEffect mount", status);
  }, []);

  return (
    <div className="common-content common-position-relative">
      <Header>
        <TopAppBar
          title="약문약답 정기 후원"
          titleType="H4"
          leftButtons={[
            <Button
              color="Tertiary"
              icon="CaretLeft"
              onClick={onGoBack}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          rightButtons={[null]}
        />
      </Header>
      <Body footerHeight={88}>
        <Flex direction="column" customStyle={{ padding: "20px" }}>
          <Flex
            direction="column"
            gap="4px"
            customStyle={{
              width: "100%",
              marginTop: "16px",
              marginBottom: "24px",
            }}
          >
            {status == PremiumPurchaedStatus.FAILED ? (
              <Text textType={"H3"} color={COLOR_SYSTEM.get("Red")[400]}>
                자동 결제에 실패했습니다.
              </Text>
            ) : status == PremiumPurchaedStatus.CANCELED ? (
              <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
                약문약답 정기 후원이 곧 종료됩니다.
              </Text>
            ) : status == PremiumPurchaedStatus.PROVIDED ? (
              <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
                아래 종료일까지 후원혜택이 유지됩니다.
              </Text>
            ) : (
              <Text textType={"H3"} color={COLOR_SYSTEM.get("Gray")[900]}>
                약문약답 정기 후원 중입니다.
              </Text>
            )}

            {status == PremiumPurchaedStatus.FAILED && (
              <Text
                textType={"Body2SemiBold"}
                color={COLOR_SYSTEM.get("Red")[400]}
              >
                결제 수단을 확인해주세요.
              </Text>
            )}

            {status != PremiumPurchaedStatus.PROVIDED && (
              <Text
                textType={"Body1SemiBold"}
                color={COLOR_SYSTEM.get("Gray")[900]}
                style={{
                  textDecoration:
                    status == PremiumPurchaedStatus.CANCELED
                      ? "line-through"
                      : "none",
                }}
              >
                {premium.amount.toLocaleString()}원/월
              </Text>
            )}
          </Flex>
          {status == PremiumPurchaedStatus.CANCELED ||
          status == PremiumPurchaedStatus.PROVIDED ? (
            <Flex
              gap="4px"
              justifyContent="space-between"
              customStyle={{
                width: "100%",
                marginTop: "20px",
                marginBottom: "6px",
              }}
            >
              <Text
                textType={"Body1Medium"}
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                종료일
              </Text>

              <Text
                textType={"Body1SemiBold"}
                color={COLOR_SYSTEM.get("Gray")[900]}
              >
                {getDateStringFromToday({ date: premium.endAt })}
              </Text>
            </Flex>
          ) : premium.nextPurchasePlanedAt ? (
            <Flex
              gap="4px"
              justifyContent="space-between"
              customStyle={{
                width: "100%",
                marginTop: "20px",
                marginBottom: "6px",
              }}
            >
              <Text
                textType={"Body1Medium"}
                color={COLOR_SYSTEM.get("Gray")[700]}
              >
                {status == PremiumPurchaedStatus.FAILED
                  ? "결제 예정일"
                  : "다음 결제 예정일"}
              </Text>

              <Text
                textType={"Body1SemiBold"}
                color={
                  status == PremiumPurchaedStatus.FAILED
                    ? COLOR_SYSTEM.get("Red")[400]
                    : COLOR_SYSTEM.get("Gray")[900]
                }
              >
                {getDateStringFromToday({ date: premium.nextPurchasePlanedAt })}
              </Text>
            </Flex>
          ) : null}

          {status != PremiumPurchaedStatus.CANCELED &&
            status != PremiumPurchaedStatus.PROVIDED && (
              <Flex
                gap="4px"
                justifyContent="space-between"
                customStyle={{
                  width: "100%",
                }}
              >
                <Text
                  textType={"Body1Medium"}
                  color={COLOR_SYSTEM.get("Gray")[700]}
                >
                  결제 수단
                </Text>

                <Text
                  textType={"Body1SemiBold"}
                  color={
                    status == PremiumPurchaedStatus.FAILED
                      ? COLOR_SYSTEM.get("Red")[400]
                      : COLOR_SYSTEM.get("Gray")[900]
                  }
                >
                  {premium.cardName} {premium.cardNumber}
                </Text>
              </Flex>
            )}
          {status == PremiumPurchaedStatus.CANCELED ||
          status == PremiumPurchaedStatus.PROVIDED ? (
            <Button
              style={{ flexGrow: 1, marginTop: "20px", marginBottom: "12px" }}
              size={"Medium"}
              type={"Text"}
              variant={"Contained"}
              color={"Primary"}
              onClick={() => {
                bottomSheet.show({
                  title: {
                    value: "매월 얼마씩 후원할까요?",
                    align: "left",
                  },
                  body: (
                    <PremiumUserSubscribePopup
                      amount={premium.amount}
                      onDone={(amount) => {
                        onResubscribe(amount);
                        bottomSheet.close();
                      }}
                      menu={menu}
                    />
                  ),
                });
              }}
            >
              후원 다시 시작하기
            </Button>
          ) : (
            <Button
              style={{ flexGrow: 1, marginTop: "20px", marginBottom: "12px" }}
              size={"Medium"}
              type={"Text"}
              variant={"Outlined"}
              color={"Secondary"}
              onClick={onChangeCard}
            >
              결제 수단 변경
            </Button>
          )}
          {status != PremiumPurchaedStatus.CANCELED &&
            status != PremiumPurchaedStatus.PROVIDED && (
              <Flex>
                <Button
                  style={{}}
                  size={"Small"}
                  type={"Text"}
                  variant={"Tinted"}
                  color={"Tertiary"}
                  onClick={() => setShowUnsubscritePopup(true)}
                >
                  후원 해지하기
                </Button>
              </Flex>
            )}
        </Flex>
      </Body>
      {showUnsubscritePopup && (
        <Popup onClose={() => setShowUnsubscritePopup(false)}>
          <Flex
            direction="column"
            gap="24px"
            customStyle={{ width: "100%", textAlign: "center" }}
          >
            <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
              후원을 해지할까요?
            </Text>
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
              지금 해지하면 <br />
              다음 달 결제일 (
              {getDateStringFromToday({
                date: premium.nextPurchasePlanedAt,
                separator: ".",
              })}
              )부터
              <br />
              자동결제가 중단됩니다.
            </Text>

            <Flex gap="8px" customStyle={{ width: "100%" }}>
              <Button
                style={{ flex: 1 }}
                color="Secondary"
                size="Large"
                type="Text"
                variant="Tinted"
                onClick={() => setShowUnsubscritePopup(false)}
              >
                취소
              </Button>
              <Button
                style={{ flex: 1 }}
                color="Primary"
                size="Large"
                type="Text"
                variant="Contained"
                onClick={() => {
                  setShowUnsubscritePopup(false);
                  onUnsubscribe();
                }}
              >
                해지하기
              </Button>
            </Flex>
          </Flex>
        </Popup>
      )}
    </div>
  );
};

export default PremiumUserDetail;
