import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setScrollPos } from "../../../store/search";
import { COLOR_SYSTEM } from "../../design/design-system";
import SearchedMedicineCard from "./SearchedMedicineCard";
import styled from "styled-components";

const SearchedMedicineListUl = styled.ul`
  height: calc(100% - 108px);
  padding: 12px 0 40px 0;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
`;

const SearchedMedicineList = () => {
  const dispatch = useDispatch();
  const searchedList = useSelector((state: RootState) => state.newSearch.medicine.searchedList);
  const scrollPosition = useSelector((state: RootState) => state.newSearch.medicine.scrollPosition);
  const listEl = useRef<HTMLDivElement>();

  useEffect(() => {
    if (listEl.current) {
      listEl.current.scrollTop = scrollPosition;
    }

    return () => {
      dispatch(setScrollPos("Medicine", listEl.current.scrollTop));
    };
  }, [listEl]);

  return (
    <SearchedMedicineListUl ref={listEl}>
      {searchedList.map((medicine) => (
        <SearchedMedicineCard key={medicine.id} medicine={medicine} />
      ))}
    </SearchedMedicineListUl>
  );
};

export default SearchedMedicineList;
