import { UserInfo } from "./Model.User";

export enum PremiumType {
  NONE = 0,
  DONOR = 1,
  PREMIUM = 2,
}

// Premium 회원 가입 정보
export interface UserPremiumInfo {
  id?: number;
  userId?: number;
  user?: UserInfo;
  type?: PremiumType;
  status?: PremiumPurchaedStatus;
  createdAt?: string;
  nextPurchasePlanedAt?: string;
  endAt?: string;
  amount?: number;
  cardName?: string;
  cardNumber?: string;
  purchases?: UserPremiumPurchasedInfo[];
}

export enum PremiumPurchaedStatus {
  SCHEDULED = 0,
  PAID = 1,
  PROVIDED = 2, // 운영진에 의해 프리미엄 권한 부여됨
  FAILED = -1,
  CANCELED = -2,
}

// Premium 회원 결제 정보
export interface UserPremiumPurchasedInfo {
  id?: number;
  status?: PremiumPurchaedStatus;
  userId?: number;
  userPremiumId?: number;

  payMerchantId?: string; // 아임포트 결제 id
  payMethod?: string; // 결제방법
  payCardName?: string;
  payAmount?: number; // 결제금액
  canceledAmount?: number; // 취소금액

  payStatus?: string; // 결제상태
  payApplyNumber?: string; // 카드사 승인번호
  payRecieptUrl?: string; // 거래매출전표 URL
  payUid?: string; // 아임포트 결제 고유번호

  createdAt?: string;
  reservedAt?: string;
  paidAt?: string;
  canceledAt?: string;

  startAt?: string;
  endAt?: string;
  detail?: string;
}
