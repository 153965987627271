import * as events from './actions';
import { BannerState, BannerActionTypes} from './types';
import { ActionType, getType } from 'typesafe-actions';
import { Middleware } from 'redux';
import { fetchAPI } from '../../utils/APIUtil';
import * as API from './../../API.json';
import {getGlobal, GlobalKey, setGlobal, clearGlobal} from './../../utils/GlobalUtil'
import { BannerInfo } from '../../models/Model.Banner';
import { ErrorCode } from '../../models/ErrorCode';
import {log, LogLevel} from '../../utils/LogUtil'
import { UserState } from '../user/types';
import { conditionCheck } from '../../utils/ConditionCheckerUtil';


export const fetchBannerMiddleware: Middleware<{}, BannerState> = (store) => next => async (action: ActionType<typeof events>) => {
  next(action);

  if(!action)
    return;
  let states = store.getState();
  let state:BannerState = states["event"];
  let today = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));
  if (action.type == getType(events.getBanners)) {
    log(LogLevel.REDUX_BOARD, "fetchBannerMiddleware : events.getBanners ", action, today)
    next(events.fetchBanners.request(null));
    try {
      fetchAPI(API.BANNER_GET, '', null, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          let props = {me:states["user"].me};
          let baners = shuffleBanner(result.data.filter(item => conditionCheck(item.condition, props)));
          let revents:BannerInfo[] = baners;
          let newBanners = {}
          log(LogLevel.REDUX_BOARD, "fetchBannerMiddleware getBanners success", result.data, newBanners)
          next(events.fetchBanners.success(revents));
        }
        else{
          log(LogLevel.REDUX_BOARD, "fetchBannerMiddleware getBanners failed", result);
          let error : Error = {
            name : "error code " + result.error,
            message: "error code " + result.error,
          };
          next(events.fetchBanners.failure(error));
        }
      }).catch((e) => {
        log(LogLevel.REDUX_BOARD, "fetchBannerMiddleware getBanners failed", e);
        next(events.fetchBanners.failure(e));
      })
    } catch (e) {
      log(LogLevel.REDUX_BOARD, "fetchBannerMiddleware getBanners failed", e);
      next(events.fetchBanners.failure(e));
    }
  }
};

function shuffleBanner(array:BannerInfo[]) {
  // log(LogLevel.REDUX_BOARD, "shuffleBanner ", array);
  if(!array || !array.length)
    return [];

  let rvalue:BannerInfo[] = [...array];
  for(let i=1; i<rvalue.length; i++){
    let suffle = Math.random() * 2;
    if(rvalue[i-1].priority == rvalue[i].priority && suffle >= 1){
      let temp = rvalue[i-1];
      rvalue[i-1] = rvalue[i];
      rvalue[i] = temp;
    }
    // log(LogLevel.REDUX_BOARD, "shuffleBanner suffle ", i, suffle);
  }
  log(LogLevel.REDUX_BOARD, "shuffleBanner result ", array, rvalue);

  return rvalue;
}
