import { FC, SVGProps, useEffect, useState } from 'react'
import { IconPack } from './enum'
import React from 'react';

export { IconPack }
export interface Props extends React.SVGProps<SVGSVGElement> {
  name: string
  className?:string
  size?: number
  color?: string
}

const Icon = ({ name, className, size, color, ...svgProps }: Props): JSX.Element => {
  const [SvgIcon, setSvgIcon] = useState<FC<SVGProps<SVGSVGElement>>>()

  useEffect(() => {
    let isComponentMounted = true

    const importSvgDynamically = async () => {
      try {
        const importedModule = await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!./svgs/${name}.svg`
        )
        if (isComponentMounted) setSvgIcon(importedModule.default)
      } catch (error) {
        console.error(`Icon name '${name}' is not found`)
      }
    }
    importSvgDynamically()

    return () => {
      isComponentMounted = false
    }
  }, [name])

  return SvgIcon ? <SvgIcon width={size} height={size} fill={color} {...svgProps} style={{verticalAlign:"top"}} className={className}/> : <></>
}

export default Icon
