import React from "react";
import useRipple from "../../../hooks/useRipple";
import { COLOR_SYSTEM } from "../../design/design-system";
import { ButtonType } from "../Button";
import Icon from "../Icon";
import {
  ToggleIconButtonWrapper,
  ToggleIconWithTextButtonWrapper,
  ToggleTextButtonWrapper,
} from "./style";

export type ToggleButtonColor =
  | "Red"
  | "Yellow"
  | "Gray"
  | "Skyblue"
  | "Black"
  | "Blue";
export type ToggleButtonSize = "Large" | "Medium" | "Small";
export type ToggleButtonVariant =
  | "Ghost"
  | "Outlined"
  | "Tinted"
  | "OutlinedTinted";

export interface ToggleButtonProps {
  /** 버튼 색상 */
  color: ToggleButtonColor;
  /** 버튼 크기 */
  size: ToggleButtonSize;
  /** 버튼 타입 */
  type?: ButtonType;
  /** 버튼 종류 */
  variant: ToggleButtonVariant;
  /** 버튼 안에 들어가는 content */
  children?: React.ReactNode | string;
  /** 버튼 Selected 여부 */
  active?: boolean;
  /** 아이콘 위치 여부(택스트 앞) */
  left?: boolean;
  /** 아이콘 위치 여부(택스트 뒤) */
  right?: boolean;
  /** 텍스트+아이콘 가운데 정렬 */
  justfyContents?: string;
  /** 아이콘 */
  icon?: string;
  /** 커스텀 class */
  className?: string;
  /** Inline CSS */
  style?: React.CSSProperties;
  /** 클릭 이벤트 핸들러 */
  onClick: (params?: any) => any;
  /** 버튼 활성 여부 */
  disabled?: boolean;
  /** 버튼 비활성일때 색상 변경 여부 */
  disabledColor?: boolean;
  borderRadius?: string;
}

const ToggleButton = ({
  color,
  size,
  type,
  variant,
  children,
  active = false,
  left,
  right,
  justfyContents,
  icon,
  className,
  style,
  disabled,
  disabledColor,
  borderRadius,
  onClick,
}: ToggleButtonProps) => {
  const [coords, isRippling, diameter, onRippleEffect] = useRipple({
    x: -1,
    y: -1,
  });

  const setIconSize = () => {
    switch (size) {
      case "Large":
        return 24;
      case "Medium":
        return 20;
      case "Small":
      default:
        return 16;
    }
  };

  const setIconColor = () => {
    if (active) {
      switch (color) {
        case "Red":
          return COLOR_SYSTEM.get(color)[300];
        case "Yellow":
        case "Skyblue":
        case "Blue":
          return COLOR_SYSTEM.get(color)[400];
        case "Black":
          return COLOR_SYSTEM.get("Gray")[900];
        case "Gray":
        default:
          return COLOR_SYSTEM.get(color)[700];
      }
    } else {
      return COLOR_SYSTEM.get("Gray")[100];
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onRippleEffect(e);
    onClick(e);
  };

  const renderByType = () => {
    switch (type) {
      case "Icon":
        return (
          <ToggleIconButtonWrapper
            color={color}
            size={size}
            variant={variant}
            active={active}
            className={className}
            style={style}
            onClick={handleClick}
            disabled={disabled}
            disabledColor={disabledColor}
            borderRadius={borderRadius}
          >
            {icon && (
              <Icon
                width={setIconSize()}
                height={setIconSize()}
                name={icon}
                fill={setIconColor()}
              />
            )}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </ToggleIconButtonWrapper>
        );
      case "Text":
      default:
        return (
          <ToggleTextButtonWrapper
            color={color}
            size={size}
            variant={variant}
            active={active}
            className={className}
            style={style}
            onClick={handleClick}
            disabled={disabled}
            disabledColor={disabledColor}
            borderRadius={borderRadius}
          >
            {children}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </ToggleTextButtonWrapper>
        );
      case "IconWithText":
        return (
          <ToggleIconWithTextButtonWrapper
            color={color}
            size={size}
            variant={variant}
            left={left}
            right={right}
            justfyContents={justfyContents}
            active={active}
            className={className}
            style={style}
            onClick={handleClick}
            disabled={disabled}
            disabledColor={disabledColor}
            borderRadius={borderRadius}
          >
            {left && icon && (
              <Icon
                width={setIconSize()}
                height={setIconSize()}
                name={icon}
                fill={setIconColor()}
                className="left-button__icon"
              />
            )}
            {children}
            {right && icon && (
              <Icon
                width={setIconSize()}
                height={setIconSize()}
                name={icon}
                fill={setIconColor()}
                className="right-button__icon"
              />
            )}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </ToggleIconWithTextButtonWrapper>
        );
    }
  };

  return <>{renderByType()}</>;
};

export default ToggleButton;
