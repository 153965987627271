import { UserLevel } from "./Model.User"
import { log, LogLevel } from "../utils/LogUtil"

export interface AdminCustomBody {
  title?: string
  body?: string
  url?: string
  data?: CustomBody
  type?: NotificationType
  scheduledAt?: string
  createdAt?: string
  startedAt?: string
  endedAt?: string
  deletedAt?: string
  sentUserCount?: number
  sentDeviceCount?: number
}

export enum Conditions {
  EQ = "=",
  NEQ = "<>",
  IN = "in",
  NIN = "not in",
  GT = ">",
  GTE = ">=",
  LT = "<",
  LTE = "<=",
  LIKE = "like",
}

export type TypeConditions =
  | "EQ"
  | "NEQ"
  | "IN"
  | "NIN"
  | "GT"
  | "GTE"
  | "LT"
  | "LTE"
  | "LIKE"

export interface CustomBody {
  where: {
    field?: string
    condition?: Conditions
    value?: any
  }[]
  groupBy?: string[]
  count?: number
  start?: number
}

export enum NotificationType {
  REPLY_ON_MY_POST = 1,
  COMMENT_ON_MY_POST = 2,

  LIKE_ON_MY_POST = 103,
  LIKE_ON_MY_COMMENT = 104,
  SHARE_MY_POST = 105,
  SCRAP_MY_POST = 106,

  MY_INTEREST_POST = 201,
  MY_FOLLOW_POST = 202,
  NEW_QUESTION = 203,
  NEW_REPLY = 204,
  NEW_POST = 205,
  NEW_CONSULT_POST = 206,
  NEW_CONSULT_REPLY_POST = 207,

  REPLY_ON_MY_LIKE = 301,
  COMMENT_ON_MY_LIKE = 302,
  REPLY_ON_MY_SCRAP = 303,
  COMMENT_ON_MY_SCRAP = 304,
  REPLY_ON_ME_TOO = 305,
  COMMENT_ON_MY_COMMENT = 306,

  USER_LEVEL_CHANGE = 401,

  ORDER_CASH_IN = 501,

  JOBPOST_NEW_RECOMMENDED_OFFER = 601,

  CHAT_MESSAGE_RECIEVED = 701,

  ADMIN_NEW_USER = 901,
  ADMIN_NEW_POST = 902,
  ADMIN_JOBPOST_NEW_OFFER = 903,
  ADMIN_JOBPOST_NEW_APPLY = 904,
  ADMIN_JOBPOST_INTERVIEW = 905,
  ADMIN_JOBPOST_FINAL_OFFER = 906,
  ADMIN_NEW_SOCIAL_AUTH = 907,
  CUSTOM = 999,
}

export interface Notification {
  id?: number // id of notification
  toUsersId?: number // target of notification
  fromUsersId?: number
  fromUsers?: string
  fromUsersProfile?: string
  fromUsersCount?: number
  type?: NotificationType
  boardsContentsId?: number
  boardsCommentsId?: number
  rootBoardContentId?: number
  rootBoardContentUserId?: number // root author
  text?: string // body of the content or the comment
  content?: string
  comment?: string
  count?: number
  checked?: boolean
  interest?: string
  level?: number
  modifiedAt?: any
}

export function getNotificationCount(
  notiList: Notification[],
  lastCheckedTime: string
) {
  let rvalue = 0
  if (!lastCheckedTime && notiList && notiList.length > 0)
    rvalue = notiList.length
  else if (lastCheckedTime && notiList && notiList.length > 0) {
    for (let i = 0; i < notiList.length; i++) {
      if (notiList[i].modifiedAt < lastCheckedTime) break
      rvalue++
    }
  }
  return rvalue
}
