import { action, createAction, createAsyncAction } from 'typesafe-actions';
import {SeminarActionTypes, SeminarListState} from './types'
import { SeminarLecture, SeminarLecturePartialLoadOption } from '../../models/Model.Seminar';
import { BannerInfo } from '../../models/Model.Banner';

export const init = createAction(SeminarActionTypes.SEMINAR_INIT, resolve =>
  () => resolve()
);
export const initialized = createAction(SeminarActionTypes.SEMINAR_INITIALIZED, resolve =>
  () => resolve()
);

export const reloadList = createAction(SeminarActionTypes.SEMINAR_LECTURE_RELOAD_LIST, resolve =>
  (options:object, id: number) => resolve(options, id)
);

export const getSeminarLecture = createAction(SeminarActionTypes.SEMINAR_LECTURE_FETCH, resolve =>
  (lectureId:number, loadOption:number = SeminarLecturePartialLoadOption.ALL) => resolve(lectureId, loadOption)
);

export const updateSeminarLectureView = createAction(SeminarActionTypes.SEMINAR_LECTURE_VIEW_UPDATE, resolve =>
  (lectureId:number, progress:number) => resolve(lectureId, progress)
);

export const updateSeminarLectureViewLastPlayTime = createAction(SeminarActionTypes.SEMINAR_LECTURE_VIEW_TIME_UPDATE, resolve =>
  (lectureId:number, time:number) => resolve(lectureId, time)
);

export const getSeminarLectures = createAction(SeminarActionTypes.SEMINAR_LECTURE_LIST_FETCH, resolve =>
  (categoryId:number) => resolve(categoryId)
);

export const getSeminarFeatured = createAction(SeminarActionTypes.SEMINAR_FEATURED_FETCH, resolve =>
  () => resolve()
);

export const fetchSeminarLecture = createAsyncAction(
  SeminarActionTypes.SEMINAR_LECTURE_REQUEST,
  SeminarActionTypes.SEMINAR_LECTURE_SUCCESS,
  SeminarActionTypes.SEMINAR_LECTURE_ERROR,
)<number, SeminarLecture, any>(); // <string, SeminarList, Error>();

export const fetchSeminarLectures = createAsyncAction(
  SeminarActionTypes.SEMINAR_LECTURE_LIST_REQUEST,
  SeminarActionTypes.SEMINAR_LECTURE_LIST_SUCCESS,
  SeminarActionTypes.SEMINAR_LECTURE_LIST_ERROR,
)<number, SeminarListState, any>(); // <string, SeminarList, Error>();

export const fetchSeminarFeatured = createAsyncAction(
  SeminarActionTypes.SEMINAR_FEATURED_REQUEST,
  SeminarActionTypes.SEMINAR_FEATURED_SUCCESS,
  SeminarActionTypes.SEMINAR_FEATURED_ERROR,
)<void, BannerInfo[], any>(); // <string, SeminarList, Error>();
