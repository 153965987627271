import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonToolbar } from '@ionic/react';
import './../../Admin.scss'
import {log, LogLevel} from '../../../utils/LogUtil'
import {setGlobal, GlobalKey, getGlobal} from './../../../utils/GlobalUtil'
import { fetchAPI } from '../../../utils/APIUtil';
import Truncate from 'react-truncate';
import { SeminarLecture, SeminarLectureAttribute } from '../../../models/Model.Seminar';
import { UIPopupType } from '../../../store/ui/types';
import { STATUS_CODES } from 'http';
import { idText } from 'typescript';
import ReactHtmlParser from 'react-html-parser'
import Button from '../../../components/atoms/Button';

const AdminAPI = {        
  "DAILY_PHARM_COMPANY_LIST" : {"method":"GET", "path":"/admin/event", "contentType":"application/json"}
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  recruit : any,
  onClose : () => void
}

type State = {
}

class DailyPharmRecruitDetail extends Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      companyList:[],
      category:"",
      order:"",
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
      
  }

  onRegisterStibee(){

  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "PollManage.render", this.props, this.state);

    if(!this.props.recruit)
      return <div/>;
    
    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar" onClick={this.props.onClose}>
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button">
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="viewer-title" >DFR 상세보기</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Button size={'Large'} type={'IconWithText'} variant={'Contained'} color={'Primary'} icon="CaretRight" right onClick={()=>{
            window.open("https://recruit.dailypharm.com/Offer/OfferView.php?ID="+this.props.recruit.code, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
          }}>이동하기</Button>
          {this.props.recruit && this.props.recruit.type && this.props.recruit.type != "약국" &&
            <div className="common-container-row">
                <div className="common-flex-column common-flex-grow">

                </div>
                <Button size={'Large'} type={'IconWithText'} variant={'Contained'} color={'Primary'} icon="Plus" right onClick={()=>{
                  window.open("https://recruit.dailypharm.com/Offer/OfferView.php?ID="+this.props.recruit.code, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
                }}>Stibee 등록</Button>
            </div>
          }
          
          <div className="common-container">
            {ReactHtmlParser(JSON.stringify(this.props.recruit, null, 4).replace(/\n/gi, "<br/>").replace(/\\n/gi, "<br/>").replace(/ /gi, "&nbsp;"))}
          </div>
        </IonContent>
        
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures : state.seminar.lectures,
  progressPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath : state.board.filePath,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId:number) => actions.seminar.getSeminarLecture(lectureId),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DailyPharmRecruitDetail));