import * as users from "./actions";
import { UserState } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import {
  getGlobal,
  GlobalKey,
  setGlobal,
  clearGlobal,
} from "./../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";
import { TARGET } from "./../../config.json";
import { VERSION, BUILD_DATE } from "./../../version.json";
import AnalyticsUtil from "./../../utils/AnalyticsUtil";
import { UserWorkTypeName } from "../../models/Model.User";
import { FREE_SUMMARY_COUNT } from "./reducer";

const windowAny: any = window;

export const fetchUserMiddleware: Middleware<{}, UserState> = ({
  getState,
}) => (next) => async (action: ActionType<typeof users>) => {
  let states = getState();
  let state: UserState = states["user"];

  next(action);

  if (action.type == getType(users.socialLogin)) {
  } else if (action.type == getType(users.login)) {
    log(
      LogLevel.REDUX_USER,
      "users.login fetch me",
      getState().token,
      getGlobal(GlobalKey.TOKEN)
    );
    next(users.fetchUserME.request());
    try {
      let result = await fetchAPI(
        API.USER_ME_REQUEST,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_USER, result);
      if (result && !result.error) {
        next(users.fetchUserME.success(result.data));
        windowAny.amplitude.getInstance().setUserId(result.data.id.toString());
        windowAny.amplitude.getInstance().setUserProperties({
          nickname: result.data.nickname,
          notiOption: getGlobal(GlobalKey.NOTIFICATION_OPTION, true),
          level: result.data.level,
          version: VERSION + "." + BUILD_DATE,
          workType: UserWorkTypeName[result.data.workType],
          jobpostStatus: result.data.jobpostStatus,
          workRegion: result.data.workPlaceRegion,
          homeRegion: result.data.homeRegion,
          lastJobpostAccess: result.data.lastJobPostAt,
          premiumType: result.data.premiumType,
          licensedAt: result.data.licensedAt,
        });
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "LOGIN", "로그인");
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        clearGlobal(GlobalKey.TOKEN);
        next(users.fetchUserME.failure(error));
        // log(LogLevel.REDUX_USER, "invalid token");
      }
    } catch (e) {
      next(users.fetchUserME.failure(e));
    }
  } else if (
    action.type == getType(users.checkUserAccomplished) &&
    !state.isAccomplished
  ) {
    log(
      LogLevel.REDUX_USER,
      "users.login fetch me",
      getState().token,
      getGlobal(GlobalKey.TOKEN)
    );
    next(users.fetchUserAccomplished.request());
    try {
      let result = await fetchAPI(
        API.USER_ACCOMPLISHED,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_USER, result);
      if (result && !result.error) {
        next(users.fetchUserAccomplished.success(result.data));
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        clearGlobal(GlobalKey.TOKEN);
        next(users.fetchUserAccomplished.failure(error));
        // log(LogLevel.REDUX_USER, "invalid token");
      }
    } catch (e) {
      next(users.fetchUserAccomplished.failure(e));
    }
  } else if (action.type == getType(users.getUser)) {
    log(
      LogLevel.REDUX_USER,
      "users.getUser",
      getState().token,
      getGlobal(GlobalKey.TOKEN)
    );
    next(users.fetchUserInfo.request());
    try {
      let result = await fetchAPI(
        API.USER_INFO_REQUEST,
        action.payload.toString(),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_USER, result);
      if (result && !result.error) {
        next(users.fetchUserInfo.success(result.data));
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        clearGlobal(GlobalKey.TOKEN);
        next(users.fetchUserInfo.failure(error));
        // log(LogLevel.REDUX_USER, "invalid token");
      }
    } catch (e) {
      next(users.fetchUserInfo.failure(e));
    }
  } else if (action.type == getType(users.updateBoardStatistic)) {
    log(
      LogLevel.REDUX_USER,
      "users.updateBoardStatistic",
      getState().token,
      getGlobal(GlobalKey.TOKEN)
    );
    next(users.fetchUserBoardStatistics.request());
    try {
      let result = await fetchAPI(
        API.USER_BOARD_STATISTICS,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_USER, result);
      if (result && !result.error) {
        next(users.fetchUserBoardStatistics.success(result.data));
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        clearGlobal(GlobalKey.TOKEN);
        next(users.fetchUserBoardStatistics.failure(error));
        // log(LogLevel.REDUX_USER, "invalid token");
      }
    } catch (e) {
      next(users.fetchUserBoardStatistics.failure(e));
    }
  } else if (action.type == getType(users.getLastRecievedComment)) {
    log(
      LogLevel.REDUX_USER,
      "users.getLastRecievedComment",
      getState().token,
      getGlobal(GlobalKey.TOKEN)
    );
    next(users.fetchLastRecievedComment.request());
    try {
      let result = await fetchAPI(
        API.BOARD_RECIEVED_COMMENT,
        "",
        { start: 0, count: 3 },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_USER, result);
      if (result && !result.error) {
        next(users.fetchLastRecievedComment.success(result.data));
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        clearGlobal(GlobalKey.TOKEN);
        next(users.fetchLastRecievedComment.failure(error));
        // log(LogLevel.REDUX_USER, "invalid token");
      }
    } catch (e) {
      next(users.fetchLastRecievedComment.failure(e));
    }
  } else if (action.type == getType(users.logout)) {
    log(
      LogLevel.REDUX_USER,
      "users.logout",
      getState().token,
      getGlobal(GlobalKey.TOKEN)
    );
    next(users.fetchUserKakaoLogout.request());
    try {
      clearGlobal(GlobalKey.TOKEN, true);
      let windowAny: any = window;
      let os = getGlobal(GlobalKey.OS);
      log(LogLevel.UI_ACTION, "Login:logout", os);
      if (!os || os == "browser") {
        if (windowAny.Kakao) {
          try {
            windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
          } catch (e) {
            log(LogLevel.NONE, e);
          }
        }
        windowAny.Kakao.Auth.logout((result) => {
          log(
            LogLevel.REDUX_USER,
            "users windowAny.Kakao.Auth.logout: DONE",
            result
          );
        });
      } else {
        if (windowAny.KakaoCordovaSDK) await windowAny.KakaoCordovaSDK.logout();
      }
      setGlobal(GlobalKey.NOTIFICATION_INITIALIZED, false);
      next(users.fetchUserKakaoLogout.success());
    } catch (e) {
      log(LogLevel.REDUX_USER, "users.logout: error: ", e);
      next(users.fetchUserKakaoLogout.failure(e));
    }
  } else if (action.type == getType(users.getFreeSummaryCount)) {
    log(
      LogLevel.REDUX_USER,
      "users.getFreeSummaryCount",
      getState().token,
      getGlobal(GlobalKey.TOKEN)
    );
    next(users.fetchFreeSummaryCount.request());
    try {
      let result = await fetchAPI(
        API.ACTION_SUMMARY_CNT,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_USER, result);
      let leftCount = FREE_SUMMARY_COUNT;
      if (result && !result.error) {
        leftCount = FREE_SUMMARY_COUNT - result.data;
        if (leftCount < 0) leftCount = 0;
      }
      next(users.fetchFreeSummaryCount.success(leftCount));
    } catch (e) {
      log(LogLevel.REDUX_USER, "users.getFreeSummaryCount: error: ", e);
      next(users.fetchFreeSummaryCount.failure(e));
    }
  }
};
