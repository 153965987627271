import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import { RowDirectionButtonContainer } from "./style";

interface BeforeLeaveSettingPopupProps extends PopupProps {
  onNotSave: () => void;
  onSave: () => void;
}

const BeforeLeaveSettingPopup: React.FC<BeforeLeaveSettingPopupProps> = ({ onClose, onNotSave, onSave }) => {
  return (
    <Popup onClose={onClose}>
      <div>
        <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          변경 사항을 저장할까요?
        </Text>
      </div>
      <RowDirectionButtonContainer>
        <Button color="Secondary" size="Large" type="Text" variant="Tinted" onClick={onNotSave}>
          아니오
        </Button>
        <Button color="Primary" size="Large" type="Text" variant="Contained" onClick={onSave}>
          예
        </Button>
      </RowDirectionButtonContainer>
    </Popup>
  );
};

export default BeforeLeaveSettingPopup;
