import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import {
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonList,
  IonSpinner,
} from "@ionic/react";
import "./Notifications.css";
import "./../Common.css";
import SimpleBoardList from "./../components/SimpleBoardList";
import { BoardType } from "./../store/board/types";
import { log, LogLevel } from "../utils/LogUtil";
import { Notification } from "../models/Model.Notification";

import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import NotificationListItem from "../components/NotificationListItem";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  segment: string;
};

class Notifications extends Component<Props> {
  contentScroll;
  contentList;

  state = {
    segment: "all",
  };

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
  }

  componentDidMount() {
    let os = getGlobal(GlobalKey.OS);
    if (os === "browser") {
      this.props.load();
    }
    this.props.check();
    this.autoScroll();
  }

  componentWillUnmount() {
    setGlobal(GlobalKey.SCROLL + "notification", 0);
  }

  onBack = () => {
    this.props.history.goBack();
  };

  onUserSelected = (noti: Notification) => {
    if (noti.fromUsersId && noti.fromUsersId != noti.rootBoardContentUserId)
      this.props.history.push("/user?id=" + noti.fromUsersId);
  };

  onNotiSelected = (noti: Notification) => {
    if (!noti.checked) this.props.checkItem(noti.id);
    if (noti.rootBoardContentId) {
      let subQuery = "";
      if (noti.boardsContentsId) subQuery += "&fcnt=" + noti.boardsContentsId;
      if (noti.boardsCommentsId) subQuery += "&fcmt=" + noti.boardsCommentsId;
      this.props.history.push(
        "/boards/view?id=" + noti.rootBoardContentId + subQuery
      );
    } else if (noti.boardsContentsId)
      this.props.history.push("/boards/view?id=" + noti.boardsContentsId);
  };

  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = () => {
    setTimeout(async () => {
      try {
        if (this.contentScroll) {
          let scroll = getGlobal(GlobalKey.SCROLL + "notification".toString());
          if (scroll) {
            log(
              LogLevel.UI_EVENT,
              "Notifications:autoScroll do scroll to height",
              scroll
            );
            await this.contentScroll.scrollTo(0, scroll);
          } else {
            log(
              LogLevel.UI_EVENT,
              "Notifications:autoScroll has no scroll height"
            );
          }
        } else {
          log(LogLevel.UI_EVENT, "Notifications:autoScroll no scroll ");
          this.autoScrollTry++;
          if (this.autoScrollTry < this.autoScrollTryMax) {
            this.autoScroll();
          }
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "Notifications:autoScroll failed", e);
        this.autoScrollTry++;
        if (this.autoScrollTry < this.autoScrollTryMax) {
          this.autoScroll();
        }
      }
    }, 100);
  };

  onScroll = (event) => {
    let target: any = event.target;

    if (!target) return;
    setGlobal(GlobalKey.SCROLL + "notification", target.scrollTop);
    log(LogLevel.UI_ACTION, "Notifications:scroll", target.scrollTop);

    // log(LogLevel.UI_EVENT, "BoardList:scroll", event.detail.scrollTop, target.clientHeight, this.contentList.clientHeight);
    if (
      !this.props.notification.end &&
      !this.props.notification.loading &&
      target.scrollTop + target.clientHeight >
        this.contentList.clientHeight * 0.9
    ) {
      log(
        LogLevel.UI_EVENT,
        "BoardList:Scroll bottom loads more contents",
        target.scrollTop,
        target.clientHeight,
        this.contentList.clientHeight
      );
      this.props.loadMore();
    }
  };

  render() {
    log(LogLevel.NONE, "Notifications:render");

    let loading;

    if (
      !this.props.notification ||
      this.props.notification.loading ||
      (!this.props.notification.end && !this.props.notification.notifications)
    )
      loading = (
        <div className="common-container-column common-flex-align-center">
          <IonSpinner name="crescent" />
        </div>
      );

    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                class="common-header-toolbar-button"
                onClick={this.onBack}
              >
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="common-title" onClick={this.onBack}>
                알림
              </IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="common-content-with-header">
          <div
            className="common-content common-scroll"
            ref={(ref) => {
              this.contentScroll = ref;
            }}
            onScroll={this.onScroll}
          >
            <IonList
              ref={(ref) => {
                this.contentList = ref;
              }}
              class="common-container"
              mode="md"
            >
              {this.props.notifications.map((item, index: number) => (
                <NotificationListItem
                  key={index.toString()}
                  noti={item}
                  onClickUser={this.onUserSelected}
                  onClickItem={this.onNotiSelected}
                />
              ))}
              {loading}
            </IonList>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  notifications: state.notification.notifications,
  notification: state.notification,
});

const mapDispatchToProps = {
  loadFilePath: () => actions.board.getFilePath(),
  checkItem: (id: number) => actions.notification.checkItem(id),
  check: () => actions.notification.check(),
  load: () => actions.notification.refresh(),
  loadMore: () => actions.notification.load(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
);
