import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { COLOR_SYSTEM } from "../../design/design-system";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  AllInOnePartnerInfo,
  CompanyTypeName,
  UserInfo,
  UserWorkType,
  UserWorkTypeName,
} from "../../../models/Model.User";
import Text from "../../atoms/Text";
import Popup from "../../molecules/Popup";
import { withRouter, RouteComponentProps } from "react-router-dom";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import "./style.scss";
import "./../../../Common.css";
import Button from "../../atoms/Button";
import { Header } from "../../atoms/Layout";
import Tag from "../../atoms/Tag";
import Body from "../../cell/Body";
import TabBar from "../../molecules/TabBar";
import Spinner from "../../atoms/Spinner";
import SwipeableViews from "react-swipeable-views";
import { getGlobal } from "../../../utils/GlobalUtil";
import ReactHtmlParser from "react-html-parser";
import Footer from "../../cell/Footer";
import StringUtil from "../../../utils/StringUtil";
import { BoardContent } from "../../../models/Model.Board";
import PostListItem from "../../organisms/AllInOne/PostListItem";

export interface AllInOneDetailProps {
  me: UserInfo;
  onGoBack: () => void;
  onShare: () => void;
  onCall: () => void;
  onConsult: () => void;
  partner: AllInOnePartnerInfo;
  posts: BoardContent[];
  contents: any;
  onClickPost: (post: BoardContent) => void;
  onClickLink: (url: string) => void;
}

const AllInOneDetail: React.FC<AllInOneDetailProps & RouteComponentProps> = ({
  // history,
  me,
  onGoBack,
  onShare,
  onCall,
  onConsult,
  partner,
  posts,
  contents,
  onClickPost,
  onClickLink,
}) => {
  const [index, setIndex] = useState<number>(
    getGlobal("intAllInOneDetailTabIndex")
  );

  try {
    if (!partner) {
      return <Spinner />;
    }

    let swipeViews = [];
    if (posts && posts.length > 0) {
      swipeViews.push(
        <div key="0" className="allinone-posts-container">
          {posts.map((post, index) => (
            <PostListItem
              key={index.toString()}
              content={contents[post.id]}
              partner={partner}
              onClick={() => onClickPost(post)}
            />
          ))}
        </div>
      );
    }
    swipeViews.push(
      <div
        key="1"
        className="allinone-information-container"
        style={{ paddingTop: "20px" }}
      >
        <div className="allinone-information-body">
          <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
            {ReactHtmlParser(partner.introductionHTML)}
          </Text>
        </div>
        <div className="allinone-information-title">
          <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            주소
          </Text>
        </div>
        <div className="allinone-information-body">
          <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
            {partner.companyAddress}
          </Text>
        </div>
        {partner.homepage && (
          <div className="allinone-information-title">
            <Text
              textType="Body1SemiBold"
              color={COLOR_SYSTEM.get("Gray")[900]}
            >
              웹사이트
            </Text>
          </div>
        )}
        {partner.homepage && (
          <div
            className="allinone-information-body"
            onClick={(e) => {
              onClickLink(partner.homepage);
            }}
          >
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
              {partner.homepage}
            </Text>
          </div>
        )}
      </div>
    );

    return (
      <div className="common-content common-position-relative">
        <Header>
          <TopAppBar
            titleType="OFF"
            leftButtons={[
              <Button
                color="Tertiary"
                icon="X"
                onClick={onGoBack}
                size="Medium"
                type="Icon"
                variant="Ghost"
              />,
            ]}
            rightButtons={[
              <Button
                color="Secondary"
                icon="Share"
                size="Medium"
                type="Icon"
                variant="Ghost"
                onClick={onShare}
              />,
            ]}
          />
        </Header>
        <Body footerHeight={88}>
          <div className="allinone-detail-head-container">
            <div className="common-flex-grow">
              <div className="allinone-detail-title">
                <Text
                  textType="LargeTitle2"
                  color={COLOR_SYSTEM.get("Gray")[700]}
                >
                  {partner.companyProfileName || partner.companyName}
                </Text>
                <div style={{ marginTop: "5px", marginBottom: "9px" }}>
                  <Tag size="Small" variant="Ghost" color="Blue">
                    {CompanyTypeName[partner.companyType]}
                  </Tag>
                </div>
              </div>
            </div>
            <img
              className="allinone-detail-logo"
              src={StringUtil.convertFilePath(partner.logoImageUrl)}
              onError={(e) => {
                let targetAny: any = e.target;
                targetAny.src = "/images/profile_p.jpg";
              }}
            />
          </div>
          {posts && posts.length > 0 && (
            <TabBar
              size="Medium"
              type="Underlined"
              sameWidth={true}
              items={[
                {
                  active: index == 0,
                  badge: false,
                  onClick: () => setIndex(0),
                  text: "콘텐츠",
                },
                {
                  active: index == 1,
                  badge: false,
                  onClick: () => setIndex(1),
                  text: "회사정보",
                },
              ]}
              scrollable={false}
            />
          )}
          <SwipeableViews
            className="allinone-swipe"
            enableMouseEvents
            hysteresis={0.1}
            index={index}
            onChangeIndex={(index) => setIndex(index)}
          >
            {swipeViews}
          </SwipeableViews>
        </Body>
        {me && (
          <Footer>
            <div className="allinone-footer">
              <Button
                style={{ flexGrow: 1 }}
                size={"Medium"}
                type={"Text"}
                variant={"Outlined"}
                color={"Secondary"}
                onClick={onCall}
              >
                전화하기
              </Button>
              <Button
                style={{ flexGrow: 1 }}
                size={"Medium"}
                type={"Text"}
                variant={"Contained"}
                color={"Secondary"}
                onClick={onConsult}
              >
                문의 남기기
              </Button>
            </div>
          </Footer>
        )}
      </div>
    );
  } catch (error) {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "AllInOneDetailTemplate",
      errorMessage: error,
    });
    return null;
  }
};

export default withRouter(AllInOneDetail);
