import { BoardContent } from "./Model.Board";
import { UserInfo } from "./Model.User";

export enum OrderStatus {
  NONE = 0,
  IN_CART = 1,
  ORDERED = 2,
  PAID = 3,
  PREPARING_PRODUCT = 4,
  PREPARING_DELIVER = 5,
  DELIVERING = 6,
  DELIVERED = 7,
  COMPLETED = 8,
  CANCELED = 101,
  ORDER_FAILED = 102,
  RETURNING = 103,
  RETURN = 104,
}

export const OrderStatusName = {
  0: "없음",
  1: "장바구니",
  2: "주문접수",
  3: "제품준비중",
  4: "제품준비중",
  5: "배송준비중",
  6: "배송중",
  7: "배송완료",
  8: "주문확정",
  101: "결제취소",
  102: "주문실패",
  103: "반품대기중",
  104: "반품완료",
};

export enum ProductStatus {
  NONE = 0x00,
  PREPARING = 0x01, // 준비중
  ONGOING = 0x02, // 진행중
  SHORT = 0x03, // 품절
  CLOSED = 0x04, // 종료
}

export const ProductStatusName = {
  [ProductStatus.NONE]: "없음",
  [ProductStatus.PREPARING]: "준비중",
  [ProductStatus.ONGOING]: "진행중",
  [ProductStatus.SHORT]: "품절",
  [ProductStatus.CLOSED]: "종료",
};

export enum OrderPayMethod {
  NONE = 0, // 준비중
  CARD = 1, // 카드결제
  BANK = 2, // 계좌이체
  VBANK = 3, // 가상계좌
  PHONE = 4, // 휴대폰소액결제
}

export interface ProductInfo {
  id?: number;
  name?: string;
  code?: string;
  description?: string;
  company?: string;
  contentId?: number;
  content?: BoardContent;
  status?: ProductStatus;
  priceOriginal?: number; // 판매단위 가격
  price?: number; // 실제 판매단위 가격
  deliveryFee?: number; // 배송비 가격
  orderAmountLimitCondition?: any; // 인당 판매제한 수량 로직(판매단위)
  deliveryFeeCondition?: any; // 인당 판매제한 수량 로직(판매단위)
  createdAt?: string;
  deletedAt?: string;
  image?: string; // main image : 1x1
  imageBase64?: any; // main image : 1x1
  bannerImage?: string; // banner image
  bannerImageBase64?: any; // banner image

  guide?: any; // {title, description}
  // saleUnit?: string;      // 판매단위 (개, 통, 박스)
  // amountPerUnit?: number; // 판매단위당 개수
  // itemUnit?: string;      // 물품 단위 (개, 통)
  // totalAmount?: number;    // 재고 시작총 수령 (판매단위)
  // stock?: number;     // 재고 (판매단위)
  // orderAmountLimit?: number; // 인당 판매제한 수량 (판매단위)
  // parentId?: number;
  // products?:ProductInfo[];

  advertiserCode?: string;
  advertiserProductCode?: string;
  documentId?: string;
  documentTabName?: string;
  refundDocumentId?: string;
  refundDocumentTabName?: string;
  refundSlackHookUrl?: string;

  loading?: boolean;
  error?: boolean;
  pharmacyOnly?: boolean; // 약국 전용 상품 여부 // 약국 인증이 필요한 상품
}

export interface OrderInfo {
  id?: number;
  userId?: number;
  productId?: number;
  product?: ProductInfo;
  user?: UserInfo;
  status?: OrderStatus;
  amount?: number; // 주문량
  returnAmount?: number; // 반품량
  returnReason?: string; // 반품사유
  payProduct?: number; // 총 상품 가격
  payDelivery?: number; // 배송비
  payRefund?: number; // 반품비
  payAmount?: number; // 결제액

  deliveryCompany?: string; // 택배사
  deliveryId?: string; // 운송장 번호
  recipientName?: string;
  recipientPostalCode?: string;
  recipientAddress1?: string;
  recipientAddress2?: string;
  recipientPhone?: string;

  createdAt?: string;
  orderedAt?: string; // 주문일
  paidAt?: string; // 결제일
  deliverAt?: string; // 배송준비일
  deliverPlannedAt?: string; // 예상배송일
  deliveredAt?: string; // 배송완료일
  closedAt?: string; // 주문완료일
  canceledAt?: string; // 주문취소일
  returnAt?: string; // 반품일
  returnedAt?: string; // 반품완료일
  deletedAt?: string;

  payMethod?: OrderPayMethod; // 결제방법
  paidAmount?: number; // 결제금액
  payStatus?: string; // 결제상태
  payApplyNumber?: string; // 카드사 승인번호
  payRecieptUrl?: string; // 거래매출전표 URL
  payMerchantId?: string; // 아임포트 결제 id
  impUid?: string; // 아임포트 결제 고유번호
  canceledAmount?: number; // 취소금액

  // subOrders?: OrderInfo[];
  // parentId?: number;
  // productParentId?: number;
  loading?: boolean;
  error?: boolean;
}

export interface AddressInfo {
  id?: number;
  addressName?: string;
  recipientName?: string;
  recipientPostalCode?: string;
  recipientAddress1?: string;
  recipientAddress2?: string;
  recipientPhone?: string;

  createdAt?: string;
  deletedAt?: string;
}
