import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
  AllInOnePartnerInfo,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import AllInOnePartnerSelect from "./component/allinone/AllInOnePartnerSelect";
import ToggleSwitch from "../component/atom/ToggleSwitch";
import { BoardContentQuiz, DailyQuiz } from "../models/Model.Board";
import QuizSelect from "./component/quiz/QuizSelect";
import QuizDetail from "./component/quiz/QuizDetail";
import WeeklyDigestNiceList from "./component/WeeklyDigestNiceList";
import NiceContentSelect from "./component/quiz/NiceContentSelect";
import ToggleButton from "../components/atoms/ToggleButton/ToggleButton";
import ContentDetail from "./component/quiz/ContentDetail";
import { on } from "cluster";
import { BoardQuizFetcher } from "../utils/BoardQuizUtil";
import DailyQuizSelect from "./component/quiz/DailyQuizSelect";
import DailyQuizDetail from "./component/quiz/DailyQuizDetail";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selectedDailyQuiz: DailyQuiz;
  selectedQuiz: BoardContentQuiz;
  selectedContentId: number;
  showQuiz: boolean;
  showQuizList: boolean;
  showDailyQuizList: boolean;
  showDailyQuiz: boolean;
  showContentList: boolean;
  showContent: boolean;
  onAddQuiz?: (quiz: BoardContentQuiz) => void;
};

class BoardQuizManage extends Component<Props, State> {
  popup = null;
  quizList = null;
  dailyQuizList = null;

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
    document.title = "GPT 퀴즈 관리";

    this.state = {
      selectedDailyQuiz: null,
      selectedQuiz: null,
      selectedContentId: 0,
      showQuiz: true,
      showDailyQuizList: false,
      showDailyQuiz: false,
      showQuizList: true,
      showContentList: false,
      showContent: false,
      onAddQuiz: null,
    };
  }

  componentDidMount() {}

  onSelectQuiz = (quiz: BoardContentQuiz) => {
    log(LogLevel.UI_EVENT, "BoardQuizManage.onSelectQuiz", quiz);
    this.setState({ selectedQuiz: quiz });
  };

  onSelectDailyQuiz = (dailyQuiz: DailyQuiz) => {
    log(LogLevel.UI_EVENT, "BoardQuizManage.onSelectDailyQuiz", dailyQuiz);
    this.setState({ selectedDailyQuiz: dailyQuiz, showDailyQuiz: true });
  };

  // setOnAdd?: (onAdd: (quiz: BoardContentQuiz) => void) => void;
  setOnAddQuiz = (onAdd: (quiz: BoardContentQuiz) => void) => {
    this.setState({ onAddQuiz: onAdd });
  };

  onSelectContent = (contentId: number) => {
    if (this.state.selectedContentId == contentId)
      this.setState({ selectedContentId: 0 });
    else
      this.setState({
        selectedContentId: contentId,
        selectedQuiz: null,
        showContent: true,
      });
  };

  onGenerateContent = (contentId: number, prompt: string) => {
    log(LogLevel.UI_EVENT, "BoardQuizManage.onGenerateContent", contentId);
    const quizFetcher = new BoardQuizFetcher(
      contentId,
      prompt,
      (quiz) => {
        this.setState({ selectedQuiz: { ...quiz } });
      },
      (quiz) => {
        this.setState({ selectedQuiz: { ...quiz } });
        if (this.quizList) this.quizList.refresh();
      },
      (err) => {
        log(
          LogLevel.UI_ACTION,
          "BoardQuizManage.onGenerateContent onGPTAgain failed",
          err
        );
      }
    );
  };

  onQuizUpdateDone = (quiz: BoardContentQuiz) => {
    log(LogLevel.UI_EVENT, "BoardQuizManage.onDone", this.state.selectedQuiz);
    if (this.quizList) this.quizList.refresh(quiz);
    if (quiz) this.setState({ selectedQuiz: quiz });
    else this.setState({ selectedQuiz: null });
  };

  onDailyQuizUpdateDone = (dailyQuiz: DailyQuiz) => {
    log(LogLevel.UI_EVENT, "BoardQuizManage.onDone", this.state.selectedQuiz);
    if (this.dailyQuizList) this.dailyQuizList.refresh(dailyQuiz);
    if (dailyQuiz) this.setState({ selectedQuiz: dailyQuiz });
    else this.setState({ selectedQuiz: null });
  };

  onSearch = (options) => {
    log(LogLevel.UI_EVENT, "BoardQuizManage.onSearch", options);
    if (this.quizList) this.quizList.search(options);
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "BoardQuizManage.onCancel", this.state.selectedQuiz);
    this.setState({ selectedQuiz: null });
  };

  onDeleteQuiz = (id: number) => {
    this.setState({ selectedQuiz: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardQuizManage.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">GPT 퀴즈 관리</IonText>
          <IonButtons slot="end">
            <ToggleButton
              onClick={() =>
                this.setState({
                  showDailyQuizList: !this.state.showDailyQuizList,
                })
              }
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showDailyQuizList}
            >
              오늘의 퀴즈 리스트
            </ToggleButton>
            <ToggleButton
              onClick={() =>
                this.setState({
                  showDailyQuiz: !this.state.showDailyQuiz,
                })
              }
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showDailyQuiz}
            >
              오늘의 퀴즈 상세
            </ToggleButton>
            <ToggleButton
              onClick={() =>
                this.setState({ showQuizList: !this.state.showQuizList })
              }
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showQuizList}
            >
              퀴즈 리스트
            </ToggleButton>
            <ToggleButton
              onClick={() => this.setState({ showQuiz: !this.state.showQuiz })}
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showQuiz}
              disabled={!this.state.selectedQuiz}
              disabledColor={!this.state.selectedQuiz}
            >
              퀴즈 상세
            </ToggleButton>
            <ToggleButton
              onClick={() =>
                this.setState({ showContentList: !this.state.showContentList })
              }
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showContentList}
            >
              추천 콘텐츠 리스트
            </ToggleButton>
            <ToggleButton
              onClick={() =>
                this.setState({ showContent: !this.state.showContent })
              }
              size={"Small"}
              type={"Text"}
              variant={"Tinted"}
              color={"Skyblue"}
              style={{ marginRight: "10px" }}
              active={this.state.showContent}
              disabled={
                !(
                  (this.state.selectedQuiz &&
                    this.state.selectedQuiz.contentId) ||
                  this.state.selectedContentId
                )
              }
              disabledColor={
                !(
                  (this.state.selectedQuiz &&
                    this.state.selectedQuiz.contentId) ||
                  this.state.selectedContentId
                )
              }
            >
              콘텐츠 상세{" "}
            </ToggleButton>
          </IonButtons>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            {this.state.showDailyQuizList && (
              <div className="admin-container">
                <DailyQuizSelect
                  onSelect={this.onSelectDailyQuiz}
                  dailyQuiz={this.state.selectedDailyQuiz}
                  ref={(ref) => {
                    this.dailyQuizList = ref;
                  }}
                />
              </div>
            )}
            {this.state.showDailyQuiz && (
              <div className="admin-container">
                <DailyQuizDetail
                  dailyQuiz={this.state.selectedDailyQuiz}
                  me={this.props.me}
                  onDone={this.onDailyQuizUpdateDone}
                  onSelect={this.onSelectDailyQuiz}
                  onSelectQuiz={this.onSelectQuiz}
                  onCancel={() => {
                    this.setState({ showDailyQuiz: false });
                  }}
                  setOnAdd={this.setOnAddQuiz}
                />
              </div>
            )}
            {this.state.showQuizList && (
              <div className="admin-container">
                <QuizSelect
                  onSelect={this.onSelectQuiz}
                  quiz={this.state.selectedQuiz}
                  ref={(ref) => {
                    this.quizList = ref;
                  }}
                  onClose={() => this.setState({ showQuizList: false })}
                  contentId={this.state.selectedContentId}
                  onAdd={this.state.onAddQuiz}
                />
              </div>
            )}
            {this.state.selectedQuiz && (
              <div id="admin-sub-conainer" className="admin-container">
                <QuizDetail
                  quiz={this.state.selectedQuiz}
                  onDone={this.onQuizUpdateDone}
                  onCancel={this.onCancel}
                  onAdd={this.state.onAddQuiz}
                  me={this.props.me}
                />
              </div>
            )}
            {this.state.showContentList && (
              <div id="admin-sub-conainer" className="admin-container">
                <NiceContentSelect onSelect={this.onSelectContent} />
              </div>
            )}
            {((this.state.selectedQuiz && this.state.selectedQuiz.contentId) ||
              this.state.selectedContentId > 0) &&
              this.state.showContent && (
                <div id="admin-sub-conainer" className="admin-container">
                  <ContentDetail
                    contentId={
                      (this.state.selectedQuiz &&
                        this.state.selectedQuiz.contentId) ||
                      this.state.selectedContentId
                    }
                    onCancel={() => this.setState({ showContent: false })}
                    onGenerateQuiz={this.onGenerateContent}
                  />
                </div>
              )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardQuizManage)
);
