import React, { Component } from 'react';
import './Welcome.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton } from '@ionic/react';
import profileM from './../assets/image/profile_m_4.png'
import profileF from './../assets/image/profile_w_1.png'
import SwipeableViews from 'react-swipeable-views';
import { UserInfo } from '../models/Model.User';
import SliderIndex from './SliderIndex';
import WelcomeStage1Interest from './WelcomeStage1Interest';
import WelcomeStage1Description from './WelcomeStage1Description';
import welcomeImage1 from './../assets/image/welcome1.png';
import welcomeImage2 from './../assets/image/welcome2.png';
import welcomeImage3 from './../assets/image/welcome3.png';
import welcomeImage4 from './../assets/image/welcome4.png';
import welcomeImage5 from './../assets/image/welcome5.png';
import { LogLevel, log } from '../utils/LogUtil';


type Props = {
  interest: string;
  onUpdateInterest: (interest: string) => void;
  onDone: () => void;
};

type State = {
  index: number
}

class WelcomeStage1 extends Component<Props, State> {
  state = {
    index:0,
  }

  handleChangeIndex = (index: number) => {
    this.setState({index: index});
  }

  onNext = () => {
    log(LogLevel.UI_ACTION, "WelcomeStage1:onNext")
    this.setState({index: this.state.index+1});
  }

  render() {  
    return (
      <div className="welcome-container">
        <div className="welcome-slider-container"><SliderIndex cnt={6} index={this.state.index}/></div>
        <div className="welcome-swipe-container"> 
          <SwipeableViews className="welcome-swipe" enableMouseEvents index={this.state.index} onChangeIndex={this.handleChangeIndex}>
            <WelcomeStage1Interest interest={this.props.interest} onUpdateInterest={this.props.onUpdateInterest} onNext={this.onNext}/>
            <WelcomeStage1Description onNext={this.onNext} img={welcomeImage1} description={<span>허가사항과 부작용을<br/>간편하게 확인하세요.</span>}/>
            <WelcomeStage1Description onNext={this.onNext} img={welcomeImage2} description={<span>질문해 보세요.<br/>경험있는 약사님이 답해드립니다.</span>}/>
            <WelcomeStage1Description onNext={this.onNext} img={welcomeImage3} description={<span>다른 회원들과<br/>상호작용 하세요.</span>}/>
            <WelcomeStage1Description onNext={this.onNext} img={welcomeImage4} description={<span>좋은 컨텐츠는<br/>챙겨보고, 나눠보세요.</span>}/>
            <WelcomeStage1Description onDone={this.props.onDone} img={welcomeImage5} description={<span>내 활동을 한 눈에<br/>파악해 보세요.</span>}/>
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

export default WelcomeStage1;