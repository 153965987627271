import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton, IonHeader, IonToolbar, IonButtons, IonIcon, IonLabel, IonContent, IonSegment, IonSegmentButton, IonInput, IonTextarea, IonItem, IonToggle } from '@ionic/react';
import './Setting.css';
import {fetchAPI} from "../utils/APIUtil"
import * as API from './../API.json';
import logoIcon from './../assets/icon/logo.png'
import kakaoIcon from './../assets/icon/question_small_yellow_mobile_3X.png'
import profile from './../assets/image/profile_m_1.png'
import BoardList from './../components/BoardList'
import DynamicChecker from '../components/DynamicChecker';
import {log, LogLevel} from '../utils/LogUtil'

import {getGlobal, GlobalKey, setGlobal} from './../utils/GlobalUtil'
import {TARGET} from './../config.json';
import {VERSION,BUILD_DATE} from './../version.json';
import { UserInfo, UserLevel } from '../models/Model.User';
import CheckToggleButton from '../components/CheckToggleButton';
import { UIServiceType } from '../store/ui/types';
import ToggleSwitch from '../component/atom/ToggleSwitch';

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
}

type State = {
  segment: string;
  nickname: string;
  nicknameCheckStatus: number
  description: string;
}

class Setting extends Component<Props, State> {

  
  constructor(props: Props) {
    super(props);
    this.state = {
      segment: 'write',
      nickname: "씨잘맨",
      nicknameCheckStatus: 0,
      description: '개국 1년차 초보 약국장입니다. 신경계 약물에 관심이 많습니다. 잘부탁드려요~~',
    }
  }

  componentDidMount () {
    if(this.props.backKeyControl) this.props.backKeyControl.setListener(this.onBack);

  }

  onBack = () => {
    this.props.history.goBack();
  }

  onDone = () => {
    this.props.history.goBack();
  }

  onPressLogout = () => {
    this.props.logOutUser();
    this.props.history.replace('/');
  }

  onPressUnregister = () => {

  }


  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    let windowAny:any = window;
    if(!os || os == "browser"){
      try{
        windowAny.Kakao.init('0d4139a6dc131b05b8109f629d7cc3f7');
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
      try{
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: '_Vxjhxgj' // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }else{  
      let plusFriendTemplate = {
        plusFriendId: '_Vxjhxgj',
      };
      try{
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  }

  onClickAgreeMarketing = async () => {
    let newMe:UserInfo = {};
    if(this.props.me.agreeMarketingAt){
      newMe.agreeMarketingAt = null;
    }else{
      newMe.agreeMarketingAt = 1;
    }

    let result = await fetchAPI(API.USER_ME_UPDATE, '', null, newMe, getGlobal(GlobalKey.TOKEN))
    if(result && !result.error) {
      this.props.updateMe(newMe);
    }
  }

  render() {
    log(LogLevel.NONE, "MyProfileChange:render");
    let appVersion = "1.0.0";
    let os = getGlobal(GlobalKey.OS);
    let windowAny: any = window;
    if(os && os != "browser" && windowAny.AppVersion) {
      appVersion = windowAny.AppVersion.version;
    }

    let enableTest;
    if(this.props.me.level >= UserLevel.MANAGER){
      let enabled:boolean = getGlobal(GlobalKey.ENABLE_TEST, true);
      enableTest = (
        <div className="setting-body-container">
          <IonItem class="setting-item-container"  onClick={(e) => {setGlobal(GlobalKey.ENABLE_TEST, !enabled, true)}}>
            <div className="setting-item-inner">
              <div className="setting-title">테스트 기능 활성화</div>
              <IonToggle class="setting-notification-toggle" mode="ios" checked={enabled}/>
            </div>
          </IonItem>
        </div>
      )
    }

    return (
      <>
        <IonHeader class="setting-header" no-border>
          <IonToolbar color="setting-toolbar">
            <IonButtons slot="start">
              <IonButton class="setting-toolbar-button" onClick={this.onBack}>
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel onClick={this.onBack}>설정</IonLabel>
            </IonButtons>
            {/* <IonButtons slot="end">
              <IonButton class="setting-toolbar-button" onClick={this.onDone}>
                완료
              </IonButton>
            </IonButtons> */}
          </IonToolbar>
        </IonHeader>
        <div className="common-content-with-header common-scroll">
          <div className="setting-body-container">
            <IonItem class="setting-item-container" onClick={() => this.props.history.push('/main/user/setting/notice')}>
              <div className="setting-item-inner">
                <div className="setting-title">공지사항</div>
                <IonButton color="setting-button">
                  <IonIcon name="arrow-forward" mode="ios"/>
                </IonButton>
              </div>
            </IonItem>
            <IonItem class="setting-item-container" onClick={() => this.props.history.push('/main/user/setting/notification')}>
              <div className="setting-item-inner">
                <div className="setting-title">알림 설정</div>
                <IonButton color="setting-button">
                  <IonIcon name="arrow-forward" mode="ios"/>
                </IonButton>
              </div>
            </IonItem>
            <IonItem class="setting-item-container">
              <div className="setting-item-inner">
                <div className="setting-title">마케팅 수신 동의</div>
                <div style={{marginRight:"10px"}}>
                  <ToggleSwitch checked={this.props.me.agreeMarketingAt} onClick={this.onClickAgreeMarketing}/>
                </div>
              </div>
            </IonItem>
            <IonItem class="setting-item-container" onClick={this.onChat }>
              <div className="setting-item-inner">
                <div className="setting-title">피드백남기기</div>
                <img className="setting-kakao" src={kakaoIcon}/>
              </div>
            </IonItem>
          </div>
          <div className="setting-body-container">
            <IonItem class="setting-item-container" onClick={() => this.props.history.push('/main/user/setting/faq')}>
              <div className="setting-item-inner">
                <div className="setting-title">FAQ</div>
                <IonButton color="setting-button">
                  <IonIcon name="arrow-forward" mode="ios"/>
                </IonButton>
              </div>
            </IonItem>
            <IonItem class="setting-item-container" onClick={() => this.props.history.push('/main/user/setting/privacy')}>
              <div className="setting-item-inner">
                <div className="setting-title">개인정보보호 지침</div>
                <IonButton color="setting-button">
                  <IonIcon name="arrow-forward" mode="ios"/>
                </IonButton>
              </div>
            </IonItem>
            <IonItem class="setting-item-container" onClick={() => this.props.history.push('/main/user/setting/contract')}>
              <div className="setting-item-inner">
                <div className="setting-title">이용 약관</div>
                <IonButton color="setting-button">
                  <IonIcon name="arrow-forward" mode="ios"/>
                </IonButton>
              </div>
            </IonItem>
            <IonItem class="setting-item-container" onClick={() => this.props.history.push('/main/user/setting/company')}>
              <div className="setting-item-inner">
                <div className="setting-title">사업자 정보</div>
                <IonButton color="setting-button">
                  <IonIcon name="arrow-forward" mode="ios"/>
                </IonButton>
              </div>
            </IonItem>
            <IonItem class="setting-item-container">
              <div className="setting-item-inner">
                <div className="setting-title">앱 버전 정보</div>
                <div className="setting-version">{appVersion}</div>
              </div>
            </IonItem>
            <IonItem class="setting-item-container">
              <div className="setting-item-inner">
                <div className="setting-title">서비스 버전 정보</div>
                <div className="setting-version">{VERSION}.{BUILD_DATE}.{TARGET.toUpperCase()}</div>
              </div>
            </IonItem>
          </div>
          <div className="setting-body-container">
            <IonItem class="setting-item-container" onClick={this.onPressLogout}>
              <div className="setting-item-inner">
                <div className="setting-title-red">로그아웃</div>
              </div>
            </IonItem>
            <IonItem class="setting-item-container" onClick={this.onPressUnregister}>
              <div className="setting-item-inner">
                <div className="setting-title-hide">회원탈퇴</div>
              </div>
            </IonItem>
          </div>
          {enableTest}
        </div>
      </>

    );
  }
}

const mapStateToProps = (state: RootState) => ({
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
  me: state.user.me
});

const mapDispatchToProps = {
  logOutUser: () => actions.user.logout(),
  updateMe: (me:UserInfo) => actions.user.setMe(me),

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Setting));