import React, { Component } from 'react';
import './InterestSelectPopup.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem } from '@ionic/react';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';;
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../utils/LogUtil'
import InterestSelectToggleButton from './InterestSelectToggleButton';
import { UserInterests } from '../models/Model.User';

type Props = {
  onDone?: (interest:string) => void; 
};

type State = {
  interest: string;
  visible: boolean;
}


class InterestSelectPopup extends Component<Props, State> {
  state={
    interest: '-',
    visible: false,
  };

  show = (interest:string) => {
    log(LogLevel.UI_ACTION, "InterestSelectPopup:show", interest)
    this.setState({interest:interest, visible:true});
  }
  
  hide = () => {
    log(LogLevel.UI_ACTION, "InterestSelectPopup:hide")
    this.setState({visible: false});
  }
  
  isShown = () => {
    return this.state.visible;
  }

  onDone = () => {
    this.hide();
  }
  
  onCheckAll = (code:string, checked:boolean) => {
    let interest;
    if(checked)
      interest = '-';
    else 
      interest = 'ABCDGHJPLMNRSV';
    this.setState({interest});
    if(this.props.onDone)
      this.props.onDone(interest);
  }
  
  onCheck = (code:string, checked:boolean) => {
    let interest;
    if(checked)
      interest = this.state.interest.replace(code, '');
    else {
      interest = this.state.interest + code;
      interest = interest.replace('-', '');
    }
    if(!interest)
      interest = '-';
    log(LogLevel.UI_ACTION, "InterestSelectPopup:onCheck", code, interest, checked)
    this.setState({interest});
    if(this.props.onDone)
      this.props.onDone(interest);
  }
  
  render() {
    return (
      <IonModal cssClass="interest-select-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="interest-select-popup-inner">
          <div className="interest-select-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
          <div className="interest-select-container">
            <div className="interest-select-title-container">
              <div className="interest-select-title">관심분야 수정</div>
              <IonButton color="interest-select-popup-close" onClick={() => this.setState(() => ({ visible: false }))}>
                <IonIcon class="interest-select-popup-close-icon" name="close"/>
              </IonButton>
            </div>
            <div className="interest-select-title">관심분야 수정</div>
            <div className="interest-select-body-container">
              <InterestSelectToggleButton name="모두" code="ALL" checked={this.state.interest.length >= 14} onClick={this.onCheckAll} />
              <div className="common-container">
                {UserInterests.map((item, index) => 
                  <InterestSelectToggleButton key={index.toString()} name={item.name} code={item.code} checked={this.state.interest.includes(item.code)} onClick={this.onCheck} />
                )}
              </div>
            </div>
          </div>
          <div className="interest-select-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
        </div>
      </IonModal>
    );
  }
}

export default InterestSelectPopup;