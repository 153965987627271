

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonLabel, IonSpinner } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import * as API from './../../../API.json';
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../../models/Model.Board';
import Truncate from 'react-truncate';
import { BannerInfo, EMPTY_BANNER, BannerType } from '../../../models/Model.Banner';
import { PollInfo, EMPTY_POLL, PollItemInfo, PollResponseInfo, PollStatisticsInfo, PollStatisticsItemInfo } from '../../../models/Model.Poll';
import PollEdit from './PollEdit';
import PollSatisticsItem from './PollStatisticsItem';

const AdminAPI = {        
  "STATISTICS" : {"method":"GET", "path":"/admin/poll/", "contentType":"application/json"},
};

type Props = {
  // onBack: () => void,
  // // onPop: () => void,
  onNext: (component:any, depth: number, data:any) => void, 
  selectedPoll: PollInfo;
  // selectedPollItem: PollItemInfo;
  // depth: number;
  // selectedResponse: PollResponseInfo;
};

type State = {
  statstics: PollStatisticsInfo,

}

class PollSatistics extends Component<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      statstics: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps){
    log(LogLevel.UI_LIFECYCLE, "PollResponses:componentDidUpdate", prevProps.selectedPoll, this.props.selectedPoll);
    if(this.props.selectedPoll && this.props.selectedPoll.id && (!prevProps.selectedPoll || !prevProps.selectedPoll.id || prevProps.selectedPoll.id != this.props.selectedPoll.id)){
      this.setState({statstics: null});
      this.fetchData();
    }
  }

  fetchData = async () => {
    if(!this.props.selectedPoll || !this.props.selectedPoll.id)
      return;

    let result = await fetchAPI(AdminAPI.STATISTICS, this.props.selectedPoll.id + "/statistics", null, null, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error && result.data){
      this.setState({statstics: result.data});
    }
    else{
      this.setState({statstics: null});
    }
  }

  render() {
    log(LogLevel.UI_EVENT, "PollSatistics:render", this.state);
    if(!this.state.statstics)
      return (
        <div className="admin-container">
          <div className="common-container-column common-flex-align-center">
            <IonSpinner />
          </div>
        </div>
      );

    let items;
    if(this.state.statstics.items && this.state.statstics.items.length){
      items = (
        <div className="common-container">
          {this.state.statstics.items.map((item:PollStatisticsItemInfo, index) => {
            return (
              <PollSatisticsItem key={index.toString()} selectedPoll={this.props.selectedPoll} statistics={this.state.statstics} item={item} onNext={this.props.onNext}/>
            );
          })}
        </div>
      );
    }

    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button">
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="viewer-title" >설문 통계</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="admin-board-list-container">
          <div className="admin-title">설문 ID : {this.state.statstics.pollId}</div>
          <div className="admin-title">응답자 수 : {this.state.statstics.responseCount}</div>
          {items}
        </div>
      </div>
    );
  }
}

export default PollSatistics;