import React from "react";
import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";
import { Relative } from "../../atoms/Layout";

export interface BottomSheetProps {
  children?: React.ReactNode;
  active?: boolean;
  height?: string;
  maxHeight?: string;
  className?: string;
  style?: React.CSSProperties;
  onClose?: (params?: any) => any;
  onTouchStart?: (params?: any) => any;
  onTouchMove?: (params?: any) => any;
  onTouchEnd?: (params?: any) => any;
}

const BottomSheetModalWrapper = styled.div<BottomSheetProps>`
  padding: 40px 24px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${({ height }) => height};
  max-height: ${({ maxHeight }) => maxHeight};
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  transform: ${({ active }) => `translateY(${active ? "0" : "100%"})`};
  transition: transform 0.3s ease-in-out;
  border-radius: 20px 20px 0 0;
  z-index: 201;
`;

const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 200;
`;

const BottomSheet: React.FC<BottomSheetProps> = ({
  className,
  children,
  active,
  height,
  maxHeight,
  style,
  onClose,
}) => {
  // const tabBar = document.querySelector(".appstack-tabbar") as HTMLElement | null;

  // if (tabBar !== null) {
  //   if (active) {
  //     // tabBar.style.display = "none";
  //     console.log(active);
  //     tabBar.setAttribute("style", "display: none");
  //   } else {
  //     // tabBar.style.display = "flex";
  //     console.log(active);
  //     tabBar.setAttribute("style", "display: flex");
  //   }
  // }
  let startEvent = false;
  let moveEvent = false;

  return (
    <>
      {active && (
        <Shadow
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
      )}
      <BottomSheetModalWrapper
        active={active}
        height={height}
        maxHeight={maxHeight}
        className={className}
        style={style}
      >
        {children}
      </BottomSheetModalWrapper>
    </>
  );
};

export default BottomSheet;
