import Textarea from 'react-textarea-autosize';
import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import {
  IonList,
} from "@ionic/react";
import { bizFetchAPI, fetchAPI } from "../../../utils/APIUtil";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { log, LogLevel } from "../../../utils/LogUtil";
import { getGlobal, GlobalKey } from "../../../utils/GlobalUtil";
import { NotificationType } from "../../../models/Model.Notification";

const USER_ROW_CNT = 30;


const API = {
  MEMBER_LIST: {
    method: "GET",
    path: "/cc/admin",
    contentType: "application/json",
  },
  UPDATE_MEMBER: {
    method: "PUT",
    path: "/cc/admin",
    contentType: "application/json",
  },
  DELETE_MEMBER: {
    method: "DELETE",
    path: "/cc/admin",
    contentType: "application/json",
  },
  CREATE_MESSAGE: {
    method: "POST",
    path: "/admin/notification/custom/message",
    contentType: "application/json",
  }
}



const CreateInAppMessage = (props) => {
  const toastPopup = useSelector((state:any) => state.ui.popups[UIPopupType.TOAST_POPUP]);

  const [search, setSearch] = useState("");
  const [partners, setPartners] = useState([]);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  
  const { selectedService } = props;

  const userListRef = useRef(null);
  let loading = false;


  useEffect(() => {
    fetchData();
  }, [search]);


const onCreateMessage = (e) => {
  console.log("onCreateUser", e)
  fetchAPI(
    API.CREATE_MESSAGE,
    '',
    null,
    {
      title: e.title,
      body: e.body, 
      url: e.url, 
      data: JSON.stringify({
        type: e.type ? e.type : NotificationType.CUSTOM,
        action: "jump",
        url: e.url
      }),
      scheduledAt: e.scheduledAt,
    },
    getGlobal(GlobalKey.TOKEN)
  )
  .then((response) => {
    // Show a success toast
    setToastMessage(null);
    setToastMessage('메세지 생성완료 ');

    fetchData();
    props.onUserCreated();
  })
  .catch((error) => {
    console.error('Error:', error);
    // Show an error toast
    setToastMessage(null);
    setToastMessage('Error saving changes');
  });
}


  const fetchData = async () => {
    if (loading) return;
    loading = true;

    const result = await bizFetchAPI(
      API.MEMBER_LIST,
      '',
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );

    log(LogLevel.ALL, "fetchData", result.data)

    if (result) {
      setPartners(Array.isArray(result.data.data) ? result.data.data : []);
    } else {
      setPartners([]);
    }

    loading = false;
  };


  return (
    <div className="admin-container">
        {toastMessage && toastPopup.show(toastMessage)}
      <IonList ref={userListRef}>
        <div className="partners-list">
            <UserInsertForm  onSave={onCreateMessage} setToastMessage={setToastMessage} selectedService={selectedService} toastPopup={toastPopup}/>
        </div>
      </IonList>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CreateInAppMessage);



function UserInsertForm({ onSave, setToastMessage, selectedService, toastPopup }) {
    const [error, setError] = useState(false);
    const [editFields, setEditFields] = useState({
      title: "",
      body: "", 
      url: "", 
      data: "",
      scheduledAt: "",
      createdAt: "", 
      startedAt: "",
      endedAt: "",
      deletedAt: "",
    });

    const handleFieldChange = (field, value) => {
      if (field === 'data') {
        try {
          JSON.parse(value);
        } catch (error) {
          toastPopup.show('Invalid JSON format');
          return;
        }
      }

        setEditFields((prevFields) => ({
        ...prevFields,
        [field]: value,
        }));
    };

    const handleSaveClick = () => {
        onSave(editFields);
    };


  return (
    <CreateMessageContainer>
        <ButtonContainer>
            <Button onClick={handleSaveClick}> 메세지 생성 및 예약</Button>
        </ButtonContainer>
        <EditableField>
            <strong>제목:</strong>
            <Input
                value={editFields.title}
                onChange={(e) => handleFieldChange('title', e.target.value)}
            />
        </EditableField>
        <EditableField>
            <strong>내용:</strong>
            <Input
                value={editFields.body}
                onChange={(e) => handleFieldChange('body', e.target.value)}
            />
        </EditableField>
        <EditableField>
            <strong>주소:</strong>
            <Input
                value={editFields.url}
                onChange={(e) => handleFieldChange('url', e.target.value)}
            />
        </EditableField>
        <EditableField>
            <strong>예약날짜:</strong>
            <DateInput
              // className={editFields.scheduledAt ? "common-color-caution" : ""}
              placeholder="예약일시"
              value={editFields.scheduledAt}
              onChange={(e) => {
                handleFieldChange('scheduledAt', e.target.value)
              }}
            />
        </EditableField>
        <EditableField>
            <strong>Data:</strong>
            <Textarea className="admin-text-area" style={{ borderColor: error ? 'red' : 'initial' }} minRows={3} maxRows={15} value={editFields.data} onChange={(e) => { handleFieldChange('data', e.target.value)}} />
        </EditableField>
    </CreateMessageContainer>
  );
}



const CreateMessageContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  padding-top: 60px;
  position: relative;
  margin-bottom: 20px;
  width: 90%; // Adjust this to your preferred width
  max-width: 1200px; // Adjust this to your preferred maximum width
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px
`;

const EditableField = styled.div`
  margin: 5px 0;
  &:hover {
    background-color: #f0f0f0;
  }
`;



const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  border: none;
  background: none;
  padding: 0; // Remove the default padding
  padding-left: 20px;
  outline: none;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.3s ease;
  background-color: ${props => props.disabled ? 'grey' : 'green'};
  color: white;

  &:hover {
    background-color: #0056b3; // Change this to your preferred hover color
  }

  &:focus {
    outline: none;
  }
`;

const DateInput = styled.input.attrs({ type: 'date' })`
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: box-shadow 0.3s ease-in-out;

  &:focus {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  &.common-color-caution {
    border: 1px solid red;
  }
`;
