import * as notifications from "./actions";
import { NotificationState } from "./types";
import { ActionType, getType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import { getGlobal, GlobalKey, setGlobal } from "./../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";

export const fetchNotificationMiddleware: Middleware<{}, NotificationState> = (
  store
) => (next) => async (action: ActionType<typeof notifications>) => {
  next(action);

  let states = store.getState();
  let state: NotificationState = states["notification"];

  if (action.type == getType(notifications.refresh)) {
    log(LogLevel.REDUX_NOTIFICATION, "notifications.load ");
    next(notifications.fetchNotificationLoad.request());
    try {
      let options: any = {
        start: 0,
        count: 100,
      };
      let result = await fetchAPI(
        API.NOTIFICATION_LOAD,
        "",
        options,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_NOTIFICATION, result);
      if (result && !result.error) {
        next(notifications.fetchNotificationLoad.success(result.data));
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        localStorage.removeItem("TOKEN");
        next(notifications.fetchNotificationLoad.failure(error));
        // log(LogLevel.REDUX_NOTIFICATION, "invalid token");
      }
    } catch (e) {
      next(notifications.fetchNotificationCheckItem.failure(e));
    }
  } else if (action.type == getType(notifications.load) && !state.end) {
    log(LogLevel.REDUX_NOTIFICATION, "notifications.load ");
    next(notifications.fetchNotificationLoad.request());
    try {
      let options: any = {
        start: state.notifications.length,
        count: 100,
      };
      let result = await fetchAPI(
        API.NOTIFICATION_LOAD,
        "",
        options,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_NOTIFICATION, result);
      if (result && !result.error) {
        next(notifications.fetchNotificationLoad.success(result.data));
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        localStorage.removeItem("TOKEN");
        next(notifications.fetchNotificationLoad.failure(error));
        // log(LogLevel.REDUX_NOTIFICATION, "invalid token");
      }
    } catch (e) {
      next(notifications.fetchNotificationCheckItem.failure(e));
    }
  } else if (action.type == getType(notifications.checkItem)) {
    log(LogLevel.REDUX_NOTIFICATION, "notifications.checkItem ");
    next(notifications.fetchNotificationCheckItem.request());
    try {
      let result = await fetchAPI(
        API.NOTIFICATION_CHECK,
        action.payload,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_NOTIFICATION, result);
      if (result && !result.error) {
        next(notifications.fetchNotificationCheckItem.success(action.payload));
      } else {
        const error: Error = {
          name: "invalide token",
          message: "",
        };
        localStorage.removeItem("TOKEN");
        next(notifications.fetchNotificationCheckItem.failure(error));
        // log(LogLevel.REDUX_NOTIFICATION, "invalid token");
      }
    } catch (e) {
      next(notifications.fetchNotificationCheckItem.failure(e));
    }
  } else if (action.type == getType(notifications.chatUnreadCountLoad)) {
    log(LogLevel.REDUX_NOTIFICATION, "notifications.chatUnreadCountLoad ");
    next(notifications.fetchNotificationCheckItem.request());
    try {
      let result = await fetchAPI(
        API.JOBPOST_CHAT_CHECK,
        "",
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      // log(LogLevel.REDUX_NOTIFICATION, result);
      if (result && !result.error && result.hasUnread) {
        next(notifications.chatUnreadCountSet(result.hasUnread));
      } else {
        next(notifications.chatUnreadCountSet(0));
        // log(LogLevel.REDUX_NOTIFICATION, "invalid token");
      }
    } catch (e) {
      next(notifications.fetchNotificationCheckItem.failure(e));
    }
  }
};
