import React from "react";
import { connect } from "react-redux";
import { RootState } from "../store";
import {
  IonIcon,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
  IonMenuToggle,
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import "./Admin.scss";

const routes = {
  appPages: [
    { title: "홈으로", path: "/", icon: "home" },
    { title: "이벤트팝업관리", path: "/admin/event", icon: "pizza" },
    { title: "사용자관리", path: "/admin/user", icon: "contacts" },
    { title: "게시판관리", path: "/admin/boards", icon: "folder" },
    { title: "게시물관리", path: "/admin/board", icon: "clipboard" },
    { title: "게시물이동관리", path: "/admin/board/move", icon: "clipboard" },
    { title: "세미나관리", path: "/admin/seminar", icon: "videocam" },
    { title: "배너관리", path: "/admin/banner", icon: "copy" },
    { title: "검색키워드관리", path: "/admin/search", icon: "search" },
    { title: "프리미엄회원관리", path: "/admin/premium", icon: "cash" },
    { title: "구인구직관리", path: "/admin/jobpost", icon: "briefcase" },
    ,
    { title: "법인구인구직관리", path: "/admin/bizjobpost", icon: "briefcase" },
    {
      title: "(POC)DailyPharmBiz",
      path: "/admin/dailypharm-biz",
      icon: "flask",
    },
    {
      title: "구인구직쿠폰관리",
      path: "/admin/biz/coupon",
      icon: "briefcase",
    },
    { title: "법인회원관리", path: "/admin/allinone", icon: "contacts" },
    {
      title: "법인회원구매내역관리",
      path: "/admin/allinone/purchase",
      icon: "cart",
    },
    { title: "다제약물매니저관리", path: "/admin/cc", icon: "contacts" },
    {
      title: "GPT요약관리",
      path: "/admin/board/summary",
      icon: "chatbubbles",
    },
    {
      title: "GPT퀴즈관리",
      path: "/admin/board/quiz",
      icon: "chatbubbles",
    },
    { title: "설문관리", path: "/admin/poll", icon: "clipboard" },
    { title: "홀리트리작성창", path: "/admin/board/holytreenew", icon: "add" },
    { title: "다이제스트", path: "/admin/digest", icon: "paper" },
    { title: "(통계)OKR", path: "/admin/statistics/key", icon: "stats" },
    {
      title: "(통계)개인별활동통계",
      path: "/admin/statistics/user",
      icon: "stats",
    },
    {
      title: "(통계)게시판통계",
      path: "/admin/statistics/boards",
      icon: "stats",
    },
    { title: "(통계)구인구직통계", path: "/admin/sale/jobpost", icon: "cart" },
    {
      title: "(판매)구인구직구매관리",
      path: "/admin/sale/jobpost",
      icon: "cart",
    },
    { title: "(판매)제품관리", path: "/admin/sale/product", icon: "cart" },
    { title: "(판매)주문관리", path: "/admin/sale/order", icon: "cart" },
    { title: "초대이벤트현황", path: "/admin/invite", icon: "cart" },
    { title: "인앱알림", path: "/admin/inapp", icon: "chatbubbles" },
    { title: "DEV포워딩", path: "/admin/redirect", icon: "jet" },
    { title: "(POC)DailyPharm", path: "/admin/dailypharm", icon: "flask" },

    { title: "(POC)채팅", path: "/components", icon: "chat" },
    { title: "(POC)유사컨텐츠", path: "/admin/suggest", icon: "flask" },
    { title: "앱알림", path: "/admin/notification", icon: "_notifications" },
    // { title: '초대이벤트현황', path: '/admin/event/invite', icon: '_gift' },
    // { title: '(약)성분관리', path: '/admin/medicine/ingredient', icon: 'egg' },
    // { title: '(약)의약품관리', path: '/admin/medicine/medicine', icon: 'egg' },
    // { title: '(약)부작용빈도/용어', path: '/admin/medicine/sideeffect', icon: 'egg' },
    // { title: '(약)ATC링크관리', path: '/admin/medicine/atc', icon: 'egg' },
    // { title: '(임시)사용자근무지위치', path: '/admin/userposition', icon: 'qustion' },
    // { title: '(판매)임시주문관리', path: '/admin/temp/order', icon: '_cart' },
    // { title: 'Dashboard', path: '/admin/dashboard', icon: 'apps' },
    // { title: '새게시물작성', path: '/admin/board/new', icon: 'create' },
    // { title: '다이제스트', path: '/admin/digest', icon: 'paper' },
    // { title: 'New작성창', path: '/admin/newcomposer', icon: 'brush' },
  ],
};

type Props = RouteComponentProps<{}>;

const Menu: React.SFC<Props> = ({ history }) => {
  function renderlistItems(list: any[]) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem button onClick={() => history.push(p.path)}>
            <IonIcon slot="start" name={p.icon}></IonIcon>
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu contentId="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="outer-content">
        <IonList>
          <IonListHeader>Navigate</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

const mapStateToProps = (state: RootState) => ({});

export default withRouter(Menu);
