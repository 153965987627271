import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonToggle } from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { GlobalKey, getGlobal } from "../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import * as API from "../../../API.json";
import { UIPopupType } from "../../../store/ui/types";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import { UserInfo } from "../../../models/Model.User";
import {
  OrderInfo,
  OrderStatus,
  OrderStatusName,
  ProductInfo,
} from "../../../models/Model.Sale";
import OrderListItem from "./OrderListItem";

const ADMIN_API = {
  ORDERS: {
    method: "get",
    path: "/admin/sale/order/list",
    contentType: "application/json",
  },
  UPDATE_ORDERS: {
    method: "put",
    path: "/admin/sale/order/list",
    contentType: "application/json",
  },
  DELETE_ORDERS: {
    method: "put",
    path: "/admin/sale/order/delete",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect?: (order: OrderInfo) => void;
    selectedUser?: UserInfo;
    selectedProduct?: ProductInfo;
  };

type State = {
  items: OrderInfo[];
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  finished: boolean;
  loading: boolean;
  orderBy: string;
  orderAsc: boolean;
  edit: boolean;
  searchStatus: OrderStatus;
  searchKeyword: string;
  checkedList: number[];
  includeDeleted: boolean;
};

class OrderList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      finished: false,
      loading: false,
      dateStart: getDateStringFromToday({ weeks: -1 }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      orderBy: "",
      orderAsc: false,
      edit: false,
      searchStatus: OrderStatus.NONE,
      searchKeyword: "",
      checkedList: [],
      includeDeleted: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  refresh = (order?: OrderInfo) => {
    log(LogLevel.UI_EVENT, "OrderList:refresh", order);

    if (order) {
      let items = [...this.state.items];
      let index = items.findIndex((item) => item.id == order.id);
      if (index >= 0) {
        items[index] = { ...items[index], ...order };
        this.setState({ items });
      } else {
        items.push(order);
        this.setState({ items });
      }
    } else {
      this.fetchData(2);
    }
  };

  fetchData = (refresh = 0) => {
    // 0: load more, 1: clear and load, 2: refresh all
    if (this.state.dateStartWarn || this.state.dateEndWarn) return;

    if (!refresh && this.state.finished) return;

    this.setState({ loading: true, items: refresh ? [] : this.state.items });

    let params: any = {
      start: refresh ? 0 : this.state.items.length,
      count: refresh == 2 ? this.state.items.length : 20,
      startDate: this.state.dateStart,
      endDate: this.state.dateEnd,
    };

    // if (this.props.selectedUser) params.userId = this.props.selectedUser.id;

    // if (this.props.selectedProduct)
    //   params.productId = this.props.selectedProduct.id;

    if (this.state.includeDeleted) params.includeDeleted = 1;

    // if (this.state.status) params.status = this.state.status;

    fetchAPI(
      ADMIN_API.ORDERS,
      "",
      params,
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      log(
        LogLevel.UI_EVENT,
        "OrderList:fetchCompanyData Offer",
        result,
        this.state.items,
        refresh
      );
      if (result && !result.error) {
        this.setState({
          items: refresh ? result.data : [...this.state.items, ...result.data],
          finished: result.data.length < 20,
          loading: false,
          orderBy: "",
        });
      } else {
        this.setState({
          items: refresh ? [] : this.state.items,
          finished: true,
          loading: false,
        });
      }
    });
  };

  onSetOrderJobOffer(field: string) {
    let asc: boolean = this.state.orderAsc;

    if (this.state.orderBy == field) {
      asc = !asc;
    }
    let items = [...this.state.items];
    items = this.orderOrders(items, field, asc);
    this.setState({ items, orderBy: field, orderAsc: asc });
  }

  orderOrders(data: any[], field: string, asc: boolean) {
    data = data.sort((a: OrderInfo, b: OrderInfo) => {
      let tokens = field.split(".");
      let aCmp = a,
        bCmp = b;
      for (let i = 0; i < tokens.length; i++) {
        aCmp = aCmp[tokens[i]];
        bCmp = bCmp[tokens[i]];
      }
      let rvalue = 0;
      if (aCmp > bCmp) rvalue = -1;
      else if (aCmp < bCmp) rvalue = 1;

      if (!asc) rvalue *= -1;

      return rvalue;
    });
    return data;
  }

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:OrderList:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:OrderList:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:OrderList:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  onCopy = () => {
    let windowAny: any = window;
    let $ = windowAny.$;
    // window.open('data:application/vnd.ms-excel,' + $('#table').html());
    var body = document.getElementById("admin-table-body");
    var range = document.createRange();
    range.selectNode(body);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    this.props.toastPopup.show("클립보드에 저장되었습니다.(안되었을수도 있음)");
  };

  onPrepareDeliverBulk = () => {
    this.props.confirmPopup.show({
      title:
        this.state.checkedList.length +
        "개의 주문을 배송준비중으로 변경하시겠습니까?",
      backDropDismiss: true,
      onDone: () => {
        log(
          LogLevel.UI_EVENT,
          "OrderList:onPrepareDeliverBulk Offer",
          this.state.checkedList
        );
        this.props.waitingPopup.show();
        let order: OrderInfo = {
          status: OrderStatus.PREPARING_DELIVER,
        };
        fetchAPI(
          ADMIN_API.UPDATE_ORDERS,
          "",
          null,
          { ids: this.state.checkedList, order },
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          this.props.waitingPopup.hide();
          if (result && !result.error) {
            this.setState({ checkedList: [] });
          } else {
            this.props.toastPopup.show("삭제실패");
          }
          this.fetchData(2);
        });
      },
    });
  };

  onDeleteBulk = () => {
    this.props.confirmPopup.show({
      title:
        this.state.checkedList.length + "개의 주문을 삭제처리 하시겠습니까?",
      backDropDismiss: true,
      onDone: () => {
        log(
          LogLevel.UI_EVENT,
          "OrderList:onDeleteBulk Offer",
          this.state.checkedList
        );
        this.props.waitingPopup.show();
        fetchAPI(
          ADMIN_API.DELETE_ORDERS,
          "",
          null,
          { ids: this.state.checkedList },
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          this.props.waitingPopup.hide();

          if (result && !result.error) {
            this.setState({ checkedList: [] });
          } else {
            this.props.toastPopup.show("삭제실패");
          }
          this.fetchData(1);
        });
      },
    });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "OrderList.render", this.props, this.state);

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={() => this.fetchData()}>
          더 불러오기
        </IonButton>
      );
    }

    return (
      <div className="admin-full-container">
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">게시기간</div>
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateStart}
            onChange={(e) => this.onDateChange(e, "dateStart")}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateEnd}
            onChange={(e) => this.onDateChange(e, "dateEnd")}
          />
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -1 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            1주
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -4 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            4주
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ byMonth: true }),
                dateEnd: getDateStringFromToday({ byMonth: true, months: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            이번달
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({
                  byMonth: true,
                  months: -1,
                }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            지난달부터
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({
                  byMonth: true,
                  months: -1,
                }),
                dateEnd: getDateStringFromToday({ byMonth: true }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            지난달
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -6 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            6개월간
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.NONE
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ searchStatus: OrderStatus.NONE })}
          >
            전체
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.ORDERED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ searchStatus: OrderStatus.ORDERED })}
          >
            {OrderStatusName[OrderStatus.ORDERED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.PAID
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ searchStatus: OrderStatus.PAID })}
          >
            {OrderStatusName[OrderStatus.PAID]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.PREPARING_PRODUCT
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ searchStatus: OrderStatus.PREPARING_PRODUCT })
            }
          >
            {OrderStatusName[OrderStatus.PREPARING_PRODUCT]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.PREPARING_DELIVER
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ searchStatus: OrderStatus.PREPARING_DELIVER })
            }
          >
            {OrderStatusName[OrderStatus.PREPARING_DELIVER]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.DELIVERING
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ searchStatus: OrderStatus.DELIVERING })
            }
          >
            {OrderStatusName[OrderStatus.DELIVERING]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.DELIVERED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ searchStatus: OrderStatus.DELIVERED })
            }
          >
            {OrderStatusName[OrderStatus.DELIVERED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.CANCELED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ searchStatus: OrderStatus.CANCELED })
            }
          >
            {OrderStatusName[OrderStatus.CANCELED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.ORDER_FAILED
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ searchStatus: OrderStatus.ORDER_FAILED })
            }
          >
            {OrderStatusName[OrderStatus.ORDER_FAILED]}
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.searchStatus == OrderStatus.RETURNING
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() =>
              this.setState({ searchStatus: OrderStatus.RETURNING })
            }
          >
            {OrderStatusName[OrderStatus.RETURNING]}
          </div>
          {/* <div
            className={
              "admin-toggle" +
              (this.state.includeDeleted ? " admin-toggle-selected" : "")
            }
            onClick={() =>
              this.setState({ includeDeleted: !this.state.includeDeleted })
            }
          >
            삭제포함
          </div> */}
        </div>
        <div
          className="common-container-row admin-margin-bottom common-flex-align-center"
          style={{ gap: "10px" }}
        >
          <IonButton onClick={() => this.fetchData(1)}>불러오기</IonButton>
          <IonButton onClick={this.onCopy}>복사</IonButton>
          검색어
          <input
            placeholder="검색어"
            value={this.state.searchKeyword}
            onChange={(e) => this.setState({ searchKeyword: e.target.value })}
          />
          수정
          <IonToggle
            checked={this.state.edit}
            color="primary"
            onClick={() => {
              this.setState({ edit: !this.state.edit });
            }}
          />
          {!!this.state.checkedList.length && (
            <div className="common-flex-row common-flex-align-center">
              <IonButton onClick={this.onPrepareDeliverBulk}>
                제품준비중(선택)
              </IonButton>
              <IonButton onClick={this.onDeleteBulk}>삭제(선택)</IonButton>
            </div>
          )}
        </div>
        <div className="common-container">
          {this.renderTable()}

          {more}
        </div>
        <div style={{ height: "100px" }} />
      </div>
    );
  }

  renderTable = () => {
    return (
      <table id="admin-table" className="admin-table">
        <thead>
          {this.state.edit ? (
            <tr>
              <td className="admin-table-label-x">⎕</td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("id")}
              >
                주문ID
                {this.state.orderBy == "id"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("status")}
              >
                상태
                {this.state.orderBy == "status"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("productId")}
              >
                PID
                {this.state.orderBy == "orderedAt"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td className="admin-table-label-x">주문량</td>
              <td className="admin-table-label-x">제품가격</td>
              <td className="admin-table-label-x">배송비</td>
              <td className="admin-table-label-x">총액</td>
              <td className="admin-table-label-x">결제금액</td>
              <td className="admin-table-label-x">반품액</td>
              <td className="admin-table-label-x">배송인</td>
              <td className="admin-table-label-x">배송전번</td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("lastJobpostAt")}
              >
                주문일
                {this.state.orderBy == "lastJobpostAt"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td className="admin-table-label-x">배송사</td>
              <td className="admin-table-label-x">송장번호</td>
            </tr>
          ) : (
            <tr>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("id")}
              >
                주문ID
                {this.state.orderBy == "id"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td className="admin-table-label-x">사용자ID</td>
              <td className="admin-table-label-x">사용자명</td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("status")}
              >
                상태
                {this.state.orderBy == "status"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("productId")}
              >
                PID
                {this.state.orderBy == "productId"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td className="admin-table-label-x">주문량</td>
              <td className="admin-table-label-x">제품가격</td>
              <td className="admin-table-label-x">배송비</td>
              <td className="admin-table-label-x">총액</td>
              <td className="admin-table-label-x">결제금액</td>
              <td className="admin-table-label-x">반품액</td>
              <td className="admin-table-label-x">사업자번호</td>
              <td className="admin-table-label-x">배송인</td>
              <td className="admin-table-label-x">배송전번</td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("orderedAt")}
              >
                주문일
                {this.state.orderBy == "orderedAt"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td
                className="admin-table-label-x"
                onClick={() => this.onSetOrderJobOffer("deliveredAt")}
              >
                배송일
                {this.state.orderBy == "deliveredAt"
                  ? this.state.orderAsc
                    ? " ▼"
                    : " ▲"
                  : " -"}
              </td>
              <td className="admin-table-label-x">배송우편</td>
              <td className="admin-table-label-x">배송주소</td>
            </tr>
          )}
        </thead>
        <tbody id="admin-table-body">
          {this.state.items.map((order: OrderInfo, index) => {
            return (
              <OrderListItem
                keys={index.toString()}
                index={index}
                order={order}
                checked={this.state.checkedList.includes(order.id)}
                editMode={this.state.edit}
                onSelect={this.props.onSelect}
                onCheck={this.onCheck}
                onRefund={this.onRefund}
                onDeliveryUpdate={this.onDeliveryUpdate}
                searchKeyword={this.state.searchKeyword}
                searchStatus={this.state.searchStatus}
              />
            );
          })}
        </tbody>
      </table>
    );
  };

  onCheck = (order: OrderInfo) => {
    let checkedList = [...this.state.checkedList];
    let index = checkedList.indexOf(order.id);

    if (index >= 0) {
      checkedList.splice(index, 1);
    } else {
      checkedList.push(order.id);
    }

    this.setState({ checkedList });
  };

  onRefund = (order: OrderInfo, index: number, refundAmount: number) => {
    log(
      LogLevel.UI_EVENT,
      "OrderList:onRefund",
      this.state.checkedList,
      refundAmount
    );
    if (!refundAmount || !order || order.status != OrderStatus.RETURNING)
      return;

    this.props.confirmPopup.show({
      title: `주문[${order.id}]에 ${refundAmount}원을 환불 하시겠습니까?`,
      backDropDismiss: true,
      onDone: () => {
        log(
          LogLevel.UI_EVENT,
          "OrderList:onRefund start",
          this.state.checkedList
        );
        this.props.waitingPopup.show();
        let newOrder: OrderInfo = {
          id: order.id,
          status: OrderStatus.RETURN,
          payRefund: refundAmount,
        };
        fetchAPI(
          API.SALE_ORDER_CHANGE,
          "",
          null,
          newOrder,
          getGlobal(GlobalKey.TOKEN)
        ).then((result) => {
          this.props.waitingPopup.hide();

          if (result && !result.error) {
            this.props.toastPopup.show("환불 완료");
          } else {
            this.props.toastPopup.show("환불 실패");
          }
          this.fetchData(2);
        });
      },
    });
  };

  onDeliveryUpdate = (
    order: OrderInfo,
    index: number,
    deliveryCompany: string,
    deliveryId: string
  ) => {
    if (!deliveryCompany || !order || !deliveryId) return;

    this.props.waitingPopup.show();
    let newOrder: OrderInfo = {
      id: order.id,
      status: OrderStatus.DELIVERING,
      deliveryCompany,
      deliveryId,
    };
    fetchAPI(
      API.SALE_ORDER_CHANGE,
      "",
      null,
      newOrder,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      this.props.waitingPopup.hide();

      if (result && !result.error) {
        this.props.toastPopup.show("배송처리 완료");
      } else {
        this.props.toastPopup.show("배송처리 실패");
      }
      this.fetchData(2);
    });
  };
}

const mapStateToProps = (state: RootState) => ({
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(OrderList);
