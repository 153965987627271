import * as gamifications from './actions';
import { GamificationState, GamificationActionTypes} from './types';
import { ActionType, getType } from 'typesafe-actions';
import { Middleware } from 'redux';
import { fetchAPI } from '../../utils/APIUtil';
import * as API from './../../API.json';
import {getGlobal, GlobalKey, setGlobal, clearGlobal} from './../../utils/GlobalUtil'
import { EventInfo } from '../../models/Model.Event';
import { ErrorCode } from '../../models/ErrorCode';
import {log, LogLevel} from '../../utils/LogUtil'
import { UserState } from '../user/types';
import ABTestUtil, { ABTestFeature } from '../../utils/ABTestUtil';
import { truncate } from 'fs';
import { getDateStringFromToday } from '../../utils/TimeUtil';
import InterestSelectToggleButton from '../../components/InterestSelectToggleButton';
import { GamificationPeriodicReport, GamificationPeriodicReportType } from '../../models/Model.Gamification';

export const fetchGamificationMiddleware: Middleware<{}, GamificationState> = (store) => next => async (action: ActionType<typeof gamifications>) => {
  next(action);

  let states = store.getState();
  let state:GamificationState = states["gamification"];

  if (ABTestUtil.isTest(ABTestFeature.UI_GAMIFICATION_WEEKLY_REPORT) && action.type == getType(gamifications.getReport) && !state.report) {
    let today = new Date(Date.now() + 9 * 60 * 60 * 1000);
    let todayNum = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));

    let lastReportDayNum = getGlobal(GlobalKey.GAMIFICATION_LAST_REPORT, true);

    let startDate = "", endDate = "";
    let dayOfToday = ((new Date()).getDay()-1)%7;
    let reportType:GamificationPeriodicReportType = 0;
    let needReport:boolean = true;

    if(lastReportDayNum == todayNum )
      needReport = false;
    else {
      switch(dayOfToday){
        case 0:
        case 1:
        case 2:
        case 3:
          if(todayNum - dayOfToday <= lastReportDayNum){
            needReport = false;
          }
          else {
            reportType = GamificationPeriodicReportType.ANSWER_REPORT;
            startDate = getDateStringFromToday({byWeek:true, weeks:-1, weekStarts:1});
            endDate = getDateStringFromToday({byWeek:true, weekStarts:1});
          }
          break;
        case 4:
        case 5:
        case 6:
          if(todayNum - dayOfToday + 4 <= lastReportDayNum){
            needReport = false;
          }
          else {
            reportType = GamificationPeriodicReportType.HELP;
            startDate = getDateStringFromToday({byWeek:true, days:-1, weekStarts:1});
            endDate = getDateStringFromToday();
          }
          break;
      }
    }

    log(LogLevel.REDUX_EVENT, "fetchGamificationMiddleware : gamifications.getReport ", action, today, todayNum, lastReportDayNum, needReport, startDate, endDate)
    // if(ABTestUtil.isTest()){
    //   needReport = true;
    //   reportType = (Date.now()%2) +1;
    //   startDate = "2019-07-20";
    //   endDate = getDateStringFromToday();      
    // }

    if(needReport && reportType){
      let request : GamificationPeriodicReport = {
        type : reportType,
        startDate,
        endDate,
      }

      next(gamifications.fetchReport.request());
      try {
        fetchAPI(API.GAMIFICATION_GET_PERIODIC_REPORT, '', request, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
          if(result && !result.error && result.data){
            let report:GamificationPeriodicReport = result.data;
            log(LogLevel.REDUX_BOARD, "fetchGamificationMiddleware : gamifications.getReport success", report)
            next(gamifications.fetchReport.success(report));
          }
          else{
            log(LogLevel.REDUX_BOARD, "fetchGamificationMiddleware : gamifications.getReport failed", result);
            let error : Error = {
              name : "error code " + result.error,
              message: "error code " + result.error,
            };
            setGlobal(GlobalKey.GAMIFICATION_LAST_REPORT, todayNum, true);
            next(gamifications.fetchReport.failure(error));
          }
        }).catch((e) => {
          log(LogLevel.REDUX_BOARD, "fetchGamificationMiddleware : gamifications.getReport failed", e);
          next(gamifications.fetchReport.failure(e));
        })
      } catch (e) {
        log(LogLevel.REDUX_BOARD, "fetchGamificationMiddleware : gamifications.getReport failed", e);
        next(gamifications.fetchReport.failure(e));
      }

    }

  }
  else if (ABTestUtil.isTest(ABTestFeature.UI_GAMIFICATION_WEEKLY_REPORT) && action.type == getType(gamifications.clearReport)) {
    let todayNum = Math.floor((Date.now() + 9 * 60 * 60 * 1000)/(24*60*60*1000));
    setGlobal(GlobalKey.GAMIFICATION_LAST_REPORT, todayNum, true);
    log(LogLevel.REDUX_BOARD, "fetchGamificationMiddleware : gamifications.clearReport", todayNum);
  }

};
