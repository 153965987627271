import React, { Component } from "react";
import "./RegisterStage2.css";
import "./RegisterStage.css";
import { IonButton, IonInput, IonIcon, IonToast, IonContent } from "@ionic/react";
import DynamicChecker, { CheckStatus } from "./../DynamicChecker";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { UserInfo, UserWorkType } from "../../models/Model.User";
import { getGlobal, GlobalKey } from "./../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";
import { loadImageBase64 } from "../../utils/ImageUtil";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { UIPopupType } from "../../store/ui/types";
import DynamicCheckerInput from "./../DynamicCheckerInput";
import sampleLicenseImage from "./../../assets/image/sample_license.jpg";
import kakaoIcon from "./../../assets/icon/question_small_yellow_mobile_3X.png";
import AnalyticsUtil from "./../../utils/AnalyticsUtil";
import StringUtil from "../../utils/StringUtil";

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    private: boolean;
    user: UserInfo;
    onDone: (data: any) => void;
    onBack: (data: any) => void;
  };

type State = {
  licenseImageBase64: any;
  licenseUrl: string;
};
const windowAny: any = window;

class RegisterStage2a extends Component<Props, State> {
  contentScroll = null;

  constructor(props) {
    super(props);
    this.state = {
      licenseUrl: this.props.user.licenseUrl,
      licenseImageBase64: this.props.user.licenseImageBase64,
    };
    this.canPass = this.canPass.bind(this);
  }

  canPass() {
    return this.state.licenseUrl || this.state.licenseImageBase64;
  }

  onDone = () => {
    if (!this.state.licenseImageBase64 && !this.props.user.licenseUrl) {
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_4_FAIL", "회원가입_4_개인정보입력실패", {
        이유: "면허사진이상",
      });
      this.props.toast.show("면허사진을 추가하셔야합니다.");
      return;
    }

    let rvalue = {
      licenseImageBase64: this.state.licenseImageBase64,
    };
    this.props.onDone(rvalue);
  };

  onBack = () => {
    let rvalue = {
      licenseImageBase64: this.state.licenseImageBase64,
    };
    this.props.onBack(rvalue);
  };

  onClickCamera = () => {
    log(LogLevel.UI_ACTION, "RegisterStage2a:onClickCamera");
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") return;

    if (windowAny.navigator && windowAny.navigator.camera && windowAny.navigator.camera.getPicture) {
      windowAny.navigator.camera.getPicture(this.onCameraSuccess, this.onCameraFail, {
        quality: 80,
        destinationType: windowAny.Camera.DestinationType.DATA_URL,
        encodingType: windowAny.Camera.EncodingType.JPEG,
        mediaType: windowAny.Camera.MediaType.PICTURE,
        correctOrientation: true,
        targetWidth: 1920,
        targetHeight: 1920,
      });
    }
  };

  onCameraSuccess = (imageURI) => {
    this.setState({ licenseImageBase64: "data:image/jpeg;base64," + imageURI });
    setTimeout(() => {
      // this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
      this.contentScroll.scrollTo(0, this.contentScroll.clientHeight);
    }, 300);
  };

  onCameraFail = (message) => {
    log(LogLevel.UI_EXCEPTION, "RegisterStage2a::onCameraFail Failed because: ", message);
  };

  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") {
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
      try {
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: "_Vxjhxgj", // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    } else {
      let plusFriendTemplate = {
        plusFriendId: "_Vxjhxgj",
      };
      try {
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  };

  onImageSelected = (e) => {
    // console.dir(e);
    console.dir(e.target.files[0]);
    console.dir(e.target);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0]).then((base64) => {
        this.onImageLoad(base64);
      });
    }
  };

  onImageLoad = (base64) => {
    this.setState({ licenseImageBase64: base64 });
    setTimeout(() => {
      // this.contentScroll.scrollToPoint(null, this.contentScroll.clientHeight);
      this.contentScroll.scrollTo(0, this.contentScroll.clientHeight);
    }, 300);
    log(LogLevel.UI_EVENT, "RegisterStage2a:onImageLoad", this.contentScroll, this.contentScroll.clientHeight);
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "RegisterStage2a:render", this.props.user);
    let attachmenet;
    let os = getGlobal(GlobalKey.OS);

    if (os && os != "browser") {
      attachmenet = (
        <div className="register2-upload-button-container">
          <IonButton class="register2-upload-button" fill="clear" onClick={this.onClickCamera}>
            <IonIcon class="register2-upload-button-icon" name="camera" />
            <div className="register2-upload-button-text">촬영하기</div>
          </IonButton>
        </div>
      );
    }

    let attachmentViewer;
    if (this.state.licenseImageBase64) {
      attachmentViewer = (
        <div className="register2-upload-viewer-container">
          <img className="register2-upload-viewer" src={this.state.licenseImageBase64} />
        </div>
      );
    } else if (this.props.user.licenseUrl) {
      attachmentViewer = (
        <div className="register2-upload-viewer-container">
          <img className="register2-upload-viewer" src={StringUtil.convertFilePath(this.props.user.licenseUrl)} />
        </div>
      );
    }

    let workPlace: string = "";
    if (this.props.user.workPlaceRegion) workPlace = this.props.user.workPlaceRegion + " ";
    if (this.props.user.workPlaceName) workPlace += this.props.user.workPlaceName;

    let sampleLicense;
    if (!this.state.licenseUrl && !this.state.licenseImageBase64)
      sampleLicense = <img className="register2-sample-license" src={sampleLicenseImage} />;

    return (
      <div
        className="register2-container"
        ref={(ref) => {
          this.contentScroll = ref;
        }}
      >
        <div className="register-progress-container">
          <div className="register-progress-done">1</div>
          <div className="register-progress-done">2</div>
          <div className="register-progress-done">3</div>
          <div className="register-progress-done">4</div>
          <div className="register-progress-undone">5</div>
        </div>
        <div className="register2-service-description">
          약사인증을 위해 실명과 면허번호를 면허증 사진과 대조작업을 합니다.
        </div>
        <div className="register-chat-guide">
          <img className="register-chat-kakao" src={kakaoIcon} onClick={this.onChat} />
        </div>
        <div className="register2-body-container">
          <div className="register-title">약사 면허 인증</div>
          <div className="register2-item-container">
            <div
              className={
                "register2-item-name common-bold" +
                (!this.state.licenseUrl && !this.state.licenseImageBase64 ? " common-color-point" : "")
              }
            >
              면허증 사진 (필수)
            </div>
          </div>
          <div className="register2-title-description">
            제출된 면허 이미지는 <span className="register2-title-description-highlight">가입승인 즉시 폐기</span>
            됩니다.
          </div>
          {attachmentViewer}
          <div className="register2-upload-container">
            {attachmenet}
            <div className="register2-upload-button-container">
              <IonButton class="register2-upload-button" fill="clear">
                <IonIcon class="register2-upload-button-icon" name="image" />
                <div className="register2-upload-button-text">첨부하기</div>
              </IonButton>
              <input className="register2-upload-file" type="file" accept="image/*" onChange={this.onImageSelected} />
            </div>
          </div>
          <div className="register2-license-guide">
            ※ 성명과 면허번호가 보이는 면허증 혹은 면허자격 증명서를 첨부해주세요.
          </div>
          {sampleLicense}
        </div>

        <div className="register-navigate-container">
          <IonButton color="register-navigate-inactive" onClick={this.onBack}>
            &lt; 이전 단계로
          </IonButton>
          <div className="register-navigate-space" />
          <IonButton
            color={this.canPass() ? "register-navigate-active" : "register-navigate-inactive"}
            onClick={this.onDone}
          >
            다음 단계로 &gt;
          </IonButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath: state.board.filePath,
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStage2a);
