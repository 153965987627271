import * as jobposts from "./actions";
import {
  JobPostState,
  JobPostActionTypes,
  JobListType,
  JobListState,
} from "./types";
import { ActionType, getType } from "typesafe-actions";
import { Middleware } from "redux";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import {
  getGlobal,
  GlobalKey,
  setGlobal,
  clearGlobal,
} from "./../../utils/GlobalUtil";
import { EventInfo } from "../../models/Model.Event";
import { ErrorCode } from "../../models/ErrorCode";
import { log, LogLevel } from "../../utils/LogUtil";
import { UserState, AuthStatus } from "../user/types";
import ABTestUtil, { ABTestFeature } from "../../utils/ABTestUtil";
import { truncate } from "fs";
import { getDateStringFromToday } from "../../utils/TimeUtil";
import InterestSelectToggleButton from "../../components/InterestSelectToggleButton";
import { UserInfo, UserWorkType } from "../../models/Model.User";
import {
  JobApplyStatus,
  JobOfferStatus,
  JobOfferInfo,
  JobApplyInfo,
  JobOfferType,
} from "../../models/Model.JobPost";
import { listenerCount } from "process";
import { shuffle } from "lodash";

export const fetchJobPostMiddleware: Middleware<{}, JobPostState> = (store) => (
  next
) => async (action: ActionType<typeof jobposts>) => {
  next(action);

  let states = store.getState();
  let state: JobPostState = states["jobpost"];
  let user: UserState = states["user"];
  let me = user ? user.me : null;

  if (
    ABTestUtil.isTest(ABTestFeature.UI_JOB_POST) &&
    user &&
    user.authStatus === AuthStatus.AUTHENTICATED
  ) {
    if (action.type === getType(jobposts.init)) {
      log(LogLevel.REDUX, "fetchJobPostMiddleware: jobposts.init ", state, me);
      await fetchList(0, me, state, jobposts, next);
    } else if (action.type === getType(jobposts.reloadList)) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.reloadList ",
        state,
        action.meta
      );
      await fetchList(action.meta, me, state, jobposts, next);
    } else if (action.type === getType(jobposts.loadList)) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.loadList ",
        state,
        action.payload
      );
      await fetchList(action.payload, me, state, jobposts, next);
    } else if (action.type === getType(jobposts.loadOffer)) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.loadOffer ",
        state,
        action
      );
      next(jobposts.fetchOffer.request(action.meta));
      try {
        let result = await fetchAPI(
          API.JOBPOST_OFFER_GET,
          action.meta.toString(),
          action.payload,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result) {
          if (!result.error && result.data) {
            let aOffer: JobOfferInfo = result.data;
            if (aOffer.applications && aOffer.applications.length) {
              let applications = aOffer.applications.map((item) => {
                return { id: item.id };
              });
              for (let k = 0; k < aOffer.applications.length; k++) {
                let aApply: JobApplyInfo = aOffer.applications[k];
                aApply.offer = { id: aOffer.id };
                next(jobposts.fetchApply.success(aApply));
              }
              aOffer.applications = applications;
            }
            next(jobposts.fetchOffer.success(aOffer));
            log(
              LogLevel.REDUX,
              "fetchJobPostMiddleware: jobposts.loadOffer SUCCESS"
            );
          } else {
            log(
              LogLevel.REDUX,
              "fetchJobPostMiddleware: jobposts.loadOffer error ",
              result
            );
            next(jobposts.fetchOffer.failure(action.meta));
          }
        }
      } catch (e) {
        log(
          LogLevel.REDUX,
          "fetchJobPostMiddleware: jobposts.loadOffer exception ",
          e
        );
        next(jobposts.fetchOffer.failure(action.meta));
      }
    } else if (action.type === getType(jobposts.loadApply)) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.loadApply ",
        state,
        action
      );
      next(jobposts.fetchApply.request(action.meta));
      try {
        let result = await fetchAPI(
          API.JOBPOST_APPLY_GET,
          action.meta.toString(),
          action.payload,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result) {
          if (!result.error && result.data) {
            let aApply: JobApplyInfo = result.data;
            if (aApply.offer) {
              aApply.offer.applications = [{ id: aApply.id }];
              next(jobposts.fetchOffer.success(aApply.offer));
              aApply.offer = { id: aApply.offerId };
            }
            next(jobposts.fetchApply.success(aApply));
            log(
              LogLevel.REDUX,
              "fetchJobPostMiddleware: jobposts.loadApply SUCCESS"
            );
          } else {
            log(
              LogLevel.REDUX,
              "fetchJobPostMiddleware: jobposts.loadApply error ",
              result
            );
            next(jobposts.fetchApply.failure(action.meta));
          }
        }
      } catch (e) {
        log(
          LogLevel.REDUX,
          "fetchJobPostMiddleware: jobposts.loadApply error ",
          e
        );
        next(jobposts.fetchApply.failure(action.meta));
      }
    } else if (action.type === getType(jobposts.loadCoupon)) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.loadApply ",
        state,
        action
      );
      next(jobposts.fetchCoupon.request(null));
      try {
        let result = await fetchAPI(
          API.JOBPOST_COUPON_GET,
          "",
          {},
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result) {
          if (!result.error && result.data && result.data.length) {
            next(jobposts.fetchCoupon.success(result.data[0]));
            log(
              LogLevel.REDUX,
              "fetchJobPostMiddleware: jobposts.loadApply SUCCESS"
            );
          } else {
            log(
              LogLevel.REDUX,
              "fetchJobPostMiddleware: jobposts.loadApply error ",
              result
            );
            next(jobposts.fetchCoupon.failure(null));
          }
        }
      } catch (e) {
        log(
          LogLevel.REDUX,
          "fetchJobPostMiddleware: jobposts.loadApply error ",
          e
        );
        next(jobposts.fetchCoupon.failure(null));
      }
    } else if (action.type === getType(jobposts.visit)) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.visit ",
        state,
        action
      );
      let currentTime = Date.now();
      // if(state.lists[JobListType.OFFER|JobListType.RECOMMENDED] && state.lists[JobListType.OFFER|JobListType.RECOMMENDED].items && state.lists[JobListType.OFFER|JobListType.RECOMMENDED].items.length){
      //   let items = state.lists[JobListType.OFFER|JobListType.RECOMMENDED].items;
      //   for(let i=0; i<items.length; i++){
      //     if(state.offers[items[i].id] && state.offers[items[i].id].startedAt){
      //       let item:JobOfferInfo = state.offers[items[i].id];
      //       let lastDate = new Date(item.startedAt)
      //       if(lastDate){
      //         let lastDateValue = lastDate.valueOf() + 9*60*60*1000;
      //         if(lastDateValue > currentTime)
      //           currentTime = lastDateValue;
      //       }
      //     }
      //   }
      // }
      setGlobal(GlobalKey.JOBPOST_LAST_VISITED, currentTime, true);
    } else if (action.type === getType(jobposts.visitLeave)) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.visitLeave ",
        state,
        action
      );
      next(jobposts.countNew(0));
    } else if (
      action.type === getType(jobposts.countNewApply) &&
      me &&
      me.workType === UserWorkType.PHARMACY_OWNER &&
      state.lists[JobListType.OFFER | JobListType.MY] &&
      state.lists[JobListType.OFFER | JobListType.MY].items &&
      state.lists[JobListType.OFFER | JobListType.MY].items.length
    ) {
      log(
        LogLevel.REDUX,
        "fetchJobPostMiddleware: jobposts.visitLeave ",
        state,
        action
      );
      let list = state.lists[JobListType.OFFER | JobListType.MY].items;
      let newCount = 0;
      for (let j = 0; j < list.length; j++) {
        let aOffer: JobOfferInfo = list[j];
        if (aOffer.applications && aOffer.applications.length) {
          for (let k = 0; k < aOffer.applications.length; k++) {
            let aApply: JobApplyInfo = state.applies[aOffer.applications[k].id];
            if (aApply && aApply.status === JobApplyStatus.APPLIED) {
              newCount++;
            }
          }
        }
      }
      next(jobposts.countNew(newCount));
    }
  }
};

const fetchList = async (
  type: number,
  me: UserInfo,
  state: JobPostState,
  jobposts,
  next
) => {
  if (!me || !state.lists) {
    log(LogLevel.REDUX, "jobpost: fetchList: Error ", me, state, type);
    return;
  }
  log(LogLevel.REDUX, "jobpost: fetchList: Start ", me, state, type);

  let keys = Object.keys(state.lists);

  for (let i = 0; i < keys.length; i++) {
    if (!type || keys[i] === type.toString()) {
      let listState: JobListState = state.lists[keys[i]];
      if (
        listState &&
        (listState.needReload || !listState.end) &&
        !listState.loading
      ) {
        let rvalue = { ...listState };
        next(jobposts.fetchList.request(keys[i]));
        // eslint-disable-next-line default-case

        switch (keys[i]) {
          case (JobListType.OFFER | JobListType.MY).toString():
            if (!listState.needReload && listState.end) return;
            try {
              let option = {
                ownerId: me.id,
                applies: true,
                minStatus: JobOfferStatus.POSTED,
                count: 0,
                start: 0,
              };
              let result = await fetchAPI(
                API.JOBPOST_OFFERS,
                "",
                option,
                null,
                getGlobal(GlobalKey.TOKEN)
              );
              if (result) {
                if (!result.error && result.data && result.data.length) {
                  let data = result.data.map((item) => {
                    return { id: item.id };
                  });
                  let newCount = 0;
                  for (let j = 0; j < result.data.length; j++) {
                    let aOffer: JobOfferInfo = result.data[j];
                    if (aOffer.applications && aOffer.applications.length) {
                      let applications = aOffer.applications.map((item) => {
                        return { id: item.id };
                      });
                      for (let k = 0; k < aOffer.applications.length; k++) {
                        let aApply: JobApplyInfo = aOffer.applications[k];
                        aApply.offer = { id: aOffer.id };
                        if (aApply.status === JobApplyStatus.APPLIED) {
                          newCount++;
                        }
                        next(jobposts.fetchApply.success(aApply));
                      }
                      aOffer.applications = applications;
                    }
                    next(jobposts.fetchOffer.success(aOffer));
                  }
                  next(jobposts.countNew(newCount));
                  rvalue.items = data;
                } else {
                  rvalue.items = [];
                }
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              } else {
                rvalue.items = [];
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              }
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.MY",
                result,
                rvalue
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.MY error ",
                e
              );
              next(jobposts.fetchList.failure(keys[i]));
            }
            break;
          case (JobListType.OFFER | JobListType.HISTORY).toString():
            if (!listState.needReload && listState.end) return;
            try {
              let option = {
                ownerId: me.id,
                count: 10,
                withEnded: 1,
                start: listState.needReload ? 0 : listState.items.length,
              };
              let result = await fetchAPI(
                API.JOBPOST_OFFERS,
                "",
                option,
                null,
                getGlobal(GlobalKey.TOKEN)
              );
              if (result) {
                if (!result.error && result.data && result.data.length) {
                  let data = result.data.map((item) => {
                    return { id: item.id };
                  });
                  for (let j = 0; j < result.data.length; j++) {
                    next(jobposts.fetchOffer.success(result.data[j]));
                  }
                  rvalue.items = [
                    ...(listState.needReload ? [] : rvalue.items),
                    ...data,
                  ];
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                }
                rvalue.loading = false;
                rvalue.end =
                  result.data && result.data.length === 10 ? false : true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              } else {
                rvalue.items = listState.needReload ? [] : rvalue.items;
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              }
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.HISTORY",
                result,
                rvalue
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.HISTORY error ",
                e
              );
              next(jobposts.fetchList.failure(keys[i]));
            }
            break;
          // case ((JobListType.OFFER|JobListType.RECOMMENDED).toString()) :
          //   if(me.jobpostStatus && me.jobpostStatus !="-" && me.homePosition && me.homePosition.x && me.homePosition.y){
          //     if(!listState.needReload && listState.end)
          //       return;
          //     try{
          //       let option:any = {
          //         applicantId: 1,
          //         count: 0,
          //         start: listState.needReload?0:listState.items.length,
          //         type: me.jobpostStatus,
          //         positionx: me.homePosition?me.homePosition.x:0,   //{x,y}
          //         positiony: me.homePosition?me.homePosition.y:0,
          //         distance: (listState.options&&listState.options.distanceFree)?50000:me.jobpostDistance,    // 0: infinity
          //         totalCountNeeded: 1,
          //         furtherCountDistance:  (listState.options&&listState.options.distanceFree)?0:50000,
          //         minStatus: JobOfferStatus.POSTED,
          //       }
          //       // let option:any = {
          //       //   applicantId: 1,
          //       //   count: 5,
          //       //   start: listState.needReload?0:listState.items.length,
          //       //   type: me.jobpostStatus,
          //       //   positionx: me.homePosition?me.homePosition.x:0,   //{x,y}
          //       //   positiony: me.homePosition?me.homePosition.y:0,
          //       //   distance: (listState.options&&listState.options.distanceFree)?50000:me.jobpostDistance,    // 0: infinity
          //       //   totalCountNeeded: 1,
          //       //   furtherCountDistance:  (listState.options&&listState.options.distanceFree)?0:50000,
          //       //   minStatus: JobOfferStatus.POSTED,
          //       // }

          //       if(!option.start){
          //         option.totalCountNeeded = 1;
          //         option.furtherCountDistance = (listState.options&&listState.options.distanceFree)?0:50000;
          //       }

          //       let result = await fetchAPI(API.JOBPOST_OFFERS, '', option, null, getGlobal(GlobalKey.TOKEN));
          //       if(result){
          //         let lastVisited = state.lastVisited;
          //         let newCnt = 0;

          //         if(!result.error && result.data && result.data.length){
          //           let data = result.data.map((item) => { return {id:item.id}});
          //           for(let j=0; j<result.data.length; j++){
          //             let item:JobOfferInfo = result.data[j];
          //             let lastDate = new Date(item.startedAt)
          //             if(lastDate){
          //               let lastDateValue = lastDate.valueOf() + 9*60*60*1000;
          //               if(lastDateValue > lastVisited && (Date.now() - lastDateValue <= 2*24*60*60*1000))
          //                 newCnt++;
          //             }

          //             next(jobposts.fetchOffer.success(result.data[j]));
          //           }
          //           rvalue.items = [...(listState.needReload?[]:rvalue.items), ...data];
          //         }else{
          //           rvalue.items = listState.needReload?[]:rvalue.items;
          //         }

          //         if(result.totalCount)
          //           rvalue.totalCount = result.totalCount;
          //         if(result.furtherCount)
          //           rvalue.furtherCount = result.furtherCount;
          //         rvalue.loading = false;
          //         // rvalue.end = (result.data && result.data.length === 5 && (!rvalue.totalCount || rvalue.totalCount > rvalue.items.length ))?false:true;
          //         rvalue.end = true;
          //         rvalue.needReload = false;
          //         rvalue.checksum = rvalue.items.length;
          //         next(jobposts.fetchList.success(rvalue));

          //         if(newCnt){
          //           next(jobposts.countNew(newCnt));
          //         }
          //       }else{
          //         rvalue.items = listState.needReload?[]:rvalue.items;
          //         rvalue.loading = false;
          //         rvalue.end = true;
          //         rvalue.needReload = false;
          //         rvalue.checksum = rvalue.items.length;
          //         next(jobposts.fetchList.success(rvalue));
          //       }
          //       log(LogLevel.REDUX, "jobpost: fetchList: JobListType.OFFER|JobListType.RECOMMENDED", result, rvalue, rvalue.end);
          //     }catch(e){
          //       log(LogLevel.REDUX, "jobpost: fetchList: JobListType.OFFER|JobListType.RECOMMENDED error", e, rvalue.end);
          //       next(jobposts.fetchList.failure(e));
          //     }
          //   }
          //   break;
          case (JobListType.OFFER | JobListType.ALL).toString():
            console.log(listState);
            if (!listState.needReload && listState.end) return;
            // if(me.jobpostStatus){
            try {
              let option: any = {
                count: 10,
                start: listState.needReload ? 0 : listState.items.length,
                type:
                  listState.options && listState.options.type
                    ? listState.options.type
                    : "",
                distance:
                  listState.options && listState.options.distance
                    ? listState.options.distance
                    : 0, // 0: infinity
                address:
                  listState.options && listState.options.address
                    ? listState.options.address
                    : "",
                unreadOnly:
                  listState.options && listState.options.unreadOnly ? true : "",
                minStatus: JobOfferStatus.POSTED,
                ownerType: 0,
              };

              setGlobal(
                GlobalKey.JOBPOST_SEARCH_LAST_OPTION,
                listState.options,
                true
              );

              if (me.workType === UserWorkType.PHARMACY_OWNER) {
                if (
                  me.workPlacePosition &&
                  me.workPlacePosition.x &&
                  me.workPlacePosition.y
                ) {
                  option.positionx = me.workPlacePosition.x; //{x,y}
                  option.positiony = me.workPlacePosition.y; //{x,y}
                }

                // if(listState.options.status === "ended"){
                //   option.minStatus = JobOfferStatus.ENDED;
                //   option.withEnded = true;
                // }else if(listState.options.status === "all"){
                //   option.withEnded = true;
                // }
              } else {
                if (me.homePosition && me.homePosition.x && me.homePosition.y) {
                  option.positionx = me.homePosition.x; //{x,y}
                  option.positiony = me.homePosition.y; //{x,y}
                }
              }

              option.applicantId = 1;

              if (listState.options && listState.options.order === "raised") {
                option.orderDateField = "raised";
              }

              if (!option.start) {
                option.totalCountNeeded = 1;
              }

              if (listState.options && listState.options.liked) {
                option.liked = true;
              }

              let result = await fetchAPI(
                API.JOBPOST_OFFERS,
                "",
                option,
                null,
                getGlobal(GlobalKey.TOKEN)
              );
              if (result) {
                if (!result.error && result.data && result.data.length) {
                  let data = result.data.map((item) => {
                    return { id: item.id };
                  });
                  for (let j = 0; j < result.data.length; j++) {
                    next(jobposts.fetchOffer.success(result.data[j]));
                  }
                  rvalue.items = [
                    ...(listState.needReload ? [] : rvalue.items),
                    ...data,
                  ];
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                }

                if (result.totalCount) rvalue.totalCount = result.totalCount;

                rvalue.loading = false;
                rvalue.end =
                  result.data && result.data.length === 10 ? false : true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              } else {
                rvalue.items = listState.needReload ? [] : rvalue.items;
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              }
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL",
                result,
                rvalue
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL error",
                e
              );
              next(jobposts.fetchList.failure(e));
            }
            // }
            break;
          case (JobListType.OFFER | JobListType.PHARMACY).toString():
            if (!listState.needReload && listState.end) return;

            try {
              let option: any = {
                count: 10,
                start: listState.needReload ? 0 : listState.items.length,
                type:
                  listState.options && listState.options.type
                    ? listState.options.type
                    : "",
                distance:
                  listState.options && listState.options.distance
                    ? listState.options.distance
                    : 0, // 0: infinity
                address:
                  listState.options && listState.options.address
                    ? listState.options.address
                    : "",
                unreadOnly:
                  listState.options && listState.options.unreadOnly ? true : "",
                minStatus: JobOfferStatus.POSTED,
                ownerType: 1,
              };

              setGlobal(
                GlobalKey.JOBPOST_SEARCH_LAST_OPTION,
                listState.options,
                true
              );

              if (me.workType === UserWorkType.PHARMACY_OWNER) {
                if (
                  me.workPlacePosition &&
                  me.workPlacePosition.x &&
                  me.workPlacePosition.y
                ) {
                  option.positionx = me.workPlacePosition.x; //{x,y}
                  option.positiony = me.workPlacePosition.y; //{x,y}
                }

                // if(listState.options.status === "ended"){
                //   option.minStatus = JobOfferStatus.ENDED;
                //   option.withEnded = true;
                // }else if(listState.options.status === "all"){
                //   option.withEnded = true;
                // }
              } else {
                if (me.homePosition && me.homePosition.x && me.homePosition.y) {
                  option.positionx = me.homePosition.x; //{x,y}
                  option.positiony = me.homePosition.y; //{x,y}
                }
              }

              option.applicantId = 1;

              if (listState.options && listState.options.order === "raised") {
                option.orderDateField = "raised";
              }

              if (!option.start) {
                option.totalCountNeeded = 1;
              }

              if (listState.options && listState.options.liked) {
                option.liked = true;
              }

              let result = await fetchAPI(
                API.JOBPOST_OFFERS,
                "",
                option,
                null,
                getGlobal(GlobalKey.TOKEN)
              );
              if (result) {
                if (!result.error && result.data && result.data.length) {
                  // console.log("Pharm Result: ", result.data, option);
                  let data = result.data.map((item) => {
                    return { id: item.id };
                  });
                  for (let j = 0; j < result.data.length; j++) {
                    next(jobposts.fetchOffer.success(result.data[j]));
                  }
                  rvalue.items = [
                    ...(listState.needReload ? [] : rvalue.items),
                    ...data,
                  ];
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                }

                if (result.totalCount) rvalue.totalCount = result.totalCount;

                rvalue.loading = false;
                rvalue.end =
                  result.data && result.data.length === 10 ? false : true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              } else {
                rvalue.items = listState.needReload ? [] : rvalue.items;
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              }
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL",
                result,
                rvalue
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL error",
                e
              );
              next(jobposts.fetchList.failure(e));
            }
            // }
            break;
          case (JobListType.OFFER | JobListType.BIZ).toString():
            if (!listState.needReload && listState.end) return;

            try {
              let option: any = {
                count: 10,
                start: listState.needReload ? 0 : listState.items.length,
                type:
                  listState.options && listState.options.type
                    ? listState.options.type
                    : "",
                distance:
                  listState.options && listState.options.distance
                    ? listState.options.distance
                    : 0, // 0: infinity
                address:
                  listState.options && listState.options.address
                    ? listState.options.address
                    : "",
                unreadOnly:
                  listState.options && listState.options.unreadOnly ? true : "",
                minStatus: JobOfferStatus.POSTED,
                ownerType: 2,
                companyType: listState.options.companyType || 0,
              };

              setGlobal(
                GlobalKey.JOBPOST_SEARCH_LAST_OPTION,
                listState.options,
                true
              );

              if (me.workType === UserWorkType.PHARMACY_OWNER) {
                if (
                  me.workPlacePosition &&
                  me.workPlacePosition.x &&
                  me.workPlacePosition.y
                ) {
                  option.positionx = me.workPlacePosition.x; //{x,y}
                  option.positiony = me.workPlacePosition.y; //{x,y}
                }

                // if(listState.options.status === "ended"){
                //   option.minStatus = JobOfferStatus.ENDED;
                //   option.withEnded = true;
                // }else if(listState.options.status === "all"){
                //   option.withEnded = true;
                // }
              } else {
                if (me.homePosition && me.homePosition.x && me.homePosition.y) {
                  option.positionx = me.homePosition.x; //{x,y}
                  option.positiony = me.homePosition.y; //{x,y}
                }
              }

              option.applicantId = 1;

              if (listState.options && listState.options.order === "raised") {
                option.orderDateField = "raised";
              }

              if (!option.start) {
                option.totalCountNeeded = 1;
              }

              if (listState.options && listState.options.liked) {
                option.liked = true;
              }

              let result = await fetchAPI(
                API.JOBPOST_OFFERS,
                "",
                option,
                null,
                getGlobal(GlobalKey.TOKEN)
              );
              if (result) {
                if (!result.error && result.data && result.data.length) {
                  // console.log("Pharm Result: ", result.data, option);
                  let data = result.data.map((item) => {
                    return { id: item.id };
                  });
                  for (let j = 0; j < result.data.length; j++) {
                    next(jobposts.fetchOffer.success(result.data[j]));
                  }
                  rvalue.items = [
                    ...(listState.needReload ? [] : rvalue.items),
                    ...data,
                  ];
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                }

                if (result.totalCount) rvalue.totalCount = result.totalCount;

                rvalue.loading = false;
                rvalue.end =
                  result.data && result.data.length === 10 ? false : true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              } else {
                rvalue.items = listState.needReload ? [] : rvalue.items;
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              }
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL",
                result,
                rvalue
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL error",
                e
              );
              next(jobposts.fetchList.failure(e));
            }
            // }
            break;
          case (JobListType.OFFER | JobListType.SEARCHED).toString():
            if (!listState.needReload && listState.end) return;

            if (!listState.options.query.trim() && listState.items.length > 0) {
              rvalue.items = [];
              rvalue.loading = false;
              rvalue.end = true;
              rvalue.needReload = false;
              rvalue.checksum = 0;
              next(jobposts.fetchList.success(rvalue));
              return;
            }
            // if(me.jobpostStatus){
            try {
              let option: any = {
                count: 10,
                start: listState.needReload ? 0 : listState.items.length,
                type:
                  listState.options && listState.options.type
                    ? listState.options.type
                    : "",
                distance:
                  listState.options && listState.options.distance
                    ? listState.options.distance
                    : 0, // 0: infinity
                address:
                  listState.options && listState.options.address
                    ? listState.options.address
                    : "",
                // unreadOnly: listState.options && listState.options.unreadOnly ? true : "",
                unreadOnly: false,
                minStatus: JobOfferStatus.POSTED,
                query: listState.options.query,
                companyType: listState.options.companyType || 0,
                ownerType: listState.options.ownerType || 0,
              };

              setGlobal(
                GlobalKey.JOBPOST_SEARCH_LAST_OPTION,
                listState.options,
                true
              );

              if (me.workType === UserWorkType.PHARMACY_OWNER) {
                if (
                  me.workPlacePosition &&
                  me.workPlacePosition.x &&
                  me.workPlacePosition.y
                ) {
                  option.positionx = me.workPlacePosition.x; //{x,y}
                  option.positiony = me.workPlacePosition.y; //{x,y}
                }

                // if(listState.options.status === "ended"){
                //   option.minStatus = JobOfferStatus.ENDED;
                //   option.withEnded = true;
                // }else if(listState.options.status === "all"){
                //   option.withEnded = true;
                // }
              } else {
                if (me.homePosition && me.homePosition.x && me.homePosition.y) {
                  option.positionx = me.homePosition.x; //{x,y}
                  option.positiony = me.homePosition.y; //{x,y}
                }
              }

              option.applicantId = 1;

              if (listState.options && listState.options.order === "raised") {
                option.orderDateField = "raised";
              }

              if (!option.start) {
                option.totalCountNeeded = 1;
              }

              if (listState.options && listState.options.liked) {
                option.liked = true;
              }

              let result = await fetchAPI(
                API.JOBPOST_OFFERS,
                "",
                option,
                null,
                getGlobal(GlobalKey.TOKEN)
              );
              if (result) {
                if (!result.error && result.data && result.data.length) {
                  let data = result.data.map((item) => {
                    return { id: item.id };
                  });
                  for (let j = 0; j < result.data.length; j++) {
                    next(jobposts.fetchOffer.success(result.data[j]));
                  }
                  rvalue.items = [
                    ...(listState.needReload ? [] : rvalue.items),
                    ...data,
                  ];
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                }

                if (result.totalCount) rvalue.totalCount = result.totalCount;

                rvalue.loading = false;
                rvalue.end =
                  result.data && result.data.length === 10 ? false : true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              } else {
                rvalue.items = listState.needReload ? [] : rvalue.items;
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              }
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.SEARCHED",
                result,
                rvalue
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.SEARCHED error",
                e
              );
              next(jobposts.fetchList.failure(e));
            }
            // }
            break;
          case (JobListType.OFFER | JobListType.MY_LIKE).toString():
            console.log(listState);
            if (!listState.needReload && listState.end) return;
            // if(me.jobpostStatus){
            try {
              let option: any = {
                count: 10,
                start: listState.needReload ? 0 : listState.items.length,
                minStatus: JobOfferStatus.POSTED,
              };
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType. me",
                me
              );

              if (me.workType === UserWorkType.PHARMACY_OWNER) {
                if (
                  me.workPlacePosition &&
                  me.workPlacePosition.x &&
                  me.workPlacePosition.y
                ) {
                  option.positionx = me.workPlacePosition.x; //{x,y}
                  option.positiony = me.workPlacePosition.y; //{x,y}
                }

                // if(listState.options.status === "ended"){
                //   option.minStatus = JobOfferStatus.ENDED;
                //   option.withEnded = true;
                // }else if(listState.options.status === "all"){
                //   option.withEnded = true;
                // }
              } else {
                if (me.homePosition && me.homePosition.x && me.homePosition.y) {
                  option.positionx = me.homePosition.x; //{x,y}
                  option.positiony = me.homePosition.y; //{x,y}
                }
              }

              option.applicantId = 1;

              if (!option.start) {
                option.totalCountNeeded = 1;
              }

              if (listState.options && listState.options.liked) {
                option.liked = true;
              }

              let result = await fetchAPI(
                API.JOBPOST_OFFERS,
                "",
                option,
                null,
                getGlobal(GlobalKey.TOKEN)
              );

              if (result) {
                if (!result.error && result.data && result.data.length) {
                  let data = result.data.map((item) => {
                    return { id: item.id };
                  });
                  for (let j = 0; j < result.data.length; j++) {
                    next(jobposts.fetchOffer.success(result.data[j]));
                  }
                  rvalue.items = [
                    ...(listState.needReload ? [] : rvalue.items),
                    ...data,
                  ];
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                }

                if (result.totalCount) rvalue.totalCount = result.totalCount;

                rvalue.loading = false;
                rvalue.end =
                  result.data && result.data.length === 10 ? false : true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              } else {
                rvalue.items = listState.needReload ? [] : rvalue.items;
                rvalue.loading = false;
                rvalue.end = true;
                rvalue.needReload = false;
                rvalue.checksum = rvalue.items.length;
                next(jobposts.fetchList.success(rvalue));
              }
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL",
                result,
                rvalue
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL error",
                e
              );
              next(jobposts.fetchList.failure(e));
            }
            // }
            break;
          case (JobListType.OFFER | JobListType.PREMIUM).toString():
            try {
              const res = await fetchAPI(
                API.JOBPOST_PREMIUMS,
                "",
                null,
                null,
                getGlobal(GlobalKey.TOKEN)
              );
              const { gold, silver, bronze, pharmacy } = res;
              next(
                jobposts.fetchList.success({
                  id: JobListType.OFFER | JobListType.PREMIUM,
                  gold: shuffle(gold),
                  silver: shuffle(silver),
                  bronze: shuffle(bronze),
                  pharmacy: shuffle(pharmacy),
                })
              );
            } catch (e) {
              log(
                LogLevel.REDUX,
                "jobpost: fetchList: JobListType.OFFER|JobListType.ALL error",
                e
              );
              next(jobposts.fetchList.failure(e));
            }
            // }
            break;
          case (JobListType.APPLY | JobListType.MY).toString():
            if (me.jobpostStatus) {
              try {
                let option = {
                  applicantId: me.id,
                  distance: 1,
                  count: 0,
                  start: 0,
                  dateField: "modified",
                  minStatus: JobApplyStatus.SAVED,
                  detail: 1,
                  offerDetail: 1,
                  withLikes: 1,
                  // withInqueries: 1,
                  withEndedWithinDays: 7,
                };
                let result = await fetchAPI(
                  API.JOBPOST_APPLIES,
                  "",
                  option,
                  null,
                  getGlobal(GlobalKey.TOKEN)
                );
                if (result) {
                  if (!result.error && result.data && result.data.length) {
                    log(
                      LogLevel.REDUX,
                      "jobpost: fetchList: JobListType.APPLY|JobListType.MY 0",
                      result,
                      rvalue
                    );
                    let data = result.data.map((item) => {
                      return { id: item.id };
                    });
                    for (let j = 0; j < result.data.length; j++) {
                      let aApply: JobApplyInfo = result.data[j];
                      if (aApply.offer) {
                        if (aApply.id > 0)
                          aApply.offer.applications = [{ id: aApply.id }];
                        next(jobposts.fetchOffer.success(aApply.offer));
                        aApply.offer = { id: aApply.offerId };
                      }
                      next(jobposts.fetchApply.success(aApply));
                    }
                    rvalue.items = [...data];
                  } else {
                    rvalue.items = listState.needReload ? [] : rvalue.items;
                  }
                  rvalue.loading = false;
                  rvalue.end = true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                } else {
                  rvalue.items = [];
                  rvalue.loading = false;
                  rvalue.end = true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                }
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.MY",
                  result,
                  rvalue
                );
              } catch (e) {
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.MY error",
                  e
                );
                next(jobposts.fetchList.failure(e));
              }
            }
            break;
          case (JobListType.APPLY | JobListType.HISTORY).toString():
            if (me.jobpostStatus) {
              try {
                let option = {
                  applicantId: me.id,
                  distance: true,
                  count: 30,
                  start: listState.needReload ? 0 : listState.items.length,
                  dateField: "modified",
                  detail: 1,
                  minStatus: JobApplyStatus.APPLIED,
                  totalCountNeeded: 1,
                  offerDetail: 1,
                };
                let result = await fetchAPI(
                  API.JOBPOST_APPLIES,
                  "",
                  option,
                  null,
                  getGlobal(GlobalKey.TOKEN)
                );
                if (result) {
                  if (!result.error && result.data && result.data.length) {
                    log(
                      LogLevel.REDUX,
                      "jobpost: fetchList: JobListType.APPLY|JobListType.HISTORY 0",
                      result,
                      rvalue
                    );
                    let data = result.data.map((item) => {
                      return { id: item.id };
                    });
                    for (let j = 0; j < result.data.length; j++) {
                      let aApply: JobApplyInfo = result.data[j];
                      if (aApply.offer) {
                        aApply.offer.applications = [{ id: aApply.id }];
                        next(jobposts.fetchOffer.success(aApply.offer));
                        aApply.offer = { id: aApply.offerId };
                      }
                      next(jobposts.fetchApply.success(aApply));
                    }

                    if (result.totalCount)
                      rvalue.totalCount = result.totalCount;

                    rvalue.items = [
                      ...(listState.needReload ? [] : rvalue.items),
                      ...data,
                    ];
                  } else {
                    rvalue.items = listState.needReload ? [] : rvalue.items;
                  }

                  rvalue.loading = false;
                  rvalue.end =
                    result.data && result.data.length === 10 ? false : true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                  rvalue.loading = false;
                  rvalue.end = true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                }
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.HISTORY",
                  result,
                  rvalue
                );
              } catch (e) {
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.HISTORY error",
                  e
                );
                next(jobposts.fetchList.failure(e));
              }
            }
            break;
          case (JobListType.APPLY | JobListType.PASSED).toString():
            if (me.jobpostStatus) {
              try {
                let option = {
                  start: 0,
                  count: 1,
                  applicantId: me.id,
                  status: JobApplyStatus.PASSED,
                  offerDetail: 1,
                };
                let result = await fetchAPI(
                  API.JOBPOST_APPLIES,
                  "",
                  option,
                  null,
                  getGlobal(GlobalKey.TOKEN)
                );
                if (result) {
                  if (!result.error && result.data && result.data.length) {
                    let data = result.data.map((item) => {
                      return { id: item.id };
                    });
                    for (let j = 0; j < result.data.length; j++) {
                      let aApply: JobApplyInfo = result.data[j];
                      if (aApply.offer) {
                        aApply.offer.applications = [{ id: aApply.id }];
                        next(jobposts.fetchOffer.success(aApply.offer));
                        aApply.offer = { id: aApply.offerId };
                      }
                      next(jobposts.fetchApply.success(aApply));
                    }
                    rvalue.items = [
                      ...(listState.needReload ? [] : rvalue.items),
                      ...data,
                    ];
                  } else {
                    rvalue.items = listState.needReload ? [] : rvalue.items;
                  }

                  rvalue.loading = false;
                  rvalue.end =
                    result.data && result.data.length === 10 ? false : true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                } else {
                  rvalue.items = listState.needReload ? [] : rvalue.items;
                  rvalue.loading = false;
                  rvalue.end = true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                }
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.PASSED",
                  result,
                  rvalue
                );
              } catch (e) {
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.PASSED error",
                  e
                );
                next(jobposts.fetchList.failure(e));
              }
            }
            break;
          case (JobListType.APPLY | JobListType.HISTORY_LATEST).toString():
            if (me.jobpostStatus) {
              try {
                let option = {
                  applicantId: me.id,
                  distance: true,
                  count: 1,
                  start: 0,
                  dateField: "applied",
                  minStatus: JobApplyStatus.APPLIED,
                  detail: 2,
                };
                let result = await fetchAPI(
                  API.JOBPOST_APPLIES,
                  "",
                  option,
                  null,
                  getGlobal(GlobalKey.TOKEN)
                );
                if (result) {
                  if (!result.error && result.data && result.data.length) {
                    let data = result.data.map((item) => {
                      return { id: item.id };
                    });
                    for (let j = 0; j < result.data.length; j++) {
                      let aApply: JobApplyInfo = result.data[j];
                      if (aApply.offer) {
                        aApply.offer.applications = [{ id: aApply.id }];
                        next(jobposts.fetchOffer.success(aApply.offer));
                        aApply.offer = { id: aApply.offerId };
                      }
                      next(jobposts.fetchApply.success(aApply));
                    }
                    rvalue.items = [...data];
                  } else {
                    rvalue.items = listState.needReload ? [] : rvalue.items;
                  }
                  rvalue.loading = false;
                  rvalue.end =
                    result.data && result.data.length === 10 ? false : true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                } else {
                  rvalue.items = [];
                  rvalue.loading = false;
                  rvalue.end = true;
                  rvalue.needReload = false;
                  rvalue.checksum = rvalue.items.length;
                  next(jobposts.fetchList.success(rvalue));
                }
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.HISTORY_LATEST",
                  result,
                  rvalue
                );
              } catch (e) {
                log(
                  LogLevel.REDUX,
                  "jobpost: fetchList: JobListType.APPLY|JobListType.HISTORY_LATEST error",
                  e
                );
                next(jobposts.fetchList.failure(e));
              }
            }
            break;
          default:
            break;
        }
      }
    }
  }
};
