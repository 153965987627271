import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton, IonIcon } from "@ionic/react";
import "./Login.css";
import "./../Common.css";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import kakaoIcon from "./../assets/icon/kakao_logo.png";
import { UserInfo, UserSNSType, UserWorkTypeName } from "./../models/Model.User";
import { log, LogLevel } from "../utils/LogUtil";
import { MY_ADDRESS } from "./../config.json";
import {VERSION,BUILD_DATE} from './../version.json';

import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import { action } from "typesafe-actions";
import { timerStart, timerStop, timerLap } from "../utils/TimeUtil";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import ABTestUtil from "../utils/ABTestUtil";
import jwt_decode from "jwt-decode";
import { getDevice } from "../utils/DeviceUtil";

const windowAny: any = window;

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    full?: boolean;
  };

type State = {
  redirection: string;
  count: number;
  appleLoginAvailable: boolean;
};

class Login extends Component<Props, State> {
  today: string;

  constructor(props: Props) {
    super(props);
    let count = getGlobal(GlobalKey.TOTAL_USER_COUNT, true);
    let now = new Date();
    this.today = now.getFullYear() + "년 " + (now.getMonth() + 1) + "월 " + now.getDate() + "일";

    this.state = {
      redirection: "",
      count,
      appleLoginAvailable: false,
    };
  }

  initTry = 0;
  waitInit = () => {
    if (getGlobal(GlobalKey.OS) == "ios") {
      if (windowAny.cordova && windowAny.cordova.plugins && windowAny.cordova.plugins.SignInWithApple) {
        this.setState({ appleLoginAvailable: true });
      } else {
        this.initTry++;
        if (this.initTry < 10) {
          setTimeout(() => {
            this.waitInit();
          }, 100);
        }
      }
    }
  };

  async componentDidMount() {
    let windowAny: any = window;
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") {
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.NONE, e);
      }
    }
    if (this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);
    // document.addEventListener("backbutton", this.goBack);
    let result = await fetchAPI(API.USER_COUNT, "", null, null);
    if (result && !result.error) {
      this.setState({ count: result.data });
      setGlobal(GlobalKey.TOTAL_USER_COUNT, result.data, true);
    }
    log(LogLevel.UI_LIFECYCLE, "Login:componentDidMount", os);
    this.waitInit();
  }

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "Login:goBack");
    let os = getGlobal(GlobalKey.OS);
    if (os && os != "browser" && windowAny.navigator && windowAny.navigator.app) {
      windowAny.navigator.app.exitApp();
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount() {
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    log(LogLevel.UI_EVENT, "Login:componentWillUnmount backbutton unregister");

    // document.removeEventListener("backbutton", this.goBack);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);
  }

  loginKakao = () => {
    let windowAny: any = window;
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "HOME_LOGIN_KAKAO", "카카오로그인");
    let os = getGlobal(GlobalKey.OS);
    log(LogLevel.UI_ACTION, "Login:login", os);
    timerStart();
    if (!os || os == "browser") {
      windowAny.Kakao.Auth.loginForm({
        success: (authObj) => {
          windowAny.Kakao.API.request({
            url: "/v2/user/me",
            success: this.onSuccessKakao,
            fail: this.onFailKakao,
          });
        },
        fail: this.onFailKakao,
      });
    } else {
      let loginOptions = {};
      loginOptions["authTypes"] = [
        windowAny.AuthConstant.AuthTypes.AuthTypeTalk,
        windowAny.AuthConstant.AuthTypes.AuthTypeStory,
        windowAny.AuthConstant.AuthTypes.AuthTypeAccount,
      ];

      windowAny.KakaoCordovaSDK.login(loginOptions, this.onSuccessKakao, this.onFailKakao);
    }
  };
  onStatus = ({ status, user }) => {
    log(LogLevel.UI_DATA_LOAD, "Login:onStatus", status, user);
  };

  onSuccessKakao = async (data) => {
    log(LogLevel.UI_DATA_LOAD, "Login:onSuccessKakao kakao login", data);
    if (!data || !data.id || !data.properties) {
      this.props.toastPopup.show("카카오톨 로그인 실패:권한을 확인하세요.");
    }
    let kakaoInfo = {
      id: data.id,
      email: data.kaccount_email ? data.kaccount_email : "",
      nickname: data.properties.nickname,
      profile: data.properties.thumbnail_image,
      name: "",
    };
    let result: any = await fetchAPI(API.LOGIN_REQUEST, "", null, { kakaoId: kakaoInfo.id });
    if (result && result.message && result.message == "success") {
      let token = result.data.token;
      setGlobal(GlobalKey.TOKEN, token, true);
      this.props.setToken(token);

      result = await fetchAPI(API.USER_ME_REQUEST, "", null, null, token);
      if (result && !result.error) {
        log(LogLevel.UI_DATA_LOAD, "Login:onSuccessKakao: yc login", result);
        try {
          this.props.setMe(result.data);
          windowAny.amplitude.getInstance().setUserId(result.data.id.toString());
          windowAny.amplitude
            .getInstance()
            .setUserProperties({ 
              nickname:result.data.nickname, 
              notiOption: getGlobal(GlobalKey.NOTIFICATION_OPTION, true), 
              level:result.data.level, 
              version:(VERSION + "." + BUILD_DATE),
              workType: UserWorkTypeName[result.data.workType],
              jobpostStatus: result.data.jobpostStatus,
              workRegion: result.data.workPlaceRegion,
              homeRegion: result.data.homeRegion,
              lastJobpostAccess: result.data.lastJobPostAt,
              licensedAt : result.data.licensedAt,
            });
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "LOGIN", "로그인");
          let path = "/";
          if (this.props.location.state && this.props.location.state.from) path = this.props.location.state.from;
          this.props.history.replace(path);
          // this.setState({redirection:`/`});
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, "Login:onSuccessKakao", e);
        }
      } else {
        log(LogLevel.UI_DATA_LOAD, "Login:onSuccessKakao: yc login: no me data");
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_1_DONE", "회원가입_1_회원구분", {
          소요시간: timerLap(),
        });
        this.props.history.push("/register", {
          type: UserSNSType.KAKAO,
          info: kakaoInfo,
          from: this.props.location.state ? this.props.location.state.from : null,
        });
      }
    } else {
      log(LogLevel.UI_DATA_LOAD, "Login:onSuccessKakao: API.LOGIN_REQUEST fail");
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_1_DONE", "회원가입_1_회원구분", {
        소요시간: timerLap(),
      });
      this.props.history.push("/register", {
        type: UserSNSType.KAKAO,
        info: kakaoInfo,
        from: this.props.location.state ? this.props.location.state.from : null,
      });
    }
  };

  onFailKakao = (err) => {
    log(LogLevel.UI_EXCEPTION, "Login:onFailKakao", err);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_1_ERROR", "회원가입_소셜로그인실패", {
      소셜: "kakao",
      에러: JSON.stringify(err),
    });
    alert("Kakao Login Failed");
    if (ABTestUtil.isTest()) {
      this.props.toastPopup.show(JSON.stringify(err, null, 4));
    }
  };

  loginApple = () => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "HOME_LOGIN_APPLE", "애플로그인");
    windowAny.cordova.plugins.SignInWithApple.signin(
      { requestedScopes: [0, 1] },
      this.onSuccessApple,
      this.onFailApple
    );
  };

  onSuccessApple = async (data) => {
    log(LogLevel.UI_DATA_LOAD, "Login:onSuccessApple kakao login", data, data.identityToken.toString());
    if (!data || !data.user) {
      this.props.toastPopup.show("애플 로그인 실패");
    }
    if (ABTestUtil.isTest()) {
      this.props.toastPopup.show(JSON.stringify(data, null, 4));
    }

    let email = "";
    if (data.email) email = data.email;
    if (!email && data.identityToken) {
      let parsed: any = jwt_decode(data.identityToken);
      if (parsed.email) email = parsed.email;
    }

    let name = "";
    if (data.fullName) {
      name = data.fullName.familyName + data.fullName.givenName;
    }

    let appleInfo = {
      id: data.user,
      email,
      nickname: "",
      profile: "",
      name,
    };

    let result: any = await fetchAPI(API.LOGIN_REQUEST, "", null, { appleId: appleInfo.id });
    if (result && result.message && result.message == "success") {
      let token = result.data.token;
      setGlobal(GlobalKey.TOKEN, token, true);
      this.props.setToken(token);

      result = await fetchAPI(API.USER_ME_REQUEST, "", null, null, token);
      if (result && !result.error) {
        log(LogLevel.UI_DATA_LOAD, "Login:onSuccessApple: yc login", result);
        try {
          this.props.setMe(result.data);
          windowAny.amplitude.getInstance().setUserId(result.data.id.toString());
          windowAny.amplitude
            .getInstance()
            .setUserProperties({ 
              nickname:result.data.nickname, 
              notiOption: getGlobal(GlobalKey.NOTIFICATION_OPTION, true), 
              level:result.data.level, 
              version:(VERSION + "." + BUILD_DATE),
              workType: UserWorkTypeName[result.data.workType],
              jobpostStatus: result.data.jobpostStatus,
              workRegion: result.data.workPlaceRegion,
              homeRegion: result.data.homeRegion,
              lastJobpostAccess: result.data.lastJobPostAt,
              licensedAt : result.data.licensedAt,
            });
          AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "LOGIN", "로그인");
          let path = "/";
          if (this.props.location.state && this.props.location.state.from) path = this.props.location.state.from;
          this.props.history.replace(path);
          // this.setState({redirection:`/`});
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, "Login:onSuccessApple", e);
        }
      } else {
        log(LogLevel.UI_DATA_LOAD, "Login:onSuccessApple: yc login: no me data");
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_1_DONE", "회원가입_1_회원구분", {
          소요시간: timerLap(),
        });
        this.props.history.push("/register", {
          type: UserSNSType.APPLE,
          info: appleInfo,
          from: this.props.location.state ? this.props.location.state.from : null,
        });
      }
    } else {
      log(LogLevel.UI_DATA_LOAD, "Login:onSuccessApple: API.LOGIN_REQUEST fail");
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_1_DONE", "회원가입_1_회원구분", {
        소요시간: timerLap(),
      });
      this.props.history.push("/register", {
        type: UserSNSType.APPLE,
        info: appleInfo,
        from: this.props.location.state ? this.props.location.state.from : null,
      });
    }
  };

  onFailApple = (err) => {
    log(LogLevel.UI_EXCEPTION, "Login:onFailApple", err);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_1_ERROR", "회원가입_소셜로그인실패", {
      소셜: "apple",
      에러: JSON.stringify(err, null, 4),
    });
    alert("Apple Login Failed");
    if (ABTestUtil.isTest()) {
      this.props.toastPopup.show(JSON.stringify(err, null, 4));
    }
  };

  renderView() {
    log(LogLevel.NONE, "Login:render");

    if (this.state.redirection) return <Redirect to={this.state.redirection} />;

    let appleLoginButton;
    if (
      getGlobal(GlobalKey.OS) == "ios" &&
      windowAny.cordova &&
      windowAny.cordova.plugins &&
      windowAny.cordova.plugins.SignInWithApple &&
      this.state.appleLoginAvailable &&
      windowAny.AppVersion &&
      windowAny.AppVersion.version > "1.1.6"
    ) {
      appleLoginButton = (
        <IonButton class="login-apple-button" onClick={this.loginApple}>
          <IonIcon class="login-apple-logo" name="logo-apple" />
          <span className="login-apple-text">Apple로 등록</span>
        </IonButton>
      );
    }

    return (
      <div className="login-container">
        <img className="login-logo-h" src={MY_ADDRESS + "/images/logo_h.png"} />
        <div className="common-flex-grow" style={{ minHeight: "60px" }} />
        <img className="login-emoticon" src={MY_ADDRESS + "/emoticons/knowledge_up.png"} />
        <div className="login-catchphase-normal">{this.today}, 오늘</div>
        <div className="login-catchphase-larger">
          <span className="common-color-point">전국 {this.state.count}명</span>의 약사님이
        </div>
        <div className="login-catchphase-normal">약문약답과 함께하고 있습니다. </div>
        <div className="common-flex-grow-2" />
        <IonButton class="login-kakao-button" onClick={this.loginKakao}>
          <img className="login-kakao-logo" src={kakaoIcon} />
          <span className="login-kakao-text">카카오로 시작하기</span>
        </IonButton>
        {appleLoginButton}
        <div className="common-flex-grow" style={{ maxHeight: "70px" }} />
      </div>
    );
  }

  render() {
    if ((getGlobal(GlobalKey.OS, true) == "browser" || getDevice() == "iPad") && !this.props.full)
      return (
        <div className="common-content common-flex-row common-flex-align-center">
          <div className="common-content common-container-column common-flex-align-center">
            <div className="common-flex-grow" />
            <div className="common-small-outer-container">{this.renderView()}</div>
            <div className="common-flex-grow" />
          </div>
        </div>
      );
    else {
      return this.renderView();
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  setMe: (user: UserInfo) => actions.user.setMe(user),
  setToken: (token: string) => actions.user.setToken(token),
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
