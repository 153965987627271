import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { IonToggle } from "@ionic/react";
import ViewerBody from "./ViewerBody";
import {
  BoardContent,
  BoardComment,
  BoardAttribute,
} from "./../models/Model.Board";
import * as API from "./../API.json";
import { fetchAPI } from "./../utils/APIUtil";
import { getGlobal, GlobalKey, setGlobal } from "./../utils/GlobalUtil";
import { BoardType } from "./../store/board/types";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";

import AnalyticsUtil from "../utils/AnalyticsUtil";
import badgeIcon from "./../assets/icon/nice_badge.png";
import { Flex, Static } from "./atoms/Layout";
import Tag from "./atoms/Tag";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import Icon from "./atoms/Icon";
import NiceCard from "../assets/image/nice_card.png";
import Button from "./atoms/Button";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onMoveToRecommend?: () => void;
  };

type State = {};

class BoardListRecommends extends Component<Props, State> {
  recommendsElement = null;
  state = {};

  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardListRecommends:componentDidMount",
      this.props.nice
    );
    this.autoScroll();

    if (this.recommendsElement) {
      this.recommendsElement.addEventListener("touchstart", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchmove", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchend", this.isolateTouch, {
        passive: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.recommendsElement) {
      this.recommendsElement.removeEventListener(
        "touchstart",
        this.isolateTouch,
        { passive: true }
      );
      this.recommendsElement.removeEventListener(
        "touchmove",
        this.isolateTouch,
        { passive: true }
      );
      this.recommendsElement.removeEventListener(
        "touchend",
        this.isolateTouch,
        { passive: true }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.recommendsElement &&
      (!prevProps.nice ||
        !prevProps.nice.contents ||
        prevProps.nice.contents.length === 0) &&
      !(
        !this.props.nice ||
        !this.props.nice.contents ||
        this.props.nice.contents.length === 0
      )
    ) {
      this.recommendsElement.addEventListener("touchstart", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchmove", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchend", this.isolateTouch, {
        passive: true,
      });
    }
  }

  isolateTouch(e) {
    e.stopPropagation();
  }

  navigateToViewPosting = (posting: BoardContent, index: number) => {
    let recommendDepth = 0;
    if (history) recommendDepth = history.length;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_CONTENT",
      "게시물_목록_추천선택",
      {
        게시물id: posting.id,
        추천내용: posting.subject,
      }
    );
    this.props.history.push(`/boards/view?id=${posting.id}`, {
      type: BoardType.RECOMMEND,
      index,
    });
  };

  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = async () => {
    // setTimeout(async () => {
    try {
      let scroll = getGlobal(GlobalKey.SCROLL + "UnionRecommend".toString());
      if (scroll) {
        if (this.recommendsElement) {
          this.recommendsElement.scrollTo(scroll, 0);
          log(
            LogLevel.UI_EVENT,
            "BoardListRecommends:autoScroll contentScroll"
          );
        } else {
          this.autoScrollTry++;
          if (this.autoScrollTry < this.autoScrollTryMax) {
            log(LogLevel.UI_EVENT, "BoardListRecommends:autoScroll Try ");
            setTimeout(() => this.autoScroll(), 10);
          } else {
            log(
              LogLevel.UI_EVENT,
              "BoardListRecommends:autoScroll Try FINISHED FAILED"
            );
          }
        }
      } else {
        log(LogLevel.UI_EVENT, "BoardListRecommends:autoScroll no scroll ");
      }
    } catch (e) {
      log(LogLevel.UI_EXCEPTION, "BoardListRecommends:autoScroll failed", e);
      this.autoScrollTry++;
      if (this.autoScrollTry < this.autoScrollTryMax) {
        log(
          LogLevel.UI_EVENT,
          "BoardListRecommends:autoScroll Try ",
          this.autoScrollTry,
          scroll
        );
        setTimeout(() => this.autoScroll(), 10);
      } else {
        log(
          LogLevel.UI_EVENT,
          "BoardListRecommends:autoScroll Try FINISHED FAILED"
        );
      }
    }
    // }, 100);
  };

  onScroll = (event) => {
    let target: any = event.target;
    log(
      LogLevel.UI_ACTION,
      "BoardListRecommends:renderContent",
      target.scrollLeft
    );

    if (!target || !this.recommendsElement) return;

    setGlobal(GlobalKey.SCROLL + "UnionRecommend", target.scrollLeft);
  };

  renderContent = (posting: BoardContent, index) => {
    let attribute = posting.boardsCategoriesAttribute
      ? posting.boardsCategoriesAttribute
      : "";
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardListRecommends:renderContent",
      posting,
      attribute
    );

    let text = posting.subject;
    if (!text) text = posting.bodyText;

    let rvalue = (
      <div key={index.toString()} style={{ flex: "none" }}>
        <Static
          customStyle={{
            padding: "12px",
            width: "210px",
            height: "98px",
            borderRadius: "8px",
            transform: "translateZ(1000)",
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
            border: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
          }}
          onClick={() => this.navigateToViewPosting(posting, index)}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Tag color="Gray" variant="Tinted" size="Small">
              {posting.boardsCategoriesName}
            </Tag>
          </div>
          <div style={{ marginTop: "8px" }}>
            <Text
              textType="Body2SemiBold"
              color={COLOR_SYSTEM.get("Gray")[800]}
              numberOfLines={2}
            >
              {text}
            </Text>
          </div>
        </Static>
      </div>
    );
    return rvalue;
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardListRecommends:render", this.props.nice);

    if (
      !this.props.nice ||
      !this.props.nice.contents ||
      this.props.nice.contents.length === 0
    )
      return null;

    let items = this.props.nice.contents.slice(0, 10);

    return (
      <>
        <Static
          style={{
            width: "100%",
            padding: "20px 20px 0 20px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Text textType={"Body1Bold"} color={COLOR_SYSTEM.get("Gray")[700]}>
            추천 게시글
          </Text>
          <img src={NiceCard} alt="" width={16} height={18} />
          <div className="common-flex-grow" />
          <Button
            size={"Small"}
            type={"Text"}
            variant={"Ghost"}
            color={"Quaternary"}
            onClick={this.props.onMoveToRecommend}
          >
            모두보기
          </Button>
        </Static>
        <Static
          ref={(ref) => {
            this.recommendsElement = ref;
          }}
          style={{
            width: "100%",
            maxWidth: "100%",
            padding: "12px 20px 16px 20px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            overflowX: "scroll",
            transform: "translateZ(0)",
          }}
          onScroll={this.onScroll}
        >
          {items.map((posting: any, index) => {
            return this.renderContent(this.props.contents[posting.id], index);
          })}
        </Static>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  contents: state.board.contents,
  nice: state.board.boards[BoardType.RECOMMEND],
  me: state.user.me,
});

const mapDispatchToProps = {
  // refreshBoards: () => actions.board.refreshBoards(),
  // loadBoard: (board:BoardType) => actions.board.getBoard(board.toString(), 50),
  loadContent: (id: number) => actions.board.getContent(id),
  loadBoard: (board: BoardType) => actions.board.getBoard(board, 10),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardListRecommends)
);
