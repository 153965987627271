import styled from "styled-components";
import { COLOR_SYSTEM } from "../../design/design-system";

interface JobPostRegistrationStyledProps {
  edit?: boolean;
  taxTypeError?: boolean;
}

const MainWrapper = styled.main<JobPostRegistrationStyledProps>`
  padding: ${({ edit }) => (edit ? "72px 24px 120px" : "72px 24px 60px")};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
  & > * + * {
    margin-top: 40px;
  }
`;

const SectionWrapper = styled.section<JobPostRegistrationStyledProps>`
  &.pharmacy-info {
    display: flex;
    flex-direction: column;
  }

  .error-message {
    display: inline-block;
    margin-top: 8px;
  }

  .pharmacy-info__address {
    margin-top: 8px;
  }

  &.workday {
    & > * + * {
      margin-top: 20px;
    }
  }

  .workday__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title__required {
    margin-left: 4px;
  }

  .workday__button-list {
    width: 100%;
    display: flex;
    overflow-x: auto;

    & > * + * {
      margin-left: 10px;
    }

    button {
      flex: 1;
    }
  }

  .worktime__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .worktime__input {
    textarea {
      @media screen and (max-width: 350px) {
        height: 230px;
      }

      @media screen and (max-width: 310px) {
        height: 260px;
      }

      @media screen and (max-width: 250px) {
        height: 290px;
      }
    }
  }

  &.need-license,
  .apply-method__button-list,
  &.pharmacistCnt {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 12px;
    }
  }

  .salary__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .salary__description {
    display: inline-block;
    margin-bottom: 12px;
  }

  .salary__button-list {
    display: flex;
    flex-wrap: nowrap;
    margin: 12px 0 4px -8px;

    & > * {
      margin-left: 8px;
      margin-bottom: 8px;
    }

    button {
      flex: 1 1 33%;
    }

    @media screen and (max-width: 300px) {
      & {
        flex-wrap: wrap;
      }
    }
  }

  .salary__input {
    display: flex;
    justify-content: space-between;

    & > * + * {
      margin-left: 8px;
    }
  }

  .salary__tax-type {
    margin-top: 12px;
    padding: 20px;
    border-radius: 12px;

    ${({ taxTypeError }) => {
      const backgroundColor = taxTypeError
        ? COLOR_SYSTEM.get("Red")[10]
        : COLOR_SYSTEM.get("Gray")[10];
      const borderColor = taxTypeError
        ? COLOR_SYSTEM.get("Red")[50]
        : COLOR_SYSTEM.get("Gray")[50];

      return `
        background-color: ${backgroundColor};
        border: 1px solid ${borderColor};
      `;
    }};

    .tax__buttons {
      margin: 12px 0;

      & > * + * {
        margin-top: 8px;
      }

      button {
        width: 100%;
      }
    }
  }

  .need-license__button-list {
    display: flex;

    & > * + * {
      margin-left: 8px;
    }

    button {
      flex: 1;
    }
  }

  .apply-method__desc {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 4px;
    }
  }

  .pharmacistCnt__input {
    display: flex;

    & > * + * {
      margin-left: 8px;
    }

    div {
      flex: 1 1 33%;
    }
  }

  .welfare__title {
    display: block;
  }

  .welfare__button-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -12px;
    margin-top: 12px;

    & > * {
      margin-left: 12px;
      margin-bottom: 12px;
    }
  }

  .software__button-list,
  .atc__button-list,
  .machine__button-list {
    margin-top: 12px;
    margin-left: -12px;

    & > * {
      margin-left: 12px;
      margin-bottom: 12px;
    }
  }
`;

const PopupBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > * + * {
    margin-top: 12px;
  }
`;

const PopupDetailInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${COLOR_SYSTEM.get("Gray")[10]};

  & > * + * {
    margin-top: 8px;
  }
`;

const PopupDetailInformationItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  .title {
    min-width: 60px;
  }
  text-align: left;
`;

const PopupFooter = styled.div`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

const NaverMap = styled.div`
  margin-top: 20px;

  #map {
    height: 270px;
    border-radius: 12px;
    margin: 12px auto 8px auto;

    @media screen and (min-width: 320px) {
      width: 256px;
    }

    @media screen and (min-width: 375px) {
      width: 340px;
    }

    @media screen and (min-width: 425px) {
      width: 380px;
    }

    @media screen and (min-width: 500px) {
      width: 460px;
    }

    @media screen and (min-width: 600px) {
      width: 525px;
    }
  }
`;

export {
  MainWrapper,
  SectionWrapper,
  PopupBody,
  PopupDetailInformationContainer,
  PopupDetailInformationItem,
  PopupFooter,
  NaverMap,
};
