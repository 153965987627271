import { Notification } from "./../../models/Model.Notification";

export interface NotificationState {
  notifications: Notification[];
  checked: string;
  notiCount: number;
  loading: boolean;
  end: boolean;

  chatUnreadCount: number;
}

export enum NotificationActionTypes {
  NOTIFICATION_LOAD = "@@noti/NOTIFICATION_LOAD",
  NOTIFICATION_CHECKED_TIME = "@@noti/NOTIFICATION_CHECKED_TIME",
  NOTIFICATION_CHECKED_TIME_INIT = "@@noti/NOTIFICATION_CHECKED_TIME_INIT",
  NOTIFICATION_CHECK_ITEM = "@@noti/NOTIFICATION_CHECK_ITEM",
  NOTIFICATION_REFRESH = "@@noti/NOTIFICATION_REFRESH",
  NOTIFICATION_REQUEST_LOAD = "@@noti/NOTIFICATION_REQUEST_LOAD",
  NOTIFICATION_SUCCESS_LOAD = "@@noti/NOTIFICATION_SUCCESS_LOAD",
  NOTIFICATION_ERROR_LOAD = "@@noti/NOTIFICATION_ERROR_LOAD",
  NOTIFICATION_REQUEST_CHECK_ITEM = "@@noti/NOTIFICATION_REQUEST_CHECK_ITEM",
  NOTIFICATION_SUCCESS_CHECK_ITEM = "@@noti/NOTIFICATION_SUCCESS_CHECK_ITEM",
  NOTIFICATION_ERROR_CHECK_ITEM = "@@noti/NOTIFICATION_ERROR_CHECK_ITEM",
  NOTIFICATION_CHAT_UNREAD_COUNT_LOAD = "@@noti/NOTIFICATION_CHAT_UNREAD_COUNT_LOAD",
  NOTIFICATION_CHAT_UNREAD_COUNT_SET = "@@noti/NOTIFICATION_CHAT_UNREAD_COUNT_SET",
}
