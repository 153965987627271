import React, { Component } from "react";
import "./../BoardManage.css";
import "./../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonActionSheet,
  IonCheckbox,
} from "@ionic/react";
import { fetchAPI } from "./../../utils/APIUtil";
import * as API from "./../../API.json";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../utils/LogUtil";
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from "../../models/Model.User";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import {
  BoardContent,
  BoardInfo,
  BoardAttribute,
  QNA_PRESET,
  POST_PRESET,
  DISCUSS_PRESET,
  NOTICE_PRESET,
  COMMERCIAL_PRESET,
  CHALLENGE_PRESET,
} from "../../models/Model.Board";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // ES6
import ViewerBody from "../../components/ViewerBody";
import { Keyword } from "../../models/Model.Medicine";
import BoardListItem from "../../components/BoardListItem";
import Mention from "quill-mention";
import MagicUrl from "quill-magic-url";
import { isThisMonth } from "date-fns";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { UIPopupType } from "../../store/ui/types";

const ADMIN_API = {
  ADD_BOARD: { method: "post", path: "/admin/board/board", contentType: "application/json" },
  UPDATE_BOARD: { method: "put", path: "/admin/board/board", contentType: "application/json" },
  UPDATE_BOARD_ID: { method: "put", path: "/admin/board/id", contentType: "application/json" },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: () => void;
    board: BoardInfo;
  };

type State = {
  id: string;
  idEdit: boolean;
  idWarning: boolean;
  ids: string;
  name: string;
  fullname: string;
  type: number;
  level: number;
  order: number;
  attribute: string;
  edited: boolean;
};

class BoardEdit extends Component<Props, State> {
  state = {
    id: "0",
    idEdit: false,
    idWarning: false,
    ids: "",
    name: "",
    fullname: "",
    type: 0,
    level: 0,
    order: 0,
    attribute: "",
    edited: false,
  };

  constructor(props: Props) {
    super(props);
    if (props.board) {
      this.state = { ...this.state, ...props.board, id: this.props.board.id.toString() };
    }
  }

  componentWillUpdate(nextProps, nextState) {
    log(LogLevel.UI_LIFECYCLE, "BoardEdit:componentWillUpdate");
    if (
      (nextProps.board && !this.props.board) ||
      (nextProps.board && this.props.board && nextProps.board.id != this.props.board.id)
    ) {
      this.setState({
        ...nextProps.board,
        edited: false,
        id: nextProps.board.id.toString(),
        idEdit: false,
        idWarning: false,
      });
    }
  }

  onDone = async () => {
    if (this.state.idWarning) {
      this.props.toastPopup.show("ID가 유효하지 않습니다.");
      return;
    }

    if (this.props.board.id) {
      // Update Board Info

      let id = parseInt(this.state.id);
      if (!id) {
        this.props.toastPopup.show("ID가 유효하지 않습니다.");
        return;
      }

      let board: BoardInfo = {
        id,
        ids: this.state.ids,
        name: this.state.name,
        fullname: this.state.fullname,
        type: this.state.type,
        level: this.state.level,
        order: this.state.order,
        attribute: this.state.attribute,
      };

      let result;
      if (this.props.board.id != id) {
        try {
          result = await fetchAPI(
            ADMIN_API.UPDATE_BOARD_ID,
            "",
            null,
            { idFrom: this.props.board.id, idTo: id },
            getGlobal(GlobalKey.TOKEN)
          );
          if (!result || result.error) {
            this.props.toastPopup.show("ID Update가 실패했습니다.");
            return;
          }
        } catch (e) {
          this.props.toastPopup.show("ID Update가 실패했습니다.");
          return;
        }
      }

      try {
        result = await fetchAPI(ADMIN_API.UPDATE_BOARD, "", null, board, getGlobal(GlobalKey.TOKEN));
        log(LogLevel.UI_DATA_LOAD, "BoardEdit:onDone", result);
        if (!result || result.error) {
          this.props.toastPopup.show("Update가 실패했습니다.");
          return;
        } else {
          this.props.onDone();
        }
      } catch (e) {
        this.props.toastPopup.show("Update가 실패했습니다.");
        log(LogLevel.UI_EXCEPTION, "BoardEdit:onDone", e);
        return;
      }
    } else {
      // Add Board Info

      let board: BoardInfo = {
        ids: this.state.ids,
        name: this.state.name,
        fullname: this.state.fullname,
        type: this.state.type,
        level: this.state.level,
        order: this.state.order,
        attribute: this.state.attribute,
      };

      try {
        let result = await fetchAPI(ADMIN_API.ADD_BOARD, "", null, board, getGlobal(GlobalKey.TOKEN));
        if (!result || result.error) {
          this.props.toastPopup.show("Add가 실패했습니다.");
          return;
        } else {
          this.props.onDone();
        }
      } catch (e) {
        this.props.toastPopup.show("Add가 실패했습니다.");
        return;
      }
    }

    // let lastKeywords = [];
    // if(this.state.content && this.state.content.keywords){
    //   lastKeywords = [...this.state.content.keywords];
    // }
    // let updateContent : BoardContent = {
    //   id: this.state.content.id,
    //   bodyHtml: this.state.content.bodyHtml,
    //   keywords: [...lastKeywords, ...this.state.selectedKeywords],
    //   subject : this.state.title,
    //   managedAt: "1",
    // }

    // log(LogLevel.UI_ACTION, "BoardEdit Update", updateContent)
    // let result = await fetchAPI(API.BOARD_UPDATE, "", null, updateContent, getGlobal(GlobalKey.TOKEN))
    // if(result && !result.error){
    //   this.props.onDone();
    // }
    // else{
    //   log(LogLevel.UI_EXCEPTION, "BoardEdit Update failed", result);
    // }
  };

  onChangeId = (e) => {
    log(LogLevel.UI_LIFECYCLE, "BoardEdit:onChangeId", this.state.idEdit);
    if (!this.state.idEdit) return;

    let idWarning = true;
    let idStr = e.target.value;
    try {
      let idNum = Number(idStr);
      log(LogLevel.UI_LIFECYCLE, "BoardEdit:onChangeId", idNum);

      if (idNum >= 0) idWarning = false;
    } catch (e) {}

    this.setState({ id: idStr, edited: true, idWarning });
  };

  onChangeType = (e) => {
    log(LogLevel.UI_LIFECYCLE, "BoardEdit:onChangeType", this.state.idEdit);

    let type = 0;
    let typeStr = e.target.value;
    try {
      let typeNum = Number(typeStr);

      if (typeNum >= 0) type = typeNum;
    } catch (e) {}

    this.setState({ type, edited: true });
  };

  onChangeLevel = (e) => {
    log(LogLevel.UI_LIFECYCLE, "BoardEdit:onChangeLevel", this.state.idEdit);

    let level = 0;
    let typeStr = e.target.value;
    try {
      let typeNum = Number(typeStr);

      if (typeNum >= 0) level = typeNum;
    } catch (e) {}

    this.setState({ level, edited: true });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardEdit:render", this.state);
    if (!this.props.board) return null;

    let options = [
      BoardAttribute.IS_QNA,
      BoardAttribute.IS_DISCUSSION,
      BoardAttribute.HAS_TITLE,

      BoardAttribute.CAN_REPLY,
      BoardAttribute.CAN_SHARE,
      BoardAttribute.CAN_LIKE_ON_POST,
      BoardAttribute.CAN_LIKE_ON_REPLY,
      BoardAttribute.CAN_DISLIKE,
      BoardAttribute.CAN_METOO,
      BoardAttribute.CAN_COMMENT_ON_POST,
      BoardAttribute.CAN_COMMENT_ON_REPLY,
      BoardAttribute.CAN_ANONYMOUS,

      BoardAttribute.UI_VIEWER_PROFILE_ON_POST,
      BoardAttribute.UI_VIEWER_PROFILE_ON_REPLY,
      BoardAttribute.UI_VIEWER_TOOLBAR_ON_POST,
      BoardAttribute.UI_VIEWER_TOOLBAR_ON_REPLY,

      BoardAttribute.UI_LIST_IMAGE,
      BoardAttribute.UI_LIST_LINK,
      BoardAttribute.UI_LIST_TITLE,
      BoardAttribute.UI_LIST_BODY,
      BoardAttribute.UI_LIST_NOTICE,
      BoardAttribute.UI_LIST_FAB,
      BoardAttribute.UI_LIST_FILTER,
      BoardAttribute.UI_LIST_SORT,
      BoardAttribute.UI_LIST_PROFILE,
      BoardAttribute.UI_LIST_METOO_CNT,
      BoardAttribute.UI_LIST_REPLY_CNT,
      BoardAttribute.UI_LIST_COMMENT_CNT,
      BoardAttribute.UI_LIST_LIKE_CNT,
      BoardAttribute.UI_LIST_VIEW_CNT,
      BoardAttribute.UI_LIST_IMAGE,
      BoardAttribute.UI_LIST_BANNER,
      BoardAttribute.UI_LIST_NICE_CONTENT_BADGE,
      BoardAttribute.UI_LIST_BIG_IMAGE,
      BoardAttribute.UI_LIST_COMMENT,
      BoardAttribute.UI_LIST_TOOLBAR,
    ];
    return (
      <div className="admin-content-manage-container">
        <div className="common-container-row common-flex-align-center">
          <div> Order : </div>
          <input
            className={"admin-grow" + (this.state.idWarning ? " common-color-caution" : "")}
            type="number"
            placeholder="ID"
            value={this.state.order.toString()}
            onChange={(e) => {
              this.setState({ order: parseInt(e.target.value), edited: true });
            }}
          />
        </div>
        <div className="common-container-row common-flex-align-center">
          <div> ID : </div>
          <input
            className={"admin-grow" + (this.state.idWarning ? " common-color-caution" : "")}
            placeholder="ID"
            value={this.state.id}
            onChange={this.onChangeId}
          />
          <IonCheckbox
            disabled={this.props.board.id == 0}
            checked={this.state.idEdit}
            onClick={(e) => {
              this.setState({ idEdit: !this.state.idEdit });
            }}
          />
        </div>
        <div className="common-container-row common-flex-align-center">
          <div> IDS : </div>
          <IonInput
            placeholder="IDS"
            value={this.state.ids}
            onIonChange={(e) => {
              this.setState({ ids: e.detail.value, edited: true });
            }}
          />
        </div>
        <div className="common-container-row common-flex-align-center">
          <div> Name : </div>
          <IonInput
            placeholder="name"
            value={this.state.name}
            onIonChange={(e) => {
              this.setState({ name: e.detail.value, edited: true });
            }}
          />
        </div>
        <div className="common-container-row common-flex-align-center">
          <div> Full Name : </div>
          <IonInput
            placeholder="fullname"
            value={this.state.fullname}
            onIonChange={(e) => {
              this.setState({ fullname: e.detail.value, edited: true });
            }}
          />
        </div>
        <div className="common-container-row common-flex-align-center">
          <div> Level: </div>
          <IonInput
            type="number"
            placeholder="type"
            value={this.state.level.toString()}
            onIonChange={this.onChangeLevel}
          />
        </div>
        <div className="common-container-row common-flex-align-center">
          <div> Type (Hide): </div>
          <IonInput
            type="number"
            placeholder="type"
            value={this.state.type.toString()}
            onIonChange={this.onChangeType}
          />
        </div>
        <div className="common-flex-row">
          <div className="admin-margin-right"> Attributes </div>
          <div
            className="common-color-highlight admin-margin-right"
            onClick={(e) => {
              this.setState({ edited: true, attribute: QNA_PRESET });
            }}
          >
            {" "}
            &lt;Q&A&gt;{" "}
          </div>
          <div
            className="common-color-highlight admin-margin-right"
            onClick={(e) => {
              this.setState({ edited: true, attribute: POST_PRESET });
            }}
          >
            {" "}
            &lt;자유게시판&gt;{" "}
          </div>
          <div
            className="common-color-highlight admin-margin-right"
            onClick={(e) => {
              this.setState({ edited: true, attribute: DISCUSS_PRESET });
            }}
          >
            {" "}
            &lt;이슈토론&gt;{" "}
          </div>
          <div
            className="common-color-highlight admin-margin-right"
            onClick={(e) => {
              this.setState({ edited: true, attribute: NOTICE_PRESET });
            }}
          >
            {" "}
            &lt;공지사항&gt;{" "}
          </div>
          <div
            className="common-color-highlight admin-margin-right"
            onClick={(e) => {
              this.setState({ edited: true, attribute: COMMERCIAL_PRESET });
            }}
          >
            {" "}
            &lt;카드뉴스&gt;{" "}
          </div>
          <div
            className="common-color-highlight admin-margin-right"
            onClick={(e) => {
              this.setState({ edited: true, attribute: CHALLENGE_PRESET });
            }}
          >
            {" "}
            &lt;챌린지&gt;{" "}
          </div>
        </div>
        <div className="common-container-row-wrap">
          {Object.keys(BoardAttribute).map((item: any, index) => (
            <div className="common-flex-row" key={index.toString()}>
              <IonCheckbox
                checked={this.state.attribute.includes(BoardAttribute[item])}
                onClick={(e) => {
                  this.setState({
                    edited: true,
                    attribute: this.state.attribute.includes(BoardAttribute[item])
                      ? this.state.attribute.replace(BoardAttribute[item], "")
                      : this.state.attribute + BoardAttribute[item],
                  });
                }}
              />
              <div className="admin-margin-right">{item}</div>
            </div>
          ))}
        </div>
        <IonButton disabled={!this.state.edited && !this.state.idWarning} expand="full" onClick={this.onDone}>
          반영
        </IonButton>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BoardEdit);
