import React, { Component } from 'react';
import './DynamicChecker.css';
import { IonSpinner, IonTitle, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';

export enum CheckStatus {
  UNCHECKED = 0,
  CHECKING = 1,
  CHECKED = 2,
  WARNING = 3,
}

/**
 * status: 0:unchecked 1:checking 2:checked 3:warning
 */
type Props = {
  status:CheckStatus
};

class DynamicChecker extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    let status = this.props.status;
    switch(status){
      case CheckStatus.UNCHECKED:
        // return null;
        return(
          <div className="dynamic-checker-unchecked" >
            <IonIcon name="checkmark"/>
          </div>
        );
      case CheckStatus.CHECKING:
        return(
          <div className="dynamic-checker-unchecking" >
            <IonSpinner name="lines-small"/>
          </div>
        );
      case CheckStatus.CHECKED:
        return(
          <div className="dynamic-checker-checked" >
            <IonIcon name="checkmark"/>
          </div>
        );      
      case CheckStatus.WARNING:
        return null;
        // return(
        //   <div className="dynamic-checker-warning" >
        //     <IonIcon name="warning"/>
        //   </div>
        // );   
      default:
        return(
          <div className="dynamic-checker-unchecked" >
            <IonIcon name="checkmark"/>
          </div>
        );

    }
  }
}

export default DynamicChecker;