import React from "react";
import useRipple from "../../../hooks/useRipple";
import { COLOR_SYSTEM } from "../../design/design-system";
import { ButtonType } from "../Button";
import Icon from "../Icon";
import { saveState } from "../../../api/index";
import {
  IconFloatingButtonWrapper,
  IconWithTextFloatingButtonWrapper,
  TextFloatingButtonWrapper,
} from "./style";

export type FloatingButtonSize = "XLarge" | "Large" | "Medium";
export type FloatingButtonUsage = "Primary" | "Secondary" | "Tertiary";

export interface FloatingButtonProps {
  /** 버튼 크기 */
  size: FloatingButtonSize;
  /** 버튼 타입 */
  type: ButtonType;
  /** 버튼 색상 */
  color: FloatingButtonUsage;
  /** 버튼 안에 들어가는 content */
  children?: React.ReactNode | string;
  /** 아이콘 위치 여부(택스트 앞) */
  left?: boolean;
  /** 아이콘 위치 여부(택스트 뒤) */
  right?: boolean;
  /** 아이콘 */
  icon?: string;
  /** 클릭 이벤트 핸들러 */
  onClick: (params?: any) => any;
  /** 스타일 커스터마이징 */
  style?: React.CSSProperties;
}

const FloatingButton = ({
  size,
  type,
  color,
  children,
  left,
  right,
  icon,
  onClick,
  style,
}: FloatingButtonProps) => {
  const [coords, isRippling, diameter, onRippleEffect] = useRipple({
    x: -1,
    y: -1,
  });

  const setIconSize = () => {
    switch (size) {
      case "XLarge":
      case "Large":
        return 24;
      case "Medium":
      default:
        return 20;
    }
  };

  const setIconColor = () => {
    switch (color) {
      case "Primary":
      case "Secondary":
        return COLOR_SYSTEM.get("Gray")[0];
      case "Tertiary":
      default:
        return COLOR_SYSTEM.get("Gray")[400];
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onRippleEffect(e);
    if (onClick) onClick(e);
  };

  const renderByType = () => {
    switch (type) {
      case "Icon":
        return (
          <IconFloatingButtonWrapper
            color={color}
            size={size}
            buttonType={type}
            onClick={handleClick}
            style={style}
          >
            {icon && (
              <Icon
                width={setIconSize()}
                height={setIconSize()}
                name={icon}
                fill={setIconColor()}
              />
            )}
            {!icon && children}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </IconFloatingButtonWrapper>
        );
      case "Text":
        return (
          <TextFloatingButtonWrapper
            color={color}
            size={size}
            buttonType={type}
            onClick={handleClick}
            style={style}
          >
            {children}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </TextFloatingButtonWrapper>
        );
      case "IconWithText":
        return (
          <IconWithTextFloatingButtonWrapper
            color={color}
            size={size}
            buttonType={type}
            left={left}
            right={right}
            onClick={handleClick}
            style={style}
          >
            {left && icon && (
              <Icon
                width={setIconSize()}
                height={setIconSize()}
                name={icon}
                fill={setIconColor()}
                className="left-button__icon"
              />
            )}
            {children}
            {right && icon && (
              <Icon
                width={setIconSize()}
                height={setIconSize()}
                name={icon}
                fill={setIconColor()}
                className="right-button__icon"
              />
            )}
            {isRippling && (
              <span
                className="ripple"
                style={{
                  left: coords.x,
                  top: coords.y,
                  width: diameter,
                  height: diameter,
                }}
              />
            )}
          </IconWithTextFloatingButtonWrapper>
        );
      default:
        return;
    }
  };

  return <>{renderByType()}</>;
};

export default FloatingButton;
