import { setGlobal, GlobalKey, getGlobal } from "./GlobalUtil";
import { log, LogLevel } from "./LogUtil";

export function getDateTimeString(dateString, option: any = {}) {
  if (!dateString) return "";
  let date = new Date(dateString);
  let nowTick = Date.now();
  let now = new Date(nowTick);

  var pastMinute = Math.floor((nowTick - date.valueOf()) / 60000);
  // log(LogLevel.NONE, "getDateTimeString pastMinute", pastMinute);
  if (pastMinute <= 0) return "방금";
  if (pastMinute < 60) return pastMinute + "분 전";
  var pastHours = Math.floor(pastMinute / 60);
  // log(LogLevel.NONE, "getDateTimeString pastHours", pastHours);
  if (pastHours < 12) return pastHours + "시간 전";

  let __dateS = _getDateString(date, now);
  let __timeS = _getTimeString(date);
  if (__dateS && __dateS != "어제" && option.dayOnly) return __dateS;
  return __dateS + __timeS;
}

function _getDateString(date, now = new Date(Date.now())) {
  //date
  var dY = date.getFullYear();
  var dM = date.getMonth();
  var dD = date.getDate();
  // now
  var nY = now.getFullYear();
  var nM = now.getMonth();
  var nD = now.getDate();
  // yesterday
  var yesterday = new Date(now.valueOf() - 1000 * 60 * 60 * 24);
  var yY = yesterday.getFullYear();
  var yM = yesterday.getMonth();
  var yD = yesterday.getDate();

  if (dY === nY && dM === nM && dD === nD) {
    return "";
  }

  if (dY === yY && dM === yM && dD === yD) {
    return "어제 ";
  }

  var dateString = "";
  if (nY !== dY) {
    dateString += dY + "년 ";
  }
  dateString += dM + 1 + "월 " + dD + "일 ";

  return dateString;
}

function _getTimeString(date) {
  // log(LogLevel.NONE, "_getTimeString : " + date.toString())
  var TimeType, hour, minutes, rvalue;

  // Getting current hour from Date object.
  hour = date.getHours();

  // Checking if the Hour is less than equals to 11 then Set the Time format as AM.
  if (hour <= 11) {
    TimeType = "오전";
  } else {
    // If the Hour is Not less than equals to 11 then Set the Time format as PM.
    TimeType = "오후";
  }

  // IF current hour is grater than 12 then minus 12 from current hour to make it in 12 Hours Format.
  if (hour > 12) {
    hour = hour - 12;
  }

  // If hour value is 0 then by default set its value to 12, because 24 means 0 in 24 hours time format.
  if (hour === 0) {
    hour = 12;
  }

  // Getting the current minutes from date object.
  minutes = date.getMinutes();

  // Checking if the minutes value is less then 10 then add 0 before minutes.
  if (minutes < 10) {
    minutes = "0" + minutes.toString();
  }

  // Adding all the variables in fullTime variable.
  rvalue =
    TimeType.toString() + " " + hour.toString() + ":" + minutes.toString();

  return rvalue;
}

export function getDateTimeStringShort(dateString) {
  if (!dateString) return "";
  let date = new Date(dateString);
  let nowTick = Date.now();
  let now = new Date(nowTick);

  var pastMinute = Math.floor((nowTick - date.valueOf()) / 60000);
  // log(LogLevel.NONE, "getDateTimeString pastMinute", pastMinute);
  if (pastMinute === 0) return "방금";
  if (pastMinute < 60) return pastMinute + "분 전";
  var pastHours = Math.floor(pastMinute / 60);
  // log(LogLevel.NONE, "getDateTimeString pastHours", pastHours);
  if (pastHours < 12) return pastHours + "시간 전";

  let rvalue = _getDateStringShort(date, now);

  if (!rvalue || rvalue.length <= 5) rvalue += " " + _getTimeStringShort(date);
  return rvalue;
}

function _getDateStringShort(date, now = new Date(Date.now())) {
  //date
  var dY = date.getFullYear();
  var dM = date.getMonth();
  var dD = date.getDate();
  // now
  var nY = now.getFullYear();
  var nM = now.getMonth();
  var nD = now.getDate();
  // yesterday
  var yesterday = new Date(now.valueOf() - 1000 * 60 * 60 * 24);
  var yY = yesterday.getFullYear();
  var yM = yesterday.getMonth();
  var yD = yesterday.getDate();

  if (dY === nY && dM === nM && dD === nD) {
    return "";
  }

  if (dY === yY && dM === yM && dD === yD) {
    return "어제 ";
  }

  var dateString = "";
  // if(nY !== dY){
  dateString += dY + ".";
  // }
  dateString +=
    (dM + 1).toString().padStart(2, "0") + "." + dD.toString().padStart(2, "0");

  return dateString;
}

function _getTimeStringShort(date) {
  // log(LogLevel.NONE, "_getTimeString : " + date.toString())
  var TimeType, hour, minutes, rvalue;

  // Getting current hour from Date object.
  hour = date.getHours();

  // Getting the current minutes from date object.
  minutes = date.getMinutes();

  // Checking if the minutes value is less then 10 then add 0 before minutes.
  if (minutes < 10) {
    minutes = "0" + minutes.toString();
  }

  // Adding all the variables in fullTime variable.
  rvalue = hour.toString() + ":" + minutes.toString();

  return rvalue;
}

export function getDateTimeDifferent(dateString) {
  if (!dateString) return "";
  let date = new Date(dateString);
  let dateTick = date.valueOf();
  let nowTick = Date.now();

  var pastMinute = Math.floor((nowTick - dateTick) / 60000);
  // log(LogLevel.NONE, "getDateTimeString pastMinute", pastMinute);
  if (pastMinute <= 0) return "방금";
  if (pastMinute < 60) return pastMinute + "분 전";
  var pastHours = Math.floor(pastMinute / 60);
  // log(LogLevel.NONE, "getDateTimeString pastHours", pastHours);
  if (pastHours < 24) return pastHours + "시간 전";
  var pastDays = Math.floor(pastHours / 24);

  if (pastDays < 30) return pastDays + "일 전";
  var pastMonth = Math.floor(pastDays / 30);

  if (pastDays < 365) return pastMonth + "개월 전";

  var pastYears = Math.floor(pastDays / 365);
  return pastYears + "년 전";
}

export interface DateStringGenerateOption {
  date?: string; // anchor date
  dateDays?: number; // days to add to anchor date

  day?: number; // day to set
  month?: number; // month to set
  year?: number; // year to set

  days?: number; // days to add
  weeks?: number; // weeks to add
  months?: number; // months to add
  years?: number; // years to add

  byWeek?: boolean; // anchor to the first day of week
  weekStarts?: number; // start of week, 0:Sunday 1:Monday
  byMonth?: boolean; // anchor to the first day of month

  year2Digit?: boolean; // display years with 2 digits
  withoutYear?: boolean; // without year string
  showTime?: boolean; // with time (HH/MM)
  separator?: string; // year-month-day separator (default:"-")
  timeSeparator?: string; // time separator (default:"")

  trim?: boolean; // trim  0 string -> 01/03 => 1/3
}

export function getDateStringFromToday(options: DateStringGenerateOption = {}) {
  let date;
  if (options.date) {
    date = new Date(options.date);
    date.setHours(date.getHours() + 9);
  } else if (options.dateDays) {
    date = new Date((options.dateDays * 24 + 9) * 60 * 60 * 1000);
  } else {
    date = new Date(Date.now() + 9 * 60 * 60 * 1000);
  }
  date = new Date(date.toISOString().substring(0, 10));

  if (options.year) {
    date.setFullYear(options.year);
  }

  if (options.month) {
    date.setMonth(options.month - 1);
  }

  if (options.day) {
    date.setDate(options.day);
  }

  // log(LogLevel.ALL, "getDateStringFromToday 1", date, date.toISOString().substring(0,10));
  if (options.years) date.setUTCFullYear(date.getUTCFullYear() + options.years);
  if (options.months) date.setMonth(date.getMonth() + options.months);
  if (options.weeks) date.setDate(date.getDate() + options.weeks * 7);
  if (options.days) date.setDate(date.getDate() + options.days);

  if (options.byWeek)
    date.setDate(
      date.getDate() -
        date.getDay() +
        (options.weekStarts ? options.weekStarts : 0)
    );
  if (options.byMonth) date.setDate(1);
  // log(LogLevel.ALL, "getDateStringFromToday 2", date, date.toISOString().substring(0,10));

  // log(LogLevel.ALL, "getDateStringFromToday 3", date, date.toISOString().substring(0,10));

  let dateFullString = date.toISOString();
  let rvalue = dateFullString.substring(0, 10);
  if (options.trim) rvalue = rvalue.replace(/-0/gi, "-");

  if (options.withoutYear) rvalue = rvalue.substring(5);
  else if (options.year2Digit) rvalue = rvalue.substring(2);

  if (options.separator || options.separator == "")
    rvalue = rvalue.replace(/-/gi, options.separator);

  if (options.showTime) {
    if (!options.timeSeparator) options.timeSeparator = "";
    let timevalue = dateFullString.substring(11, 19);
    if (options.timeSeparator)
      timevalue = timevalue.replace(/:/gi, options.timeSeparator);

    rvalue += " " + timevalue;
  }
  return rvalue;
}

export function convertDateToDays(date: string) {
  let dateObj = new Date(date);
  return Math.floor(dateObj.getTime() / (1000 * 60 * 60 * 24));
}

export const SERVICE_START_DATE = "2019-07-10";

export function getTimerString(seconds) {
  if (!seconds) return "00:00";
  // log(LogLevel.NONE, "_getTimeString : " + date.toString())
  var minute = Math.floor(seconds / 60);
  var second = Math.floor(seconds % 60);

  // Adding all the variables in fullTime variable.
  let rvalue = minute.toString() + ":" + second.toString().padStart(2, "0");

  return rvalue;
}

export const TimerId = {
  TIMER_MAIN: 0,
  TIMER_SUB: 1,
};

export function timerStart(timerId = TimerId.TIMER_MAIN) {
  // log(LogLevel.NONE, "_getTimeString : " + date.toString())
  setGlobal(GlobalKey.TIMER + timerId, Date.now());
}

export function timerLap(timerId = TimerId.TIMER_MAIN) {
  let now = Date.now();
  let last = getGlobal(GlobalKey.TIMER + timerId);
  setGlobal(GlobalKey.TIMER + timerId, now);
  return now - last;
}

export function timerStop(timerId = TimerId.TIMER_MAIN) {
  return Date.now() - getGlobal(GlobalKey.TIMER + timerId);
}

export function getYearsPeriodString(months: number) {
  return Math.floor((months * 10) / 12) / 10 + "년";
}

export function getDayDiff(from: string, to: string = "") {
  let dateFrom = new Date(from);
  dateFrom.setHours(0);
  dateFrom.setMinutes(0);
  dateFrom.setSeconds(0);

  let dateTo;
  if (to) {
    dateTo = new Date(to);
  } else {
    dateTo = new Date(Date.now());
  }
  dateTo.setHours(0);
  dateTo.setMinutes(0);
  dateTo.setSeconds(1);

  let fromValue = dateFrom.valueOf();
  let toValue = dateTo.valueOf();
  log(
    LogLevel.ALL,
    "getDateStringFromToday 1",
    dateFrom,
    dateTo,
    toValue - fromValue,
    from,
    to
  );

  let days = Math.floor((toValue - fromValue) / (24 * 60 * 60 * 1000));

  return days;
}

export function extractTimeToJTag(str: string) {
  if (!str) return str;
  return str.replace(
    /(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)([0-5]?\d)/gi,
    (match) => "<j>" + match + "</j>"
  );
}

export function extractTimeValue(str) {
  let rvalue = 0;
  try {
    let strs = str.split(":");
    if (strs && strs.length) {
      for (let i = 0; i < strs.length; i++) {
        rvalue = rvalue * 60 + parseInt(strs[i]);
      }
    }
  } catch (e) {
    return 0;
  }
  return rvalue;
}
