import * as allinones from "./actions";
import { ActionType, getType } from "typesafe-actions";
import { AllInOneState } from "./types";
import { BannerInfo } from "../../models/Model.Banner";
import { log, LogLevel } from "../../utils/LogUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import {
  AllInOnePartnerInfo,
  AllInOnePartnerLevel,
  CompanyType,
  UserWorkType,
} from "../../models/Model.User";

const defaultState: AllInOneState = {
  partnerListLoading: false,
  partnerList: null,
  partners: {},
  banners: null,
};

export default (
  state = defaultState,
  action: ActionType<typeof allinones>
): AllInOneState => {
  switch (action.type) {
    case getType(allinones.fetchPartnerList.request):
      log(LogLevel.REDUX, "Reducer: fetchPartnerList.request", action, state);
      return { ...state, partnerListLoading: true };
    case getType(allinones.fetchPartnerList.success):
      if (!action.payload || !action.payload.length) {
        return state;
      }

      let partnerList = action.payload.map(
        (item: AllInOnePartnerInfo, index) => {
          return item.id;
        }
      );
      let partners = { ...state.partners };
      for (let i = 0; i < action.payload.length; i++) {
        partners[action.payload[i].id] = {
          ...state.partners[action.payload[i].id],
          ...action.payload[i],
        };
      }
      log(
        LogLevel.REDUX,
        "Reducer: fetchPartnerList.success",
        action,
        state,
        partners,
        partnerList
      );

      return { ...state, partnerList, partners, partnerListLoading: false };
    case getType(allinones.fetchBanners.success):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchBanners.success", action, state);
      if (action.payload) {
        let banners: BannerInfo[] = action.payload;
        return { ...state, banners };
      } else {
        return { ...state, banners: [] };
      }
    case getType(allinones.fetchBanners.failure):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchBanners.failure", action, state);
      return { ...state, banners: [] };

    default:
      return state;
  }

  return state;
};
