import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheet, { BottomSheetProps } from "../../molecules/BottomSheet/BottomSheet";
import {
  FinishPostPopupCadidateItem,
  FinishPostPopupCadidates,
  FinishPostPopupFooter,
  FinishPostPopupMain,
} from "./style";
import NoListProfile from "../../../assets/image/no-list-profile.png";
import { JobOfferResult, JobOfferInfo } from "../../../models/Model.JobPost";

interface PharmacistListBottomSheetProps extends BottomSheetProps {
  passed: number[];
  setPassed: React.Dispatch<React.SetStateAction<number[]>>;
  jobPost: JobOfferInfo;
  onFinishSurvey: (jobOfferResult: JobOfferResult) => void;
}

const PharmacistListBottomSheet: React.FC<PharmacistListBottomSheetProps> = ({
  passed,
  setPassed,
  jobPost,
  active,
  onClose,
  onFinishSurvey,
}) => {
  const handleSelectPharmacist = (chatId: number) => () => {
    let newPass = [...passed];
    const index = newPass.indexOf(chatId);

    if (index >= 0) {
      newPass.splice(index, 1);
      if (newPass.length === 0) {
        newPass = [-1];
      }
    } else {
      if (newPass.includes(-1)) newPass = [];
      newPass.push(chatId);
    }

    setPassed(newPass);
  };

  const handleSelectNoList = () => {
    const newPass = [-1];
    setPassed(newPass);
  };

  return (
    <BottomSheet
      active={active}
      onClose={() => {
        onClose();
        setPassed([]);
      }}
      height={"70vh"}
      style={{ padding: "40px 0 0 0" }}
    >
      <FinishPostPopupMain page={"List"}>
        <Text element="h3" textType="H3" color={COLOR_SYSTEM.get("Gray")[800]} className="popup__title">
          어떤 약사님을 채용하셨나요?
        </Text>
        <FinishPostPopupCadidates>
          {jobPost &&
            jobPost.chats &&
            jobPost.chats.map((chat) => (
              <FinishPostPopupCadidateItem
                key={chat.id}
                selected={passed.includes(chat.id)}
                onClick={handleSelectPharmacist(chat.id)}
              >
                <img src={chat.profileUrl} className="profile" />
                <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]} className="list-item__name">
                  {chat.name} 약사
                </Text>
                <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[400]} className="list-item__last-message">
                  마지막 대화 {chat.lastMessagedAt.substring(0, 10).replace(/-/gi, ".")}
                </Text>
              </FinishPostPopupCadidateItem>
            ))}
          <FinishPostPopupCadidateItem className="no-list" selected={passed.includes(-1)} onClick={handleSelectNoList}>
            <img src={NoListProfile} className="profile" />
            <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
              목록에 없음
              <br />
              <Text textType="InputMedium" color={COLOR_SYSTEM.get("Gray")[400]}>
                (채팅이 아닌 문자나 전화로 연락)
              </Text>
            </Text>
          </FinishPostPopupCadidateItem>
        </FinishPostPopupCadidates>
      </FinishPostPopupMain>
      <FinishPostPopupFooter>
        <Button
          color="Primary"
          size="Large"
          type="Text"
          variant="Contained"
          disabled={!passed.length}
          disabledColor={!passed.length}
          onClick={() => {
            onClose();
            onFinishSurvey(JobOfferResult.Success);
          }}
        >
          선택
        </Button>
      </FinishPostPopupFooter>
    </BottomSheet>
  );
};

export default PharmacistListBottomSheet;
