// import { BoardContentSummary } from "../models/Model.Board";
// type ParserState =
//   | "START"
//   | "OBJECT"
//   | "ARRAY"
//   | "KEY"
//   | "COLON"
//   | "VALUE"
//   | "COMMA";

import { head } from "lodash";
import { BoardContentSummary } from "../models/Model.Board";
import { SERVER_ADDRESS, SERVER_API_PORT } from "./../config.json";
import { GlobalKey, getGlobal } from "./GlobalUtil";
import { LogLevel, log } from "./LogUtil";
import EventSource from "eventsource";
import parse from "html-react-parser";
import { JsonParser } from "./JsonUtil";

export class BoardSummaryFetcher {
  onRecieve: (summary: BoardContentSummary) => void = null;
  onDone: (summary: BoardContentSummary) => void = null;
  onFail: (err) => void = null;
  id: number = 0;
  parser: BoardSummaryJsonParser;
  eventSource: EventSource = null;

  constructor(id, onRecieve, onDone, onFail) {
    this.id = id;
    this.onRecieve = onRecieve;
    this.onDone = onDone;
    this.onFail = onFail;
    this.parser = new BoardSummaryJsonParser(id);

    let token = getGlobal(GlobalKey.TOKEN, true);
    if (token) {
      let headers = {
        Authorization: "Bearer " + token,
      };

      this.eventSource = new EventSource(
        `${SERVER_ADDRESS + SERVER_API_PORT}/board/summary/${this.id}`,
        { headers: headers }
      );
      this.eventSource.onmessage = this._onMessage;
      this.eventSource.onerror = this._onError;
    }
  }

  private _onMessage = (event) => {
    if (event.type !== "message") {
      return;
    }
    const data = JSON.parse(event.data);

    if (data === "#$EndSessionSignal12345$#") {
      this.eventSource.close();
      this.eventSource = null;

      log(
        LogLevel.UI_ACTION,
        "BoardSummaryFetcher:_onMessage DONE",
        data,
        this.parser.getJsonObject(),
        this.parser.fullJsonString
      );

      if (this.onDone) {
        this.onDone(this.parser.getJsonObject());
      }
      return;
    }
    this.parser.parses(data);

    log(
      LogLevel.UI_ACTION,
      "BoardSummaryFetcher:_onMessage",
      data,
      this.parser.getJsonObject(),
      this.parser.fullJsonString
    );

    if (this.onRecieve) {
      this.onRecieve(this.parser.getJsonObject());
    }
  };

  private _onError = (err) => {
    // console.error("EventSource failed:", err);
    log(LogLevel.UI_ACTION, "BoardSummaryFetcher:_onError", err);
    this.eventSource.close();
    this.eventSource = null;
    if (this.onFail) {
      this.onFail(err);
    }
  };
}


export class BoardSummaryJsonParser extends JsonParser{
  id: number = 0;

  constructor(id) {
    super();
    this.id = id;
  }

  getJsonObject() {
    this.rootObject["id"] = this.id;
    this.rootObject["state"] = this.currentState;
    return this.rootObject;
  }

  validate() {
    let rvalue = "";
    if (this.currentState !== "FINISH") {
      log(
        LogLevel.UI_ACTION,
        "BoardSummaryJsonParser validate failed : this.currentState !== FINISH",
        this
      );
      rvalue += "currentState is not FINISH\n";
    }

    if (!this.rootObject.contentId) {
      log(
        LogLevel.UI_ACTION,
        "BoardSummaryJsonParser validate failed : no contentId",
        this
      );
      rvalue += "no contentId\n";
    }

    if (!this.rootObject.summary) {
      log(
        LogLevel.UI_ACTION,
        "BoardSummaryJsonParser validate failed : no summary",
        this
      );
      rvalue += "no summary\n";
    }

    if (!this.rootObject.summary.question) {
      log(
        LogLevel.UI_ACTION,
        "BoardSummaryJsonParser validate failed : no question on summary",
        this
      );
      rvalue += "no question on summary\n";
    }
    if (
      !this.rootObject.summary.answers ||
      Array.isArray(this.rootObject.summary.answers) === false
    ) {
      log(
        LogLevel.UI_ACTION,
        "BoardSummaryJsonParser validate failed : no answers",
        this
      );
      rvalue += "no answers\n";
    }
    return rvalue;
  }
}
