
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonFooter, IonModal, IonBackButton, IonTitle, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './Loading.css';
import logo from "./../assets/image/logo.png"
import {AuthStatus} from './../store/user/types'
import {getGlobal, GlobalKey, setGlobal} from './../utils/GlobalUtil'
import {log, LogLevel} from '../utils/LogUtil'
import {CONTRACT_VERSION} from './../config.json'

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  from? : string
};

class Loading extends Component<Props> {
  constructor(props: Props) {
    super(props);
    log(LogLevel.NONE, props);
  }

  async componentDidMount() { 
    if(this.props.user.authStatus == AuthStatus.UNKNOWN){
      let token = getGlobal(GlobalKey.TOKEN, true);
      if(token){
        log(LogLevel.UI_DATA_LOAD, "Tokens!!");
        this.props.setUserToken(token);
        this.props.loginUser();
      }else{
        log(LogLevel.UI_DATA_LOAD, "no token");
        this.props.logoutUser();
      }
    }

  }

  render() {
    if(this.props.user.authStatus == AuthStatus.AUTHENTICATED){
      log(LogLevel.UI_LIFECYCLE, "Loading : render AuthStatus.AUTHENTICATED");
      // if first login => go to welcome
      if(!this.props.user.me.guided && !getGlobal(GlobalKey.WELCOME_DONE, true))
        return <Redirect to="/welcome"/>

      if(this.props.user.me && this.props.user.me.contractVersion < CONTRACT_VERSION){
        let from, search;
        if(this.props.location.state && this.props.location.state.from){
          from = this.props.location.state.from;
          if(this.props.location.state.search)
          search += this.props.location.state.search;
        }
        return <Redirect to={{
          pathname: '/contract',
          state: { from, search }
        }}/>
      }
        
      let target="/main"
      if(this.props.location.state && this.props.location.state.from){
        target = this.props.location.state.from;
        if(this.props.location.state.search)
        target += this.props.location.state.search;
      }
      return <Redirect to={target}/>
    } else if(this.props.user.authStatus == AuthStatus.REGISTERING){
      let target="/registering"
      let from, search;
      if(this.props.location.state && this.props.location.state.from){
        from = this.props.location.state.from;
        if(this.props.location.state.search)
        search += this.props.location.state.search;
      }
      log(LogLevel.UI_LIFECYCLE, "Loading : render AuthStatus.REGISTERING", this.props.location, target);
      return <Redirect to={{
        pathname: '/registering',
        state: { from, search }
      }}/>
    } else if(this.props.user.authStatus == AuthStatus.EXPIRED){
      let from;
      if(this.props.location && this.props.location.state && this.props.location.state.from)
        from = this.props.location.state.from;
      log(LogLevel.UI_LIFECYCLE, "Loading : render AuthStatus.EXPIRED");
      return <Redirect to={{pathname:'/login', state:{from}}} />
    }
    log(LogLevel.UI_LIFECYCLE, "Loading : render ", this.props.user.authStatus);
    return (
      <div className="loading-container"/>
    );
  }
}


const mapStateToProps = (state: RootState) => ({
  user: state.user
});

const mapDispatchToProps = {
  loginUser: () => actions.user.login(),
  logoutUser: () => actions.user.logout(),
  setUserToken: (token:string) => actions.user.setToken(token),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loading));