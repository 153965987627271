import { BoardContent, BoardContentCommercialType } from "./Model.Board";
import { SeminarLecture } from "./Model.Seminar";
import { getDateStringFromToday } from "../utils/TimeUtil";

export enum SearchType {
    BOARD = 0,
    MEDICINE = 1,
    JOBPOST = 2,
}

export interface SearchHistoryInfo{
    id?: number,
    usersId?: number,
    search?: string,
    searchType?: SearchType,
    resultType?: number,
    resultId?: number,
    result?: string,
    count?: number,
}


export enum SearchKeywordType {
    NONE = 0,
    HOT = 1,
    RECOMMENDED_RESULTS = 2,
    RECOMMENDED = 3,
}

export interface SearchKeywordRelatedContentInfo{
    id?: number,
    order?: number,
    keyword?: string,
    commercialType?: BoardContentCommercialType,
    advertiserCode?: string;
    advertiserProductCode?: string;
    keywordId?: number,
    contentId?: number,
    seminarId?: number,
    imageUrl?: string,
    imageBase64?: any,
    content?: BoardContent,
    seminar?: SeminarLecture,
}

export interface SearchKeywordInfo{
    id?: number,
    keyword?: string,
    type?: SearchKeywordType,
    order?: number,
    startAt?: any,
    endAt?: any,
    deletedAt?: any,
    
    commercialType?: BoardContentCommercialType,
    advertiserCode?: string;
    advertiserProductCode?: string;

    relatedContents?: SearchKeywordRelatedContentInfo[]
}

export const EMPTY_SEARCH_KEYWORD : SearchKeywordInfo = {
    id: 0,
    keyword: "",
    type: SearchKeywordType.HOT,
    order: 0,
    startAt: getDateStringFromToday(),
    endAt: getDateStringFromToday({years: 1}),
    
    commercialType: BoardContentCommercialType.NONE,
    advertiserCode: "",
    advertiserProductCode: "",
    relatedContents: null,
} 
