export const ErrorCode = {
  NO_ERROR: 0,
  ERROR_NONE: 0,
  ERROR_INVALID_TOKEN: 1,
  ERROR_NOT_ENOUGH_PARAMETERS: 2,
  ERROR_NOT_ENOUGH_AUTH: 3,
  ERROR_INVALID_PARAMETERS: 4,

  ERROR_EXCEPTION: 10,

  ERROR_NOT_EXIST: 1001,
  ERROR_DELTETED: 1002,
  ERROR_DUPLICATED: 1003,
  ERROR_INVALID: 1004,

  ERROR_INSERT_FAILED: 2001,
  ERROR_UPDATE_FAILED: 2002,
  ERROR_DELETE_FAILED: 2003,

  ERROR_UPLOAD_FAILED: 2101,

  ERROR_EXPIRED: 3001,

  ERROR_ORDER_SHORT: 4001,
  ERROR_ORDER_STOCK_INVALID: 4002,
  ERROR_PURCHASE_FAILED: 4003,

  ERROR_MINOR_ALREADY_DONE: 9001,
};
