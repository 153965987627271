

import React, { Component } from 'react';
import './../../UserManage.css';
import './../../../Common.css';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonCheckbox, IonHeader, IonToolbar, IonButtons, IonIcon, IonLabel } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName, RegisterRoute, REGISTER_ROUTE_NAME, UserPermanentType } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { actions, RootState } from '../../../store';
import { connect } from 'react-redux';
import { UIPopupType } from '../../../store/ui/types';
import { getDateStringFromToday, getDateTimeString } from '../../../utils/TimeUtil';
import { JobOfferName } from '../../../models/Model.JobPost';

const API = {        
  "USER_GET" : {"method":"GET", "path":"/admin/user/user", "contentType":"application/json"},
};

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  onCancel: () => void;
  userId: number
};

type State = {
  user: UserInfo;
}

class UserManageProfile extends Component<Props, State> {

  state = {user: null};

  constructor(props:Props){
    super(props);

    if(this.props.userId){
      this.props.waitingPopup.show();
      fetchAPI(API.USER_GET, "", {id:this.props.userId}, null, getGlobal(GlobalKey.TOKEN)).then((result => {
        if(result && !result.error && result.data){
          this.setState({user:result.data});
          this.props.waitingPopup.hide();
        }
        else{
          log(LogLevel.UI_EXCEPTION, "constructor", result);
        }
      }))  
    }

  }

  componentDidUpdate(prevProps, prevState){
    log(LogLevel.UI_LIFECYCLE, "UserManageProfile:componentDidUpdate", prevProps, this.props);
    if(this.props.userId && (!prevProps || this.props.userId != prevProps.userId)){
      this.props.waitingPopup.show();
      fetchAPI(API.USER_GET, "", {id:this.props.userId}, null, getGlobal(GlobalKey.TOKEN)).then((result => {
        if(result && !result.error && result.data){
          this.setState({user:result.data});
          this.props.waitingPopup.hide();
        }
        else{
          log(LogLevel.UI_EXCEPTION, "componentDidUpdate", result);
        }
      }))  
    }
  }


  render() {
    log(LogLevel.UI_LIFECYCLE, "UserManageProfile:render", this.state.user);
    if(this.state && this.state.user){
      let route = [];
      if(this.state.user.registerRoute){
        if(this.state.user.registerRoute.includes(RegisterRoute.KAKAOTALK))
          route.push(<div key="1" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.KAKAOTALK]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.EXPO))
          route.push(<div key="2" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.EXPO]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.NEWS))
          route.push(<div key="3" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.NEWS]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.CONTENT))
          route.push(<div key="4" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.CONTENT]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.INVITE))
          route.push(<div key="5" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.INVITE]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.FACEBOOK))
          route.push(<div key="6" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.FACEBOOK]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.YSGL))
          route.push(<div key="7" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.YSGL]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.DP_RECRUIT))
          route.push(<div key="8" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.DP_RECRUIT]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.BAROPHARM))
          route.push(<div key="9" className="admin-toggle">{REGISTER_ROUTE_NAME[RegisterRoute.BAROPHARM]}</div>);
        if(this.state.user.registerRoute.includes(RegisterRoute.ETC))
          route.push(<div key="10" className="admin-toggle">{this.state.user.registerRouteEtc}</div>);
      }

      return (
        <div className="user-manage-container">
          {this.props.onCancel && 
            <IonHeader class="common-header" no-border>
              <IonToolbar color="common-header-toolbar">
                <IonButtons slot="start">
                  <IonButton class="common-header-toolbar-button" onClick={this.props.onCancel}>
                    <IonIcon name="arrow-back" mode="ios"/>
                  </IonButton>
                  <IonLabel class="common-header-title" onClick={this.props.onCancel}>사용자정보</IonLabel>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          }
          
          <div className="user-manage-title">ID : {this.state.user.id}</div>
          <div className="user-manage-title">닉네임 : {this.state.user.nickname}</div>
          <div className="user-manage-title">연락처 : {this.state.user.phone}</div>
          <div className="user-manage-title">이메일 : {this.state.user.email}</div>
          <div className="user-manage-title">근무정보 : {UserWorkTypeName[this.state.user.workType]}</div>
          <div className="user-manage-title">근무지 : {this.state.user.workPlaceRegion} {this.state.user.workPlaceName} </div>
          <div className="common-flex-row">
            <div className="user-manage-title">가입경로 : </div>
            {route}
          </div>
          <div className="user-manage-title">이름 : {this.state.user.name} </div>
          <div className="user-manage-title">면허번호 : {this.state.user.license} </div>
          <div className="user-manage-title">생년/성별 : {this.state.user.birthGender} </div>
          <div className="user-manage-title">가입일 : {this.state.user.createdAt} </div>
          <div className="user-manage-title">마케팅동의일 : {getDateTimeString(this.state.user.agreeMarketingAt)}</div>
          <div className="user-manage-title">평생회원 : {UserPermanentType[this.state.user.permenent]}</div>
          <div className="user-manage-title">약관동의버전 : {this.state.user.contractVersion}</div>
          <div className="user-manage-title">구인알림설정 : {JobOfferName[this.state.user.jobpostStatus]}</div>
          <div className="user-manage-title">구인알림지역 : {this.state.user.homeRegion}</div>
          <div className="user-manage-title">구인알림거리 : {this.state.user.jobpostDistance}</div>
        </div>
      );

    }
    return <div/>;
  }
}


const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  waitingPopup : state.ui.popups[UIPopupType.WAITING_POPUP]
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
}
export default connect(mapStateToProps, mapDispatchToProps)(UserManageProfile);