import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { log, LogLevel } from "../utils/LogUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
import SaleOrderList from "../component/template/SaleOrderList";
import Scroll, { ScrollAction } from "../component/molecule/Scroll";
import "./../Common.css";
import { OrderInfo } from "../models/Model.Sale";
import ChannelTalkService from "../utils/ChannelTalkService";
import { jumpToDeliveryTracker } from "../utils/DeliveryTrackerUtil";
import { getGlobal, GlobalKey } from "../utils/GlobalUtil";
import * as API from "./../API.json";
import Body from "../components/cell/Body";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {};

type State = {};

const windowAny: any = window;

class ProductSaleMyOrders extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  scrollRef: ScrollAction = null;

  componentDidMount() {
    this.props.backKeyControl.setListener(() => {
      this.goBack();
      return false;
    });

    this.props.refreshOrders();
    this.props.loadOrders();
  }

  componentWillUnmount() {
    // this.props.backKeyControl.popListener();
  }

  componentDidUpdate(prevProps, prevState) {}

  goBack = () => {
    log(LogLevel.UI_ACTION, "ProductSaleMyOrders:goBack");
    if (ChannelTalkService.isShown) {
      ChannelTalkService.hide();
      return;
    }
    this.props.history.goBack();
  };

  onClickDetail = (order: OrderInfo) => {
    this.props.history.push("/sale/myorder?id=" + order.id);
  };

  onClickButtonCancel = (order: OrderInfo) => {
    this.props.history.push("/sale/cancel?id=" + order.id);
  };

  onClickButtonReturn = (order: OrderInfo) => {
    this.props.history.push("/sale/return?id=" + order.id);
  };

  onClickButtonInquiry = (order: OrderInfo) => {
    if (!ChannelTalkService.isShown) ChannelTalkService.show();
  };

  onClickButtonTracking = (order: OrderInfo) => {
    log(
      LogLevel.UI_LIFECYCLE,
      "ProductSaleMyOrders.onClickButtonTracking",
      order
    );
    if (order) {
      jumpToDeliveryTracker(order.deliveryCompany, order.deliveryId);
    }
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductSaleMyOrders.render", this.props.orders);

    return (
      // <>
      // <Header goBack={this.goBack} name="주문 내역"/>
      <Body scrollable={false}>
        <Scroll
          ref={(ref) => (this.scrollRef = ref)}
          onLoadMore={this.props.loadOrders}
          autoScrollName="MyOrders"
        >
          <SaleOrderList
            className="common-block"
            orderList={this.props.orderList.orders}
            orders={this.props.orders}
            loading={this.props.orderList.loading}
            onClickDetail={this.onClickDetail}
            onClickButtonCancel={this.onClickButtonCancel}
            onClickButtonReturn={this.onClickButtonReturn}
            onClickButtonInquiry={this.onClickButtonInquiry}
            onClickButtonTracking={this.onClickButtonTracking}
          />
        </Scroll>
      </Body>
      // </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  orders: state.sale.orders,
  orderList: state.sale.orderList,
  links: state.board.links,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  refreshOrders: () => actions.sale.refreshOrders(),
  loadOrders: () => actions.sale.getOrders(0),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductSaleMyOrders)
);
