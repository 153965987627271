import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import JobPostListTemplate from "../../components/templates/JobPostList";
import {
  JobOfferInfo,
  JobOfferName,
  JobOfferType,
} from "../../models/Model.JobPost";
import {
  CompanyType,
  UserWorkType,
  UserWorkTypeName,
} from "../../models/Model.User";
import { actions, RootState } from "../../store";
import { JobListType } from "../../store/jobpost/types";
import { UIPopupType, UIServiceType } from "../../store/ui/types";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import { log, LogLevel } from "../../utils/LogUtil";
import * as API from "./../../API.json";
import { withRouter } from "react-router-dom";
import ABTestUtil, { ABTestFeature } from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { SearchType } from "../../models/Model.Search";
import { companyName, JobBizOfferName } from "../../types/jobpost";
import { getDistanceString } from "../../utils/LocationUtil";
import { on } from "cluster";
import { sendKakaoBizInviteMessage } from "../../utils/KakaoUtil";

const JobPostList = ({ history }) => {
  try {
    let loading = false;

    const progressPopup = useSelector(
      (state: RootState) => state.ui.popups[UIPopupType.WAITING_POPUP]
    );
    const me = useSelector((state: RootState) => state.user.me);
    const chatCount = useSelector(
      (state: RootState) => state.notification.chatUnreadCount
    );
    const isOwner = useMemo(() => me.workType === UserWorkType.PHARMACY_OWNER, [
      me,
    ]);
    const isHospital = useMemo(() => {
      if (
        me.workType === UserWorkType.HOSPITAL ||
        me.workType == UserWorkType.PHARMACEUTICAL_COMPANY
      )
        return true;
      if (
        (me.workPlaceAddress.includes("병원") ||
          me.workPlaceName.includes("병원") ||
          me.workPlaceName.includes("제약")) &&
        !me.workPlaceName.endsWith("약국")
      )
        return true;

      return false;
    }, [me]);
    // const offerListAll = useSelector((state: RootState) => state.jobpost.lists[JobListType.OFFER | JobListType.ALL]);
    const offerListPharmacy = useSelector(
      (state: RootState) =>
        state.jobpost.lists[JobListType.OFFER | JobListType.PHARMACY]
    );
    const offerListBiz = useSelector(
      (state: RootState) =>
        state.jobpost.lists[JobListType.OFFER | JobListType.BIZ]
    );
    const offerListMyLike = useSelector(
      (state: RootState) =>
        state.jobpost.lists[JobListType.OFFER | JobListType.MY_LIKE]
    );
    const offerListMy = useSelector(
      (state: RootState) =>
        state.jobpost.lists[JobListType.OFFER | JobListType.MY]
    );
    const offerListUnread = useSelector(
      (state: RootState) =>
        state.jobpost.lists[JobListType.OFFER | JobListType.UNREAD]
    );
    const offerListSearched = useSelector(
      (state: RootState) =>
        state.jobpost.lists[JobListType.OFFER | JobListType.SEARCHED]
    );
    const offerListPremium = useSelector(
      (state: RootState) =>
        state.jobpost.lists[JobListType.OFFER | JobListType.PREMIUM]
    );

    const offers = useSelector((state: RootState) => state.jobpost.offers);
    const backKeyControl = useSelector(
      (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
    );
    const toastPopup = useSelector(
      (state: RootState) => state.ui.popups[UIPopupType.TOAST_POPUP]
    );
    const lastVisited = useSelector(
      (state: RootState) => state.jobpost.lastVisited
    );
    const dispatch = useDispatch();

    const [
      filteredCompanyType,
      setFilteredCompanyType,
    ] = useState<CompanyType | null>(null);

    const onSetting = () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_LIST_SETTING",
        "구인구직_리스트_세팅_선택"
      );
      history.push(`/jobpost/setting`);
    };

    const onChat = () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_LIST_CHAT",
        "구인구직_리스트_채팅_선택"
      );
      history.push("/chat/chatlist");
    };

    // const [hasChat, setHasChat] = useState(true);
    // const [chatUnreadMessageCount, setChatUnreadMessageCount] = useState(0);
    // const sendbirdLoaded = useRef(false);

    // const sdk = sendBirdSelectors.getSdk(useSendbirdStateContext());

    useEffect(() => {
      // intializeSendbird();
      log(LogLevel.UI_LIFECYCLE, "JobPostList: mounted");
      document.addEventListener("resume", onResume, false);

      return () => {
        log(LogLevel.UI_LIFECYCLE, "JobPostList: unmounted");
        document.removeEventListener("resume", onResume);
      };
    }, []);

    const onResume = () => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RESUME_REFRESS",
        "구인구직_리스트_RESUME_UPDATE"
      );
      dispatch(
        actions.jobpost.reloadList(
          null,
          JobListType.OFFER | JobListType.PHARMACY
        )
      );

      if (ABTestUtil.isTest()) {
        toastPopup.show("Jobpost onResume: list updated ");
      }
    };

    // const intializeSendbird = () => {
    //   if (
    //     ABTestUtil.isTest(ABTestFeature.CHAT) &&
    //     !sendbirdLoaded.current &&
    //     sdk &&
    //     sdk.getTotalUnreadMessageCount &&
    //     sdk.ChannelHandler &&
    //     sdk.addChannelHandler
    //   ) {
    //     sdk
    //       .getTotalUnreadMessageCount()
    //       .then((count) => {
    //         setChatUnreadMessageCount(count);
    //       })
    //       .catch((e) => {
    //         log(LogLevel.UI_EXCEPTION, "JobPostList:intializeSendbird", e);
    //       });

    //     const channelHandler = new sdk.ChannelHandler();
    //     channelHandler.onMessageReceived = (channel, message) => {
    //       log(LogLevel.UI_ACTION, "LocalDealsMain:onMessageReceived ", message);
    //       sdk
    //         .getTotalUnreadMessageCount()
    //         .then((count) => {
    //           setChatUnreadMessageCount(count);
    //         })
    //         .catch((e) => {
    //           log(LogLevel.UI_EXCEPTION, "JobPostList:intializeSendbird", e);
    //         });
    //     };
    //     channelHandler.onMessageUpdated = (channel, message) => {
    //       log(LogLevel.UI_ACTION, "LocalDealsMain:onMessageUpdated ", message);
    //       sdk
    //         .getTotalUnreadMessageCount()
    //         .then((count) => {
    //           setChatUnreadMessageCount(count);
    //         })
    //         .catch((e) => {
    //           log(LogLevel.UI_EXCEPTION, "JobPostList:intializeSendbird", e);
    //         });
    //     };
    //     sdk.addChannelHandler("LocalDealChatUnread", channelHandler);

    //     sendbirdLoaded.current = true;
    //     log(
    //       LogLevel.UI_EVENT,
    //       "JobPostList:intializeSendbird sendbird initialized"
    //     );
    //   }
    // };

    const onScrap = async (offer: JobOfferInfo) => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SCRAP",
        "구인구직_약사_공지_리스트_찜하기",
        {
          공고id: offer.id,
        }
      );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SCRAP",
        "구인구직_약사_공지_찜하기",
        { 공고id: offer.id }
      );

      fetchAPI(
        API.JOBPOST_LIKE,
        offer.id.toString(),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          dispatch(
            actions.jobpost.reloadList(
              null,
              JobListType.OFFER | JobListType.MY_LIKE
            )
          );
        })
        .catch((err) => console.error(err));
    };

    const onSelect = async (offer: JobOfferInfo, isBiz?: boolean) => {
      log(LogLevel.UI_ACTION, "JobPostList: onSelect ", offer, isBiz);
      if (isBiz && offer.companyType != CompanyType.PHARMACY) {
        history.push(`/jobpost/biz/view/?id=${offer.id}`);
      } else {
        history.push(`/jobpost/view?id=${offer.id}`);
      }

      if (offer.ownerId == me.id) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_SELECT_MY_OFFER",
          "구인구직_약국_메인_내공고_선택",
          {
            공고id: offer.id,
          }
        );
      } else {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_SELECT_OFFER",
          "구인구직_약사_공지_선택",
          {
            공고id: offer.id,
          }
        );
      }
    };

    const onUnscrap = async (offer: JobOfferInfo) => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SCRAP_CANCEL",
        "구인구직_약사_공지_리스트_찜하기_취소",
        {
          공고id: offer.id,
        }
      );
      fetchAPI(
        API.JOBPOST_LIKE_CANCEL,
        offer.id.toString(),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          console.log(result);
          dispatch(
            actions.jobpost.reloadList(
              null,
              JobListType.OFFER | JobListType.MY_LIKE
            )
          );
          dispatch(actions.jobpost.updateOffer({ id: offer.id, liked: false }));
        })
        .catch((err) => console.error(err));
    };

    const onGotoBiz = () => {
      window.open(
        "https://biz.ymyd.co.kr/",
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );
    };

    const onMessageBiz = () => {
      sendKakaoBizInviteMessage();
    };

    const onPremium = (offer: JobOfferInfo) => {
      if (
        !offer &&
        offerListMy &&
        offerListMy.items &&
        offerListMy.items.length > 0
      ) {
        let tempOffer = null;
        for (let i = 0; i < offerListMy.items.length; i++) {
          let item = offers[offerListMy.items[i].id];
          log(
            LogLevel.UI_ACTION,
            "JobPostList: onPremium no offer, check my ",
            item,
            tempOffer
          );
          if (!item.order) {
            if (tempOffer) {
              tempOffer = null;
              break;
            }
            tempOffer = item;
          }
        }

        if (tempOffer) {
          offer = tempOffer;
        }
      }

      if (!offer || !offer.id) return;

      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_PREMIUM",
        "구인구직_약국_프리미엄_선택",
        { 공고id: offer.id }
      );

      history.push(`/jobpost/order/premium?id=${offer.id}`, {
        returnUrl: "/main/jobpost",
      });
    };

    const onSearchChanged = (options, type: JobListType) => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SEARCH",
        "구인구직_약사_공지_리스트_검색조건변경",
        options
      );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_SEARCH",
        "구인구직_약사_메인_검색진행",
        options
      );
      log(LogLevel.UI_ACTION, "JobPost.onSearchChanged", options);
      dispatch(actions.jobpost.reloadList(options, JobListType.OFFER | type));
      localStorage.setItem("jobpost-list-scroll-position", "0");
    };

    const onRefresh = (list: JobListType) => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_REFRESH",
        "구인구직_약사_공지_리스트_리프레시",
        {
          list: JobListType[list],
        }
      );

      if (list === JobListType.PHARMACY) {
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.PHARMACY
          )
        );
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.PREMIUM
          )
        );
      } else if (list === JobListType.BIZ) {
        dispatch(
          actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.BIZ)
        );
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.PREMIUM
          )
        );
      } else if (list === JobListType.MY_LIKE) {
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.MY_LIKE
          )
        );
      } else if (list === JobListType.MY) {
        dispatch(
          actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY)
        );
      } else if (list === JobListType.PREMIUM) {
        dispatch(
          actions.jobpost.reloadList(
            null,
            JobListType.OFFER | JobListType.PREMIUM
          )
        );
      }

      localStorage.setItem("jobpost-list-scroll-position", "0");
    };

    const onRaise = async (offer: JobOfferInfo) => {
      log(LogLevel.UI_ACTION, "JobPost.onRaise", offer.id);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_리스트_끌어올리기",
        {
          공고id: offer.id,
        }
      );
      fetchAPI(
        API.JOBPOST_RAISE,
        "",
        null,
        { id: offer.id },
        getGlobal(GlobalKey.TOKEN)
      )
        .then((result) => {
          dispatch(
            actions.jobpost.reloadList(
              null,
              JobListType.OFFER | JobListType.ALL
            )
          );
          dispatch(
            actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.MY)
          );
        })
        .catch((err) => console.error(err));
    };

    const onAlimMessage = (offer: JobOfferInfo) => {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_RAISE",
        "구인구직_공고_리스트_알림톡보내기",
        {
          공고id: offer.id,
        }
      );
      history.push(`/jobpost/order/alimtalk?id=${offer.id}`, {
        returnUrl: "/main/jobpost",
      });
    };

    const onPrepareFinalize = (offer: JobOfferInfo) => {
      dispatch(actions.jobpost.loadOffer({ chatlist: true }, offer.id));
    };

    // Post 더 불러오기
    const getMorePosts = (type: JobListType) => {
      if (loading) return;
      loading = true;
      dispatch(actions.jobpost.loadList(JobListType.OFFER | type));
      loading = false;
    };

    // 최근 검색어 조회
    const getKeywords = async () => {
      const res = await fetchAPI(
        API.SEACH_HISTORY_GET,
        "",
        {
          start: 0,
          count: 5,
          type: SearchType.JOBPOST,
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      );

      return res;
    };

    // 최근 검색어 추가
    const addKeyword = async (keyword: string) => {
      await fetchAPI(
        API.SEACH_HISTORY_ADD,
        "",
        null,
        {
          search: keyword,
          searchType: SearchType.JOBPOST,
        },
        getGlobal(GlobalKey.TOKEN)
      );
    };

    // 최근 검색어 삭제
    const deleteKeyword = async (keyword: string) => {
      await fetchAPI(
        API.SEACH_HISTORY_DELETE,
        "",
        {
          type: SearchType.JOBPOST,
          search: keyword, // 안넣으면 전체삭제
        },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
    };

    const getOptions = (postType: JobListType) => {
      switch (postType) {
        case JobListType.PHARMACY:
          return offerListPharmacy.options;
        case JobListType.BIZ:
          return offerListBiz.options;
        case JobListType.MY:
          return offerListMy.options;
        case JobListType.MY_LIKE:
          return offerListMyLike.options;
        case JobListType.SEARCHED:
          return offerListSearched.options;
        default:
          return;
      }
    };

    const getCurrentFiltered = useCallback(
      (filter: "companyType" | "type" | "location", selector: any) => {
        switch (filter) {
          case "location": {
            const { address, distance } = selector;

            if (!address && !distance) return "모든 지역";
            if (distance) return `${getDistanceString(distance)}이내`;

            return address;
          }
          case "companyType": {
            const { companyType } = selector;
            if (companyType === undefined) return "모든 업종";

            return companyName[companyType];
          }
          case "type": {
            const { type, ownerType } = selector;

            if (type === JobOfferType.ALL || type === JobOfferType.INVALID)
              return "모든 근무 유형";

            return ownerType === 2 ? JobBizOfferName[type] : JobOfferName[type];
          }
          default:
            return null;
        }
      },
      []
    );

    if (isOwner && (!offerListMy || !offerListPharmacy || !offerListBiz)) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_List_Page_nullable_exception",
        "에러",
        {
          page: "JobPostListPage",
          case: 1,
          userId: me.id,
          userWorkType: UserWorkTypeName[me.workType],
          offerListMy,
          offerListPharmacy,
          offerListBiz,
        }
      );
      return null;
    }
    if (
      !isOwner &&
      (!offerListPharmacy ||
        !offerListBiz ||
        !offerListMyLike ||
        !offerListSearched ||
        !offerListPremium ||
        !offerListPremium.gold)
    ) {
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "JOBPOST_List_Page_nullable_exception",
        "에러",
        {
          page: "JobPostListPage",
          case: 2,
          userId: me.id,
          userWorkType: UserWorkTypeName[me.workType],
          offerListPharmacy,
          offerListBiz,
          offerListMyLike,
          offerListSearched,
          offerListPremium,
        }
      );
      return null;
    }

    return (
      <JobPostListTemplate
        me={me}
        jobPostsSearched={offerListSearched}
        jobPostsMyLike={offerListMyLike}
        jobPostsMy={offerListMy}
        jobPostsUnread={offerListUnread}
        jobPostsPharmacy={offerListPharmacy}
        jobPostsBiz={offerListBiz}
        jobPostsPremium={offerListPremium}
        onGetMorePosts={getMorePosts}
        offers={offers}
        isOwner={isOwner}
        isHospital={isHospital}
        onSearchChanged={onSearchChanged}
        onRaise={onRaise}
        onAlimMessage={onAlimMessage}
        onPrepareFinalize={onPrepareFinalize}
        onScrap={onScrap}
        onSelect={onSelect}
        onUnscrap={onUnscrap}
        onSetting={onSetting}
        onRefresh={onRefresh}
        onChat={onChat}
        // hasChat={hasChat}
        chatUnreadMessageCount={chatCount}
        lastVisited={lastVisited}
        onGetKeywords={getKeywords}
        onAddKeyword={addKeyword}
        onDeleteKeyword={deleteKeyword}
        getCurrentFiltered={getCurrentFiltered}
        getOptions={getOptions}
        filteredCompanyType={filteredCompanyType}
        onGotoBiz={onGotoBiz}
        onMessageBiz={onMessageBiz}
        onPremium={onPremium}
      />
    );
  } catch (error) {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_List_Page_Error",
      "에러",
      {
        page: "JobPostListPage",
        errorMessage: error,
      }
    );
  }
};

export default withRouter(JobPostList);
