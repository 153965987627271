import React, { useEffect } from "react";
import {
  Absolute,
  Fixed,
  Flex,
  Relative,
  Static,
  Sticky,
} from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import Tag from "../../atoms/Tag";
import Popup from "../../molecules/Popup";
import Icon from "../../atoms/Icon";
import Input from "../../atoms/Input";
import { BoardContentSummary } from "../../../models/Model.Board";
import ReactHtmlParser from "react-html-parser";
import Spinner from "../../atoms/Spinner";
import { LogLevel, log } from "../../../utils/LogUtil";
import AdsUtil from "../../../utils/AdsUtil";

const QustionWrapper = styled.div`
  margin-top: 32px;
  padding: 16px 20px;
  background: ${COLOR_SYSTEM.get("Gray")[10]};
  border-radius: 12px;

  z-index: 0;
  transform: translateZ(0);
  overflow: show;

  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  br {
    margin-bottom: 8px;
  }

  b {
    font-weight: inherit;
    background-color: Yellow;};
  }
`;

const AnswerWrapper = styled.div`
  margin-top: 24px;
  padding-left: 16px;
  border-left: 1px solid ${COLOR_SYSTEM.get("Gray")[400]};

  b {
    font-weight: inherit;
    background-color: Yellow;
  }
`;

const KeywordWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 1000px;
  transform: translateZ(0);
  overflow: show;

  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};

  ${({ disabled }) => disabled && `* {opacity: 0.5}`};
`;

const PullUpErrorModalMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 24px;

  & > * + * {
    margin-top: 12px;
  }
`;

const PullUpErrorModalFooter = styled.footer`
  width: 100%;
  display: flex;

  & > * + * {
    margin-left: 8px;
  }

  button {
    flex: 1;
  }
`;

export interface BoardSummaryProps {
  summary: BoardContentSummary;
  keywords?: string[];
  currentPage?: number;
  totalPages?: number;
  locked?: boolean;
  onAdsReward?: () => void;
  onUnlock?: () => void;
  onClickReference?: (focus: {
    contentId?: number;
    commentId?: number;
  }) => void;
  onReport?: (text: string) => void;
  onClickKeyword?: (keyword: string) => void;
  summaryGroups?: any;
}

const BoardSummary: React.FC<BoardSummaryProps> = ({
  summary,
  keywords,
  currentPage,
  totalPages,
  locked,
  onUnlock,
  onAdsReward,
  onClickReference,
  onReport,
  onClickKeyword,
  summaryGroups,
}) => {
  const [showReportPopup, setRegisterPostErrorPopup] = React.useState<boolean>(
    false
  );
  const [reportText, setReportText] = React.useState<string>("");

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardSummary:useEffect [summaryGroups]",
      summary
    );
  }, [summaryGroups]);

  if (!summary || summary.state === "START")
    return (
      <QustionWrapper>
        <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
          <Spinner />
        </Text>
      </QustionWrapper>
    );

  return (
    <Flex
      direction="column"
      customStyle={{
        width: "100%",
        flexGrow: 1,
        minHeight: "100%",
        paddingBottom: "40px",
      }}
    >
      {keywords && totalPages > 0 && (
        <Flex justifyContent="space-between" alignItems="center">
          <Flex gap="8px" alignItems="center">
            <Text textType="Body2Bold" color={COLOR_SYSTEM.get("Gray")[900]}>
              {keywords.map((item) => `#${item}`).join(" ")}
            </Text>
            <Tag size="Small" variant="Outlined" color="Black">
              <b>{currentPage}</b>
              <Text color={COLOR_SYSTEM.get("Gray")[400]}>
                &nbsp;/ {totalPages}
              </Text>
            </Tag>
          </Flex>
          <Static onClick={() => onClickReference(null)}>
            <Text
              textType="Caption"
              color={COLOR_SYSTEM.get("Gray")[400]}
              style={{ textDecorationLine: "underline" }}
            >
              원글 보기
            </Text>
          </Static>
        </Flex>
      )}
      {summary && summary.summary && summary.summary.question && (
        <QustionWrapper>
          <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            {getSummaryHtml(summary.summary.question)}
          </Text>
        </QustionWrapper>
      )}
      <Relative
        customStyle={{
          width: "100%",
          height: "100%",
          minHeight: "100%",
          flex: 1,
        }}
      >
        {summary &&
          summary.summary &&
          summary.summary.answers &&
          summary.summary.answers.map((item, index) => (
            <AnswerWrapper key={index}>
              {item.text && (
                <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                  {getSummaryHtml(item.text)}
                  {item.from &&
                    item.from.map((from, index) => (
                      <Text
                        key={index.toString()}
                        color={COLOR_SYSTEM.get("Blue")[400]}
                        style={{
                          verticalAlign: "super",
                          fontSize: "smaller",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          let focus;
                          try {
                            if (/^CMT\d+$/.test(from)) {
                              focus = {
                                commentId: parseInt(from.replace("CMT", "")),
                              };
                            } else if (/^CNT\d+$/.test(from)) {
                              focus = {
                                contentId: parseInt(from.replace("CNT", "")),
                              };
                            } else if (from == "content" || from == "Q") {
                              focus = {
                                contentId: summary.contentId,
                              };
                            }
                            if (onClickReference) {
                              log(
                                LogLevel.UI_LIFECYCLE,
                                "BoardSummary:onClickReference",
                                focus,
                                from,
                                summary
                              );
                              onClickReference(focus);
                            }
                          } catch (e) {}
                        }}
                      >
                        [원문]
                      </Text>
                    ))}
                </Text>
              )}
            </AnswerWrapper>
          ))}
        {summary.state == "FINISH" && (
          <Static customStyle={{ margin: "40px 0px" }}>
            <Button
              size="Medium"
              type="IconWithText"
              variant="Ghost"
              color="Quaternary"
              left
              icon="Flag 02 Filled"
              onClick={() => {
                setRegisterPostErrorPopup(true);
              }}
            >
              오류 제보하기
            </Button>
          </Static>
        )}
        {summary && (summary.keyword || summary.state == "FINISH") && (
          <Separator type="Line" />
        )}
        {summary && summary.keyword && (
          <Flex
            direction="column"
            gap="4px"
            customStyle={{ margin: "24px 0px" }}
          >
            <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[900]}>
              이 글에 포함된 키워드
            </Text>
            <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[600]}>
              키워드를 선택하면 더 많은 요약글을 모아 볼 수 있어요.
            </Text>
          </Flex>
        )}
        <Flex gap="12px" customStyle={{ flexWrap: "wrap" }}>
          {summary &&
            summary.keyword &&
            summary.keyword.map((item, index) => {
              let count = 0;
              if (
                summaryGroups &&
                summaryGroups[item] &&
                summaryGroups[item].count > 0
              ) {
                count = summaryGroups[item].count;
              }

              log(
                LogLevel.UI_LIFECYCLE,
                "BoardSummary:keyword Count ",
                item,
                summaryGroups,
                summaryGroups && summaryGroups[item],
                summaryGroups &&
                  summaryGroups[item] &&
                  summaryGroups[item].count
              );
              return (
                <KeywordWrapper
                  key={index.toString()}
                  disabled={keywords && keywords.includes(item)}
                  onClick={() => {
                    if (!keywords || !keywords.includes(item)) {
                      if (onClickKeyword) onClickKeyword(item);
                    }
                  }}
                >
                  <Text textType="Body2" color={COLOR_SYSTEM.get("Blue")[500]}>
                    #{item}
                  </Text>

                  {count > 0 && (
                    <Text
                      textType="Body2Medium"
                      color={COLOR_SYSTEM.get("Gray")[300]}
                    >
                      {count}
                    </Text>
                  )}
                </KeywordWrapper>
              );
            })}
        </Flex>

        {locked && (
          <Absolute
            bottom="0"
            right="0"
            zIndex={100}
            customStyle={{
              width: "100%",
              height: "100%",
              backgroundImage:
                "linear-gradient(rgba(255,255,255,0.3) 0px, rgba(255,255,255,0.9) 40px, rgba(255,255,255,0.95) )",
              backdropFilter: "blur(2px)",
            }}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="end"
              customStyle={{
                width: "100%",
                height: "100%",
              }}
            >
              <Sticky
                bottom="0"
                customStyle={{
                  width: "100%",
                }}
              >
                <Flex
                  direction="column"
                  alignItems="center"
                  customStyle={{
                    width: "100%",
                    paddingBottom: "116px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src="/images/lock.png"
                    style={{ width: "117px", marginBottom: "34px" }}
                  />
                  <Text
                    style={{ marginBottom: "8px" }}
                    textType="H3"
                    color={COLOR_SYSTEM.get("Gray")[900]}
                  >
                    AI 요약 기능 무료 이용 횟수를
                    <br />
                    모두 소진했습니다.
                  </Text>
                  <Text
                    style={{ marginBottom: "40px" }}
                    textType="Body1Medium"
                    color={COLOR_SYSTEM.get("Gray")[800]}
                  >
                    <br />
                    AI 요약은 약문약답을 후원하는 약사님들께
                    <br /> 감사의 의미로 제공하고 있습니다. <br />
                    <br />
                    여러분의 후원은 약문약답을 더욱 가치 있고
                    <br /> 지속 가능한 공간으로 만드는 데 큰 도움이 됩니다.
                  </Text>
                  <Flex gap="8px">
                    {AdsUtil.isAvailable() && onAdsReward && (
                      <Button
                        size="Large"
                        type="Text"
                        variant="Contained"
                        color="Primary"
                        onClick={onAdsReward}
                      >
                        요약보기{" "}
                        <Tag
                          color="Blue"
                          variant="Outlined"
                          size="Small"
                          style={{ marginLeft: "8px" }}
                        >
                          AD
                        </Tag>
                      </Button>
                    )}
                    <Button
                      size="Large"
                      type="Text"
                      variant="Contained"
                      color="Primary"
                      onClick={onUnlock}
                    >
                      약문약답 후원하기
                    </Button>
                  </Flex>
                </Flex>
              </Sticky>
            </Flex>
          </Absolute>
        )}
      </Relative>
      {showReportPopup && (
        <Popup
          closeOnBackDrop={true}
          onClose={() => {
            setRegisterPostErrorPopup(false);
          }}
          children={
            <Flex direction="column" gap="24px">
              <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
                오류 제보하기
              </Text>
              <Input
                multiLine={true}
                placeholder={
                  <>
                    어떤 오류가 있나요?
                    <br />
                    자세히 알려주시면 기능개선에 큰 도움이 됩니다.
                  </>
                }
                value={reportText}
                onChange={(e) => {
                  setReportText(e.target.value);
                }}
                style={{ width: "100%", height: "200px" }}
              />
              <Flex width="100%" gap="8px">
                <Button
                  color="Secondary"
                  size="Medium"
                  type="Text"
                  variant="Tinted"
                  onClick={() => {
                    setRegisterPostErrorPopup(false);
                  }}
                  style={{ flex: 1 }}
                >
                  취소
                </Button>
                <Button
                  color="Primary"
                  size="Medium"
                  type="Text"
                  variant="Contained"
                  onClick={() => {
                    setRegisterPostErrorPopup(false);
                    if (onReport) onReport(reportText);
                  }}
                  style={{ flex: 1 }}
                >
                  완료
                </Button>
              </Flex>
            </Flex>
          }
        />
      )}
    </Flex>
  );
};

export function getSummaryHtml(text: string) {
  let tempStr = text
    .replace(/<b>/g, "@@@TEMP_OPEN_TAG@@@")
    .replace(/<\/b>/g, "@@@TEMP_CLOSE_TAG@@@");

  // 단계 2: 모든 <를 &lt;로 변경
  tempStr = tempStr.replace(/</g, "&lt;");

  // 단계 3: 임시 문자열을 원래 태그로 복원
  tempStr = tempStr
    .replace(/@@@TEMP_OPEN_TAG@@@/g, "<b>")
    .replace(/@@@TEMP_CLOSE_TAG@@@/g, "</b>")
    .replace(/\n/gi, "<br/>")
    .replace(/\\\\n/gi, "<br/>")
    .replace(/\\n/gi, "<br/>");

  return ReactHtmlParser(tempStr, {});
}
export default BoardSummary;
