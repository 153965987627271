

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../SeminarManage.css';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import ProfileImage from '../../../components/ProfileImage';
import { SeminarLecture } from '../../../models/Model.Seminar';
import { EMPTY_SEARCH_KEYWORD, SearchKeywordInfo, SearchKeywordType } from '../../../models/Model.Search';
import * as API from '../../../API.json';;

const AdminAPI = {        
};

const ROW_CNT = 20;

type Props = {
  onSelect: (keyword:SearchKeywordInfo) => void;
  progressPopup : any,
};

type State = {
  keywords: SearchKeywordInfo[],
  type: SearchKeywordType,
  includeDeleted: boolean,
  finished: boolean,
}

class SearchKeywordSelect extends Component<Props, State> {
  loading = false;

  state = {
    keywords: [],
    type: SearchKeywordType.RECOMMENDED_RESULTS,
    includeDeleted: true,
    finished: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  refresh = () =>{
    this.fetchData();
  }

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SearchKeywordSelect:fetchData");
    this.props.progressPopup.show();
    this.loading = true;
    fetchAPI(API.SEACH_KEYWORD_GET, "", {start:0, count: ROW_CNT, type:this.state.type, full: true, withDeleted: this.state.includeDeleted}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
      if(result && !result.error){
        log(LogLevel.UI_DATA_LOAD, "Admin:SearchKeywordSelect:fetchData result", result);
        let finished = false;
        if(result.data && result.data.length < ROW_CNT)
          finished = true;

        this.setState({keywords: result.data, finished});
        this.loading = false;
      }
      else{
        log(LogLevel.UI_DATA_LOAD, "Admin:SearchKeywordSelect:fetchData result", result);
        this.setState({keywords: [], finished: true});
      }
      this.props.progressPopup.hide();
    });
  }

  fetchDataMore = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SearchKeywordSelect:fetchDataMore", this.state.finished);
    if(!this.state.finished){
      this.props.progressPopup.show();
      this.loading = true;
      fetchAPI(API.SEACH_KEYWORD_GET, "", {start:this.state.keywords.length, count: ROW_CNT, type:this.state.type, full: true, withDeleted: this.state.includeDeleted}, null, getGlobal(GlobalKey.TOKEN)).then((result) => {
        if(result && !result.error){
          log(LogLevel.UI_DATA_LOAD, "Admin:SearchKeywordSelect:fetchData result", result);
          let finished = false;
          if(result.data && result.data.length < ROW_CNT)
            finished = true;

          this.setState({keywords: [...this.state.keywords, ...result.data], finished});
          this.loading = false;
        }
        else{
          log(LogLevel.UI_DATA_LOAD, "Admin:SearchKeywordSelect:fetchData result", result);
          this.setState({finished: true});
          this.loading = false;
        }
        this.props.progressPopup.hide();
      });
    }

  }

  onSelect = (keyword: SearchKeywordInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", keyword);
    this.props.onSelect(keyword);
  }

  onAdd = () => {
    this.props.onSelect({...EMPTY_SEARCH_KEYWORD, type: this.state.type});
  }

  onOptionChange = (name, value) => {
    let updates:any={};
    updates[name] = value;
    this.setState(updates);
    setTimeout(this.fetchData, 100);
  }

  render() {
    return (
      <div className="admin-container">
        <div className="common-container-row common-flex-align-center">
            <div>TYPE</div>
            <div className={"admin-toggle" + ((this.state.type==SearchKeywordType.RECOMMENDED_RESULTS)?" admin-toggle-selected":"")} onClick={() => this.onOptionChange("type", SearchKeywordType.RECOMMENDED_RESULTS)}>추천</div>
            <div className={"admin-toggle" + ((this.state.type==SearchKeywordType.HOT)?" admin-toggle-selected":"")} onClick={() => this.onOptionChange("type", SearchKeywordType.HOT)}>인기</div>
            <div className="common-flex-grow"/>
            <div>삭제키워드</div>
            <div className={"admin-toggle" + ((this.state.includeDeleted)?" admin-toggle-selected":"")} onClick={() => this.onOptionChange("includeDeleted", true)}>포함</div>
            <div className={"admin-toggle" + ((this.state.includeDeleted)?"":" admin-toggle-selected")} onClick={() => this.onOptionChange("includeDeleted", false)}>제외</div>
        </div>
        <div className="common-list">
          <IonButton expand="full" onClick={this.onAdd}>
            <IonIcon name="add"/>
          </IonButton>
          <IonList>
            { this.state.keywords.map((item:SearchKeywordInfo, index: number) => {
              if(item.startAt.length > 10){
                let date = new Date(item.startAt);
                date.setHours(date.getHours() + 9);
                item.startAt = date.toISOString().substring(0, 10);
              }
              if(item.endAt.length > 10){
                let date = new Date(item.endAt);
                date.setHours(date.getHours() + 9);
                item.endAt = date.toISOString().substring(0, 10);
              }
              return(
                <div key={index.toString()} className="common-container-row common-flex-align-center" onClick={() => this.onSelect(item)}>
                  <div className="common-flex-grow">
                    <div className={"admin-title" + (item.deletedAt?" common-color-caution":"")}>{item.order} - {item.keyword} {(item.deletedAt?"(삭제)":"")}</div>
                    <div className={"admin-title" + (item.deletedAt?" common-color-caution":"")}>{item.startAt} ~ {item.endAt}</div>
                  </div>
                </div>
              );
            })}   

          </IonList>
        </div>
      </div>  
    );
  }
}

export default SearchKeywordSelect;