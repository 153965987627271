import React, { Component } from "react";
import { getGlobal, GlobalKey } from "./../utils/GlobalUtil";
import BoardUtil from "../utils/BoardUtil";
import { isIOS } from "react-device-detect";
// import * as loadImage from 'blueimp-load-image';
import { UIPopupType } from "../store/ui/types";
import { timerStart, timerStop } from "../utils/TimeUtil";
import { loadImageBase64 } from "../utils/ImageUtil";
import HtmlComposer from "./HtmlComposer";
import TextComposer from "./TextComposer";

class Composer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!getGlobal(GlobalKey.OS) || getGlobal(GlobalKey.OS) == "browser")
      return <HtmlComposer {...this.props} />;
    return <TextComposer {...this.props} />;
  }
}

export default Composer;
