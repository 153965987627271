

import React, { Component } from 'react';
import './../../Admin.scss';
import './../../../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea, IonInput, IonIcon, IonHeader, IonToolbar, IonButtons, IonLabel } from '@ionic/react';
import {fetchAPI} from './../../../utils/APIUtil'
import * as API from './../../../API.json';
import { timeout } from 'q';
import smileIcon from './../../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel, UserLevelName } from '../../../models/Model.User';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import { triggerAsyncId } from 'async_hooks';
import { BoardContent } from '../../../models/Model.Board';
import Truncate from 'react-truncate';
import { BannerInfo, EMPTY_BANNER, BannerType } from '../../../models/Model.Banner';
import { PollInfo, EMPTY_POLL, PollItemInfo, PollResponseInfo } from '../../../models/Model.Poll';
import PollEdit from './PollEdit';

const AdminAPI = {        
  "POLL_LIST" : {"method":"GET", "path":"/admin/poll/polls", "contentType":"application/json"},
};

type Props = {
  onBack: () => void,
  // onPop: () => void,
  onNext: (component:any, depth: number, data:any) => void, 
  selectedPoll: PollInfo;
  selectedPollItem: PollItemInfo;
  depth: number;
  // selectedResponse: PollResponseInfo;
};

type State = {
  polls: PollInfo[],
}

class PollSelect extends Component<Props, State> {
  state = {
    polls: [],
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onSelect = (poll: PollInfo) => {
    log(LogLevel.UI_EVENT, "PollSelect:onSelect", poll);
    this.props.onNext(PollEdit, this.props.depth, {selectedPoll: poll});
  }

  fetchData = async () => {
    let result = await fetchAPI(AdminAPI.POLL_LIST, "", {}, null, getGlobal(GlobalKey.TOKEN));
    if(result && !result.error){
      this.setState({polls: result.data?result.data:[]});
    }
    else{
      this.setState({polls: []});
    }
  }

  
  onAdd = () => {
    this.props.onNext(PollEdit, this.props.depth, {selectedPoll: {...EMPTY_POLL}});
  }

  render() {
    log(LogLevel.UI_EVENT, "PollSelect:render", this.state);

    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button" onClick={this.props.onBack}>
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="viewer-title" >설문 목록</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="admin-board-list-container">
          <IonList>
            <IonButton expand="full" onClick={this.fetchData}>
              <IonIcon name="refresh"/>
            </IonButton>
            <IonButton expand="full" onClick={this.onAdd}>
              <IonIcon name="add"/>
            </IonButton>
            { this.state.polls.map((item:PollInfo, index: number) => {
                return (
                  <div key={index.toString()} className="admin-board-list-item-container" onClick={() => this.onSelect(item)}>
                    <div className="admin-row"> 
                      <div className="admin-grow">{item.id}<span className="common-color-caution">{item.alias?(" - " + item.alias):""}</span> - {item.title}{item.deletedAt?"[삭제]":""}</div>
                    </div>
                  </div>
                );
              }
            )}              
          </IonList>
        </div>
      </div>
    );
  }
}

export default PollSelect;