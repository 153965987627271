import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonSelect,
  IonText,
  IonSelectOption,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
// import './EventManage.css'
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { getGlobal, GlobalKey } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import { BoardContent, Attachment } from "../models/Model.Board";
import { EventInfo, EMPTY_EVENT } from "../models/Model.Event";
import EventEdit from "./component/EventEdit";
import EventSelect from "./component/EventSelect";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  event: EventInfo;
};

class EventManage extends Component<Props, State> {
  state = { event: EMPTY_EVENT };
  eventlist = null;

  constructor(props: Props) {
    super(props);
    document.title = "이벤트팝업 관리";
  }

  onDone = (event: EventInfo) => {
    this.setState({ event });
    this.eventlist.fetchData();
  };

  onSelect = (event: EventInfo) => {
    this.setState({ event });
  };

  render() {
    // log(LogLevel.UI_LIFECYCLE, "EventManage.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">이벤트팝업 관리</IonText>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <EventEdit event={this.state.event} onDone={this.onDone} />
            </div>
            <div id="admin-sub-conainer" className="admin-container">
              <EventSelect
                ref={(ref) => (this.eventlist = ref)}
                onSelect={this.onSelect}
              />
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventManage)
);
