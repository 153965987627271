// type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
// }
import React, { Component } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RootState } from "../../store";
import { useCallback, useEffect, useState } from "react";
import { LogLevel, log } from "../../utils/LogUtil";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { UserPremiumPurchasedInfo } from "../../models/Model.User.Premium";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import { GlobalKey, getGlobal, setGlobal } from "../../utils/GlobalUtil";
import ChannelTalkService from "../../utils/ChannelTalkService";
import PremiumUserPurchaseHistory from "../PremiumUserPurchaseHistory";
import ProductSaleMyOrders from "../ProductSaleMyOrders";
import { Header } from "../../components/atoms/Layout";
import TopAppBar from "../../components/molecules/TopAppBar/TopAppBar";
import Button from "../../components/atoms/Button";
import { set } from "lodash";

const PurchaseHistoryPage: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const [page, setPage] = useState<"PREMIUM" | "PRODUCT">(
    getGlobal("strLastPurchaseHistory", true) || "PREMIUM"
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  console.log(page);

  return (
    <div className="common-content common-position-relative">
      <Header>
        <TopAppBar
          titleType="H1Tabs"
          title="후원 내역"
          tabs={[
            {
              text: "후원내역",
              active: page == "PREMIUM",
              onClick: () => {
                setGlobal("strLastPurchaseHistory", "PREMIUM", true);
                setPage("PREMIUM");
              },
            },
            {
              text: "주문내역",
              active: page == "PRODUCT",
              onClick: () => {
                setGlobal("strLastPurchaseHistory", "PRODUCT", true);
                setPage("PRODUCT");
              },
            },
          ]}
          leftButtons={[
            <Button
              color="Tertiary"
              icon="CaretLeft"
              onClick={goBack}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          rightButtons={[]}
        />
      </Header>
      {page == "PREMIUM" && <PremiumUserPurchaseHistory />}
      {page == "PRODUCT" && <ProductSaleMyOrders />}
    </div>
  );
};

export default withRouter(PurchaseHistoryPage);
