import React from "react";
import { JobOfferType } from "../../../models/Model.JobPost";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import BottomSheet, { BottomSheetProps } from "../../molecules/BottomSheet/BottomSheet";
import { WorkTypeList } from "./style";

interface WorkTypeSelectBottomSheetProps extends BottomSheetProps {
  settingPage?: boolean;
  selectedWorkType?: JobOfferType;
  onSelect: (workType: JobOfferType) => () => void;
}

const WorkTypeSelectBottomSheet: React.FC<WorkTypeSelectBottomSheetProps> = ({
  settingPage = false,
  selectedWorkType,
  active,
  onClose,
  onSelect,
}) => {
  return (
    <BottomSheet active={active} onClose={onClose}>
      <WorkTypeList>
        {settingPage && (
          <li onClick={onSelect(JobOfferType.ALL)}>
            <div className="worktype__item__title">
              <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
                모든 근무 유형
              </Text>
            </div>
            <Icon
              width={24}
              height={24}
              fill={
                selectedWorkType === JobOfferType.ALL ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[100]
              }
              name={"Check"}
            />
          </li>
        )}
        <li onClick={onSelect(JobOfferType.FULL_TIME)}>
          <div className="worktype__item__title">
            <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
              풀타임
            </Text>
            <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[400]}>
              일 8시간, 주 5일 이상
            </Text>
          </div>
          <Icon
            width={24}
            height={24}
            fill={
              selectedWorkType === JobOfferType.FULL_TIME
                ? COLOR_SYSTEM.get("Skyblue")[400]
                : COLOR_SYSTEM.get("Gray")[100]
            }
            name={"Check"}
          />
        </li>
        <li onClick={onSelect(JobOfferType.PART_TIME)}>
          <div className="worktype__item__title">
            <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
              파트타임
            </Text>
            <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[400]}>
              일 8시간, 주 5일 미만
            </Text>
          </div>
          <Icon
            width={24}
            height={24}
            fill={
              selectedWorkType === JobOfferType.PART_TIME
                ? COLOR_SYSTEM.get("Skyblue")[400]
                : COLOR_SYSTEM.get("Gray")[100]
            }
            name={"Check"}
          />
        </li>
        <li onClick={onSelect(JobOfferType.TEMPORARY)}>
          <div className="worktype__item__title">
            <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[800]}>
              단기알바
            </Text>
            <Text textType="InputLarge" color={COLOR_SYSTEM.get("Gray")[400]}>
              3개월 이내 단기간
            </Text>
          </div>
          <Icon
            width={24}
            height={24}
            fill={
              selectedWorkType === JobOfferType.TEMPORARY
                ? COLOR_SYSTEM.get("Skyblue")[400]
                : COLOR_SYSTEM.get("Gray")[100]
            }
            name={"Check"}
          />
        </li>
      </WorkTypeList>
    </BottomSheet>
  );
};

export default WorkTypeSelectBottomSheet;
