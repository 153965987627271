
import React, { Component } from 'react';
import './SliderIndex.css';
import fileIcon from './../assets/image/write_fileimage_box.png'
import {getFileSizeString} from '../utils/FileUtil'
import { IonIcon, IonButton } from '@ionic/react';
import { LogLevel, log } from '../utils/LogUtil';

const windowAny : any = window;

type Props = {
  cnt?: number;
  index?: number;
  className?: string; // width, height, --color
  onTouch?: (index:number) => void;
};

const SliderIndex: React.SFC<Props> = ({cnt, index, className}) => {
  log(LogLevel.UI_LIFECYCLE, "SliderIndex: render", cnt, index, className);
  let dots = [];
  for(let i=0; i<cnt; i++){
    if(i == index){
      dots.push(<div key={i.toString()} className="slider-index-dot"><div className="slider-index-dot-enabled"/></div>)
    }else{
      dots.push(<div key={i.toString()} className="slider-index-dot"><div className="slider-index-dot-disabled"/></div>)
    }
  }

  return (
  <div className={"slider-index-container " + className}>
    {dots}
  </div>
  );
}

export default SliderIndex;