import { ReactNode } from "react";

// 타입 정의
type BottomSheetTitle = {
  value: string;
  align: "left" | "right" | "center";
};

export interface BottomSheetUIOptions {
  title: BottomSheetTitle;
  body: ReactNode;
}

export interface BottomSheetState {
  on: boolean;
  active: boolean;
  title: BottomSheetTitle;
  body: ReactNode;
  maxHeight?: string;
}

export interface BottomSheetAction {
  type: string;
  payload: any;
}

// Action
const ON_BOTTOM_SHEET = "BottomSheet/on";
const OFF_BOTTOM_SHEET = "BottomSheet/off";
const PAINT_BOTTOM_SHEET = "BottomSheet/paint";
const SHOW_BOTTOM_SHEET = "BottomSheet/show";
const HIDE_BOTTOM_SHEET = "BottomSheet/hide";

// Action Creator
export const onBottomSheet = () => ({
  type: ON_BOTTOM_SHEET,
});

export const offBottomSheet = () => ({
  type: OFF_BOTTOM_SHEET,
});

export const paintBottomSheet = (options?: Partial<BottomSheetState>) => ({
  type: PAINT_BOTTOM_SHEET,
  payload: {
    ...options,
  },
});

export const showBottomSheet = () => ({
  type: SHOW_BOTTOM_SHEET,
});

export const hideBottomSheet = () => ({
  type: HIDE_BOTTOM_SHEET,
});

// Middleware
export const bottomSheetMiddleware = (store) => (next) => (action) => {
  if (action.type === PAINT_BOTTOM_SHEET) {
    setTimeout(() => {
      next(showBottomSheet());
    }, 50);
  }

  next(action);
};

// Initial State
const initialState: BottomSheetState = {
  on: false,
  active: false,
  title: {
    value: "",
    align: "left",
  },
  body: "",
};

// Reducer
const reducer = (state = initialState, action: BottomSheetAction) => {
  const { type } = action;

  switch (type) {
    case ON_BOTTOM_SHEET:
      return {
        ...state,
        on: true,
      };
    case OFF_BOTTOM_SHEET:
      return {
        ...state,
        on: false,
        active: false,
      };
    case PAINT_BOTTOM_SHEET:
      const options = action.payload;
      return {
        ...state,
        title: {
          value: "",
          align: "left",
        },
        ...options,
      };
    case SHOW_BOTTOM_SHEET:
      return {
        ...state,
        active: true,
      };
    case HIDE_BOTTOM_SHEET:
      return {
        ...state,
        body: null,
        active: false,
      };

    default:
      return state;
  }
};

export default reducer;
