import React, { useCallback, useEffect, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Textarea from 'react-textarea-autosize';
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from '../Text';
import { log, LogLevel } from '../../../utils/LogUtil';
import Icon from '../Icon';

type Size =
"S" | 
"M" | 
"L" | 
"XL"

const SIZE = {
  S: {
    size: 12,
    gap: 0,
  },
  M: {
    size: 16,
    gap: 0,
  },
  L: {
    size: 24,
    gap: 2,
  },
  XL: {
    size: 40,
    gap: 12,
  },
}

interface WrapperProps {
  size:Size
}
const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  .star-score-container {
    width: ${props => (SIZE[props.size].size *5 + SIZE[props.size].gap *4)}px;
    height: ${props => SIZE[props.size].size}px;
    padding: 0px;
    outline: none;
    outline-style: none;
    background: none;
    border: none;
    position: relative;

    .star-score-bg{
      width: 100%;
    }

    .star-score-fg{
      overflow: hidden;
      position: absolute;
      height: 100%;
      top: 0px;
      left: 0px;
    }

    .star-score-inner{
      height: 100%;
      width: 100%;
      align-items: center;
      white-space: nowrap;

      .star-score-star {
        height: 100%;
        width: ${props => SIZE[props.size].size}px;
        display: inline-block;
        vertical-align:top;

      }

      .star-score-gap {
        width: ${props => SIZE[props.size].gap}px;
        display: inline-block;
        vertical-align:top;
      }
    }


  }
  
`

export interface Props {
  size?: Size;
  score?: number;
  onClick?: (number, e) => void;
  color?: string;
}
const StarScore: React.FC<Props> = ({
  size = "M",
  score = 0,
  onClick,
  color = "Point",
}) => {


  let percent = score * 100 / 5;



  return (
    <Wrapper size={size}>
      <div className="star-score-container" >
        <div className="star-score-bg star-score-inner">
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR.Gray6}/>
          </div>
        </div>
        <div className="star-score-fg star-score-inner" style={{width:percent+"%"}}>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR[color]}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR[color]}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR[color]}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR[color]}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={COLOR[color]}/>
          </div>
        </div>
      </div>
    </Wrapper>

  )
}

export default StarScore
