import React, { useCallback, useState } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import { JobOfferType, JobPostRegistrationForm } from "../../../models/Model.JobPost";
import Popup from "../../molecules/Popup";
import Icon from "../../atoms/Icon";
import { PopupBody, PopupDetailInformationContainer, PopupDetailInformationItem, PopupFooter } from "./style";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { JobListState } from "../../../store/jobpost/types";
import { log, LogLevel } from "../../../utils/LogUtil";
import { clearGlobal, getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { getDateTimeString } from "../../../utils/TimeUtil";
import { MemoizedHeader } from "./Header";
import Main from "./Main";
import WorkTypeSelectBottomSheet from "../../organisms/JobPostBottomSheet/WorkTypeSelectBottomSheet";

export type PageMode = "Write" | "Load" | "Preview";

interface JobPostRegistrationProps {
  mode?: PageMode;
  form?: JobPostRegistrationForm;
  onChangeForm?: React.Dispatch<React.SetStateAction<JobPostRegistrationForm>>;
  onChangeMode?: React.Dispatch<React.SetStateAction<PageMode>>;
  myHistory: JobListState;
  edit?: boolean;
  jobPost?: any;
  onUpdateJobPost?: (form: JobPostRegistrationForm, postId: number) => void;
  onSendAlimTalk?: (postId: number) => void;
}

const JobPostRegistration: React.FC<JobPostRegistrationProps & RouteComponentProps> = ({
  history,
  mode,
  form,
  onChangeForm,
  onChangeMode,
  edit = false,
  jobPost,
  myHistory,
  onUpdateJobPost,
}) => {
  log(LogLevel.UI_ACTION, "LocalDealJobPostOfferDetail:render: ", getGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, true));

  const [workTypeSheet, setWorkTypeSheet] = useState(false);
  const [exitPopup, setExitPopup] = useState(false);
  const [draftPopup, setDraftPopup] = useState(
    !edit && !jobPost && getGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, true).savedAt
  );

  const [edited, setEdited] = useState(false);

  const handleBackClick = useCallback(() => {
    edited ? setExitPopup(true) : history.goBack();
  }, [edited]);

  const handleLoadMyHistoryClick = useCallback(() => {
    onChangeMode("Load");
  }, [mode]);

  const handleOpenWorkTypeSheetClick = () => setWorkTypeSheet(true);

  const handleSelectWorkType = (workType: JobOfferType) => () => {
    setEdited(true);
    setWorkTypeSheet(false);
    onChangeForm({ ...form, workType });
    setWorkTypeSheet(false);
  };

  return (
    <>
      <MemoizedHeader
        edit={edit}
        mode={mode}
        myHistory={myHistory}
        handleBackClick={handleBackClick}
        handleLoadMyHistoryClick={handleLoadMyHistoryClick}
      />
      <Main
        edit={edit}
        form={form}
        jobPost={jobPost}
        onChangeForm={onChangeForm}
        onChangeMode={onChangeMode}
        setEdited={setEdited}
        handleOpenWorkTypeSheetClick={handleOpenWorkTypeSheetClick}
        onUpdateJobPost={onUpdateJobPost}
      />

      {/* 근무 유형 Bottom Sheet */}
      <WorkTypeSelectBottomSheet
        active={!form.workType || workTypeSheet}
        selectedWorkType={form.workType}
        onClose={() => {
          setWorkTypeSheet(false);
        }}
        onSelect={handleSelectWorkType}
      />

      {/* 작성 중 이탈 팝업 */}
      {exitPopup && (
        <Popup onClose={() => setExitPopup(false)}>
          <PopupBody>
            <Icon width={60} height={60} name={"Exclamation Mark Filled"} fill={COLOR_SYSTEM.get("Red")[300]} />
            <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
              정말로 나가실 건가요?
            </Text>
            {!edit && !jobPost && (
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
                입력하신 내용은 임시 저장됩니다.
              </Text>
            )}
          </PopupBody>
          <PopupFooter>
            <Button
              color="Secondary"
              size="Medium"
              type="Text"
              variant="Tinted"
              onClick={() => {
                if (!edit && !jobPost) {
                  let savedAt = new Date(Date.now()).toISOString();
                  log(LogLevel.UI_ACTION, "LocalDealJobPostOfferDetail:SaveDraft: ", savedAt, form);
                  setGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, { ...form, savedAt }, true);
                }
                setExitPopup(false);
                history.goBack();
              }}
            >
              나가기
            </Button>
            <Button
              color="Primary"
              size="Medium"
              type="Text"
              variant="Contained"
              onClick={() => {
                setExitPopup(false);
              }}
            >
              계속 진행하기
            </Button>
          </PopupFooter>
        </Popup>
      )}

      {/* 자동저장 불러오기 팝업 */}
      {draftPopup && (
        <Popup
          onClose={() => {
            setDraftPopup(false);
          }}
        >
          <PopupBody>
            <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
              작성중인 공고가 있어요.
              <br />
              이어서 작성하실건가요?
            </Text>
            <PopupDetailInformationContainer textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
              <PopupDetailInformationItem>
                <div className={"title"}>
                  <Text textType="Body2Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
                    작성일지
                  </Text>
                </div>
                <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[800]}>
                  {getDateTimeString(getGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, true).savedAt)}
                </Text>
              </PopupDetailInformationItem>
              <PopupDetailInformationItem>
                <div className={"title"}>
                  <Text textType="Body2Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
                    제목
                  </Text>
                </div>
                <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[800]}>
                  {getGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, true).title}
                </Text>
              </PopupDetailInformationItem>
              <PopupDetailInformationItem></PopupDetailInformationItem>
            </PopupDetailInformationContainer>
          </PopupBody>
          <PopupFooter>
            <Button
              color="Secondary"
              size="Large"
              type="Text"
              variant="Tinted"
              onClick={() => {
                clearGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, true);
                setDraftPopup(false);
                setEdited(true);
              }}
            >
              새로 작성
            </Button>
            <Button
              color="Primary"
              size="Large"
              type="Text"
              variant="Contained"
              onClick={() => {
                onChangeForm(getGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, true));
                clearGlobal(GlobalKey.JOBPOST_DRAFT_OFFER, true);
                setDraftPopup(false);
              }}
            >
              이어서 작성
            </Button>
          </PopupFooter>
        </Popup>
      )}
    </>
  );
};

export default withRouter(JobPostRegistration);
