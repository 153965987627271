import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
  AllInOnePartnerInfo,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import AllInOnePartnerSelect from "./component/allinone/AllInOnePartnerSelect";
import ToggleSwitch from "../component/atom/ToggleSwitch";
import SummarySelect from "./component/summary/SummarySelect";
import { BoardContentSummary } from "../models/Model.Board";
import SummaryDetail from "./component/summary/SummaryDetail";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  selected: BoardContentSummary;
};

class BoardSummaryManage extends Component<Props, State> {
  popup = null;
  list = null;

  state = {
    selected: null,
    showPartners: true,
  };

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
    document.title = "GPT 요약 관리";
  }

  componentDidMount() {}

  onSelect = (summary: BoardContentSummary) => {
    log(LogLevel.UI_EVENT, "BoardSummaryManage.onSelect", summary);
    this.setState({ selected: summary });
  };

  onDone = () => {
    log(LogLevel.UI_EVENT, "BoardSummaryManage.onDone", this.state.selected);
    if (this.list) this.list.refresh();
    this.setState({ selected: null });
  };

  onSearch = (options) => {
    log(LogLevel.UI_EVENT, "BoardSummaryManage.onSearch", options);
    if (this.list) this.list.search(options);
  };

  onCancel = () => {
    log(LogLevel.UI_EVENT, "BoardSummaryManage.onCancel", this.state.selected);
    this.setState({ selected: null });
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardSummaryManage.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">GPT 요약 관리</IonText>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <SummarySelect
                onDone={this.onSelect}
                summary={this.state.selected}
                ref={(ref) => {
                  this.list = ref;
                }}
              />
            </div>
            {this.state.selected && (
              <div id="admin-sub-conainer" className="admin-container">
                <SummaryDetail
                  summary={this.state.selected}
                  onDone={this.onDone}
                  onCancel={this.onCancel}
                  onSearch={this.onSearch}
                />
              </div>
            )}
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardSummaryManage)
);
