import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { ConfirmUIOptions, hideConfirm, setResolveCallback, showConfirm } from "../store/modal/confirm";

const useConfirm = () => {
  const { resolveCallback } = useSelector((state: RootState) => state.confirm);
  const dispatch = useDispatch();

  const closeConfirm = () => {
    dispatch(hideConfirm());
  };

  const onCancel = () => {
    closeConfirm();
    if (resolveCallback) resolveCallback(false);
  };

  const onConfirm = () => {
    closeConfirm();
    if (resolveCallback) resolveCallback(true);
  };

  const confirm = (options: ConfirmUIOptions) => {
    dispatch(showConfirm(options));
    return new Promise((res) => dispatch(setResolveCallback(res)));
  };

  return { confirm, onConfirm, onCancel };
};

export default useConfirm;
