import React, { Component } from 'react';
import './ProgressPopup.css';
import { IonButton, IonModal,IonSpinner, IonInput,IonList, IonItem } from '@ionic/react';
import {log, LogLevel} from '../../utils/LogUtil'

type Props = {
  visible?: false;
};

type State = {
  visible: boolean;
  label: string;
  stopped: boolean;
}

class ProgressPopup extends Component<Props, State> {
  modal = null;
  shown = false;
  state={
    visible: false,
    label:'',
    stopped: false,
  };

  show = () => {
    log(LogLevel.UI_POPUP, "ProgressPopup:show", this.shown)
    this.setState({stopped: false, visible:true});
    
  }

  hide = () => {
    log(LogLevel.UI_POPUP, "ProgressPopup:hide")
    this.setState({stopped: true, label:''});
    this.startTimer();
  }

  isShown = () => {
    return this.state.visible;
  }

  startTimer = () => {
    setTimeout(()=>{
      try{
        if(this.state.stopped && this.shown){
          log(LogLevel.UI_EXCEPTION, "ProgressPopup:startTimer", this.state, this.shown)
          this.setState({visible: false});
          this.shown = false;
        }
        else
          this.startTimer();
      }catch(e) {
        log(LogLevel.UI_EXCEPTION, "ProgressPopup:startTimer", e)
      }
    }, 200)
  }

  setLabel = (label) => {
    this.setState({label:label});
  }

  didShow = () => {
    log(LogLevel.UI_LIFECYCLE, "ProgressPopup:didShow")
    this.shown = true;
  }
  
  didHide = () => {
    log(LogLevel.UI_LIFECYCLE, "ProgressPopup:didHide")
    this.shown = false;
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "ProgressPopup:render", this.state, this.shown)
    let visible = false;
    if(this.props.visible || this.state.visible)
      visible = true;
    return (
      <IonModal cssClass="progress-popup-container"
        isOpen={visible}
        backdropDismiss = {false}
        onIonModalDidPresent ={this.didShow}
        onIonModalDidDismiss = {this.didHide}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="progress-container">
          <IonSpinner class="progress-spinner" name="lines-small"/>

          <div className="progress-label">
            {this.state.label}
          </div>
        </div>

      </IonModal>
    );
  }
}

export default ProgressPopup;