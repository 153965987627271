import { BannerInfo } from "../../models/Model.Banner";
import { BoardContent } from "../../models/Model.Board";
import { AllInOnePartnerInfo } from "../../models/Model.User";

export interface AllInOneState {
  partnerListLoading: boolean;
  partnerList: string[];
  partners: any; // {id: AllInOnePartnerInfo}
  banners: BannerInfo[];
}

export enum AllInOneActionTypes {
  ALLINONE_PARTNERS_FETCH = "@@allinone/ALLINONE_PARTNERS_FETCH",
  ALLINONE_PARTNERS_REQUEST = "@@allinone/ALLINONE_PARTNERS_REQUEST",
  ALLINONE_PARTNERS_SUCCESS = "@@allinone/ALLINONE_PARTNERS_SUCCESS",
  ALLINONE_PARTNERS_ERROR = "@@allinone/ALLINONE_PARTNERS_ERROR",
  ALLINONE_PARTNER_FETCH = "@@allinone/ALLINONE_PARTNER_FETCH",
  ALLINONE_PARTNER_REQUEST = "@@allinone/ALLINONE_PARTNER_REQUEST",
  ALLINONE_PARTNER_SUCCESS = "@@allinone/ALLINONE_PARTNER_SUCCESS",
  ALLINONE_PARTNER_ERROR = "@@allinone/ALLINONE_PARTNER_ERROR",
  ALLINONE_BANNERS_FETCH = "@@allinone/ALLINONE_BANNERS_FETCH",
  ALLINONE_BANNERS_REQUEST = "@@allinone/ALLINONE_BANNERS_REQUEST",
  ALLINONE_BANNERS_SUCCESS = "@@allinone/ALLINONE_BANNERS_SUCCESS",
  ALLINONE_BANNERS_ERROR = "@@allinone/ALLINONE_BANNERS_ERROR",
}
