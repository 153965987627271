import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import { rippleEvent } from '../../_util/RrppleEffect'
import Icon from '../Icon'


type ButtonVariant = 'Ghost' | 'Outlined' | 'Contained'
type ButtonSize = 'S' | 'M'
type ButtonColor = 'Primary' | 'Secondary' | 'Gray'
const BUTTON_SIZE = {
  S: {
    height: '32px',
    borderRadius: '6px',
    padding: '6px 7px',
    typography: 'ButtonS',
    block:"inline-block",
    iconSize: 18,
    iconMarginGap: "4px",
  },
  M: {
    height: '40px',
    borderRadius: '8px',
    padding: '9px 11px',
    typography: 'ButtonM',
    block:"block",
    iconSize: 20,
    iconMarginGap: "4px",
  },
}

const BUTTON_VARIANT_COLOR = {
  'Ghost': {
    'Primary':{
      borderColor: COLOR.Transparent,
      color: COLOR.Primary,
      background: COLOR.Transparent,
    },
    'Secondary':{
      borderColor: COLOR.Transparent,
      color: COLOR.Gray1,
      background: COLOR.Transparent,
    },
    'Gray':{
      borderColor: COLOR.Transparent,
      color: COLOR.Gray3,
      background: COLOR.Transparent,
    },
    'UnChecked':{
      borderColor: COLOR.Transparent,
      color: COLOR.Gray4,
      background: COLOR.Transparent,
    },
  },
  'Outlined': {
    'Primary':{
      borderColor: COLOR.Primary,
      color: COLOR.Primary,
      background: COLOR.Transparent,
    },
    'Secondary':{
      borderColor: COLOR.Gray7,
      color: COLOR.Gray1,
      background: COLOR.Transparent,
    },
    'Gray':{
      borderColor: COLOR.Gray7,
      color: COLOR.Gray3,
      background: COLOR.Transparent,
    },
    'UnChecked':{
      borderColor: COLOR.Gray8,
      color: COLOR.Gray5,
      background: COLOR.Transparent,
    },
  },
  'Contained': {
    'Primary':{
      borderColor: COLOR.Primary,
      color: COLOR.White,
      background: COLOR.Primary,
    },
    'Secondary':{
      borderColor: COLOR.Gray7,
      color: COLOR.Gray1,
      background: COLOR.Gray7,
    },
    'Gray':{
      borderColor: COLOR.Gray8,
      color: COLOR.Gray3,
      background: COLOR.Gray8,
    },
    'UnChecked':{
      borderColor: COLOR.Gray8,
      color: COLOR.Gray5,
      background: COLOR.Gray8,
    },
  },
}

interface WrapperProps {
  size: ButtonSize
  variant: ButtonVariant
  color: ButtonColor
  block: boolean
  checked: boolean
}

const Wrapper: StyledComponent<'button', React.FC, WrapperProps> = styled.button<WrapperProps>`
  z-index: 0;
  height: ${(props) => BUTTON_SIZE[props.size].height};
  width: ${(props) => props.block?"100%":"auto"};
  padding: ${(props) => BUTTON_SIZE[props.size].padding};
  border-radius: ${(props) => BUTTON_SIZE[props.size].borderRadius};
  background-color: ${(props) => BUTTON_VARIANT_COLOR[props.variant][props.checked?props.color:"UnChecked"].background};
  color: ${(props) => BUTTON_VARIANT_COLOR[props.variant][props.checked?props.color:"UnChecked"].color};
  outline: none;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  display: ${(props) => BUTTON_SIZE[props.size].block};
  width: ${(props) => BUTTON_SIZE[props.size].width};
  border: 1px solid ${(props) => BUTTON_VARIANT_COLOR[props.variant][props.checked?props.color:"UnChecked"].borderColor};
  &:disabled {
    background-color: ${(props) => BUTTON_VARIANT_COLOR[props.variant]['UnChecked'].background};
    border: 1px solid ${(props) => BUTTON_VARIANT_COLOR[props.variant]['UnChecked'].borderColor};
    color: ${(props) => BUTTON_VARIANT_COLOR[props.variant]['UnChecked'].color};
    cursor: not-allowed;
  }

  .text-toggle-button-body{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

`

interface Props {
  size?: ButtonSize
  variant?: ButtonVariant
  color?: ButtonColor
  checked?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, value:any) => void
  iconLeftName?: string
  iconRightName?: string
  iconCheckedColor?: string
  block?: boolean
  value?: any
  canClickOnlyUnchecked?: boolean
}
const TextToggleButton: React.FC<Props> = ({
  size = 'S',
  variant = 'Contained',
  color = 'Primary',
  iconLeftName,
  iconRightName,
  iconCheckedColor,
  children,
  checked = false,
  canClickOnlyUnchecked = false,
  disabled,
  block,
  onClick,
  value,
}) => {

  const el = useRef(null);
  const rippleEl = useRef(null);
  return (
    <Wrapper
      ref={el} 
      size={size}
      variant={variant}
      color={color}
      disabled={disabled}
      block={block}
      checked = {checked}
      className='common-effect-parent'
      onClick={(e) => {
        rippleEvent(e, el.current, rippleEl.current);
        if(!canClickOnlyUnchecked || !checked)
          if(onClick) onClick(e, value);
      }}
    >
      <div className="text-toggle-button-body">
        {(iconLeftName) && 
          <div style={{marginRight:BUTTON_SIZE[size].iconMarginGap}}>
            <Icon width={BUTTON_SIZE[size].iconSize} height={BUTTON_SIZE[size].iconSize} fill={checked?(iconCheckedColor?COLOR[iconCheckedColor]:BUTTON_VARIANT_COLOR[variant][color].color):BUTTON_VARIANT_COLOR[variant]["UnChecked"].color} name={iconLeftName}/>
          </div>
        }
        <Text type={BUTTON_SIZE[size].typography}>
          {children}
        </Text>
        {(iconRightName) && 
          <div style={{marginLeft:BUTTON_SIZE[size].iconMarginGap}}>
            <Icon width={BUTTON_SIZE[size].iconSize} height={BUTTON_SIZE[size].iconSize} fill={checked?(iconCheckedColor?COLOR[iconCheckedColor]:BUTTON_VARIANT_COLOR[variant][color].color):BUTTON_VARIANT_COLOR[variant]["UnChecked"].color} name={iconRightName}/>
          </div>
        }
      </div>
      <div  className="common-effect-hover"/>
      <div ref={rippleEl} className="common-effect-ripple"/>

    </Wrapper>
  )
}

export default TextToggleButton
