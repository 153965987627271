import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { IonToggle } from "@ionic/react";
import ViewerBody from "./ViewerBody";
import {
  BoardContent,
  BoardComment,
  BoardAttribute,
} from "../models/Model.Board";
import * as API from "../API.json";
import { fetchAPI } from "../utils/APIUtil";
import { getGlobal, GlobalKey, setGlobal } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";

import AnalyticsUtil from "../utils/AnalyticsUtil";
import badgeIcon from "./../assets/icon/nice_badge.png";
import { Flex, Static } from "./atoms/Layout";
import Tag from "./atoms/Tag";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import Icon from "./atoms/Icon";
import NiceCard from "../assets/image/nice_card.png";
import Button from "./atoms/Button";

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {};

type State = {};

class BoardListSummaryRecommends extends Component<Props, State> {
  recommendsElement = null;
  state = {};

  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardListSummaryRecommends:componentDidMount",
      this.props.summaryList
    );

    this.props.summaryList.map((item: any, index) => {
      this.props.getSummaryGroupCount(item);
    });

    this.autoScroll();

    if (this.recommendsElement) {
      this.recommendsElement.addEventListener("touchstart", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchmove", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchend", this.isolateTouch, {
        passive: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.recommendsElement) {
      this.recommendsElement.removeEventListener(
        "touchstart",
        this.isolateTouch,
        { passive: true }
      );
      this.recommendsElement.removeEventListener(
        "touchmove",
        this.isolateTouch,
        { passive: true }
      );
      this.recommendsElement.removeEventListener(
        "touchend",
        this.isolateTouch,
        { passive: true }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.recommendsElement &&
      (!prevProps.summaryList || prevProps.summaryList.length === 0) &&
      !(!this.props.summaryList || this.props.summaryList.length === 0)
    ) {
      this.recommendsElement.addEventListener("touchstart", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchmove", this.isolateTouch, {
        passive: true,
      });
      this.recommendsElement.addEventListener("touchend", this.isolateTouch, {
        passive: true,
      });
    }
  }

  isolateTouch(e) {
    e.stopPropagation();
  }

  navigateToView = (keyword: string, index: number) => {
    let recommendDepth = 0;
    if (history) recommendDepth = history.length;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "SELECT_CONTENT",
      "게시물_목록_요약집 선택",
      {
        keyword,
      }
    );
    this.props.history.push(`/boards/summary?keyword=${keyword}`, {
      type: BoardType.RECOMMEND,
      index,
    });
  };

  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = async () => {
    // setTimeout(async () => {
    try {
      let scroll = getGlobal(
        GlobalKey.SCROLL + "UnionSummaryRecommend".toString()
      );
      if (scroll) {
        if (this.recommendsElement) {
          this.recommendsElement.scrollTo(scroll, 0);
          log(
            LogLevel.UI_EVENT,
            "BoardListSummaryRecommends:autoScroll contentScroll"
          );
        } else {
          this.autoScrollTry++;
          if (this.autoScrollTry < this.autoScrollTryMax) {
            log(
              LogLevel.UI_EVENT,
              "BoardListSummaryRecommends:autoScroll Try "
            );
            setTimeout(() => this.autoScroll(), 10);
          } else {
            log(
              LogLevel.UI_EVENT,
              "BoardListSummaryRecommends:autoScroll Try FINISHED FAILED"
            );
          }
        }
      } else {
        log(
          LogLevel.UI_EVENT,
          "BoardListSummaryRecommends:autoScroll no scroll "
        );
      }
    } catch (e) {
      log(
        LogLevel.UI_EXCEPTION,
        "BoardListSummaryRecommends:autoScroll failed",
        e
      );
      this.autoScrollTry++;
      if (this.autoScrollTry < this.autoScrollTryMax) {
        log(
          LogLevel.UI_EVENT,
          "BoardListSummaryRecommends:autoScroll Try ",
          this.autoScrollTry,
          scroll
        );
        setTimeout(() => this.autoScroll(), 10);
      } else {
        log(
          LogLevel.UI_EVENT,
          "BoardListSummaryRecommends:autoScroll Try FINISHED FAILED"
        );
      }
    }
    // }, 100);
  };

  onScroll = (event) => {
    let target: any = event.target;
    log(
      LogLevel.UI_ACTION,
      "BoardListSummaryRecommends:renderContent",
      target.scrollLeft
    );

    if (!target || !this.recommendsElement) return;

    setGlobal(GlobalKey.SCROLL + "UnionSummaryRecommend", target.scrollLeft);
  };

  renderSummary = (keyword: string, count: number, index) => {
    let rvalue = (
      <div
        key={index.toString()}
        style={{ flex: "none" }}
        onClick={() => this.navigateToView(keyword, index)}
      >
        <Flex
          direction="column"
          justifyContent="space-between"
          gap="12px"
          customStyle={{
            padding: "12px",
            width: "135px",
            borderRadius: "8px",
            transform: "translateZ(0)",
            overflow: "show",
            backgroundColor: COLOR_SYSTEM.get("Gray")[0],
            border: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
          }}
        >
          <Text
            textType="Body2SemiBold"
            color={COLOR_SYSTEM.get("Blue")[400]}
            numberOfLines={2}
          >
            #{keyword}
          </Text>
          <Flex justifyContent="space-between">
            {count > 0 ? (
              <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
                핵심 요약 {count}개
              </Text>
            ) : (
              <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[600]}>
                &nbsp;
              </Text>
            )}

            <Icon
              name={"CaretRight"}
              size={16}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
          </Flex>
        </Flex>
      </div>
    );
    return rvalue;
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardListSummaryRecommends:render");

    return (
      <>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          customStyle={{ padding: "24px 20px 12px 20px" }}
        >
          <Flex direction="column">
            <Flex gap="4px" alignItems="center">
              <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[900]}>
                AI 핵심 요약집
              </Text>
              <Icon
                name={"Sparkle Filled"}
                size={16}
                color={COLOR_SYSTEM.get("Yellow")[400]}
              />
            </Flex>
            <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[900]}>
              특정 주제의 전문성을 키워보세요.
            </Text>
          </Flex>
          <img src={"/images/summary_folder.png"} style={{ width: "80px" }} />
        </Flex>
        <Static
          ref={(ref) => {
            this.recommendsElement = ref;
          }}
          style={{
            width: "100%",
            maxWidth: "100%",
            padding: "12px 20px 16px 20px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            overflowX: "scroll",
            transform: "translateZ(0)",
            marginTop: "0px",
          }}
          onScroll={this.onScroll}
        >
          {this.props.summaryList.map((item: any, index) => {
            let count = 0;
            if (
              this.props.summaryGroups[item] &&
              this.props.summaryGroups[item].count > 0
            )
              count = this.props.summaryGroups[item].count;
            return this.renderSummary(item, count, index);
          })}
        </Static>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  me: state.user.me,
  summaryList: state.board.summaryList,
  summaryGroups: state.board.summaryGroups,
});

const mapDispatchToProps = {
  getSummaryGroupCount: (keyword: string) =>
    actions.board.getSummaryGroupCount(keyword),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardListSummaryRecommends)
);
