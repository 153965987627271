import { LOG } from "./../config.json";

export enum LogLevel {
  NONE = 0x00,
  ALL = 0xffff,
  REDUX = 0x081f,
  REDUX_BOARD = 0x0001,
  REDUX_USER = 0x0002,
  REDUX_SEARCH = 0x0004,
  REDUX_MEDICINE = 0x0008,
  REDUX_NOTIFICATION = 0x0010,
  REDUX_EVENT = 0x0800,
  UI_DATA_LOAD = 0x20,
  UI_POPUP = 0x40,
  UI_ACTION = 0x80,
  UI_EVENT = 0x100,
  UI_EXCEPTION = 0x200,
  UI_LIFECYCLE = 0x400,
}
// loglevel which is LogLevel.ALL except LogLevel.REDUX_BOARD
const loglevel = LogLevel.ALL;
// const loglevel = LogLevel.ALL & ~LogLevel.REDUX;
// const loglevel = (LogLevel.REDUX_BOARD | LogLevel.UI_DATA_LOAD);

export function log(level: LogLevel, ...optionalParams: any[]): void {
  if (LOG && (level & loglevel) !== 0) {
    console.log(LogLevel[level], ...optionalParams);
  }
}
