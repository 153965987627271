import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonSelect,
  IonText,
  IonSelectOption,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonSegment,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
// import './BannerManage.css'
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";
import { getGlobal, GlobalKey } from "./../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import { BoardContent, Attachment } from "../models/Model.Board";
import { BannerInfo, EMPTY_BANNER, BannerType } from "../models/Model.Banner";
import BannerEdit from "./component/BannerEdit";
import BannerSelect from "./component/BannerSelect";
import SeminarSelect from "./component/seminar/SeminarSelect";
import { SeminarLecture } from "../models/Model.Seminar";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  banner: BannerInfo;
  viewOption: BannerManageSecondViewOption;
};

export enum BannerManageSecondViewOption {
  NONE,
  LIST,
  SEMINAR,
}

class BannerManage extends Component<Props, State> {
  state = {
    banner: { ...EMPTY_BANNER },
    viewOption: BannerManageSecondViewOption.LIST,
  };
  bannerlist = null;
  bannerEditor = null;

  constructor(props: Props) {
    super(props);
    document.title = "배너 관리";
  }

  onDone = (banner: BannerInfo) => {
    this.setState({ banner });
    this.bannerlist.onChange();
  };

  onSelect = (banner: BannerInfo) => {
    if (!banner) banner = { ...EMPTY_BANNER };
    this.setState({ banner });
  };

  onSelectSeminar = (seminar: SeminarLecture) => {
    let banner: BannerInfo = {
      name: "Seminar-" + seminar.title,
      imageUrl:
        this.state.banner.type == BannerType.BOARD
          ? seminar.bannerUrl
          : seminar.shareUrl,
      targetUrl: "/lecture?id=" + seminar.id,
    };
    if (this.bannerEditor) this.bannerEditor.setBannerInfo(banner);
  };

  render() {
    // log(LogLevel.UI_LIFECYCLE, "BannerManage.render", this.props);
    return (
      <>
        <IonHeader class="admin-header" no-border>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonText slot="middle">배너 관리</IonText>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                this.setState({
                  viewOption: BannerManageSecondViewOption.LIST,
                });
              }}
            >
              목록보기
            </IonButton>
            <IonButton
              onClick={() => {
                this.setState({
                  viewOption: BannerManageSecondViewOption.SEMINAR,
                });
              }}
            >
              강의보기
            </IonButton>
          </IonButtons>
        </IonHeader>

        <IonContent>
          <div className="admin-main-container">
            <div className="admin-container">
              <BannerEdit
                ref={(ref) => (this.bannerEditor = ref)}
                banner={this.state.banner}
                onDone={this.onDone}
              />
            </div>
            <div
              id="admin-sub-conainer"
              className={
                "admin-container" +
                (this.state.viewOption == BannerManageSecondViewOption.LIST
                  ? ""
                  : " admin-container-hidden")
              }
            >
              <BannerSelect
                ref={(ref) => (this.bannerlist = ref)}
                onSelect={this.onSelect}
              />
            </div>
            <div
              id="admin-sub-conainer"
              className={
                "admin-container" +
                (this.state.viewOption == BannerManageSecondViewOption.SEMINAR
                  ? ""
                  : " admin-container-hidden")
              }
            >
              <SeminarSelect onDone={this.onSelectSeminar} />
            </div>
          </div>
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannerManage)
);
