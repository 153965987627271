import React, { useEffect, useRef, useState } from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { IonSpinner } from '@ionic/react'
import { SeminarLecture, SeminarLectureType, SeminarReview, SeminarReviewMent } from '../../../models/Model.Seminar';
import { Attachment } from '../../../models/Model.Board';
import Header from '../../molecule/Header';
import Body from '../../molecule/Body';
import StringUtil from '../../../utils/StringUtil';
import Scroll from '../../molecule/Scroll';
import { log, LogLevel } from '../../../utils/LogUtil';
import { ScrollAction } from '../../molecule/Scroll';
import Video from '../../atom/Video';
import VideoController from '../../cell/VideoController';
import { getGlobal, GlobalKey } from '../../../utils/GlobalUtil';
import IconButton from '../../atom/IconButton';
import Footer from '../../molecule/Footer';
import TextButton from '../../atom/TextButton';
import Badge from '../../atom/Badge';
import Text from '../../atom/Text';
import Icon from '../../atom/Icon';
import { extractTimeToJTag, extractTimeValue, getTimerString } from '../../../utils/TimeUtil';
import color from '../../_design/color';
import StarScore from '../../atom/StarScore';
import { TabBar } from '../../cell/TabBar';
import Spinner from '../../atom/Spinner';
import Avatar from '../../atom/Avatar';
import './style.scss';
import Thumbnail from '../../atom/Thumbnail';
import ProgressBar from '../../atom/ProgressBar';
import { rippleEvent } from '../../_util/RrppleEffect';
import StarScorer from '../../atom/StarScorer';
import { useDispatch, useSelector } from 'react-redux';
import { actions, RootState } from '../../../store';
import { UIPopupType } from '../../../store/ui/types';
import { fetchAPI } from '../../../utils/APIUtil';
import * as API from './../../../API.json';


interface Props {
  seminar:SeminarLecture,
  onClick: (seminar: SeminarLecture) => void,
  key?: string,
  category?: number;
}
const SeminarListItem: FC<Props> = ({
  key,
  seminar,
  onClick,
  category,
}) => {
  log(LogLevel.UI_LIFECYCLE, "SeminarListItem:", seminar, category, seminar.myReview);
  const el = useRef(null);
  const rippleEl = useRef(null);
  const [score, setScore] = useState<number>(0);
  const progressPopup = useSelector((state:RootState) => state.ui.popups[UIPopupType.WAITING_POPUP]);
  const toastPopup = useSelector((state:RootState) => state.ui.popups[UIPopupType.TOAST_POPUP]);
  const dispatch = useDispatch();

  const onReview = async () => {
    log(LogLevel.UI_LIFECYCLE, "SeminarListItem:onReview", seminar, category, seminar.myReview, score);

    if(progressPopup) progressPopup.show();
    if(score){
      let review: SeminarReview = {
        lectureId: seminar.id,
        score
      }
      seminar.myReview = review;

      let result = await fetchAPI(API.SEMINAR_REVIEW_POST, "", null, review, getGlobal(GlobalKey.TOKEN));
      if(result && !result.error){
        dispatch(actions.seminar.fetchSeminarLecture.success(seminar));
        toastPopup.show("👏  평가가 등록되었어요!")
      }
    }
    if(progressPopup) progressPopup.hide();
  }

  if(!seminar)
    return (
      <Spinner block/>
    )

  let rvalue = (
    <div ref={el} key={key} className="common-effect-parent common-position-relative common-block-line common-container-row" style={{paddingTop:"12px", paddingBottom:"12px", marginTop:"12px"}} 
      onClick={(e) => {
        rippleEvent(e, el.current, rippleEl.current);

        if(onClick)
          onClick(seminar)
      }}>
      <div className="common-flex-grow">
        {(seminar.categories && seminar.categories.length) && 
          <div className="common-container-row common-flex-align-center common-flex-row-wrap" style={{marginBottom:"8px"}}>
            {seminar.categories.map((category, index) => {
              return (
                <div key={index.toString()} style={{marginRight:"8px"}}>
                  <Badge size="S" variant="Tinted" color="Gray1">{category.title}</Badge>
                </div>
              );
            })}
          </div>
        }
        <div style={{marginBottom:"4px"}}>
          <Text type="SubHead" color="Black" numberOfLines={2}>{seminar.title}</Text>
        </div>
        <div style={{marginBottom:"5px"}}>
          <Text type="Caption2" color="Gray2" numberOfLines={1}>{(seminar.lecturers && seminar.lecturers.length > 0) && seminar.lecturers.map((lecturer) => lecturer.name).join(" · ")}</Text>
        </div>
        <div className="common-container-row common-flex-align-center">
          <div style={{height:"16px", marginRight:"4px"}}>
            <Icon width={16} height={16} colorName="Gray3" name="Eye Filled"/>
          </div>
            <Text style={{marginRight:"12px"}} type="Label" color="Gray3">{seminar.viewCount}</Text>
          <div style={{marginRight:"8px"}}>
            <StarScore size="S" score={seminar.scoreAverage}/>
          </div>
          <Text type="Label" color="Gray3">({seminar.scoreCount})</Text>
        </div>
      </div>
      <div className="seminar-list-item-thumbnail" style={{marginLeft:"16px"}}>
        <Thumbnail src={seminar.thumbnailUrl}>
          { (seminar.type == SeminarLectureType.SEMINAR) && 
            <div className="seminar-list-item-playtime">
              <Badge size="S" variant="Contained" color="Shadow">{getTimerString(seminar.playtime)}</Badge>
            </div>
          }
          { (seminar.type == SeminarLectureType.CARDNEWS && seminar.cards && seminar.cards.length > 0) && 
            <div className="seminar-list-item-playtime">
              <Badge size="S" variant="Contained" color="Shadow" iconName="Pages Filled">{seminar.cards.length}</Badge>
            </div>
          }
          { (seminar.type == SeminarLectureType.SEMINAR && !!seminar.lastPlayedTime) && 
            <div className="seminar-list-item-progress-container">
              <ProgressBar progress={(seminar.lastPlayedTime/seminar.playtime*100)}/>
            </div>
          }
        </Thumbnail>
      </div>
      <div className="common-effect-hover"/>
      <div ref={rippleEl} className="common-effect-ripple"/>
    </div>
  )
  if(category == -1 && !seminar.myReview && (!seminar.passingMark || (seminar.viewValue && seminar.viewValue > seminar.passingMark))){
    return (
      <div className="common-container">
        {rvalue}
        <div className="common-block-line" style={{paddingBottom:"12px"}}>
          <div className="common-block-sub">
            <div style={{marginBottom:"8px"}}>
             <Text type="Body2" color="Secondary">이 콘텐츠 어땠어요?</Text>
            </div>
            <div className="common-container-row common-flex-align-center">
              <StarScorer size="L" score={score} onScoreChange={(score) => setScore(score)}/>
              <div className="common-flex-grow"/>
              <TextButton size="S" variant="Outlined" color="Primary" disabled={!score} onClick={onReview}>등록하기</TextButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return rvalue;
}

export default React.memo(SeminarListItem, (props, nextProps) => { 
  if(props.seminar != nextProps.seminar)
    return false;
  return true;
})