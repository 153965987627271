import { action, createAction, createAsyncAction } from 'typesafe-actions';
import {EventActionTypes} from './types'

export const getEvents = createAction(EventActionTypes.EVENTS_FETCH, resolve =>
  () => resolve()
);

export const fetchEvents = createAsyncAction(
  EventActionTypes.EVENTS_REQUEST,
  EventActionTypes.EVENTS_SUCCESS,
  EventActionTypes.EVENTS_ERROR,
)<any, any, any>(); // <string, EventList, Error>();

export const ignoreEvent = createAction(EventActionTypes.EVENT_NOT_TODAY, resolve =>
  (id:number) => resolve(id)
);