import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./../Admin.scss";
import { log, LogLevel } from "../../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "../../utils/GlobalUtil";
import { fetchAPI } from "../../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../../models/Model.Seminar";
import { UIPopupType } from "../../store/ui/types";
import { STATUS_CODES } from "http";
import { idText, StringLiteralLike } from "typescript";
import { getDateStringFromToday } from "../../utils/TimeUtil";
import { timingSafeEqual } from "crypto";
import ReactHtmlParser from "react-html-parser";
import { getOS } from "../../utils/DeviceUtil";
import { isThisHour } from "date-fns";
import * as API from "../../API.json";
import StringUtil from "../../utils/StringUtil";
import Textarea from "react-textarea-autosize";
import DownloadUtil from "../../utils/DownloadUtil";
import { MY_ADDRESS } from "../../config.json";
import { BoardContent } from "../../models/Model.Board";
import { BoardSortType } from "../../store/board/types";
import CheckBox from "../../components/atoms/CheckBox/CheckBox";
import Button from "../../components/atoms/Button";
import { List } from "immutable";
import ToggleButton from "../../components/atoms/ToggleButton/ToggleButton";
import ABTestUtil from "../../utils/ABTestUtil";
import copy from "copy-to-clipboard";
import Input from "../../components/atoms/Input";
import Switch from "../../components/atoms/Switch/Switch";

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onSelect: (id: number) => void;
  };

type State = {
  list: BoardContent[];
  order: BoardSortType;
  subOrder: string;
  subOrderDirect: string;
  selectedIndex: number;
  finished: boolean;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  search: string;
  unitCount: number;
  scoreBoard: number[]; // 전체좋아요수, 스크랩수, 나도궁금해요수, 답변수, 댓글수 , 공유수, 조회수
  category: string;
  editDate: string;
  editDateIndex: number;
  resultGap: number;
  excludeSent: boolean;
};

class WeeklyDigestNiceList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let score = getGlobal(GlobalKey.ADMIN_DIGEST_SCORE, true);
    let scoreBoard = score.score;
    if (!scoreBoard) scoreBoard = [10, 10, 0, 0, 0, 10, 1, 10000];

    let resultGap = score.resultGap;
    if (!resultGap) resultGap = 7;

    while (scoreBoard.length <= 7) scoreBoard.push(10000);

    this.state = {
      list: [],
      order: BoardSortType.NICE,
      subOrder: "",
      subOrderDirect: "asc",
      selectedIndex: -1,
      finished: false,
      dateStart: ABTestUtil.isTest()
        ? "2019-12-17"
        : getDateStringFromToday({ months: -1 }),
      dateStartWarn: false,
      dateEnd: getDateStringFromToday({ days: 1 }),
      dateEndWarn: false,
      search: "",
      unitCount: 200,
      scoreBoard,
      category: "0",
      editDate: "",
      editDateIndex: -1,
      resultGap,
      excludeSent: false,
    };
  }
  loading = false;

  fetchList = async () => {
    if (this.loading) return;

    this.loading = true;
    this.props.progressPopup.show();

    let result = await fetchAPI(
      API.BOARD_LIST_REQUEST,
      this.state.category,
      {
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        sort: this.state.order,
        nice: true,
        search: this.state.search,
        categoryId: this.state.category,
        digestStatisticsGap:
          this.state.order == BoardSortType.DIGEST ? this.state.resultGap : "",
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        list: this.caculateScore(result.data),
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ list: [], finished: true });
    }
    this.props.progressPopup.hide();

    this.loading = false;
    log(LogLevel.UI_EVENT, "WeeklyDigestNiceList:fetchList", result);
  };

  fetchListMore = async () => {
    if (this.loading) return;
    this.loading = true;
    this.props.progressPopup.show();
    let result = await fetchAPI(
      API.BOARD_LIST_REQUEST,
      this.state.category,
      {
        start: this.state.list.length,
        count: this.state.unitCount,
        startDate: this.state.dateStart,
        endDate: this.state.dateEnd,
        sort: this.state.order,
        nice: true,
        search: this.state.search,
        categoryId: this.state.category,
        digestStatisticsGap:
          this.state.order == BoardSortType.DIGEST ? this.state.resultGap : "",
      },
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      this.setState({
        list: [...this.state.list, ...this.caculateScore(result.data)],
        finished: result.data.length < this.state.unitCount,
      });
    } else {
      this.setState({ finished: true });
    }
    this.props.progressPopup.hide();
    this.loading = false;
    log(LogLevel.UI_EVENT, "WeeklyDigestNiceList:fetchList", result);
  };

  caculateScore(list: BoardContent[]) {
    if (!list || !list.length) return;

    for (let i = 0; i < list.length; i++) {
      list[i] = { ...list[i] };
      list[i].score = // 전체좋아요수, 스크랩수, 나도궁금해요수, 답변수, 댓글수 , 공유수, 조회수
        this.state.scoreBoard[0] *
          (list[i].likeCntAll ? list[i].likeCntAll : 0) +
        this.state.scoreBoard[1] * (list[i].scrapCnt ? list[i].scrapCnt : 0) +
        this.state.scoreBoard[2] * (list[i].meTooCnt ? list[i].meTooCnt : 0) +
        this.state.scoreBoard[3] * (list[i].replyCnt ? list[i].replyCnt : 0) +
        this.state.scoreBoard[4] *
          (list[i].commentCnt ? list[i].commentCnt : 0) +
        this.state.scoreBoard[5] * (list[i].sharedCnt ? list[i].sharedCnt : 0) +
        this.state.scoreBoard[6] * (list[i].viewCnt ? list[i].viewCnt : 0) +
        this.state.scoreBoard[7] *
          (list[i].digestCandidate ? list[i].digestCandidate : 0);
    }

    return list;
  }

  onChangeScoreBoard = () => {
    log(
      LogLevel.UI_EVENT,
      "WeeklyDigestNiceList:onChangeScoreBoard",
      this.state
    );
    setGlobal(
      GlobalKey.ADMIN_DIGEST_SCORE,
      { score: this.state.scoreBoard, resultGap: this.state.resultGap },
      true
    );
    if (this.state.list && this.state.list.length > 0)
      this.setState({ list: [...this.caculateScore(this.state.list)] });
  };

  onDownload = () => {
    this.props.progressPopup.show();
    let csv = "";
    if (this.state.order == BoardSortType.DIGEST) {
      csv =
        "ID,게시판,제목,작성일,추천일,선정일,전좋아요수,전스크랩수,전나궁수,전답변수,전댓글수,전공유수,전조회수,후좋아요수,후좋아요증감,후스크랩수,후스크랩증감,후나궁수,후나궁증감,후답변수,후답변증감,후댓글수,후댓글증감,후공유수,후공유증감,후조회수,후조회증감\r\n";
      csv += this.state.list
        .map((item: BoardContent, index) => {
          let rvalue = "";
          rvalue += `${item.id},`;
          rvalue += `${item.boardsCategoriesName},`;
          rvalue += `"${item.subject
            .substring(0, 30)
            .replace(/\n/g, "⎆")
            .replace(/\t/g, " ")}",`;
          rvalue += `${
            item.createdAt
              ? getDateStringFromToday({ date: item.createdAt })
              : ""
          },`;
          rvalue += `${
            item.nicedAt ? getDateStringFromToday({ date: item.nicedAt }) : ""
          },`;
          rvalue += `${
            item.digestedAt
              ? getDateStringFromToday({
                  date: item.digestedAt,
                  showTime: true,
                })
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore && item.digestStatisticBefore.length == 7
              ? item.digestStatisticBefore[1]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore && item.digestStatisticBefore.length == 7
              ? item.digestStatisticBefore[2]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore && item.digestStatisticBefore.length == 7
              ? item.digestStatisticBefore[6]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore && item.digestStatisticBefore.length == 7
              ? item.digestStatisticBefore[3]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore && item.digestStatisticBefore.length == 7
              ? item.digestStatisticBefore[5]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore && item.digestStatisticBefore.length == 7
              ? item.digestStatisticBefore[4]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore && item.digestStatisticBefore.length == 7
              ? item.digestStatisticBefore[0]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticAfter && item.digestStatisticAfter.length == 7
              ? item.digestStatisticAfter[1]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore &&
            item.digestStatisticBefore.length == 7 &&
            item.digestStatisticAfter &&
            item.digestStatisticAfter.length == 7
              ? `+${
                  item.digestStatisticAfter[1] - item.digestStatisticBefore[1]
                }`
              : ""
          },`;
          rvalue += `${
            item.digestStatisticAfter && item.digestStatisticAfter.length == 7
              ? item.digestStatisticAfter[2]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore &&
            item.digestStatisticBefore.length == 7 &&
            item.digestStatisticAfter &&
            item.digestStatisticAfter.length == 7
              ? `+${
                  item.digestStatisticAfter[2] - item.digestStatisticBefore[2]
                }`
              : ""
          },`;
          rvalue += `${
            item.digestStatisticAfter && item.digestStatisticAfter.length == 7
              ? item.digestStatisticAfter[6]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore &&
            item.digestStatisticBefore.length == 7 &&
            item.digestStatisticAfter &&
            item.digestStatisticAfter.length == 7
              ? `+${
                  item.digestStatisticAfter[6] - item.digestStatisticBefore[6]
                }`
              : ""
          },`;
          rvalue += `${
            item.digestStatisticAfter && item.digestStatisticAfter.length == 7
              ? item.digestStatisticAfter[3]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore &&
            item.digestStatisticBefore.length == 7 &&
            item.digestStatisticAfter &&
            item.digestStatisticAfter.length == 7
              ? `+${
                  item.digestStatisticAfter[3] - item.digestStatisticBefore[3]
                }`
              : ""
          },`;
          rvalue += `${
            item.digestStatisticAfter && item.digestStatisticAfter.length == 7
              ? item.digestStatisticAfter[5]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore &&
            item.digestStatisticBefore.length == 7 &&
            item.digestStatisticAfter &&
            item.digestStatisticAfter.length == 7
              ? `+${
                  item.digestStatisticAfter[5] - item.digestStatisticBefore[5]
                }`
              : ""
          },`;
          rvalue += `${
            item.digestStatisticAfter && item.digestStatisticAfter.length == 7
              ? item.digestStatisticAfter[4]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore &&
            item.digestStatisticBefore.length == 7 &&
            item.digestStatisticAfter &&
            item.digestStatisticAfter.length == 7
              ? `+${
                  item.digestStatisticAfter[4] - item.digestStatisticBefore[4]
                }`
              : ""
          },`;
          rvalue += `${
            item.digestStatisticAfter && item.digestStatisticAfter.length == 7
              ? item.digestStatisticAfter[0]
              : ""
          },`;
          rvalue += `${
            item.digestStatisticBefore &&
            item.digestStatisticBefore.length == 7 &&
            item.digestStatisticAfter &&
            item.digestStatisticAfter.length == 7
              ? `+${
                  item.digestStatisticAfter[0] - item.digestStatisticBefore[0]
                }`
              : ""
          },`;
          rvalue += `\r\n`;

          return rvalue;
        })
        .join("");
    } else {
      csv =
        "ID,게시판,제목,작성일,추천일,좋아요수,스크랩수,나.궁.수,답변수,댓글수,공유수,조회수,스코어,선정일,후보\r\n";
      csv += this.state.list
        .map((item: BoardContent, index) => {
          let rvalue = "";
          rvalue += `${item.id},`;
          rvalue += `${item.boardsCategoriesName},`;
          rvalue += `"${item.subject
            .substring(0, 30)
            .replace(/\n/g, "⎆")
            .replace(/\t/g, " ")}",`;
          rvalue += `${
            item.createdAt
              ? getDateStringFromToday({ date: item.createdAt })
              : ""
          },`;
          rvalue += `${
            item.nicedAt ? getDateStringFromToday({ date: item.nicedAt }) : ""
          },`;
          rvalue += `${item.likeCntAll},`;
          rvalue += `${item.scrapCnt},`;
          rvalue += `${item.meTooCnt},`;
          rvalue += `${item.replyCnt},`;
          rvalue += `${item.commentCnt},`;
          rvalue += `${item.sharedCnt},`;
          rvalue += `${item.viewCnt},`;
          rvalue += `${item.score},`;
          rvalue += `${
            item.digestedAt
              ? getDateStringFromToday({ date: item.digestedAt })
              : ""
          },`;
          rvalue += `${item.digestCandidate}\r\n`;

          return rvalue;
        })
        .join("");
    }

    DownloadUtil.downloadCsv(
      csv,
      `NiceBoardList-${this.state.dateStart}-${
        this.state.dateEnd
      }-${new Date().toISOString().substring(0, 10).replace(/-/gi, "")}.csv`
    );
    this.props.progressPopup.hide();
  };

  onSelect = (detail, index) => {
    this.props.onSelect(detail.id);
    if (index == this.state.selectedIndex) index = -1;
    this.setState({ selectedIndex: index });
  };

  onOrder = (e, subOrder) => {
    e.stopPropagation();
    let subOrderDirect = "desc";
    if (subOrder == this.state.subOrder && this.state.subOrderDirect == "desc")
      subOrderDirect = "asc";

    let list = this.state.list.sort((a, b) => {
      return (
        (a[subOrder] < b[subOrder] || a[subOrder] == null
          ? -1
          : a[subOrder] > b[subOrder] || b[subOrder] == null
          ? 1
          : 0) * (subOrderDirect == "desc" ? -1 : 1)
      );
    });
    this.setState({ subOrder, subOrderDirect, list });
  };

  onScoreChange = (e, index) => {
    let value = e.target.value;
    let scoreBoard = [...this.state.scoreBoard];
    try {
      if (!value) scoreBoard[index] = 0;
      else scoreBoard[index] = Number(value);
    } catch (err) {
      scoreBoard[index] = 0;
    }
    this.setState({ scoreBoard });
  };

  onDateChange = (e, field) => {
    let dateString = e.target.value;
    let dateWarn = false;
    try {
      let date = Date.parse(dateString);
      if (!date) {
        log(
          LogLevel.UI_EXCEPTION,
          "Admin:WeeklyDigestNiceList:onDateEndChange",
          dateString
        );
        dateWarn = true;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:WeeklyDigestNiceList:onDateEndChange",
          dateString,
          date
        );
      }
    } catch (err) {
      log(
        LogLevel.UI_EXCEPTION,
        "Admin:WeeklyDigestNiceList:onDateEndChange",
        dateString,
        err
      );
      dateWarn = true;
    }
    let newState: any = {};
    (newState[field] = dateString),
      (newState[field + "Warn"] = dateWarn),
      this.setState(newState);
  };

  onLink = (item: BoardContent, index, e) => {
    e.stopPropagation();
    copy(
      `${item.id}\t${item.subject}\t${MY_ADDRESS}/boards/view?id=${item.id}`
    );
    this.props.toastPopup.show("클립보드에 글정보가 저장되었습니다.");
  };

  onDigest = (item: BoardContent, index, e) => {
    e.stopPropagation();
    copy(MY_ADDRESS + "/boards/view?id=" + item.id);
    this.props.toastPopup.show("클립보드에 주소가 저장되었습니다.");
    this.props.confirmPopup.show({
      iconImage: null,
      title: (
        <div className="common-container">
          <span className="common-bold">WeeklyDigest</span>History를{" "}
          <span className="common-bold common-color-caution">오늘로 추가</span>{" "}
          하시겠습니까?
        </div>
      ),
      body: (
        <div className="common-container">
          {item.digestCandidate == -1 && (
            <span className="common-bold common-color-caution">
              제외 항목입니다.
              <br />
            </span>
          )}
          원복이 불가능합니다.
        </div>
      ),
      onDone: async () => {
        let newBoard: BoardContent = {
          id: item.id,
          digestedAt: getDateStringFromToday({}),
          digestCandidate: 0,
          managedAt: 1,
        };
        let result = await fetchAPI(
          API.BOARD_UPDATE,
          "",
          null,
          newBoard,
          getGlobal(GlobalKey.TOKEN)
        );
        if (result && !result.error) {
          let list = [...this.state.list];
          list[index] = {
            ...item,
            digestCandidate: 0,
            digestedAt: getDateStringFromToday({}),
          };
          this.setState({ list });
        }
      },
      doneText: "네",
      cancelText: "아니오",
    });
  };

  onDigestCandidateCheck = async (item: BoardContent, value, index, e) => {
    e.stopPropagation();
    if (item.digestCandidate == value) item.digestCandidate = 0;
    else item.digestCandidate = value;
    let newBoard: BoardContent = {
      id: item.id,
      digestCandidate: item.digestCandidate,
      managedAt: 1,
    };
    let result = await fetchAPI(
      API.BOARD_UPDATE,
      "",
      null,
      newBoard,
      getGlobal(GlobalKey.TOKEN)
    );
    if (result && !result.error) {
      let list = [...this.state.list];
      list[index] = { ...item };
      this.setState({ list });
    }
  };

  renderDigestResult() {
    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            <td
              rowSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "digestedAt")}
            >
              링크
            </td>
            <td
              rowSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "id")}
            >
              ID{" "}
              {this.state.subOrder == "id"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              rowSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "boardsCategoriesId")}
            >
              게시판{" "}
              {this.state.subOrder == "boardsCategoriesId"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td rowSpan={2} className="admin-table-label-x">
              제목
            </td>
            <td
              rowSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "createdAt")}
            >
              작성일{" "}
              {this.state.subOrder == "createdAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              rowSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "nicedAt")}
            >
              추천일{" "}
              {this.state.subOrder == "nicedAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              rowSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "digestedAt")}
            >
              선정일
              {this.state.subOrder == "digestedAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td colSpan={7} className="admin-table-label-x">
              선정일 기준 지표
            </td>
            <td colSpan={14} className="admin-table-label-x">
              선정일이후 {this.state.resultGap}일후 지표
            </td>
          </tr>
          <tr>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "likeCntAll")}
            >
              좋아요수
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "scrapCnt")}
            >
              스크랩수
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              나.궁.수
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "replyCnt")}
            >
              답변수
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "commentCnt")}
            >
              댓글수
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              공유수
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "viewCnt")}
            >
              조회수
            </td>
            <td
              colSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "likeCntAll")}
            >
              좋아요수
            </td>
            <td
              colSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "scrapCnt")}
            >
              스크랩수
            </td>
            <td
              colSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              나.궁.수
            </td>
            <td
              colSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "replyCnt")}
            >
              답변수
            </td>
            <td
              colSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "commentCnt")}
            >
              댓글수
            </td>
            <td
              colSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              공유수
            </td>
            <td
              colSpan={2}
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "viewCnt")}
            >
              조회수
            </td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.list.map((item: BoardContent, index) => {
            return (
              <tr
                key={index.toString()}
                onClick={() => this.onSelect(item, index)}
              >
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  <Button
                    size={"Small"}
                    type={"Icon"}
                    variant={"Contained"}
                    color={"Primary"}
                    icon="Link"
                    onClick={(e) => this.onLink(item, index, e)}
                  ></Button>
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.id}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.boardsCategoriesName}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.subject.substring(0, 30)}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.createdAt
                    ? getDateStringFromToday({ date: item.createdAt })
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.nicedAt
                    ? getDateStringFromToday({ date: item.nicedAt })
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onDoubleClick={() => {
                    if (index == this.state.editDateIndex) {
                      this.setState({ editDateIndex: -1 });
                      return;
                    }
                    if (item.digestedAt)
                      this.setState({
                        editDate: getDateStringFromToday({
                          date: item.digestedAt,
                          showTime: true,
                        }),
                        editDateIndex: index,
                      });
                  }}
                >
                  {item.digestedAt ? (
                    index == this.state.editDateIndex ? (
                      <Input
                        value={this.state.editDate}
                        onChange={(e) =>
                          this.setState({ editDate: e.target.value })
                        }
                        onEnter={async () => {
                          if (this.state.editDate) {
                            let date = Date.parse(this.state.editDate);
                            if (!date) {
                              this.props.toastPopup(
                                "날짜 형식이 정확하지 않습니다."
                              );
                              return;
                            }
                          }
                          let newBoard: BoardContent = {
                            id: item.id,
                            digestedAt: this.state.editDate,
                            managedAt: 1,
                          };
                          let result = await fetchAPI(
                            API.BOARD_UPDATE,
                            "",
                            null,
                            newBoard,
                            getGlobal(GlobalKey.TOKEN)
                          );
                          if (result && !result.error) {
                            let list = [...this.state.list];
                            list[index] = {
                              ...item,
                              digestedAt: this.state.editDate,
                            };
                            this.setState({ list, editDateIndex: -1 });
                          }
                        }}
                      />
                    ) : (
                      getDateStringFromToday({
                        date: item.digestedAt,
                        showTime: true,
                      })
                    )
                  ) : (
                    ""
                  )}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7
                    ? item.digestStatisticBefore[1]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7
                    ? item.digestStatisticBefore[2]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7
                    ? item.digestStatisticBefore[6]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7
                    ? item.digestStatisticBefore[3]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7
                    ? item.digestStatisticBefore[5]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7
                    ? item.digestStatisticBefore[4]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7
                    ? item.digestStatisticBefore[0]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? item.digestStatisticAfter[1]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7 &&
                  item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? `(+${
                        item.digestStatisticAfter[1] -
                        item.digestStatisticBefore[1]
                      })`
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? item.digestStatisticAfter[2]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7 &&
                  item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? `(+${
                        item.digestStatisticAfter[2] -
                        item.digestStatisticBefore[2]
                      })`
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? item.digestStatisticAfter[6]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7 &&
                  item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? `(+${
                        item.digestStatisticAfter[6] -
                        item.digestStatisticBefore[6]
                      })`
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? item.digestStatisticAfter[3]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7 &&
                  item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? `(+${
                        item.digestStatisticAfter[3] -
                        item.digestStatisticBefore[3]
                      })`
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? item.digestStatisticAfter[5]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7 &&
                  item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? `(+${
                        item.digestStatisticAfter[5] -
                        item.digestStatisticBefore[5]
                      })`
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? item.digestStatisticAfter[4]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7 &&
                  item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? `(+${
                        item.digestStatisticAfter[4] -
                        item.digestStatisticBefore[4]
                      })`
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? item.digestStatisticAfter[0]
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.digestStatisticBefore &&
                  item.digestStatisticBefore.length == 7 &&
                  item.digestStatisticAfter &&
                  item.digestStatisticAfter.length == 7
                    ? `(+${
                        item.digestStatisticAfter[0] -
                        item.digestStatisticBefore[0]
                      })`
                    : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderBoardContents() {
    return (
      <table id="admin-table" className="admin-table">
        <thead className="admin-table-fixed-header">
          <tr>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "digestedAt")}
            >
              링크
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "digestedAt")}
            >
              선정
              {this.state.subOrder == "digestedAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "digestCandidate")}
            >
              후보
              {this.state.subOrder == "digestCandidate"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>

            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "id")}
            >
              ID{" "}
              {this.state.subOrder == "id"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "boardsCategoriesId")}
            >
              게시판{" "}
              {this.state.subOrder == "boardsCategoriesId"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td className="admin-table-label-x">제목</td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "createdAt")}
            >
              작성일{" "}
              {this.state.subOrder == "createdAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "nicedAt")}
            >
              추천일{" "}
              {this.state.subOrder == "nicedAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "likeCntAll")}
            >
              좋아요수{" "}
              {this.state.subOrder == "likeCntAll"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "scrapCnt")}
            >
              스크랩수{" "}
              {this.state.subOrder == "scrapCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              나.궁.수{" "}
              {this.state.subOrder == "meTooCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "replyCnt")}
            >
              답변수{" "}
              {this.state.subOrder == "replyCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "commentCnt")}
            >
              댓글수{" "}
              {this.state.subOrder == "commentCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "meTooCnt")}
            >
              공유수{" "}
              {this.state.subOrder == "meTooCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "viewCnt")}
            >
              조회수{" "}
              {this.state.subOrder == "viewCnt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "score")}
            >
              스코어{" "}
              {this.state.subOrder == "score"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>

            <td
              className="admin-table-label-x"
              onClick={(e) => this.onOrder(e, "digestedAt")}
            >
              선정일
              {this.state.subOrder == "digestedAt"
                ? this.state.subOrderDirect == "desc"
                  ? "▼"
                  : "▲"
                : "-"}
            </td>
          </tr>
        </thead>
        <tbody id="admin-table-body">
          {this.state.list.map((item: BoardContent, index) => {
            if (item.digestedAt && this.state.excludeSent) return null;
            return (
              <tr
                key={index.toString()}
                className={
                  (item.digestedAt ? "common-color-caution" : "") +
                  (item.digestCandidate == -1
                    ? " admin-table-value-dimm"
                    : "") +
                  (item.digestCandidate == 1 ? " admin-table-value-picked" : "")
                }
                onClick={() => this.onSelect(item, index)}
              >
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  <Button
                    size={"Small"}
                    type={"Icon"}
                    variant={"Contained"}
                    color={"Primary"}
                    icon="Link"
                    onClick={(e) => this.onLink(item, index, e)}
                  ></Button>
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  <Button
                    size={"Small"}
                    type={"Icon"}
                    variant={"Contained"}
                    color={"Primary"}
                    icon="Envelope"
                    onClick={(e) => this.onDigest(item, index, e)}
                  ></Button>
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  <ToggleButton
                    type="Icon"
                    active={item.digestCandidate == 1}
                    color={"Skyblue"}
                    size={"Small"}
                    variant={"Outlined"}
                    onClick={(e) =>
                      this.onDigestCandidateCheck(item, 1, index, e)
                    }
                    icon="ArrowUpLarge"
                  />
                  <ToggleButton
                    type="Icon"
                    active={item.digestCandidate == -1}
                    color={"Red"}
                    size={"Small"}
                    variant={"Outlined"}
                    onClick={(e) =>
                      this.onDigestCandidateCheck(item, -1, index, e)
                    }
                    icon="ArrowDownLarge"
                  />
                </td>

                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.id}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.boardsCategoriesName}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.subject.substring(0, 30)}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.createdAt
                    ? getDateStringFromToday({ date: item.createdAt })
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.nicedAt
                    ? getDateStringFromToday({ date: item.nicedAt })
                    : ""}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.likeCntAll}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.scrapCnt}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.meTooCnt}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.replyCnt}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.commentCnt}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.sharedCnt}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.viewCnt}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                >
                  {item.score}
                </td>
                <td
                  className={
                    "admin-table-value" +
                    (this.state.selectedIndex == index
                      ? " admin-table-value-selected"
                      : "")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onDoubleClick={() => {
                    if (index == this.state.editDateIndex) {
                      this.setState({ editDateIndex: -1 });
                      return;
                    }

                    if (item.digestedAt) {
                      this.setState({
                        editDate: getDateStringFromToday({
                          date: item.digestedAt,
                          showTime: true,
                        }),
                        editDateIndex: index,
                      });
                    } else {
                      this.setState({
                        editDate: getDateStringFromToday({ showTime: true }),
                        editDateIndex: index,
                      });
                    }
                  }}
                >
                  {index == this.state.editDateIndex ? (
                    <Input
                      value={this.state.editDate}
                      onChange={(e) =>
                        this.setState({ editDate: e.target.value })
                      }
                      onEnter={async () => {
                        if (this.state.editDate) {
                          let date = Date.parse(this.state.editDate);
                          if (!date) {
                            this.props.toastPopup(
                              "날짜 형식이 정확하지 않습니다."
                            );
                            return;
                          }
                        }
                        let newBoard: BoardContent = {
                          id: item.id,
                          digestedAt: this.state.editDate,
                          managedAt: 1,
                        };
                        let result = await fetchAPI(
                          API.BOARD_UPDATE,
                          "",
                          null,
                          newBoard,
                          getGlobal(GlobalKey.TOKEN)
                        );
                        if (result && !result.error) {
                          let list = [...this.state.list];
                          list[index] = {
                            ...item,
                            digestedAt: this.state.editDate,
                          };
                          this.setState({ list, editDateIndex: -1 });
                        }
                      }}
                    />
                  ) : item.digestedAt ? (
                    getDateStringFromToday({
                      date: item.digestedAt,
                      showTime: true,
                    })
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    log(
      LogLevel.UI_LIFECYCLE,
      "WeeklyDigestNiceList.render",
      this.props,
      this.state
    );

    let more;
    if (!this.state.finished) {
      more = (
        <IonButton expand="full" onClick={this.fetchListMore}>
          더 불러오기
        </IonButton>
      );
    }

    return (
      <div className="admin-full-container">
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">기간</div>
          <div
            className={
              "admin-toggle" +
              (this.state.order == BoardSortType.DIGEST
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ order: BoardSortType.DIGEST })}
          >
            선정일
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.order == BoardSortType.TIME_DESC
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ order: BoardSortType.TIME_DESC })}
          >
            등록일
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.order == BoardSortType.NICE
                ? " admin-toggle-selected"
                : "")
            }
            onClick={() => this.setState({ order: BoardSortType.NICE })}
          >
            추천일
          </div>
          <input
            className={this.state.dateStartWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateStart}
            onChange={(e) => this.onDateChange(e, "dateStart")}
          />
          <div>~</div>
          <input
            className={this.state.dateEndWarn ? "common-color-caution" : ""}
            placeholder="시작일시"
            value={this.state.dateEnd}
            onChange={(e) => this.onDateChange(e, "dateEnd")}
          />

          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ weeks: -2 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            2주
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -1 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            1개월
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -3 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            3개월
          </div>
          <div
            className="admin-title common-color-highlight"
            onClick={() => {
              this.setState({
                dateStart: getDateStringFromToday({ months: -6 }),
                dateEnd: getDateStringFromToday({ days: 1 }),
                dateStartWarn: false,
                dateEndWarn: false,
              });
            }}
          >
            6개월
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">게시판</div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "0" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "0" })}
          >
            전체
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "1" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "1" })}
          >
            학술
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "12" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "12" })}
          >
            약사라이프
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "2" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "2" })}
          >
            뉴스
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "3" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "3" })}
          >
            공지
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "13" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "13" })}
          >
            모임
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.category == "14" ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ category: "14" })}
          >
            장터
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">검색어</div>
          <input
            placeholder="검색어(exact match)"
            value={this.state.search}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <div className="admin-title">데이터 로딩 단위</div>
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 200 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 200 })}
          >
            200row
          </div>
          <div
            className={
              "admin-toggle" +
              (this.state.unitCount == 1000 ? " admin-toggle-selected" : "")
            }
            onClick={() => this.setState({ unitCount: 1000 })}
          >
            1000row
          </div>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom common-flex-align-center">
          <div className="admin-title">Score Weight</div>
          <Switch
            size={"Small"}
            label="이미보낸것 제외"
            isActive={this.state.excludeSent}
            onClick={() => {
              this.setState({ excludeSent: !this.state.excludeSent });
            }}
          />
        </div>
        <div className="common-container-row-wrap admin-margin-bottom common-flex-align-center">
          후보{" "}
          <input
            style={{ width: "50px" }}
            placeholder="좋아요"
            value={this.state.scoreBoard[7].toString()}
            onChange={(e) => this.onScoreChange(e, 7)}
          />
          좋아요{" "}
          <input
            style={{ width: "50px" }}
            placeholder="좋아요"
            value={this.state.scoreBoard[0].toString()}
            onChange={(e) => this.onScoreChange(e, 0)}
          />
          스크랩수{" "}
          <input
            style={{ width: "50px" }}
            placeholder="스크랩수"
            value={this.state.scoreBoard[1].toString()}
            onChange={(e) => this.onScoreChange(e, 1)}
          />
          나.궁.수{" "}
          <input
            style={{ width: "50px" }}
            placeholder="나도궁금해요수"
            value={this.state.scoreBoard[2].toString()}
            onChange={(e) => this.onScoreChange(e, 2)}
          />
          답변수{" "}
          <input
            style={{ width: "50px" }}
            placeholder="답변수"
            value={this.state.scoreBoard[3].toString()}
            onChange={(e) => this.onScoreChange(e, 3)}
          />
          댓글수{" "}
          <input
            style={{ width: "50px" }}
            placeholder="댓글수"
            value={this.state.scoreBoard[4].toString()}
            onChange={(e) => this.onScoreChange(e, 4)}
          />
          공유수{" "}
          <input
            style={{ width: "50px" }}
            placeholder="공유수"
            value={this.state.scoreBoard[5].toString()}
            onChange={(e) => this.onScoreChange(e, 5)}
          />
          조회수{" "}
          <input
            style={{ width: "50px" }}
            placeholder="조회수"
            value={this.state.scoreBoard[6].toString()}
            onChange={(e) => this.onScoreChange(e, 6)}
          />
          {this.state.order == BoardSortType.DIGEST && (
            <span className="commmon-color-highlight"> 결과값비교일수 </span>
          )}
          {this.state.order == BoardSortType.DIGEST && (
            <input
              style={{ width: "50px" }}
              placeholder="결과값비교일수"
              value={this.state.resultGap.toString()}
              onChange={(e) =>
                this.setState({ resultGap: Number(e.target.value) })
              }
            />
          )}
          <IonButton onClick={this.onChangeScoreBoard}>적용</IonButton>
        </div>
        <div className="common-container-row-wrap admin-margin-bottom">
          <IonButton onClick={this.fetchList}>불러오기</IonButton>
          <IonButton onClick={this.onDownload}>
            다운로드({this.state.list.length}건)
          </IonButton>
        </div>

        <div className="common-container">
          {more}
          {this.state.order == BoardSortType.DIGEST
            ? this.renderDigestResult()
            : this.renderBoardContents()}
          {more}
          <div style={{ height: "100px" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WeeklyDigestNiceList)
);
