
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { OrderInfo } from '../models/Model.Sale';
import { log, LogLevel } from '../utils/LogUtil';
import { UIPopupType, UIServiceType } from '../store/ui/types';
import Header from '../component/molecule/Header';
import Body from '../component/molecule/Body';
import AnalyticsUtil from '../utils/AnalyticsUtil';
import SaleOrderDetail from '../component/template/SaleOrderDetail';
import { getDateStringFromToday } from '../utils/TimeUtil';
import ChannelTalkService from '../utils/ChannelTalkService';
import { jumpToDeliveryTracker } from '../utils/DeliveryTrackerUtil';
import { getGlobal, GlobalKey } from '../utils/GlobalUtil';
const queryString = require('query-string');

type Props =  RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  orderId?: number
}

type State = {
}

const windowAny : any = window;

class ProductSaleMyOrderDetailViewer extends Component<Props, State> {
  id: number = 0;

  constructor(props: Props) {
    super(props);
    this.state ={

    }

    let qs = queryString.parse(this.props.location.search);
    let newId = 0
    try{
      newId = parseInt(qs.id);
    }catch(err) {
      newId = 0
    }
    if(!newId && this.props.orderId){
      newId = this.props.orderId;
    }

    log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID ", newId);
    if(this.id != newId){
      log(LogLevel.UI_LIFECYCLE, "Viewer:constructor new ID DETECTED", newId);
      this.id = newId;
      this.props.loadOrder(this.id);
    
      if(this.props.orders){
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "ORDER_DETAIL_START", '주문상세 시작', {"주문id":this.id});
      }
    }
  }

  componentDidMount() {
    this.props.backKeyControl.setListener(() => {
      this.goBack();
      return false;
    });

    if(!this.props.orders[this.id]){
      this.props.waitingPopup.show();
    }
  }

  componentWillUnmount() {
    // this.props.backKeyControl.popListener();
  }

  componentDidUpdate(prevProps, prevState) {

    if(!prevProps.orders[this.id] && this.props.orders[this.id] && this.props.waitingPopup.isShown()){
      this.props.waitingPopup.hide();
    }
  }

  goBack = () => {
    log(LogLevel.UI_ACTION, "ProductSaleMyOrderDetailViewer:goBack");
    if(ChannelTalkService.isShown){
      ChannelTalkService.hide();
      return;
    }
    this.props.history.goBack();
  }

  onClickButtonCancel = (order:OrderInfo) => {
    this.props.history.push("/sale/cancel?id="+order.id);
  }

  onClickButtonReturn = (order:OrderInfo) => {
    this.props.history.push("/sale/return?id="+order.id);
  }

  onClickButtonInquiry = (order:OrderInfo) => {
    if(!ChannelTalkService.isShown)
      ChannelTalkService.show();
  }

  onClickButtonTracking = (order:OrderInfo) => {
    log(LogLevel.UI_LIFECYCLE, "ProductSaleMyOrders.onClickButtonTracking", order);
    if(order){
      jumpToDeliveryTracker(order.deliveryCompany, order.deliveryId);
    }
  }

  onClickButtonReceipt = (order:OrderInfo) => {
    if(order && order.payRecieptUrl)
      window.open(order.payRecieptUrl, ((getGlobal(GlobalKey.OS)=="browser")?"_blank":"_system"));
  }


  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductSaleMyOrderDetailViewer.render", this.props.orders);
    if(!this.id || !this.props.orders[this.id])
      return null;

    let order = this.props.orders[this.id];

    return (
      <>
        <Header goBack={this.goBack} name={getDateStringFromToday({date:order.orderedAt, separator:"."}) + " 주문상세"}/>
        <Body>
          <SaleOrderDetail 
            order={this.props.orders[this.id]}
            onClickButtonCancel={this.onClickButtonCancel}
            onClickButtonReturn={this.onClickButtonReturn}
            onClickButtonInquiry={this.onClickButtonInquiry}
            onClickButtonTracking={this.onClickButtonTracking}
            onClickButtonReceipt={this.onClickButtonReceipt}
          />
        </Body>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  orders: state.sale.orders,
  links: state.board.links,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  loadOrder: (id) => actions.sale.getOrder(id),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSaleMyOrderDetailViewer));