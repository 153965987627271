import React, { Component } from 'react';
import './RegisterStage3.css';
import './RegisterStage.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonInput, IonIcon, IonTextarea, IonToast } from '@ionic/react';
import DynamicChecker, {CheckStatus} from './../DynamicChecker'
import {fetchAPI} from './../../utils/APIUtil'
import * as API from './../../API.json';;
import {UserInfo} from '../../models/Model.User'
import {log, LogLevel} from '../../utils/LogUtil'
// import * as loadImage from 'blueimp-load-image';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import { loadImageBase64 } from '../../utils/ImageUtil';
import {MY_ADDRESS} from './../../config.json';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { UIPopupType } from '../../store/ui/types';
import kakaoIcon from './../../assets/icon/question_small_yellow_mobile_3X.png'
import DynamicCheckerInput from './../DynamicCheckerInput';
import AnalyticsUtil from './../../utils/AnalyticsUtil';
import StringUtil from '../../utils/StringUtil';

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & {
  private:boolean,
  user: UserInfo
  onDone: (data: UserInfo) => void;
  onBack: (data: UserInfo) => void;
};

type State = {
  nickname: string;
  nicknameCheckStatus: number
  nicknameWarning: string;
  profileImageBase64: any;
  profileUrl: string;
  description: string;

}

const windowAny: any = window;

class RegisterStage3 extends Component<Props, State> {
  popupPharmacySelect=null;
  constructor(props){
    super(props);
    let profileUrl:string = ''
    if(!this.props.user || !this.props.user.profileUrl){
      if(this.props.user.birthGender.endsWith('1')){
        profileUrl = MY_ADDRESS + '/images/profile_m.png'
      }
      else {
        profileUrl = MY_ADDRESS + '/images/profile_w.png'
      }
    }

    this.state={
      nickname: this.props.user.nickname,
      nicknameCheckStatus: (this.props.user.nickname === this.props.user.name)?CheckStatus.CHECKED:CheckStatus.UNCHECKED,
      nicknameWarning:'',
      profileImageBase64: this.props.user.profileImageBase64,
      profileUrl: profileUrl,
      description: this.props.user.description,
    }

    if(this.state.nickname && this.props.user.nickname != this.props.user.name)
      this.validateNickname(this.state.nickname);

  }


  validateNickname = (nickname: string) => {
    if(!nickname){
      this.setState({nicknameCheckStatus:CheckStatus.UNCHECKED});
      return;
    }
    if(this.props.private){
      fetchAPI(API.USER_FIELD_VALIDATE, 'nickname',null, {value:nickname.trim()}, getGlobal(GlobalKey.TOKEN))
      .then(result => this.onNicknameChecked(result)
      ).catch(e => {
        log(LogLevel.UI_EXCEPTION, e);
      });
    }else {
      fetchAPI(API.USER_FIELD_VALIDATE_OPEN, 'nickname',null, {value:nickname.trim()})
      .then(result => this.onNicknameChecked(result)
      ).catch(e => {
        log(LogLevel.UI_EXCEPTION, e);
      });
    }
  }
  
  onNicknameChecked = (result) => {
    if(result && !result.error && result.data.value == this.state.nickname.trim()){
      this.setState({nicknameCheckStatus:CheckStatus.CHECKED, nicknameWarning:""});
    }else if(result && result.error && result.data.value == this.state.nickname.trim()){
      this.setState({nicknameCheckStatus:CheckStatus.WARNING, nicknameWarning:"다른 멤버가 사용하고 있는 닉네임입니다."})
    }else{
      log(LogLevel.NONE, result);
    }
  }

  onDone = () => {
    if(this.state.nicknameCheckStatus != CheckStatus.CHECKED){
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "REGISTRATION_5_FAIL", '회원가입_5_프로필정보입력실패', {"이유":"닉네임이상"});
      this.props.toast.show("닉네임을 확인해주세요.");
      return;
    }

    let rvalue = {
      nickname: this.state.nickname,
      profileImageBase64: this.state.profileImageBase64,
      description: this.state.description,
    }
    this.props.onDone(rvalue);
  }

  onBack = () => {
    let rvalue = {
      nickname: this.state.nickname,
      profileImageBase64: this.state.profileImageBase64,
      description: this.state.description,
    }
    this.props.onBack(rvalue);

  }

  canPass() {
    return true;
  }

  onNicknameChange = (e) => {
    let nickname = e.target.value;
    log(LogLevel.UI_ACTION, nickname);
    this.setState({nickname});

    if(!nickname || nickname.length < 2){
      this.setState({nicknameCheckStatus: CheckStatus.UNCHECKED})
    } 
    else if(nickname === this.props.user.name)
      this.setState({nicknameCheckStatus: CheckStatus.CHECKED})
    else
      this.validateNickname(nickname);
  }

  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    if(!os || os == "browser"){
      try{
        windowAny.Kakao.init('0d4139a6dc131b05b8109f629d7cc3f7');
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
      try{
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: '_Vxjhxgj' // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }else{  
      let plusFriendTemplate = {
        plusFriendId: '_Vxjhxgj',
      };
      try{
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      }catch(e){
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  }

  onDescriptionChange = (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    this.setState({description: e.detail.value});
  }

  onImageSelected = (e) => {
    // console.dir(e);
    let loadOption = {orientation: true, canvas:true, maxWidth: 200, maxHeight:200, aspectRatio:1, cover:true};
    log(LogLevel.UI_ACTION, e.target.files[0], loadOption);
    if (e.target.files && e.target.files[0]) {
      loadImageBase64(e.target.files[0], 200, true).then((base64) => {
        this.onImageLoad(base64)
      });
    }
  }

  onImageLoad = (base64) => {
    this.setState({profileImageBase64:base64});
  }

  render() {
    let profileImg;
    if(this.state.profileImageBase64)
      profileImg = (<img className="register3-profile-image" src={this.state.profileImageBase64}/>);
    else if (this.props.user && this.props.user.profileUrl)
      profileImg = (<img className="register3-profile-image" src={StringUtil.convertFilePath(this.props.user.profileUrl)}/>);
    else if (this.state.profileUrl)
      profileImg = (<img className="register3-profile-image" src={StringUtil.convertFilePath(this.state.profileUrl)}/>);

    return (
      <div className="register3-container">
        <div className="register-progress-container">
          <div className="register-progress-done">1</div>
          <div className="register-progress-done">2</div>
          <div className="register-progress-done">3</div>
          <div className="register-progress-done">4</div>
          <div className="register-progress-done">5</div>
        </div>
        <div className="register3-service-description">
          <div>프로필은 타회원에게 보여지는 정보입니다.</div>
          <div>닉네임은 필요 시 변경 가능합니다.</div>
        </div>
        <div className="register-chat-guide">
          <img className="register-chat-kakao" src={kakaoIcon} onClick={this.onChat}/>
        </div>
        <div className="register3-body-container">
          <div className="register-title">
            프로필 입력
          </div>
          <div className="register3-profile-container">
            <div className="register3-profile-inner">
              {profileImg}
              <div className="register3-profile-upload-button-container">
                <div className="register3-profile-upload-button-inner">
                  <IonButton color="register3-profile-upload-button">
                    <IonIcon class="register3-profile-upload-button-icon" name="camera"/>
                  </IonButton>
                  <input className="register3-profile-upload-file" type="file" accept="image/*" onChange={this.onImageSelected}/>
                </div>
              </div>
            </div>
          </div>        
          <DynamicCheckerInput title="닉네임" status={this.state.nicknameCheckStatus} warnings={this.state.nicknameWarning} input={this.state.nickname} onChange={this.onNicknameChange} type="text"/>

          <div className="register3-item-container">
            <div className="register3-item-name">자기소개</div>
            <IonTextarea class="register3-item-textarea" value={this.state.description} onIonChange={this.onDescriptionChange}/>
          </div>
        </div>
        <div className="register-navigate-container">

          <IonButton color="register-navigate-inactive" onClick={this.onBack}>
            &lt; 이전 단계로
          </IonButton>
          <div className="register-navigate-space"/>
          <IonButton color={this.canPass()?"register-navigate-active":"register-navigate-inactive"}  onClick={this.onDone}>
            다음 단계로 &gt;
          </IonButton>
        </div>   
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  filePath : state.board.filePath,
  toast : state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterStage3);