import React from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from 'react-router';
import { format } from 'date-fns';
import { IonLabel, IonButton, IonIcon, IonItemSliding, IonItem, IonItemOptions, IonItemOption, IonAlert } from '@ionic/react';
import './NotificationListItem.css';
import {getDateTimeString, getDateTimeStringShort} from './../utils/TimeUtil'
import {Notification, NotificationType} from './../models/Model.Notification'
import { fetchAPI } from '../utils/APIUtil';
import * as API from './../API.json';
import {BoardType} from './../store/board/types'
import {getGlobal, GlobalKey} from './../utils/GlobalUtil'
import Truncate from 'react-truncate';
import { log, LogLevel } from '../utils/LogUtil';
import ProfileImage from './ProfileImage';
import logoImg from './../assets/image/logo.png'
import { UserLevel } from '../models/Model.User';
import Text from './atoms/Text';
import { COLOR_SYSTEM } from './design/design-system';

type Props = {
  key?: string;
  noti: Notification;
  onClickItem?: (noti: Notification) => void;
  onClickUser?: (noti: Notification) => void;
}

const NotificationListItem: React.SFC<Props> = ({noti, onClickItem, onClickUser}) => {
  // log(LogLevel.UI_LIFECYCLE, "NotificationListItem: render", noti);

  
  if(noti.text && !noti.content && !noti.comment){
    return <LegacyNotificationListItem noti={noti} onClickItem={onClickItem} onClickUser={onClickUser}/>
  }

  let from; 
  
  if (noti.fromUsersId && noti.fromUsersId == noti.rootBoardContentUserId) {
    if (noti.fromUsersCount) 
      from = <span><b>작성자</b>외 {noti.fromUsersCount}명이 </span>
    else 
      from = <span><b>작성자</b>가 </span>
  }else {
    if (noti.fromUsersCount) 
      from = <span><b>{noti.fromUsers}</b>님 외 {noti.fromUsersCount}명이 </span>
    else 
      from = <span><b>{noti.fromUsers}</b>님이 </span>
  }

  switch (noti.type) {
    case NotificationType.REPLY_ON_MY_POST:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}><b>나의 글 "{noti.content}"</b>에 {from} 의견을 남겼어요.</Text><br/><Text textType='Body2Bold' color={COLOR_SYSTEM.get("Blue")[400]}>🙏🏻 댓글로 감사의 표시를 해주세요  🙏🏻</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 나의 글</div>
          </div>
        </div>
        );
      break;
    case NotificationType.COMMENT_ON_MY_POST:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} <b>나의 글 "{noti.content}"</b> 댓글을 남겼어요. "{noti.comment}"</Text><br/><Text textType='Body2Bold' color={COLOR_SYSTEM.get("Blue")[400]}>🙏🏻 답글을 남겨주세요 🙏🏻</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 나의 글</div>
          </div>
        </div>
        );
      break;
    case NotificationType.LIKE_ON_MY_POST:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} <b>나의 글 "{noti.content}"</b>을(를) 좋아합니다.</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 나의 글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.LIKE_ON_MY_COMMENT:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} <b>나의 댓글 "{noti.comment}"</b>을(를) 좋아합니다.</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 나의 댓글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.SHARE_MY_POST:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} 나의 글을 카카오톡으로 공유했어요. "{noti.content}"</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 나의 글</div>
          </div>
        </div>
      );
      break;
    // case NotificationType.NEW_POST:
    //   return (
    //     <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
    //       <div className="notification-list-item-body">
    //         <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>[{noti.boardsCategoryName}] 새로운 글 "{noti.content}"</Text>
    //       </div>
    //     </div>
    //   );
    //   break;
    // case NotificationType.NEW_REPLY:
    //   return (
    //     <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
    //       <div className="notification-list-item-body">
    //         <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>[{noti.boardsCategoryName}] 새로운 의견 알림 "{noti.content}"에 새로운 의견이 등록됐어요</Text>
    //       </div>
    //     </div>
    //   );
    //   break;
    case NotificationType.SCRAP_MY_POST:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} 나의 글을 스크랩 했어요. "{noti.content}"</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 나의 글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.REPLY_ON_MY_LIKE:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}><b>"{noti.content}"</b>에 {from} 의견을 남겼어요.</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 좋아요한 글</div>
          </div>
        </div>
        );
      break;
    case NotificationType.REPLY_ON_ME_TOO:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}><b>"{noti.content}"</b>에 {from} 의견을 남겼어요.</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 알림신청한 글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.COMMENT_ON_MY_LIKE:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} <b>"{noti.content}"</b>에 댓글을 남겼어요.</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 좋아요한 글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.REPLY_ON_MY_SCRAP:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}><b>"{noti.content}"</b>에 {from} 의견을 남겼어요.</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 스크랩한 글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.COMMENT_ON_MY_SCRAP:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} <b>"{noti.content}"</b>에 댓글을 남겼어요.</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 스크랩한 글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.COMMENT_ON_MY_COMMENT:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>{from} <b>나의 댓글 "{noti.content}"</b>에 댓글을 남겼어요. <b>"{noti.comment}"</b></Text><br/><Text textType='Body2Bold' color={COLOR_SYSTEM.get("Blue")[400]}>🙏🏻 답글을 남겨주세요 🙏🏻</Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · 스크랩한 글</div>
          </div>
        </div>
      );
      break;
    case NotificationType.ADMIN_NEW_POST:
      return (
        <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
          <div className="notification-list-item-body">
            <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>[Admin] 새로운 글 {from} 새 글을 등록했어요. <b>"{noti.content}"</b></Text>
            <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)} · ADMIN</div>
          </div>
        </div>
        );
      break;
    case NotificationType.USER_LEVEL_CHANGE:
      if (noti.level == UserLevel.NORMAL_USER) {
        return (
          <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
            <div className="notification-list-item-body">
              <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}>🎉환영합니다! <b>{noti.fromUsers}</b> 약사님 🎉 지금 바로 약문약답을 이용해보세요! </Text>
            </div>
          </div>
        );
      } else if (noti.level == UserLevel.DENIED_USER) {
        return (
          <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"} onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>
            <div className="notification-list-item-body">
              <Text textType='Body2' color={COLOR_SYSTEM.get("Gray")[900]}><b>가입 요청이 반려</b>되었습니다. 반려사유를 확인해주십시오.</Text>
            </div>
          </div>
        );
      }
      break;
  }
  return null;
}

const LegacyNotificationListItem: React.SFC<Props> = ({noti, onClickItem, onClickUser}) => {
  // log(LogLevel.UI_LIFECYCLE, "NotificationListItem: render", noti);

  
  let parsedNoti = parseNotification(noti);
  let title;
  let fromUsersProfile = noti.fromUsersProfile;
  if(noti.fromUsersId && noti.fromUsersId == noti.rootBoardContentUserId)
    fromUsersProfile = "";
  if(parsedNoti.title){
    title = (
      <div>
        <span className="notification-list-item-name" onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>{parsedNoti.title}</span>
      </div>
    );
  }
  return (
  <div className={noti.checked?"notification-list-item-container":"notification-list-item-container notification-list-item-container-unchecked"}>
    <ProfileImage className="notification-list-item-profile" defaultSrc={logoImg} profileUrl={fromUsersProfile} onClick={() => { console.log("NotificationListItem", "onClickUser", noti); if(onClickUser) onClickUser(noti) }} />
    <div className="notification-list-item-body">
      {title}
      <div>
        <span className="notification-list-item-name" onClick={() => { console.log("NotificationListItem", "onClickUser", noti); if(onClickUser) onClickUser(noti) }}>{parsedNoti.from}</span>
        <span onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}> {parsedNoti.fromAdditional}{parsedNoti.message}</span>
      </div>
      {/* <div onClick={() => { console.log("NotificationListItem", "onClickItem", noti); if(onClickItem) onClickItem(noti)}}>"{parsedNoti.text}"</div> */}
      <div className="notification-list-item-time">{getDateTimeStringShort(noti.modifiedAt)}</div>
    </div>
  </div>
  );
}


export function parseNotification(noti: Notification): any {
  log(LogLevel.UI_EVENT, "parseNotification : ", noti);
  let notiInfo: any = {};

  if (noti.fromUsersId && noti.fromUsersId == noti.rootBoardContentUserId) {
    if (noti.fromUsersCount) notiInfo.fromAdditional = "질문자 외 " + noti.fromUsersCount + "명이 ";
    else notiInfo.fromAdditional = "질문자가 ";
  } else if (!noti.fromUsers || noti.fromUsers == "비회원") {
    notiInfo.from = "";
    notiInfo.fromAdditional = "";
  } else {
    notiInfo.from = noti.fromUsers;
    notiInfo.fromId = noti.fromUsersId;
    notiInfo.fromAdditional = "님이 ";
    if (noti.fromUsersCount) notiInfo.fromAdditional = "님 외 " + noti.fromUsersCount + "명이 ";
  }
  let body = "";
  if (noti.text) body = '"' + noti.text + '..."';

  switch (noti.type) {
    case NotificationType.REPLY_ON_MY_POST:
      notiInfo.message = "내가 작성한 " + body + " 게시물에 답변했습니다.";
      break;
    case NotificationType.COMMENT_ON_MY_POST:
      if (!noti.fromUsers || noti.fromUsers == "비회원") {
        notiInfo.from = "익명약사";
        notiInfo.fromAdditional = "님이 ";
      }
      notiInfo.message = "내가 작성한 " + body + " 게시물에 댓글을 달았습니다.";
      break;
    case NotificationType.LIKE_ON_MY_POST:
      notiInfo.message = "내가 작성한 " + body + " 게시물을 좋아합니다.";
      break;
    case NotificationType.LIKE_ON_MY_COMMENT:
      notiInfo.message = "내가 작성한 " + body + " 댓글을 좋아합니다.";
      break;
    case NotificationType.SHARE_MY_POST:
      notiInfo.message = "내가 작성한 " + body + " 게시물을 카톡으로 공유했습니다.";
      break;
    case NotificationType.NEW_QUESTION:
    case NotificationType.NEW_CONSULT_POST:
      notiInfo.from = "";
      notiInfo.fromAdditional = "";
      notiInfo.title = "새로운 글이 등록되었습니다.";
      notiInfo.message = body;
      break;
    case NotificationType.NEW_REPLY:
    case NotificationType.NEW_CONSULT_REPLY_POST:
      notiInfo.from = "";
      notiInfo.fromAdditional = "";
      notiInfo.message = body + "에 새로운 의견이 달렸습니다. ";
      break;
    case NotificationType.NEW_POST:
      notiInfo.message = body + " 게시물을 작성하셨습니다.";
      break;
    case NotificationType.SCRAP_MY_POST:
      notiInfo.message = "내가 작성한 " + body + " 게시물을 스크랩 했습니다.";
      break;
    case NotificationType.MY_INTEREST_POST:
      notiInfo.message = "관심 주제에 대한 게시물이 등록되었습니다.";
      break;
    case NotificationType.MY_FOLLOW_POST:
      break;
    case NotificationType.REPLY_ON_MY_LIKE:
      notiInfo.message = "내가 좋아요 한 " + body + " 게시물에 답변이 달렸습니다.";
      break;
    case NotificationType.REPLY_ON_ME_TOO:
      notiInfo.message = "내가 궁금해요 표시한 " + body + " 게시물에 답변이 달렸습니다.";
      break;
    case NotificationType.COMMENT_ON_MY_LIKE:
      notiInfo.message = "내가 좋아요 한 " + body + " 게시물에 댓글이 달렸습니다.";
      break;
    case NotificationType.REPLY_ON_MY_SCRAP:
      notiInfo.message = "내가 스크랩 한 " + body + " 게시물에 답변이 달렸습니다.";
      break;
    case NotificationType.COMMENT_ON_MY_SCRAP:
      notiInfo.message = "내가 스크랩 한 " + body + " 게시물에 댓글이 달렸습니다.";
      break;
    case NotificationType.COMMENT_ON_MY_COMMENT:
      if (!noti.fromUsers || noti.fromUsers == "비회원") {
        notiInfo.from = "익명약사";
        notiInfo.fromAdditional = "님이 ";
      }
      notiInfo.message = " 나에게 댓글을 남겼습니다.  " + (body ? '"' + body + '"' : "");
      break;
    case NotificationType.ADMIN_NEW_USER:
      notiInfo.fromAdditional = "";
      notiInfo.message = "님 (총 " + noti.count + "명) 승인 필요";
      break;
    case NotificationType.ADMIN_NEW_SOCIAL_AUTH:
      notiInfo.fromAdditional = "";
      notiInfo.message = "님이 신규소셜계정으로 로그인";
      break;
    case NotificationType.ADMIN_NEW_POST:
      notiInfo.message = " 게시판에 " + body + "을 작성했습니다. [ADMIN]";
      break;
    case NotificationType.ORDER_CASH_IN:
      notiInfo.from = "";
      notiInfo.fromAdditional = "";
      notiInfo.message = "입금이 확인되었습니다. 배송이 시작되면 택배사가 카톡으로 알려드립니다.";
      break;
    case NotificationType.USER_LEVEL_CHANGE:
      if (noti.level == UserLevel.NORMAL_USER) {
        notiInfo.fromAdditional = "";
        notiInfo.message = "님 환영합니다. 지금 바로 약문약답을 사용해보세요.";
      } else if (noti.level == UserLevel.DENIED_USER) {
        notiInfo.fromAdditional = "";
        notiInfo.message = "님 반려 사유를 확인해 주십시오.";
      } else {
        notiInfo.fromAdditional = "";
        notiInfo.message = "님의 회원등급이 변경되었습니다.";
      }
      break;
  }

  //console.log("generateNotificationText", noti, text)
  return notiInfo;
}

export default NotificationListItem;