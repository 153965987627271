import { CouponInfo } from "../../models/Mode.Biz";

export interface JobPostState {
  offers: any; //{id:JobOfferInfo}
  applies: any; //{id:JobApplyInfo}
  lists: any; //{id:JobListState}
  newCount: number;
  lastVisited: number;
  coupon: CouponInfo;
}

export enum JobListType {
  TYPE_FILTER = 0x0f,
  DETAIL_FILTER = 0xf0,

  OFFER = 0x01,
  APPLY = 0x02,

  RECOMMENDED = 0x10,
  ALL = 0x20,
  MY = 0x30,
  HISTORY = 0x40,
  HISTORY_LATEST = 0x50,
  PASSED = 0x60,
  MY_LIKE = 0x70,
  UNREAD = 0x80,
  SEARCHED = 0x90,
  PHARMACY = 0x100,
  BIZ = 0x200,
  PREMIUM = 0x400,
}

export interface JobListState {
  id?: number; // JobListType
  options?: any;
  items?: any[];
  loading?: boolean;
  end?: boolean;
  needReload?: boolean;
  checksum?: number;
  totalCount?: number;
  furtherCount?: number;
  gold?: any[];
  silver?: any[];
  bronze?: any[];
  pharmacy?: any[];
}

export enum JobPostActionTypes {
  JOBPOST_INIT = "@@event/JOBPOST_INIT",
  JOBPOST_RELOAD_LIST = "@@event/JOBPOST_RELOAD_LIST",
  JOBPOST_ORDER_UPDATE = "@@event/JOBPOST_ORDER_UPDATE",
  JOBPOST_APPLY_UPDATE = "@@event/JOBPOST_APPLY_UPDATE",
  JOBPOST_LIST_FETCH = "@@event/JOBPOST_LIST_FETCH",
  JOBPOST_LIST_REQUEST = "@@event/JOBPOST_LIST_REQUEST",
  JOBPOST_LIST_SUCCESS = "@@event/JOBPOST_LIST_SUCCESS",
  JOBPOST_LIST_ERROR = "@@event/JOBPOST_LIST_ERROR",
  JOBPOST_ORDER_FETCH = "@@event/JOBPOST_ORDER_FETCH",
  JOBPOST_ORDER_REQUEST = "@@event/JOBPOST_ORDER_REQUEST",
  JOBPOST_ORDER_SUCCESS = "@@event/JOBPOST_ORDER_SUCCESS",
  JOBPOST_ORDER_ERROR = "@@event/JOBPOST_ORDER_ERROR",
  JOBPOST_APPLY_FETCH = "@@event/JOBPOST_APPLY_FETCH",
  JOBPOST_APPLY_REQUEST = "@@event/JOBPOST_APPLY_REQUEST",
  JOBPOST_APPLY_SUCCESS = "@@event/JOBPOST_APPLY_SUCCESS",
  JOBPOST_APPLY_ERROR = "@@event/JOBPOST_APPLY_ERROR",
  JOBPOST_COUNT_NEW_RECOMMEND = "@@event/JOBPOST_COUNT_NEW_RECOMMEND",
  JOBPOST_COUNT_NEW_APPLY = "@@event/JOBPOST_COUNT_NEW_APPLY",
  JOBPOST_COUNT_NEW_APPLY_INTERNAL = "@@event/JOBPOST_COUNT_NEW_APPLY_INTERNAL",
  JOBPOST_VISIT = "@@event/JOBPOST_VISIT",
  JOBPOST_VISIT_LEAVE = "@@event/JOBPOST_VISIT_LEAVE",
  JOBPOST_SCRAP = "@@event/JOBPOST_SCRAP",
  JOBPOST_COUPON_FETCH = "@@event/JOBPOST_COUPON_FETCH",
  JOBPOST_COUPON_REQUEST = "@@event/JOBPOST_COUPON_REQUEST",
  JOBPOST_COUPON_SUCCESS = "@@event/JOBPOST_COUPON_SUCCESS",
  JOBPOST_COUPON_ERROR = "@@event/JOBPOST_COUPON_ERROR",
}
