import React, { useEffect, useMemo } from "react";
import {
  Absolute,
  Fixed,
  Flex,
  Header,
  Relative,
  Static,
} from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Button from "../../atoms/Button";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Body from "../../cell/Body";
import BoardSummary from "../BoardSummary";
import BoardSummaryCover from "./BoardSummaryCover";
import {
  BoardContentSummary,
  BoardContentSummaryGroup,
  sampleBoardContentSummary,
} from "../../../models/Model.Board";
import { UserInfo } from "../../../models/Model.User";
import Icon from "../../atoms/Icon";
import SwipeableViews from "react-swipeable-views";
import FloatingButton from "../../atoms/FloatingButton";
import { LogLevel, log } from "../../../utils/LogUtil";
import { scrap } from "../../../store/jobpost/actions";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";

export interface BoardSummaryGroupProps {
  onGoBack: () => void;
  onScrap: () => void;
  pageIndex: number;
  onChangePage: (pageIndex: number) => void;
  summaryGroup: BoardContentSummaryGroup;
  summaries: any;
  summary: BoardContentSummary;
  me: UserInfo;
  freeSummaryCount: number;
  onClickReference?: (focus?: {
    contentId?: number;
    commentId?: number;
  }) => void;
  onClickAdsReward?: () => void;

  onClickUnlock?: () => void;
  onAdminRefresh?: () => void;
  onReport: (message: string) => void;
  onClickKeyword: (keyword: string) => void;
  summaryGroups?: any;
  scrapped: boolean;
}

const BoardSummaryGroup: React.FC<BoardSummaryGroupProps> = ({
  onGoBack,
  onScrap,
  pageIndex,
  onChangePage,
  onClickReference,
  onClickUnlock,
  onClickAdsReward,
  summaryGroup,
  summaries,
  summary,
  me,
  freeSummaryCount,
  onReport,
  onClickKeyword,
  summaryGroups,
  scrapped,
}) => {
  if (!summaries || !summaryGroup || !summaryGroup.summaries) return null;
  //         const thisSummary = summaries[item.id];
  const thisSummary = useMemo(() => {
    let rvalue = null;
    if (
      summaries &&
      summaryGroup &&
      summaryGroup.summaries &&
      summaryGroup.summaries[pageIndex] &&
      summaries[summaryGroup.summaries[pageIndex].id]
    ) {
      rvalue = summaries[summaryGroup.summaries[pageIndex].id];
    }
    log(
      LogLevel.UI_DATA_LOAD,
      "BoardSummaryGroup:useMemo [thisSummary]",
      rvalue,
      pageIndex,
      summaries,
      summaryGroup
    );

    return rvalue;
  }, [pageIndex, summaries, summaryGroup]);

  useEffect(() => {
    log(
      LogLevel.UI_DATA_LOAD,
      "BoardSummaryGroup:useEffect [thisSummary]",
      thisSummary
    );
  }, [thisSummary]);
  return (
    <Relative direction="column" customStyle={{ width: "100%" }}>
      <Header>
        <TopAppBar
          titleType="OFF"
          leftButtons={[
            <Button
              color="Tertiary"
              icon="X"
              onClick={onGoBack}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          rightButtons={[
            !me.premiumType &&
              (freeSummaryCount > 0 || (summary && summary.viewed) ? (
                <Flex
                  alignItems="center"
                  gap="4px"
                  customStyle={{
                    borderRadius: "1000px",
                    background: COLOR_SYSTEM.get("Gray")[20],
                    padding: "5px 10px",
                  }}
                >
                  <Text
                    textType="CaptionBold"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    무료이용
                  </Text>
                  <Relative>
                    <img src={"/images/ticket.png"} style={{ width: "20px" }} />
                    <Text
                      textType="Caption2SemiBold"
                      color={COLOR_SYSTEM.get("Gray")[0]}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {freeSummaryCount}
                    </Text>
                  </Relative>
                </Flex>
              ) : (
                <Flex
                  alignItems="center"
                  gap="4px"
                  customStyle={{
                    borderRadius: "1000px",
                    background: COLOR_SYSTEM.get("Gray")[20],
                    padding: "5px 10px",
                  }}
                >
                  <Text
                    textType="CaptionBold"
                    color={COLOR_SYSTEM.get("Gray")[500]}
                  >
                    Supporters Only
                  </Text>
                  <Icon
                    name="Sparkle Filled"
                    size={16}
                    color={COLOR_SYSTEM.get("Yellow")[400]}
                  />
                </Flex>
              )),
            summary && (
              <ToggleButton
                color="Gray"
                size="Medium"
                variant="Ghost"
                type="Icon"
                icon="Bookmark Filled"
                active={scrapped}
                onClick={async () => {
                  onScrap();
                }}
              />
            ),
          ]}
        />
      </Header>
      <Body>
        {pageIndex === -1 ? (
          <Static customStyle={{ padding: "0px 20px", minHeight: "100%" }}>
            <BoardSummaryCover
              summaryGroup={summaryGroup}
              summaries={summaries}
              onStart={(showContinue: boolean) =>
                onChangePage(showContinue ? summaryGroup.lastViewed : 0)
              }
            />
          </Static>
        ) : (
          <Relative customStyle={{ height: "100%", maxHeight: "100%" }}>
            {thisSummary && (
              <Relative
                customStyle={{
                  padding: "0px 20px",
                  height: "100%",
                  maxHeight: "100%",
                  overflow: "auto",
                }}
              >
                <BoardSummary
                  keywords={[summaryGroup.keyword]}
                  currentPage={pageIndex + 1}
                  onAdsReward={onClickAdsReward}
                  totalPages={summaryGroup.summaries.length}
                  summary={thisSummary}
                  onUnlock={onClickUnlock}
                  onClickReference={onClickReference}
                  locked={
                    !me.premiumType &&
                    freeSummaryCount <= 0 &&
                    thisSummary &&
                    !thisSummary.viewed
                  }
                  onReport={onReport}
                  onClickKeyword={onClickKeyword}
                  summaryGroups={summaryGroups}
                />
              </Relative>
            )}

            {pageIndex > 0 && (
              <Fixed left="20px" bottom="40px">
                <FloatingButton
                  color="Tertiary"
                  icon="ArrowLeftLarge"
                  onClick={() => onChangePage(pageIndex - 1)}
                  size="XLarge"
                  type="Icon"
                />
              </Fixed>
            )}
            {pageIndex < summaryGroup.summaries.length - 1 && (
              <Fixed right="20px" bottom="40px">
                <FloatingButton
                  color="Tertiary"
                  icon="ArrowRightLarge"
                  onClick={() => onChangePage(pageIndex + 1)}
                  size="XLarge"
                  type="Icon"
                />
              </Fixed>
            )}
          </Relative>
          // <Relative customStyle={{ height: "100%", maxHeight: "100%" }}>
          //   <SwipeableViews
          //     className="boards-swipe"
          //     enableMouseEvents
          //     hysteresis={0.1}
          //     index={pageIndex}
          //     onChangeIndex={onChangePage}
          //     style={{ width: "100%", height: "100%", overflow: "hidden" }}
          //   >
          //     {summaryGroup &&
          //       summaryGroup.summaries.map((item, index) => {
          //         const thisSummary = summaries[item.id];
          //         log(
          //           LogLevel.UI_LIFECYCLE,
          //           "BoardSummaryGroup:summaryGroup.summaries.map",
          //           item,
          //           !me.premiumType,
          //           freeSummaryCount <= 0,
          //           thisSummary,
          //           !thisSummary.viewed
          //         );
          //         return (
          //           <Relative
          //             key={index.toString()}
          //             customStyle={{
          //               padding: "0px 20px",
          //               height: "100%",
          //               maxHeight: "100%",
          //               overflow: "auto",
          //             }}
          //           >
          //             <BoardSummary
          //               keywords={[summaryGroup.keyword]}
          //               currentPage={index + 1}
          //               totalPages={summaryGroup.summaries.length}
          //               summary={thisSummary}
          //               onUnlock={onClickUnlock}
          //               onClickReference={onClickReference}
          //               locked={
          //                 !me.premiumType &&
          //                 freeSummaryCount <= 0 &&
          //                 thisSummary &&
          //                 !thisSummary.viewed
          //               }
          //               onReport={onReport}
          //               onClickKeyword={onClickKeyword}
          //               summaryGroups={summaryGroups}
          //             />
          //           </Relative>
          //         );
          //       })}
          //   </SwipeableViews>
          //   {pageIndex > 0 && (
          //     <Fixed left="20px" bottom="40px">
          //       <FloatingButton
          //         color="Tertiary"
          //         icon="ArrowLeftLarge"
          //         onClick={() => onChangePage(pageIndex - 1)}
          //         size="Large"
          //         type="Icon"
          //       />
          //     </Fixed>
          //   )}
          //   {pageIndex < summaryGroup.summaries.length - 1 && (
          //     <Fixed right="20px" bottom="40px">
          //       <FloatingButton
          //         color="Tertiary"
          //         icon="ArrowRightLarge"
          //         onClick={() => onChangePage(pageIndex + 1)}
          //         size="Large"
          //         type="Icon"
          //       />
          //     </Fixed>
          //   )}
          // </Relative>
        )}
      </Body>
    </Relative>
  );
};

export default BoardSummaryGroup;
