import React, { FC, forwardRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Icon from '../../atom/Icon'
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from './../../atom/Text'
import IconButton from '../../atom/IconButton'
interface ContainerProps {
  center?: boolean

}

const Container: StyledComponent<'div', FC, ContainerProps> = styled.div<ContainerProps>`
width: 100%;
height: 64px;
min-height: 64px;
padding: 20px 0px 0px 0px;
position: relative;
background: #fff;
display: flex;
flex-direction: row;
align-items: center;

.header-buttons-right{
  z-index: 10;
  height: 44px;
  padding: 0px 12px 0px 0px;
  ${(props) => props.center?`
  position: absolute;
  bottom: 0px;
  right: 0px;
  `:""}
}

.header-buttons-left{
  z-index: 10;
  width: 56px;
  height: 44px;
  padding: 0px 0px 0px 12px;
  ${(props) => props.center?`
  position: absolute;
  bottom: 0px;
  left: 0px;
  `:""}
}

.header-left-margin {
  width: 24px;
}

.header-text-center{
  z-index: 5;
  position: absolute;
  bottom: 9px;
  left: 0px;
  width: 100%;
  text-align: center;
}

.header-text-left{
  flex-grow:1
  text-align: left;
}

`

export interface Props {
  goBack? : () => void
  name?: string
  needBack?: boolean
  needClose?: boolean
  center?: boolean
  buttons?: any
}

const Header: FC<Props> = ({goBack, name="", needBack = true, needClose = false, center = true, buttons = null }, ref) => {
  return (
    <Container ref={ref} center={center}>
        {needBack && (
          <div className="header-buttons-left">
            <IconButton iconName="Back" size="M" variant="Ghost" color="Secondary" onClick={goBack}/>
          </div>
        )}
        {needClose && (
          <div className="header-buttons-left" onClick={goBack}>
            <Icon width="24" height="24" fill={COLOR["Gray1"]} name="X"/>
          </div>
        )}
        {(!needClose && !needBack) && (
          <div className="header-left-margin"/>
        )}
        <Text className={(center?"header-text-center":"header-text-left")} type="Headline" color="Black">
          {name}
        </Text>
        {buttons &&  (
          <div className="header-buttons-right">
            {buttons}
          </div>
        )}
    </Container>
  )
}

export default forwardRef(Header);
