import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import {
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonLabel,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonActionSheet,
} from "@ionic/react";
import "./MyProfile.css";
import "./../Common.css";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import logoIcon from "./../assets/icon/logo.png";
import kakaoIcon from "./../assets/icon/kakao_logo.png";
import profile from "./../assets/image/profile_m_1.png";
import SimpleBoardList from "../components/SimpleBoardList";
import { BoardType, BoardListState } from "../store/board/types";
import { log, LogLevel } from "../utils/LogUtil";
import SwipeableViews from "react-swipeable-views";

import { getGlobal, GlobalKey, setGlobal } from "../utils/GlobalUtil";
import { UserInfo } from "../models/Model.User";
import Profile from "../components/Profile";
import BoardContentList from "../components/BoardContentList";
import BoardContentListSimpleItem from "../components/BoardContentListSimpleItem";
import { action } from "typesafe-actions";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import { UIPopupType, UIServiceType } from "../store/ui/types";
const queryString = require("query-string");

const SegmentList = [
  // {name:"작성글", index:0, id:BoardType.OTHER_POST_START},
  { name: "답글", index: 1, id: BoardType.OTHER_ANSWER_START },
];
const windowAny: any = window;

type Props = RouteComponentProps &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  segmentIndex: number;
  showMenuPopover: boolean;
};

class OtherProfile extends Component<Props, State> {
  userId = 0;
  contentScroll = [null, null];
  constructor(props: Props) {
    super(props);
    let qs = queryString.parse(location.search);
    log(LogLevel.NONE, "OtherProfile id", this.props.location.search, qs);
    let segmentIndex = getGlobal(GlobalKey.OTHER_SEGMENT);
    if (qs.id) {
      this.userId = parseInt(qs.id);
      this.props.loadUser(this.userId);

      // SegmentList[0].id = BoardType.OTHER_POST_START | this.userId;
      // SegmentList[1].id = BoardType.OTHER_ANSWER_START | this.userId;
      // if(!this.props.boards[SegmentList[0].id] || this.props.boards[SegmentList[0].id].needReload)
      //   this.props.loadBoard(SegmentList[0].id);
      // if(!this.props.boards[SegmentList[1].id] || this.props.boards[SegmentList[1].id].needReload)
      //   this.props.loadBoard(SegmentList[1].id);
      SegmentList[0].id = BoardType.OTHER_ANSWER_START | this.userId;
      if (
        !this.props.boards[SegmentList[0].id] ||
        this.props.boards[SegmentList[0].id].needReload
      )
        this.props.loadBoard(SegmentList[0].id);
    }
    this.state = {
      segmentIndex,
      showMenuPopover: false,
    };
    this.props.loadFilePath();
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "OTHER_PROFILE",
      "회원프로파일_시작",
      { UserId: this.userId }
    );
  }

  componentDidMount() {
    if (this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.onBack);
    // document.addEventListener("backbutton", this.onBack);
    this.autoScroll();
  }

  componentWillUnmount() {
    // if(this.props.progressPopup)
    //   this.props.progressPopup.hide();
    log(LogLevel.UI_EVENT, "Viewer:componentWillUnmount backbutton unregister");
    // document.removeEventListener("backbutton", this.onBack);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.onBack);
  }

  onBack = () => {
    setGlobal(GlobalKey.OTHER_SEGMENT, 0);
    setGlobal(GlobalKey.SCROLL + "other0", 0);
    // setGlobal(GlobalKey.SCROLL+"other1", 0);

    this.props.history.goBack();
  };

  updateSegment = (e: CustomEvent) => {
    let name: string = e.detail.value;
    let index = parseInt(name);

    log(LogLevel.UI_ACTION, "OtherProfile:updateSegment", name, index);
    this.handleChangeIndex(index);
  };

  handleChangeIndex = (index: number) => {
    this.setState({ segmentIndex: index });
    setGlobal(GlobalKey.OTHER_SEGMENT, index);
  };

  autoScrollTryMax = 10;
  autoScrollTry = 0;

  autoScroll = () => {
    log(LogLevel.UI_ACTION, "OtherProfile:autoScroll");
    setTimeout(async () => {
      try {
        let scroll = getGlobal(GlobalKey.SCROLL + "other0");
        if (scroll) {
          let scrollDone = false;
          if (this.contentScroll[0]) {
            log(
              LogLevel.UI_ACTION,
              "OtherProfile:autoScroll 0 jump to " + scroll
            );
            scrollDone = await this.contentScroll[0].scrollTo(scroll);
          }
          if (!scrollDone) {
            this.autoScrollTry++;
            if (this.autoScrollTry < this.autoScrollTryMax) {
              this.autoScroll();
              return;
            }
          }
        }
        scroll = getGlobal(GlobalKey.SCROLL + "other1");
        if (scroll) {
          let scrollDone = false;
          if (this.contentScroll[1]) {
            log(
              LogLevel.UI_ACTION,
              "OtherProfile:autoScroll 1 jump to " + scroll
            );
            scrollDone = await this.contentScroll[1].scrollTo(scroll);
          }
          if (!scrollDone) {
            this.autoScrollTry++;
            if (this.autoScrollTry < this.autoScrollTryMax) {
              this.autoScroll();
              return;
            }
          }
        }
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, "BoardList:autoScroll failed", e);
        this.autoScrollTry++;
        if (this.autoScrollTry < this.autoScrollTryMax) {
          this.autoScroll();
          return;
        }
      }
    }, 100);
  };

  onScroll = (index, event) => {
    let target: any = event.target;
    log(LogLevel.UI_ACTION, "OtherProfile:onScroll ", index, target.scrollTop);
    setGlobal(GlobalKey.SCROLL + "other" + index.toString(), target.scrollTop);
  };

  onClickItem = (name, id) => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "CONTENT_SELECT",
      "게시물선택",
      { 게시물id: id, 게시판이름: "내프로필>" + name }
    );
  };

  onBlock = async () => {
    this.props.waitingPopup.show();
    let result = await fetchAPI(
      API.USER_BLOCK,
      this.userId.toString(),
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );

    if (result && !result.error) {
      this.props.loadUser(this.userId);
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "USER_BLOCK", "사용자차단", {
        id: this.userId,
      });
    }
    this.props.waitingPopup.hide();
  };

  onUnblock = async () => {
    this.props.waitingPopup.show();
    let result = await fetchAPI(
      API.USER_UNBLOCK,
      this.userId.toString(),
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );

    if (result && !result.error) {
      this.props.loadUser(this.userId);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "USER_UNBLOCK",
        "사용자차단해제",
        { id: this.userId }
      );
    }
    this.props.waitingPopup.hide();
  };

  render() {
    log(LogLevel.NONE, "OtherProfile:render");

    let cnt: number = 0;
    if (
      this.props.boards[SegmentList[this.state.segmentIndex].id] &&
      this.props.boards[SegmentList[this.state.segmentIndex].id].contents
    )
      cnt = this.props.boards[SegmentList[this.state.segmentIndex].id].contents
        .length;

    return (
      <>
        <IonHeader class="myprofile-header" no-border>
          <IonToolbar color="myprofile-toolbar">
            <IonButtons slot="start">
              <IonButton class="myprofiletoolbar-button" onClick={this.onBack}>
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel onClick={this.onBack}>프로필</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="myprofile-content">
          <div className="myprofile-card">
            <Profile user={this.props.users[this.userId]} />
            <div className="myprofile-card-more-container">
              <IonButton
                color="myprofile-card-more"
                fill="clear"
                onClick={() => {
                  this.setState({ showMenuPopover: true });
                }}
              >
                <IonIcon class="myprofile-card-more" mode="ios" name="more" />
              </IonButton>
            </div>
          </div>
          <div>
            <IonSegment
              value={this.state.segmentIndex.toString()}
              mode="md"
              slot="start"
              color="myprofile-segment"
              onIonChange={this.updateSegment}
            >
              {SegmentList.map((item, index) => {
                log(LogLevel.NONE, "OtherProfile Loading segment", item, index);
                return (
                  <IonSegmentButton
                    key={index.toString()}
                    mode="md"
                    class="myprofile-segment-button"
                    value={index.toString()}
                    checked={this.state.segmentIndex == index}
                  >
                    <div className="myprofile-segment-button-text">
                      {item.name}
                    </div>
                  </IonSegmentButton>
                );
              })}
            </IonSegment>
            <div className="myprofile-segment-title">
              <span className="myprofile-segment-title-highlight">{cnt}개</span>
              의 {SegmentList[this.state.segmentIndex].name}이 있습니다.
            </div>
          </div>
          <div className="myprofile-list-container">
            <SwipeableViews
              className="myprofile-swipe"
              enableMouseEvents
              index={this.state.segmentIndex}
              onChangeIndex={this.handleChangeIndex}
            >
              {SegmentList.map((item, index) => {
                log(LogLevel.NONE, "OtherProfile Loading lists", item, index);
                if (
                  this.props.boards[SegmentList[index].id] &&
                  this.props.boards[SegmentList[index].id].contents
                )
                  return (
                    <BoardContentList
                      key={index.toString()}
                      ref={(ref) => {
                        this.contentScroll[index] = ref;
                      }}
                      id={index}
                      onScroll={this.onScroll}
                      loading={this.props.boards[SegmentList[index].id].loading}
                      contents={
                        this.props.boards[SegmentList[index].id].contents
                      }
                      checksum={
                        this.props.boards[SegmentList[index].id].checksum
                      }
                      component={BoardContentListSimpleItem}
                      onClickItem={(id) => {
                        this.onClickItem(SegmentList[index].name, id);
                      }}
                    />
                  );
                else return null;
              })}
            </SwipeableViews>
          </div>
          {this.props.users[this.userId] && (
            <IonActionSheet
              mode="ios"
              children
              isOpen={this.state.showMenuPopover}
              onDidDismiss={() => {
                this.setState(() => ({ showMenuPopover: false }));
                this.props.backKeyControl.popListener();
              }}
              buttons={[
                this.props.users[this.userId].blocked
                  ? {
                      text: "차단해제하기",
                      cssClass: "common-menu-normal",
                      handler: () => {
                        log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                        this.onUnblock();
                      },
                    }
                  : {
                      text: "차단하기",
                      cssClass: "common-menu-normal",
                      handler: () => {
                        log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
                        this.onBlock();
                      },
                    },
              ]}
              backdropDismiss={true}
            />
          )}
        </IonContent>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  users: state.user.users,
  boards: state.board.boards,
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
  waitingPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
});

const mapDispatchToProps = {
  loadUser: (id: number) => actions.user.getUser(id),
  loadFilePath: () => actions.board.getFilePath(),
  loadBoard: (id: number) => actions.board.getBoard(id),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OtherProfile)
);
