import { log, LogLevel } from "../utils/LogUtil";
import { UserInfo } from "./Model.User";
import { getDateStringFromToday } from "../utils/TimeUtil";

export interface PollInfo{
    id?: number,
    alias?: string,
    userId?: number,
    title?: string, // userId
    description?: string, // userId
    startAt?: string,
    endAt?: string,
    createdAt?: string,
    deletedAt?: string,

    responsed?: number,
    pageCnt?: number,
    itemCnt?: number,
    
    items?: PollItemInfo[],
    myResponse?: PollResponseItemInfo[],
    responses?: PollResponseInfo[],
}

export enum PollItemType {
    TEXT = 0,
    SELECTION = 1,
    MULTI_SELECTION = 2,
    NUMBER_LEVEL_SELECTION = 3,
    NUMBER = 4,
}

export interface PollItemInfo{
    id?: number,
    alias?: string,
    pollId?: number,
    order?: number,
    page?: number,
    mandatory?: boolean,
    question?: string,
    type?: PollItemType,
    description?:string,
    candidates?: string[],
    jumps?: number[],
    maxSelect?: number,
    needInput?: boolean,
    inputPlaceholder?: string,
    inputTitle?: string,
    inputPrefix?: string,
    inputPostfix?: string,
    inputMultiline?: boolean,
    defaultsSelection?: number[],
    conditionFlow?: any,
}

export interface PollResponseInfo{
    id?: number,
    userId?: number,
    user?: UserInfo,
    pollId?: number,
    poll?: PollInfo,
    createdAt?: string,
    items?: PollResponseItemInfo[]
}


export interface PollResponseItemInfo{
    id?: number,
    user?: UserInfo,
    pollId?: number,
    pollItemId?: number,
    pollResponseId?: number,
    pollItem?: PollItemInfo,
    value?: string,
    values?: string[],
}

export enum PollResponseItemLoadOption {
    BASE = 0x00,
    POLL_QUESTION = 0x01,
    POLL_DETATIL = 0x02,
    USER = 0x04,
    ALL = 0xff,
}

export interface PollStatisticsInfo{
    pollId?: number,
    responseCount?: number,
    poll?: PollInfo,
    items?: PollStatisticsItemInfo[],
}

export interface PollStatisticsItemInfo{
    pollItemId?: number,
    responseCount?: number,
    pollItem?: PollItemInfo,
    values?: PollStatisticsItemValueInfo[],
}

export interface PollStatisticsItemValueInfo {
    value?: any,
    count?: number,
    ratio?: number,
}

export const EMPTY_POLL:PollInfo = {
    id : 0,
    title : "", 
    description : "", 
    startAt : getDateStringFromToday(),
    endAt : getDateStringFromToday({months:3}),

    items : [],
}

export const EMPTY_POLL_ITEM:PollItemInfo = {
    id : 0,
    order: 0,
    page: 0,
    mandatory: false,
    question: "",
    type: PollItemType.TEXT,
    description:"",
    candidates: [],
}




export function comparePollItems(a: PollItemInfo, b:PollItemInfo){
    if(a.order == b.order)
        return a.id - b.id;
    return a.order - b.order;
}

export function trimResponse(response: PollResponseInfo){
    log(LogLevel.UI_DATA_LOAD, "Model.Poll:trimResponse before:", response);
    if(!response || !response.items || !response.items.length)
        return null;
        
    let answered;
    if(response.items && response.items.length){
        answered = response.items.filter((item) => {return (item.value || item.values )});

        if(!answered || !answered.length)
            return null;
        
        response.items = answered;
    }
    log(LogLevel.UI_DATA_LOAD, "Model.Poll:trimResponse after:", response);

    return response;
}

export function getPollItemIndexByAlias(poll:PollInfo, alias:string){
    if(!poll || !poll.items || !poll.items.length)
        return -1;
    for(let i = 0; i<poll.items.length; i++){
        if(poll.items[i].alias == alias)
            return i;
    }
    return -1;
}

export function onPollResponse(poll:PollInfo, pollItemindex: number, response: PollResponseItemInfo){
    if(!poll || pollItemindex<0)
        return;

    if(!poll.myResponse){
        poll = prepareResponse(poll, null);
    }

    if(poll.myResponse.length > pollItemindex)
        poll.myResponse[pollItemindex] = response;

    return poll;
}

export function prepareResponse(poll:PollInfo, response: PollResponseInfo){
    if(!poll)
        return;
    log(LogLevel.UI_DATA_LOAD, "Model.Poll:prepareResponse before:", poll, response);

    if(!response || !response.items || !response.items.length){
        poll.myResponse = poll.items.map((item) => { return {   
            pollItemId: item.id,
            text: "",
        }});
    }else{
        let items:PollResponseItemInfo[] = [...response.items];
        poll.myResponse = poll.items.map((item) => { 
            for(let i=0;i<items.length; i++){
                if(items[i].pollItemId == item.id){
                    let rvalue:PollResponseItemInfo = {...items[i]};
                    items.splice(i,1);
                    return rvalue;
                    break;
                }
            }
            return {   
                pollItemId: item.id,
                text: "",
            }
        });
    }
    log(LogLevel.UI_DATA_LOAD, "Model.Poll:prepareResponse after:", poll, response);

    return poll;
}

export function getReponse(poll:PollInfo){

}