import React from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { format } from "date-fns";
import {
  IonLabel,
  IonButton,
  IonIcon,
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonAlert,
} from "@ionic/react";
import "./BoardListItem.css";
import qustionIcon from "./../assets/image/question.png";
import { getDateTimeString } from "./../utils/TimeUtil";
import commentIcon from "./../assets/icon/comment.svg";
import viewIcon from "./../assets/icon/view.svg";
import scappedIcon from "./../assets/image/scrapped.png";
import unscappedIcon from "./../assets/image/unscrapped.png";
import { BoardContent } from "./../models/Model.Board";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import { BoardType } from "./../store/board/types";
import { getGlobal, GlobalKey } from "./../utils/GlobalUtil";
import Truncate from "react-truncate";
import { action } from "typesafe-actions";
import { log, LogLevel } from "../utils/LogUtil";
import ProfileImage from "./ProfileImage";
import LinkMeta from "./LinkMeta";
import { COLOR_SYSTEM } from "./design/design-system";
import Tag from "./atoms/Tag";
import Text from "./atoms/Text";
import { Flex, Static } from "./atoms/Layout";
import NoticeImage from "../assets/image/notice.png";

type Props = RouteComponentProps<{}> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    key?: string;
    posting?: BoardContent;
    postingId?: number;
  };

class BoardListNoticeItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  navigateToView = () => {
    let id = this.props.postingId;
    if (!id && this.props.posting) id = this.props.posting.id;
    this.props.history.push(`/boards/view?id=${id}`);
  };

  render() {
    log(LogLevel.UI_LIFECYCLE, "BoardListNoticeItem:render", this.props);
    let posting: BoardContent = this.props.posting;
    if (!posting && this.props.postingId) {
      posting = this.props.contents[this.props.postingId];
    }
    if (!posting || !posting.id) return null;

    return (
      // <IonItem class="boardlistitem-container boardlistitem-notice-container" >
      //   <div className="boardlistitem-inner boardlistitem-notice-inner"onClick={this.navigateToView}>
      //     <div className="boardlistitem-notice-category-container">
      //       [ 공 지 ]
      //     </div>
      //     <div className="boardlistitem-notice-title-container">
      //       {posting.subject}
      //     </div>
      //   </div>
      // </IonItem>
      <Flex
        customStyle={{
          backgroundColor: COLOR_SYSTEM.get("Gray")[0],
          margin: "4px 0px",
          overflow: "hidden",
          padding: "10px 20px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          borderTop: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
          borderBottom: `1px solid ${COLOR_SYSTEM.get("Gray")[50]}`,
        }}
        onClick={this.navigateToView}
      >
        <Tag variant="Contained" color="Black" size="Small">
          공지
        </Tag>
        <div className="common-flex-grow">
          <Text
            textType="Body2Medium"
            color={COLOR_SYSTEM.get("Gray")[700]}
            numberOfLines={1}
          >
            {posting.subject}
          </Text>
        </div>
      </Flex>
      // <Flex
      //   customStyle={{
      //     backgroundColor: COLOR_SYSTEM.get("Gray")[900],
      //     height: "120px",
      //     marginBottom: "12px",
      //     overflow: "hidden",
      //   }}
      //   onClick={this.navigateToView}
      // >
      //   <Flex
      //     direction="column"
      //     gap="12px"
      //     customStyle={{
      //       flex: 1,
      //       padding: "16px 0 20px 20px",
      //     }}
      //   >
      //     <div>
      //       <Tag color="Black" variant="Tinted">
      //         공지사항
      //       </Tag>
      //     </div>
      //     <Text textType="Body2Medium" color="#ffffff" numberOfLines={2}>
      //       {posting.subject}
      //     </Text>
      //   </Flex>
      //   <div style={{ position: "relative", top: "10px" }}>
      //     <img src={NoticeImage} width={100} height={100} />
      //   </div>
      // </Flex>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  contents: state.board.contents,
});

const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardListNoticeItem)
);

// export default withRouter(BoardListItem);
