import React, { Children, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import TYPOGRAPY from './../../_design/typography'
import COLOR from './../../_design/color'
import Text from './../../atom/Text'
import ToggleSwitch from '../../atom/ToggleSwitch'
import IconButton from '../../atom/IconButton'

const Wrapper: StyledComponent<'div', React.FC> = styled.div`
  width: 100%;

  .video-controller-container{
    padding: 10px 24px;
    background: ${COLOR.Gray8};
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;


    .video-controller-resolution-text{
      margin-left: 8px;
    }

    .video-controller-speed-text{
      min-width: 52px;
      text-align: center;
    }
  }

`

interface Props {
  highResolution?: boolean
  onChangeResolution?: (highResolution:boolean) => void
  speed?: number
  onChangeSpeed?: (speed: number) => void
}
const VideoController: React.FC<Props> = ({
  highResolution = false,
  onChangeResolution,
  speed = 1.0,
  onChangeSpeed,
}) => {

  return (
    <Wrapper>
      <div className="video-controller-container">
        {onChangeResolution && 
          <div className="common-flex-row common-flex-align-center" onClick={() => onChangeResolution(!highResolution)}>
            <ToggleSwitch checked={highResolution}/> 
            <Text type="Caption1" color="Gray2"  className="video-controller-resolution-text">고화질</Text>
          </div>
        }
        <div className="common-flex-grow"/>
        {onChangeSpeed && 
          <div className="common-flex-row common-flex-align-center">
            <IconButton disabled={speed == 0.8} iconName="PolygonDown" size="S" variant="Outlined" color="Gray" 
              onClick={() => {
                switch(speed){
                  case 1.0:
                    onChangeSpeed(0.8);
                    break;
                  case 1.2:
                    onChangeSpeed(1);
                    break;
                  case 1.5:
                    onChangeSpeed(1.2);
                    break;
                  case 2:
                    onChangeSpeed(1.5);
                    break;
                }
              }}
            />
            <Text type="Caption1" color="Secondary"  className="video-controller-speed-text">{speed}배속</Text>
            <IconButton disabled={speed == 2} iconName="PolygonUp" size="S" variant="Outlined" color="Gray" 
              onClick={() => {
                switch(speed){
                  case 0.8:
                    onChangeSpeed(1);
                    break;
                  case 1.0:
                    onChangeSpeed(1.2);
                    break;
                  case 1.2:
                    onChangeSpeed(1.5);
                    break;
                  case 1.5:
                    onChangeSpeed(2);
                    break;
                }
              }}
            />
          </div>
        }
      </div>
    </Wrapper>
  )
}

export default VideoController
