import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions} from '../store';
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import { IonButton } from '@ionic/react';
import './Landing.css';
import './../Common.css';
import {fetchAPI} from "../utils/APIUtil"
import * as API from './../API.json';
import logoIcon from './../assets/icon/logo.png'
import kakaoIcon from './../assets/icon/kakao_logo.png'
import {UserInfo} from './../models/Model.User'
import {log, LogLevel} from '../utils/LogUtil'

import {getGlobal, GlobalKey, setGlobal} from './../utils/GlobalUtil'
import { action } from 'typesafe-actions';
import { timerStart, timerStop, timerLap } from '../utils/TimeUtil';
import AnalyticsUtil from './../utils/AnalyticsUtil';
import { UIPopupType, UIServiceType } from '../store/ui/types';
import Login from './Login';
import {MY_ADDRESS} from '../config.json'

const windowAny: any = window;

type Props = RouteComponentProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
}

class Landing extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() { 
    if(this.props.backKeyControl) this.props.backKeyControl.setListener(this.goBack);
  }

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "Landing:goBack");
    let os = getGlobal(GlobalKey.OS);
    if(os && os != "browser" && windowAny.navigator && windowAny.navigator.app){
      windowAny.navigator.app.exitApp();
    }else{
      this.props.history.goBack()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.backKeyControl && this.props.backKeyControl)
      this.props.backKeyControl.setListener(this.goBack);
  }

  render() {
    log(LogLevel.NONE, "Landing:render");

    return (
      <div className='common-content common-flex-row common-flex-align-center'>
        <div className='common-content common-container-column common-flex-align-center'>
          <div className="common-flex-grow"/>
          <div className="common-small-outer-container">
            <div className="common-flex-grow">
              <Login full={true}/>
            </div>
            <div className="landing-button-container common-container-column common-flex-align-center">
              <div className="landing-button-text">모바일에서는 App으로 이용하세요~</div>
              <div className="common-flex-row">
                <a href="https://apps.apple.com/us/app/%EC%95%BD%EB%AC%B8%EC%95%BD%EB%8B%B5/id1461728432" target="_system"><img className="landing-button-image" src={MY_ADDRESS + "/images/download_apple.png"}/></a>
                <a href="https://play.google.com/store/apps/details?id=com.onjourney.pharmacycafe" target="_system"><img className="landing-button-image" src={MY_ADDRESS + "/images/download_google.png"}/></a>
              </div>
            </div>
          </div>
          <div className="common-flex-grow"/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  backKeyControl: state.ui.services[UIServiceType.BACK_CONTROLLER],
});

const mapDispatchToProps = {
  setMe: (user:UserInfo) => actions.user.setMe(user),
  setToken: (token: string) => actions.user.setToken(token)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing));