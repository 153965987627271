import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BoardSummaryPopup from "../../components/templates/BoardSummaryPopup";
import { useDispatch, useSelector } from "react-redux";
import { RootState, actions } from "../../store";
import { LogLevel, log } from "../../utils/LogUtil";
import { BoardContentSummary } from "../../models/Model.Board";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { GlobalKey, getGlobal } from "../../utils/GlobalUtil";
import { UserLevel } from "../../models/Model.User";
import { on } from "process";
import { UIPopupType } from "../../store/ui/types";
import AdsUtil from "../../utils/AdsUtil";
import ABTestUtil from "../../utils/ABTestUtil";

const windowAny: any = window;
export interface BoardSummaryPopupPageProps {
  contentId: number;
  onClickReference?: (focus: {
    contentId?: number;
    commentId?: number;
  }) => void;
}

const BoardSummaryPopupPage: React.FC<
  RouteComponentProps & BoardSummaryPopupPageProps
> = ({ history, location, match, contentId, onClickReference }) => {
  const premium = useSelector((state: RootState) => state.user.me.premiumType);
  const me = useSelector((state: RootState) => state.user.me);
  const summary: BoardContentSummary = useSelector(
    (state: RootState) => state.board.summaries[contentId]
  );

  const summaryGroups: any = useSelector(
    (state: RootState) => state.board.summaryGroups
  );

  const freeSummaryCount = useSelector(
    (state: RootState) => state.user.freeSummaryCount
  );
  const toast = useSelector(
    (state: RootState) => state.ui.popups[UIPopupType.TOAST_POPUP]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "게시글_요약팝업진입",
      {
        게시물id: contentId,
      }
    );
  }, []);

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardSummaryPopupPage:useEffect [contentId]",
      contentId
    );
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "게시글_요약팝업진입",
      {
        게시물id: contentId,
      }
    );
    if (!summary) {
      dispatch(actions.board.getSummary(contentId));
    }
  }, [contentId]);

  useEffect(() => {
    log(
      LogLevel.UI_LIFECYCLE,
      "BoardSummaryPopupPage:useEffect [summary]",
      summary,
      freeSummaryCount
    );

    if (summary && summary.state != "START" && !summary.viewed) {
      log(
        LogLevel.UI_LIFECYCLE,
        "BoardSummaryPopupPage:useEffect [summary.state != START && !summary.viewed && freeSummaryCount > 0]",
        summary,
        freeSummaryCount
      );
      if (!me.premiumType && freeSummaryCount > 0) {
        dispatch(actions.user.decreaseFreeSummaryCount());

        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "GPT_SUMMARY_FREE",
          "무료요약차감",
          {
            게시물id: contentId,
          }
        );
      }
      if (me.premiumType || freeSummaryCount > 0) {
        fetchAPI(
          API.ACTION_SUMMARY,
          contentId,
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        dispatch(actions.board.setSummary({ id: contentId, viewed: true }));
      }
    }

    if (summary && summary.keyword && summary.keyword.length > 0) {
      for (let i = 0; i < summary.keyword.length; i++) {
        dispatch(actions.board.getSummaryGroupCount(summary.keyword[i]));
      }
    }
  }, [summary]);

  const onAdminRefresh = async () => {
    await fetchAPI(
      API.ADMIN_BOARD_SUMMARY_CLEAR,
      contentId,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );

    dispatch(actions.board.clearSummary(contentId));
    dispatch(actions.board.getSummary(contentId));
  };

  const unlock = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "게시글_요약팝업_후원페이지이동",
      {
        게시물id: contentId,
      }
    );

    history.push("/premium/subscribe", {
      from: location.pathname + location.search,
    });
  };

  const onReport = (message: string) => {
    fetchAPI(
      API.BOARDS_POST_SUMMARY_REPORT,
      "",
      null,
      { memo: message, contentId: contentId },
      getGlobal(GlobalKey.TOKEN)
    );
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "게시글_요약팝업_오류보고",
      {
        게시물id: contentId,
        memo: message,
      }
    );
  };

  const onClickKeyword = (keyword: string) => {
    history.push("/boards/summary?keyword=" + keyword);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "게시글_요약팝업_키워드선택",
      {
        게시물id: contentId,
        keyword: keyword,
      }
    );
  };

  const onClickAdsInterstitial = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "게시글_요약팝업_광고",
      {
        type: "interstitial",
        게시물id: contentId,
      }
    );
    AdsUtil.show(AdsUtil.TYPE_INTERSTITIAL, {});
  };

  const onClickAdsReward = () => {
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "GPT_SUMMARY_START",
      "게시글_요약팝업_광고",
      {
        type: "reward",
        게시물id: contentId,
      }
    );
    AdsUtil.show(AdsUtil.TYPE_REWARD, {
      name: "요약보기",
      onRewarded: () => {},
    });
    fetchAPI(
      API.ACTION_SUMMARY,
      contentId,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    dispatch(actions.board.setSummary({ id: contentId, viewed: true }));
  };

  log(
    LogLevel.UI_LIFECYCLE,
    "BoardSummaryPopupPage:render ",
    premium,
    freeSummaryCount <= 0,
    summary,
    summary && !summary.viewed,
    contentId
  );

  return (
    <BoardSummaryPopup
      summary={summary}
      summaryGroups={summaryGroups}
      onClickReference={onClickReference}
      onClickAdsReward={onClickAdsReward}
      premium={premium}
      freeSummaryCount={freeSummaryCount}
      onAdminRefresh={me.level >= UserLevel.MANAGER ? onAdminRefresh : null}
      onClickUnlock={unlock}
      onReport={onReport}
      onClickKeyword={onClickKeyword}
    />
  );
};

export default withRouter(BoardSummaryPopupPage);
