import React, { FC, ReactNode } from "react";
import { Flex } from "../atoms/Layout";
import { JsxElement } from "typescript";

interface BottomSheetListItemProps {
  left?: ReactNode | JsxElement;
  right?: ReactNode| JsxElement;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const BottomSheetListItem: FC<BottomSheetListItemProps> = ({ left, right, onClick }) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" onClick={onClick}>
      {left}
      {right}
    </Flex>
  );
};

export default BottomSheetListItem;
