import { BoardContent, Attachment } from "../models/Model.Board";
import { LogLevel, log } from "./LogUtil";
import { fetchAPI } from "./APIUtil";
import * as API from "./../API.json";
import { getGlobal, GlobalKey } from "./GlobalUtil";
import StringUtil from "./StringUtil";
import { UserInterests } from "../models/Model.User";

const windowAny: any = window;
const naver: any = windowAny.naver;

export default class GeocodeUtil {
  static getGPSByAddress = async (address: string, near: boolean = true) => {
    return new Promise((resolve, reject) => {
      GeocodeUtil._getGPSByAddressRecursive(resolve, address, near);
    });
  };

  static _getGPSByAddressRecursive(resolve, address, recursive) {
    log(LogLevel.UI_DATA_LOAD, "GeocodeUtil:_getGPSByAddressRecursive", address);
    let position = { x: 0, y: 0 };
    if (naver.maps) {
      naver.maps.Service.geocode(
        {
          query: address,
        },
        (status, response) => {
          if (
            status == naver.maps.Service.Status.OK &&
            response &&
            response.v2 &&
            response.v2.addresses &&
            response.v2.addresses.length
          ) {
            let item = response.v2.addresses[0]; // 검색 결과의 배열
            log(LogLevel.UI_DATA_LOAD, "GeocodeUtil:_getGPSByAddressRecursive result", item);
            position = { x: item.x, y: item.y };
            resolve(position);
          } else {
            log(LogLevel.UI_EXCEPTION, "GeocodeUtil:_getGPSByAddressRecursive error", address, status, response);
            if (recursive) {
              let tokens = address.split(" ");
              if (tokens.length > 1) {
                tokens.splice(tokens.length - 1, 1);
                let shortAddress = tokens.join(" ");
                GeocodeUtil._getGPSByAddressRecursive(resolve, shortAddress, recursive);
              } else resolve(position);
            } else {
              resolve(position);
            }
          }
        }
      );
    } else {
      resolve(position);
    }
  }

  static getPostalCode = async (address: string, near: boolean = true) => {
    return new Promise<string>((resolve, reject) => {
      GeocodeUtil._getPostalCodeByAddressRecursive(resolve, address, near);
    });
  };

  static _getPostalCodeByAddressRecursive(resolve, address, recursive) {
    log(LogLevel.UI_DATA_LOAD, "GeocodeUtil:_getPostalCodeByAddressRecursive", address);
    naver.maps.Service.geocode(
      {
        query: address,
      },
      (status, response) => {
        let code = "";
        if (
          status == naver.maps.Service.Status.OK &&
          response &&
          response.v2 &&
          response.v2.addresses &&
          response.v2.addresses.length
        ) {
          let item = response.v2.addresses[0]; // 검색 결과의 배열
          log(LogLevel.UI_DATA_LOAD, "GeocodeUtil:_getPostalCodeByAddressRecursive result", item);

          if (item.addressElements && item.addressElements.length) {
            for (let i = 0; i < item.addressElements.length; i++) {
              if (
                item.addressElements[i].types &&
                item.addressElements[i].types.length &&
                item.addressElements[i].types[0] == "POSTAL_CODE"
              ) {
                code = item.addressElements[i].longName;
                log(LogLevel.UI_EXCEPTION, "GeocodeUtil:_getPostalCodeByAddressRecursive found", code);
                break;
              }
            }
          }
        }

        if (!code && recursive) {
          log(LogLevel.UI_EXCEPTION, "GeocodeUtil:_getPostalCodeByAddressRecursive error", address, status, response);
          let tokens = address.split(" ");
          if (tokens.length > 1) {
            tokens.splice(tokens.length - 1, 1);
            let shortAddress = tokens.join(" ");
            GeocodeUtil._getPostalCodeByAddressRecursive(resolve, shortAddress, recursive);
          } else {
            resolve(code);
          }
        } else {
          resolve(code);
        }
      }
    );
  }
}
