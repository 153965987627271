import React, { useCallback, useEffect, useRef } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Textarea from 'react-textarea-autosize';
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from '../Text';
import { log, LogLevel } from '../../../utils/LogUtil';
import Icon from '../Icon';

interface WrapperProps {
  rounded: boolean;
}
const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  .progress-bar-container {
    width: 100%;
    height: 4px;
    min-height: 4px;
    padding: 0px;
    border-radius: ${(props) => props.rounded?"2":"0"}px;
    position: relative;
    overflow: hidden;

    .progress-bar-bg{
      width: 100%;
      min-height: 4px;
      height: 4px;
      background: ${COLOR.White};
    }

    .progress-bar-fg{
      overflow: hidden;
      position: absolute;
      height: 4px;
      min-height: 4px;
      top: 0px;
      left: 0px;
    }
  }
  
`

export interface Props {
  progress?: number;
  color?: string;
  rounded?: boolean;
}
const ProgressBar: React.FC<Props> = ({
  progress = 0,
  color = "Primary",
  rounded = true,
}) => {
  if(!progress)
    progress = 0;
  else if (progress > 100)
    progress = 100  

  return (
    <Wrapper rounded={rounded}>
      <div className="progress-bar-container" >
        <div className="progress-bar-bg"/>
        <div className="progress-bar-fg" style={{width:progress+"%", background:COLOR[color]}}/>
      </div>
    </Wrapper>

  )
}

export default ProgressBar
