import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  AllInOnePartnerLevel,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
  UserInfo,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import { BizUserInfo } from "../../../models/Mode.Biz";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import { Flex } from "../../../components/atoms/Layout";
import {
  PremiumPurchaedStatus,
  PremiumType,
  UserPremiumInfo,
} from "../../../models/Model.User.Premium";
import { getDateStringFromToday } from "../../../utils/TimeUtil";

const API = {
  PREMIUM_LIST: {
    method: "GET",
    path: "/admin/user/premiums",
    contentType: "application/json",
  },
};

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (premium: UserPremiumInfo) => void;
    onClose?: () => void;
  };

type State = {
  search: string;
  premiums: UserPremiumInfo[];
  count: number;
  dateStart: string;
  dateStartWarn: boolean;
  dateEnd: string;
  dateEndWarn: boolean;
  finished: boolean;
  selectedID: number;
  aliveOnly: boolean;
  all: boolean;
};
const USER_LOAD_COUNT = 50;
class PremiumUsersSelect extends Component<Props, State> {
  loading = false;
  userList = null;

  state = {
    search: "",
    premiums: [],
    count: USER_LOAD_COUNT,
    dateStart: getDateStringFromToday({ months: -1 }),
    dateStartWarn: false,
    dateEnd: getDateStringFromToday({ days: 1 }),
    dateEndWarn: false,
    finished: false,
    selectedID: -1,
    aliveOnly: true,
    all: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onKeyPress = (e) => {
    log(
      LogLevel.UI_ACTION,
      "PremiumUsersSelect:onKeyPress",
      e.keyCode,
      e.shiftKey
    );
    if (e.keyCode == 13) {
      this.fetchData();
      e.preventDefault();
    }
  };

  onSearchChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({ search: keywords });
  };

  refresh = () => {
    this.fetchData();
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:PremiumUsersSelect:fetchData");
    if (this.state.dateStartWarn || this.state.dateEndWarn) return;
    this.loading = true;
    let finished = false;

    let option = {
      start: 0,
      count: this.state.count,
      search: this.state.search,
    };
    if (this.state.aliveOnly) option["aliveOnly"] = true;
    if (this.state.all) option["all"] = true;

    fetchAPI(
      API.PREMIUM_LIST,
      "",
      option,
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:PremiumUsersSelect:fetchData result",
          result
        );
        if (result.data && result.data.length < this.state.count)
          finished = true;

        this.setState({ premiums: result.data, finished });
        this.loading = false;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:PremiumUsersSelect:fetchData result",
          result
        );
        finished = true;
        this.setState({ premiums: [], finished });
      }
    });
  };

  fetchDataMore = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:PremiumUsersSelect:fetchDataMore");
    this.loading = true;
    let finished = false;
    if (!this.state.finished) {
      let option = {
        start: this.state.premiums.length,
        count: this.state.count,
        search: this.state.search,
      };
      if (this.state.aliveOnly) option["aliveOnly"] = true;
      if (this.state.all) option["all"] = true;

      fetchAPI(
        API.PREMIUM_LIST,
        "",
        option,
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:PremiumUsersSelect:fetchData result",
            result
          );

          if (result.data && result.data.length < this.state.count)
            finished = true;

          this.setState({
            premiums: [...this.state.premiums, ...result.data],
            finished,
          });
          this.loading = false;
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:PremiumUsersSelect:fetchData result",
            result
          );
          finished = true;
          this.setState({ finished });
          this.loading = false;
        }
      });
    }
  };

  clearSearch = () => {
    this.setState({ search: "" });
  };

  onSelect = (user: UserInfo) => {
    log(LogLevel.UI_EVENT, "Admin:PremiumUsersSelect:onSelect", user);
    this.props.onDone(user);
  };

  download = (filename, text) => {
    var pom = document.createElement("a");
    pom.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      var event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  };

  onDownload = () => {
    this.props.confirmPopup.show({
      iconImage: null,
      title: (
        <div className="common-container">
          <span className="common-bold">
            회원정보 다운로드에{" "}
            <span className="common-bold common-color-caution">
              주의를 기울이십시오.
            </span>
          </span>
        </div>
      ),
      body: (
        <div className="common-container">
          회원정보 다운로드 받는 기록이 서버에 남습니다.
          <br />
          사용된 정보는 반드시 삭제하시고, <br /> 파일의 보안에 철저히
          신경쓰세요.
        </div>
      ),
      onDone: () => {
        // fetchAPI(
        //   API.PREMIUM_LIST_DOWNLOAD,
        //   "",
        //   { start: 0, query: 0, level: this.state.level, search: this.state.search },
        //   null,
        //   getGlobal(GlobalKey.TOKEN)
        // ).then((result) => {
        //   log(LogLevel.UI_DATA_LOAD, "Admin:UserManage", result);
        //   if (result)
        //     this.download(
        //       `Users-${UserLevelName[this.state.level]}-${this.state.search}-${new Date()
        //         .toISOString()
        //         .substring(0, 10)
        //         .replace(/-/gi, "")}.csv`,
        //       result
        //     );
        // });
      },
      doneText: "네",
    });
  };

  render() {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:PremiumUsersSelect:render",
      this.state.premiums
    );
    return (
      <div className="admin-grow-container">
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  프리미엄회원 목록
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <Flex
          gap="20px"
          alignItems="center"
          customStyle={{ width: "100%", padding: "10px 10px" }}
        >
          <Flex customStyle={{ minWidth: "150px", gap: "8px" }}>
            모든 항목 보기
            <ToggleSwitch
              checked={this.state.all}
              onClick={() =>
                this.setState({
                  all: !this.state.all,
                  aliveOnly: this.state.all ? this.state.aliveOnly : false,
                })
              }
            >
              <IonIcon size="large" slot="icon" icon="moon" />
            </ToggleSwitch>
          </Flex>
          <Flex customStyle={{ minWidth: "150px", gap: "8px" }}>
            유효회원만 보기
            <ToggleSwitch
              checked={this.state.aliveOnly}
              onClick={() =>
                this.setState({ aliveOnly: !this.state.aliveOnly })
              }
            >
              <IonIcon size="large" slot="icon" icon="moon" />
            </ToggleSwitch>
          </Flex>
          <Flex gap="8px">
            <div
              className={
                "admin-toggle" +
                (this.state.count == USER_LOAD_COUNT
                  ? " admin-toggle-selected"
                  : "")
              }
              onClick={() => this.setState({ count: USER_LOAD_COUNT })}
            >
              {USER_LOAD_COUNT}row
            </div>
            <div
              className={
                "admin-toggle" +
                (this.state.count == 5000 ? " admin-toggle-selected" : "")
              }
              onClick={() => this.setState({ count: 5000 })}
            >
              5000row
            </div>
          </Flex>
        </Flex>
        <div className="admin-item-container">
          <IonInput
            class="admin-input"
            placeholder="검색"
            value={this.state.search}
            onIonChange={this.onSearchChange}
            onKeyDown={this.onKeyPress}
          />
          <IonButton fill="clear" color="primary" onClick={this.clearSearch}>
            <IonIcon name="close" />
          </IonButton>
        </div>

        <div className="admin-item-container">
          <IonButton color="primary" onClick={this.fetchData}>
            불러오기
          </IonButton>
          <IonButton
            color="primary"
            onClick={() =>
              this.props.onDone({
                id: 0,
                userId: 0,
                amount: 0,

                type: PremiumType.DONOR,
                status: PremiumPurchaedStatus.PROVIDED,
                endAt: getDateStringFromToday({ months: 1 }),
              })
            }
          >
            새로등록
          </IonButton>
          <IonButton color="primary" onClick={this.onDownload}>
            다운로드
          </IonButton>
          &nbsp;&nbsp;총 {this.state.premiums.length}명 (&nbsp;월&nbsp;
          {this.state.premiums &&
            this.state.premiums.length &&
            this.state.premiums
              .reduce((total, item: UserPremiumInfo) => total + item.amount, 0)
              .toLocaleString()}
          원&nbsp;)
        </div>

        <div className="common-container">
          {!this.state.finished && (
            <IonButton
              color="primary"
              style={{ width: "100%" }}
              onClick={this.fetchDataMore}
            >
              더 불러오기
            </IonButton>
          )}
          <table className="admin-table">
            <thead className="admin-table-fixed-header">
              <tr>
                <td className="admin-table-label-x">ID</td>
                <td className="admin-table-label-x">UID</td>
                <td className="admin-table-label-x">상태</td>
                <td className="admin-table-label-x">이름</td>
                <td className="admin-table-label-x">닉네임</td>
                <td className="admin-table-label-x">Email</td>
                <td className="admin-table-label-x">전화번호</td>
                <td className="admin-table-label-x">금액</td>
                <td className="admin-table-label-x">시작일</td>
                <td className="admin-table-label-x">종료일</td>
                <td className="admin-table-label-x">결제예약일</td>
              </tr>
            </thead>
            <tbody>
              {this.state.premiums &&
                this.state.premiums.map(
                  (item: UserPremiumInfo, index: number) => {
                    let route = [];
                    return (
                      <tr onClick={() => this.props.onDone(item)}>
                        <td className="admin-table-value">{item.id}</td>
                        <td className="admin-table-value">{item.userId}</td>
                        <td className="admin-table-value">
                          {PremiumPurchaedStatus[item.status]}
                        </td>

                        <td className="admin-table-value">{item.user.name}</td>
                        <td className="admin-table-value">
                          {item.user.nickname}
                        </td>
                        <td className="admin-table-value">{item.user.email}</td>
                        <td className="admin-table-value">{item.user.phone}</td>
                        <td className="admin-table-value">{item.amount}</td>
                        <td className="admin-table-value">
                          {getDateStringFromToday({ date: item.createdAt })}
                        </td>
                        <td className="admin-table-value">
                          {item.endAt &&
                            getDateStringFromToday({ date: item.endAt })}
                        </td>
                        <td className="admin-table-value">
                          {item.nextPurchasePlanedAt &&
                            getDateStringFromToday({
                              date: item.nextPurchasePlanedAt,
                            })}
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
          {!this.state.finished && (
            <IonButton
              color="primary"
              style={{ width: "100%" }}
              onClick={this.fetchDataMore}
            >
              더 불러오기
            </IonButton>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PremiumUsersSelect);
