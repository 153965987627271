import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from "constants";
import { BoardType } from "../store/board/types";
import { log, LogLevel } from "./LogUtil";

export enum GlobalKey {
  OS = "os",
  DEVICE = "device",
  TOKEN = "token",
  TOTAL_USER_COUNT = "totalUserCount",
  SEARCH_MEDICAL_TEXT = "searchMedicalText",
  SEARCH_SEGMENT = "searchTab",
  SEARCH_SCROLL = "searchScroll",
  SCROLL = "scroll-",
  MAIN_SEGMENT = "intMainTab",
  LOCAL_DEALS_SEGMENT = "intLocalDealTab",
  MY_SEGMENT = "intMyTab",
  OTHER_SEGMENT = "intOtherTab",
  NOTIFICATION_KEY = "notiKey",
  NOTIFICATION_OPTION = "notiOption",
  NOTIFICATION_CHECKED_TIME = "notiChecked",
  NOTIFICATION_INITIALIZED = "notiInit",
  SCHOLAR_SEARCH = "scholarSearch",
  WELCOME_DONE = "welcomeDone",
  TIMER = "timer_",
  SEARCH_ENGINE = "searchEngine",
  INITIAL_CONTENT = "initialContent",
  LONG_PRESS_TIMER = "longPressTimer",
  FILE_PATH = "jsonFilePath",
  TEMP = "temp_",
  EVENT_LIST = "jsonEvent",
  REGISTRATION_DATA = "jsonRegistration",
  REGISTRATION_PROGRESS = "registrationProgress",
  GROUND_RULE_GUIDED_DATE = "groundRuleGuidedDate",
  NEED_GROUND_RULE_GUIDE = "needGroundRuleGuide",
  ADMIN_INGREDIENT_PAGE = "adminIngredientPage",
  ADMIN_MEDICINE_PAGE = "adminMedicinePage",
  ADMIN_DIGEST_ORDER = "intAdminDigestOrder",
  ADMIN_DIGEST_SCORE = "jsonAdminDigestScore",
  // UNREAD_RECOMMENDS = "jsonUnreadRecommends",
  // UNREAD_RECOMMENDS_LOADED_AT = "unreadRecommendsLoadedAt",
  // UNREAD_RECOMMENDS_COUNT = "unreadRecommendsCnt",
  NUDGE_READY = "nudgeReady",
  NUDGED_LAST_DATE = "lastNudgedDate",
  BOARD_LAST_VISITED = "intBoardLastVisited-",
  BIZ_BOARD_LAST_VISITED = "intBizBoardLastVisited-",
  BOARD_UNION_CATEGORIES = "jsonBoardUnionCategories",
  ADMIN_STATISTICS_USER_SHOW_OPTION = "intAdminStatisticsUserShowOption",
  ADMIN_STATISTICS_BOARD_SHOW_OPTION = "intAdminStatisticsBoardShowOption",
  ADMIN_STATISTICS_LECTURE_SHOW_OPTION = "intAdminStatisticsLectureShowOption",
  COMPOSER_AUTO_SAVE = "jsonComposerSavedAuto",
  COMPOSER_DRAFT = "jsonComposerDraft_",

  SEMINAR_HIGH_RESOLUTION = "bSeminarHighResolution",

  GAMIFICATION_LAST_REPORT = "intGamificationLastReportDate",
  ENABLE_TEST = "bEnableTest",
  RECOMMEND_WITHOUT_REPLIES = "bAdminRecommendWithoutReplies",
  SHOW_RECOMMEND_ON_COMPOSER = "bShowRecommendOnComposer",
  SHOW_MENU = "bShowMenu",
  MENU_LAST_CHECKED = "menuLastChecked",
  JOBPOST_SEARCH_SEGMENT = "intJobPostSearchSegment",
  JOBPOST_SEARCH_PROVICE = "jobPostSearchSegment",
  JOBPOST_SEARCH_LAST_OPTION = "jsonjobPostSearchLastOption",
  JOBPOST_MAIN_SCROLL = "intJobPostMainScroll",
  JOBPOST_PHARMACIST_GUIDE_POPUP_AT = "intJobPostPhamacyGuidPopupAt",
  JOBPOST_LAST_VISITED = "intJobpostLastVisited",
  JOBPOST_DRAFT_OFFER = "jsonJobpostDraftOffer",
  ADMIN_DFR_MESSAGE = "adminDFRMessage",
  SALE_LAST_ORDER_ID = "intSaleLastOrderId",
  COPYRIGHT_NOTIFIED_AT = "intCopyrightNotifiedAt",
  LAST_JOBPOST_OFFER_EDIT = "jsonLastJobpostOfferEdit",
  DAILYPHARM_ID = "strDfId",
  DAILYPHARM_PWD = "strDfPwd",
  DAILYPHARM_OFFER_OPTION = "strDfOpt",
  ADMIN_BANNER_OPTION_ALIVE = "bAdminBannerAlive",
  SEMINAR_ORDER_BY = "strSeminarOrderBy",
  SEMINAR_SEGMENT = "intSeminarSegment",

  BOARD_UNION_GUIDED = "intBoardUnionGuided",
  VIEWER_SUMMARY_GUIDED = "intViewerSummaryGuided",
  // DEPRECATED
  SEMINAR_PLAYED_TIME = "numSeminarPlayedTime_",
  YPARTNERS_CATEGORY_INDEX = "intYPartnersCategoryIndex",
}

const GlobalDefault = {
  [GlobalKey.NOTIFICATION_OPTION]: "-ABCDEFGHIJKL",
  welcomeDone: false,
  searchEngine: 1,
  longPressTimer: null,
  totalUserCount: 3800,
  adminIngredientPage: 0,
  adminMedicinePage: 0,
  unreadRecommendsLoadedAt: 0,
  unreadRecommendsCnt: 30,
  lastNudgedDate: 0,
  "intBoardLastVisited-1": new Date("2020-01-20").getTime(),
  "intBoardLastVisited-2": new Date("2020-01-20").getTime(),
  "intBoardLastVisited-3": new Date("2020-01-20").getTime(),
  adminStatisticsUserShowOption: 0xffff,
  [GlobalKey.BOARD_UNION_CATEGORIES]: [
    BoardType.QNA,
    BoardType.CONSULT_QNA,
    BoardType.ALLINONE,
  ],
  [GlobalKey.BOARD_UNION_CATEGORIES + BoardType.RECOMMEND]: [
    BoardType.QNA,
    BoardType.CONSULT_QNA,
    BoardType.ALLINONE,
  ],
  [GlobalKey.YPARTNERS_CATEGORY_INDEX]: -1,
};

const globalAny: any = global;

export function getGlobal(
  key: string,
  useStorage: boolean = false,
  defaultValue?: any
) {
  let rvalue = null,
    rvalueD = null,
    rvalueS = null;
  if (globalAny.ycGlobal) rvalue = globalAny.ycGlobal[key];

  if (!rvalue && rvalue != 0 && rvalue != "" && useStorage) {
    rvalueS = rvalue = localStorage.getItem("yc_" + key);

    if (key.startsWith("json")) {
      if (rvalue) rvalue = JSON.parse(rvalue);
      else rvalue = null;
    } else if (rvalue && key.startsWith("int")) {
      rvalue = parseInt(rvalue);
      if (!rvalue) rvalue = 0;
    } else if (rvalue && key.startsWith("num")) {
      rvalue = Number(rvalue);
    } else if (rvalue && key.startsWith("b")) {
      rvalue = rvalue == "true";
    }
    setGlobal(key, rvalue);
  }

  if (!rvalue && rvalue != 0 && rvalue != "" && rvalue != false) {
    if (defaultValue !== undefined) rvalue = defaultValue;
    else if (GlobalDefault.hasOwnProperty(key)) rvalue = GlobalDefault[key];
    else if (key.startsWith("int") || key.startsWith("num")) rvalue = 0;
    else if (key.startsWith("b")) rvalue = false;
    else if (key.startsWith("str")) rvalue = "";
    else if (key.startsWith("json")) rvalue = {};
    rvalueD = rvalue;

    setGlobal(key, rvalue);
  }

  log(LogLevel.UI_DATA_LOAD, "getGlobal", key, rvalue, rvalueS, rvalueD);

  return rvalue;
}

export function setGlobal(
  key: string,
  value: any,
  useStorage: boolean = false
) {
  if (!globalAny.ycGlobal) globalAny.ycGlobal = {};
  globalAny.ycGlobal[key] = value;

  if (useStorage) {
    let saveValue = value;
    if (key.startsWith("json")) {
      saveValue = JSON.stringify(saveValue);
    }
    localStorage.setItem("yc_" + key, saveValue);
  }

  log(LogLevel.UI_ACTION, "setGlobal", key, value, useStorage);
}

export function clearGlobal(key: string, useStorage: boolean = false) {
  if (globalAny.ycGlobal && globalAny.ycGlobal[key])
    globalAny.ycGlobal[key] = null;

  if (useStorage) {
    localStorage.removeItem("yc_" + key);
  }
  // log(LogLevel.NONE, "setGlobal", globalAny.ycGlobal, key, value);
}
