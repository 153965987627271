import React, { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Flex } from "../../atoms/Layout";
import { fetchAPI } from "../../../utils/APIUtil";
import Button from "../../atoms/Button";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import API from "../../../API.json";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { UIPopupType, UIServiceType } from "../../../store/ui/types";
import useBottomSheet from "../../../hooks/useBottomSheet";
import ShareList from "../ShareList";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Icon from "../../atoms/Icon";
import {
  UserLevel,
  UserNudgeStages,
  UserNudgeState,
} from "../../../models/Model.User";
import { BoardAttribute, BoardContent } from "../../../models/Model.Board";
import useShare from "../../../hooks/useShare";
import { log, LogLevel } from "../../../utils/LogUtil";
import { MY_ADDRESS } from "../../../config.json";
import { IonActionSheet } from "@ionic/react";
import {
  getContent,
  refreshBoards,
  updateContent,
} from "../../../store/board/actions";
import { setMe } from "../../../store/user/actions";
import { BoardType } from "../../../store/board/types";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import BoardUtil from "../../../utils/BoardUtil";
import { toast } from "react-toastify";

interface BoardViewTopAppBarProps {
  boardId: number;
  scrapped: boolean;
  content: BoardContent;
  adminMode?: boolean;
  onBack?: (e) => void;
}

enum QuestionReports {
  Delete = 5,
  Irrelevant = 4,
  HateSpeech = 1,
  Spam = 2,
}

const AdminAPI = {
  TOGGLE_NO_NICE: {
    method: "GET",
    path: "/admin/board/toggle/nice",
    contentType: "application/json",
  },
  MOVE: {
    method: "PUT",
    path: "/admin/board/content/category",
    contentType: "application/json",
  },
  QUIZ: {
    method: "GET",
    path: "/admin/quiz/generate",
    contentType: "application/json",
  },
};

const BoardViewTopAppBar: FC<BoardViewTopAppBarProps & RouteComponentProps> = ({
  boardId,
  scrapped,
  history,
  content,
  adminMode,
  onBack,
}) => {
  const dispatch = useDispatch();
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const backKeyControl = useSelector(
    (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
  );
  const confirmPopup = useSelector(
    (state: RootState) => state.ui.popups[UIPopupType.CONFIRM_POPUP]
  );
  const toastPopup = useSelector(
    (state: RootState) => state.ui.popups[UIPopupType.TOAST_POPUP]
  );
  const me = useSelector((state: RootState) => state.user.me);
  const contents = useSelector((state: RootState) => state.board.contents);
  const bottomSheet = useBottomSheet();
  const { boardShare } = useShare({ board: contents[boardId] }, me);

  const handleClickReport = (report: QuestionReports) => async () => {
    await fetchAPI(
      API.ACTION_REPORT,
      `${report}/${boardId}`,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    toastPopup.show("신고처리가 완료되었습니다.");
    bottomSheet.close();
  };

  const onModify = () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onModify", content);
    if (adminMode) {
      let url = `/edit?id=${content.id}`;
      if (content.groupParent) {
        url += `&parent=${content.groupParent}`;
      }

      window.open(MY_ADDRESS + url, "_blank");
      return;
    }
    if (
      getGlobal(GlobalKey.OS) !== "browser" &&
      ((content.keywords && content.keywords.length > 0) ||
        (content.imagesInner && content.imagesInner.length > 0))
    ) {
      // show "go to PC to modify"
      confirmPopup.show({
        title: (
          <div className="common-container">
            이 글은 PC 브라우저에서 수정하실 수 있습니다.
          </div>
        ),
        body: (
          <div className="common-container">
            이 글은 PC 브라우저로 www.ymyd.co.kr로 접속하시면 수정이
            가능하십니다.
          </div>
        ),
      });
      return;
    }
    let url = `/edit?id=${content.id}`;
    if (content.groupParent) {
      url += `&parent=${content.groupParent}`;
    }
    history.push(url);
  };

  // const popupBackkey = (name) => {
  //   backKeyControl.pushListener(() => {
  //     let newState: any = {};
  //     newState[name] = false;
  //     this.setState(newState);
  //     return true;
  //   });
  // }
  const onDelete = async () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onDelete", content);
    let result = await fetchAPI(
      API.BOARD_DELETE,
      content.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (!result.error) {
      if (boardId !== content.id) dispatch(getContent(boardId));
      else {
        dispatch(refreshBoards(content.boardsCategoriesId));
        history.goBack();
      }
    }
  };

  const onScrap = async () => {
    if (content && content.deletedAt) return;
    let scrapped = content.scrapped;
    let scrapCnt = content.scrapCnt;
    log(LogLevel.NONE, "doScrap", !scrapped);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    if (scrapped) {
      result = await fetchAPI(
        API.ACTION_CANCEL_SCRAP,
        content.id,
        null,
        null,
        token
      );
      scrapCnt--;
    } else {
      result = await fetchAPI(API.ACTION_SCRAP, content.id, null, null, token);
      scrapCnt++;
      // AMPLITUDE
      let body;
      if (content.subject) body = content.subject.substring(0, 10);
      else if (content.bodyText) body = content.bodyText.substring(0, 10);
      let name = "";
      if (content.usersNickname) name = content.usersNickname;
      else content.usersCustomName;
      name = content.usersCustomName;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_SCRAP",
        "게시물_스크랩",
        {
          게시물id: content.id,
          게시물내용: body,
          글쓴이id: content.usersId,
          글쓴이닉네임: name,
          게시판이름: content.boardsCategoriesName,
        }
      );

      let advertiserInfo;
      if (content.commercialType && content.advertiserCode) {
        advertiserInfo = {
          code: content.advertiserCode,
          productCode: content.advertiserProductCode,
          contentType:
            content.cards && content.cards.length ? "CARDNEWS" : "CONTENT",
          contentId: content.id,
          contentTitle: content.subject,
        };
      }
      if (advertiserInfo) {
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_REACTION",
          "광고고객반응",
          {
            type: "스크랩",
            ...advertiserInfo,
          }
        );
      }
    }
    if (result && !result.error) {
      content.scrapCnt = scrapCnt;
      content.scrapped = !scrapped;

      //console.log("updateContent main", this.content);
      BoardUtil.checksum(content);
      BoardUtil.countAllLike(content);
      dispatch(updateContent(content));
      dispatch(refreshBoards(BoardType.MY_SCRAP));
    }

    if (ABTestUtil.isTest(ABTestFeature.NUDGE)) {
      if (
        content.scrapped &&
        !getGlobal(GlobalKey.NUDGE_READY) &&
        (!me.nudgeState || me.nudgeState < UserNudgeState.NUDGED_IGNORED)
      ) {
        me.scrapCnt = me.scrapCnt ? me.scrapCnt + 1 : 1;
        dispatch(setMe(me));
        let point: number =
          (me.likeCnt ? me.likeCnt : 0) + (me.scrapCnt ? me.scrapCnt : 0);
        if (UserNudgeStages[me.nudgeState ? me.nudgeState : 0] < point) {
          let today = Math.floor(
            (Date.now() + 9 * 60 * 60 * 1000) / (24 * 60 * 60 * 1000)
          );
          if (getGlobal(GlobalKey.NUDGED_LAST_DATE, true) < today) {
            setGlobal(GlobalKey.NUDGED_LAST_DATE, today, true);
            setGlobal(GlobalKey.NUDGE_READY, true);
          }
        }
      }
    }
  };

  const handleClickDelete = () => {
    log(LogLevel.UI_ACTION, "Menu Delete clicked");
    setShowDeletePopover(true);
    // setState({ showDeletePopover: true });
    backKeyControl.pushListener(() => {
      setShowDeletePopover(false);
      return true;
    });
    bottomSheet.close();
  };

  const onToggleNice = async () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onToggleNice", content);
    if (content.noNice) {
      await fetchAPI(
        AdminAPI.TOGGLE_NO_NICE,
        "",
        { id: content.id },
        null,
        getGlobal(GlobalKey.TOKEN)
      );
    } else {
      confirmPopup.show({
        title: (
          <div className="common-container common-color-highlight">
            추천을 금지하시겠습니까?
          </div>
        ),
        iconImage: null,
        doneText: "금지",
        cancelText: "취소",
        onDone: async () => {
          await fetchAPI(
            AdminAPI.TOGGLE_NO_NICE,
            "",
            { id: content.id },
            null,
            getGlobal(GlobalKey.TOKEN)
          );
          dispatch(getContent(content.id));
        },
      });
    }
  };

  const handleClickAdminDisableNice = () => {
    log(LogLevel.UI_ACTION, "Menu Modify clicked");
    onToggleNice();
  };

  const onMoveCategory = () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onMoveCategory", content);
    let target = BoardType.CONSULT_QNA;
    if (content.boardsCategoriesId == BoardType.CONSULT_QNA) {
      target = BoardType.QNA;
    }
    confirmPopup.show({
      title: (
        <div className="common-container common-color-highlight">
          게시물을 {BoardType[target]}으로 이동하시겠습니까?
        </div>
      ),
      iconImage: null,
      doneText: "이동",
      cancelText: "취소",
      onDone: async () => {
        await fetchAPI(
          AdminAPI.MOVE,
          "",
          null,
          { id: content.id, boardsCategoriesId: target },
          getGlobal(GlobalKey.TOKEN)
        );
        dispatch(getContent(content.id));
        dispatch(refreshBoards());
        history.goBack();
      },
    });
    return;
  };

  const onAdminDigestSuggestion = () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onAdminDigestSuggestion", content);
    confirmPopup.show({
      title: (
        <div className="common-container common-color-highlight">
          게시물을 다이제스트로 추천하시겠습니까?
        </div>
      ),
      iconImage: null,
      doneText: "추천",
      cancelText: "취소",
      onDone: async () => {
        let newBoard: BoardContent = {
          id: content.id,
          digestCandidate: 1,
          managedAt: 1,
        };
        let result = await fetchAPI(
          API.BOARD_UPDATE,
          "",
          null,
          newBoard,
          getGlobal(GlobalKey.TOKEN)
        );
        history.goBack();
      },
    });
    return;
  };

  const onAdminQuiz = () => {
    log(LogLevel.UI_ACTION, "ViewerBody:onAdminDigestSuggestion", content);
    confirmPopup.show({
      title: (
        <div className="common-container common-color-highlight">
          게시물의 퀴즈를 만드시겠습니까?
        </div>
      ),
      iconImage: null,
      doneText: "추천",
      cancelText: "취소",
      onDone: async () => {
        let result = await fetchAPI(
          AdminAPI.QUIZ,
          "",
          { contentId: content.id, streamless: true },
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        toastPopup.show("퀴즈가 생성되었습니다.");
      },
    });
    return;
  };

  const handleClickAdminMove = () => {
    log(LogLevel.UI_ACTION, "Menu Modify clicked");
    onMoveCategory();
    bottomSheet.close();
  };

  const handleClickAdminDigest = () => {
    log(LogLevel.UI_ACTION, "Menu Digest clicked");
    onAdminDigestSuggestion();
    bottomSheet.close();
  };

  const handleClickAdminQuiz = () => {
    log(LogLevel.UI_ACTION, "Menu Digest clicked");
    onAdminQuiz();
    bottomSheet.close();
  };

  return (
    <Flex
      as="header"
      alignItems="center"
      justifyContent="space-between"
      customStyle={{
        height: "48px",
        padding: "0 10px",
      }}
    >
      <IonActionSheet
        mode="ios"
        children
        header="정말로 삭제하시겠습니까?"
        isOpen={showDeletePopover}
        onDidDismiss={() => {
          setShowDeletePopover(false);
          backKeyControl.popListener();
        }}
        buttons={[
          {
            text: "삭제하겠습니다.",
            cssClass: "common-menu-red",
            handler: () => {
              log(LogLevel.UI_ACTION, "Delete Menu Delete clicked");
              onDelete();
            },
          },
          {
            text: "취소",
            role: "cancel",
            cssClass: "common-menu-normal",
            handler: () => {
              log(LogLevel.UI_ACTION, "Delete Menu Cancel clicked");
            },
          },
        ]}
        backdropDismiss={true}
      />
      <div>
        <Button
          color="Secondary"
          size="Medium"
          variant="Ghost"
          type="Icon"
          icon="CaretLeft"
          onClick={(e) => {
            if (onBack) onBack(e);
            else history.push("/main/boards");
          }}
        />
      </div>
      <Flex alignItems="center" gap="8px">
        {content &&
          content.boardsCategoriesAttribute &&
          content.boardsCategoriesAttribute.includes(
            BoardAttribute.CAN_SCRAP
          ) && (
            <ToggleButton
              color="Gray"
              size="Medium"
              variant="Ghost"
              type="Icon"
              icon="Bookmark Filled"
              active={scrapped}
              onClick={async () => {
                try {
                  onScrap();
                } catch (e) {
                  toastPopup.show(
                    "스크랩 중에 에러가 발생했습니다. 잠시후 다시 시도해주세요."
                  );
                }
              }}
            />
          )}
        <Button
          color="Secondary"
          size="Small"
          variant="Ghost"
          type="Icon"
          icon="Share01"
          onClick={() => {
            bottomSheet.show({
              title: {
                value: "공유하기",
                align: "center",
              },
              body: (
                <ShareList
                  onKakao={boardShare.handleClickKakao}
                  onSMS={boardShare.handleClickSMS}
                  onLink={boardShare.handleClickLink}
                />
              ),
            });
          }}
        />
        <Button
          color="Secondary"
          size="Medium"
          variant="Ghost"
          type="Icon"
          icon="More"
          onClick={() => {
            bottomSheet.show({
              body: (
                <Flex direction="column">
                  {me.id != content.usersId && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={() => {
                        bottomSheet.show({
                          title: {
                            value: "신고 사유 선택",
                            align: "left",
                          },
                          body: (
                            <Flex
                              direction="column"
                              gap="24px"
                              customStyle={{ marginTop: "24px" }}
                            >
                              <Text
                                textType="Body1"
                                color={COLOR_SYSTEM.get("Gray")[700]}
                                onClick={handleClickReport(
                                  QuestionReports.Delete
                                )}
                              >
                                글삭튀(답변이 달린 후 내용 삭제)
                              </Text>
                              <Text
                                textType="Body1"
                                color={COLOR_SYSTEM.get("Gray")[700]}
                                onClick={handleClickReport(
                                  QuestionReports.Irrelevant
                                )}
                              >
                                게시판 주제와 무관
                              </Text>
                              <Text
                                textType="Body1"
                                color={COLOR_SYSTEM.get("Gray")[700]}
                                onClick={handleClickReport(
                                  QuestionReports.HateSpeech
                                )}
                              >
                                폭언, 비속어, 혐오 발언
                              </Text>
                              <Text
                                textType="Body1"
                                color={COLOR_SYSTEM.get("Gray")[700]}
                                onClick={handleClickReport(
                                  QuestionReports.Spam
                                )}
                              >
                                홍보, 스팸, 광고
                              </Text>
                            </Flex>
                          ),
                        });
                      }}
                    >
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        신고하기
                      </Text>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                  {me.id == content.usersId && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={onModify}
                    >
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        수정하기
                      </Text>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                  {me.id == content.usersId &&
                    (!content.replies || content.replies.length == 0) &&
                    content.replyCnt == 0 && (
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        customStyle={{ height: "48px" }}
                        onClick={handleClickDelete}
                      >
                        <Text
                          textType="Body1"
                          color={COLOR_SYSTEM.get("Gray")[700]}
                        >
                          삭제하기
                        </Text>
                        <Icon
                          name="CaretRight"
                          width={24}
                          height={24}
                          color={COLOR_SYSTEM.get("Gray")[200]}
                        />
                      </Flex>
                    )}
                  {me.level >= UserLevel.MANAGER && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={onModify}
                    >
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        수정하기(관리자)
                      </Text>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                  {me.level >= UserLevel.MANAGER && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={handleClickAdminDisableNice}
                    >
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        추천금지(관리자)
                      </Text>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                  {me.level >= UserLevel.MANAGER && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={handleClickAdminMove}
                    >
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        게시판 이동(관리자)
                      </Text>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                  {me.level >= UserLevel.MANAGER && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={handleClickAdminDigest}
                    >
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        다이제스트추천(관리자)
                      </Text>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                  {me.level >= UserLevel.MANAGER && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={handleClickAdminQuiz}
                    >
                      <Text
                        textType="Body1"
                        color={COLOR_SYSTEM.get("Gray")[700]}
                      >
                        퀴즈만들기(관리자)
                      </Text>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                  {me.level >= UserLevel.MANAGER && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      customStyle={{ height: "48px" }}
                      onClick={handleClickDelete}
                    >
                      <Flex alignItems="center" gap="12px">
                        <Icon
                          name="Trash"
                          width={24}
                          height={24}
                          color={COLOR_SYSTEM.get("Red")[300]}
                        />
                        <Text
                          textType="Body1"
                          color={COLOR_SYSTEM.get("Red")[400]}
                        >
                          삭제하기(관리자)
                        </Text>
                      </Flex>
                      <Icon
                        name="CaretRight"
                        width={24}
                        height={24}
                        color={COLOR_SYSTEM.get("Gray")[200]}
                      />
                    </Flex>
                  )}
                </Flex>
              ),
            });
          }}
        />
      </Flex>
    </Flex>
  );
};

export default withRouter(BoardViewTopAppBar);
