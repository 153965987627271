export function getDistanceString(distancem){
    if(!distancem && distancem !== 0)
        return "알수없음";
    if(distancem < 0)
        return "알수없음";
    if(distancem >= 10000)
        return Math.floor(distancem/1000) + "km";
    else if(distancem >= 1000)
        return Math.floor(distancem/100)/10 + "km";
    else
        return Math.floor(distancem) + "m";
}

export function getAbstractDistanceString(distancem){
    if(!distancem && distancem !== 0)
        return "알수없음";
    if(distancem < 0)
        return "알수없음";
    if(distancem < 5000)
        return "5km내";
    else if(distancem <= 10000)
        return "10km내";
    else if(distancem <= 20000)
        return "20km내";
    else if(distancem <= 30000)
        return "30km내";
    else if(distancem <= 40000)
        return "40km내";
    else if(distancem <= 50000)
        return "50km내";
    else
        return "50km이상";
}
