import { action, createAction, createAsyncAction } from 'typesafe-actions';
import {GamificationActionTypes} from './types'
import { GamificationPeriodicReport } from '../../models/Model.Gamification';

export const getReport = createAction(GamificationActionTypes.GAMIFICATION_REPORT_FETCH, resolve =>
  () => resolve()
);

export const clearReport = createAction(GamificationActionTypes.GAMIFICATION_REPORT_CLEAR, resolve =>
  () => resolve()
);

export const fetchReport = createAsyncAction(
  GamificationActionTypes.GAMIFICATION_REPORT_REQUEST,
  GamificationActionTypes.GAMIFICATION_REPORT_SUCCESS,
  GamificationActionTypes.GAMIFICATION_REPORT_ERROR,
)<void, GamificationPeriodicReport, any>(); // <string, EventList, Error>();
