import React, { useEffect, useRef, useState } from "react";
import Button from "../../atoms/Button";
import TabBar from "../../molecules/TabBar";
import { COLOR_SYSTEM } from "../../design/design-system";
import SegmentedControl from "../../molecules/SegmentedControl/SegmentedControl";
import { REGIONS } from "../../SelectRegionPopup";
import Text from "../../atoms/Text";
import Icon from "../../atoms/Icon";
import { JobOfferType } from "../../../models/Model.JobPost";
import { JobListState, JobListType } from "../../../store/jobpost/types";
import { CompanyType, UserInfo, UserType } from "../../../models/Model.User";
import TickMarkSlider from "../../molecules/TickMarkSlider";
import {
  BottomSheetMain,
  DistanceContainer,
  DistanceControlBox,
  DistanceTooltip,
  DistrictContainer,
  Footer,
  Header,
  Item,
  ItemList,
  Left,
  MainNav,
  NoAddress,
  Right,
  WorkingType,
  WorkingTypeList,
  WorkingTypeTitle,
} from "./style";
import { RouteComponentProps, withRouter } from "react-router";

export type Tab = "companyType" | "location" | "type" | null;
type LocationType = "District" | "Distance";
type SelectMode = "region" | "distance";

export interface LocationBottomSheetModalProps {
  me: UserInfo;
  type?: LocationType;
  currentTab: Tab;
  jobPosts: JobListState;
  active?: boolean;
  postType: JobListType;
  setCurrentTab: React.Dispatch<React.SetStateAction<Tab>>;
  onClose?: (params?: any) => any;
  onDone: (searchOption?: any) => void;
}

const LocationBottomSheetModal: React.FC<RouteComponentProps & LocationBottomSheetModalProps> = ({
  history,
  me,
  currentTab,
  jobPosts,
  active,
  postType,
  setCurrentTab,
  onClose,
  onDone,
}) => {
  const isUpdate = useRef(false);
  const distances = [5, 10, 15, 20, 25, 30, 0];

  const [selectedCompanyType, setSelectedCompanyType] = useState<CompanyType>(
    jobPosts.options.companyType || CompanyType.ALL
  );
  const [selectedRegion, setSelectedRegion] = useState("전체");
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectMode, setSelectMode] = useState<SelectMode>("region");
  const [selectedDistance, setSelectedDistance] = useState(distances.length - 1);
  const [workingType, setWorkingType] = useState<JobOfferType>(JobOfferType.ALL);

  const handleCompanyTypeClick = (value: CompanyType) => () => {
    setSelectedCompanyType(value);
  };

  const handleWorkingTypeClick = (value: JobOfferType) => () => {
    setWorkingType(value);
  };

  useEffect(() => {
    if (isUpdate.current) {
      setSelectedDistrict(REGIONS[selectedRegion]["region"][0]["name"]);
      setSelectedLocation(REGIONS[selectedRegion]["region"][0]["search"]);
      isUpdate.current = false;
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (jobPosts) {
      const [name, region] = jobPosts.options.address.split(" ");

      if (name || region) {
        setSelectedRegion(Object.keys(REGIONS)[Object.values(REGIONS).findIndex((item) => item.name === name)]);
        setSelectedDistrict(region || "전체");
        setSelectedLocation(jobPosts.options.address);
      } else {
        setSelectedRegion("전체");
        setSelectedDistrict("전체");
        setSelectedLocation("");
      }

      setWorkingType(jobPosts.options.type || JobOfferType.ALL);
      const index = distances.indexOf(jobPosts.options.distance / 1000);

      setSelectedDistance(index !== -1 ? index : distances.length - 1);

      setSelectMode(jobPosts.options.distance ? "distance" : "region");
    }
  }, [jobPosts]);

  return (
    <>
      <Header>
        <Button color="Tertiary" icon="X" onClick={onClose} size="Medium" type="Icon" variant="Ghost" />
      </Header>
      <TabBar
        size={"Medium"}
        items={[
          {
            active: currentTab === "location",
            badge: false,
            onClick: () => {
              setCurrentTab("location");
            },
            text: "희망 근무 위치",
          },
          postType !== JobListType.PHARMACY && {
            active: currentTab === "companyType",
            badge: false,
            onClick: () => {
              setCurrentTab("companyType");
            },
            text: "희망 업종",
          },
          {
            active: currentTab === "type",
            badge: false,
            onClick: () => {
              setCurrentTab("type");
            },
            text: "희망 근무 유형",
          },
        ]}
        scrollable
        type="Underlined"
      />
      {currentTab === "companyType" && (
        <main style={{ height: "calc(100% - 180px)", overflowY: "scroll" }}>
          <WorkingTypeList>
            <WorkingType
              onClick={handleCompanyTypeClick(CompanyType.ALL)}
              selected={selectedCompanyType === CompanyType.ALL}
            >
              <WorkingTypeTitle>
                <Text
                  textType="InputLarge"
                  color={
                    selectedCompanyType === CompanyType.ALL
                      ? COLOR_SYSTEM.get("Skyblue")[500]
                      : COLOR_SYSTEM.get("Gray")[800]
                  }
                >
                  모든 업종
                </Text>
              </WorkingTypeTitle>
              <Icon
                width={24}
                height={24}
                name={"Check"}
                fill={
                  selectedCompanyType === CompanyType.ALL
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
              />
            </WorkingType>
            {postType === JobListType.SEARCHED && (
              <WorkingType
                onClick={handleCompanyTypeClick(CompanyType.PHARMACY)}
                selected={selectedCompanyType === CompanyType.PHARMACY}
              >
                <WorkingTypeTitle>
                  <Text
                    textType="InputLarge"
                    color={
                      selectedCompanyType === CompanyType.PHARMACY
                        ? COLOR_SYSTEM.get("Skyblue")[500]
                        : COLOR_SYSTEM.get("Gray")[800]
                    }
                  >
                    약국
                  </Text>
                </WorkingTypeTitle>
                <Icon
                  width={24}
                  height={24}
                  name={"Check"}
                  fill={
                    selectedCompanyType === CompanyType.PHARMACY
                      ? COLOR_SYSTEM.get("Skyblue")[400]
                      : COLOR_SYSTEM.get("Gray")[100]
                  }
                />
              </WorkingType>
            )}
            <WorkingType
              onClick={handleCompanyTypeClick(CompanyType.HOSPITAL)}
              selected={selectedCompanyType === CompanyType.HOSPITAL}
            >
              <WorkingTypeTitle>
                <Text
                  textType="InputLarge"
                  color={
                    selectedCompanyType === CompanyType.HOSPITAL
                      ? COLOR_SYSTEM.get("Skyblue")[500]
                      : COLOR_SYSTEM.get("Gray")[800]
                  }
                >
                  병·의원
                </Text>
              </WorkingTypeTitle>
              <Icon
                width={24}
                height={24}
                name={"Check"}
                fill={
                  selectedCompanyType === CompanyType.HOSPITAL
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
              />
            </WorkingType>
            <WorkingType
              onClick={handleCompanyTypeClick(CompanyType.PHARMACEUTICAL_COMPANY)}
              selected={selectedCompanyType === CompanyType.PHARMACEUTICAL_COMPANY}
            >
              <WorkingTypeTitle>
                <Text
                  textType="InputLarge"
                  color={
                    selectedCompanyType === CompanyType.PHARMACEUTICAL_COMPANY
                      ? COLOR_SYSTEM.get("Skyblue")[500]
                      : COLOR_SYSTEM.get("Gray")[800]
                  }
                >
                  제약바이오
                </Text>
              </WorkingTypeTitle>
              <Icon
                width={24}
                height={24}
                name={"Check"}
                fill={
                  selectedCompanyType === CompanyType.PHARMACEUTICAL_COMPANY
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
              />
            </WorkingType>
            <WorkingType
              onClick={handleCompanyTypeClick(CompanyType.DISTRIBUTOR)}
              selected={selectedCompanyType === CompanyType.DISTRIBUTOR}
            >
              <WorkingTypeTitle>
                <Text
                  textType="InputLarge"
                  color={
                    selectedCompanyType === CompanyType.DISTRIBUTOR
                      ? COLOR_SYSTEM.get("Skyblue")[500]
                      : COLOR_SYSTEM.get("Gray")[800]
                  }
                >
                  의약품 도매
                </Text>
              </WorkingTypeTitle>
              <Icon
                width={24}
                height={24}
                name={"Check"}
                fill={
                  selectedCompanyType === CompanyType.DISTRIBUTOR
                    ? COLOR_SYSTEM.get("Skyblue")[400]
                    : COLOR_SYSTEM.get("Gray")[100]
                }
              />
            </WorkingType>
          </WorkingTypeList>
        </main>
      )}

      {currentTab === "location" && (
        <BottomSheetMain>
          <MainNav>
            <SegmentedControl
              color="Gray"
              items={[
                {
                  active: selectMode === "region",
                  text: "지역으로 선택",
                  type: "Text",
                  onClick: () => {
                    setSelectMode("region");
                  },
                },
                {
                  active: selectMode === "distance",
                  text: "거리로 선택",
                  type: "Text",
                  onClick: () => {
                    setSelectMode("distance");
                  },
                },
              ]}
              size="Medium"
              variant="Tinted"
            />
          </MainNav>
          {selectMode === "region" ? (
            <DistrictContainer>
              <Left>
                <ItemList>
                  {Object.keys(REGIONS).map((region) => (
                    <Item
                      key={region}
                      selected={region === selectedRegion}
                      onClick={(e) => {
                        isUpdate.current = true;
                        setSelectedRegion(region);
                      }}
                    >
                      <Text
                        textType="InputMedium"
                        color={
                          region === selectedRegion ? COLOR_SYSTEM.get("Skyblue")[400] : COLOR_SYSTEM.get("Gray")[500]
                        }
                      >
                        {region}
                      </Text>
                    </Item>
                  ))}
                </ItemList>
              </Left>
              <Right>
                <ItemList>
                  {selectedRegion &&
                    REGIONS[selectedRegion].region.map((district) => (
                      <Item
                        key={district.name}
                        selected={district.name === selectedDistrict}
                        onClick={() => {
                          setSelectedDistrict(district.name);
                          setSelectedLocation(district.search);
                        }}
                      >
                        <Text
                          textType="InputMedium"
                          color={
                            district.name === selectedDistrict
                              ? COLOR_SYSTEM.get("Skyblue")[400]
                              : COLOR_SYSTEM.get("Gray")[500]
                          }
                        >
                          {district.name}
                        </Text>
                      </Item>
                    ))}
                </ItemList>
              </Right>
            </DistrictContainer>
          ) : (
            <DistanceContainer address={me.homeAddress}>
              {/* me.homeAddress */}
              {me.homeAddress ? (
                <DistanceControlBox>
                  <DistanceTooltip>
                    {distances[selectedDistance] ? (
                      <Text textType="H2" color={COLOR_SYSTEM.get("Gray")[900]}>
                        집 주변{" "}
                        <Text textType="H2" color={COLOR_SYSTEM.get("Skyblue")[400]}>
                          {distances[selectedDistance]}km
                        </Text>{" "}
                        이내
                      </Text>
                    ) : (
                      <Text textType="H2" color={COLOR_SYSTEM.get("Gray")[900]}>
                        모든 지역
                      </Text>
                    )}
                  </DistanceTooltip>
                  <TickMarkSlider
                    width="100%"
                    rangeLabels={["5", "", "", "", "", "", "전체"]}
                    selectedIndex={selectedDistance}
                    onChange={(e) => {
                      setSelectedDistance(Number(e.target.value) - 1);
                    }}
                  />
                </DistanceControlBox>
              ) : (
                <NoAddress>
                  <Text element="h2" textType="H2" color={COLOR_SYSTEM.get("Gray")[400]}>
                    주소를 입력해주세요
                  </Text>
                  <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[400]}>
                    거리로 희망 근무 위치를 선택하려면
                    <br />
                    구인구직 설정에서 주소를 입력해주세요
                  </Text>
                  <Button
                    color="Secondary"
                    onClick={() => {
                      history.push("/jobpost/setting");
                    }}
                    size="Large"
                    type="Text"
                    variant="Outlined"
                  >
                    주소 입력하러 가기
                  </Button>
                </NoAddress>
              )}
            </DistanceContainer>
          )}
        </BottomSheetMain>
      )}

      {currentTab === "type" && (
        <main style={{ height: "calc(100% - 180px)", overflowY: "scroll" }}>
          <WorkingTypeList>
            {postType === JobListType.BIZ || (postType === JobListType.SEARCHED && selectedCompanyType > 0) ? (
              <>
                <WorkingType
                  onClick={handleWorkingTypeClick(JobOfferType.ALL)}
                  selected={workingType === JobOfferType.ALL}
                >
                  <WorkingTypeTitle>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.ALL
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[800]
                      }
                    >
                      모든 근무 유형
                    </Text>
                  </WorkingTypeTitle>
                  <Icon
                    width={24}
                    height={24}
                    name={"Check"}
                    fill={
                      workingType === JobOfferType.ALL
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </WorkingType>
                <WorkingType
                  onClick={handleWorkingTypeClick(JobOfferType.EMPLOYED)}
                  selected={workingType === JobOfferType.EMPLOYED}
                >
                  <WorkingTypeTitle>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.EMPLOYED
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[800]
                      }
                    >
                      정규직
                    </Text>
                  </WorkingTypeTitle>
                  <Icon
                    width={24}
                    height={24}
                    name={"Check"}
                    fill={
                      workingType === JobOfferType.EMPLOYED
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </WorkingType>
                <WorkingType
                  onClick={handleWorkingTypeClick(JobOfferType.TEMPORARY)}
                  selected={workingType === JobOfferType.TEMPORARY}
                >
                  <WorkingTypeTitle>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.TEMPORARY
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[800]
                      }
                    >
                      계약직
                    </Text>
                  </WorkingTypeTitle>
                  <Icon
                    width={24}
                    height={24}
                    name={"Check"}
                    fill={
                      workingType === JobOfferType.TEMPORARY
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </WorkingType>
              </>
            ) : (
              <>
                <WorkingType
                  onClick={handleWorkingTypeClick(JobOfferType.ALL)}
                  // onTouchStart={handleWorkingTypeClick(JobOfferType.ALL)}
                  selected={workingType === JobOfferType.ALL}
                >
                  <WorkingTypeTitle>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.ALL
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[800]
                      }
                    >
                      모든 근무 유형
                    </Text>
                  </WorkingTypeTitle>
                  <Icon
                    width={24}
                    height={24}
                    name={"Check"}
                    fill={
                      workingType === JobOfferType.ALL
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </WorkingType>
                <WorkingType
                  onClick={handleWorkingTypeClick(JobOfferType.FULL_TIME)}
                  // onTouchStart={handleWorkingTypeClick(JobOfferType.FULL_TIME)}
                  selected={workingType === JobOfferType.FULL_TIME}
                >
                  <WorkingTypeTitle>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.FULL_TIME
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[800]
                      }
                    >
                      풀타임
                    </Text>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.FULL_TIME
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[400]
                      }
                    >
                      일 8시간, 주 5일 이상
                    </Text>
                  </WorkingTypeTitle>
                  <Icon
                    width={24}
                    height={24}
                    name={"Check"}
                    fill={
                      workingType === JobOfferType.FULL_TIME
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </WorkingType>
                <WorkingType
                  onClick={handleWorkingTypeClick(JobOfferType.PART_TIME)}
                  // onTouchStart={handleWorkingTypeClick(JobOfferType.PART_TIME)}
                  selected={workingType === JobOfferType.PART_TIME}
                >
                  <WorkingTypeTitle>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.PART_TIME
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[800]
                      }
                    >
                      파트타임
                    </Text>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.PART_TIME
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[400]
                      }
                    >
                      일 8시간, 주 5일 미만
                    </Text>
                  </WorkingTypeTitle>
                  <Icon
                    width={24}
                    height={24}
                    name={"Check"}
                    fill={
                      workingType === JobOfferType.PART_TIME
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </WorkingType>
                <WorkingType
                  onClick={handleWorkingTypeClick(JobOfferType.TEMPORARY)}
                  // onTouchStart={handleWorkingTypeClick(JobOfferType.TEMPORARY)}
                  selected={workingType === JobOfferType.TEMPORARY}
                >
                  <WorkingTypeTitle>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.TEMPORARY
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[800]
                      }
                    >
                      단기알바
                    </Text>
                    <Text
                      textType="InputLarge"
                      color={
                        workingType === JobOfferType.TEMPORARY
                          ? COLOR_SYSTEM.get("Skyblue")[500]
                          : COLOR_SYSTEM.get("Gray")[400]
                      }
                    >
                      3개월 이내 단기간
                    </Text>
                  </WorkingTypeTitle>
                  <Icon
                    width={24}
                    height={24}
                    name={"Check"}
                    fill={
                      workingType === JobOfferType.TEMPORARY
                        ? COLOR_SYSTEM.get("Skyblue")[400]
                        : COLOR_SYSTEM.get("Gray")[100]
                    }
                  />
                </WorkingType>
              </>
            )}
          </WorkingTypeList>
        </main>
      )}

      <Footer>
        <Button
          color="Tertiary"
          size="Large"
          type="Text"
          variant="Ghost"
          onClick={() => {
            isUpdate.current = true;
            setSelectedRegion("전체");
            setSelectedDistance(distances.length - 1);
            setWorkingType(JobOfferType.ALL);
            setSelectedCompanyType(CompanyType.ALL);
            // if (currentTab === "location") {
            //   locationLeftEl.current.scrollTop = 0;
            //   locationRightEl.current.scrollTop = 0;
            // } else {
            //   console.log(currentTab);
            //   typeEl.current.scrollTop = 0;
            // }
          }}
        >
          초기화
        </Button>
        <Button
          color="Primary"
          size="Large"
          type="Text"
          variant="Contained"
          // disabled={!selectedRegion || !selectedDistrict}
          onClick={() => {
            console.log(selectedRegion, selectedDistrict, selectedLocation, selectedCompanyType);

            let type = "";
            let companyType = selectedCompanyType === CompanyType.ALL ? undefined : selectedCompanyType;
            let ownerType = 0;

            if (postType === JobListType.SEARCHED || postType === JobListType.BIZ) {
              if (selectedCompanyType === CompanyType.PHARMACY) {
                ownerType = UserType.YMYD;
              } else if (selectedCompanyType === CompanyType.ALL) {
                ownerType = UserType.ALL;
              } else {
                ownerType = UserType.BIZ_JOBPOST;
              }
            }

            if (postType === JobListType.SEARCHED || postType === JobListType.BIZ) {
              type = selectedCompanyType === CompanyType.ALL ? JobOfferType.ALL : workingType || "";
            } else {
              type = workingType || "";
            }

            console.log(jobPosts.options.companyType, selectedCompanyType);

            if (
              (jobPosts.options.companyType > 0 && selectedCompanyType === 0) ||
              (jobPosts.options.companyType === 0 && selectedCompanyType > 0)
            ) {
              type = "";
            }

            const options = {
              ...jobPosts.options,
              type,
              address: selectMode === "region" ? selectedLocation || "" : "",
              distance: selectMode === "distance" ? distances[selectedDistance] * 1000 : 0,
              companyType,
              liked: false,
              ownerType,
            };
            onDone(options);
            onClose();
          }}
        >
          적용
        </Button>
      </Footer>
    </>
  );
};

export default withRouter(LocationBottomSheetModal);
