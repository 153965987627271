import React, { Component } from "react";
import "./../../Admin.scss";
import "./../../../Common.css";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonInput,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonLabel,
} from "@ionic/react";
import { fetchAPI } from "../../../utils/APIUtil";
import { timeout } from "q";
import smileIcon from "./../assets/icon/smile.svg";
import { log, LogLevel } from "../../../utils/LogUtil";
import {
  UserLevel,
  UserLevelName,
  RegisterRoute,
  REGISTER_ROUTE_NAME,
  AllInOnePartnerLevel,
  CompanyTypeName,
  PARTNERS_TEMPLATE,
} from "../../../models/Model.User";
import { getGlobal, GlobalKey, setGlobal } from "../../../utils/GlobalUtil";
import { triggerAsyncId } from "async_hooks";
import ProfileImage from "../../../components/ProfileImage";
import IconButton from "../../../component/atom/IconButton";
import { callPhone, sendSMSMessage } from "../../../utils/UrlSchemeUtil";
import StringUtil from "../../../utils/StringUtil";
import { RootState } from "../../../store";
import { UIPopupType } from "../../../store/ui/types";
import { connect } from "react-redux";
import ToggleSwitch from "../../../component/atom/ToggleSwitch";
import { BizUserInfo } from "../../../models/Mode.Biz";
import { COLOR_SYSTEM } from "../../../components/design/design-system";
import { Flex } from "../../../components/atoms/Layout";
import { BoardContentSummary } from "../../../models/Model.Board";
import styled from "styled-components";
import Text from "../../../components/atoms/Text";
import ReactHtmlParser from "react-html-parser";
import { type } from "../../../component/atom/Text/index.stories";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import DownloadUtil from "../../../utils/DownloadUtil";

const AdminAPI = {
  SUMMARY_LIST: {
    method: "GET",
    path: "/admin/summary/list",
    contentType: "application/json",
  },
};

const USER_ROW_CNT = 40;

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (summary: BoardContentSummary) => void;
    onClose?: () => void;
    summary: BoardContentSummary;
  };

type State = {
  searchKeyword: string;
  searchContentId: number;
  summaries: BoardContentSummary[];
  finished: boolean;
};

class SummarySelect extends Component<Props, State> {
  loading = false;

  state = {
    searchKeyword: "",
    searchContentId: 0,
    summaries: [],
    finished: false,
  };

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.fetchData();
  }

  onKeyPress = (e) => {
    log(LogLevel.UI_ACTION, "SummarySelect:onKeyPress", e.keyCode, e.shiftKey);
    if (e.keyCode == 13) {
      this.fetchData();
      e.preventDefault();
    }
  };

  onSearchKeywordChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let keywords = e.detail.value;
    this.setState({ searchKeyword: keywords });
  };
  onSearchContentIdChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let num = e.detail.value;
    this.setState({ searchContentId: parseInt(num) });
  };

  refresh = () => {
    this.fetchData();
  };

  download = () => {
    log(
      LogLevel.UI_ACTION,
      "Admin:SummarySelect:download",
      this.state.summaries
    );
    let text = "ID\tContentID\tVersion\tJson\n";
    this.state.summaries.map((item: BoardContentSummary) => {
      text += `${item.id}\t${item.contentId}\t${item.version}\t${JSON.stringify(
        item
      )}\n`;
    });
    DownloadUtil.downloadCsv(
      text,
      `Summary-${new Date()
        .toISOString()
        .substring(0, 10)
        .replace(/-/gi, "")}.csv`
    );
  };

  search = (option: State) => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SummarySelect:search", option);

    this.setState(option);
    setTimeout(this.refresh, 100);
  };

  fetchData = async () => {
    log(LogLevel.UI_DATA_LOAD, "Admin:SummarySelect:fetchData");
    this.loading = true;

    let parameter: any = {
      count: USER_ROW_CNT,
      start: 0,
    };

    if (this.state.searchContentId) {
      parameter.contentId = this.state.searchContentId;
    } else if (this.state.searchKeyword) {
      parameter.keyword = this.state.searchKeyword;
      parameter.availableOnly = true;
    } else {
      parameter.availableOnly = true;
    }

    let finished = false;

    fetchAPI(
      AdminAPI.SUMMARY_LIST,
      "",
      parameter,
      null,
      getGlobal(GlobalKey.TOKEN)
    ).then((result) => {
      if (result && !result.error) {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:SummarySelect:fetchData result",
          result
        );
        if (result.data && result.data.length < USER_ROW_CNT) finished = true;

        this.setState({ summaries: result.data, finished });
        this.loading = false;
      } else {
        log(
          LogLevel.UI_DATA_LOAD,
          "Admin:SummarySelect:fetchData result",
          result
        );
        finished = true;
        this.setState({ summaries: [], finished });
      }
    });
  };

  fetchDataMore = async () => {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:SummarySelect:fetchDataMore",
      this.state.finished
    );
    this.loading = true;

    let parameter: any = {
      count: USER_ROW_CNT,
      start: this.state.summaries.length,
    };

    if (this.state.searchContentId) {
      parameter.contentId = this.state.searchContentId;
    } else if (this.state.searchKeyword) {
      parameter.keyword = this.state.searchKeyword;
      parameter.availableOnly = true;
    }
    let finished = false;

    if (!this.state.finished) {
      fetchAPI(
        AdminAPI.SUMMARY_LIST,
        "",
        parameter,
        null,
        getGlobal(GlobalKey.TOKEN)
      ).then((result) => {
        if (result && !result.error) {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:SummarySelect:fetchData result",
            result
          );

          if (result.data && result.data.length < USER_ROW_CNT) finished = true;

          this.setState({
            summaries: [...this.state.summaries, ...result.data],
          });
          this.loading = false;
        } else {
          log(
            LogLevel.UI_DATA_LOAD,
            "Admin:SummarySelect:fetchData result",
            result
          );
          finished = true;
          this.loading = false;
        }
      });
    }
  };

  clearSearch = (key: string) => () => {
    let newState = {};
    if (key == "searchKeyword") newState = { searchKeyword: "" };
    if (key == "searchContentId") newState = { searchContentId: 0 };
    this.setState(newState);
  };

  onSelect = (summary: BoardContentSummary) => {
    log(LogLevel.UI_EVENT, "Admin:SummarySelect:onSelect", summary);
    this.props.onDone(summary);
  };

  endAtStyle = (endAt: string) => {
    const endDate = new Date(endAt);
    const currentDate = new Date();

    const diffInTime = endDate.getTime() - currentDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    log(
      LogLevel.ALL,
      "AllInOneOrderList:diffInDays",
      diffInDays,
      endAt,
      currentDate,
      endDate,
      diffInTime
    );

    if (diffInDays < 0) {
      return {
        backgroundColor: COLOR_SYSTEM.get("Red")[50],
      };
    }
    return {};
  };

  render() {
    log(
      LogLevel.UI_DATA_LOAD,
      "Admin:SummarySelect:render",
      this.state.summaries
    );
    return (
      <div className="admin-container">
        {this.props.onClose && (
          <IonHeader class="common-header" no-border>
            <IonToolbar color="common-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  class="common-header-toolbar-button"
                  onClick={this.props.onClose}
                >
                  <IonIcon name="arrow-back" mode="ios" />
                </IonButton>
                <IonLabel
                  class="common-header-title"
                  onClick={this.props.onClose}
                >
                  요약 목록
                </IonLabel>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <div className="admin-item-container">
          키워드
          <IonInput
            class="admin-input"
            placeholder="Keyword"
            value={this.state.searchKeyword}
            onIonChange={this.onSearchKeywordChange}
            onKeyDown={this.onKeyPress}
            style={{ marginLeft: "10px" }}
          />
          <IonButton
            fill="clear"
            color="primary"
            onClick={this.clearSearch("seachKeyword")}
          >
            <IonIcon name="close" />
          </IonButton>
        </div>
        <div className="admin-item-container">
          Content
          <IonInput
            class="admin-input"
            placeholder="ContentId"
            value={this.state.searchContentId.toString()}
            onIonChange={this.onSearchContentIdChange}
            onKeyDown={this.onKeyPress}
            type="number"
            style={{ marginLeft: "10px" }}
          />
          <IonButton
            fill="clear"
            color="primary"
            onClick={this.clearSearch("searchContentId")}
          >
            <IonIcon name="close" />
          </IonButton>
        </div>
        <div className="admin-item-container">
          <IonButton color="primary" onClick={this.refresh}>
            불러오기
          </IonButton>
          <IonButton color="primary" onClick={this.download}>
            다운로드
          </IonButton>
        </div>

        <div className="common-container">
          {this.state.summaries.length > 0 && !this.state.finished && (
            <IonButton
              color="primary"
              onClick={this.fetchDataMore}
              style={{ width: "100%" }}
            >
              더 불러오기
            </IonButton>
          )}
          <IonList>
            {this.state.summaries.map(
              (item: BoardContentSummary, index: number) => {
                // log(
                //   LogLevel.UI_DATA_LOAD,
                //   "Admin:SummarySelect:render Items",
                //   item
                // );
                return (
                  <QustionWrapper
                    key={index.toString()}
                    onClick={() => this.onSelect(item)}
                    deleted={item.deletedAt}
                    selected={
                      this.props.summary && item.id == this.props.summary.id
                    }
                  >
                    <Text
                      textType="CaptionBold"
                      color={COLOR_SYSTEM.get("Blue")[400]}
                    >
                      ({item.id} - C{item.contentId} - V{item.version} -{" "}
                      {getDateStringFromToday({
                        date: item.createdAt,
                        showTime: true,
                      })}
                      )
                    </Text>
                    <Text
                      textType="Body1SemiBold"
                      color={COLOR_SYSTEM.get("Gray")[900]}
                      numberOfLines={2}
                    >
                      {item.summary &&
                        item.summary.question &&
                        ReactHtmlParser(
                          item.summary.question
                            .replace(/\n/gi, "<br/>")
                            .replace(/\\\\n/gi, "<br/>")
                            .replace(/\\n/gi, "<br/>"),
                          {}
                        )}
                    </Text>
                    <Text
                      textType="Caption"
                      color={COLOR_SYSTEM.get("Green")[400]}
                      numberOfLines={2}
                    >
                      {item.keyword.join(", ")}
                    </Text>
                  </QustionWrapper>
                );
              }
            )}
          </IonList>
          {this.state.summaries.length > 0 && !this.state.finished && (
            <IonButton
              color="primary"
              onClick={this.fetchDataMore}
              style={{ width: "100%" }}
            >
              더 불러오기
            </IonButton>
          )}
        </div>
      </div>
    );
  }
}

const QustionWrapper = styled.div<{ deleted: boolean; selected: boolean }>`
  padding: 16px;
  background: ${({ deleted }) =>
    COLOR_SYSTEM.get(deleted ? "Red" : "Gray")[10]};
  border-radius: 12px;
  margin-bottom: 8px;
  ${({ selected }) =>
    selected && `border: 2px solid ${COLOR_SYSTEM.get("Blue")[400]};`}
`;

const mapStateToProps = (state: RootState) => ({
  // filePath: state.board.filePath,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SummarySelect);
