import React from 'react';
import { FC } from 'react'
import styled, { StyledComponent } from 'styled-components'
import { OrderInfo, OrderStatus } from '../../../models/Model.Sale'
import { IonSpinner } from '@ionic/react'
import Text from '../../atom/Text';
import SaleOrderItemBody from '../../organism/SaleOrderItemBody';
import TextRectangleButton from '../../cell/TextRectangleButton';
import Separator from '../../atom/Separator';
import StringUtil from '../../../utils/StringUtil';
import UserUtil from '../../../utils/UserUtil';


const Container: StyledComponent<'div', FC> = styled.div`
  width: 100%;
  .sale-order-detail-info-item {
    margin-bottom: 20px;

    .sale-order-detail-info-pay-item-margin{
      margin-bottom: 8px;
    }

    .sale-order-detail-info-delivery-item-margin{
      margin-bottom: 12px;
    }

    .sale-order-detail-info-delivery-title{
      width: 96px;
      min-width: 96px;
    }
  }


  .sale-order-detail-info-item-footer-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .sale-order-detail-info-item-footer-button-margin{
      width: 12px;
      min-width: 12px;
    }

    .sale-order-detail-info-item-footer-2button{
      width: calc((100% - 12px) / 2);
    }

    .sale-order-detail-info-item-footer-3button{
      width: calc((100% - 24px) / 3);
    }
  }
`

interface Props {
  order:OrderInfo,
  onClickButtonCancel?: (order:OrderInfo) => void
  onClickButtonReturn?: (order:OrderInfo) => void
  onClickButtonInquiry?: (order:OrderInfo) => void
  onClickButtonTracking?: (order:OrderInfo) => void
  onClickButtonReceipt?: (order:OrderInfo) => void
}

const SaleOrderDetail: FC<Props> = ({
  order,
  onClickButtonCancel,
  onClickButtonReturn,
  onClickButtonInquiry,
  onClickButtonTracking,
  onClickButtonReceipt,
}) => {
  let footer;
  
  switch (order.status){
    case OrderStatus.PAID : 
    case OrderStatus.ORDERED : 
    case OrderStatus.PREPARING_PRODUCT : 
    case OrderStatus.PREPARING_DELIVER : 
    {
      footer = (
        <div className="sale-order-detail-info-item-footer-container">
          <div className="sale-order-detail-info-item-footer-2button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonCancel) onClickButtonCancel(order)}}>
              주문취소
            </TextRectangleButton>
          </div>
          <div className="sale-order-detail-info-item-footer-button-margin"/>
          <div className="sale-order-detail-info-item-footer-2button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonInquiry) onClickButtonInquiry(order)}}>
              문의하기
            </TextRectangleButton>
          </div>
        </div>
      )
      break;
    }
    case OrderStatus.DELIVERING : 
    case OrderStatus.DELIVERED : 
    {
      footer = (
        <div className="sale-order-detail-info-item-footer-container">
          <div className="sale-order-detail-info-item-footer-3button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonReturn) onClickButtonReturn(order)}}>
              반품신청
            </TextRectangleButton>
          </div>
          <div className="sale-order-detail-info-item-footer-button-margin"/>
          <div className="sale-order-detail-info-item-footer-3button">
            <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonInquiry) onClickButtonInquiry(order)}}>
              문의하기
            </TextRectangleButton>
          </div>
          <div className="sale-order-detail-info-item-footer-button-margin"/>
          <div className="sale-order-detail-info-item-footer-3button">
            <TextRectangleButton level="2" size="L" onClick={() => {if(onClickButtonTracking) onClickButtonTracking(order)}}>
              배송조회
            </TextRectangleButton>
          </div>
        </div>
      )
      break;
    }
    case OrderStatus.RETURNING : 
    case OrderStatus.RETURN : 
    {
      footer = (
        <div className="sale-order-detail-info-item-footer-container">
          <TextRectangleButton level="1" size="L" onClick={() => {if(onClickButtonInquiry) onClickButtonInquiry(order)}}>
            문의하기
          </TextRectangleButton>
        </div>
      )
      break;
    }

  }
  
  return (
    <Container>
      <div className="common-block">
        <div className="sale-order-detail-info-item">
          <Text className="sale-order-detail-item-title" type="Headline" color="Black">
            상품 정보
          </Text>
        </div>
        <div className="sale-order-detail-info-item">
          <SaleOrderItemBody order={order}/>
        </div>
        {footer}
      </div>
      <Separator type="Block"/>
      <div className="common-block">
        <div className="sale-order-detail-info-item">
          <Text className="sale-order-detail-item-title" type="Headline" color="Black">
            결제 정보
          </Text>
        </div>
        <div className="sale-order-detail-info-item">
          <div className="common-container-row common-flex-align-center sale-order-detail-info-pay-item-margin">
            <Text className="common-flex-grow" type="SubHead" color="Gray2">
              총 상품 가격
            </Text>
            <Text type="Body1" color="Gray2">
              {StringUtil.commafy(order.payProduct)}원
            </Text>
          </div>
          <div className="common-container-row common-flex-align-center">
            <Text className="common-flex-grow" type="SubHead" color="Gray2">
              배송비
            </Text>
            <Text type="Body1" color="Gray2">
              {StringUtil.commafy(order.payDelivery)}원
            </Text>
          </div>
        </div>
        <div className="sale-order-detail-info-item">
          <Separator type="Line"/>
        </div>
        <div className="sale-order-detail-info-item">
          <div className="common-container-row common-flex-align-center sale-order-detail-info-pay-item-margin">
            <Text className="common-flex-grow" type="SubHead" color="Gray2">
              반품비
            </Text>
            <Text type="Body1" color="Gray2">
              {order.payRefund?"-" + StringUtil.commafy(order.payRefund):"0"}원
            </Text>
          </div>
          <div className="common-container-row common-flex-align-center">
            <Text className="common-flex-grow" type="SubHead" color="Black">
              총 결제금액
            </Text>
            <Text type="Body1" color="Black">
              {StringUtil.commafy(order.payProduct + order.payDelivery - order.payRefund)}원
            </Text>
          </div>
        </div>
        <TextRectangleButton level="1" size="L" onClick={() => onClickButtonReceipt(order)}>
          영수증 보기
        </TextRectangleButton>
      </div>
      <Separator type="Block"/>
      <div className="common-block">
        <div className="sale-order-detail-info-item">
          <Text className="sale-order-detail-item-title" type="Headline" color="Black">
            배송 정보
          </Text>
        </div>
        <div className="sale-order-detail-info-item">
          <div className="common-container-row common-flex-align-center sale-order-detail-info-delivery-item-margin">
            <Text className="sale-order-detail-info-delivery-title" type="SubHead" color="Gray2">
              받는분
            </Text>
            <Text className="common-flex-grow" type="Body1" color="Gray2">
              {order.recipientName}
            </Text>
          </div>
          <div className="common-container-row sale-order-detail-info-delivery-item-margin">
            <Text className="sale-order-detail-info-delivery-title" type="SubHead" color="Gray2">
              주소
            </Text>
            <Text className="common-flex-grow" type="Body1" color="Gray2">
              {order.recipientAddress1 + " " +order.recipientAddress2}
            </Text>
          </div>
          <div className="common-container-row common-flex-align-center sale-order-detail-info-delivery-item-margin">
            <Text className="sale-order-detail-info-delivery-title" type="SubHead" color="Gray2">
              연락처
            </Text>
            <Text className="common-flex-grow" type="Body1" color="Gray2">
              {UserUtil.getPhoneNumberString(order.recipientPhone)}
            </Text>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SaleOrderDetail
