import React, { Component } from 'react';
import './InterestSelectToggleButton.css';
import { IonButton, IonIcon, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import {log, LogLevel} from '../utils/LogUtil'

/**
 * status: 0:unchecked 1:checking 2:checked 3:warning
 */
type Props = {
  onClick? : (code:string, checked:boolean)=>void,
  checked? : boolean,
  name?: string,
  code?: string,
  key?: string,

};

class InterestSelectToggleButton extends Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    // log(LogLevel.UI_LIFECYCLE, "InterestSelectToggleButton:render", this.props)
    if(this.props.checked)
      return(
        <IonButton color="category-select-button-checked" onClick={() => this.props.onClick(this.props.code, this.props.checked)}>
          <div>{this.props.name}</div>
          <div className="category-select-icon-container"><IonIcon class="category-select-icon" name="checkmark"/></div>
        </IonButton>
      );
    else
      return(
        <IonButton color="category-select-button-unchecked" onClick={() => this.props.onClick(this.props.code, this.props.checked)}>
          <div>{this.props.name}</div>
          <div className="category-select-icon-container"></div>
        </IonButton>
      );
  }
}

export default InterestSelectToggleButton;