import { useCallback } from "react";
import { COLOR_SYSTEM } from "../components/design/design-system";
import { UserWorkType, UserWorkTypeName } from "../models/Model.User";
import useConfirm from "./useConfirm";

const useChangeWorkTypeConfirm = () => {
  const { confirm } = useConfirm();

  const showCanNotChangeWorkTypeConfirm = useCallback(
    async (dday: number) =>
      await confirm({
        icon: {
          name: "Exclamation Mark Filled",
          width: 55,
          height: 55,
          color: COLOR_SYSTEM.get("Red")[300],
        },
        title: `${dday}일 후에\n근무유형 변경이 가능합니다.`,
        description: "변경이 꼭 필요한 경우\n아래 문의하기를 눌러주세요.",
        button: {
          cancel: {
            text: "문의하기",
            color: "Secondary",
          },
          confirm: {
            text: "확인",
            color: "Primary",
          },
        },
      }),
    []
  );

  const showChangeWorkTypeWarningConfirm = useCallback(async (workType: UserWorkType) => {
    const warningDescriptions =
      workType === UserWorkType.PHARMACY_OWNER
        ? [
            "구인 공고의 지역, 약국명 등\n상세 내용을 확인할 수 없습니다.",
            "구인 공고에 지원을 할 수 없습니다.",
            "구인 공고 알림을 받을 수 없습니다.",
            "관심 공고를 학인할 수 없습니다.",
          ]
        : ["구인 공고를 등록할 수 없습니다.", "내 공고 리스트를 확인할 수 없습니다."];

    return await confirm({
      icon: {
        name: "Exclamation Mark Filled",
        width: 55,
        height: 55,
        color: COLOR_SYSTEM.get("Red")[300],
      },
      title: `근무 유형을 ${UserWorkTypeName[workType]}(으)로\n변경할까요?`,
      description: "근무유형은 30일마다 1회 변경 가능합니다.",
      warning: {
        title: "변경 시 아래의 기능을 사용할 수 없습니다.",
        descriptions: warningDescriptions,
      },
      button: {
        cancel: {
          text: "변경 안함",
          color: "Secondary",
        },
        confirm: {
          text: "변경하기",
          color: "Primary",
        },
      },
    });
  }, []);

  return { showCanNotChangeWorkTypeConfirm, showChangeWorkTypeWarningConfirm };
};

export default useChangeWorkTypeConfirm;
