
import * as seminars from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { SeminarListState, SeminarState} from "./types";
import { SeminarLecture, SeminarLectureType } from '../../models/Model.Seminar';
import {log, LogLevel} from '../../utils/LogUtil'
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';

const defaultState: SeminarState = {
  initialized: false,
  lectures : {},
  categoryList: [],
  lists: [], // type id - lecture id list
  features: [],
};

export default (state = defaultState, action: ActionType<typeof seminars>): SeminarState => {
  let newState:SeminarState;
  let lectures = {...state.lectures};
  let newLists:SeminarListState[];
  switch (action.type) {
    case getType(seminars.initialized):
      log(LogLevel.REDUX, "Reducer: seminars.initialized", state);
      return ({
        ...state, initialized:true,
      });
      break;
    case getType(seminars.init):
      log(LogLevel.REDUX, "Reducer: seminars.init", state);
      if(!state.initialized){

        newLists = [{
            id: 0,  
            options:{
              orderBy: "createdAt",
              type: SeminarLectureType.ALL,
            },
            // data
            categoryName: "전체",
            lectures: [],
            end: false,
            loading: false,
            needReload: true,
          },
          {
            id: 1,  
            options:{
              orderBy: "createdAt",
              type: SeminarLectureType.SEMINAR,
            },
          
            // data
            categoryName: "세미나",
            lectures: [],
            end: false,
            loading: false,
            needReload: true,
          },
          {
            id: 2,  
            options:{
              orderBy: "createdAt",
              type: SeminarLectureType.CARDNEWS,
            },
          
            // data
            categoryName: "카드뉴스",
            lectures: [],
            end: false,
            loading: false,
            needReload: true,
          },
          {
            id: 3,  
            options:{
              category: -1,
              type: SeminarLectureType.ALL,
            },
          
            // data
            categoryName: "내 시청기록",
            lectures: [],
            end: false,
            loading: false,
            needReload: true,
          }
        ]
        
        log(LogLevel.REDUX, "Reducer: seminars.init owner", newLists);
        return ({
          ...state, lists:newLists,
        });
      }
      break;
    case getType(seminars.reloadList):
      log(LogLevel.REDUX, "Reducer: seminars.reloadList", action.payload, action.meta, state);
      if(action.meta < 0){
        let newLists = [...state.lists]
        for(let i=0; i<state.lists.length; i++){
          newLists[i] = {...state.lists[i], needReload:true, end:false, loading:false}
          if(action.payload)
            newLists[action.meta].options = {...newLists[action.meta].options, ...action.payload}
        }
        log(LogLevel.REDUX, "Reducer: seminars.reloadList ALL", newLists);
        return ({
          ...state, lists:newLists,
        });
      }
      else{
        let newLists = [...state.lists];
        if(newLists[action.meta]){
          newLists[action.meta].end = false;
          newLists[action.meta].loading = false;
          newLists[action.meta].needReload = true;

          if(action.payload)
            newLists[action.meta].options = {...newLists[action.meta].options, ...action.payload}
          log(LogLevel.REDUX, "Reducer: seminars.reloadList done", newLists);
          return ({
            ...state, lists:newLists
          });        
        }
      }
      break;
    case getType(seminars.updateSeminarLectureView):
      log(LogLevel.REDUX_BOARD, "Reducer: updateSeminarLectureView", action, state, lectures[action.payload]);
      if(action.payload){
        if(!lectures[action.payload])
          lectures[action.payload] = {};
        if(lectures[action.payload].passingMark && lectures[action.payload].viewValue && lectures[action.payload].passingMark > lectures[action.payload].viewValue && lectures[action.payload].passingMark < action.meta)
          lectures[action.payload] = {...lectures[action.payload], viewValue:action.meta};
        else if(!lectures[action.payload].viewValue && lectures[action.payload].viewValue != 0)
          lectures[action.payload].viewValue =action.meta;
        else if(lectures[action.payload].viewValue < action.meta)
          lectures[action.payload].viewValue =action.meta;
        return {...state, lectures};
      }else{
        return state;
      }
    case getType(seminars.fetchSeminarLecture.request):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchSeminarLecture.request", action, state);
      if(action.payload){
        if(!lectures[action.payload])
          lectures[action.payload] = {};
        lectures[action.payload].loading =true;
        return {...state, lectures};
      }else{
        return state;
      }
    case getType(seminars.fetchSeminarLecture.success):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchSeminarLecture.success", action, state);
      if(action.payload){
        lectures[action.payload.id] = {...lectures[action.payload.id], ...action.payload, loading:false}
        return {...state, lectures};
      }else{
        return state;
      }
    case getType(seminars.fetchSeminarLecture.failure):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchSeminarLecture.failure", action, state);
      return state;

    case getType(seminars.fetchSeminarLecture.failure):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchSeminarLecture.failure", action, state);
      return state;

    case getType(seminars.fetchSeminarLectures.request):
      newState= {...state};
      if(newState.lists[action.payload])
        newState.lists[action.payload].loading = true;
      log(LogLevel.REDUX_BOARD, "Reducer: fetchSeminarLectures.request", action, state, newState);
      return newState;
    case getType(seminars.fetchSeminarLectures.success):
      log(LogLevel.REDUX, "Reducer: fetchSeminarLectures.success", action.payload, state);
      newLists = [...state.lists];
      newLists[action.payload.id] = action.payload;
      return ({
        ...state, lists:newLists
      });  
    case getType(seminars.fetchSeminarLectures.failure):
      newState= {...state};
      newState.lists[action.payload.id].loading = false;
      log(LogLevel.REDUX_BOARD, "Reducer: fetchSeminarLectures.failure", action, state);
      return newState;      
    case getType(seminars.fetchSeminarFeatured.success):
      log(LogLevel.REDUX_BOARD, "Reducer: fetchSeminarFeatured.success", action, state);
      newState= {...state};
      newState.features = action.payload;
      return newState;
    case getType(seminars.updateSeminarLectureViewLastPlayTime):
      log(LogLevel.REDUX_BOARD, "Reducer: updateSeminarLectureViewLastPlayTime", action, state);
      if(action.payload){
        if(!lectures[action.payload])
          lectures[action.payload] = {};

        lectures[action.payload].lastPlayedTime = action.meta;
        return {...state, lectures};
      }else{
        return state;
      }
    default:
      return state;
  }
  "error code TypeError: Cannot read properties of undefined (reading 'success')"
  return state;
}
