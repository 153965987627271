import React from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import { format } from "date-fns";
import {
  IonLabel,
  IonButton,
  IonIcon,
  IonItemSliding,
  IonItem,
  IonItemOptions,
  IonItemOption,
  IonAlert,
} from "@ionic/react";
import "./BoardListItem.css";
import qustionIcon from "./../assets/image/question.png";
import { getDateTimeString, getDateTimeStringShort } from "./../utils/TimeUtil";
import badgeIcon from "./../assets/icon/nice_badge.png";
import { BoardContent, BoardAttribute } from "./../models/Model.Board";
import { fetchAPI } from "../utils/APIUtil";
import * as API from "./../API.json";
import {
  BoardType,
  BoardFilterType,
  partnerThumbnail,
} from "./../store/board/types";
import { getGlobal, GlobalKey } from "./../utils/GlobalUtil";
import Truncate from "react-truncate";
import { action } from "typesafe-actions";
import { log, LogLevel } from "../utils/LogUtil";
import ProfileImage from "./ProfileImage";
import LinkMeta from "./LinkMeta";
import { MY_ADDRESS } from "./../config.json";
import AnalyticsUtil from "./../utils/AnalyticsUtil";
import ABTestUtil, { ABTestFeature } from "../utils/ABTestUtil";
import StringUtil from "../utils/StringUtil";
import { BannerInfo } from "../models/Model.Banner";
import VisibilitySensor from "react-visibility-sensor";
import ActionButton from "./ActionButton";
import { UIPopupType } from "../store/ui/types";
import BoardUtil from "../utils/BoardUtil";
import likeIcon from "./../assets/icon/like.svg";
import Text from "./atoms/Text";
import { COLOR_SYSTEM } from "./design/design-system";
import Tag from "./atoms/Tag";
import { Flex, Static } from "./atoms/Layout";
import Icon from "./atoms/Icon";
import { sendKakaoInviteMessage } from "../utils/KakaoUtil";
import { CompanyTypeName } from "../models/Model.User";
import Avatar from "./atoms/Avatar";
import BoardListSummaryRecommends from "./BoardListSummaryRecommends";
import AdsUtil from "../utils/AdsUtil";

type Props = RouteComponentProps<{}> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    key?: string;
    id?: number;
    index?: number;
    posting?: BoardContent;
    attribute?: string;
    listType?: BoardType;
    filter?: number;
    onClick?: (content: BoardContent) => void;
    onBannerVisibleChange?: (visible: boolean, banner: BannerInfo) => void;
    onBannerClick?: (banner: BannerInfo) => void;
  };

const BODY_MAX_LENGTH_2 = 55;
const TITLE_MAX_LENGTH_2 = 40;
const windowAny: any = window;

class BoardListItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // if(!this.props.filePath){
    //   this.props.loadFilePath();
    // }
  }

  navigateToView = () => {
    let posting = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }

    // let name = "";
    let id = 0;
    if (this.props.id) id = this.props.id;
    if (this.props.posting && this.props.posting.id) id = this.props.posting.id;

    if (this.props.onClick) this.props.onClick(posting);
    // if(this.props.listType)
    //   name = BoardType[this.props.listType];

    // AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SELECT_CONTENT", '게시물 선택', {"게시물id":id, "게시판이름":name});

    this.props.history.push(`/boards/view?id=${this.props.id}`, {
      listType: this.props.listType,
      index: this.props.index,
    });
  };

  doScrap = async () => {
    let posting = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }
    let scrapped = !posting.scrapped;
    log(LogLevel.NONE, "doScrap", scrapped);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    if (scrapped) {
      result = await fetchAPI(
        API.ACTION_SCRAP,
        this.props.id,
        null,
        null,
        token
      );
    } else {
      result = await fetchAPI(
        API.ACTION_CANCEL_SCRAP,
        this.props.id,
        null,
        null,
        token
      );
    }

    if (result && !result.error) {
      let newContent: BoardContent = { ...posting, scrapped };
      this.props.updateContent(newContent);
      this.props.refreshBoards(BoardType.MY_SCRAP);
    }
    // this.setState({scrapped});
  };

  onUserPressed = (e) => {
    log(LogLevel.UI_ACTION, "BoardListItem: onUserPressed");
    // let posting = this.props.contents[this.props.id];
    // // if(posting.usersId != this.props.me.id)
    // if(!posting.isAnonymous && posting.usersId > 0){
    //   this.props.history.push("/user?id="+posting.usersId);
    //   e.stopPropagation();
    // }
  };

  onClickLink = (url) => {
    // if (url.startsWith("&&&")) {
    //   if (getGlobal(GlobalKey.OS) != "browser") {
    //     this.props.history.push(url.substring(3));
    //   } else {
    //     window.open(url.replace("&&&", MY_ADDRESS), getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system");
    //   }
    // } else if (url.startsWith(MY_ADDRESS)) {
    //   if (getGlobal(GlobalKey.OS) != "browser") {
    //     this.props.history.push(url);
    //   } else {
    //     window.open(url, getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system");
    //   }
    // } else if (url.startsWith("/")) {
    //   this.props.history.push(url);
    // } else if (url.startsWith("tel") || url.startsWith("sms")) {
    //   window.open(url, "_system");
    // } else window.open(url, getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system");
    this.navigateToView();
  };

  onKakaoShare = (e) => {
    let posting = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }
    if (!posting) {
      return;
    }

    e.stopPropagation();

    this.props.confirmPopup.show({
      title: (
        <div className="common-container common-color-highlight">잠깐만요,</div>
      ),
      body: (
        <div className="common-container">
          1. 약문약답의 모든 컨텐츠는 약사만을 위한 컨텐츠입니다. 비약사에게
          공유하시면 안되요~
          <br />
          <br />
          2. 공유된 링크는 약문약답앱에서만 확인 가능합니다.
        </div>
      ),
      iconImage: null,
      doneText: "공유하기",
      cancelText: "공유취소",
      onDone: this.doKakaoShare,
    });
  };

  doKakaoShare = async () => {
    log(LogLevel.UI_ACTION, "BoardListItem: doKakaoShare");
    let posting = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }
    if (!posting) {
      return;
    }

    let webUrl;
    let result = await fetchAPI(
      API.ACTION_SHARE,
      posting.id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    if (!!ABTestUtil.isTest(ABTestFeature.DISABLE_OPEN_PAGE_SHARE)) {
      if (result && !result.error) {
        webUrl = MY_ADDRESS + "/open?id=" + result.data;
      }
    }

    posting.sharedCnt = posting.sharedCnt + 1;
    posting.shared = true;
    //console.log("updateContent main", this.content);
    BoardUtil.checksum(posting);
    BoardUtil.countAllLike(posting);
    this.props.updateContent(posting);

    let os = getGlobal(GlobalKey.OS);

    let image;
    if (posting.cards && posting.cards.length && posting.cards[0].url)
      image = StringUtil.convertFilePath(posting.cards[0].url);
    else if (posting.image) image = StringUtil.convertFilePath(posting.image);
    else image = MY_ADDRESS + "/images/ymydgi_20220719.jpg";

    if (!ABTestUtil.isTest(ABTestFeature.DISABLE_OPEN_PAGE_SHARE) && webUrl) {
      if (!os || os == "browser") {
        let feed = {
          objectType: "feed",
          content: {
            title: posting.subject,
            description: posting.bodyText,
            imageUrl: image,
            link: {
              mobileWebUrl: webUrl,
              webUrl: webUrl,
              androidExecParams: "type=view&id=" + posting.id,
              iosExecParams: "type=view&id=" + posting.id,
            },
          },
          social: {
            likeCount: posting.likeCntAll,
            commentCount: posting.replyCnt,
            viewCount: posting.viewCnt,
          },
          buttons: [
            {
              title: "Web에서 보기",
              link: {
                mobileWebUrl: webUrl,
                webUrl: webUrl,
              },
            },
            {
              title: "App에서 보기",
              link: {
                androidExecParams: "type=view&id=" + posting.id,
                iosExecParams: "type=view&id=" + posting.id,
              },
            },
          ],
        };
        //console.log("BoardListItem: doKakaoShare", feed)
        try {
          windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, e);
        }
        windowAny.Kakao.Link.sendDefault(feed);
      } else if (windowAny.KakaoCordovaSDK) {
        let feed = {
          objectType: "feed",
          content: {
            title: posting.subject,
            desc: posting.bodyText,
            imageURL: image,
            link: {
              mobileWebURL: webUrl,
              webURL: webUrl,
              androidExecutionParams: "type=view&id=" + posting.id,
              iosExecutionParams: "type=view&id=" + posting.id,
            },
          },
          social: {
            likeCount: posting.likeCntAll,
            commentCount: posting.replyCnt,
            viewCount: posting.viewCnt,
          },
          buttons: [
            {
              title: "Web에서 보기",
              link: {
                mobileWebURL: webUrl,
                webURL: webUrl,
              },
            },
            {
              title: "App에서 보기",
              link: {
                androidExecutionParams: "type=view&id=" + posting.id,
                iosExecutionParams: "type=view&id=" + posting.id,
              },
            },
          ],
        };
        windowAny.KakaoCordovaSDK.sendLinkFeed(
          feed,
          (res) => {
            console.log(
              "BoardListItem: doKakaoShare: Kakao share success",
              res,
              feed
            );
          },
          (res) => {
            console.log(
              "BoardListItem: doKakaoShare: Kakao share fail",
              res,
              feed
            );
          }
        );
      }
    } else {
      if (!os || os == "browser") {
        let feed = {
          objectType: "feed",
          content: {
            title: posting.subject,
            description: posting.bodyText,
            imageUrl: image,
            link: {
              androidExecParams: "type=view&id=" + posting.id,
              iosExecParams: "type=view&id=" + posting.id,
            },
          },
          social: {
            likeCount: posting.likeCnt,
            commentCount: posting.replyCnt,
            viewCount: posting.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecParams: "type=view&id=" + posting.id,
                iosExecParams: "type=view&id=" + posting.id,
              },
            },
          ],
        };
        //console.log("BoardListItem: doKakaoShare", feed)
        try {
          windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
        } catch (e) {
          log(LogLevel.UI_EXCEPTION, e);
        }
        windowAny.Kakao.Link.sendDefault(feed);
      } else if (windowAny.KakaoCordovaSDK) {
        let feed = {
          objectType: "feed",
          content: {
            title: posting.subject,
            desc: posting.bodyText,
            imageURL: image,
            link: {
              androidExecutionParams: "type=view&id=" + posting.id,
              iosExecutionParams: "type=view&id=" + posting.id,
            },
          },
          social: {
            likeCount: posting.likeCnt,
            commentCount: posting.replyCnt,
            viewCount: posting.viewCnt,
          },
          buttons: [
            {
              title: "App에서 보기",
              link: {
                androidExecutionParams: "type=view&id=" + posting.id,
                iosExecutionParams: "type=view&id=" + posting.id,
              },
            },
          ],
        };
        windowAny.KakaoCordovaSDK.sendLinkFeed(
          feed,
          (res) => {
            console.log(
              "BoardListItem: doKakaoShare: Kakao share success",
              res,
              feed
            );
          },
          (res) => {
            console.log(
              "BoardListItem: doKakaoShare: Kakao share fail",
              res,
              feed
            );
          }
        );
      }
    }

    // AMPLITUDE
    let body;
    if (posting.subject) body = posting.subject.substring(0, 10);
    else if (posting.bodyText) body = posting.bodyText.substring(0, 10);
    let name = "";
    if (posting.usersNickname) name = posting.usersNickname;
    else posting.usersCustomName;
    name = posting.usersCustomName;
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "CONTENT_SHARE",
      "게시물_공유",
      {
        게시물id: posting.id,
        게시물내용: body,
        글쓴이id: posting.usersId,
        글쓴이닉네임: name,
        게시판이름: posting.boardsCategoriesName,
      }
    );
  };

  onLike = async (e) => {
    let posting = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }
    if (!posting || posting.deletedAt) return;

    e.stopPropagation();

    let liked = posting.liked;
    let likeCnt = posting.likeCnt;
    log(LogLevel.UI_ACTION, "BoardListItem:onLike", !liked, posting);
    let token = getGlobal(GlobalKey.TOKEN);
    let result;
    // AMPLITUDE
    let body;
    if (posting.subject) body = posting.subject.substring(0, 10);
    else if (posting.bodyText) body = posting.bodyText.substring(0, 10);
    let name = "";
    if (posting.usersNickname) name = posting.usersNickname;
    else if (posting.usersCustomName) name = posting.usersCustomName;

    // log(LogLevel.UI_ACTION, "BoardListItem:onLike name", name, posting);
    if (liked) {
      result = fetchAPI(API.ACTION_CANCEL_LIKE, posting.id, null, null, token);
      likeCnt--;
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_LIKE_CANCEL",
        "게시물_좋아요_취소",
        {
          게시물id: posting.id,
          게시물내용: body,
          글쓴이id: posting.usersId,
          글쓴이닉네임: name,
          게시판이름: posting.boardsCategoriesName,
        }
      );
    } else {
      result = fetchAPI(API.ACTION_LIKE, posting.id, null, null, token);
      likeCnt++;
      // AMPLITUDE
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "CONTENT_LIKE",
        "게시물_좋아요",
        {
          게시물id: posting.id,
          게시물내용: body,
          글쓴이id: posting.usersId,
          글쓴이닉네임: name,
          게시판이름: posting.boardsCategoriesName,
        }
      );
    }
    log(LogLevel.UI_ACTION, "BoardListItem:onLike result", result);

    posting.likeCnt = likeCnt;
    posting.liked = !liked;

    //console.log("updateContent main", this.content);
    BoardUtil.checksum(posting);
    BoardUtil.countAllLike(posting);
    this.props.updateContent(posting);
    this.props.refreshBoards(BoardType.MY_LIKE);
  };

  renderProfile() {
    let profile = null;
    let posting: BoardContent = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }

    if (!this.props.attribute.includes(BoardAttribute.UI_LIST_PROFILE))
      return null;

    let name = posting.usersNickname;
    if (posting.usersId === -1) {
      if (posting.usersCustomName) name = posting.usersCustomName;
      else if (posting.usersNickname) name = posting.usersNickname;
      else name = "익명사용자";
    }
    if (posting.isAnonymous) {
      if (posting.usersCustomName) name = posting.usersCustomName;
      else name = "익명사용자";
    }
    profile = (
      <div
        className="boardlistitem-profile-container"
        onClick={this.onUserPressed}
      >
        <ProfileImage
          className="boardlistitem-profile"
          profileUrl={posting.usersProfileUrl}
        />
        <div>{name}</div>
      </div>
    );
    return profile;
  }

  renderTitle() {
    let title = null;
    let posting: BoardContent = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }

    let attribute = posting.boardsCategoriesAttribute;
    if (!attribute) attribute = this.props.attribute;
    if (!attribute) attribute = "";

    if (
      !attribute.includes(BoardAttribute.HAS_TITLE) ||
      !attribute.includes(BoardAttribute.UI_LIST_TITLE)
    )
      return null;

    let titleS = posting.subject;
    if (!titleS || !titleS.length) {
      titleS = "제목 없음";
    }

    let icon;
    if (
      this.props.attribute &&
      this.props.attribute.includes(
        BoardAttribute.UI_LIST_NICE_CONTENT_BADGE
      ) &&
      posting.isNice
    ) {
      icon = <img className="boardlistitem-title-icon" src={badgeIcon} />;
    }

    title = (
      <div className="boardlistitem-title-container">
        {icon}
        <Text
          textType="Body1Bold"
          color={COLOR_SYSTEM.get("Gray")[800]}
          numberOfLines={1}
        >
          {titleS}
        </Text>
      </div>
    );

    return title;
  }

  renderImage() {
    let posting = this.props.contents[this.props.id];
    if (!posting) {
      posting = this.props.posting;
    }
    if (
      !(
        this.props.attribute &&
        this.props.attribute.includes(BoardAttribute.UI_LIST_IMAGE) &&
        !this.props.attribute.includes(BoardAttribute.UI_LIST_BIG_IMAGE)
      )
    )
      return null;

    let image;
    if (posting.image)
      image = (
        <img
          className="boardlistitem-body-image"
          src={StringUtil.convertFilePath(posting.image)}
        />
      );
    return image;
  }

  renderToolbar() {
    let posting: BoardContent = this.props.contents[this.props.id];
    let attribute = this.props.attribute;
    if (!attribute) attribute = posting.boardsCategoriesAttribute;
    if (!attribute) attribute = "";

    if (attribute.includes(BoardAttribute.UI_LIST_TOOLBAR)) {
      let toolbar;
      let toolbarItemList = [];
      let index = 0;
      if (
        posting.boardsCategoriesAttribute.includes(BoardAttribute.CAN_SHARE)
      ) {
        if (toolbarItemList.length > 0) {
          toolbarItemList.push(
            <div
              key={(index++).toString()}
              className="boardlistitem-toolbar-button-devider common-flex-grow"
            />
          );
          toolbarItemList.push(
            <div key={(index++).toString()} className="common-flex-grow" />
          );
        }
        toolbarItemList.push(
          <IonButton
            key={(index++).toString()}
            color="common-button"
            class={
              "boardlistitem-toolbar-button" +
              (posting.shared ? " boardlistitem-toolbar-button-on" : "")
            }
            onClick={this.onKakaoShare}
          >
            공유하기
          </IonButton>
        );
      }

      if (
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.CAN_COMMENT_ON_POST
        )
      ) {
        if (toolbarItemList.length > 0) {
          toolbarItemList.push(
            <div
              key={(index++).toString()}
              className="boardlistitem-toolbar-button-devider common-flex-grow"
            />
          );
          toolbarItemList.push(
            <div key={(index++).toString()} className="common-flex-grow" />
          );
        }
        toolbarItemList.push(
          <div
            key={(index++).toString()}
            className="boardlistitem-toolbar-button common-flex-column common-flex-align-center"
          >
            댓글달기
          </div>
        );
      }

      if (
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.CAN_LIKE_ON_POST
        )
      ) {
        let emphasisLike = false;
        if (
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.UI_VIEWER_LIKE_DISLIKE_EMPHASIS
          ) &&
          posting.likeCnt &&
          (!posting.dislikeCnt || posting.likeCnt > posting.dislikeCnt)
        )
          emphasisLike = true;
        let title = "좋아요";
        if (
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.IS_DISCUSSION
          )
        )
          title = "찬성";
        if (toolbarItemList.length > 0) {
          toolbarItemList.push(
            <div
              key={(index++).toString()}
              className="boardlistitem-toolbar-button-devider common-flex-grow"
            />
          );
          toolbarItemList.push(
            <div key={(index++).toString()} className="common-flex-grow" />
          );
        }
        toolbarItemList.push(
          <IonButton
            key={(index++).toString()}
            color="common-button"
            class={
              "boardlistitem-toolbar-button common-flex-row common-flex-align-center" +
              (posting.liked ? " boardlistitem-toolbar-button-on" : "")
            }
            onClick={this.onLike}
          >
            <IonIcon class="boardlistitem-toolbar-icon" src={likeIcon} />
            좋아요
          </IonButton>
        );
      }

      toolbar = (
        <div className="boardlistitem-toolbar-container">{toolbarItemList}</div>
      );

      return toolbar;
    } else {
      //log(LogLevel.UI_LIFECYCLE, "BoardListItem.render: no toolbar", posting);
    }

    return null;
  }

  render() {
    let posting: BoardContent = this.props.contents[this.props.id];
    try {
      if (!posting) {
        posting = this.props.posting;
      }
      if (!posting || !posting.id) return null;

      let attribute = this.props.attribute;
      if (!attribute) attribute = posting.boardsCategoriesAttribute;
      if (!attribute) attribute = "";

      let link;
      if (
        attribute.includes(BoardAttribute.UI_LIST_LINK) &&
        posting.reference &&
        posting.reference.url &&
        (posting.reference.title ||
          posting.reference.description ||
          posting.reference.image)
      ) {
        link = (
          <LinkMeta
            link={posting.reference.url}
            title={posting.reference.title}
            description={posting.reference.description}
            image={posting.reference.image}
            onClick={this.onClickLink}
          />
        );
      }

      let bigImage;
      if (
        posting.image &&
        this.props.attribute &&
        this.props.attribute.includes(BoardAttribute.UI_LIST_IMAGE) &&
        this.props.attribute.includes(BoardAttribute.UI_LIST_BIG_IMAGE)
      ) {
        bigImage = (
          <img
            className="boardlistitem-body-big-image"
            src={StringUtil.convertFilePath(posting.image)}
          />
        );
      }

      let body;

      let title = this.renderTitle();

      if (
        (attribute.includes(BoardAttribute.UI_LIST_BODY) && posting.bodyText) ||
        (attribute.includes(BoardAttribute.UI_LIST_TITLE) && posting.subject) ||
        !posting.boardsCategoriesAttribute.includes(BoardAttribute.HAS_TITLE)
      ) {
        let bodyS = posting.bodyText;
        if (bodyS.length > 45) bodyS = bodyS.substring(0, 45) + "...";
        if (bodyS || link || title)
          body = (
            <div className="boardlistitem-body-container">
              <div className="boardlistitem-body-inner">
                <div className="boardlistitem-body">
                  {/* <Truncate className="boardlistitem-body-text" lines={truncateLines} ellipsis="...">
                    {posting.bodyText}
                  </Truncate> */}
                  {title}
                  {attribute.includes(BoardAttribute.UI_LIST_BODY) && bodyS && (
                    <div className="boardlistitem-body-text">
                      <Text
                        textType="Body2"
                        color={COLOR_SYSTEM.get("Gray")[600]}
                        numberOfLines={posting.image ? 2 : 1}
                      >
                        {bodyS}
                      </Text>
                    </div>
                  )}
                </div>
                {this.renderImage()}
              </div>
              {bigImage}
              {link}
            </div>
          );
      }

      let replyCnt;
      let commentCnt;
      let metooCnt;
      let likeCnt;
      let viewCnt;

      if (
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.UI_LIST_METOO_CNT
        )
      ) {
        if (
          posting.boardsCategoriesAttribute.includes(BoardAttribute.IS_QNA) &&
          posting.replyCnt === 0
        )
          // metooCnt = <span className="boardlistitem-inner-info-item">궁금해요&nbsp;&nbsp;{posting.meTooCnt}</span>;
          metooCnt = (
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Red")[300]}>
              {posting.meTooCnt > 0
                ? `+${posting.meTooCnt}명이 의견을 기다리는중`
                : "의견을 기다리는중"}
            </Text>
          );
      }
      if (
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.UI_LIST_REPLY_CNT
        )
      ) {
        let className = "boardlistitem-inner-info-item-reply";
        if (posting.replyCnt === 0)
          className = "boardlistitem-inner-info-item-noreply";

        let replayTerm = "의견";
        if (posting.boardsCategoriesAttribute.includes(BoardAttribute.IS_QNA))
          replayTerm = "답변";

        replyCnt = (
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[900]}>
            의견 {posting.replyCnt}
          </Text>
          // <span className={className}>
          //   {replayTerm}&nbsp;&nbsp;{posting.replyCnt}
          // </span>
        );
      }
      if (
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.UI_LIST_LIKE_CNT
        ) &&
        !metooCnt
      ) {
        let likeTerm = "좋아요";
        if (
          posting.boardsCategoriesAttribute.includes(
            BoardAttribute.IS_DISCUSSION
          )
        )
          likeTerm = "찬성";

        likeCnt = (
          // <span className="boardlistitem-inner-info-item">
          //   {likeTerm}&nbsp;&nbsp;{posting.likeCntAll}
          // </span>
          <Flex alignItems="center" gap="4px">
            <Icon
              name="Thumbs Up"
              width={16}
              height={16}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
            <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
              {posting.likeCntAll}
            </Text>
          </Flex>
        );
      }
      if (
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.UI_LIST_VIEW_CNT
        )
      ) {
        // viewCnt = <span className="boardlistitem-inner-info-item">읽기&nbsp;&nbsp;{posting.viewCnt}</span>;
        viewCnt = (
          <Flex alignItems="center" gap="4px">
            <Icon
              name="Eye"
              width={16}
              height={16}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
            <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
              {posting.viewCnt}
            </Text>
          </Flex>
        );
      }
      if (
        posting.boardsCategoriesAttribute.includes(
          BoardAttribute.UI_LIST_COMMENT_CNT
        ) &&
        (!posting.boardsCategoriesAttribute.includes(
          BoardAttribute.CAN_REPLY
        ) ||
          posting.replyCnt > 0)
      ) {
        // commentCnt = <span className="boardlistitem-inner-info-item">댓글&nbsp;&nbsp;{posting.commentCnt}</span>;
        commentCnt = (
          <Flex alignItems="center" gap="4px">
            <Icon
              name="Comment Circle Dots"
              width={16}
              height={16}
              color={COLOR_SYSTEM.get("Gray")[200]}
            />
            <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
              {posting.commentCnt}
            </Text>
          </Flex>
        );
      }

      let curtain;
      if (
        (this.props.filter == BoardFilterType.UNREAD && posting.viewed) ||
        (this.props.filter == BoardFilterType.UNANSWERED && posting.replyCnt)
      ) {
        log(
          LogLevel.UI_LIFECYCLE,
          "BoardListItem:render need curtain",
          this.props,
          posting
        );
        curtain = <div className="boardlistitem-body-inactive" />;
      }

      let bannerView;
      let BANNER_INTERVAL = 7;
      if (attribute.includes(BoardAttribute.UI_LIST_BANNER)) {
        if (
          this.props.banners &&
          this.props.index > 0 &&
          this.props.index % BANNER_INTERVAL == 0 &&
          Math.floor(this.props.index / BANNER_INTERVAL) <
            this.props.banners.length
        ) {
          log(
            LogLevel.UI_LIFECYCLE,
            "BoardListItem:render banner ",
            this.props.banners
          );
          let banner = this.props.banners[
            Math.floor(this.props.index / BANNER_INTERVAL)
          ];
          if (banner.advertiserInfo)
            bannerView = (
              <VisibilitySensor
                onChange={(visible) =>
                  this.props.onBannerVisibleChange(visible, banner)
                }
              >
                <img
                  className="boardlistitem-banner"
                  src={StringUtil.convertFilePath(banner.imageUrl)}
                  onClick={() => this.props.onBannerClick(banner)}
                />
              </VisibilitySensor>
            );
          else
            bannerView = (
              <img
                className="boardlistitem-banner"
                src={StringUtil.convertFilePath(banner.imageUrl)}
                onClick={() => this.props.onBannerClick(banner)}
              />
            );
        }
      }

      let summeryView;

      if (
        ABTestUtil.isTest(ABTestFeature.UI_GPT_SUMMARY) &&
        attribute.includes(BoardAttribute.UI_LIST_SUMMARY)
      ) {
        if (this.props.index == 1) {
          log(
            LogLevel.UI_LIFECYCLE,
            "BoardListItem:render summary ",
            attribute,
            BoardAttribute.UI_LIST_SUMMARY,
            this.props.index
          );
          summeryView = <BoardListSummaryRecommends />;
        }
      }

      let icon;
      if (
        this.props.attribute &&
        this.props.attribute.includes(
          BoardAttribute.UI_LIST_NICE_CONTENT_BADGE
        ) &&
        posting.isNice
      ) {
        icon = <img className="boardlistitem-title-icon" src={badgeIcon} />;
      }
      return (
        <>
          {bannerView}
          {summeryView}
          {/* <Static
            className="boardlistitem-container"
            customStyle={{
              width: "100%",
              padding: "0",
              backgroundColor: COLOR_SYSTEM.get("Gray")[0],
              borderWidth: "1px 0",
              borderColor: COLOR_SYSTEM.get("Gray")[50],
              borderStyle: "solid",
            }}
          > */}
          <IonItem class="boardlistitem-container">
            <Static
              customStyle={{
                width: "100%",
                paddingTop: "16px",
              }}
              onClick={this.navigateToView}
            >
              {curtain}
              {this.renderProfile()}
              {this.props.attribute &&
                this.props.attribute.includes(
                  BoardAttribute.UI_LIST_CANTEGORY_NAME
                ) && (
                  <Static
                    customStyle={{ marginBottom: "12px", padding: "0 20px" }}
                  >
                    {posting.boardsCategoriesName == "약사파트너스" ? (
                      <Flex
                        direction="row"
                        justifyContent="left"
                        alignItems="center"
                        gap="5px"
                      >
                        <Avatar
                          size="XSmall"
                          url={posting.usersProfileUrl}
                          border={true}
                        />{" "}
                        <Text
                          numberOfLines={1}
                          textType="CaptionBold"
                          color={COLOR_SYSTEM.get("Gray")[700]}
                        >
                          {posting.usersNickname}
                        </Text>{" "}
                        <Tag size="Small" variant="Ghost" color="Blue">
                          {posting.subCategories &&
                            posting.subCategories[0] &&
                            posting.subCategories[0].name}
                        </Tag>
                      </Flex>
                    ) : (
                      <Tag variant="Tinted" color="Gray">
                        {posting.boardsCategoriesName}
                      </Tag>
                    )}
                  </Static>
                )}
              {body}
              {/* {link} */}

              <Flex
                alignItems="center"
                justifyContent="space-between"
                customStyle={{
                  padding: "12px 20px",
                  marginTop: "16px",
                  borderTop: `1px solid ${COLOR_SYSTEM.get("Gray")[20]}`,
                }}
              >
                <Flex alignItems="center" gap="12px">
                  <Flex alignItems="center" gap="4px">
                    <Icon
                      name="Clock"
                      width={16}
                      height={16}
                      color={COLOR_SYSTEM.get("Gray")[200]}
                    />
                    <Text
                      textType="Caption"
                      color={COLOR_SYSTEM.get("Gray")[400]}
                    >
                      {getDateTimeStringShort(posting.createdAt)}
                    </Text>
                  </Flex>
                  {viewCnt}
                  {likeCnt}
                  {commentCnt}
                </Flex>
                {posting.replyCnt ? replyCnt : metooCnt}
              </Flex>
              {this.renderToolbar()}
            </Static>
          </IonItem>
          {/* </Static> */}
        </>
      );
    } catch (e) {
      log(
        LogLevel.UI_EXCEPTION,
        "BoardListItem.render Exception",
        this.props.attribute,
        posting,
        e
      );
      return null;
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
  me: state.user.me,
  boards: state.board.boards,
  contents: state.board.contents,
  // filePath: state.board.filePath,
  banners: state.banner.banners,
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
});

const mapDispatchToProps = {
  // logOutUser: () => actions.user.logout(),
  // refreshBoards: () => actions.board.refreshBoards(),
  // loadBoard: (board:BoardType) => actions.board.getBoard(board.toString(), 50),
  updateContent: (content: BoardContent) =>
    actions.board.updateContent(content),
  refreshBoards: (board: BoardType = BoardType.ALL) =>
    actions.board.refreshBoards(board),
  // loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BoardListItem)
);

// export default withRouter(BoardListItem);
