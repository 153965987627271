import React, { Component } from 'react';
import './../../../Common.css';


type Props = {
};

const InformationContract: React.SFC<Props> = ({}) => {
  return (
  <div className="common-block">
이용 약관 <br/>
<br/>
제 1 장 총칙<br/>
제 1 조 (목적)<br/>
<br/>
본 약관은 약문약답 주식회사 (이하 '회사')와 이용 고객(이하 '회원') 간에 회사가 제공하는 약문약답 및 약문약답 관련 서비스(이하 '서비스')의 이용에 관한 제반사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.<br/>
<br/>
제 2 조 (용어의 정의)<br/>
본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
1. '서비스'라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 '회원'이 이용할 수 있는 서비스를 의미합니다.<br/>
2. '회원'이라 함은 회사의 '서비스'에 접속하여 이 약관에 따라 '회사'와 이용계약을 체결하고 '회사'가 제공하는 '서비스'를 이용하는 고객을 의미합니다.<br/>
3. '아이디(ID)'라 함은 '회원'의 식별과 '서비스' 이용을 위하여 '회원'이 정하고 '회사'가 승인하는 문자와 숫자의 조합을 의미합니다.<br/>
4. '비밀번호'라 함은 '회원'이 부여 받은 '아이디와 일치되는 '회원'임을 확인하고 비밀보호를 위해 '회원' 자신이 정한 문자 또는 숫자의 조합을 의미합니다.<br/>
5. '게시물'이라 함은 '회원'이 '서비스'를 이용함에 있어 '서비스상'에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.<br/>
6. '서비스탈퇴'라 함은 회원이 이용계약을 해지하는 것을 의미합니다.<br/>
<br/>
제 3 조 (약관의 게시와 개정)<br/>
1. '회사'는 본 약관을 '회원'이 서비스를 이용하는 과정에서 해당 약관의 내용을 확인할 수 있도록 '서비스' 초기 화면 등에 게시하며 기술적 조치를 취합니다.<br/>
2. '회사'는 '회원'이 '회사'와 본 약관의 내용에 관하여 질의 및 응답할 수 있도록 서비스를 제공합니다.<br/>
3. '회사'는 콘텐츠산업진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
4. '회사'가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 '회사'의 게시판 혹은 '서비스' 초기화면에 그 적용일자 7일 전에 공지합니다. 단, '회원'에게 불리하거나 중대한 사항의 변경일 경우에는 적용일자 및 변경사유를 명시하여 현행 약관과 함께 그 개정 약관의 적용일자 30일 전에 공지 합니다.<br/>
5. '회사'가 동조 제2항에 따라 개정된 약관을 공지하면서, 공지 기간 내에 '회원'이 명시적으로 거부의 의사표시를 하지 않으면 개정약관에 동의한 것으로 간주한다는 뜻을 명확하게 공지 또는 통지하였음에도 '회원'이 명시적으로 거부의 의사표시를 하지 아니한 경우 '회원'이 개정약관에 동의한 것으로 간주합니다.<br/>
6. '회원'이 동조 제3항에 따라 개정 약관의 적용에 동의하지 않는 경우 '회원'은 '서비스' 이용계약을 해지할 수 있으며, 계약 해지에 대해 어떠한 통지도 필요하지 않습니다.<br/>
<br/>
제 2 장 회원의 가입 및 관리<br/>
제 4 조 (이용계약 체결)<br/>
1. 이용계약은 '회원'이 되고자 하는 자(이하 '가입신청자')가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 '회사'가 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br/>
2. 회원가입신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주하며 실제 정보를 입력하지 않은 '회원'은 법적인 보호를 받을 수 없으며, '서비스' 이용의 제한을 받을 수 있습니다.<br/>
3. '회사'는 '가입신청자'의 신청에 대하여 '서비스' 이용을 승낙함을 원칙으로 합니다. 다만, '회사'는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.<br/>
1) 가입신청자가 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 '회사'의 회원 재가입 승낙을 얻은 경우에는 예외로 함.<br/>
2) 실명이 아니거나 타인의 명의를 이용한 경우<br/>
3) 허위의 정보를 기재하거나, '회사'가 제시하는 내용을 기재하지 않은 경우<br/>
4) 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br/>
5) 부정한 용도 혹은 영리를 추구할 목적으로 신청한 경우<br/>
4. 제1항에 따른 신청에 있어 '회사'는 '회원'의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.<br/>
5. '회사'는 서비스 관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br/>
6. '회사'는 '회원'에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.<br/>
<br/>
제 5 조 (회원정보의 변경)<br/>
1. '회원'은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 반드시 필요한 실명, 주민등록번호, 아이디 등은 수정이 불가능합니다.<br/>
2. '회원'은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 '회사'에 대하여 그 변경사항을 알려야 합니다.<br/>
3. 제2항의 변경사항을 '회사'에 알리지 않아 발생한 불이익에 대하여 '회사'는 책임지지 않습니다.<br/>
<br/>
제 6 조 (개인정보보호 의무)<br/>
'회사'는 '정보통신망법' 등 관계 법령이 정하는 바에 따라 '회원'의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련 법령 및 '회사'의 개인정보처리방침이 적용됩니다.<br/>
<br/>
제 7 조 ('회원'의 '아이디' 및 '비밀번호'의 관리에 대한 의무)<br/>
1. '회원'의 '아이디'와 '비밀번호'에 관한 관리책임은 '회원'에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.<br/>
2. '회사'는 '회원'의 '아이디'가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 '회사' 및 '회사'의 운영자로 오인한 우려가 있는 경우, 해당 '아이디'의 이용을 제한할 수 있습니다.<br/>
3. '회원'은 '아이디' 및 '비밀번호'가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 '회사'에 통지하고 '회사'의 안내에 따라야 합니다.<br/>
4. 제3항의 경우에 해당 '회원'이 '회사'에 그 사실을 통지하지 않거나, 통지한 경우에도 '회사'의 안내에 따르지 않아 발생한 불이익에 대하여 '회사'는 책임지지 않습니다.<br/>
<br/>
제 8 조 ('회원'에 대한 통지)<br/>
1. '회사'가 '회원'에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.<br/>
2. '회사'는 '회원' 전체에 대한 통지의 경우 7일 이상 '회사'의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.<br/>
<br/>
제 9 조 ('회원' 서비스 탈퇴 및 자격 상실 등)<br/>
1. '회원'은 '회사'에 언제든지 서비스 탈퇴를 요청할 수 있으며 '회사'는 즉시 '회원' 서비스 탈퇴를 처리합니다.<br/>
2. '회원'이 다음 각호의 사유에 해당하는 경우, '회사'는 '회원'자격을 제한 및 정지, 가입취소 시킬 수 있습니다.<br/>
1) 가입신청 시에 허위내용을 등록한 경우<br/>
2) '회사'의 '서비스'이용대금, 기타 '회사'의 '서비스'이용에 관련하여 '회원'이 부담하는 채무를 기일에 이행하지 않는 경우<br/>
3) 다른 사람의 '회사'의 '서비스'이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
4) '회사'를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
3. '회사'가 '회원'자격을 제한, 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 '회사'는 '회원'자격을 상실시킬 수 있습니다.<br/>
4. '회사'가 '회원'자격을 상실시키는 경우에는 '회원'등록을 말소합니다. 이 경우 '회원'에게 이를 통지하고, '회원'등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.<br/>
<br/>
제 10 조 ('회사'의 의무)<br/>
1. '회사'는 '회원'의 가입을 이 약관에 따라 승낙하고 '회원'의 변경, 취소가 없는 한 즉시 '서비스'를 제공하며, '회사'가 제공하는 '서비스' 종류('콘텐츠' 채널 등 포함)는 '서비스'내 별도 마련된 안내 페이지에서 상세 확인 가능합니다.<br/>
2. '회사'는 법령과 본 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.<br/>
3. '회사'는 '회원'이 안전하게 '콘텐츠'를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.<br/>
4. '회사'는 '서비스' 이용과 관련하여 '회원'으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 지체 없이 처리합니다. '회원'이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 그 처리과정 및 결과를 전달합니다.<br/>
<br/>
제 11 조 ('회원'의 의무)<br/>
1. '회원'은 다음 행위를 하여서는 안됩니다.<br/>
1) 신청 또는 변경 시 허위내용의 기재<br/>
2) 타인의 정보도용<br/>
3) '회사'에 게시된 정보의 변경<br/>
4) '회사'가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br/>
5) 임의로 '콘텐츠'를 다운로드 하거나 저장, 무단 배포 등 '회사'와 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
6) '회사' 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
7) 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 '회사'의 사이트에 공개, 게시, 전자우편 전송 또는 기타 방법으로 타인에 유포하는 행위<br/>
8) '회사'의 사전승낙 없이 '서비스'를 이용하여 상품의 판매 등 영업활동이나 타사 서비스의 홍보, 회원 유치를 하는 행위<br/>
9) '회사'가 제공하는 '콘텐츠'를 공공장소 및 영리를 목적으로 하는 영업장, 매장 등에서 공중에 대한 공개 재현용 등 사적인 이용을 벗어난 범위에서의 이용하는 행위<br/>
10) 기타 불법적이거나 부당한 행위<br/>
2. '회원'은 관계법령, 본 약관의 규정, 이용안내 및 '콘텐츠'와 관련하여 공지한 주의사항, '회사'가 통지하는 사항 등을 준수하여야 하며, 기타 '회사'의 업무에 방해되는 행위를 하여서는 안됩니다.<br/>
3. '서비스'를 통해 제공되는 모든 '콘텐츠'는 저작권법의 보호를 받으며 '회원'이 본 조항에서 규정한 사항을 위반할 경우 이용이 중지되고 관련 법령에 의해 처벌받을 수 있습니다.<br/>
4. '회원'은 내용별로 '회사'가 '서비스' 공지사항에 게시하거나 별도로 공지한 이용제한사항을 준수하여야 합니다.<br/>
<br/>
제 3 장 서비스의 이용<br/>
제 12 조 ('서비스'의 제공 등)<br/>
1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.<br/>
1) Q&A 서비스<br/>
2) 게시판 서비스<br/>
3) 구인구직 서비스<br/>
4) 기타 '회사'가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 '회원'에게 제공하는 일체의 서비스<br/>
* 일부 서비스는 회원 구분에 따라 서비스 이용이 제한될 수 있습니다.<br/>
2. 회사는 '서비스'를 일정 범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.<br/>
3. '서비스'는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.<br/>
4. '회사'는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 '서비스'의 제공을 일시적으로 중단할 수 있습니다. 이 경우 '회사'는 제 8 조 ('회원'에 대한 통지)에 정한 방법으로 '회원'에게 통지합니다. 다만, '회사'가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br/>
5. '회사'는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.<br/>
<br/>
제 13 조 ('서비스'의 변경)<br/>
1. '회사'는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 '서비스'를 변경할 수 있습니다.<br/>
2. '서비스'의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.<br/>
3. '회사'는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 '회원'에게 별도의 보상을 하지 않습니다.<br/>
제 14 조 (정보의 제공 및 광고의 게재)<br/>
1. '회사'는 '회원'이 '서비스' 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 '회원'에게 제공할 수 있습니다. 다만, '회원'은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.<br/>
2. 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 '회원'의 사전 동의를 받아서 전송합니다. 다만, '회원'의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.<br/>
3. '회사'는 '서비스'의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 '회원'은 수신거절을 '회사'에게 할 수 있습니다.<br/>
4. '이용자(회원, 비회원 포함)'는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.<br/>
<br/>
제 15 조 ('게시물'의 저작권 및 관리)<br/>
1. '회원'이 '서비스' 내에 게시한 '게시물'의 저작권은 해당 게시물의 저작자에게 귀속됩니다.<br/>
2. '회원'이 '서비스' 내에 게시하는 '게시물'은 검색결과 내지 '서비스' 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, '회원'은 언제든지 '서비스' 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 요청 및 조치를 취할 수 있습니다.<br/>
3. '회사'는 제2항 이외의 방법으로 '회원'의 '게시물'을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 '회원'의 동의를 얻어야 합니다.<br/>
4. '회원'의 '게시물'이 '정보통신망법' 및 '저작권법'등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 '게시물'의 게시중단 및 삭제 등을 요청할 수 있으며, '회사'는 관련법에 따라 조치를 취하여야 합니다.<br/>
5. '회사'는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 '게시물'에 대해 임시조치 등을 취할 수 있습니다.<br/>
<br/>
제 16 조 (저작권의 귀속)<br/>
1. '서비스'에 대한 저작권 및 지적재산권은 '회사'에 귀속됩니다. 단, '회원'의 '게시물' 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.<br/>
2. '회원'은 서비스를 이용함으로써 얻은 정보를 '회사'의 사전 승낙 없이 임의 가공, 판매, 복제, 송신, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.<br/>
<br/>
제 17 조 (계약해제, 해지 등)<br/>
1. '회원'이 서비스 이용계약을 해지하고자 하는 때에는 언제든지 회원 정보 관리 메뉴에서 '회사'가 정한 절차에 따라 회원의 ID를 삭제하고 탈퇴할 수 있습니다.<br/>
2. 회원이 제11조의 규정을 위반한 경우 회사는 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민, 형사상 책임도 물을 수 있습니다.<br/>
3. 회원이 서비스를 이용하는 도중, 연속하여 일(1)년 동안 서비스를 이용하기 위해 회사의 서비스에 log-in한 기록이 없는 경우 회사는 회원의 회원자격을 상실시킬 수 있습니다.<br/>
4. 본 이용 계약이 해지된 경우, 회사는 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 여러분의 모든 데이터는 소멸되며 이는 복구할 수 없습니다. <br/>
5. 프로필 사진 등과 같이 '회원'을 식별할 수 있는 개인정보 일체는 삭제됩니다만,  타인에 의해 담기 혹은 스크랩 등이 되어 재게시 되거나 공용게시판에 등록된 게시물 등은 삭제되지 않으니 사전에 삭제하신 후 탈퇴하시기 바랍니다. <br/>
<br/>
제 18 조 (이용제한 등)<br/>
1. '회사'는 '회원'이 다음 각 호의 사유에 해당하는 경우 사전통지 없이 회원의 서비스 이용 제한 및 적법한 조치를 취할 수 있으며 이용계약을 해지하거나 기간을 정하여 서비스를 중지할 수 있습니다.<br/>
1) 회원 가입신청 또는 변경 시 허위 내용을 등록한 경우<br/>
2) 타인의 개인정보를 도용하여 회원으로 가입한 경우<br/>
3) 타인의 서비스 이용을 방해하거나 그 정보를 도용한 경우<br/>
4) 회사의 운영진, 직원 또는 관계자를 사칭하는 경우<br/>
5) 회사의 사전승낙없이 서비스를 이용하여 영업활동이나 타사 서비스 홍보, 회원 유치 행위를 하는 경우<br/>
6) 회원 ID를 타인과 거래, 공유하거나, 회사 프로그램상의 버그를 악용하여 정상적이지 아니한 방법으로 사이트를 이용하는 경우<br/>
7) 서비스를 통하여 얻은 정보를 회사의 사전승낙 없이 서비스 이용 외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 경우<br/>
8) 회사 또는 제3자의 저작권 등 기타 지적재산권을 침해하는 내용을 전송, 게시, 기타의 방법으로 타인에게 유포하는 경우<br/>
9) 공공질서 및 미풍양속에 위반되는 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 기타의 방법으로 타인에게 유포하는 경우<br/>
10) 서비스에 위해를 가하거나 고의로 방해한 경우<br/>
11) 다른 회원을 희롱, 위협하거나 특정 이용자에게 지속적으로 고통, 불편을 주는 행위를 하는 경우<br/>
12) 범죄와 관련이 있다고 객관적으로 판단되는 행위를 하는 경우<br/>
13) 본 서비스를 이용함에 있어 본 약관 및 기타 회사가 정한 정책 또는 운영 규칙을 위반하는 경우<br/>
14) 기타 관련 법령에 위배하거나 회사가 서비스를 운영하기에 현저히 부적합한 행위를 하는 경우<br/>
2. '회원'은 전항의 귀책사유로 인하여 회사나 다른 회원에게 입힌 손해를 배상할 책임이 있습니다.<br/>
<br/>
제 19 조 (책임제한)<br/>
1. '회사'는 천재지변 또는 이에 준하는 불가항력으로 인하여 '서비스'를 제공할 수 없는 경우에는 '서비스' 제공에 관한 책임이 면제됩니다.<br/>
2. '회사'는 '회원'의 귀책사유로 인한 '서비스' 이용의 장애에 대하여는 책임을 지지 않습니다.<br/>
3. '회사'는 '회원'이 '서비스'와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br/>
4. '회사'는 '회원' 간 또는 '회원'과 제3자 상호간에 '서비스'를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.<br/>
5. '회사'는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.<br/>
<br/>
제 20 조 (준거법 및 재판관할)<br/>
1. '회사'와 '회원' 간 제기된 소송은 대한민국법을 준거법으로 합니다.<br/>
2. '회사'와 '회원'간 발생한 분쟁에 관한 소송은 제소 당시의 '회원'의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 '회원'의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.<br/>
3. 해외에 주소나 거소가 있는 '회원'의 경우 '회사'와 '회원'간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.<br/>
<br/>
[부칙]<br/>
<br/>
1. (시행일) 본 약관은 2021년 12월 1일부터 시행합니다.
</div>
  );
}

export default InformationContract;