import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { StyledComponent } from 'styled-components'
import Textarea from 'react-textarea-autosize';
import COLOR from './../../_design/color'
import TYPOGRAPY from './../../_design/typography'
import Text from '../Text';
import { log, LogLevel } from '../../../utils/LogUtil';
import Icon from '../Icon';

type Size =
"S" | 
"M" | 
"L" | 
"XL"

const SIZE = {
  S: {
    size: 12,
    gap: 0,
  },
  M: {
    size: 16,
    gap: 0,
  },
  L: {
    size: 28,
    gap: 8,
  },
  XL: {
    size: 40,
    gap: 12,
  },
}

interface WrapperProps {
  size:Size
}
const Wrapper: StyledComponent<'div', React.FC, WrapperProps> = styled.div<WrapperProps>`
  width: ${props => (SIZE[props.size].size *5 + SIZE[props.size].gap *4)}px; 
  height: ${props => SIZE[props.size].size}px;

  .star-score-container {
    width: 100%;
    height: 100%;
    padding: 0px;
    outline: none;
    outline-style: none;
    background: none;
    border: none;
    position: relative;

    .star-score-bg{
      width: 100%;
    }

    .star-score-inner{
      height: 100%;
      width: 100%;
      align-items: center;
      white-space: nowrap;

      .star-score-star {
        height: 100%;
        width: ${props => SIZE[props.size].size}px;
        display: inline-block;
        vertical-align:top;

      }

      .star-score-gap {
        width: ${props => SIZE[props.size].gap}px;
        display: inline-block;
        vertical-align:top;
      }
    }


  }
  
`

export interface Props {
  size?: Size;
  score?: number;
  onScoreChange?: (number) => void;
  color?: string;
  debug?: boolean;
}
const StarScorer: React.FC<Props> = ({
  size = "M",
  score = 0,
  onScoreChange,
  color = "Point",
  debug,
}) => {

  const containerEl = useRef(null);
  const initialized = useRef<boolean>(false);
  const touched = useRef<boolean>(false);
  const containerClientX = useRef<number>(0);

  const [x, setX] = useState<number>(0);

  useEffect(() => {
    log(LogLevel.UI_EVENT, "StarScorer:userEffect []", containerEl.current);
    if(onScoreChange && !initialized.current && containerEl.current){
      initialized.current = true;

      containerEl.current.addEventListener("touchstart", handleTouchStart, false);
      containerEl.current.addEventListener("touchmove", handleTouchMove, false);
      containerEl.current.addEventListener("touchend", handleMoveFinish, false);
      containerEl.current.addEventListener("touchleave", handleMoveFinish, false);
      containerEl.current.addEventListener("mousedown", handleMouseDown, false);
      containerEl.current.addEventListener("mousemove", handleMouseMove, false);
      containerEl.current.addEventListener("mouseup", handleMoveFinish, false);
      containerEl.current.addEventListener("mouseleave", handleMoveFinish, false);
    }

    return () => {
      log(LogLevel.UI_EVENT, "StarScorer:userEffect [] close", containerEl.current);
      if(initialized.current && containerEl.current){
        containerEl.current.removeEventListener("touchstart", handleTouchStart, false);
        containerEl.current.removeEventListener("touchmove", handleTouchMove, false);
        containerEl.current.removeEventListener("touchend", handleMoveFinish, false);
        containerEl.current.removeEventListener("touchleave", handleMoveFinish, false);
        containerEl.current.removeEventListener("mousedown", handleMouseDown, false);
        containerEl.current.removeEventListener("mousemove", handleMouseMove, false);
        containerEl.current.removeEventListener("mouseup", handleMoveFinish, false);
        containerEl.current.removeEventListener("mouseleave", handleMoveFinish, false);
      }
    }
  },[])

  const handleTouchStart = (evt) => {
    evt.preventDefault();
    // log(LogLevel.UI_EVENT, "StarScorer: handleTouchStart", evt.touches[0].clientX);
    touched.current = true;
    containerClientX.current = containerEl.current.getBoundingClientRect().x;
    if(debug) setX(evt.touches[0].clientX)
    caculateRate(evt.touches[0].clientX);
  }

  const handleTouchMove = (evt) => {
    if(touched){
      evt.preventDefault();
      // log(LogLevel.UI_EVENT, "StarScorer: handleTouchMove", evt.touches[0].clientX);
      if(debug) setX(evt.touches[0].clientX)
      caculateRate(evt.touches[0].clientX);
    }
  }

  const handleMouseDown = (evt) => {
    evt.preventDefault();
    // log(LogLevel.UI_EVENT, "StarScorer: handleMouseDown", evt.clientX, evt.clientY);
    touched.current = true;
    containerClientX.current = containerEl.current.getBoundingClientRect().x;
    if(debug) setX(evt.clientX)
    caculateRate(evt.clientX);
  }

  const handleMouseMove = (evt) => {
    if(touched.current){
      evt.preventDefault();
      caculateRate(evt.clientX);
      if(debug) setX(evt.clientX)
      // log(LogLevel.UI_EVENT, "StarScorer: handleMouseMove", evt, evt.clientX, containerEl.current.clientWidth, containerEl.current.getBoundingClientRect(),);
    }
  }

  const caculateRate = (x: number) => {
    x = x - containerClientX.current;
    if( x<0 )
      x = 0;
    let rate = Math.floor(x * 100 / containerEl.current.clientWidth / 20) + 1;

    if(rate > 5)
      rate = 5

    if(rate != score && onScoreChange){
      log(LogLevel.UI_EVENT, "StarScorer: onScoreChange", rate, score, color, COLOR[color]);
      onScoreChange(rate);
    }
  }

  const handleMoveFinish = (evt) => {
    if(touched.current){
      evt.preventDefault();
      log(LogLevel.UI_EVENT, "StarScorer: handleMoveFinish");
      touched.current = false;
    }
  }

  return (
    <Wrapper size={size}>
      <div ref={containerEl} className="star-score-container" >
        <div className="star-score-bg star-score-inner">
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={score>=1?COLOR[color]:COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={score>=2?COLOR[color]:COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={score>=3?COLOR[color]:COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={score>=4?COLOR[color]:COLOR.Gray6}/>
          </div>
          <div className="star-score-gap"/>
          <div className="star-score-star">
            <Icon height={SIZE[size].size} width={SIZE[size].size} name="Star" fill={score>=5?COLOR[color]:COLOR.Gray6}/>
          </div>
        </div>
      </div>
      {debug &&
        <Text type="H2" color="Black">{x}</Text>
      }
    </Wrapper>

  )
}

export default StarScorer
