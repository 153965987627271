import React from "react";
import styled from "styled-components";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

const KeywordWrapper = styled.div`
  display: inline-block;
  padding: 8px 16px;
  border-radius: 100px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  cursor: pointer;
`;

interface KeywordProps {
  keyword: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const Keyword: React.FC<KeywordProps> = ({ keyword, onClick }) => {
  return (
    <KeywordWrapper onClick={onClick}>
      <Text textType="Body2Medium" color={COLOR_SYSTEM.get("Gray")[700]}>
        {keyword}
      </Text>
    </KeywordWrapper>
  );
};

export default Keyword;
