import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonButton,
  IonModal,
  IonIcon,
  IonInput,
  IonList,
  IonItem,
  IonToast,
} from "@ionic/react";
import { fetchAPI } from "./../utils/APIUtil";
import * as API from "./../API.json";
import Skeleton from "react-loading-skeleton";
import smileIcon from "./../assets/icon/smile.svg";
import { KeywordType, Medicine, Ingredient } from "../models/Model.Medicine";
import { compose } from "redux";
import { connect, connectAdvanced } from "react-redux";
import { RootState, actions } from "../store";
import { log, LogLevel } from "../utils/LogUtil";

type Props = {};

type State = {
  toastMessage: string;
  toastDuration: number;
};

class ToastPopup extends Component<Props, State> {
  state = {
    toastMessage: "",
    toastDuration: 2000,
  };

  show = (message: string, duration: number = 1900) => {
    log(LogLevel.UI_ACTION, "ToastPopup:show", duration);
    this.setState({ toastMessage: "" });
    setTimeout(() => {
      this.setState({ toastMessage: message, toastDuration: duration });
    }, 100);
  };

  render() {
    return (
      <IonToast
        style={{ zIndex: "10000" }}
        isOpen={this.state.toastMessage && true}
        onDidDismiss={() => this.setState(() => ({ toastMessage: "" }))}
        message={this.state.toastMessage}
        duration={this.state.toastDuration}
      />
    );
  }
}

export default ToastPopup;
