import React, { memo } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import styled from "styled-components";
import { openSystemBrowser } from "../../../utils/CommonUtil";

export interface MedicineIngredient {
  type: number;
  id: string;
  textKor: string;
  textEng: string;
  volume: string;
  linkKor: string | null;
  linkEng: string | null;
}

interface IngredientListItemProps {
  ingredient: MedicineIngredient;
}

const FlexColLi = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > hr {
    width: 100%;
    height: 0;
    border: 0.5px solid ${COLOR_SYSTEM.get("Gray")[50]};
  }
`;

const FlexRowLi = styled.li`
  display: flex;
  gap: 12px;
  transform: translateZ(0);
`;

const IngredientListItem: React.FC<IngredientListItemProps> = ({ ingredient }) => {
  const handleClickLink = (url: string) => () => {
    openSystemBrowser(url);
  };

  return (
    <FlexColLi>
      <div>
        <Text textType="Body1SemiBold" color={COLOR_SYSTEM.get("Gray")[800]}>
          {ingredient.textKor}({ingredient.textEng}): {ingredient.volume}
        </Text>
      </div>
      <FlexRowLi>
        {ingredient.linkKor && (
          <Button
            color="Secondary"
            size="Small"
            type="Text"
            variant="Tinted"
            onClick={handleClickLink(ingredient.linkKor)}
          >
            국내허가사항
          </Button>
        )}
        {ingredient.linkEng && (
          <Button
            color="Secondary"
            size="Small"
            type="Text"
            variant="Tinted"
            onClick={handleClickLink(ingredient.linkEng)}
          >
            해외자료
          </Button>
        )}
      </FlexRowLi>
      <hr />
    </FlexColLi>
  );
};

export default memo(IngredientListItem);
