import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
} from "@ionic/react";
import "./Admin.scss";
import { log, LogLevel } from "../utils/LogUtil";

import { setGlobal, GlobalKey, getGlobal } from "../utils/GlobalUtil";
import { BoardType } from "../store/board/types";
import {
  UserLevel,
  UserWorkType,
  UserInfo,
  UserWorkTypeName,
  UserLevelName,
  AllInOnePartnerInfo,
  UserType,
  UserTypeName,
} from "../models/Model.User";
import { fetchAPI } from "../utils/APIUtil";
import AllInOnePartnerManageProfile from "./component/allinone/AllInOnePartnerManageProfile";
import ProfileImage from "../components/ProfileImage";
import { threadId } from "worker_threads";
import AllInOnePartnerSelect from "./component/allinone/AllInOnePartnerSelect";
import ToggleSwitch from "../component/atom/ToggleSwitch";
import CCSelect from "./component/cc/CCSelect";
import CCManageProfile from "./component/cc/CCManageProfile";
import CCUserSelect from "./component/cc/CCSelect";
import CCCreateManager from "./component/cc/CCCreateManager";


type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const CcUserManage: React.FC<Props> = (props) => {
  const [selected, setSelected] = useState<AllInOnePartnerInfo | null>(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [selectedService, setSelectedService] = useState<UserType>(UserType.BIZ_CC);
  const listRef = useRef(null);

  useEffect(() => {
    props.loadFilePath();
  }, [props]);

  const onSelect = (partner: AllInOnePartnerInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", partner);
    setSelected(partner);
  };

  const onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", selected);
    if (listRef.current) listRef.current.refresh();
    setSelected(null);
  };

  const onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", selected);
    setSelected(null);
  };

  log(LogLevel.UI_LIFECYCLE, "AllInOnePartnerManage.render", props);

  return (
    <>
      <IonHeader class="admin-header" no-border>
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <IonText slot="middle">{UserTypeName[selectedService]}관리</IonText>
      </IonHeader>
      <IonContent>
        <div className="admin-main-container">
          <div className="admin-container">
            <CCUserSelect
              key={refreshKey}
              onSelect={onSelect}
              setSelectedService={setSelectedService}
              selectedService={selectedService}
              ref={listRef}
            />
          </div>
          <div id="admin-sub-conainer" className="admin-container">
            <CCCreateManager 
              onUserCreated={() => setRefreshKey(prevKey => prevKey + 1)} 
              selectedService={selectedService}
            />
          </div>
        </div>
      </IonContent>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CcUserManage)
);
