import styled from "styled-components";
import { typography } from "../../design/typography";
import { COLOR_SYSTEM } from "../../design/design-system";
import { TopAppBarProps } from "./TopAppBar";

interface TopAppBarWrapperProps extends TopAppBarProps {}

const TopAppBarWrapper = styled.div<TopAppBarWrapperProps>`
  box-sizing: border-box;
  padding: ${({ titleType }) =>
    titleType === "Search" ? "20px 8px" : "20px 8px 20px 20px"};
  position: relative;
  height: ${({ titleType }) => {
    switch (titleType) {
      case "H1":
        return "60px";
      case "H4":
      case "OFF":
      case "Search":
      default:
        return "48px";
    }
  }};
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : COLOR_SYSTEM.get("Gray")[0]};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-between"};

  .title {
    ${({ titleType }) => {
      if (titleType === "H4") {
        return `
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      `;
      }
    }}
  }
`;

const SearchBar = styled.form`
  flex: 1;
  display: flex;
  align-items: center;

  input {
    padding: 7px;
    flex: 1;
    border: none;
    outline: none;
    ${typography.get("InputLarge")};

    &::placeholder {
      color: ${COLOR_SYSTEM.get("Gray")[200]};
    }
  }

  .clear__button {
    margin-right: 16px;
  }
`;

const ButtonList = styled.ul<TopAppBarWrapperProps>`
  padding-left: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-x: auto;

  & > * + * {
    margin-left: 10px;
  }
`;

export { TopAppBarWrapper, SearchBar, ButtonList };
