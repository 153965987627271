import React, { useCallback, useEffect, useState } from "react";
import {
  Absolute,
  Fixed,
  Flex,
  Header,
  Relative,
  Static,
} from "../../atoms/Layout";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";

import styled from "styled-components";
import Button from "../../atoms/Button";
import {
  BoardContentQuiz,
  BoardContentQuizResult,
  BoardContentSummaryGroup,
  convertToBoardContentQuizResult,
  DailyQuiz,
} from "../../../models/Model.Board";
import Skeleton from "react-loading-skeleton";
import ReactHtmlParser from "react-html-parser";
import { LogLevel, log } from "../../../utils/LogUtil";
import { getSummaryHtml } from "../BoardSummary";
import Body from "../../cell/Body";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import Spinner from "../../atoms/Spinner";
import BoardQuizView from "../BoardQuizView";
import { setIn } from "immutable";
import { getDateStringFromToday } from "../../../utils/TimeUtil";
import ProgressBar from "../../atoms/ProgressBar";

const QustionWrapper = styled.div<{ index: number }>`
  padding: 16px;
  background: ${COLOR_SYSTEM.get("Gray")[10]};
  border-radius: 12px;
  transform: translateZ(0);
  overflow: show;

  * {
    opacity: ${({ index }) => (index > 0 ? 1 - 0.3 * index : 1)};
  }
`;

export interface DailyQuizTestProps {
  dailyQuiz: DailyQuiz;
  index: number;
  solve: DailyQuiz;
  onDone: (dailyQuiz: DailyQuiz) => void;
  onProgress: () => void;
  onSolve: (result: BoardContentQuizResult) => void;
  onGoBack: () => void;
  onQuizReference: (quiz: BoardContentQuiz) => void;
}

const today = getDateStringFromToday();
const DailyQuizTest: React.FC<DailyQuizTestProps> = ({
  dailyQuiz,
  solve,
  index,
  onDone,
  onProgress,
  onSolve,
  onGoBack,
  onQuizReference,
}) => {
  const onQuizSolve = useCallback(
    (quiz: BoardContentQuiz) => {
      let newResult: BoardContentQuizResult = convertToBoardContentQuizResult(
        quiz
      );
      newResult.dailyQuizId = dailyQuiz.id;

      // let newSolve = { ...solve };

      // newSolve.results.push(newResult);
      // if (newResult.score > 0) newSolve.score += newResult.score;

      onSolve(newResult);
      log(
        LogLevel.UI_ACTION,
        "DailyQuizTest:onQuizSolve",
        quiz,
        newResult
        // newSolve.results
      );
    },
    [solve, dailyQuiz]
  );

  const onNext = useCallback(() => {
    log(LogLevel.UI_ACTION, "DailyQuizTest:onNext", index, solve);
    if (index < dailyQuiz.quizzes.length - 1) onProgress();
    else {
      onDone(solve);
    }
  }, [index, dailyQuiz, solve, onDone]);

  if (!dailyQuiz || !dailyQuiz.quizzes || !dailyQuiz.quizzes.length) {
    return (
      <Flex alignItems="center">
        <Spinner />
      </Flex>
    );
  }

  log(
    LogLevel.UI_LIFECYCLE,
    "DailyQuizTest:render",
    index,
    solve,
    solve.results[index]
  );

  return (
    <>
      <Header>
        <TopAppBar
          title={
            "오늘의 퀴즈" +
            (dailyQuiz.date == today ? "" : ` (${dailyQuiz.date})`)
          }
          titleType="H4"
          leftButtons={[
            <Button
              color="Tertiary"
              icon="X"
              onClick={onGoBack}
              size="Medium"
              type="Icon"
              variant="Ghost"
            />,
          ]}
          rightButtons={[
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[700]}>
              {index + 1} / {dailyQuiz.quizzes.length}
            </Text>,
            <div style={{ width: "20px"}} />,
          ]}
        />
        <ProgressBar
          max = {dailyQuiz.quizzes.length}
          value = {index + 1} style={{padding: "0px 20px", marginTop: "8px"}}/>
          
      </Header>
      <Body scrollable={true} headerHeight={72}>
        <BoardQuizView
          quiz={dailyQuiz.quizzes[index]}
          onClickReference={() => onQuizReference(dailyQuiz.quizzes[index])}
          onDone={onQuizSolve}
          onNext={onNext}
          quizResult={solve.results[index]}
        />
      </Body>
    </>
  );
};

export default DailyQuizTest;
