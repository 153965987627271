import React, { Component } from "react";
import "./RegisterStage1.css";
import "./RegisterStage.css";
import { withRouter, RouteComponentProps } from "react-router";
import { IonButton, IonInput } from "@ionic/react";
import { fetchAPI } from "../../utils/APIUtil";
import * as API from "./../../API.json";
import { TARGET } from "./../../config.json";
import { LogLevel, log } from "../../utils/LogUtil";
import { UserInfo } from "../../models/Model.User";
import {
  setGlobal,
  GlobalKey,
  getGlobal,
  clearGlobal,
} from "../../utils/GlobalUtil";
import kakaoIcon from "./../../assets/icon/question_small_yellow_mobile_3X.png";
import { UIPopupType } from "../../store/ui/types";
import { RootState } from "../../store";
import { connect } from "react-redux";

type Props = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    onDone: (data: UserInfo) => void;
    onBack: () => void;
    user: UserInfo;
  };

type States = {
  name: string;
  phone: string;
};
const windowAny: any = window;

class RegisterStage1 extends Component<Props, States> {
  constructor(props: Props) {
    super(props);
    let name = "";
    if (this.props.user && this.props.user.name) name = this.props.user.name;
    let phone = "";
    if (this.props.user && this.props.user.phone) phone = this.props.user.phone;
    this.state = {
      name,
      phone,
    };
  }

  componentDidMount() {
    windowAny.IMP.init("imp68909306");
  }

  onClick = () => {
    if (!this.state.name || !this.state.name.trim()) {
      this.props.toast.show("성함을 입력해주세요.");
      return;
    }
    if (
      !this.state.phone ||
      !this.state.phone.trim() ||
      this.state.phone.length < 10
    ) {
      this.props.toast.show("전화번호를 입력해주세요.");
      return;
    }

    setGlobal(GlobalKey.TEMP + "name", this.state.name, true);
    setGlobal(GlobalKey.TEMP + "phone", this.state.phone, true);

    // MUST NOT COMMENTED!!!!!!!!!!!
    if (TARGET == "local")
      this.props.onDone({
        name: this.state.name,
        phone: this.state.phone,
        birthGender: "791",
        birthday: "19790418",
        gender: "1",
        uniqueKey: "==result.data.uniqueKey",
      });
    else
      windowAny.IMP.certification(
        {
          merchant_uid: "merchant_" + new Date().getTime(), //본인인증과 연관된 가맹점 내부 주문번호가 있다면 넘겨주세요,
          name: this.state.name,
          phone: this.state.phone,
        },
        this.onCertificated
      );
  };

  onCertificated = async (rsp) => {
    //console.log(rsp)

    if (rsp.success) {
      // 인증성공
      //console.log(rsp.imp_uid);
      let result = await fetchAPI(
        API.USER_CERTIFICATE,
        "",
        { id: rsp.imp_uid },
        null
      );

      if (!result.error) {
        log(LogLevel.UI_DATA_LOAD, "onCertificated", result);
        let name = this.state.name
          ? this.state.name
          : getGlobal(GlobalKey.TEMP + "name", true);
        let phoneNumber = this.state.phone
          ? this.state.phone
          : getGlobal(GlobalKey.TEMP + "phone", true);
        let birthGender = result.data.birthGender;

        // log(LogLevel.UI_DATA_LOAD, name, phoneNumber, birthdayGender, birth, birthday, result.data.birth,  birth.toISOString(), birth.toLocaleTimeString(), birth.toLocaleDateString()  )
        this.props.onDone({
          name,
          phone: phoneNumber,
          birthGender,
          birthday: result.data.birthday,
          gender: result.data.gender,
          uniqueKey: result.data.uniqueKey,
        });

        clearGlobal(GlobalKey.TEMP + "name");
        clearGlobal(GlobalKey.TEMP + "phone");
      }
    } else {
      // 인증취소 또는 인증실패
      var msg = "인증에 실패하였습니다.";
      msg += "에러내용 : " + rsp.error_msg;

      alert(msg);
      //console.log("failed")
    }
  };

  onNameChange = (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    this.setState({ name: e.detail.value });
  };

  onPhoneChange = (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    this.setState({ phone: e.detail.value });
  };

  onBack = () => {
    this.props.onBack();
  };

  onChat = async () => {
    let os = getGlobal(GlobalKey.OS);
    if (!os || os == "browser") {
      try {
        windowAny.Kakao.init("0d4139a6dc131b05b8109f629d7cc3f7");
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
      try {
        windowAny.Kakao.PlusFriend.chat({
          plusFriendId: "_Vxjhxgj", // 플러스친구 홈 URL에 명시된 id로 설정합니다.
        });
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    } else {
      let plusFriendTemplate = {
        plusFriendId: "_Vxjhxgj",
      };
      try {
        await windowAny.KakaoCordovaSDK.chatPlusFriend(plusFriendTemplate);
      } catch (e) {
        log(LogLevel.UI_EXCEPTION, e);
      }
    }
  };

  render() {
    return (
      <div className="register1-container">
        <div className="register-progress-container">
          <div className="register-progress-done">1</div>
          <div className="register-progress-done">2</div>
          <div className="register-progress-undone">3</div>
          <div className="register-progress-undone">4</div>
          <div className="register-progress-undone">5</div>
        </div>
        <div className="register1-service-description">
          약사 면허를 제대로 인증하기 위해 가입절차가 다소 복잡한 점 미리 양해
          부탁드립니다.
        </div>
        <div className="register-chat-guide">
          <img
            className="register-chat-kakao"
            src={kakaoIcon}
            onClick={this.onChat}
          />
        </div>
        <div className="register1-body-container">
          <div className="register-title">실명인증</div>
          <div className="register1-item-container">
            <div className="register1-item-name">이&nbsp;&nbsp;름</div>
            <IonInput
              class="register1-item-input"
              value={this.state.name}
              onIonChange={this.onNameChange}
            />
          </div>
          <div className="register1-item-container">
            <div className="register1-item-name">연락처</div>
            <IonInput
              class="register1-item-input"
              value={this.state.phone}
              onIonChange={this.onPhoneChange}
              type="tel"
            />
          </div>
          <div className="register1-button-description">
            <span className="register1-button-description-highlight">
              본인 명의 휴대폰
            </span>
            으로만 가입이 가능합니다.
          </div>
          <IonButton class="register1-button" onClick={this.onClick}>
            휴대폰 실명 인증
          </IonButton>
        </div>
        <div className="common-flex-grow" />
        <div className="register-navigate-container">
          <IonButton color="register-navigate-inactive" onClick={this.onBack}>
            &lt; 이전 단계로
          </IonButton>
          <div className="register-navigate-space" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  toast: state.ui.popups[UIPopupType.TOAST_POPUP],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterStage1);
