import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import JobPostBizDetailTemplate from "../../components/templates/JobPostBizDetail";
import { actions, RootState } from "../../store";
import queryString from "query-string";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import { JobListType } from "../../store/jobpost/types";
import DownloadUtil from "../../utils/DownloadUtil";
import { Attachment } from "../../models/Model.Board";
import { OptionType } from "../../types/jobpost";
import { JobOfferInfo, JobOfferStatus } from "../../models/Model.JobPost";
import * as API from "./../../API.json";
import { MY_ADDRESS } from "./../../config.json";
import { UIServiceType } from "../../store/ui/types";
import {
  CompanyType,
  CompanyTypeName,
  UserWorkType,
} from "../../models/Model.User";
import Spinner from "../../components/atoms/Spinner";
import { log, LogLevel } from "../../utils/LogUtil";
import AnalyticsUtil from "../../utils/AnalyticsUtil";

interface MatchParams {
  postId: string;
}

type Props = RouteComponentProps<MatchParams> & {
  offer?: JobOfferInfo;
};

const JobPostBizDetail: React.FC<Props> = ({ history, location, offer }) => {
  const dispatch = useDispatch();
  const [viewCnt, setViewCnt] = useState(0);
  const [isScrapped, setIsScrapped] = useState(false);
  const [optionBottomSheet, setOptionBottomSheet] = useState(false);

  const backKeyControl = useSelector(
    (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
  );
  const me = useSelector((state: RootState) => state.user.me);
  const isOwner = useMemo(() => me.workType === UserWorkType.PHARMACY_OWNER, [
    me,
  ]);

  const id = useMemo(
    () => (offer && offer.id) || queryString.parse(location.search).id,
    [location.search, offer]
  );
  const post = useSelector(
    (state: RootState) => state.jobpost.offers[id as string]
  );

  const fetchView = useCallback(async () => {
    if (!post || !me) return;

    try {
      const res = await fetchAPI(
        API.JOBPOST_VIEW,
        id,
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      if (!res.error) {
        dispatch(
          actions.jobpost.updateOffer({
            id: Number(id),
            viewCnt: post.viewCnt + 1,
          })
        );
        setViewCnt(post.viewCnt + 1);
      }
    } catch (err) {
      console.error(err);
    }
  }, [id, post, me]);

  const loadPost = useCallback(async () => {
    try {
      if (id) {
        await fetchAPI(
          API.JOBPOST_VIEW,
          id,
          null,
          null,
          getGlobal(GlobalKey.TOKEN)
        );
        dispatch(actions.jobpost.loadOffer({ chatlist: true }, Number(id)));
      }
    } catch (err) {
      console.error(err);
      alert("공고를 불러오는데 실패했습니다.");
    }
  }, [id]);

  const handleShareClick = useCallback(() => {
    if (navigator.share) {
      navigator
        .share({
          title: "title",
          text: "Hello world",
          url: "https://www.naver.com",
        })
        .then(() => {
          alert("공유하기 성공");
        });
    } else {
      alert("No share API");
    }
  }, [post]);

  // 스크랩
  const fetchScrap = useCallback(async () => {
    try {
      await fetchAPI(
        API.JOBPOST_LIKE,
        post.id.toString(),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      dispatch(
        actions.jobpost.updateOffer({
          id: post.id,
          liked: true,
          likeCnt: post.liked + 1,
        })
      );
      dispatch(
        actions.jobpost.reloadList(
          null,
          JobListType.OFFER | JobListType.MY_LIKE
        )
      );
    } catch (err) {
      console.error(err);
    }
  }, [post]);

  const fetchUnscrap = useCallback(async () => {
    try {
      await fetchAPI(
        API.JOBPOST_LIKE_CANCEL,
        post.id.toString(),
        null,
        null,
        getGlobal(GlobalKey.TOKEN)
      );
      dispatch(
        actions.jobpost.updateOffer({
          id: post.id,
          liked: false,
          likeCnt: post.liked - 1,
        })
      );
      dispatch(
        actions.jobpost.reloadList(
          null,
          JobListType.OFFER | JobListType.MY_LIKE
        )
      );
    } catch (err) {
      console.error(err);
    }
  }, [post]);

  const handleToggleScrap = useCallback(async () => {
    isScrapped ? await fetchUnscrap() : await fetchScrap();
    setIsScrapped(!isScrapped);
  }, [isScrapped]);

  // 파일 다운로드
  const handleDownloadClick = useCallback(
    (file: Attachment) => () => {
      DownloadUtil.downloadDocument(file.url, file.name);
    },
    []
  );

  /**
   * 상세페이지의 token 값 받아오는 함수
   */
  const fetchGetOfferJWT = useCallback(async () => {
    const res = await fetchAPI(
      API.ADMIN_GET_OFFER_JWT,
      id,
      null,
      null,
      getGlobal(GlobalKey.TOKEN)
    );
    return `${MY_ADDRESS}/jobpost/view/${res.data}`;
  }, [id]);

  const handleGoBackClick = useCallback(() => {
    history.goBack();
  }, []);

  const handleOpenBottomSheet = useCallback(() => {
    setOptionBottomSheet(true);
  }, [optionBottomSheet]);

  const handleCloseBottomSheet = useCallback(() => {
    setOptionBottomSheet(false);
  }, [optionBottomSheet]);

  const fetchFinishPost = async (id: number) => {
    try {
      const res = await fetchAPI(
        API.JOBPOST_OFFER_UPDATE,
        "",
        null,
        { id: id, status: JobOfferStatus.ENDED },
        getGlobal(GlobalKey.TOKEN)
      );

      if (!res.error) {
        alert("공고 종료에 성공했습니다.");
        dispatch(
          actions.jobpost.reloadList(null, JobListType.OFFER | JobListType.BIZ)
        );
        history.goBack();
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleSelectOption = useCallback(
    (optionType: OptionType) => async () => {
      switch (optionType) {
        case "finish": {
          try {
            await fetchFinishPost(post.id);
          } catch (err) {
            alert("공고종료에 실패했습니다!");
          }
          return;
        }
        case "URL":
          const url = await fetchGetOfferJWT();
          prompt("상세페이지 URL", url);
          return;
        default:
          return;
      }
    },
    [post]
  );

  const handleLinkClick = (type: any, url?: string) => () => {
    log(LogLevel.UI_ACTION, "JobPostBizDetail: handleLinkClick ", type, url);

    switch (type) {
      case "homepage":
        if (url && url.startsWith("www.")) url = "https://" + url;

        window.open(
          url,
          getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
        );
        break;

      case "kakaoMap": {
        let myPosition;
        if (me && me.homePosition) myPosition = me.homePosition;
        let position;
        if (post && post.companyPosition) position = post.companyPosition;

        log(
          LogLevel.UI_ACTION,
          "JobPostDetail:showMap: ",
          myPosition,
          position
        );

        if (
          !myPosition ||
          !myPosition.x ||
          !myPosition.y ||
          !position ||
          !position.x ||
          !position.y
        )
          return;

        if (getGlobal(GlobalKey.OS) === "browser") {
          // history.push(url.substring(3));
          // window.open(`https://map.kakao.com/link/to/우리집,126.9522394,37.4640070`,"_system");
          window.open(
            `https://map.kakao.com/link/to/${encodeURIComponent(
              post.companyName
            )},${position.y},${position.x}`,
            "_blank"
          );
        } else {
          // window.open(`nmap://route/public?slat=37.4640070&slng=126.9522394&sname=%EC%84%9C%EC%9A%B8%EB%8C%80%ED%95%99%EA%B5%90&dlat=37.5209436&dlng=127.1230074&dname=%EC%98%AC%EB%A6%BC%ED%94%BD%EA%B3%B5%EC%9B%90&appname=com.onjourney.pharmacycafe`,"_system");
          window.open(
            `nmap://route/public?slat=${myPosition.y}&slng=${
              myPosition.x
            }&sname=${encodeURIComponent("우리집")}&dlat=${position.y}&dlng=${
              position.x
            }&dname=${encodeURIComponent(
              post.companyName
            )}&appname=com.onjourney.pharmacycafe`,
            "_system"
          );
        }
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "JOBPOST_PHARMACIST_MAIN_PATH_VIEW",
          "구인구직_공고_경로_이동",
          {}
        );

        log(LogLevel.UI_ACTION, "handleLinkClick:kakaoMap: ", position);
      }

      case "naverMap":
        {
          let position;
          if (post && post.companyPosition) position = post.companyPosition;
          if (!position || !position.x || !position.y) return;

          if (getGlobal(GlobalKey.OS) === "browser") {
            // history.push(url.substring(3));

            window.open(
              `http://map.naver.com/?dlevel=13&lat=${position.y}&lng=${position.x}`,
              "_blank"
            );
            // window.open(`https://map.kakao.com/link/map/${offer.pharmacy.name},${pharmacyPosition.x},${pharmacyPosition.y}`,"_blank");
          } else {
            // window.open(`nmap://route/public?slat=37.4640070&slng=126.9522394&sname=%EC%84%9C%EC%9A%B8%EB%8C%80%ED%95%99%EA%B5%90&dlat=37.5209436&dlng=127.1230074&dname=%EC%98%AC%EB%A6%BC%ED%94%BD%EA%B3%B5%EC%9B%90&appname=com.onjourney.pharmacycafe`,"_system");
            window.open(
              `nmap://place?lat=${position.y}&lng=${
                position.x
              }&name=${encodeURIComponent(
                post.companyName
              )}&appname=com.onjourney.pharmacycafe`,
              "_system"
            );
          }
        }
        break;
      default:
        break;
    }
  };

  const setCompanyTypeTagColor = useCallback(() => {
    switch (post.companyType) {
      case CompanyType.HOSPITAL:
        return "Blue";
      case CompanyType.PHARMACEUTICAL_COMPANY:
        return "Green";
      case CompanyType.DISTRIBUTOR:
        return "Brown";
      case CompanyType.ETC:
      default:
        return "Gray";
    }
  }, [post]);

  useEffect(() => {
    if (post) {
      setIsScrapped(post.liked);
      fetchView();

      backKeyControl.setListener(() => {
        handleGoBackClick();
        return true;
      });
    }
  }, [post]);

  useEffect(() => {
    loadPost();
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "JOBPOST_OFFER_VIEW",
      "구인구직_법인공고_진입",
      {
        id,
      }
    );
  }, [id]);

  if (!post || (post.loading && !post.title)) return <Spinner />;

  return (
    <JobPostBizDetailTemplate
      me={me}
      isOwner={isOwner}
      post={post}
      viewCnt={viewCnt}
      isScrapped={isScrapped}
      optionBottomSheet={optionBottomSheet}
      handleToggleScrap={handleToggleScrap}
      handleDownloadClick={handleDownloadClick}
      handleGoBackClick={handleGoBackClick}
      handleShareClick={handleShareClick}
      handleOpenBottomSheet={handleOpenBottomSheet}
      handleCloseBottomSheet={handleCloseBottomSheet}
      handleSelectOption={handleSelectOption}
      handleLinkClick={handleLinkClick}
      setCompanyTypeTagColor={setCompanyTypeTagColor}
      isPrivate={true}
    />
  );
};

export default withRouter(JobPostBizDetail);
