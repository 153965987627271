// import { BoardContentSummary } from "../models/Model.Board";
// type ParserState =
//   | "START"
//   | "OBJECT"
//   | "ARRAY"
//   | "KEY"
//   | "COLON"
//   | "VALUE"
//   | "COMMA";

import { head } from "lodash";
import { BoardContentSummary } from "../models/Model.Board";
import { SERVER_ADDRESS, SERVER_API_PORT } from "../config.json";
import { GlobalKey, getGlobal } from "./GlobalUtil";
import { LogLevel, log } from "./LogUtil";
import EventSource from "eventsource";
import parse from "html-react-parser";
import { JsonParser } from "./JsonUtil";

export class BoardQuizFetcher {
  onRecieve: (summary: BoardContentSummary) => void = null;
  onDone: (summary: BoardContentSummary) => void = null;
  onFail: (err) => void = null;
  id: number = 0;
  contentId: number = 0;
  parser: BoardQuizJsonParser;
  eventSource: EventSource = null;

  constructor(contentId, prompt, onRecieve, onDone, onFail) {
    this.contentId = contentId;
    this.onRecieve = onRecieve;
    this.onDone = onDone;
    this.onFail = onFail;
    this.parser = new BoardQuizJsonParser(contentId);

    let token = getGlobal(GlobalKey.TOKEN, true);
    if (token) {
      let headers = {
        Authorization: "Bearer " + token,
      };

      this.eventSource = new EventSource(
        `${SERVER_ADDRESS + SERVER_API_PORT}/admin/quiz/generate?contentId=${
          this.contentId
        }&prompt=${prompt}`,
        { headers: headers }
      );
      this.eventSource.onmessage = this._onMessage;
      this.eventSource.onerror = this._onError;
    }
  }

  private _onMessage = (event) => {
    if (event.type !== "message") {
      return;
    }
    const data = JSON.parse(event.data);
    const regex = /#\$ID\d+\$#/;

    if (regex.test(data)) {
      this.parser.id = parseInt(data.match(/\d+/)[0]);
      log(
        LogLevel.UI_ACTION,
        "BoardQuizFetcher:_onMessage ID",
        data,
        this.parser.getJsonObject(),
        this.parser.fullJsonString
      );
      return;
    } else if (data === "#$EndSessionSignal12345$#") {
      this.eventSource.close();
      this.eventSource = null;

      log(
        LogLevel.UI_ACTION,
        "BoardQuizFetcher:_onMessage DONE",
        data,
        this.parser.getJsonObject(),
        this.parser.fullJsonString
      );

      if (this.onDone) {
        this.onDone(this.parser.getJsonObject());
      }
      return;
    }
    this.parser.parses(data);

    log(
      LogLevel.UI_ACTION,
      "BoardQuizFetcher:_onMessage",
      data,
      this.parser.getJsonObject(),
      this.parser.fullJsonString
    );

    if (this.onRecieve) {
      this.onRecieve(this.parser.getJsonObject());
    }
  };

  private _onError = (err) => {
    // console.error("EventSource failed:", err);
    log(LogLevel.UI_ACTION, "BoardQuizFetcher:_onError", err);
    this.eventSource.close();
    this.eventSource = null;
    if (this.onFail) {
      this.onFail(err);
    }
  };
}

export class BoardQuizJsonParser extends JsonParser {
  id: number = 0;
  contentId: number = 0;

  constructor(contentId) {
    super();
    this.contentId = contentId;
  }

  getJsonObject() {
    this.rootObject["id"] = this.id;
    this.rootObject["contentId"] = this.contentId;
    this.rootObject["state"] = this.currentState;
    return this.rootObject;
  }

  validate() {
    let rvalue = "";
    if (this.currentState !== "FINISH") {
      log(
        LogLevel.UI_ACTION,
        "BoardQuizJsonParser validate failed : this.currentState !== FINISH",
        this
      );
      rvalue += "currentState is not FINISH\n";
    }

    if (!this.rootObject.contentId) {
      log(
        LogLevel.UI_ACTION,
        "BoardQuizJsonParser validate failed : no contentId",
        this
      );
      rvalue += "no contentId\n";
    }

    // if (!this.rootObject.summary) {
    //   log(
    //     LogLevel.UI_ACTION,
    //     "BoardQuizJsonParser validate failed : no summary",
    //     this
    //   );
    //   rvalue += "no summary\n";
    // }

    // if (!this.rootObject.summary.question) {
    //   log(
    //     LogLevel.UI_ACTION,
    //     "BoardQuizJsonParser validate failed : no question on summary",
    //     this
    //   );
    //   rvalue += "no question on summary\n";
    // }
    // if (
    //   !this.rootObject.summary.answers ||
    //   Array.isArray(this.rootObject.summary.answers) === false
    // ) {
    //   log(
    //     LogLevel.UI_ACTION,
    //     "BoardQuizJsonParser validate failed : no answers",
    //     this
    //   );
    //   rvalue += "no answers\n";
    // }
    return rvalue;
  }
}
