
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonModal, IonLoading, IonToast, IonIcon, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonSegment, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel, IonFooter, IonInput, IonItem, IonList } from '@ionic/react';
import './ProductOrder.css';
import './../../pages/ProductSaleOrder.css'
import * as API from '../../API.json';
import { OrderInfo, ProductInfo, ProductStatus } from '../../models/Model.Sale';
import { log, LogLevel } from '../../utils/LogUtil';
import { UIPopupType } from '../../store/ui/types';
import SwipeableViews from 'react-swipeable-views';
import ReactHtmlParser from 'react-html-parser'
import StringUtil from '../../utils/StringUtil';
import { KeywordType } from '../../models/Model.Medicine';
import ComponentGenerator from '../../ivcomponent/ComponentGenerator';
import LinkMeta from '../LinkMeta';
import drugIcon from './../../assets/icon/drug.svg'
import {MY_ADDRESS} from '../../config.json'
import { Reference } from '../../models/Model.Board';
import { getGlobal, GlobalKey } from '../../utils/GlobalUtil';
import NumberSelector from '../NumberSelector';
import { UserInfo } from '../../models/Model.User';
import Truncate from 'react-truncate';
import { fetchAPI } from '../../utils/APIUtil';

type Props =  {
  order: OrderInfo,
  onDone : () => void,
}

type State = {
}

const windowAny : any = window;

class ProductOrderDone extends Component<Props, State> {

  onProgress:boolean = false

  constructor(props: Props) {
    super(props);
    this.state ={
    }
  }

  componentDidMount() {

  }

  goBack = (e) => {
    log(LogLevel.UI_ACTION, "ProductOrderDone:goBack");
    this.props.onDone();
  }


  render() {
    log(LogLevel.UI_LIFECYCLE, "ProductOrderDone.render");
    if(!this.props.order || !this.props.order.product)
      return null;

    return (
      <>
        <IonHeader class="common-header" no-border>
          <IonToolbar color="common-header-toolbar">
            <IonButtons slot="start">
              <IonButton onClick={this.goBack}>
                <IonIcon name="arrow-back" mode="ios"/>
              </IonButton>
              <IonLabel class="common-header-title" onClick={this.goBack}>결제 완료</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div className="sales-product-viewer-content product-order-container">
          <div className="product-order-done-message-container">
            감사합니다! <br/><br/>
            결제가 완료되었습니다.
          </div>  
          <div className="product-order-title">
            주문 정보
          </div>  
          <div className="product-order-information-container">
            <div className="product-order-information-title">
              <Truncate lines={1} ellipsis="...">
                <span className="common-bold"> {this.props.order.product.name}</span> {(this.props.order.product.description?" - " + this.props.order.product.description:"")} 
              </Truncate>
            </div>  
            <div className="common-container-row common-flex-align-center" style={{padding:"10px 0px"}}>
              <div className="product-order-done-information-price">
                {this.props.order.product.price.toLocaleString()}원
              </div>   
              <div className="product-order-done-information-price">수량 {this.props.order.amount}개</div>
            </div>
            <div className="common-container-row common-flex-align-center" style={{padding:"10px 0px"}}>
              <div className="product-order-information-price-total-title">총 금액</div>
              <div className="common-flex-grow"/>
              <div className="product-order-information-price-total-container">
                <div className="product-order-information-price">{(this.props.order.product.price * this.props.order.amount + this.props.order.product.deliveryFee).toLocaleString()}원</div>
                {(this.props.order.product.deliveryFee>0) && 
                  <div className="product-order-information-price-total-information">배송료 {this.props.order.product.deliveryFee}원 포함</div>
                }
              </div>
            </div>
          </div>  
          <div className="product-order-title">
            배송 정보
          </div>  
          <div className="product-order-information-container">
            <div className="common-container-row common-flex-align-center product-order-information-title">
              <div className="common-bold common-flex-grow">수령인</div>
              <div className="common-flex-grow">{this.props.order.recipientName}</div>
              <div className="common-bold common-flex-grow">전화번호</div>
              <div className="common-flex-grow">{this.props.order.recipientPhone}</div>
            </div>
            <div className="common-container-row common-flex-align-center product-order-information-title">
              <div className="common-bold common-flex-grow" style={{minWidth:"35px"}}>주소</div>
              <div className="common-flex-grow">{this.props.order.recipientAddress1} {this.props.order.recipientAddress2}</div>
            </div>
          </div>  
        </div>
        <IonFooter class="product-order-additional-information-footer" mode="ios">
          <IonButton color="common-button" class="product-order-additional-information-done-button" onClick={this.props.onDone}>
            확인
          </IonButton>
        </IonFooter>
      </>
    );
  }
}


type ItemProps = {
  onSelected: (address:string, postalcode:string) => void;
  item: any;
  key: string;
};

class SearchPharmacyItem extends Component<ItemProps> {

  onClick = ()=>{
    this.props.onSelected(this.props.item.address, this.props.item.postalCode);
  }

  render() {
    return (
      <IonItem no-border class="search-address-item-container" onClick={this.onClick}>
        <div className="search-address-item-inner">
          <div className="search-address-item-name">{this.props.item.name}</div>
          <div className="search-address-item-address">{this.props.item.address}</div>
          <div className="search-address-item-phone-container">
            <div className="search-address-item-phone-title">전화번호</div>
            <div className="search-address-item-phone">{this.props.item.phone}</div>
          </div>
        </div>
      </IonItem>
    );
  }
}

export class SearchAddressItem extends Component<ItemProps> {

  onClick = ()=>{
    this.props.onSelected(this.props.item.address, this.props.item.postalCode);
  }

  render() {
    return (
      <IonItem no-border class="search-address-item-container" onClick={this.onClick}>
        <div className="search-address-item-inner">
          <div className="search-address-item-address">{this.props.item.address}</div>
          <div className="search-address-item-phone-container">
            <div className="search-address-item-phone-title">우편번호</div>
            <div className="search-address-item-phone">{this.props.item.postalCode}</div>
          </div>
        </div>
      </IonItem>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  me: state.user.me,

});

const mapDispatchToProps = {
}

export default ProductOrderDone;