import React, { Component } from 'react';
import './Welcome.css';
import './../Common.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton } from '@ionic/react';
import profile from './../assets/image/profile_host.png'
import { UserInfo } from '../models/Model.User';
import ProfileImage from './ProfileImage';

type Props = {
  me : UserInfo;
  onDone: () => void;
};

class WelcomeStage0 extends Component<Props> {

  render() {
    return (
      <div className="welcome-container">
        <ProfileImage className="welcome-0-profile" profileUrl={this.props.me.profileUrl}/>
        <div className="welcome-0-welcome">
          {this.props.me.name} 약사님 <br/> 환영합니다.
        </div>
        <div className="welcome-0-description">
          약문약답은 <br/>
          <br/>
          의약품 안전사용을 위해 매일같이 고민하는 <br/>
          약사님들을 위한 지식교류 서비스입니다.<br/>
          <br/>
          약사 업무에 필요한 다양한 기능들을 <br/>
          고민해서 지속적으로 업데이트 해 가겠습니다.<br/>
        </div>

        <div className="welcome-next-button-container">
          <IonButton color="welcome-next-button" onClick={this.props.onDone}>
            약문약답 시작하기
          </IonButton>
        </div>   
      </div>
    );
  }
}

export default WelcomeStage0;