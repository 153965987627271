import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'

import {setGlobal, GlobalKey, getGlobal} from './../utils/GlobalUtil'
import { BoardType } from '../store/board/types';
import { UserLevel, UserWorkType, UserInfo, UserWorkTypeName, UserLevelName } from '../models/Model.User';
import { fetchAPI } from '../utils/APIUtil';
import ProfileImage from '../components/ProfileImage';
import { threadId } from 'worker_threads';
import UserSelect from './component/user/UserSelect';
import UserStatisticsView from './component/user-statistics/UserStatisticsView';
import UserRankStatisticsView from './component/user-statistics/UserRankStatisticsView';
import UserChurnStatisticsView from './component/user-statistics/UserChurnStatisticsView';
import UserDistributionStatisticsView from './component/user-statistics/UserDistributionStatisticsView';
import UserConditionedDistributionStatisticsView from './component/user-statistics/UserConditionedDistributionStatisticsView';

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  selecting: boolean,
  selected: UserInfo,
}

class UserStatistics extends Component<Props, State> {
  popup = null;
  list = null;

  state = {
    selecting: false,
    selected: null,
  };

  constructor(props: Props) {
    super(props);
    this.props.loadFilePath();
  }

  componentDidMount() {
  }

  onSelect = (user: UserInfo) => {
    log(LogLevel.UI_EVENT, "onSelect", user);
    var x = document.getElementById("admin-sub-conainer");
    this.setState({selected:user});
  }

  onDone = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    if(this.list) this.list.refresh();
    this.setState({selected:null});
  }

  onCancel = () => {
    log(LogLevel.UI_EVENT, "onDone", this.state.selected);
    this.setState({selected:null});
  }


  render() {
    log(LogLevel.UI_LIFECYCLE, "UserStatistics.render", this.props);

    let userList;
    if(this.state.selecting)
      userList = (
        <div id="admin-sub-conainer" className="admin-container">
          <UserSelect level={UserLevel.NORMAL_USER} onDone={this.onSelect}  ref={ref => {this.list = ref;}} />
        </div>
      );
    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                사용자별 활동 통계
            </IonText>
            <IonButtons slot="end">
                <IonButton onClick={() => this.setState({selecting:!this.state.selecting})}>사용자선택</IonButton>
            </IonButtons>
        </IonHeader>
        <IonContent>
          <div className="admin-main-container">
            <div className={"admin-"+(this.state.selecting?"full-":"")+ "container"}>
              <UserDistributionStatisticsView/>
              <UserConditionedDistributionStatisticsView/>
              <UserRankStatisticsView onSelect={this.onSelect}/>
              <UserChurnStatisticsView onSelect={this.onSelect}/>
              <UserStatisticsView user={this.state.selected}/>
            </div>
            {userList}
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  loadFilePath: () => actions.board.getFilePath(),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserStatistics));