import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import PartnersMainTemplate from "../../components/templates/PartnersMain";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import { getGlobal, GlobalKey } from "../../utils/GlobalUtil";
import * as API from "./../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import { JobListType } from "../../store/jobpost/types";
import ABTestUtil from "../../utils/ABTestUtil";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { GroupChannel, GroupChannelListQuery, SendBirdError } from "sendbird";
import axios from "axios";
import { MY_ADDRESS } from "./../../config.json";
import Spinner from "../../components/atoms/Spinner";
import { UIServiceType } from "../../store/ui/types";
import { BoardContent } from "../../models/Model.Board";
import { BoardType } from "../../store/board/types";
import { CompanyType } from "../../models/Model.User";
import allinoneDescription from "./../../assets/image/allinone-description.png";
import { COLOR_SYSTEM } from "../../components/design/design-system";
import { BizUserInfo } from "../../models/Mode.Biz";

const windowAny: any = window;

const PartnersMainPage: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();
  const banners = useSelector((state: RootState) => state.allinone.banners);
  const partners = useSelector((state: RootState) => state.allinone.partners);
  const partnerList = useSelector(
    (state: RootState) => state.allinone.partnerList
  );
  const bizCategoryList = useSelector(
    (state: RootState) => state.board.bizCategoryList
  );
  const bizCategories = useSelector(
    (state: RootState) => state.board.bizBoards
  );
  const contents = useSelector((state: RootState) => state.board.contents);
  const hotList = useSelector(
    (state: RootState) => state.board.boards[BoardType.PARTNERS_HOT]
  );
  const newList = useSelector(
    (state: RootState) => state.board.boards[BoardType.PARTNERS_NEW_CONTENTS]
  );
  const tempalteEl = useRef(null);

  useEffect(() => {
    log(LogLevel.UI_ACTION, "PartnersMainPage:useEffect mount");
    if (!partnerList) dispatch(actions.allinone.getPartnerList());
    if (!banners) dispatch(actions.allinone.getBanners());
    // dispatch(actions.board.getBizBoard(null));
    if (!hotList) dispatch(actions.board.getBoard(BoardType.PARTNERS_HOT));
    if (!newList)
      dispatch(actions.board.getBoard(BoardType.PARTNERS_NEW_CONTENTS));
  }, []);

  const onClickPartner = (partner: BizUserInfo) => {
    try {
      log(LogLevel.UI_ACTION, "PartnersMainPage:onClickPartner", partner);
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER",
        "약사파트너스_파트너_선택",
        {
          id: partner.id,
          회사: partner,
        }
      );
      history.push("/partners/detail?id=" + partner.id);
    } catch (err) {
      console.error(err);
    }
  };

  const onClickPost = (post: BoardContent) => {
    try {
      log(
        LogLevel.UI_ACTION,
        "PartnersMainPage:onClickPost",
        post.id,
        post.usersBizId,
        partners,
        post
      );
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_CONTENT",
        "약사파트너스_컨텐츠_선택",
        {
          게시물id: post.id,
          회사id: post.usersBizId,
          회사:
            partners && partners[post.usersBizId]
              ? partners[post.usersBizId].companyName
              : "",
          콘텐츠: post.subject,
          from: "메인페이지",
        }
      );
      history.push(
        `/boards/view?id=${post.id}&partnerId=${partners[post.usersBizId].id}`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onClickIntroduction = () => {
    try {
      log(LogLevel.UI_ACTION, "PartnersMainPage:onClickPartner");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_SELECT_PARTNER",
        "약사파트너스_소개_선택",
        {}
      );
      history.push(`/partners/introduction`);
    } catch (err) {
      console.error(err);
    }
  };

  const onRecommendPartner = () => {
    try {
      log(LogLevel.UI_ACTION, "PartnersMainPage:onRecommendPartner");
      AnalyticsUtil.event(
        AnalyticsUtil.TYPE_ALL,
        "PARTNERS_RECOMMEND_PARTNER",
        "약사파트너스_파트너_추천",
        {}
      );
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSfnkh7dx1eNSsCQhEVWXTge1ahXU4LLK9uei-flw__kgG4OtQ/viewform",
        getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
      );
    } catch (err) {
      console.error(err);
    }
  };
  log(LogLevel.UI_LIFECYCLE, "PartnersMainPage");

  const onShowBanner = (item: any, index: number) => {
    if (!item) return;

    log(LogLevel.UI_ACTION, "PartnersMainPage:onShowBanner", index, item);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "ADVERTISE_EXPOSURE",
      "광고노출",
      {
        type: "약사파트너스배너",
        code: "PARTNERS",
        url: item.targetUrl,
        contentId: item.id,
        contentTitle: item.name,
        ...item.advertiserInfo,
      }
    );
  };

  const onClickBanner = (item: any, index: number, position: any) => {
    if (item.targetUrl) {
      try {
        log(LogLevel.UI_ACTION, "PartnersMainPage:onClickPartner", item, index);
        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "PARTNERS_SELECT_PARTNER",
          "약사파트너스_배너_선택",
          {
            index,
            url: item.targetUrl,
          }
        );

        AnalyticsUtil.event(
          AnalyticsUtil.TYPE_ALL,
          "ADVERTISE_CLICK",
          "광고클릭",
          {
            type: "약사파트너스배너",
            code: "PARTNERS",
            url: item.targetUrl,
            contentId: item.id,
            contentTitle: item.name,
            ...item.advertiserInfo,
          }
        );

        const url = item.targetUrl;
        if (url.startsWith("&&&")) {
          if (getGlobal(GlobalKey.OS) !== "browser") {
            history.push(url.substring(3));
          } else {
            window.open(
              url.replace("&&&", MY_ADDRESS),
              getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
            );
          }
        } else if (url.startsWith(MY_ADDRESS)) {
          if (getGlobal(GlobalKey.OS) !== "browser") {
            history.push(url);
          } else {
            window.open(
              url,
              getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
            );
          }
        } else if (url.startsWith("/")) {
          history.push(url);
        } else if (url.startsWith("tel") || url.startsWith("sms")) {
          window.open(url, "_system");
        } else
          window.open(
            url,
            getGlobal(GlobalKey.OS) === "browser" ? "_blank" : "_system"
          );
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onClickCategory = (index: number) => {
    if (tempalteEl.current) tempalteEl.current.setIndex(index);
  };

  try {
    return (
      <PartnersMainTemplate
        ref={tempalteEl}
        banners={banners}
        partnerList={partnerList}
        partners={partners}
        contents={contents}
        hotList={hotList}
        newList={newList}
        categoryList={bizCategoryList}
        categories={bizCategories}
        onClickPartner={onClickPartner}
        onClickPost={onClickPost}
        onClickCategory={onClickCategory}
        onClickBanner={onClickBanner}
        onShowBanner={onShowBanner}
        onRecommendPartner={onRecommendPartner}
        onClickIntroduction={onClickIntroduction}
      />
    );
  } catch (error) {
    log(LogLevel.UI_EXCEPTION, "PartnersMainPage:exception", error.message);
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PartnersMainPage",
      errorMessage: error,
    });

    return null;
  }
};

export default withRouter(PartnersMainPage);
