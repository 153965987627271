import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { actions, RootState } from "../../store";
import { log, LogLevel } from "../../utils/LogUtil";
import queryString from "query-string";
import { getPayString, JobOfferName } from "../../models/Model.JobPost";
import { fetchAPI } from "../../utils/APIUtil";
import {
  clearGlobal,
  getGlobal,
  GlobalKey,
  setGlobal,
} from "../../utils/GlobalUtil";
import * as API from "../../API.json";
import AnalyticsUtil from "../../utils/AnalyticsUtil";
import PremiumUserSubscribe from "../../components/templates/PremiumUserSubscribe";
import {
  PremiumType,
  UserPremiumInfo,
  UserPremiumPurchasedInfo,
} from "../../models/Model.User.Premium";
import { getDateStringFromToday } from "../../utils/TimeUtil";
import { set } from "lodash";
import PremiumUserDetail from "../../components/templates/PremiumUserDetail";
import { UIPopupType } from "../../store/ui/types";
import PremiumUserSubscribeComplete from "../../components/templates/PremiumUserSubscribeComplete";
import PremiumUserUnsubscribeComplete from "../../components/templates/PremiumUserUnsubscribeComplete";

const windowAny: any = window;

const PremiumUserUnsubscribeDonePage: React.FC<RouteComponentProps> = ({
  history,
  location,
  match,
}) => {
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.user.me);
  const waitingPopup = useSelector(
    (state: RootState) => state.ui.popups[UIPopupType.WAITING_POPUP]
  );
  const loading = useRef<boolean>(false);

  const [showComplete, setShowCompletePopup] = useState<boolean>(false);

  useEffect(() => {
    log(LogLevel.UI_ACTION, "PremiumUserSubscribePage:useEffect mount", me);
    AnalyticsUtil.event(
      AnalyticsUtil.TYPE_ALL,
      "PREMIUM_PAGE",
      "유료회원_해지성공_진입",
      {}
    );
  }, []);

  const goBack = useCallback(() => {
    history.replace(
      location && location.state && location.state.from
        ? location.state.from
        : "/"
    );
  }, [history]);

  try {
    return <PremiumUserUnsubscribeComplete onGoBack={goBack} />;
  } catch (error) {
    log(
      LogLevel.UI_EXCEPTION,
      "PremiumUserSubscribePage:exception",
      error.message,
      error.stack
    );
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "UI_Rendering_Error", "에러", {
      page: "PremiumUserSubscribePage",
      errorMessage: error,
    });

    return null;
  }
};

export default withRouter(PremiumUserUnsubscribeDonePage);
