import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'
import {setGlobal, GlobalKey, getGlobal} from './../utils/GlobalUtil'
import { fetchAPI } from '../utils/APIUtil';
import Truncate from 'react-truncate';
import { SeminarLecture, SeminarLectureAttribute } from '../models/Model.Seminar';
import { UIPopupType } from '../store/ui/types';
import { STATUS_CODES } from 'http';
import { PollInfo, PollItemInfo, PollResponseInfo, PollResponseItemInfo } from '../models/Model.Poll';
import PollSelect from './component/poll/PollSelect';
import { idText } from 'typescript';

const AdminAPI = {        
  "GET_LIST" : {"method":"POST", "path":"/admin/seminar/lectures", "contentType":"application/json"}
};

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  viewStack : any[],
  index: number,
  selectedPoll: PollInfo;
  selectedPollItem: PollItemInfo;
  selectedResponse: PollResponseInfo;
  selectedResponseItem: PollResponseItemInfo;
}

class SurveyManage extends Component<Props, State> {
  lecturesList = null;
  lectureEditor = null;
  popup = null;
  state = {
    viewStack : [PollSelect],
    index: 0,
    selectedPoll : null,
    selectedPollItem : null,
    selectedResponse : null,
    selectedResponseItem : null,
  };

  constructor(props: Props) {
    super(props);

  }

  componentDidMount() {
  }
  
  onBack = () => {
    if(this.state.index){
      this.setState({index : this.state.index-1})
    }
  }

  onPop = () => {
    if(this.state.viewStack.length > 1){
      let index = this.state.index;
      let viewStack = [...this.state.viewStack];
      viewStack.splice(viewStack.length -1, 1);
      if(index >= viewStack.length)
        index--;
      this.setState({index, viewStack});
    }
  }

  onNext = (component:any, depth:number, data:any) => {
    log(LogLevel.UI_LIFECYCLE, "PollManage.onNext", this.props, this.state, depth, data);
    let index = this.state.index;
    let viewStack = [...this.state.viewStack];

    if(component){
      if(depth < viewStack.length -1){
        viewStack.splice(depth+1);
      }
      viewStack.push(component);
      if(index < viewStack.length -2)
        index = viewStack.length -2
    }

    this.setState({index, viewStack, ...data});
  }

  goPrevious = () => {
    if(!this.state.index)
      return;
    this.setState({index:this.state.index-1});
  }

  
  goNext = () => {
    if(this.state.index >= this.state.viewStack.length - 1)
      return;
    this.setState({index:this.state.index+1});
  }

  render() {
    let main, sub;
    let newProps:any = {
      onBack: this.onBack, 
      onPop: this.onPop,
      onNext: this.onNext, 
      selectedPoll: this.state.selectedPoll, 
      selectedPollItem: this.state.selectedPollItem, 
      selectedResponse: this.state.selectedResponse, 
      selectedResponseItem: this.state.selectedResponseItem,
    }
    log(LogLevel.UI_LIFECYCLE, "PollManage.render", this.props, this.state, newProps);

    if(this.state.viewStack[this.state.index]){
      main = React.createElement(this.state.viewStack[this.state.index], {...newProps, depth:this.state.index});
    }
    if(this.state.viewStack[this.state.index+1]){
      sub = React.createElement(this.state.viewStack[this.state.index+1], {...newProps, depth:this.state.index+1});
    }
    
    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                설문 관리
            </IonText>

            <IonButton color="primary" onClick={this.goPrevious}>&lt;</IonButton>
            <IonButton color="primary" onClick={this.goNext}>&gt;</IonButton>
        </IonHeader>

        <IonContent>

          <div className="admin-main-container">
            <div className="admin-container">
              {main}
            </div>
            <div id="admin-sub-conainer" className="admin-container">
              {sub}
            </div>
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures : state.seminar.lectures,
  progressPopup : state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath : state.board.filePath,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId:number) => actions.seminar.getSeminarLecture(lectureId),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveyManage));