import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColorSystem, COLOR_SYSTEM } from "../../design/design-system";
import Button from "../../atoms/Button";
import ToggleButton from "../../atoms/ToggleButton/ToggleButton";
import Text from "../../atoms/Text";
import Tag from "../../atoms/Tag";
import Icon from "../../atoms/Icon";
import TopAppBar from "../../molecules/TopAppBar/TopAppBar";
import { JobOfferInfo, JobOfferType } from "../../../models/Model.JobPost";
import {
  getByteSize,
  parseDate,
  renderJobPostPay,
} from "../../../utils/JobPostUtil";
import {
  companyName,
  employeeCntParser,
  JobBizOfferName,
  OptionType,
} from "../../../types/jobpost";
import {
  ButtonBox,
  Field,
  File,
  DetailMainContainer,
  UploadedFiles,
  Header,
  ImageView,
} from "./style";
import { Attachment } from "../../../models/Model.Board";
import { UserInfo, UserLevel } from "../../../models/Model.User";
import OptionBottomSheet from "../../organisms/JobPostBottomSheet/OptionBottomSheet";
import Parser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { UIServiceType } from "../../../store/ui/types";
import { RootState } from "../../../store";
import {
  hideBottomSheet,
  offBottomSheet,
  onBottomSheet,
  paintBottomSheet,
} from "../../../store/modal/bottomsheet";
import ShareList from "../../organisms/ShareList";
import JobPostBizDetailField from "../../organisms/JobPostBizDetail/JobPostBizDetailField";
import useShare from "../../../hooks/useShare";
import StringUtil from "../../../utils/StringUtil";
import { log, LogLevel } from "../../../utils/LogUtil";
import { Absolute, Static } from "../../atoms/Layout";
import { GlobalKey, setGlobal } from "../../../utils/GlobalUtil";

interface JobPostBizDetailTemplateProps {
  me?: UserInfo;
  isOwner?: boolean;
  post: JobOfferInfo;
  viewCnt?: number;
  isScrapped?: boolean;
  isPrivate: boolean;
  optionBottomSheet?: boolean;
  handleToggleScrap?: () => void;
  handleDownloadClick?: (file: Attachment) => () => void;
  handleGoBackClick?: () => void;
  handleOpenBottomSheet?: () => void;
  handleCloseBottomSheet?: () => void;
  handleShareClick?: () => void;
  handleSelectOption?: (optionType: OptionType) => () => void;
  handleLinkClick?: (type: any, url?: string) => () => void;
  setCompanyTypeTagColor?: () => ColorSystem;
}

const JobPostBizDetailTemplate: React.FC<JobPostBizDetailTemplateProps> = ({
  me,
  isOwner,
  post,
  viewCnt,
  isScrapped,
  isPrivate,
  optionBottomSheet,
  handleToggleScrap,
  handleDownloadClick,
  handleGoBackClick,
  handleOpenBottomSheet,
  handleCloseBottomSheet,
  handleSelectOption,
  handleLinkClick,
  setCompanyTypeTagColor,
}) => {
  const NAVER_MAP_BASE_URL =
    "https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors";
  const isMyPost = useMemo(() => me.id === post.ownerId, [me, post]);
  const { active: bottomSheetActive } = useSelector(
    (state: RootState) => state.bottomSheet
  );
  const backKeyControl = useSelector(
    (state: RootState) => state.ui.services[UIServiceType.BACK_CONTROLLER]
  );
  const dispatch = useDispatch();
  const { jobPostShare } = useShare({ jobPost: post }, me);
  const [isVisible, setIsVisible] = useState(true);

  const handleClickShare = useCallback(() => {
    dispatch(
      paintBottomSheet({
        title: {
          value: "공유하기",
          align: "center",
        },
        body: (
          <ShareList
            onKakao={jobPostShare.handleClickKakao}
            onSMS={jobPostShare.handleClickSMS}
            onLink={jobPostShare.handleClickLink}
          />
        ),
      })
    );
  }, []);

  const isWysiwygEditorEmpty = useCallback((value: string) => {
    if (value === null || value === undefined) return;
    const content = value.replace(/<\/?[\w]+\s?((class=).*?)?>/gi, "").trim();
    return !content;
  }, []);

  useEffect(() => {
    if (backKeyControl) {
      backKeyControl.pushListener(() => {
        if (bottomSheetActive) {
          dispatch(hideBottomSheet());
        } else {
          handleGoBackClick();
        }
        return true;
      }, "jobdetail-popup");
    }
  }, [bottomSheetActive]);

  useEffect(() => {
    dispatch(onBottomSheet());

    return () => {
      dispatch(offBottomSheet());
      backKeyControl.popListener("jobdetail-popup");
    };
  }, []);

  const transform = (node, index) => {
    if (node.type == "tag" && node.name == "a") {
      let url = node.attribs.href;

      let data = "";
      if (
        node.children &&
        node.children.length &&
        node.children[0].type == "text"
      ) {
        data = node.children[0].data;
      }
      log(
        LogLevel.UI_ACTION,
        "JobPostBizDetailTemplate: transform ",
        data,
        url
      );

      return (
        <a key={index.toString()} onClick={handleLinkClick("homepage", url)}>
          {data ? data : url}
        </a>
      );
    }

    return undefined;
  };

  return (
    <>
      <Header>
        <TopAppBar
          leftButtons={
            isPrivate && [
              <Button
                color="Secondary"
                size="Medium"
                variant="Ghost"
                icon="CaretLeft"
                type="Icon"
                onClick={handleGoBackClick}
              />,
            ]
          }
          rightButtons={[
            !isMyPost && (
              <Button
                color="Secondary"
                icon="Share01"
                size="Medium"
                type="Icon"
                variant="Ghost"
                onClick={handleClickShare}
              />
            ),
            me && (me.id === post.ownerId || me.level >= UserLevel.MANAGER) && (
              <Button
                color="Secondary"
                size="Medium"
                variant="Ghost"
                icon="More"
                type="Icon"
                onClick={handleOpenBottomSheet}
              />
            ),
          ]}
          titleType="H4"
        />
      </Header>

      <DetailMainContainer>
        <Field className="title-field">
          <Text
            element="h2"
            textType="H2"
            color={COLOR_SYSTEM.get("Gray")[800]}
          >
            {post.title}
          </Text>
          <Text textType="Body1Medium" color={COLOR_SYSTEM.get("Gray")[800]}>
            {JobBizOfferName[post.workType]}{" "}
            {!!post.contractPeriod &&
              `(${post.workType === JobOfferType.EMPLOYED ? "수습기간 " : ""}${
                post.contractPeriod
              }개월)`}
          </Text>
          <div className="detail-count">
            <Tag color="Gray" icon="EyeFilled" left variant="Tinted">
              {viewCnt ? viewCnt : post.viewCnt}
            </Tag>
            <Tag color="Gray" icon="BookmarkFilled" left variant="Tinted">
              {post.likeCnt}
            </Tag>
          </div>
        </Field>

        <Field className="company-info-field">
          <div className="company__main">
            <div className="company-name">
              <Text
                element="h4"
                textType="H4"
                color={COLOR_SYSTEM.get("Gray")[800]}
              >
                {post.companyName}
              </Text>
              <Tag color={setCompanyTypeTagColor()} variant="Tinted">
                {companyName[post.companyType]}
              </Tag>
            </div>
            <div className="company-address-count">
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[400]}>
                {post.companyRegion}
              </Text>
              <div
                style={{
                  width: "1px",
                  height: "12px",
                  backgroundColor: COLOR_SYSTEM.get("Gray")[200],
                }}
              ></div>
              <Text textType="Body2" color={COLOR_SYSTEM.get("Gray")[400]}>
                직원 수 {employeeCntParser[post.employeeCnt]}
              </Text>
            </div>
            {post.homepage ? (
              <Button
                color="Secondary"
                size="Small"
                variant="Outlined"
                type="IconWithText"
                icon="CaretRight"
                right
                onClick={handleLinkClick("homepage", post.homepage)}
              >
                홈페이지 바로가기
              </Button>
            ) : null}
          </div>
        </Field>

        {!isWysiwygEditorEmpty(post.introduction) ? (
          <JobPostBizDetailField>
            <Field className="company-introduction">
              <Text
                element="pre"
                textType="Body1"
                color={COLOR_SYSTEM.get("Gray")[800]}
              >
                {Parser(post.introduction, { transform: transform })}
              </Text>
            </Field>
          </JobPostBizDetailField>
        ) : null}

        {!isWysiwygEditorEmpty(post.message) ? (
          <JobPostBizDetailField title="주요 업무">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.message, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        {!isWysiwygEditorEmpty(post.qualification) ? (
          <JobPostBizDetailField title="자격 요건">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.qualification, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        <JobPostBizDetailField title="급여 정보">
          {post.salaryRangeLow && post.salaryRangeHigh ? (
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              •{" "}
              {renderJobPostPay(
                post.salaryType,
                post.taxType,
                post.salaryRangeLow,
                post.salaryRangeHigh,
                true
              )}
            </Text>
          ) : null}
          {post.canNegoSalary && (
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              • 추후 협의 가능
            </Text>
          )}
        </JobPostBizDetailField>

        {!isWysiwygEditorEmpty(post.preference) ? (
          <JobPostBizDetailField title="우대 사항">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.preference, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        {!isWysiwygEditorEmpty(post.workTime) || !post.workTime ? (
          <JobPostBizDetailField title="근무 조건">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.workTime, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        {!isWysiwygEditorEmpty(post.welfare) ? (
          <JobPostBizDetailField title="혜택 및 복지">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.welfare, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        {post.applyMethod ? (
          <JobPostBizDetailField title="지원 방법">
            {post.applyMethod.includes("E") && (
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                • 이메일 지원 :{" "}
                <a
                  className="email-link"
                  onClick={handleLinkClick(
                    "homepage",
                    `mailto:${post.emailPersonInCharge}`
                  )}
                >
                  {post.applyMethodDetailEmail}
                </a>
              </Text>
            )}
            {post.applyMethod.includes("H") && (
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                • 홈페이지 지원 :{" "}
                <a
                  onClick={handleLinkClick(
                    "homepage",
                    post.applyMethodDetailHomepage
                  )}
                >
                  {post.applyMethodDetailHomepage}
                </a>
              </Text>
            )}
            {post.applyMethod.includes("P") && (
              <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
                • 우편 지원 : {post.applyMethodDetailPost}{" "}
                {post.applyMethodDetailPostDetail}
              </Text>
            )}
          </JobPostBizDetailField>
        ) : null}

        <JobPostBizDetailField title="지원 기간">
          <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
            •{" "}
            {post.applyStartAt && post.applyEndAt
              ? `${parseDate(post.applyStartAt)} ~ ${parseDate(
                  post.applyEndAt
                )}`
              : "상시 채용"}
          </Text>
        </JobPostBizDetailField>

        {!isWysiwygEditorEmpty(post.submissionDoc) ? (
          <JobPostBizDetailField title="제출 서류">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.submissionDoc, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        {post.submissionDocForms && post.submissionDocForms.length ? (
          <JobPostBizDetailField title="양식 파일">
            <UploadedFiles>
              {post.submissionDocForms.map((file) => (
                <File key={file.name}>
                  <div className="file-icon">
                    <Icon
                      size={22}
                      color={COLOR_SYSTEM.get("Skyblue")[500]}
                      name={"Document"}
                    />
                  </div>
                  <div className="file-info">
                    <Text
                      textType="Body2Medium"
                      color={COLOR_SYSTEM.get("Gray")[900]}
                    >
                      {file.name}
                    </Text>
                    <br />
                    <Text
                      textType="CaptionBold"
                      color={COLOR_SYSTEM.get("Gray")[400]}
                    >
                      {getByteSize(Number(file.size))}
                    </Text>
                  </div>
                  <Button
                    color="Tertiary"
                    variant="Ghost"
                    size="Medium"
                    type="Icon"
                    icon="Download"
                    onClick={handleDownloadClick(file)}
                  />
                </File>
              ))}
            </UploadedFiles>
          </JobPostBizDetailField>
        ) : null}

        {!isWysiwygEditorEmpty(post.hireProcess) ? (
          <JobPostBizDetailField title="채용 절차">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.hireProcess, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        {!isWysiwygEditorEmpty(post.hireNotice) ? (
          <JobPostBizDetailField title="채용 결과 안내">
            <Text
              element="pre"
              textType="Body1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              {Parser(post.hireNotice, { transform: transform })}
            </Text>
          </JobPostBizDetailField>
        ) : null}

        <JobPostBizDetailField title="지원 문의">
          {post.namePersonInCharge ? (
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              • 담당자 : {post.namePersonInCharge}
            </Text>
          ) : null}
          {post.officeInCharge ? (
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              • 담당부서 : {post.officeInCharge}
            </Text>
          ) : null}
          {post.phonePersonInCharge ? (
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              • 전화번호 :{" "}
              <a
                className="email-link"
                href={`tel:${post.phonePersonInCharge}`}
              >
                {post.phonePersonInCharge}
              </a>
            </Text>
          ) : null}
          {post.emailPersonInCharge ? (
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              • 이메일 문의 :{" "}
              <a
                className="email-link"
                href={`mailto:${post.emailPersonInCharge}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {post.emailPersonInCharge}
              </a>
            </Text>
          ) : null}
        </JobPostBizDetailField>

        {post.images && post.images.length ? (
          <JobPostBizDetailField title="참고 이미지">
            <UploadedFiles>
              {post.images.map((file, index) => (
                // <File key={file.name}>
                //   <div className="file-icon">
                //     <Icon size={22} color={COLOR_SYSTEM.get("Skyblue")[500]} name={"Document"} />
                //   </div>
                //   <div className="file-info">
                //     <Text textType="Body2Medium" color={COLOR_SYSTEM.get("Gray")[900]}>
                //       {file.name}
                //     </Text>
                //     <br />
                //     <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[400]}>
                //       {getByteSize(Number(file.size))}
                //     </Text>
                //   </div>
                //   <Button
                //     color="Tertiary"
                //     variant="Ghost"
                //     size="Medium"
                //     type="Icon"
                //     icon="Download"
                //     onClick={handleDownloadClick(file)}
                //   />
                // </File>
                <ImageView key={index.toString()}>
                  <img src={StringUtil.convertFilePath(file.url)} />
                </ImageView>
              ))}
            </UploadedFiles>
          </JobPostBizDetailField>
        ) : null}

        <JobPostBizDetailField title="근무 지역">
          <div className="work-place-address">
            <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[800]}>
              {post.companyAddress.trim()} {post.companyAddressDetail.trim()}
            </Text>
            <Button
              color="Secondary"
              size="Small"
              variant="Outlined"
              type="IconWithText"
              icon="CaretRight"
              right
              onClick={handleLinkClick("kakaoMap")}
            >
              교통편 확인하기
            </Button>
          </div>
          <div onClick={handleLinkClick("naverMap")}>
            <img
              src={`${NAVER_MAP_BASE_URL}?w=700&h=300&center=${post.companyPosition.x},${post.companyPosition.y}&level=16&markers=color:blue|pos:${post.companyPosition.x}%20${post.companyPosition.y}&scale=2&X-NCP-APIGW-API-KEY-ID=4n0skfcl2w`}
              alt="약국 지도"
            />
          </div>
        </JobPostBizDetailField>
      </DetailMainContainer>

      {isPrivate && !isOwner && (
        <>
        {isVisible && (
              <Absolute bottom="80px" left={Math.min(60, window.innerWidth - 200) + "px"}>
                <Static
                  style={{
                    background: COLOR_SYSTEM.get("Blue")[500],
                    padding: "8px 12px",
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    borderRadius: "8px",
                    gap: "12px",
                  }}
                  onClick={(e) => {
                    setIsVisible(false)
                  }}
                >
                  <Text textType={"Body2"} color={COLOR_SYSTEM.get("Gray")[0]}>
                  관심공고로 등록하면,{" "}<br/>
                  지원마감 3일, 1일 전에 알림톡을 보내드려요
                  </Text>
                  <Icon size={16} name="X" color={COLOR_SYSTEM.get("Blue")[100]} />
                </Static>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "20px",
                    height: "8px",
                    width: "12px",
                    borderLeft: "8px solid transparent",
                    borderRight: "8px solid transparent",
                    borderTop: `8px solid ${COLOR_SYSTEM.get("Blue")[500]}`,
                  }}
                />
              </Absolute>
        )}
        <ButtonBox>
          <ToggleButton
            color="Gray"
            size="Large"
            variant="Outlined"
            type="IconWithText"
            icon="BookmarkFilled"
            left
            active={isScrapped}
            style={{ justifyContent: "center" }}
            onClick={handleToggleScrap}
          >
            관심 공고에 등록
          </ToggleButton>
        </ButtonBox>
        </>
      )}

      {isPrivate && (
        <OptionBottomSheet
          active={optionBottomSheet}
          isMyPost={false}
          isManager={true}
          biz={true}
          post={post}
          onClose={handleCloseBottomSheet}
          onSelect={handleSelectOption}
        />
      )}
    </>
  );
};

export default JobPostBizDetailTemplate;
