import React from "react";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import { PopupBody, PopupFooter, SuccessPopupFooter } from "./style";

interface RegisterSuccessPopupProps extends PopupProps {
  isAlimFreeLimited: boolean;
  handleCloseSuccessPopup: () => void;
  handleSendAlimClick: () => void;
  handlePremiumAdClick: () => void;
}

const RegisterSuccessPopup: React.FC<RegisterSuccessPopupProps> = ({
  isAlimFreeLimited,
  onClose,
  handleCloseSuccessPopup,
  handleSendAlimClick,
  handlePremiumAdClick,
}) => {
  return (
    <Popup onClose={onClose}>
      <PopupBody>
        <Icon
          width={60}
          height={60}
          name={"Check Circle"}
          fill={COLOR_SYSTEM.get("Skyblue")[300]}
        />
        <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          구인 공고를 등록했어요!
        </Text>
        {isAlimFreeLimited && (
          <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
            더 많은 노출을 원하시면 배너광고를,
            <br />
            빠른 채용을 원하시면
            <br />
            카카오톡 알림톡을 보내세요. (유료)
          </Text>
        )}
      </PopupBody>
      <SuccessPopupFooter>
        <Button
          color="Primary"
          size="Medium"
          type="Text"
          variant="Contained"
          onClick={() => handlePremiumAdClick()}
        >
          Premium 광고하기
        </Button>
        {isAlimFreeLimited && (
          <Button
            color="Primary"
            size="Medium"
            type="Text"
            variant="Outlined"
            className="alim__button"
            onClick={() => handleSendAlimClick()}
          >
            알림톡 보내기
            <div className="hint">
              <Text textType="InputXSmall" color={COLOR_SYSTEM.get("Gray")[0]}>
                성공 확률 80% 상승!
              </Text>
            </div>
          </Button>
        )}

        <Button
          color="Secondary"
          size="Medium"
          type="Text"
          variant="Tinted"
          onClick={handleCloseSuccessPopup}
        >
          닫기
        </Button>
      </SuccessPopupFooter>
    </Popup>
  );
};

export default RegisterSuccessPopup;
