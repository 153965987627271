import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../store";
import {
  fetchMedicine,
  fetchPost,
  onChangeKeyword,
  resetCurrentSearchedKeyword,
  resetMedicine,
  resetPost,
  SearchType,
  setCurrentSearchedKeyword,
} from "../../../store/search";
import AnalyticsUtil from "../../../utils/AnalyticsUtil";
import Input from "../../atoms/Input";

const SearchBarForm = styled.form`
  width: 95%;
  margin: 10px auto 0;
`;

const SearchBar = () => {
  const dispatch = useDispatch();
  const searchType: SearchType = useSelector((state: RootState) => state.newSearch.searchType);
  const keyword = useSelector((state: RootState) => state.newSearch.keyword);

  const handleSubmitKeyword = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        if (!keyword.trim()) return;

        dispatch(setCurrentSearchedKeyword(keyword.trim()));
        dispatch(searchType === "Post" ? fetchPost(true) : fetchMedicine(keyword.trim()));
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SUBMIT_SEARCH", "검색", {
          검색모드: searchType === "Post" ? "BOARD" : "MEDICINE",
          검색어: keyword.trim(),
        });
        document.querySelector("input").blur();
      } catch (err) {
        AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "Search_Error", "검색통합에러", {
          page: "Search",
          where: "SearchBar/handleSubmitKeyword",
          errorMessage: err,
        });
      }
    },
    [searchType, keyword]
  );

  const handleChangeKeyword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(onChangeKeyword(e.target.value));
  }, []);

  const handleClickClear = () => {
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "SEARCH_INITIALIZE", "검색초기화", {
      검색모드: searchType === "Post" ? "BOARD" : "MEDICINE",
    });

    try {
      dispatch(onChangeKeyword(""));
      dispatch(resetPost());
      dispatch(resetMedicine());
      dispatch(resetCurrentSearchedKeyword());
    } catch (err) {
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "Search_Error", "검색통합에러", {
        page: "Search",
        where: "SearchBar/handleClickClear",
        errorMessage: err,
      });
    }
  };

  return (
    <SearchBarForm onSubmit={handleSubmitKeyword}>
      <Input
        type="search"
        search
        placeholder="궁금한 것을 검색해보세요"
        value={keyword}
        onChange={handleChangeKeyword}
        onClear={handleClickClear}
      />
    </SearchBarForm>
  );
};

export default SearchBar;
