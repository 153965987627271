import { Map } from "immutable";

type Level = "1" | "2" | "3" | "4" | "5";

export const SHADOW = Map<Level, string>({
  1: `box-shadow: 0px 2px 8px rgba(15, 32, 52, 0.12);`,
  2: `box-shadow: 0px 4px 14px rgba(15, 32, 52, 0.1);`,
  3: `box-shadow: 0px 8px 24px rgba(15, 32, 52, 0.15);`,
  4: `box-shadow: 0px 12px 32px rgba(15, 32, 52, 0.15);`,
  5: `box-shadow: 0px 20px 40px 0px #0F203433;`,
});

export const GLASS_EFFECT = Map<Level, string>({
  1: `backdrop-filter: blur(12px);`,
  2: `backdrop-filter: blur(20px);`,
  3: `backdrop-filter: blur(28px);`,
  4: `backdrop-filter: blur(36px);`,
});
