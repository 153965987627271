import { differenceInCalendarDays, format } from "date-fns";
import React from "react";
import styled from "styled-components";
import { JobOfferInfo } from "../../../models/Model.JobPost";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import ABTestUtil, { ABTestFeature } from "../../../utils/ABTestUtil";
import { getDistanceString } from "../../../utils/LocationUtil";
import { renderJobPostPay } from "../../../utils/JobPostUtil";
import Tag from "../../atoms/Tag";
import { Flex, Static } from "../../atoms/Layout";
import {
  getDateStringFromToday,
  convertDateToDays,
} from "../../../utils/TimeUtil";

const PremiumCardLayout = styled.div`
  padding: 16px 20px 16px 20px;
  background-color: ${COLOR_SYSTEM.get("Gray")[0]};
  border: 1px solid ${COLOR_SYSTEM.get("Gray")[50]};
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 40px;
  cursor: pointer;
  transform: translateZ(0);

  .logo {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }

  .description {
    display: flex;
    flex-direction: column;

    & > span:last-child {
      margin-top: 8px;
    }
  }

  .pharmacy {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

interface PremiumCardProps {
  post: JobOfferInfo;
  onClick?: (...args: any) => any;
}

const PremiumCard: React.FC<PremiumCardProps> = ({ post, onClick }) => {
  const { companyType, ownerType, companyName, title, applyEndAt } = post;

  if (
    ABTestUtil.isTest(ABTestFeature.UI_PHARMACY_PREMIUM) &&
    companyType == 0
  ) {
    return (
      <PremiumCardLayout onClick={onClick}>
        <div className="pharmacy">
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Skyblue")[400]}>
            {companyName}
          </Text>
          <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
            {title}
          </Text>
          <Static customStyle={{ marginTop: "4px" }}>
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[400]}>
              {post.companyRegion}
            </Text>
          </Static>

          <Flex
            alignItems="center"
            justifyContent="space-between"
            customStyle={{ width: "100%" }}
          >
            <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[400]}>
              {renderJobPostPay(
                post.salaryType,
                post.taxType,
                post.salaryRangeLow,
                post.salaryRangeHigh
              )}
            </Text>
            {post.canNegoSalary && (
              <Tag color="Gray" variant="Tinted">
                협의 가능
              </Tag>
            )}
          </Flex>
        </div>
      </PremiumCardLayout>
    );
  }
  const differenceDays =
    convertDateToDays(getDateStringFromToday({ date: applyEndAt })) -
    convertDateToDays(getDateStringFromToday());

  return (
    <PremiumCardLayout onClick={onClick}>
      <div className="description">
        <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Skyblue")[400]}>
          {companyName}
        </Text>
        <Text textType="Body1Bold" color={COLOR_SYSTEM.get("Gray")[800]}>
          {title}
        </Text>
        {applyEndAt ? (
          <Text textType="CaptionBold" color={COLOR_SYSTEM.get("Gray")[400]}>
            {differenceDays >= 0 ? differenceDays : 0}일 남음 (~
            {format(new Date(applyEndAt), "MM/dd")})
          </Text>
        ) : (
          <Text textType="Caption" color={COLOR_SYSTEM.get("Gray")[400]}>
            채용시까지
          </Text>
        )}
      </div>
    </PremiumCardLayout>
  );
};

export default React.memo(PremiumCard);
