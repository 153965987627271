
import React, { Component } from 'react';
import './../../Common.css';
import './ComposeCardnewsPopup.css'
import {log, LogLevel} from '../../utils/LogUtil'
import { Attachment } from '../../models/Model.Board';
import { IonModal, IonButton, IonIcon } from '@ionic/react';
import { loadImageBase64 } from '../../utils/ImageUtil';
import StringUtil from '../../utils/StringUtil';
import Input from '../../component/atom/Input';
import SwipeableViews from 'react-swipeable-views';

type Props = {
  cardnews: Attachment[]; 
  onDone: (cardnews:Attachment[]) => void;
  progressPopup: any;
  editName?:boolean;
  editDescription?:boolean;
  toastPopup?:any;
};

type State = {
  visible: boolean;
  type: number;
  cardnews: Attachment[]; 
}

class ComposeCardnewsPopup extends Component<Props, State> {
  state={
    visible: false,
    type: 0,
    cardnews: [],
};

  constructor(props:Props){
    super(props);

  }

  show = () => {
    log(LogLevel.UI_LIFECYCLE, "ComposeCardnewsPopup  :show")
    let cardnews = this.props.cardnews;
    if(!cardnews)
      cardnews = [];
    for(let i=0; i<cardnews.length; i++){
      if(cardnews[i].partialLinks)
        cardnews[i].partialLinksJson = JSON.stringify(cardnews[i].partialLinks);
    }
    this.setState({visible:true, cardnews});

  }

  hide = () => {
    log(LogLevel.UI_LIFECYCLE, "ComposeCardnewsPopup  :hide")
    this.setState({visible: false});
  }

  onDone = () => {
    for(let i=0; i<this.state.cardnews.length; i++){
      try{
        if(this.state.cardnews[i].partialLinksJson){
          this.state.cardnews[i].partialLinks = JSON.parse(this.state.cardnews[i].partialLinksJson);
          delete this.state.cardnews[i].partialLinksJson;
        }
      }catch(e){
        log(LogLevel.UI_EXCEPTION, "HtmlComposer:onDone",i, )
        if(this.props.toastPopup)
          this.props.toastPopup.show(i+"번째 json 파싱 실패");
        return;
      }
    }

    log(LogLevel.UI_ACTION, "ComposeCardnewsPopup  :onDone", this.state.cardnews);
    if(this.props.onDone)
      this.props.onDone(this.state.cardnews);
    this.hide();
  }

  isShown = () => {
    return this.state.visible;
  }

  onImageSelected = async (e) => {
    // console.dir(e);
    log(LogLevel.UI_ACTION, "HtmlComposer:onImageSelected",e.target.files)
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("이미지 로딩 중...");
    let cardnews:Attachment[] = [...this.state.cardnews];
    if (e.target.files && e.target.files.length > 0) {
      let targets = [...e.target.files];
      e.target.value = null;
      for(let i=0; i<targets.length; i++){
        let base64 = await loadImageBase64(targets[i]);
        cardnews.push({
          name: targets[i].name,
          description: "",
          base64: base64,
        })
      }

      cardnews.sort((a, b) => a.name>b.name?1:(a.name==b.name?0:-1));
      this.setState({cardnews})
    }
    this.props.progressPopup.hide();
  }

  onClear = () => {
    this.setState({cardnews:[]});
  }

  onDeleteCard = (index) => {
    let cardnews:Attachment[] = [...this.state.cardnews];
    cardnews.splice(index,1);
    this.setState({cardnews});
  }

  onAddPartialLink = (index) => {
    let cardnews:Attachment[] = [...this.state.cardnews];
    let emptyJson = [{
      left: 0,
      right: 1,
      top: 0,
      bottom: 1,
      link: '/'
    }]
    cardnews[index].partialLinksJson = JSON.stringify(emptyJson);

    this.setState({cardnews});
  }

  onExchange = (indexA, indexB) => {
    let length = this.state.cardnews.length;
    if(indexA < 0 || indexA >= length || indexB < 0 || indexB >= length )
      return;

    let cardnews:Attachment[] = [...this.state.cardnews];
    cardnews[indexA] = this.state.cardnews[indexB];
    cardnews[indexB] = this.state.cardnews[indexA];
    this.setState({cardnews});
  }

  onItemLinkChange = (index, e) => {
    let cardnews: Attachment[] = [...this.state.cardnews];
    cardnews[index] = {...this.state.cardnews[index], link:e.target.value};
    this.setState({cardnews});
  }

  onItemChange = (index, name, e) => {
    let cardnews: Attachment[] = [...this.state.cardnews];
    cardnews[index] = {...this.state.cardnews[index]};
    cardnews[index][name] = e.target.value;
    this.setState({cardnews});
  }
  
  render() {
    log(LogLevel.UI_LIFECYCLE, "ComposeCardnewsPopup  : render");

    return (
      <IonModal cssClass="common-modal-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {false}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >

        <div className="common-modal-popup-inner">
          <div className="common-modal-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
          <div className="common-modal-container">
            <div className="common-container-row common-flex-align-center">
              <div className="common-flex-grow">
                Cardnews 편집기
              </div>
              <IonButton color="common-button" class="compose-cardnews-top-button compose-cardnews-top-button-other">
                <IonIcon name="add"/>
                <input className="common-file-input" multiple={true} type="file" accept="image/*" onChange={this.onImageSelected}/>
              </IonButton>
              <IonButton color="common-button" class="compose-cardnews-top-button compose-cardnews-top-button-other" onClick={this.onClear}>
                <span className="common-color-caution">
                  전체삭제
                </span>
              </IonButton>
              <IonButton color="common-button" class="compose-cardnews-top-button compose-cardnews-top-button-other" onClick={this.hide}>
                취소
              </IonButton>
              <IonButton color="common-button" class="compose-cardnews-top-button compose-cardnews-top-button-done" onClick={this.onDone}>
                저장
              </IonButton>
            </div>

            <div className="common-container">
                {
                  this.state.cardnews.map((item:Attachment, index) => {
                    return (
                      <div key={index.toString()} className="common-container-row common-flex-align-center compose-cardnews-card">
                        <img src={item.base64?item.base64:(item.url?StringUtil.convertFilePath(item.url):"")} className="compose-cardnews-thumbnail"/>
                        <div className="common-flex-grow">
                          {item.url&&<div>{item.url}</div>}
                          {(this.props.editName)?
                            <Input value={item.name?item.name:""} placeholder="name" onChange={e => this.onItemChange(index, "name", e)}/>
                          :
                            <div>{item.name}</div>
                          }
                          {(this.props.editDescription) &&
                            <Input value={item.description?item.description:""} placeholder="description" onChange={e => this.onItemChange(index, "description", e)}/>
                          }
                          <Input value={item.link?item.link:""} onChange={e => this.onItemChange(index, "link", e)}/>
                          <Input value={item.partialLinksJson?item.partialLinksJson:""} onChange={e => this.onItemChange(index, "partialLinksJson", e)}/>
                        </div>
                        <IonButton color="common-button" class="compose-cardnews-card-button" onClick={() => this.onAddPartialLink(index)}>
                          <IonIcon name="copy"/>
                        </IonButton>
                        <IonButton color="common-button" class="compose-cardnews-card-button" onClick={() => this.onDeleteCard(index)}>
                          <IonIcon name="close"/>
                        </IonButton>
                        <IonButton color="common-button" class="compose-cardnews-card-button" onClick={() => this.onExchange(index-1, index)}>
                          <IonIcon name="arrow-up"/>
                        </IonButton>
                        <IonButton color="common-button" class="compose-cardnews-card-button" onClick={() => this.onExchange(index, index+1)}>
                          <IonIcon name="arrow-down"/>
                        </IonButton>
                      </div>
                    );
                  })
                }
            </div>
          </div>
          <div className="common-modal-popup-backdrop"  onClick={() => this.setState(() => ({ visible: false }))}/>
        </div>
      </IonModal>
    );
  }
}

export default (ComposeCardnewsPopup);