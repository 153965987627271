import React, { Component } from 'react';
import './GamificationPeriodicReportPopup.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonIcon, IonInput,IonList, IonItem, IonCheckbox } from '@ionic/react';
import {fetchAPI} from './../utils/APIUtil'
import * as API from './../API.json';;
import Skeleton from 'react-loading-skeleton';
import gamiReportHelp from './../assets/image/gamification_report_1.png'
import gamiReportAnswer from './../assets/image/gamification_report_2.png'
import { KeywordType, Medicine, Ingredient } from '../models/Model.Medicine';
import {compose} from 'redux'
import { connect, connectAdvanced } from 'react-redux';
import { RootState, actions} from '../store';
import {log, LogLevel} from '../utils/LogUtil'
import { GamificationPeriodicReport, GamificationPeriodicReportType } from '../models/Model.Gamification';
import ABTestUtil, { ABTestFeature } from '../utils/ABTestUtil';
import ProfileImage from './ProfileImage';
import Truncate from 'react-truncate';
import AnalyticsUtil from '../utils/AnalyticsUtil';
import {timerStart, timerStop} from "./../utils/TimeUtil";

type Props = {
  filePath?: string;
};

type State = {
  visible: boolean;
  report: GamificationPeriodicReport;
}

class GamificationPeriodicReportPopup extends Component<Props, State> {
  state={
    visible: false,
    report: null,
  };

  show = (report: GamificationPeriodicReport) => {
    if(ABTestUtil.isTest(ABTestFeature.UI_GAMIFICATION_WEEKLY_REPORT)){
      log(LogLevel.NONE, "GamificationPeriodicReportPopup:show")
      this.setState({report, visible:true});
      AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "GAMIFICATION_REPORT_POPUP_SHOW", '게미_팝업보기', {"type":GamificationPeriodicReportType[report.type]});
      timerStart();
    }
  }

  hide = () => {
    log(LogLevel.NONE, "GamificationPeriodicReportPopup:hide")
    this.setState({visible: false});
    AnalyticsUtil.event(AnalyticsUtil.TYPE_ALL, "GAMIFICATION_REPORT_POPUP_SHOW", '게미_팝업보기', {"type":GamificationPeriodicReportType[this.state.report.type], "time":timerStop()});
  }

  onDone = () => {
    this.hide();
  }

  isShown = () => {
    return this.state.visible;
  }

  ignore = () => {
    this.hide();
  }

  renderReportHelp() {
    let helped;
    if(this.state.report.userInfoList && this.state.report.userInfoList.length>0)
      helped = (
        <div className="gamification-report-info-user-list-container">
          {this.state.report.userInfoList.map((item,index) => 
            <div key={index.toString()} className="gamification-report-info-user-item-container">
              <ProfileImage className="gamification-report-info-user-item-profile" profileUrl={item.profileUrl}/>
              <div className="gamification-report-info-user-item-nickname">
                <Truncate className="gamification-report-info-user-item-nickname" lines={1} ellipsis="...">
                  {item.nickname}
                </Truncate>
              </div>
            </div>
          )}
        </div>
      );
    return (
      <div className="gamification-report-container">
        <IonButton color="gamification-report-close" onClick={this.hide}>
          <IonIcon class="gamification-report-close-icon" name="close"/>
        </IonButton>
        <div className="gamification-report-main-container gamification-report-main-background-help">
          <img className="gamification-report-main-image" src={gamiReportHelp}/>
          <div className="gamification-report-main-message">
            약사님의 답변이 한주동안<br/>
            <span className="gamification-report-main-message-point">{this.state.report.intValues[0]}명의 약사님들께 도움</span>이 되었습니다.
          </div>
        </div>
        <div className="gamification-report-info-container">
          <div className="gamification-report-info-title"> 도움받은 분들 </div>
          {helped}
        </div>
      </div>
    );
    
  }

  renderReportAnswerReport() {
    return (
      <div className="gamification-report-container">
        <IonButton color="gamification-report-close" onClick={() => this.setState(() => ({ visible: false }))}>
          <IonIcon class="gamification-report-close-icon" name="close"/>
        </IonButton>
        <div className="gamification-report-main-container gamification-report-main-background-answerer">
          <img className="gamification-report-main-image" src={gamiReportAnswer}/>
          <div className="gamification-report-main-message">
            지난 주에 <span className="gamification-report-main-message-point">{this.state.report.intValues[0]}개의 답변</span>을 하셨어요<br/>
            이번 주에도 화이팅!
          </div>
        </div>
        <div className="gamification-report-info-container">
          <div className="gamification-report-info-title"> 나의 답변에 대한 반응 </div>
          <div className="gamification-report-info-weekly-report-container">
            <div className="gamification-report-info-weekly-report-item-container">
              <div className="gamification-report-info-weekly-report-count-container gamification-report-info-weekly-report-count-container-1">
                <div className="gamification-report-info-weekly-report-count-inner">
                  <div className="gamification-report-info-weekly-report-count">
                    <div className="gamification-report-info-weekly-report-count-text">
                      {this.state.report.intValues[1]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="gamification-report-info-weekly-report-description">
                좋아요
              </div>
            </div>
            <div className="common-flex-grow"/>
            <div className="gamification-report-info-weekly-report-item-container">
              <div className="gamification-report-info-weekly-report-count-container gamification-report-info-weekly-report-count-container-2">
                <div className="gamification-report-info-weekly-report-count-inner">
                  <div className="gamification-report-info-weekly-report-count">
                    <div className="gamification-report-info-weekly-report-count-text">
                      {this.state.report.intValues[2]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="gamification-report-info-weekly-report-description">
                스크랩
              </div>
            </div>
            <div className="common-flex-grow"/>
            <div className="gamification-report-info-weekly-report-item-container">
              <div className="gamification-report-info-weekly-report-count-container gamification-report-info-weekly-report-count-container-3">
                <div className="gamification-report-info-weekly-report-count-inner">
                  <div className="gamification-report-info-weekly-report-count">
                    <div className="gamification-report-info-weekly-report-count-text">
                      {this.state.report.intValues[3]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="gamification-report-info-weekly-report-description">
                댓글
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "GamificationPeriodicReportPopup: render", this.state.report);
    
    let report;
    if(this.state.report){
      switch(this.state.report.type) {
        case GamificationPeriodicReportType.HELP:
          report = this.renderReportHelp();
          break;
        case GamificationPeriodicReportType.ANSWER_REPORT:
          report = this.renderReportAnswerReport();
          break;
      }
    }
    

    return (
      <IonModal cssClass= "common-modal-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="common-modal-popup-inner">
          <div className="common-modal-popup-backdrop" onClick={() => this.setState(() => ({ visible: false }))}/>
          {report}
          <div className="common-modal-popup-backdrop" onClick={() => this.setState(() => ({ visible: false }))}/>
        </div>
      </IonModal>
    );
  }
}

export default GamificationPeriodicReportPopup;