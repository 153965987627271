
import React, { Component } from 'react';
import './../../UserManage.css';
import { withRouter, RouteComponentProps } from 'react-router';
import { IonButton, IonModal,IonSelect, IonSelectOption,IonList, IonItem, IonTextarea } from '@ionic/react';
import {fetchAPI} from '../../../utils/APIUtil'
import * as API from '../../../API.json';;
import { timeout } from 'q';
import smileIcon from './../assets/icon/smile.svg'
import {log, LogLevel} from '../../../utils/LogUtil'
import { UserInfo, UserWorkTypeName, UserLevel } from '../../../models/Model.User';
import UserManageProfile from './UserManageProfile';

type Props = {
  onDone: () => void;
};

type State = {
  user: UserInfo;
  description: string;
  level: UserLevel;
}

class UserManagePopup extends Component<Props, State> {
  state={
    user: null,
    description: '',
    level: UserLevel.ALL,
  };

  show = (user: UserInfo) => {
    log(LogLevel.UI_EVENT, "UserManagePopup:show")
    this.setState({user:user});
  }

  hide = () => {
    log(LogLevel.UI_EVENT, "UserManagePopup:hide")
    this.setState({user: null});
  }

  onDone = () => {
    this.hide();
    this.props.onDone();
  }

  onCancel = () => {
    this.hide();
  }

  onMessageChange = (e: CustomEvent) => {
    // log(LogLevel.NONE, e);
    this.setState({description: e.detail.value});
  }

  onLevelChange = async (e: CustomEvent) => {
    log(LogLevel.UI_EVENT, e);
    let level: UserLevel = parseInt(e.detail.value);
    this.setState({level: level});
  }

  render() {
    return (

      <IonModal cssClass="user-manage-popup-container"
        isOpen={this.state.user?true:false}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ user: null }))}
      >
        <UserManageProfile user={this.state.user} onDone={this.onDone} onCancel={this.onCancel}/>
      </IonModal>
    );
  }
}

export default UserManagePopup;