import React, { useState } from "react";
import Text from "../../atoms/Text";
import {
  FinishFooter,
  FinishMain,
  IsSuccessSelectorItem,
  IsSuccessSelectorList,
  SelectorListMain,
} from "./style";
import SuccessImage from "../../../assets/image/jobpost-apply-success.png";
import OtherServiceImage from "../../../assets/image/jobpost-apply-other.png";
import FailImage from "../../../assets/image/jobpost-apply-fail.png";
import { COLOR_SYSTEM } from "../../design/design-system";
import Icon from "../../atoms/Icon";
import Button from "../../atoms/Button";
import { RouteComponentProps, withRouter } from "react-router";
import PharmacistListBottomSheet from "../../organisms/JobPostBottomSheet/PharmacistListBottomSheet";
import { JobOfferResult, JobOfferInfo } from "../../../models/Model.JobPost";

type Step = "Selector" | "Finish";

interface JobPostSuccessSurveyProps {
  jobPost: JobOfferInfo;
  onFinalize: (
    passed: number[],
    jobOfferResult: JobOfferResult
  ) => Promise<void>;
}

const JobPostSuccessSurvey: React.FC<
  RouteComponentProps & JobPostSuccessSurveyProps
> = ({ history, jobPost, onFinalize }) => {
  const [currentStep, setCurrentStep] = useState<Step>("Selector");
  const [pharmacistListBottomSheet, setPharmacistListBottomSheet] = useState(
    false
  );
  const [passed, setPassed] = useState<number[]>([]);

  const handleActiveBottomSheet = () => {
    setPharmacistListBottomSheet(true);
  };

  const handleFinishClick = (jobOfferResult: JobOfferResult) => {
    if (jobOfferResult === JobOfferResult.Success) {
      onFinalize(passed, jobOfferResult);
      setCurrentStep("Finish");
    } else {
      return () => {
        onFinalize(passed, jobOfferResult);
        setCurrentStep("Finish");
      };
    }
  };

  return (
    <>
      {currentStep === "Selector" ? (
        <SelectorListMain page={"Success"}>
          <Text
            element="h2"
            textType="H2"
            color={COLOR_SYSTEM.get("Gray")[800]}
          >
            채용에 성공하셨나요?
          </Text>
          <IsSuccessSelectorList>
            <IsSuccessSelectorItem onClick={handleActiveBottomSheet}>
              <img src={SuccessImage} alt="" />
              <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[800]}>
                약문약답에서 성공했어요
              </Text>
            </IsSuccessSelectorItem>
            <IsSuccessSelectorItem
              onClick={handleFinishClick(JobOfferResult.OtherService)}
            >
              <img src={OtherServiceImage} alt="" />
              <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[800]}>
                타 서비스에서 성공했어요
              </Text>
            </IsSuccessSelectorItem>
            <IsSuccessSelectorItem
              onClick={handleFinishClick(JobOfferResult.Fail)}
            >
              <img src={FailImage} alt="" />
              <Text textType="Headline" color={COLOR_SYSTEM.get("Gray")[800]}>
                실패했어요
              </Text>
            </IsSuccessSelectorItem>
          </IsSuccessSelectorList>
        </SelectorListMain>
      ) : (
        <div className="common-container-full common-background-white">
          <FinishMain page={"Finish"}>
            <Icon
              width={50}
              height={50}
              name={"Check Circle"}
              fill={COLOR_SYSTEM.get("Skyblue")[400]}
            />
            <Text
              element="h1"
              textType="H1"
              color={COLOR_SYSTEM.get("Gray")[800]}
            >
              구인 공고를 종료했어요
            </Text>
          </FinishMain>
          <FinishFooter>
            <Button
              color="Secondary"
              size="Large"
              type="Text"
              variant="Tinted"
              onClick={() => {
                history.push("/main/jobpost");
              }}
            >
              닫기
            </Button>
          </FinishFooter>
        </div>
      )}

      <PharmacistListBottomSheet
        active={pharmacistListBottomSheet}
        onClose={() => {
          setPharmacistListBottomSheet(false);
        }}
        jobPost={jobPost}
        passed={passed}
        setPassed={setPassed}
        onFinishSurvey={handleFinishClick}
      />
    </>
  );
};

export default withRouter(JobPostSuccessSurvey);
