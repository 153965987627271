import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootState, actions } from '../store';
import { withRouter, RouteComponentProps } from "react-router";
import { IonInput, IonText, IonSelect, IonSelectOption, IonHeader, IonIcon, IonButtons, IonMenuButton, IonList, IonSegmentButton, IonButton, IonSearchbar, IonContent, IonRefresher, IonRefresherContent, IonFab, IonFabList, IonFabButton, IonAlert, IonBadge, IonLabel } from '@ionic/react';
import './Admin.scss'
import {log, LogLevel} from '../utils/LogUtil'


import KeyMeasureStatisticsRegisterView from './component/KeyMeasureStatisticsRegisterView';
import KeyMeasureStatisticsActiveUserView from './component/KeyMeasureStatisticsActiveUserView';
import BoardExcellentStatisticsView from './component/BoardExcellentStatisticsView';
import { UserInfo } from '../models/Model.User';

type Props =  RouteComponentProps<{tab:string}> & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>

type State = {
  selecting: boolean,
  selected: UserInfo,
}

class KeyMeasureStatistics extends Component<Props, State> {
  popup = null;
  list = null;

  state = {
    selecting: false,
    selected: null,
  };

  constructor(props: Props) {
    super(props);
    // this.props.loadFilePath();
  }

  componentDidMount() {
  }

  render() {
    log(LogLevel.UI_LIFECYCLE, "KeyMeasureStatistics.render", this.props);

    return (
      <>
        <IonHeader class="admin-header" no-border>
            <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonText slot="middle">
                핵심 지표 (가입, WAU)
            </IonText>
        </IonHeader>
        <IonContent>
          <div className="admin-full-container">
            <KeyMeasureStatisticsRegisterView/>
            <KeyMeasureStatisticsActiveUserView/>
            <BoardExcellentStatisticsView simple={true}/>
          </div>
        </IonContent>
        
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  // user: state.user,
});

const mapDispatchToProps = {
//   logOutUser: () => actions.user.logout(),
//   refreshBoards: () => actions.board.refreshBoards(),
  // loadFilePath: () => actions.board.getFilePath(),
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KeyMeasureStatistics));