import React from "react";
import styled from "styled-components";
import { typography, Typography } from "../../design/typography";

type TextElement =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "a"
  | "span"
  | "p"
  | "address"
  | "pre"
  | "div"
  | "strong";

export interface TextProps {
  /** 텍스트 요소 */
  element?: TextElement;
  /** 텍스트 안에 들어갈 content */
  children: React.ReactNode | string;
  /** <a> 태그가 가리키는 URL */
  href?: string;
  /** 글꼴 */
  textType?: Typography | "inherit";
  /** 글 색상 */
  color: string;
  /** Custom Class name */
  className?: string;
  /** 보여지는 최대 line 수 */
  numberOfLines?: number;
  style?: React.CSSProperties;

  onClick?: () => void;
}

interface TextWrapperProps {
  element?: TextElement;
  textType: Typography | "inherit";
  color: string;
  numberOfLines?: number;
}

const TextWrapper = styled.span<TextWrapperProps>`
  display: ${({ numberOfLines }) => (numberOfLines ? "-webkit-box" : "inline")};
  ${({ textType }) =>
    textType == "inherit"
      ? `
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;    
  `
      : typography.get(textType)};
  color: ${({ color }) => color};
  word-break: break-all;

  ${({ element }) => {
    if (element === "pre") return "white-space: pre-line";
  }};

  ${({ numberOfLines }) => {
    if (numberOfLines) {
      return `display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${numberOfLines};`;
    }
  }};
`;

const Text: React.FC<TextProps> = ({
  element = "span",
  children,
  href,
  textType = "inherit",
  color,
  className,
  numberOfLines,
  style,
  onClick,
}) => {
  return (
    <TextWrapper
      as={element}
      href={href}
      textType={textType}
      color={color}
      className={className}
      numberOfLines={numberOfLines}
      onClick={onClick}
      element={element}
      style={style}
    >
      {children}
    </TextWrapper>
  );
};

export default Text;
