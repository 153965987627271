import { SERVER_ADDRESS, SERVER_API_PORT, BIZ_SERVER_ADDRESS } from "./../config.json";
import { log, LogLevel } from "../utils/LogUtil";

export function fetchAPI(api, path_append, queries, body, token = undefined) {
  // log(LogLevel.NONE, "fetchAPI", api, api.contentType);
  let params = {
    method: api.method,
    headers: {
      Accept: "application/json",
      //   'Content-Type': api.contentType,
      Origin: "",
      Authorization: token ? "Bearer " + token : "",
    },
    // body: body
  };

  if (api.contentType) {
    params.headers["Content-Type"] = api.contentType;
  }
  if (api.accept) {
    params.headers["Accept"] = api.accept;
  }

  if (api.contentType === "application/json") {
    if (body) {
      params.body = JSON.stringify(body);
    }
  } else {
    if (body) {
      params.body = body;
    }
  }

  let url = SERVER_ADDRESS + SERVER_API_PORT + api.path + path_append;
  if (queries) {
    let query = Object.keys(queries)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(queries[k]))
      .join("&");

    url += "?" + query;
  }
  // log(LogLevel.NONE, "fetchAPI", url, params);
  return new Promise((resolve, reject) => {
    // log(LogLevel.NONE, "params: ", url );
    fetch(url, params)
      .then((response) => {
        // log(LogLevel.NONE, "response: ", response, response.status, response.body, response.body);
        if (response.status === 200) {
          // log(LogLevel.UI_ACTION, response, api.accept);
          if (api.accept) return response.text();
          else return response.json();
        } else {
          // log(LogLevel.NONE, "fail: " + response);
          reject(response);
        }
      })
      .then((responseJson) => {
        // log(LogLevel.NONE, "responseJson: ", responseJson, '-', responseJson.status, '-', responseJson.data);
        resolve(responseJson);
      })
      .catch((error) => {
        log(LogLevel.NONE, "Fetch error:", error, api);
        reject(error);
      });
  });
}



export function bizFetchAPI(api, path_append, queries, body, token = undefined) {
  let url = BIZ_SERVER_ADDRESS + api.path + path_append;
  if (queries) {
    let query = Object.keys(queries)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(queries[k]))
      .join("&");

    url += "?" + query;
  }

  let headers = {
    Accept: "application/json",
    Origin: "",
    authorization: token ? "Bearer " + token : "",
  };

  if (api.contentType) {
    headers["Content-Type"] = api.contentType;
  }
  if (api.accept) {
    headers["Accept"] = api.accept;
  }

  let options = {
    method: api.method,
    headers: headers,
  };

  if (api.method !== 'GET' && api.method !== 'HEAD') {
    options.body = api.contentType === "application/json" && body ? JSON.stringify(body) : body;
  }

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        log(LogLevel.NONE, "Fetch error:", error, api);
        reject(error);
      });
  });
}


