import React, { FC } from "react";
import styled, { StyledComponent } from "styled-components";
interface ContainerProps {
  scrollable: boolean;
  headerHeight: number;
  footerHeight: number;
}

const Container: StyledComponent<
  "div",
  FC,
  ContainerProps
> = styled.div<ContainerProps>`
  width: 100%;
  height: 100vh;
  padding: ${(props) => props.headerHeight}px 0px
    ${(props) => props.footerHeight}px 0px;
  background: #fff;
  position: relative;

  ${(props) =>
    props.scrollable
      ? `
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
    `
      : `
    overflow-y: hidden;
    `}
`;

export interface Props {
  scrollable?: boolean;
  headerHeight?: number;
  footerHeight?: number;
}

const Body: FC<Props> = ({
  scrollable = true,
  headerHeight = 48,
  footerHeight = 0,
  children,
}) => {
  return (
    <Container
      scrollable={scrollable}
      headerHeight={headerHeight}
      footerHeight={footerHeight}
    >
      {children}
    </Container>
  );
};

export default Body;
