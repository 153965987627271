import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import { COLOR_SYSTEM } from "../../design/design-system";
import Popup, { PopupProps } from "../../molecules/Popup";
import { PopupBody, ColumnDirectionButtonContainer } from "./style";

interface RequestRaisePopupProps extends PopupProps {
  selectedWorkTypeName: string;
  onCreate: () => void;
  onRaise: () => void;
}

const RequestRaisePopup: React.FC<RequestRaisePopupProps> = ({ selectedWorkTypeName, onClose, onCreate, onRaise }) => {
  return (
    <Popup onClose={onClose}>
      <PopupBody>
        <Text textType="H4" color={COLOR_SYSTEM.get("Gray")[800]}>
          진행 중인 {selectedWorkTypeName} 공고가 있어요
        </Text>
        <Text textType="Body1" color={COLOR_SYSTEM.get("Gray")[600]}>
          공고를 게시판 상단에 노출하고 싶다면
          <br />새 공고를 작성하는 대신,
          <br />
          끌어올리기 기능을 사용해보세요 🙂
        </Text>
      </PopupBody>
      <ColumnDirectionButtonContainer>
        <Button color="Secondary" size="Large" type="Text" variant="Tinted" onClick={onCreate}>
          새 공고 작성하기
        </Button>
        <Button color="Primary" size="Large" type="Text" variant="Contained" onClick={onRaise}>
          끌어올리러 가기
        </Button>
      </ColumnDirectionButtonContainer>
    </Popup>
  );
};

export default RequestRaisePopup;
