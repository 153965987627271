import React, { Children, Component } from 'react';
import './style.scss';
import { IonModal} from '@ionic/react';
import Text from './../../../atom/Text'
import StarScorer from '../../../atom/StarScorer';
import TextButton from '../../../atom/TextButton';
import Input from '../../../atom/Input';

type Props = {
  onDone: (score:number, text:string) => void;
  onCancel: () => void;
  lectureId?: number;
};

type State = {
  visible: boolean;
  score: number;
  text: string;
}

class SeminarReviewPopup extends Component<Props, State> {

  state={
    visible: false,
    score: 0,
    text: ""
  };

  componentDidUpdate(preProps){
    if(this.props.lectureId != preProps.lectureId){
      this.setState({score:0, text:""});
    }
  }

  show = () => {
    this.setState({visible: true});
    setTimeout(() => {
      this.setState({score: this.state.score, text: this.state.text});
    }, 50)
  }

  hide = () => {
    this.setState({visible: false});
  }

  isShown = () => {
    return this.state.visible;
  }

  onDone = () => {
    this.hide();
    if(this.props.onDone)
      this.props.onDone(this.state.score, this.state.text);
  }

  onCancel = () => {
    this.hide();
    if(this.props.onCancel)
      this.props.onCancel();
  }

  render() {
    return (
      <IonModal cssClass="seminar-review-popup-container"
        isOpen={this.state.visible}
        backdropDismiss = {true}
        onDidDismiss={() => this.setState(() => ({ visible: false }))}
      >
        <div className="seminar-review-popup-inner">
          <div className="seminar-review-popup-backdrop"  onClick={this.hide}/>
          <div className="seminar-review-container-outer">
            <div className="seminar-review-container">
              <div className="seminar-review-body-container">
                <div className="seminar-review-body-title">
                  <Text type="H4" color="Secondary">이번 컨텐츠 어땠나요?</Text>
                </div>
                <div className="seminar-review-body-star">
                  <StarScorer size="XL" score={this.state.score} onScoreChange={(score)=>this.setState({score})}/>
                </div>
                <div className="seminar-review-body-input">
                  <Input value={this.state.text} onChange={(e) => this.setState({text:e.target.value})} allowMultiline={true} maxRows={5} placeholder="이번 컨텐츠가 어땠는지 설명해주세요"/>
                </div>
              </div>
              <div className="seminar-review-buttons-container">
                <div className="seminar-review-button">
                  <TextButton size="L" variant="Contained" color="Secondary" block onClick={this.onCancel}>다음에 하기</TextButton>
                </div>
                <div className="seminar-review-button-gap"/>
                <div className="seminar-review-button">
                  <TextButton size="L" variant="Contained" color="Primary" disabled={!this.state.score} block onClick={this.onDone}>등록 하기</TextButton>
                </div>
              </div>
            </div>
          </div>
          <div className="seminar-review-popup-backdrop"  onClick={this.hide}/>
        </div>
      </IonModal>
    );
  }
}

export default SeminarReviewPopup;